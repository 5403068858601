<template>
    <v-container class="EDC-Rows">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-card class="EDC-CardSideMargin">
                            <v-row no-gutters class="EDC-DashboardRow" align="center">
                                <v-col cols="12" class="EDC-DashboardCol">
                                    <span class="EDC-Heading">Commit Progess</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="EDC-DashboardRow" align="center">
                                <v-col cols="12" class="lineecharts">
                                    <IEcharts :option="lineoption" :loading="lineloading" @click="onDataPointClick" @ready="onLineGraphReady"/>
                                </v-col>
                            </v-row>
                      </v-card>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="1" align="left">
                        <label class="v-label" style="float:left;margin-top:5px;">Select Type</label>
                    </v-col>
                    <v-col class="EDC-Col" cols="8" align="left">
                        <v-radio-group v-model="graph_type" @change="graphTypeChange" dense hide-details row>
                            <v-radio label="Total Time" class="radioClass" value="total_time">
                            </v-radio>
                            <v-radio label="Total Count" class="radioClass" value="total_count">
                            </v-radio>
                            <v-radio label="Avg Time" class="radioClass" value="avg_time">
                            </v-radio>
                            <v-radio label="Avg Size" class="radioClass" value="avg_size">
                            </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="3" class="EDC-Col">

                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="12">
                        <v-card class="EDC-Row EDC-CardSideMargin">
                            <v-row no-gutters class="EDC-DashboardRow" align="center">
                                <v-col cols="12" class="EDC-DashboardCol">
                                    <span class="EDC-Heading">{{graph_caption}}</span>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row echarts">
                                <v-col cols="12">
                                    <IEcharts  :option="option" :loading="loading" @click="onClick" @ready="onTimeReady"/>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .echarts {
        width: 1200px;
        height: 230px !important;
    }
    .lineecharts{
        width: 1250px;
        height: 200px !important;
    }
    .graph_div {
        width:500px !important;
        overflow: auto !important;
    }
    #radioControls .v-input--selection-controls {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }
    .radioClass >>> label{
        top: 5px !important;
    }
</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import config from '../../../config.json'
    import {post as postToServer } from './../../../methods/serverCall.js';
    import IEcharts from 'vue-echarts-v3/src/full.js';
    import 'echarts/lib/chart/line'
    import 'echarts/lib/component/polar'
    import {getLineGraph,getBarGraph} from '@/methods/echartgraphs/transactiongraph.js'
    export default{
        name:"JobStatsGraphs",
        mixins:[mixinJobManagement],
        components:{
            IEcharts
        },
        props:{
            jobId:{
                type:Number,
                default:0
            }
        },
        data(){
            return{
                lineoption:{},
                option :{},
                loading:false,
                lineloading:false,
                total_echart_instance:null,
                total_echart_graph:null,
                total_count_echart_instance:null,
                total_count_echart_graph:null,
                pie_echart_instance:null,
                pie_echart_graph:null,
                line_echart_instance:null,
                line_echart_graph:null,
                avg_echart_instance:null,
                avg_echart_graph:null,
                avg_size_echart_instance:null,
                avg_size_echart_graph:null,
                noOfLines:1,
                datapoint_size:5,
                datapoint_border_width:2,
                line_width:3,
                backend_data:null,
                wholeGraphData:{},
                running_transaction_data:{"transaction_id":"","data":[]},
                call_send_to_fetch_transdetails:false,
                temp_data_container:{},
                showoptions:true,
                graph_type:'total_time',
                graph_caption:"Total Time",
            }
        },
        watch:{
            jobId:{
                handler(newValue){
                    this.getInitData()
                }
            },
            '$store.state.transctionTableObj':{
                handler(newValue){
                    this.updateData(newValue)
                }
            },
            '$store.state.liveStatus':{
                handler(newValue){
                    if(newValue && newValue.status === 'completed'){
                        this.getJobCompletedTransData()
                    }
                }
            }
        },
        mounted(){
            this.getInitData()
        },
        methods: {
            getInitData(){
                var _this = this
                setTimeout(function(){
                    if(_this.total_echart_instance){
                        _this.getJobCompletedTransData()
                        _this.line_echart_instance.resize()
                    }
                 },1000)
            },
            ////graph
            graphTypeChange(item){
                var _this = this
                setTimeout(function(){
                    var options = _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
                    if(_this.total_echart_instance ){
                        _this.total_echart_instance.resize()
                    }
                },10)
            },
            onLineGraphReady(instance,echarts){
                this.line_echart_instance = instance
                this.line_echart_graph = echarts
            },
            onTimeReady(instance,echarts){
                this.total_echart_instance = instance
                this.total_echart_graph = echarts
            },
            onCountReady(instance,echarts){
                this.total_count_echart_instance = instance
                this.total_count_echart_graph = echarts
            },
            onAvgReady(instance,echarts){
                this.avg_echart_instance = instance
                this.avg_echart_graph = echarts
            },
            onSizeReady(instance,echarts){
                this.avg_size_echart_instance = instance
                this.avg_size_echart_graph = echarts
            },
            onClick(event,instance,ECharts){

            },
            onAvgClick(){

            },
            getDataPoints(response){

            },
            getDataPoints_inCouple(response){

            },
            onDataPointClick(event, instance, ECharts){
                var data = event
                if(event.data.length > 1)
                    this.drilldownText = event.seriesName + ' at '+event.data[0] + " is " +event.data[1]
                this.ShowDrillDownGraph(event.dataIndex)
            },
            ShowDrillDownGraph(data_index){
                let _this = this
                _this.isDrillDownload = true
                var data_point_data = _.cloneDeep(this.wholeGraphData.step_details[0].transaction_details[data_index])

                var table_insert_stats = []
                var table_delete_stats = []
                var table_data = data_point_data.table_details
                for (var j = 0; j < table_data.length; j++) {
                    var current_row = table_data[j]
                    _this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
                } // j for loop end
                var toolbox = {
                show:true,
                    feature:{
                        myTool1:{
                            show:true,
                            title:'Back',
                            icon:'M464 0H144c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v320c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-96 464H48V256h320v208zm96-96h-48V144c0-26.5-21.5-48-48-48H144V48h320v320z',
                            onclick:function(){
                                var transactions = _this.wholeGraphData.step_details[0].transaction_details
                                _this.drilldownText = ''
                                _this.processTransactionData(transactions)
                            }
                        },
                    }
                }
                this.generate_total_bar_graph(table_insert_stats,table_delete_stats,toolbox)
            },
            getJobCompletedTransData(){
                var _this = this
                this.loading = true
                this.lineloading = true
                postToServer(this, config.JOB_STATISTIC_URL + '/get_job_statistics', {"job_id":this.jobId}).then(response => {
                    this.wholeGraphData = response
                    console.log('this.wholeGraphData',this.wholeGraphData)
                    if(response.step_details[0])
                        this.processTransactionData(response.step_details[0].transaction_details)
                    this.loading = false
                    this.lineloading =false
                }).catch(JobStepError => {
                    this.loading = false
                    this.lineloading = false
                    _this.showMessage(JobStepError)
                })
            },
            processTransactionData(transaction_details){
                var transactions = _.cloneDeep(transaction_details)
                var table_insert_stats = []
                var table_delete_stats = []
                var commit_time = []
                var commit_line_graph_data =[]
                var commit_size = []
                for (var i = 0; i < transactions.length; i++) {
                    var current_trans = transactions[i]
                    commit_time.push({"value":current_trans.transaction_time,"name":"TransNo "+(i+1)})
                    commit_line_graph_data.push([current_trans.transaction_end_time,current_trans.transaction_time])
                    commit_size.push([current_trans.transaction_end_time,current_trans.chunk_size])
                    if(current_trans.status === 'commit'){
                        var table_data = current_trans.table_details
                        for (var j = 0; j < table_data.length; j++) {
                            var current_row = table_data[j]
                            this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
                        } // j for loop end
                    }
                } // i for loop finish
                this.generate_line_graph(commit_line_graph_data,commit_size)
                this.generate_total_bar_graph(table_insert_stats,table_delete_stats)
                // this.generate_pi(commit_time)
            },
            ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats){
                var operation = current_row.operation
                var table_name = current_row.table_name
                if(!current_row['avg_size']) // avg_size not come with live publish data
                    current_row["avg_size"] = 0
                current_row["avg_size"] = parseInt(current_row["avg_size"])
                current_row["total_time"] = parseInt(current_row["total_time"])
                current_row["total_count"] = parseInt(current_row["total_count"])
                current_row["total_size"] = current_row["total_count"] * (current_row["avg_size"]/(1024))
                if(operation === "insert"){
                    var obj = _.find(table_insert_stats,["table_name",table_name])
                    if(obj){
                        obj.total_time = obj.total_time + current_row.total_time
                        obj.total_count = obj.total_count + current_row.total_count
                        var index = _.findIndex(table_insert_stats,["table_name",table_name])
                        obj.time_graph_key = [table_name,obj.total_time]
                        obj.size_graph_key = [table_name,obj.total_count]
                        obj.avg_time_key = [table_name,this.calculateAvgTime(obj.total_count,obj.total_time)]
                        obj.avg_time_size_key = [table_name,this.calculateAvgSizeTime(obj.total_size,obj.total_time)]
                        this.$set(table_insert_stats,index,obj)
                    }
                    else{
                        current_row.time_graph_key = [table_name,current_row.total_time]
                        current_row.size_graph_key = [table_name,current_row.total_count]
                        current_row.avg_time_key = [table_name,this.calculateAvgTime(current_row.total_count,current_row.total_time)]
                        current_row.avg_time_size_key = [table_name,this.calculateAvgSizeTime(current_row.total_size,current_row.total_time)]
                        table_insert_stats.push(current_row)
                    }
                }
                else {
                    var obj = _.find(table_delete_stats,["table_name",table_name])
                    if(obj){
                        obj.total_time = obj.total_time + current_row.total_time
                        obj.total_count = obj.total_count + current_row.total_count
                        obj.time_graph_key = [table_name,obj.total_time]
                        obj.size_graph_key = [table_name,obj.total_count]
                        obj.avg_time_key = [table_name,this.calculateAvgTime(obj.total_count,obj.total_time)]
                        obj.avg_time_size_key = [table_name,this.calculateAvgSizeTime(obj.total_size,obj.total_time)]
                        var index = _.findIndex(table_delete_stats,["table_name",table_name])
                        this.$set(table_delete_stats,index,obj)
                    }
                    else{
                        current_row.time_graph_key = [table_name,current_row.total_time]
                        current_row.size_graph_key = [table_name,current_row.total_count]
                        current_row.avg_time_key = [table_name,this.calculateAvgTime(current_row.total_count,current_row.total_time)]
                        current_row.avg_time_size_key = [table_name,this.calculateAvgSizeTime(current_row.total_size,current_row.total_time)]
                        table_delete_stats.push(current_row)
                    }
                }
            },
            calculateAvgTime(count ,timeInSec){
                if(timeInSec === 0 || count === 0)
                    return 0
                return (count/timeInSec).toFixed(2)
            },
            calculateAvgSizeTime(size,timeInSec){
                if(timeInSec === 0 || size === 0)
                    return 0
                return (size/timeInSec).toFixed(2)
            },
            generate_line_graph(commit_line_graph_data,commit_size){
                let linegraphData = getLineGraph(commit_line_graph_data,commit_size,this.wholeGraphData.step_details[0].time_unit)
                this.line_echart_instance.setOption(linegraphData, true)
            },
            generate_total_bar_graph(insert_data,delete_data,toolbox={}){
                let _this = this
                if(insert_data.length < 1 && delete_data.length < 1)
                    return false

                var tables = _.map(insert_data, "table_name")
                if(!tables.length)
                    tables = _.map(delete_data, "table_name")

                var insert_time_series_data = _.map(insert_data, "time_graph_key")
                var delete_time_series_data = _.map(delete_data, "time_graph_key")
                var insert_size_series_data = _.map(insert_data, "size_graph_key")
                var delete_size_series_data = _.map(delete_data, "size_graph_key")
                if(_this.graph_type === 'total_time'){
                    _this.graph_caption = "Total Time"
                    let totalTimeData = getBarGraph(["Ins.Time","Del.Time"],'Time in('+this.wholeGraphData.step_details[0].time_unit+')',tables,insert_time_series_data,delete_time_series_data,toolbox)
                    _this.total_echart_instance.setOption(totalTimeData, true)
                    return
                }

                if(_this.graph_type === 'total_count'){
                    _this.graph_caption = "Total Count"
                    let totalRowCountData = getBarGraph(["Ins.Count","Del.Count"],'Row Count in('+this.wholeGraphData.step_details[0].count_unit+')',tables,insert_size_series_data,delete_size_series_data,toolbox)
                    _this.total_echart_instance.setOption(totalRowCountData, true)
                }
                _this.generate_avg_bar_graph(insert_data,delete_data,toolbox)

            },
            generate_avg_bar_graph(insert_data,delete_data,toolbox={}){
                let _this = this
                if(insert_data.length < 1 && delete_data.length < 1)
                    return false
                var tables = _.map(insert_data, "table_name")
                if(!tables.length)
                    tables = _.map(delete_data, "table_name")
                var insert_avg_time_series_data = _.map(insert_data, "avg_time_key")
                var delete_avg_time_series_data = _.map(delete_data, "avg_time_key")
                var insert_avg_size_time_series_data = _.map(insert_data, "avg_time_size_key")
                var delete_avg_size_time_series_data = _.map(delete_data, "avg_time_size_key")
                if(_this.graph_type === 'avg_time'){
                    _this.graph_caption ="Avg Time"
                    let timeAvgData = getBarGraph(["Ins.AvgT","Del.AvgT"],'Time in('+this.wholeGraphData.step_details[0].time_unit+')',tables,insert_avg_time_series_data,delete_avg_time_series_data,toolbox)
                    _this.total_echart_instance.setOption(timeAvgData, true)
                    return
                }
                if(_this.graph_type === 'avg_size'){
                    _this.graph_caption ="Avg Size"
                    let sizeAvgData = getBarGraph(["Ins.AvgS","Del.AvgS"],'Size in('+this.wholeGraphData.step_details[0].size_unit+')',tables,insert_avg_size_time_series_data,delete_avg_size_time_series_data,toolbox)
                    _this.total_echart_instance.setOption(sizeAvgData, true)
                    return
                }
            },
            updatetimedata(data){
                var table_name = data.table_name
                var obj = _.find(this.Datajsonlist,["table_name",table_name])

                if(data.operation === "insert"){
                    data.insert_count  = parseInt(data.total_count)
                    data.delete_count = 0
                }
                else{
                    data.delete_count = parseInt(data.total_count)
                    data.insert_count = 0
                }

                if(obj){
                    obj.insert_count = obj.insert_count + data.insert_count
                    obj.delete_count = obj.delete_count + data.delete_count
                    var index = _.findIndex(this.Datajsonlist,["table_name",table_name])
                    this.$set(this.Datajsonlist,index,obj)
                }
                else{
                    this.Datajsonlist.push(data)
                }
                var tablelist = _.map(this.Datajsonlist,"table_name")
                var insert_data_list = _.map(this.Datajsonlist,"insert_count")
                var delete_data_list =_.map(this.Datajsonlist,"delete_count")
                var option = {
                xAxis: {
                    type: 'category',
                    name:'Tables',
                    nameGap:0,
                    data:tablelist,
                    nameLocation:'center',
                    axisTick:{
                    alignWithLabel:true,
                    },
                    axisLabel: {
                    interval: 0,
                    rotate: 30
                    },
                },
                yAxis: {
                    type: 'value'
                },
                tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                    type: '',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                    }
                },
                series: [{
                    name:"Insert",
                    data: insert_data_list,
                    type: 'bar'
                },
                {
                    name:"Delete",
                    data: delete_data_list,
                    type: 'bar'
                }]
                }
                this.total_echart_instance.setOption(option, true)
            },
            ConvertTime(time,unit){
                if(!unit || unit.toLowerCase() === 'milliseconds')
                    return time

                if(unit.toLowerCase() === 'seconds')
                    return Math.floor(time/1000)
                if(unit.toLowerCase() === 'minutes' || unit.toLowerCase() === 'minute')
                    return Math.floor(time/60000)
                return Math.floor(time/3600000)
            },
            ConvertSize(time,unit){
                if(!unit || unit.toLowerCase() === 'milliseconds')
                    return time

                if(unit.toLowerCase() === 'seconds')
                    return Math.floor(time/1000)
                if(unit.toLowerCase() === 'minutes' || unit.toLowerCase() === 'minute')
                    return Math.floor(time/60000)
                return Math.floor(time/3600000)
            },
            ConvertCount(count,unit){
                if(!unit)
                    return count

                if(unit.toLowerCase() === 'ks')
                    return Math.floor(count/1000)
                if(unit.toLowerCase() === 'million')
                    return Math.floor(count/1000000)
                return count
            },
            updateData(data){
                /*new data received. Now check is the new transaction or old*/
                if(this.jobId != data.job_id)
                    return false
                var timeunit = 'milliseconds'
                var sizeunit="KB"
                var countunit="Ks"
                if(this.wholeGraphData.step_details.length && this.wholeGraphData.step_details[0].time_unit){
                    timeunit = this.wholeGraphData.step_details[0].time_unit
                    sizeunit = this.wholeGraphData.step_details[0].size_unit
                    countunit = this.wholeGraphData.step_details[0].count_unit
                }

                let totalTime = this.ConvertTime(data.total_time,timeunit)
                // let totalCount = this.ConvertCount(data.total_count,countunit)
                // let totalSize = this.conve
                data.total_time = totalTime
                // data.total_count = totalCount
                if(this.running_transaction_data && this.running_transaction_data.transaction_id){
                    if(data.transaction_id === this.running_transaction_data.transaction_id)
                    {
                        this.running_transaction_data["data"].push(data)
                        return this.CreateLiveGraph()
                    }
                    else{
                        var old_transaction_id = this.running_transaction_data.transaction_id
                        this.running_transaction_data ={"transaction_id":"","data":[]}
                        this.running_transaction_data.transaction_id = data.transaction_id
                        this.running_transaction_data["data"].push(data)

                            // we need to fetch all details of old_transacion_id to design new graph.
                            this.fetchSingleTransDetails(old_transaction_id)
                    }
                }
                else{
                var new_transaction_id = data.transaction_id
                this.running_transaction_data.transaction_id = data.transaction_id
                this.running_transaction_data["data"].push(data)
                // fetch data only once when visited this page
                this.fetchSingleTransDetails(new_transaction_id)
                }
            },
            fetchSingleTransDetails(transaction_id){
                if(!transaction_id)
                    return false
                var timeunit = 'milliseconds'
                var sizeunit="KB"
                var countunit="Ks"
                if(!this.wholeGraphData ||!this.wholeGraphData.step_details)
                    return
                if(this.wholeGraphData.step_details.length && this.wholeGraphData.step_details[0].time_unit){
                    timeunit = this.wholeGraphData.step_details[0].time_unit
                    sizeunit = this.wholeGraphData.step_details[0].size_unit
                    countunit = this.wholeGraphData.step_details[0].count_unit
                }
                var data = {"transaction_id":transaction_id,'timeunit':timeunit,'sizeunit':sizeunit,'countunit':countunit,"job_id":this.jobId}
                let _this = this
                postToServer(this, config.JOB_STATISTIC_URL + '/get_transaction_statistics', data).then(response => {
                    if(response.status === "commit"){
                        // add this response into wholeGraphData
                        if(!_this.wholeGraphData.step_details || _this.wholeGraphData.step_details.length < 1){
                            _this.wholeGraphData = {"step_details":[{"transaction_details":[]}]}
                            _this.wholeGraphData.step_details[0].transaction_details.push(response)
                        }
                        else{
                            var obj = _.find(_this.wholeGraphData.step_details[0].transaction_details, ["id",response.id])
                            if(!obj)
                                _this.wholeGraphData.step_details[0].transaction_details.push(response)
                        }
                        _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
                    }
                    else{
                            if(response.id === _this.running_transaction_data.transaction_id){
                                _this.arrangeRunningData(response.table_details)
                        }
                    }
                }).catch(JobStepError => {

                })
            },
            arrangeRunningData(response_data){
                let _this = this
                /*
                Now we need to add response table details in running_transaction_data.data if that not not present. Where we add its order is mattered here. Becuase table comes in respose in always get excuted first.So we need to add that table at index level on. So loop of the response table details start from last element
                */
                for (var i = response_data.length - 1; i >= 0; i--) {
                var table_name = response_data[i].table_name
                var operation = response_data[i].operation
                var data_list = _this.running_transaction_data["data"]
                var is_data_found = false
                for (var j = data_list.length - 1; j >= 0; j--) {
                    if(table_name === data_list[j]["table_name"] && operation === data_list[j]["operation"]){
                    is_data_found = true
                    break;
                    }
                }
                if(!is_data_found)
                    _this.running_transaction_data["data"].unshift(response_data[i])
                }
                if(_this.whichgraph === 'live' && !_this.isDrillDownload)
                this.CreateLiveGraph()
            },
            CreateLiveGraph(){
                if(this.whichgraph === "all" || this.isDrillDownload)
                    return false
                let _this = this    
                var insert_data = []
                var delete_data = []
                var data_rows =_.cloneDeep(_this.running_transaction_data["data"])
                for (var i = 0; i < data_rows.length; i++) {
                    var current_row = data_rows[i]
                    _this.ProcessOnTableGraph(current_row,insert_data,delete_data)
                }
                _this.generate_total_bar_graph(insert_data,delete_data)
            },
            loadAllGraph(){
                let _this = this;
                _this.isDrillDownload = false
                _this.total_echart_instance.setOption(this.option, true)
                if(_this.whichgraph === 'live'){
                    _this.CreateLiveGraph()
                }
                else{
                    _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
                }
            },
        },
    }
</script>