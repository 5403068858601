<template>
  <div>
    <v-layout
      row
      wrap
      pl-4
      class="breadcrumbBackground"
    >
      <v-flex
        xs8
        style="margin:auto"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs4
            pl-3
          >
            <ul class="breadcrumb">
              <li>
                <router-link to="/calendar">
                  Job Calendar
                </router-link> 
              </li>
              <li>
                History
              </li>
            </ul>
          </v-flex>
          <v-flex xs8 />
        </v-layout>                
      </v-flex>
      <v-flex
        xs4
        text-xs-right
        pr-4
        pb-1
        style=""
      />
    </v-layout>
    <vc-data-table
      :data="tableList"
      @filterData="filterData"
      @onpagination="onPagination"
      @sortBy="sortBy"
    />
    <v-snackbar
      v-model="snackbar"
      :right="true"
      :top="true"
      :timeout="snackbartimeout"
      :color="colorValue"
    >
      {{ snackbartext }}
    </v-snackbar>
    <loading-panel :loader="loader" />
  </div>
</template>
<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from './../../data/client_message.js'
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from './../../data/macros.js'
import _ from 'lodash'
import dataTable from './../../views/dataTable/datatable.vue'
import loadingPanel from './../../views/loading.vue'
import {
    USER_PROVISIONING_SERVICE_ID,
    CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION,
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'
import {EVENT_HISTORY} from '../../data/url_constants.js';
import cloneDeep from 'lodash'
export default {
    name: 'ClientJobListNew',
    components: {
        'vc-data-table': dataTable,
        'loading-panel': loadingPanel
    },
    data: function () {
        return {
            userRole:{},
            loader: false,
            snackbar: false,
            snackbartext: '',
            snackbartimeout: 5000,
            colorValue: 'success',
            tableList: {
                headers: [{
                        text: 'Event Name',
                        value: 'params.name',
                        width: "30%",
                    },
                    {
                        text: 'Event Type',
                        value: 'is_recurring',
                        width:'10%',
                        // cssClass:'w16'
                    },
                    {
                        text: 'Run Date',
                        value: 'timezone_aware_action_taken_date',
                        width: "25%",
                    },
                    {
                        text: 'Run Time',
                        value: 'timezone_aware_action_taken_time',
                        width: "25%",
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        width: "10%",
                    }
                ],
                actions:[],
                sorting_type: SERVER_SIDE,
                filterType: SERVER_SIDE,
                paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                // select_rows:true,
            },
            valid: true,
            envIpJson : {
                  "filter": [],
                  "sort": {
                      "column": "",
                      "type": ""
                  },
                  "page": 1,
                  "page_size": 10
              },
              disableDefaltSorting:true,
        }
    },
    mounted() {
        this.gethistory(this.envIpJson);
    },
    methods: {
        gethistory(envIpJson){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var calendar_id = this.$route.params.record.id
            var request_data = {
                "calendar_id": calendar_id,
                "client_id":client_id,
                "filter": envIpJson.filter,
                "page": envIpJson.page,
                "page_size": envIpJson.page_size,
                "sort": envIpJson.sort,
            }
            _this.loader = true;
            postToServer(_this, config.JOB_CALENDAR_URL + EVENT_HISTORY, request_data).then(response => {
                _this.loader = false;
                if (response) {
                  response.result.map(obj => {
                    obj.is_recurring = obj.params.is_recurring !== 'false' ? 'Recurring' : 'One Time';
                    // _this.tableList.rows.push(obj);
                  })
                  _this.tableList.rows = response.result;
                  _this.tableList.total_count = response.total;
                  _this.tableList.disableDefaltSorting = true
                }else{
                  _this.tableList.rows=[];
                }
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=[];
            _.set(inputJson, 'page', 1);
            debugger
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    if(obj.value.split('.')[1]){
                        var value = obj.value.split('.')[1]
                    }else{
                        var value = obj.value.split('.')[0]
                    }
                    let temp = {"column":value,"value": String(stringObj.text).trim()};
                     inputJson.filter.push(cloneDeep(temp));
                })
            });
            this.gethistory(inputJson)
        },
        onPagination(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.gethistory(inputJson)
        },
        sortBy: function (key, sortingType) {
            let _this = this;
            if(!key || !sortingType)
                return 

            let inputJson = _this.envIpJson;
            inputJson.sort.column = key;
            inputJson.sort.type = sortingType;
            this.gethistory(inputJson);
        }, 
    }
}
</script>
<style scoped>

</style>