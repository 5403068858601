<template>
    <div style="padding-left: 4px !important;">
        <span v-if="showConfirmation" :title="actionTitle">
            <v-menu slot="activator" left v-model="showPopOver" :close-on-content-click="false"
                :nudge-width="200" offset-x>
                <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid" v-on="on" :name="iconName" 
                    slot="activator" :original="true" :title="actionTitle"></svgicon>
                </template>
                <job-confirmation-panel v-if="isJobConfirmation" :heading="confirmationHeading" :label="confirmationLabel"
                    :lblSuccess="confirmationLblSuccess" :customOptions="actionObject.options" @onCancel="showPopOver = false"
                    @onSuccess="createEvent(selectType, eventName, $event);showPopOver = false">
                </job-confirmation-panel>

                <confirmation-panel v-else :heading="confirmationHeading" :label="confirmationLabel"
                    :lblSuccess="confirmationLblSuccess"  @onCancel="showPopOver = false"
                    @onSuccess="createEvent(selectType, eventName);showPopOver = false">
                </confirmation-panel>
            </v-menu>
        </span>
        <span v-else-if="isMultiActionOption">
            <v-menu   bottom absolute transition="scale-transition" slot="activator"  v-model="showPopOver"  close-on-content-click close-on-click>
                <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" slot="activator" :name="iconName" 
                    v-on="on" :original="true" :title="actionTitle">
                    </svgicon>
                </template>
                <v-list>
                    <v-list-item  dense v-for="(item, index) in actionObject.multiOptionActionList"
                    :key="index" @click="createEvent(selectType, item.event)" style="cursor:pointer">
                        <v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
                    </v-list-item>
            </v-list>
            </v-menu>
        </span>
        <span v-else :title="actionTitle">
            <svgicon class="svg-icon-grid svg-fill-grid" :name="iconName" 
                slot="activator" :original="true" :title="actionTitle" 
                @click="createEvent(selectType, eventName)">
            </svgicon>
        </span>
    </div>
</template>
<style scoped>

</style>
<script>
    import Confirmation from "./confirmation";
    import SuspendConfirm from "./SuspendConfirm";
    import '../../../compiled-icons';

    export default {
        name:'EdcActionIcon',
        components:{
            'confirmation-panel':Confirmation,
            'job-confirmation-panel':SuspendConfirm
        },
        props:{
            actionObject:{
                type:Object,
                default:()=>{
                    return {
                        eventName:'',
                        actionTitle:"",
                        selectType,
                        showConfirmation:false,
                        isConfirmationForJobMgmnt:false,
                        confirmationHeading:'',
                        confirmationLabel:'',
                        confirmationLblSuccess:"",
                        confirmationLblCancel:"",
                        itemList:[],
                        options:[]
                    }
                }
            },
        },
        data(){
            return {
                showPopOver:false
            }
        },
        computed:{
            isJobConfirmation(){
                return this.actionObject.isConfirmationForJobMgmnt
            },
            showConfirmation(){
                return this.actionObject.showConfirmation || this.isJobConfirmation
            },
            actionTitle(){
                return this.actionObject.actionTitle
            },
            actionSelectType(){
                return this.actionObject.selectType || 'single'
            },
            confirmationHeading(){
                return this.actionObject.confirmationHeading || 'Confirm'
            },
            confirmationLabel(){
                return this.actionObject.confirmationLabel || 'Are you sure?'
            },
            confirmationLblSuccess(){
                return this.actionObject.confirmationLblSuccess || 'Yes'
            },
            confirmationLblCancel(){
                return this.actionObject.confirmationLblCancel || 'Cancel'
            },
            isWithOptions(){
                return this.actionObject.itemList && this.actionObject.itemList.length > 0
            },
            iconName(){
                return this.actionObject.iconName
            },
            eventName(){
                return this.actionObject.eventName
            },
            selectType(){
                return this.actionObject.selectType || 'single'
            },
            isMultiActionOption(){
                return this.actionObject.multiOptionActionList && this.actionObject.multiOptionActionList.length > 0
            }
        },
        methods: {
            createEvent(selectType,eventName, suspendVal){
                this.$emit('createEvent',selectType, eventName, suspendVal)
            }
        },

    }
</script>