<template>
    <div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col><b>{{ jobStatus }}</b></v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col>{{ moreDetails }}</v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="3">Job Status</v-col>
                    <v-col class="EDC-Col" cols="3">Running job Row Status</v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col cols="3">
                        <v-progress-circular
                        :rotate="-90"
                        :size="200"
                        :width="progress_circle_width"
                        :value="getJobProcess"
                        color="teal"
                        >
                            {{ completedJobs }} / {{ totalJobs }}
                      </v-progress-circular>
                    </v-col>
                    <v-col cols="3">
                        <v-progress-circular
                        :rotate="-90"
                        :size="200"
                        :width="progress_circle_width"
                        :value="getCountProcess"
                        radius="30"
                        color="teal"
                        >
                            {{ rowProcessed }} / {{ totalRowsToProcess }}
                      </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col cols="6">
                        <vc-button
                        type="button"
                        item-text="Start"
                        :disabled="completedJobs > 0"
                        @click.native="performOperationOnMigration('/catalog_migration')"
                      />

                      <vc-button
                        type="button"
                        item-text="Pause"
                        :disabled="!isRunning"
                        @click.native="performOperationOnMigration('/suspend_catalog_migration')"
                      />

                      <vc-button
                        type="button"
                        item-text="Resume"
                        :disabled="!isStopped"
                        @click.native="performOperationOnMigration('/resume_catalog_migration')"
                      />

                      
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import _ from 'lodash';
import internal from 'stream';
    import config from '../../config.json'
    import { post as postToServer } from '../../methods/serverCall.js';

    export default{
        name:"Catalog Migration",
        data(){
            return{
                client_id:this.$session.get('client_id'),
                totalJobs:0,
                completedJobs:0,
                runningJobId:0,
                totalRowsToProcess:0,
                rowProcessed:0,
                runningJobStatus:'',
                moreDetails:'',
                progress_circle_width:24,
                isStopped:false,
                isRunning:false,
                jobStatus:''
            }
        },
        computed:{
            getJobProcess(){
                if(this.totalJobs === 0)
                    return 0
                else
                    return parseInt((this.completedJobs/this.totalJobs)*100)
            },
            getCountProcess(){
                if(this.totalRowsToProcess === 0)
                    return 0
                else
                    return parseInt((this.rowProcessed/this.totalRowsToProcess)*100)
            }
        },
        watch: {
            '$store.state.migrationInfo':{
                handler(newValue){
                this.manageStatus(newValue)
                }
            },
        },
        mounted(){
            this.getCatalogMigrationStatus()
        },
        methods:{
            getCatalogMigrationStatus(){
                var _this = this
                let data = {"client_id":_this.client_id}
                let finalUrl = config.MIGRATION_SERVICE_URL + '/fetch_catalog_migration_details'
                postToServer(_this,finalUrl, data).then(response=>{
                    _this.mapData(response)
                }).catch(error=>{
                    
                })
            },
            performOperationOnMigration(url){
                var _this = this
                let data = {"client_id":_this.client_id}
                let finalUrl = config.MIGRATION_SERVICE_URL + url
                postToServer(_this,finalUrl, data).then(response=>{

                }).catch(error=>{

                })
            },
            manageStatus(data){
                console.log(data)
                this.moreDetails = data.message
                var catalog_data = data.catalog_status_details
                this.mapData(catalog_data)
            },
            mapData(catalog_data){
                if(catalog_data){
                    this.jobStatus = catalog_data.status
                    this.isRunning = false
                    this.isStopped = false
                    if(this.jobStatus === 'running')
                        this.isRunning = true
                    if(this.jobStatus && this.jobStatus!='running')
                        this.isStopped = true
                    this.totalJobs =  catalog_data.total_job_count
                    this.completedJobs = catalog_data.completed_job_count
                    this.totalRowsToProcess = catalog_data.expected_count
                    this.rowProcessed = catalog_data.processed_count
                }
            }
        }
    }
</script>