<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                <v-col cols="4" class="EDC-Col">
                    <ul class="breadcrumb breadcrumbUL">
                    <router-link to="/Environmentlist">Environment List</router-link>
                    </ul>
                </v-col>
                <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col>
                <v-card>
                    <v-form v-model="formValid" ref="form">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col colPadding">
                                <v-row class="EDC-Row rowPadding">
                                    <v-col class="EDC-Col" cols="4">
                                        <v-text-field label="Name of Environment" style="padding-top:6px !important;" v-model="name" required :rules="namedRules" dense></v-text-field>
                                    </v-col>
                                    <v-col class="EDC-Col textAreaPadding" cols="8">
                                        <v-textarea label="Environment Description" v-model="description" rows=1 hide-details dense></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row class="EDC-Row">
                                    <v-col class="EDC-Col colPadding">
                                        <v-card class="cardPadding">
                                            <v-tabs class="EDC-Tab" v-model="tabIndex" non-linear>
                                                <v-tab class="EDC-Tabs" key="tab0">Properties</v-tab>
                                                <v-tab class="EDC-Tabs" key="tab1" v-if="showArchivist">Archivist</v-tab>
                                                <v-tab class="EDC-Tabs" key="tab2" v-if="showPathFinder">Pathfinder</v-tab>
                                                <v-tab-item class="tabItems style-2scroll" key="tabItem0">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col colPadding">
                                                            <v-row class="EDC-Row" style="padding-bottom:8px !important;">
                                                                
                                                            </v-row>
                                                            <!-- <v-divider class="EDC-Divider"></v-divider> -->
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col" cols="8">
                                                                    <v-row class="EDC-Row">
                                                                        <v-col class="EDC-Col colPadding" align="start">
                                                                            <label>Environment Can Be Use For</label>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <template v-for="(item) in availableProductListForEnv">
                                                                        <v-row class="EDC-Row" :key="item.productName" align="start">
                                                                            <v-col class="EDC-Col" cols="1"></v-col>
                                                                            <v-col class="EDC-Col colPadding" cols="3">
                                                                                <v-checkbox class="customCheckbox" v-model="item.isSelected" 
                                                                                :label="item.displayName" hide-details dense></v-checkbox>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                </v-col>
                                                            </v-row>
                                                            <v-divider class="EDC-Divider"></v-divider>
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col" cols="8">
                                                                    <v-row class="EDC-Row">
                                                                        <v-col class="EDC-Col colPadding" cols="2" align="start">
                                                                            <label>Options</label>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <template v-for="(envConfig) in envConfigForAll">
                                                                        <v-row class="EDC-Row" :key="envConfig.name" align="start">
                                                                            <v-col class="EDC-Col" cols="1"></v-col>
                                                                            <v-col class="EDC-Col colPadding" cols="3" :key="envConfig.name">
                                                                                <v-checkbox class="customCheckbox" v-model="envConfig.value" 
                                                                                :label="envConfig.display_name" hide-details dense>
                                                                                </v-checkbox>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item class="tabItems style-2scroll" v-if="showArchivist" key="tabItem1">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col colPadding">
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col colPadding" cols="4">
                                                                    <v-card class="cardPadding">
                                                                        <v-row class="EDC-Row">
                                                                            <v-col class="EDC-Col colPadding">
                                                                                <label style="float:left;font-weight:bold;" dense>Options</label>                                       
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="EDC-Row">
                                                                            <v-col class="EDC-Col colPadding">
                                                                                <template v-for="(item,indx) in archivistOptions">
                                                                                    <v-row class="EDC-Row" :key="item.name+' '+indx">
                                                                                        <v-col class="EDC-Col colPadding" align="start">
                                                                                            <label style="float:left;padding-right:8px;" dense>{{item.display_name}}</label>
                                                                                            <v-autocomplete style="bottom:4px !important;" v-model="item.selected_value" :items="item.items" 
                                                                                                hide-details row dense v-if="item.control_to_use==='dropdown'" item-text="name" item-value="id">
                                                                                            </v-autocomplete>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </template>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-divider class="EDC-Divider"></v-divider>
                                                                        <v-row class="EDC-Row">
                                                                            <v-col class="EDC-Col colPadding">
                                                                                <template v-for="(envConfig) in envConfigListForArchivist">
                                                                                    <v-row class="EDC-Row" :key="envConfig.name">
                                                                                        <v-col class="EDC-Col colPadding" align="start">
                                                                                            <label style="float:left;" dense>{{envConfig.display_name}}</label>
                                                                                            <!-- <v-checkbox class="customCheckbox" v-model="envConfig.value" 
                                                                                            :label="envConfig.display_name" hide-details dense>
                                                                                            </v-checkbox> -->
                                                                                            <v-radio-group v-model="envConfig.value" hide-details="auto" row style="padding-left:4px !important;">
                                                                                                <v-radio class="customCheckbox" :color="colorCode"  :key="index" label="Yes"  :value="true">
                                                                                                </v-radio>
                                                                                                <v-radio class="customCheckbox" :color="colorCode"  :key="index" label="No"  :value="false">
                                                                                                </v-radio>
                                                                                            </v-radio-group>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </template>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col class="EDC-Col colPadding" cols="4">
                                                                    <v-card class="cardPadding">
                                                                        <v-row class="EDC-Row">
                                                                            <v-col class="EDC-Col colPadding">
                                                                                <label style="float:left" dense>Datasources</label>                                       
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="EDC-Row">
                                                                            <v-col class="EDC-Col colPadding">
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" item-value="datasource_id"  
                                                                                        v-model="source_data_source" :rules="requiredSourceDatasource" 
                                                                                        label="Select Source Datasource" 
                                                                                        dense @click:clear="clearOtherSelectedDatasources" @input="clearOtherSelectedDatasources();getDBLocation()">
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" item-value="datasource_id" v-model="engine_data_source" 
                                                                                        :rules="requiredEngineDatasource" label="Select Source Engine Datasource" 
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" item-value="datasource_id"  v-model="target_data_source" 
                                                                                        :rules= "requiredTargetDatasource" label="Select Target Datasource" 
                                                                                        @input="getDBLocation();GetOracleTableSpace($event)" dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" item-value="datasource_id"  
                                                                                        v-model="target_engine_data_source" 
                                                                                        :rules= "requiredTargetEngineDatasource" 
                                                                                        label="Select Target Engine Datasource" 
                                                                                        dense 
                                                                                        :disabled='disabledTargetEngine'>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete 
                                                                                        :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" 
                                                                                        item-value="datasource_id"  :rules="catalogRules"
                                                                                        v-model="catalog_data_source" 
                                                                                        label="Select Catalog Datasource"
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete 
                                                                                        :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" 
                                                                                        item-value="datasource_id" 
                                                                                        v-model="reporting_table_ds_id" 
                                                                                        label="Select Reporting Table Datasource"
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete 
                                                                                        :items="acceleratorList" 
                                                                                        item-text="accelerator_name" 
                                                                                        item-value="id"
                                                                                        v-model="archivistAcceleratorId" 
                                                                                        label="Select Accelerator (Optional)"
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete 
                                                                                        :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" 
                                                                                        item-value="datasource_id" 
                                                                                        v-model="create_view_ds_1_id" 
                                                                                        label="Create View On Datasource 1"
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-autocomplete class="autocompletePadding" clearable autocomplete 
                                                                                        :items="availableDatasourceListByDBType" 
                                                                                        item-text="display_text" 
                                                                                        item-value="datasource_id" 
                                                                                        v-model="create_view_ds_2_id" 
                                                                                        label="Create View On Datasource 2"
                                                                                        dense>
                                                                                        </v-autocomplete>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col class="EDC-Col colPadding" cols="4">
                                                                    <v-card class="cardPadding" v-if="isOracleDB || isDB2i">
                                                                        <v-row class="EDC-Row" v-if="isOracleDB">
                                                                            <v-col class="EDC-Col">
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <label style="float:left" dense>Oracle Options</label>                                       
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-row class="EDC-Row">
                                                                                            <v-col class="EDC-Col colPadding">
                                                                                                <v-combobox class="autocompletePadding" clearable autocomplete :items="tableSpaceList" item-text="name" item-value="name" v-model="selectedTableSpace"  
                                                                                                label="Select Table Tablespace" :return-object="false"
                                                                                                dense>
                                                                                                </v-combobox>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row class="EDC-Row">
                                                                                            <v-col class="EDC-Col colPadding">
                                                                                                <v-combobox class="autocompletePadding" clearable autocomplete :items="tableSpaceList" item-text="name" item-value="name" v-model="selectedIndexSpace" 
                                                                                                label="Select Index Tablespace" :return-object="false" dense>
                                                                                                </v-combobox>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row class="EDC-Row colPadding" v-if="showOptionForDBLink">
                                                                                            <v-col class="EDC-Col colPadding">
                                                                                                <v-row clas="EDC-Row">
                                                                                                    <v-col class="EDC-Col colPadding">
                                                                                                        <v-text-field v-model="SourceToTargetDbLinkValue" :rules="SourceToTargetDbLinkRequired" 
                                                                                                        label="Source To Target DB Link" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                                <v-row clas="EDC-Row">
                                                                                                    <v-col class="EDC-Col colPadding">
                                                                                                        <v-text-field :rules="TargetToSourceDbLinkRequired" v-model="TargetToSourceDbLinkValue" 
                                                                                                        label="Target To Source DB Link" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="EDC-Row" v-if="isDB2i">
                                                                            <v-col class="EDC-Col">
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <label style="float:left" dense>Journaling Options</label>                                       
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="EDC-Row">
                                                                                    <v-col class="EDC-Col colPadding">
                                                                                        <v-row class="EDC-Row">
                                                                                            <v-col class="EDC-Col">
                                                                                                <v-row class="EDC-Row">
                                                                                                    <v-col class="EDC-Col colPadding" cols="6">
                                                                                                        <label style="float:left;" dense>Start Table Journaling In</label>
                                                                                                    </v-col>
                                                                                                    <v-col class="EDC-Col" style="padding-bottom:8px !important;">
                                                                                                        <v-autocomplete :items="iseriesJournalStartOptions" v-model="iseriesJornalStartOn" dense hide-details
                                                                                                        item-text="name"  item-value="id">
                                                                                                        </v-autocomplete>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row class="EDC-Row" v-if="iseriesJornalStartOn !='none'">
                                                                                            <v-col class="EDC-Col colPadding">
                                                                                                <v-row class="EDC-Row">
                                                                                                    <v-col class="EDC-Col" cols="6" style="padding-right:8px !important;">
                                                                                                        <v-text-field name="input-7-1" label="Source Journal Library" v-model="iseriesJournalSourceSchema" 
                                                                                                        :rules="iseriesJournalSourceRule" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                    <v-col class="EDC-Col" cols="6">
                                                                                                        <v-text-field name="input-7-1" label="Source Journal Name" v-model="iseriesJournalSourceName" 
                                                                                                        :rules="iseriesJournalSourceNameRule" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                                <v-row class="EDC-Row">
                                                                                                    
                                                                                                </v-row>
                                                                                                <v-row class="EDC-Row">
                                                                                                    <v-col class="EDC-Col" cols="6" style="padding-right:8px !important;">
                                                                                                        <v-text-field name="input-7-1" label="Target Journal Library" v-model="iseriesJournalTargetSchema" 
                                                                                                        :rules="iseriesJournalTargetRule" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                    <v-col class="EDC-Col">
                                                                                                        <v-text-field name="input-7-1" label="Target Journal Name" v-model="iseriesJournalTargetName"
                                                                                                        :rules="iseriesJournalTargetNameRule" dense></v-text-field>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item class="tabItems style-2scroll" v-if="showPathFinder" key="tabItem2">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col colPadding">
                                                            <v-card class="cardPadding">
                                                                <v-row class="EDC-Row" v-if="showArchivist">
                                                                    <v-col class="EDC-Col colPadding" align="left">
                                                                            <v-checkbox class="customCheckbox" v-model="useArchivistDSForPathfinder" 
                                                                            label="Use Archivist Datasources For The Pathfinder" 
                                                                            hide-details dense></v-checkbox>
                                                                    </v-col>
                                                                </v-row>
                                                                <template v-for="(item,index) in pathfinderEnvDetails">
                                                                    <v-row class="EDC-Row" :key="index+'dv'">
                                                                        <v-col cols="4" class="EDC-Col colPadding">
                                                                            <v-autocomplete clearable autocomplete :items="availableAllDatasourceList" item-text="display_text" 
                                                                            item-value="datasource_id"  v-model="item.selectedDatasource" label="Select Datasource" dense></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="4" class="EDC-Col colPadding">
                                                                            <v-autocomplete clearable autocomplete :items="acceleratorList" item-text="accelerator_name" 
                                                                            item-value="id"  v-model="item.acclerator_id"  label="Select Accelerator (Optional)" dense></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="2" class="EDC-Col colPadding" align="left">
                                                                            <svgicon class="svg-icon-grid svg-fill-grid" v-if="(index+1) === pathfinderEnvDetails.length"  name="add_v2" title="Add New Datasource" @click="AddNewDatasource()"></svgicon>
                                                                            <svgicon class="svg-icon-grid svg-fill-grid" v-else name="delete_v2" title="Delete Datasource" @click="RemoveDatasource(index)"></svgicon>
                                                                        </v-col>
                                                                    </v-row>
                                                                </template>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                            </v-tabs>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row class="EDC-Row">
                                    <v-col class="EDC-Col colPadding" align="end">
                                        <vc-button :loading="loadingForValidation" :disabled="loadingForValidation || !isAnyProductSelected" itemText="Validate" @click.native="performEnvironmentAction('validate')"></vc-button>
                                        <vc-button itemText="Save" @click.native="performEnvironmentAction" :disabled="!isAnyProductSelected"></vc-button>
                                        <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        <loading-panel :loader="loader"></loading-panel> 
    </v-container>
</template>
<style scoped>
    .customCheckbox >>> label{
        top: 5px !important;
    }
    .colPadding{
        padding-left:8px !important;
        padding-right:8px !important;
        padding-top:4px !important;
    }
    .autocompletePadding{
        padding-bottom: 0px !important;
    }
    .rowPadding{
        padding-left:16px !important;
    }
    .cardPadding{
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .tabItems{
        height:56vh !important;
        overflow-y:auto;
    }
    .textAreaPadding{
        padding-left:16px !important;
        padding-right:8px !important;
    }
</style>
<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import {GETENVIRONMENTDETAILS, GETDATASOURCEBYBDTYPE, CATALOG_DATA_SOURCE_BY_CLIENT_ID, ADDENVIRONMENT, UPDATE_ENVIRONMENT, GET_ALL_ENV_CONFIG, GET_ALL_AGENT_MASTER,VALIDATE_ENVIRONMENT} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR, CHECKBOX_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {Archivist_Product,PathFinder_Product} from '../../constants/constants.js'

export default {
    name:"EdcManageEnvironment",
    components:{

    },
    data(){
        return{
            clientId:this.$session.get('client_id'),
            name:"Untitled Environment",
            environmentId:'',
            namedRules:[
                v => !!v || 'Name is required'
            ],
            description: "",
            availableProductListForEnv:[],
            envConfigListForArchivist:[],
            availableAllDatasourceList:[],
            envConfigForAll:[],
            // availableDatasourceListByDBType:[],
            archivistOptions:[],
            archivistConfig:[],
            outlineColor:BTN_COLOR,
            colorCode:COLOR_CODE,
            checkboxColor:CHECKBOX_COLOR,
            tabIndex:0,
            acceleratorList:[],
            archivistAcceleratorId:'',
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            snackbartimeout: Snackbar_Default_Timeout,
            loader: false,
            loading: false,
            validation_loding:false,
            database_type:"",
            db_locations:'',
            source_data_source:"",
            target_data_source:"",
            engine_data_source:"",
            target_engine_data_source: "",
            catalog_data_source:"",
            reporting_table_ds_id: "",
            create_view_ds_1_id: "",
            create_view_ds_2_id:"",
            catalogRules:[
                v => !!v || 'Catalog Datasource is required'
            ],
            requiredSourceDatasource:[
                v => !!v || 'Source Datasource is required',
                (v) => v != this.target_data_source || 'Source and Target datasource must be different!',
                (v) => v != this.engine_data_source || 'Source and Engine datasource must be different!',
            ],
            requiredTargetDatasource:[
                v => !!v || 'Target Datasource is required',
                (v) => v != this.source_data_source || 'Target and Source datasource must be different!',
                (v) => v != this.engine_data_source || 'Target and Engine datasource must be different!',
            ],
            requiredEngineDatasource:[
                v => !!v || 'Engine Datasource is required',
                (v) => v != this.target_data_source || 'Engine and Target datasource must be different!',
                (v) => v != this.source_data_source || 'Engine and Source datasource must be different!'
            ],
            requiredTargetEngineDatasource: [
                v => !!v || 'Target Engine Datasource is required',
                (v) => v != this.target_data_source || 'Target Engine and Target datasource must be different!',
                (v) => v != this.source_data_source || 'Target Engine and Source datasource must be different!'
            ],
            SourceToTargetDbLinkRequired:[
                v => !!v || 'Source to target dblink required'
            ],
            TargetToSourceDbLinkRequired:[
                v => !!v || 'Target to source dblink required'
            ],
            tableSpaceList:[],
            selectedTableSpace:'',
            selectedIndexSpace:'',
            iseriesJournalStartOptions:[
                {"name":"Proof & Final Mode","id":"any_mode"},
                {"name":"Only Final Mode","id":"only_final"},
                {"name":"Never","id":"none"}
            ],
            iseriesJornalStartOn:"any_mode",
            iseriesJournalSourceSchema:"",
            iseriesJournalTargetSchema:"",
            iseriesJournalSourceName:"",
            iseriesJournalTargetName:"",
            iseriesJournalSourceRule:[
                v => !!v || 'Source journal schema required'
            ],

            iseriesJournalTargetRule:[
                v => !!v || 'Target journal schema required'
            ],

            iseriesJournalSourceNameRule:[
                v => !!v || 'Source journal name required'
            ],

            iseriesJournalTargetNameRule:[
                v => !!v || 'Target journal name required'
            ],
            disabledTargetEngine:false, // we are going to disabled target engine, when database location is same_database for all database or save_server in case of ms_sql
            SourceToTargetDbLinkValue : '',
            TargetToSourceDbLinkValue: '',
            envObj:{
    			"selectedDatasource":"",
    			"DatasourceTempSpace":"",
    			"acclerator_id":"",
    			"datasource_id":""
    		},
            pathfinderEnvDetails:[],
            formValid:false,
            loadingForValidation:false,
            databaseDisplayDict:{
                "ms_sql":"MSSQL",
                "oracle":"ORACLE",
                "db2i":"DB2i"
            },
            useArchivistDSForPathfinder:true
        }
    },
    computed:{
        selectedProducts(){
            return _.filter(this.availableProductListForEnv,(obj)=>{
                return obj.isSelected === true
            })
        },
        showArchivist(){
            let archivistObj = this.findObjectByKey(Archivist_Product.toLowerCase(),this.selectedProducts,'productName')
            if(archivistObj)
                return true
            return false
        },
        showPathFinder(){
            let archivistObj = this.findObjectByKey(PathFinder_Product.toLowerCase(),this.selectedProducts,'productName')
            if(archivistObj)
                return true
            return false
        },
        isAnyProductSelected(){
            return this.selectedProducts.length > 0
        },
        availableDatasourceListByDBType(){
            var _this = this
            var availableDatasourceListByDBType = _.filter(_this.availableAllDatasourceList,(obj)=>{
                return !_this.database_type || obj.database_type === _this.database_type
            })
            return availableDatasourceListByDBType
        },
        isOracleDB(){
            return this.database_type === 'oracle'
        },
        showOptionForDBLink(){
            return this.isOracleDB && this.db_locations && this.db_locations != "same_database"
        },
        isDB2i(){
            return this.database_type ==='db2i'
        },
        isValidEntry(){
            if(this.pathfinderEnvDetails.length === 0)
                return false
            if(!this.pathfinderEnvDetails[this.pathfinderEnvDetails.length-1].selectedDatasource)
                return false
            return true
        }
    },
    watch:{
        'source_data_source':{
            handler(newValue){
                if(newValue){
                    let selectedDS = this.findObjectByKey(newValue)
                    if(selectedDS)
                        this.database_type = selectedDS.database_type
                } else{
                    this.database_type = ''
                }
            }
        }
    },
    mounted(){
        this.manageProductList()
        this.getDatasources()
        this.getErpAcceleratorList()
        this.getArchivistSetting()
        if(!this.$route.params.environment_id){
            this.getOptions()
            this.pathfinderEnvDetails.push(_.cloneDeep(this.envObj))
        } else{
            if(this.$route.params.type === 'edit'){
                this.environmentId = this.$route.params.environment_id
            }
            this.getEnvironmentDetailsById(this.$route.params.environment_id,this.$route.params.type)
        }
    },
    methods:{
        manageProductList(){
            var _this = this
            let allProducts = this.$session.get('applications_configuration')
            _.forEach(allProducts, (obj)=>{
                let product_name = obj.product_name.toLowerCase()
                if([Archivist_Product.toLowerCase(),PathFinder_Product.toLowerCase()].indexOf(product_name) >-1){
                    obj.displayName = product_name.toLowerCase().charAt(0).toUpperCase()+product_name.slice(1)
                    obj.productName = product_name
                    obj.isSelected = true
                    _this.availableProductListForEnv.push(obj)
                }
            })
        },
        showError(error_message){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_message || SERVER_ERROR;
        },
        getDatasources(){
            var _this = this
            var user_input = {
                'client_id': this.clientId
            }
            let url = config.DATA_SOURCE_URL + GETDATASOURCEBYBDTYPE
            postToServer(this, url, user_input).then(datasourceResponse => {
                _this.availableAllDatasourceList = _.map(datasourceResponse.datasource,function(element){
                    return _.extend({}, element, {"display_text":element.datasource_name +' ('+_this.databaseDisplayDict[element.database_type]+')'})
                })
            }).catch(error=>{
                _this.showError(error)
            })
        },
        clearOtherSelectedDatasources(){
            // database type is decided by the source datasource. In case of user select other datasource first
            // and then select source , then there might be chance of mismatch selection.
            // TODO: We can do more simplfication by checking is the other datasource db type same or not. If not then clear it.
            this.target_data_source = ""
            this.engine_data_source = ""
            this.target_engine_data_source = ""
            this.catalog_data_source = ""
            this.reporting_table_ds_id = ""
            create_view_ds_1_id = ""
            create_view_ds_2_id =""
            this.disabledTargetEngine = false
        },
        getDBLocation(){
            // this function called on source engine change aswell in case user select source engine after target
            var _this = this
            if(!this.source_data_source || !this.target_data_source)
                return
            _this.disabledTargetEngine = false
            let sourceData = _this.findObjectByKey(this.source_data_source)
            let targetData  = _this.findObjectByKey(this.target_data_source)
            
            let data = {"source_datasource":sourceData,"target_datasource":targetData}
            let url = config.ENVIRONMENT_API_URL +'/get_db_location'
            postToServer(_this,url,data).then(response=>{
                // alert(response)
                _this.db_locations = response
                if(response === 'same_database' || (response === 'same_server' && _this.database_type === 'ms_sql')){
                    _this.disabledTargetEngine = true
                    _this.target_engine_data_source = _this.engine_data_source
                }
            }).catch(error=>{
                alert(error)
                _this.showError(error)
            })
        },
        getErpAcceleratorList() {
            let _this = this;
            var data = {"client_id": this.clientId}
            _this.acceleratorList = []
            postToServer(_this, config.JDE_FORMATER_URL + "/get_all_accelerator_by_client_id" ,data).then(response => {
                if (response) {	
                    console.log(response)					
                    response = _.sortBy(response,"id")
                    _this.acceleratorList = response;
                }
            }).catch(error_response => {
                // _this.showError(error_response)
            });
        },
        findObjectByKey(valueToSearch, listInSearch, keyToSearch='datasource_id'){
            if(!listInSearch)
                listInSearch = this.availableAllDatasourceList
            let searchObj = _.find(listInSearch,[keyToSearch,valueToSearch])
            return searchObj
        },
        addKeyPairToArrayOfObject(arrayList,keyPair){
            let newArrayList = _.map(arrayList, function(element) { 
                return _.extend({}, element, keyPair);
            });
            return newArrayList
        },
        getArchivistSetting(selectedOptions){
            var _this = this
            let archivistProd = _.find(this.availableProductListForEnv, ['productName',Archivist_Product.toLowerCase()])
            
            if (!archivistProd)
                archivistProd=_.find(this.availableProductListForEnv, ['productName',Archivist_Product])

            if(!archivistProd || !archivistProd.isSelected)
                return
            let url = config.ENVIRONMENT_API_URL +'/get_archivist_setting'
            getToServer(_this, url).then(response=>{
                if(selectedOptions){
                    for(let i=0;i<response.length;i++){
                        let currentConfig = response[i]
                        let savedValue = _this.findObjectByKey(currentConfig.name,selectedOptions,'name')
                        if(savedValue)
                            currentConfig['selected_value'] = savedValue.selected_value
                    }
                }
                _this.archivistOptions = response
            }).catch(error=>{

            })
        },
        getOptions(){
            var _this = this
            let selectedProducts= _.map(_this.selectedProducts,'productName')
            if(!selectedProducts || !selectedProducts.length)
                return
            selectedProducts.push('all')
            let data = {"product_name_list":selectedProducts}
            let url = config.ENVIRONMENT_API_URL +'/get_env_configuration'
            postToServer(_this, url,data).then(response=>{
                _this.divideOptions(response)
                
            }).catch(error=>{

            })
        },
        divideOptions(response){
            var _this = this
            if(_this.envConfigForAll.length === 0){
                let envConfigForAll = _this.findObjectByKey('all',response,'product_name')
                if(envConfigForAll){
                    _this.envConfigForAll = _this.addKeyPairToArrayOfObject(envConfigForAll.data, {value:true})
                }
            }
            if(_this.envConfigListForArchivist.length === 0){
                let archivistConfig = _this.findObjectByKey('archivist',response,'product_name')
                if(archivistConfig){
                    _this.envConfigListForArchivist = _this.addKeyPairToArrayOfObject(archivistConfig.data, {value:false})
                }
            }
        },
        GetOracleTableSpace(selected_id){
            if(this.database_type !="oracle")
            return
            var data = {
                "db_type":this.database_type, 
                "datasource_id":selected_id, 
                "client_id":this.clientId
            }
            var end_point_for_tablespace = config.AGENT_API_URL +'/get_tablespace_list'
            postToServer(this, end_point_for_tablespace, data).then(response => {
                this.tableSpaceList = response.tablespace_list
            }).catch(environmentError => {
                // this.showError('Error occured while fetching the tablespace names')
            })
        },
        getEnvironmentDetailsById(env_id,action){
            var _this = this
            var data = {"client_id":this.clientId, "env_id":env_id}
            let url = config.ENVIRONMENT_API_URL +'/get_environment_data'
            _this.loader = true
            postToServer(_this, url, data).then(response=>{
                _this.loader = false
                let environmentFor = response.environment_for
                for(let i=0;i<_this.availableProductListForEnv.length;i++){
                    let product = _this.availableProductListForEnv[i]
                    let isExists = _this.findObjectByKey(product.product_name,environmentFor,'product_name')
                    if(!isExists)
                        product.isSelected = false
                }
                let commonData = response.common_data
                _this.name = commonData['env_name']
                if(action === 'copy')
                    _this.name = 'Copy of '+commonData['env_name']
                _this.description = commonData['env_description']

                _this.envConfigForAll = _.filter(commonData.env_config,(obj)=>{
                    return obj.product_name === 'all'
                })

                _this.envConfigListForArchivist = _.filter(commonData.env_config,(obj)=>{
                    return obj.product_name === 'archivist'
                })

                let archivistData = response.archivist_data
                if(archivistData){
                    _this.database_type = archivistData.db_type
                    _this.db_locations = archivistData.db_location
                    _this.source_data_source = archivistData.source_data
                    _this.target_data_source = archivistData.target_data
                    _this.GetOracleTableSpace(archivistData.target_data)
                    _this.engine_data_source = archivistData.engine_database
                    _this.target_engine_data_source = archivistData.target_engine_data
                    _this.catalog_data_source = archivistData.catalog_data
                    _this.reporting_table_ds_id = archivistData.reporting_table_ds_id
                    _this.create_view_ds_1_id = archivistData.create_view_ds_1_id
                    _this.create_view_ds_2_id =  archivistData.create_view_ds_2_id
                    _this.useArchivistDSForPathfinder = archivistData.can_use_in_pathfinder?true:false
                    _this.getOptions()
                    // _this.getDBLocation()
                    _this.getArchivistSetting(archivistData.more_settings)
                    if(archivistData.accelerator_id)
                        _this.archivistAcceleratorId = parseInt(archivistData.accelerator_id)
                    if(_this.isOracleDB){
                        _this.selectedTableSpace = archivistData.table_tablespace
                        _this.selectedIndexSpace = archivistData.index_tablespace
                        _this.SourceToTargetDbLinkValue = archivistData.source_to_target_dblink
                        _this.TargetToSourceDbLinkValue = archivistData.target_to_source_dblink
                    }
                    let journalDetails = response.journal_details
                    if(journalDetails){
                        this.iseriesJornalStartOn = journalDetails.start_journaling_on
                        this.iseriesJournalSourceSchema = journalDetails.source_jrn_schema_name
                        this.iseriesJournalTargetSchema = journalDetails.target_jrn_schema_name
                        this.iseriesJournalSourceName = journalDetails.source_jrn_name
                        this.iseriesJournalTargetName = journalDetails.target_jrn_name
                    }
                }
                let pathfinderData =  response.pathfinder_data
                if(pathfinderData){
                    this.pathfinderEnvDetails = pathfinderData
                }
                else{
                    _this.pathfinderEnvDetails.push(_.cloneDeep(_this.envObj))
                }
            }).catch(error=>{
                _this.showError(error)
            })
        },
        //////////////////////// dataviewer functions
        AddNewDatasource(){
            if(this.isValidEntry)
                this.pathfinderEnvDetails.push(_.cloneDeep(this.envObj))
        },
		RemoveDatasource(index){
		    this.pathfinderEnvDetails.splice(index, 1)
			if(!this.pathfinderEnvDetails.length)
			    this.AddNewDatasource()
		},
        getPathFinderEnvData(){
            if(this.pathfinderEnvDetails.length ===1 && !this.pathfinderEnvDetails[0]['selectedDatasource'])
                return []
            // remove last row if its not have any selected value
			if(!this.pathfinderEnvDetails[this.pathfinderEnvDetails.length-1].selectedDatasource)
				this.pathfinderEnvDetails.splice(this.pathfinderEnvDetails.length-1,1)


			for(let i=0;i<this.pathfinderEnvDetails.length;i++){
				this.pathfinderEnvDetails[i].datasource_id = this.pathfinderEnvDetails[i].selectedDatasource
			}
            return this.pathfinderEnvDetails
        },
        /////////////////////// button functions
        getArchivistData(){
            var _this = this
            let archivistData = {}
            if(_this.showArchivist){
                let moreSetting = []
                for(let i=0;i<this.archivistOptions.length;i++){
                    moreSetting.push({"name":this.archivistOptions[i].name,
                    "selected_value":this.archivistOptions[i].selected_value})
                }
        
                archivistData ={
                    "db_type":this.database_type,
                    "db_location":this.db_locations,
                    "source_ds_id":this.source_data_source,
                    "source_engine_ds_id":this.engine_data_source,
                    "target_ds_id":this.target_data_source,
                    "reporting_table_ds_id":this.reporting_table_ds_id,
                    "target_engine_ds_id":this.target_engine_data_source,
                    "catalog_ds_id":this.catalog_data_source,
                    "create_view_ds_1_id" : this.create_view_ds_1_id,
                    "create_view_ds_2_id" :  this.create_view_ds_2_id,
                    "accelerator_id":this.archivistAcceleratorId,
                    "more_settings":moreSetting,
                    "can_use_in_pathfinder":this.useArchivistDSForPathfinder && this.showArchivist?true:false
                }
                if(this.isOracleDB){
                    archivistData['source_to_target_dblink'] = this.SourceToTargetDbLinkValue
                    archivistData['target_to_source_dblink']= this.TargetToSourceDbLinkValue
                    archivistData['table_tablespace'] = this.selectedTableSpace
                    archivistData['index_tablespace'] = this.selectedIndexSpace
                }
                else if(this.isDB2i){
                    archivistData["journal_details"] = {
                        "start_journaling_on":this.iseriesJornalStartOn,
                        "source_jrn_schema_name":this.iseriesJournalSourceSchema,
                        "target_jrn_schema_name": this.iseriesJournalTargetSchema,
                        "source_jrn_name":this.iseriesJournalSourceName,
                        "target_jrn_name":this.iseriesJournalTargetName
                    }
                }
            }
            return archivistData
        },
        getPathfinderData(){
            var _this = this
            let pathfinderEnvData = []
            if(this.showPathFinder){
                pathfinderEnvData = this.getPathFinderEnvData()
                if(!this.showArchivist && pathfinderEnvData.length === 0){
                    _this.showError('Select atleast single datasource')
                    throw 'Select atleast single datasource'
                }
            }
            return pathfinderEnvData
        },
        getEnvironmentData(){
            var _this = this
            let environmentData = {
                "common_data":{
                    "env_name":this.name,"env_description":this.description,
                    'env_config': this.envConfigForAll.concat(this.envConfigListForArchivist)
                },
                "environment_for":this.selectedProducts,
                "client_id":this.clientId,
                'created_by': this.$session.get('email'),
                'type': 'test',
                "archivist_data":_this.getArchivistData(),
                "pathfinder_data":_this.getPathfinderData()
            }
            return environmentData
        },
        performEnvironmentAction(action){
            var _this = this
            if (!_this.$refs.form.validate())
                return
            let environmentData = _this.getEnvironmentData()
            let url = config.ENVIRONMENT_API_URL +'/add_environment'
            if (action === 'validate'){
                url = config.ENVIRONMENT_API_URL + VALIDATE_ENVIRONMENT
                environmentData['is_from_ui'] = true
                _this.loadingForValidation = true
            }
            else if(this.environmentId){
                url = config.ENVIRONMENT_API_URL +'/edit_environment'
                environmentData['env_id'] = this.environmentId
            }
            _this.loader = true
            postToServer(_this, url,environmentData).then(response=>{
                _this.loader = false
                _this.loadingForValidation = false
                if (action === 'validate'){
                    _this.snackbar = true
                    _this.colorValue = 'success'
                    _this.snackbartext = response;
                } else
                    this.$router.push("/Environmentlist")
            }).catch(error=>{
                _this.loadingForValidation = false
                _this.loader = false
                _this.showError(error)
            })
        },
        cancel(){
            this.$refs.form.reset()
            this.$router.push('/Environmentlist')
        }
    }
}
</script>