<template lang="html">
	<div>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="12">
        		<v-text-field  v-model="text_clock_model" v-show="!showClockSelection" :label="label" append-icon="access_time" @keydown="validateTime" @click:append="showClockClicked" @blur="ValidateTimeOnBlur" autocomplete="off" dense hide-details></v-text-field>
                
                <!-- :menu-props="{value: open_clocklist,closeOnClick: true, closeOnContentClick: true,openOnClick: true}" -->
        		<v-autocomplete autocomplete="off" v-show="showClockSelection" v-model="text_clock_model" :label="label" cache-items
        		:items="defaultClockValues" @click:append="showClockClicked" append-icon="access_time" @blur="ValidateTimeOnBlur" ref="timeselection" dense hide-details></v-autocomplete>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import {
    TWENTY_FOUR_HOURS_CLOCK
} from '../data/metaData.js'
export default {
  name: 'Timer',
  data() {
    return {
       text_clock_model:this.inputtime,
       defaultClockValues:TWENTY_FOUR_HOURS_CLOCK,
       open_clocklist:false,
       showClockSelection:false,
    }
  },
  props: ['inputtime','label'],
  watch:{
   inputtime(newvalue){
   	this.text_clock_model = newvalue
   },
   text_clock_model(newvalue)
   {
   	this.updateTimeControl()
   },
   label(newvalue){

   },
  },
  mounted() {
        let _this = this;
        var timeselection = this.$refs.timeselection.$el
        if(!timeselection)
          timeselection = this.$refs.timeselection
        timeselection.addEventListener("keydown", OnTimeSelectionKeyPress);
        function OnTimeSelectionKeyPress(e) {
            // if user going to type time we need to change dropdown to textbox
             _this.showClockSelection = false
             _this.open_clocklist = false
             e.target.focus()
        }
    },
  methods: {
  	showClockClicked(){
  		this.showClockSelection = !this.showClockSelection 
  		this.open_clocklist = this.showClockSelection
  	},
  	validateTime(e){
            /* keycodes
             8 - backspace 
             9 - tab
             16- shift
             46- delete
             186-: */

             

             var allowed_keycode_array = [9,16,46,77,186]
             var already_entered_value = this.text_clock_model
             var length = already_entered_value.length?already_entered_value.length:0


            // allow backspace,tab,delete
            if(e.keyCode === 8 || e.keyCode ===9 || e.keyCode === 46)
            	return true
            if(length>=5){
            	e.preventDefault()
            	return
            }
            if(Number.isInteger(parseInt(e.key)) || allowed_keycode_array.indexOf(e.keyCode) >=0){

                // 1st dont allow to press any key other than number if input length is 0
                if(length === 0 && !Number.isInteger(parseInt(e.key)))
                {
                	e.preventDefault()
                	return false
                }

                if(length === 1){
                    // user press colon after single digit
                    if(e.keyCode === 186){
                    	this.text_clock_model = "0"+already_entered_value+":"
                    	e.preventDefault()
                    	return
                    }
                    // // user press A or P after single digit
                    // if([65,80].indexOf(e.keyCode) >=0){
                    // 	if (already_entered_value === "0")
                    // 		already_entered_value = "1"
                    // 	this.text_clock_model = "0"+already_entered_value+":"+"00"+" "+_.capitalize(e.key)+"M"
                    // 	e.preventDefault()
                    // 	return
                    // }

                    // now check newly created number should be less than 12
                    already_entered_value = parseInt(already_entered_value + e.key)
                    if(already_entered_value >= 24){
                    	e.preventDefault()
                    	return
                    }                    
                }
                else if(length === 2){
                    // add : automatically
                    // already_entered_value = already_entered_value +":"
                    // user press A or P after two digit
                    // if([65,80].indexOf(e.keyCode) >=0){
                    // 	this.text_clock_model = already_entered_value+"00"+" "+_.capitalize(e.key)+"M"
                    // 	e.preventDefault()
                    // 	return
                    // }
                    if(e.key === ":")
                        return
                    // this.text_clock_model = this.text_clock_model + ":"
                    if(["0","1","2","3","4","5"].indexOf(e.key) >= 0){
                        this.text_clock_model = this.text_clock_model + ":"+ e.key
                    }
                    e.preventDefault()
                }

                else if(length === 3){
                    // user press A or P after two digit
                    // if([65,80].indexOf(e.keyCode) >=0){
                    // 	this.text_clock_model = already_entered_value+"00"+" "+_.capitalize(e.key)+"M"
                    // 	e.preventDefault()
                    // 	return
                    // }
                    // Dont allow any other key at position 4
                    if(["0","1","2","3","4","5"].indexOf(e.key) < 0){
                    	e.preventDefault()
                    	return
                    }
                }
                else if(length === 4){
                    // user press A or P after one digit
                    // if([65,80].indexOf(e.keyCode) >=0){
                    // 	var append_zero = already_entered_value.slice(0, 3) + "0" + already_entered_value.slice(3)
                    // 	this.text_clock_model =append_zero+" "+_.capitalize(e.key)+"M"
                    // 	e.preventDefault()
                    // 	return
                    // }
                    // allow only digit
                    if(["1","2","3","4","5","6","7","8","9","0"].indexOf(e.key) < 0){
                    	e.preventDefault()
                    	return
                    }
                }
                // else if(length === 5){
                //     // user press A or P after minute digit
                //     if([65,80].indexOf(e.keyCode) >=0){
                //     	this.text_clock_model = already_entered_value+" "+_.capitalize(e.key)+"M"
                //     }
                //     else{
                //         // Add Space automatically
                //         this.text_clock_model = already_entered_value+" "
                //     }
                //     e.preventDefault()
                //     return
                // }
                // else if(length > 5){
                // 	if([65,80].indexOf(e.keyCode) >=0){
                // 		this.text_clock_model = already_entered_value+_.capitalize(e.key)+"M"
                // 	}

                // 	e.preventDefault()
                // 	return
                // }
            }
            else{
                // dont allow to press key
                e.preventDefault()
                return
            }

        },
        ValidateTimeOnBlur(){
        	this.text_clock_model = this.text_clock_model.trim()
            // should be proper date.It should be in 8(With AM/PM) Or 5 (without AM PM)
            if(this.text_clock_model.length > 5){
            	this.text_clock_model = ''
            	return
            }

            if(this.text_clock_model.length > 2 && !this.text_clock_model.includes(":")){
                this.text_clock_model = ''
                return
            }
            // split by colon
            var split_by_colon = this.text_clock_model.split(':')

            var first_el = parseInt(split_by_colon[0])
            if(split_by_colon.length !=2){

                if(first_el <=9 && split_by_colon[0].length === 1)
                    this.text_clock_model = "0"+split_by_colon[0]+":00"
                else if(first_el <=9 && split_by_colon[0].length === 2)
                     this.text_clock_model = split_by_colon[0]+":00"
                else if(first_el < 24)
                    this.text_clock_model = split_by_colon[0]+":00"
                else
            	   this.text_clock_model = ''
            	return
            }
            split_by_colon[0] = split_by_colon[0].padStart(2,"0")
            split_by_colon[1] = split_by_colon[1].padStart(2,"0")

            if(split_by_colon[0].length !=2 || split_by_colon[1].length !=2)
            {
                this.text_clock_model = ''
                return
            }
            // first element in split_by_colon should be >=0 & <=24
            if(first_el < 0 || first_el >= 24){
            	this.text_clock_model = ''
            	return
            }

            // second element in split_by_colon should be >=0 & <=59

            var second_el = parseInt(split_by_colon[1])
            if(!second_el){
                this.text_clock_model = split_by_colon[0]+":00"
                return
            }

            if(second_el < 0 || second_el > 59){
            	this.text_clock_model = ''
            	return
            }
            if(second_el <=9 && split_by_colon[1].length === 1){
                this.text_clock_model = split_by_colon[0]+":"+"0"+split_by_colon[1]
                return
            }
            this.text_clock_model = split_by_colon[0]+":"+split_by_colon[1]
        },
        updateTimeControl(){
        	this.$emit('update', this.text_clock_model);
        }
  	}
}
</script>