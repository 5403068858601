<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="12">
                        <ul class="breadcrumb">
                            <li v-if="!isPageForRepo">
                                <select v-model="Environment" v-on:change="resetEnvInJson(),getProcessDefList()" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li v-else>Repository</li>
                            <li>Object Management</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid v-if="Environment.id == '0'" key="objgrid1" :dataList="zeroIdList" @downloadBusinessObjectDetailsInExcel="downloadBusinessObjectDetailsInExcel"  @createBusinessDoc="createBusinessDoc" @createProcessDoc="createProcessDoc" @onEdit="onEdit" @onPublish = "onPublish" @onCopy="onCopy" @onUnPublish="onUnPublish"  @onCellEvent="onCellEvent" @onDelete="onDelete" @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @onDownload="onDownload" @onRowSelected="onRowSelected" @showObjectTable="onShowPD" @ShowObjectGraph="onGraphView"></edc-data-grid>

                        <edc-data-grid v-else-if="Environment.id == 'all_env'" key="objgrid2" @downloadBusinessObjectDetailsInExcel="downloadBusinessObjectDetailsInExcel" :dataList="allEnvList"  @createBusinessDoc="createBusinessDoc" @createProcessDoc="createProcessDoc" @onEdit="onEdit" @onPublish = "onPublish" @onCopy="onCopy" @onUnPublish="onUnPublish"  @onCellEvent="onCellEvent" @onDelete="onDelete" @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @onDownload="onDownload" @onRowSelected="onRowSelected" @showObjectTable="onShowPD" @ShowObjectGraph="onGraphView" @ondblClick="onCellEvent"></edc-data-grid>
                    
                        <edc-data-grid v-else :dataList="tableList" key="objgrid3" @downloadBusinessObjectDetailsInExcel="downloadBusinessObjectDetailsInExcel" @createBusinessDoc="createBusinessDoc" @onCreateVersion="createNewVersion" @createProcessDoc="createProcessDoc" @createBusinessView="redirectBusinessView('Business View')" @createDataMap="redirectDataMap('Data Map')" @onEdit="onEdit" @onPublish = "onPublish" @onCopy="onCopy" @onUnPublish="onUnPublish"  @onCellEvent="onCellEvent" @onDelete="onDelete" @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @onDownload="onDownload" @ondblClick="onCellEvent" @onRowSelected="onRowSelected" @onShowPD="onShowPD" @showObjectTable="onShowPD" @ShowObjectGraph="onGraphView" @onCreatePackage="onCreatePackage"></edc-data-grid>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-dialog v-model="viewobj_dialog" width="unset" height='600' eager persistent>
                            <v-col class="EDC-Col">
                                <v-card class="rounded-card">
                                    <v-toolbar dark dense max-height="40">
                                    <v-col class="EDC-Col text-md-center">Object Viewer
                                        <v-icon class="text-lg-left" @click="viewobj_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
                                    </v-col>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-row no-gutters class="EDC-Row">
                                            <edc-pdviewer :objectdetails="objectdetails" key="pdviewer" v-if="viewobj_dialog && showpd">
                                            
                                            </edc-pdviewer>
                                            <edc-boviewer :objectdetails="objectdetails" key="boviewer" v-if="viewobj_dialog && showbo">
                                            
                                            </edc-boviewer>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-dialog>
                        <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert> 
        
                        <v-dialog v-model="PackageDetails" width="500" persistent>
                            <v-col>
                                <v-card class="rounded-card">
                                    <v-toolbar dark dense>
                                        <v-col class="text-md-center">New Package Details
                                            <v-icon class="text-lg-left" @click="PackageDetails = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                                        </v-col>
                                    </v-toolbar>
                                    <v-container grid-list-md align-content-space-around style="padding:10px">
                                        <v-form ref="Package_form" v-model="valid" @submit.prevent="create_package">
                                            <v-col>
                                                <v-row style="width:100%;">
                                                    <v-col style="text-align: center">
                                                        <v-text-field label="Package Name" v-model="package_display_name" style="margin-right:10px" @change="package_description=package_display_name" required autocomplete="off" :rules="PackageDisplayNameRules"></v-text-field>
                                                        <v-spacer></v-spacer>
                                                        <v-textarea label="Package Description" rows=1 v-model="package_description" style="margin-right:10px" required :rules="PackageDescriptionRules"></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-spacer></v-spacer>
                                                            <vc-button @click.native="createPackage"  itemText="Save"></vc-button>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-form>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-dialog>

                        <loading-panel :loader="loader"></loading-panel>

                        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import Simplert from 'vue2-simplert';
    import config from '../../config.json';
    import {
        post as postToServer
    } from '../../methods/serverCall.js';
    import getUserRole from '../../methods/GetUserRole.js';
    import { SERVER_ERROR } from '../../data/client_message.js';
    import {
        GET_ALL_PROCESS_DEF_ENV,
        DELETE_OBJECT,
        PUBLISHED_OBJECTS_FOR_ALL_ENV,
        PUBLISH_OBJECT_IN_ENV,
        EXPORT_ALL_PROCESS_DEF_ENV,
        EXPORT_PUBLISHED_OBJECTS_FOR_ALL_ENV,
        EXPORT_PUBLISH_OBJECT_IN_ENV
    } from '../../data/url_constants.js';
    import {
        PUBLISH_SERVICE_ID,
        REPOSITORY_SERVICE_ID,
        PUBLISH_ACTION,
        CREATE_ACTION,
        UNPUBLISH_ACTION,
    } from "../../data/macros.js";
    import { getEnvironmentList } from '../../methods/EnvironmentList.js';
    import {
        TABLE_LIST,
        ZERO_ID_LIST,
        ALL_ENV_LIST,
        ENV_IP_JSON
    } from './processDefMetaData.js';
    import { manageScreenDisplay } from '@/methods/special.js';
    import { Snackbar_Default_Timeout } from '@/constants/constants.js';
    import objectPDTabularView from '../../views/objectPDTabularView.vue';
    import objectBOTabularView from '../../views/objectBOTabularView.vue';
    import { mapActions } from 'vuex';
    import { getURLByDomainByProduct } from '@/methods/domain_management.js';
    import { mixinPagePreference } from '../../mixins/pagePreferences.js';
    export default{
        name:'EdcObjectList',
        components: {
            'simplert':Simplert,
            'edc-pdviewer':objectPDTabularView,
            'edc-boviewer':objectBOTabularView
        },
        mixins: [mixinPagePreference],
        data: function () {
            return {
                PackageDetails: false,
                package_display_name: '',
                PackageDisplayNameRules: [v => !!v || 'Package Display Name is required.'],
                package_description: '',
                PackageDescriptionRules: [v => !!v || 'Package Description is required.'],
                designerHostDetails:{},
                objectdetails:{},
                viewobj_dialog:false,
                showpd:false,
                showbo:false,
                userRole:{},
                loader:false,
                selectedRows:[],
                EnvironmentList: [],
                Environment: {},
                EnvironmentListRules: [v => !!v || 'Environment is required.'],
                tableList: _.cloneDeep(TABLE_LIST),
                zeroIdList:_.cloneDeep(ZERO_ID_LIST),
                allEnvList:_.cloneDeep(ALL_ENV_LIST),
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout,
                valid:true,
                envIpJson : _.cloneDeep(ENV_IP_JSON),
                envIpJsonForEnv:_.cloneDeep(ENV_IP_JSON),
                envIpJsonForAllEnv:_.cloneDeep(ENV_IP_JSON),
                envIpJsonForRepo:_.cloneDeep(ENV_IP_JSON),
                tableListActionPreferences:[],
                allEnvActionPreferences:[],
                zeroEnvActionPreferences:[],
                packageType:'all'
            }
        },
        computed:{
            isPageForRepo(){
                return this.$store.state.sameComponentRedirectObj && this.$store.state.sameComponentRedirectObj.isPageForRepo
            },
            isPageForAllEnv(){
                return this.Environment && this.Environment.id === 'all_env'
            },
            pageCode(){
                if(this.isPageForRepo)
                    return "REPO_MGM"                
                if(this.isPageForAllEnv)
                    return "OBJ_MGM_ALL_ENV"
                return "OBJ_MGM"
            },
            associatedPreferenceList(){
                if(this.isPageForRepo)
                    return "zeroEnvActionPreferences"                
                if(this.isPageForAllEnv)
                    return "allEnvActionPreferences"
                return "tableListActionPreferences"
            }
        },
        watch:{
            '$store.state.sameComponentRedirectObj':{
                handler(newvalue,oldvalue){
                    this.managePage()
                },
                deep:true
            },
            'tableListActionPreferences':{
                handler(newValue){
                    if(newValue.length){
                        this.tableList.actions = this.updateIndexes(_.cloneDeep(this.tableList.actions),_.cloneDeep(newValue))
                    }
                },
                deep:true
            }
        },
        mounted() {
            this.tableList = this.getGridObj(this.tableList.headers,'id',false)
            this.zeroIdList = this.getGridObj(this.zeroIdList.headers,'id',false)
            this.allEnvList = this.getGridObj(this.allEnvList.headers,'id',false)

            this.designerHostDetails = _.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
            this.managePage()
        },
        methods: {
            ...mapActions(["setsameComponentRedirectObj"]),
            managePage(){
                this.resetEnvInJson()
                if(this.isPageForRepo){
                    this.Environment = {"id":"0","name":"Repository"}
                    this.EnvironmentList = [this.Environment]
                }
                else{
                    if(this.$session.get('selected_env')){
                        this.Environment = this.$session.get('selected_env')
                    }
                    getEnvironmentList(this,true);
                }
                // this.userRole = this.$session.get('user_role_mapping')
                this.envIpJsonForEnv.page_size = manageScreenDisplay()
                this.userRole = this.$session.get('user_role_mapping');
                this.mapGridActions(true)
                
                this.getProcessDefList(this.envIpJsonForEnv,true)
            },
            mapGridActions(isPageLoad){
                this.tableList.actions = [
                    {'text':'newWidOptions', 'key':"object_id", 
                        role:(this.userRole.is_superadmin || getUserRole(this.userRole,'repository_service','create')) && this.Environment.allow_create,index:1,
                        availableOptions:[
                                {title:'Create Business Object', event:'createBusinessDoc'},
                                {title:'Create Process Definition', event:'createProcessDoc'}]
                    },
                    {'text':'editKey', iconFor:'editObj', 'key':"object_id", selectType:"single",role:(this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,CREATE_ACTION)),index:2},
                    {'text':'copy',iconFor:'copyObj','key':"object_id", selectType:"single",role:(this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,CREATE_ACTION))&& this.Environment.allow_create,index:3},
                    {'text':'publish',iconFor:'pubObj','key':"object_name", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,PUBLISH_ACTION),index:4},
                    {'text':'unPublish', iconFor:'unpubObj', 'key':"object_name", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,UNPUBLISH_ACTION),index:5},
                    {'text':'createVersion',iconFor:'verObj', 'key':"object_name", selectType:"single", role:(this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID, CREATE_ACTION)) && this.Environment.allow_create,index:6,'displayText':'Create New Version'},
                    {'text':'CreatePackage',iconFor:'pkgObj','key':"object_id", selectType:"multiple", role:true, index:7,
                    options:[
                        {"value": "all", "text":'All in one package'} ,
                        {"value":"individual", "text": 'Individual package by dependent objects'}
                    ],
                    'optionKey':'showMultipleOption','optionKeyValue':'Y'
                    },
                    {'text':'downloadReportInExcel',iconFor:'reportObj','key':"object_type_desc",'cmpValue':'Business Object',
                    selectType:"multiple", role:true, index:8,directDownload:true, title:'Download Business Object Details In Excel Format',
                    emitEvent:'downloadBusinessObjectDetailsInExcel'},
                    {'text': 'showpd',iconFor:'readObj', 'key': 'object_name',  selectType:"single", index:9, role:true},

                ];
                this.allEnvList.actions = [
                    {'text':'publish','key':"object_name", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,PUBLISH_ACTION),index:2},
                    {'text':'unPublish', 'key':"object_name", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,UNPUBLISH_ACTION),index:3},
                    {'text': 'showpd', 'key': 'object_name',  selectType:"single", index:4, role:true}
                ];
                this.zeroIdList.actions = [
                    {'text':'publish','key':"id", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,PUBLISH_ACTION),index:1},
                    {'text':'delete','key':'id', selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,CREATE_ACTION),index:2},
                    {'text': 'showpd', 'key': 'object_name',  selectType:"single", index:3, role:true},
                    {'text':'downloadReportInExcel','key':"object_type_desc",'cmpValue':'Business Object',
                    selectType:"multiple", role:true, index:11,directDownload:true, title:'Download Business Object Details In Excel Format',
                    emitEvent:'downloadBusinessObjectDetailsInExcel'}
                ];
                if(isPageLoad){
                    if(this.isPageForRepo){

                    }else{
                        this.getPagePreferences('OBJ_MGM', this.tableList.actions, 'tableListActionPreferences')
                    }
                }
            },
            showMessage(message,type='error'){
                if(!message)
                    message = SERVER_ERROR
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message;
            },
            onDownload(downloadParams, filterArray){
                let _this = this; 
                _this.loader=true;
                let filter=[];
                filterArray.map((obj, index)=>{
                    obj.stringArray.map((stringObj, stringIndex)=>{
                        let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                            filter.push(_.cloneDeep(temp));
                    })
                });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option":downloadParams.recordType,
                    "client_id": _this.clientId,
                    "env_id": _this.Environment.id,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter,
                    "product_name":"archivist"
                }
                var url = config.PUBLISHER_URL+EXPORT_PUBLISH_OBJECT_IN_ENV;
                
                if (_this.Environment.id == '0')
                    url = config.REPOSITORY_API_URL+EXPORT_ALL_PROCESS_DEF_ENV;
                else if(_this.Environment.id == 'all_env')
                    url = config.PUBLISHER_URL+EXPORT_PUBLISHED_OBJECTS_FOR_ALL_ENV;

                postToServer(_this, url, inputJson).then(Response => {
                    var url = config.PUBLISHER_URL + "/static/" + Response;
                    if (_this.Environment.id == '0'){
                        url = config.REPOSITORY_API_URL + "/static/" + Response;
                    }
                    window.open(url, '_blank');
                    _this.loader=false;
                }).catch(objError => {
                    this.showMessage(objError)
                })
            },
            getCommonUrl(graphFor,record,type){
                let additionalUrl = "&last_env_id="+this.Environment.id+"&last_env_name="+this.Environment.name+ "&graphFor="+graphFor+"&allow_create="+this.Environment.allow_create+"&product_name=archivist"
                if(type){
                    let object_revision = record.object_version
                    if(record.object_revision)
                        object_revision =  record.object_revision
                    additionalUrl = additionalUrl+"&object_revision="+object_revision+"&object_id="+record.object_id+"&object_type_code="+record.object_type_code
                }
                else{
                    additionalUrl = additionalUrl+"&check_erp=true"
                }
                if(type==='C')
                    additionalUrl = additionalUrl +'&copy=Y'
                else if(type === 'V')
                    additionalUrl = additionalUrl +'&create_new_version=true'
                else if (type === 'R')
                    additionalUrl = additionalUrl +'&is_read_only=true'
                return additionalUrl
            },
            createBusinessDoc(record,type){
                let additionalUrl = this.getCommonUrl("BO",record,type)
                let url = getURLByDomainByProduct(this,this.designerHostDetails,'login',additionalUrl) 
                window.open(url, '_blank');
            },
            createProcessDoc(record,type){
                let additionalUrl = this.getCommonUrl("PD",record,type)
                let url = getURLByDomainByProduct(this,this.designerHostDetails,'login',additionalUrl)
                window.open(url, '_blank');   
            },
            ShowConfirmNewVersion(object){
                if(object.object_type == 'RT'){
                    alert("Reporting Table object is not editable")
                    return
                }
                var _this = this
                let confirmFn = function () {
                _this.SendObjectForEdit(object)
                }
                let obj = {
                title: 'Create New Version',
                message: 'This will create new version of Object and get replaced with Current version of Object, Proceed?',
                type: 'info',
                useConfirmBtn: true,
                onConfirm: confirmFn
                }
                _this.$refs.simplert.openSimplert(obj)
            },
            onRowSelected(selectedRows){
                this.selectedRows = selectedRows;
            },
            getProcessDefList (envIpJson,is_initial) {
                let _this = this;
                // this.tableList.actions = []
                if(!envIpJson){
                    envIpJson = this.getEnvIpJsonByGrid()
                    is_initial = true
                }
                _this.loader = true;            
                _this.selectedRows = []
                if(is_initial){
                    this.tableList = this.getGridObj(this.tableList.headers,'id',false)
                    this.zeroIdList = this.getGridObj(this.zeroIdList.headers,'id',false)
                    this.allEnvList = this.getGridObj(this.allEnvList.headers,'id',false)
                } 
                this.mapGridActions()
                if (this.Environment.id == '0'){
                    this.getAllProcessDefList(envIpJson);
                } else if(this.Environment.id == 'all_env') {
                    this.getObjForAllEnvs(envIpJson);
                } else {
                    _this.$session.set('selected_env', this.Environment)
                    this.getAllList(envIpJson);              
                }
            },
            getAllList(envIpJson, is_filter){
                let _this = this;
                let t_data = {
                    'env_id': this.Environment.id, 
                    'client_id': _this.clientId, 
                    "filter": envIpJson.filter,
                    "page": envIpJson.page,
                    "page_size": envIpJson.page_size,
                    "sort": envIpJson.sort
                }
                this.tableList.rows = [];
                this.tableList.total_count = 0;
                this.loader = true;
                postToServer(this, config.PUBLISHER_URL +PUBLISH_OBJECT_IN_ENV, t_data, false).then(async response => {
                    var data = response
                    if(data){
                        this.tableList.rows = data.result;
                        this.tableList.total_count = data.total; 
                    }
                    _this.loader = false;
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            getAllProcessDefList (envIpJson) {
                let inputJson = envIpJson;
                let _this = this;            
                var job_data = {
                    "client_id":_this.clientId,
                    "filter": inputJson.filter,
                    "page": inputJson.page,
                    "page_size": inputJson.page_size,
                    "sort": inputJson.sort,
                    'product_name':_this.productName
                }
                this.zeroIdList.rows = [];
                this.zeroIdList.total_count = 0;
                this.loader = true;
                postToServer(this, config.REPOSITORY_API_URL + GET_ALL_PROCESS_DEF_ENV, job_data).then(response => {
                    var data = response
                    _this.loader = false
                    if(data){      
                        data.result.map(function(obj,index){
                            obj.id = index+1
                        })         
                        this.zeroIdList.rows = data.result;
                        this.zeroIdList.total_count = data.total; 
                    }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            getObjForAllEnvs(envIpJson, is_filter) {
                let inputJson = envIpJson;
                let _this = this;
                // this.tableList.actions = []
                var request_data = {
                "client_id":_this.clientId,
                "filter": inputJson.filter,
                "page": inputJson.page,
                "page_size": inputJson.page_size,
                "sort": inputJson.sort,
                "product_name":'archivist'
                }
                this.allEnvList.rows = [];
                this.allEnvList.total_count = 0;
                _this.loader = true;
                postToServer(this, config.PUBLISHER_URL + PUBLISHED_OBJECTS_FOR_ALL_ENV, request_data).then(response => {
                    var data = response
                    _this.loader = false
                    data.result.map(function(obj,index){
                        obj.id = index+1
                    })
                    if(data){                                        
                        this.allEnvList.rows = data.result;
                        this.allEnvList.total_count = data.total; 
                    }
                    
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            onPublish:function(record){
                let _this = this;
                let publish_data = _this.getPublishData(record);
                this.$router.push({ name: 'SelectEnvTypePublish', 
                    params: {'publish_data': publish_data}
                });  
                this.managePagePreferenceCount("pubObj",this.pageCode,this[this.associatedPreferenceList])                      
            },
            onUnPublish:function(record){
                let _this = this;
                let unpublish_data = _this.getUnPublishData(record);
                postToServer(this, config.PUBLISHER_URL + '/unpublish_template', unpublish_data).then(response => {
                if(response){
                    _this.getProcessDefList(_this.getEnvIpJsonByGrid());          
                    _this.showMessage(response,'success')
                }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
                this.managePagePreferenceCount("unpubObj",this.pageCode,this[this.associatedPreferenceList])
            },
            getDeleteData(record){
                let _this = this;
                let deleteData =  {
                    'client_id': this.clientId, 
                    "deleted_by": this.userName,
                    'object_list':[],
                }
                let objectData = _.cloneDeep([record])
                if(_this.selectedRows.length)
                    objectData = _.cloneDeep(_this.selectedRows)  

                objectData.map(row=>{
                    let temp = {
                        "object_type": row.object_type_code,
                        "object_id": row.object_id,
                        "object_name": row.object_name,
                        "object_desc": row.object_desc,
                        "object_revision": row.object_revision,
                        "approval_request_id": row.approval_request_id,
                    }
                    deleteData.object_list.push(_.cloneDeep(temp));
                })
                return deleteData
            },
            getUnPublishData(record){
                let _this = this;
                let unPublishData =  {
                    'client_id': this.clientId,
                    "env_id": record.env_id || _this.Environment.id, 
                    "env_name": record.environment ||_this.Environment.name, 
                    "unpublished_by": this.userName,
                    'object_data':[],
                }
                let objectData = _.cloneDeep([record])
                if(_this.selectedRows.length)
                    objectData = _.cloneDeep(_this.selectedRows)  

                objectData.map(row=>{
                    let temp = {
                        "object_type": row.object_type,
                        "object_id": row.object_id,
                        "object_name": row.object_name,
                        "object_desc": row.object_desc,
                        "object_revision": row.object_version,
                        "approval_request_id": row.approval_request_id,
                        "published_promotion_id": row.published_promotion_id
                    }
                    unPublishData.object_data.push(_.cloneDeep(temp));
                })
                return unPublishData
            },
            getPublishData(record){
                let _this = this;
                let publishData =  {
                    'client_id': this.clientId,
                    'action_taken_by': this.userName,
                    'object_data':[],
                    'promotion_path_id': '',
                    'from_env': {
                        'env_id': _this.Environment.id,
                        'env_name': _this.Environment.name
                    }
                }
                let objectData = _.cloneDeep([record])
                
                if(_this.selectedRows.length)
                    objectData = _.cloneDeep(_this.selectedRows)  

                objectData.map(row=>{
                    let temp = {
                        "object_type": row.object_type?row.object_type:row.object_type_code,
                        "object_id": row.object_id,
                        "object_name": row.object_name,
                        "object_desc": row.object_desc,
                        "object_revision": row.object_version?row.object_version:row.object_revision,
                        "approval_request_id": row.approval_request_id,
                        "published_promotion_id": row.published_promotion_id,
                        "last_modified_date": row.timezone_aware_modified_on_date,
                        "last_modified_time": row.timezone_aware_modified_on_time,
                        "erp_code":row.erp_code,
                        "erp_version": row.erp_version,
                        "old_new_bo_mapping": row.old_new_bo_mapping
                    }
                    publishData.object_data.push(_.cloneDeep(temp));
                })    
                return publishData;
            },
            onDelete:function(record){
                let _this = this;
                var request_data = {'object_list': record}
                postToServer(this, config.PUBLISHER_URL + '/check_object_in_pending_approval', request_data).then(response => {
                    if(response){
                        if(response.status == 'PENDING_APPROVAL'){
                            _this.showMessage(response.message)
                        } else {
                            _this.deleteObject(record);
                        }
                    }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            resetEnvInJson(){
                this.envIpJsonForEnv = _.cloneDeep(ENV_IP_JSON)
                this.envIpJsonForAllEnv = _.cloneDeep(ENV_IP_JSON)
                this.envIpJsonForRepo = _.cloneDeep(ENV_IP_JSON)

                this.envIpJsonForEnv.page_size = manageScreenDisplay()
                this.envIpJsonForAllEnv.page_size = manageScreenDisplay()
                this.envIpJsonForRepo.page_size = manageScreenDisplay()
            },
            getEnvIpJsonByGrid(){
                if(this.Environment.id == '0'){
                    return this.envIpJsonForRepo
                }else if(this.Environment.id == 'all_env'){
                    return this.envIpJsonForAllEnv
                }else{
                    return this.envIpJsonForEnv
                }
            },
            filterData(filterArray, pageno){
                let _this = this;
                let inputJson = this.getEnvIpJsonByGrid()
                inputJson.filter = filterArray;
                inputJson.page = 1
                if(this.Environment.id == '0'){
                    this.getAllProcessDefList(inputJson);
                }else if(this.Environment.id == 'all_env'){
                    this.getObjForAllEnvs(inputJson);
                }else{
                    this.getAllList(inputJson);
                }
            },
            onPageChange(page, perPage) {
                let _this = this;
                let inputJson = this.getEnvIpJsonByGrid()
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getProcessDefList(inputJson);
            },
            onPerPageChange(perPage, page) {
                let _this = this;
                let inputJson = this.getEnvIpJsonByGrid()
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getProcessDefList(inputJson);
            },
            sortBy: function (sortJson) {
                let _this = this;
                let inputJson = this.getEnvIpJsonByGrid()
                inputJson.sort = sortJson
                if(this.Environment.id == '0'){
                    this.getAllProcessDefList(inputJson);
                }else if(this.Environment.id == 'all_env'){
                    this.getObjForAllEnvs(inputJson);
                }else{
                    this.getAllList(inputJson);
                }
            },
            onCellEvent:function(record){
                if(this.Environment.allow_create)
                    this.onEdit(record)
                else
                    this.onGraphView(record,true)
            },
            createNewVersion:function(record){
                let _this = this;
                if(record.object_version){
                    var object_revision = record.object_version
                }else{
                    var object_revision =  record.object_revision
                }
                var inputJson =  {"object_id": record.object_id, "object_revision": object_revision, "env_id": this.$session.get('selected_env').id}

                postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'PENDING_APPROVAL'){
                            _this.showMessage('Object is in Approval pending state, can not create new version')
                        } else {
                            this.ShowConfirmNewVersion(record)
                        }
                    }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
                this.managePagePreferenceCount("verObj",this.pageCode,this[this.associatedPreferenceList])
            },
            onEdit:function(record){
                let _this = this;
                if(record.object_type == 'RT'){
                    alert("Reporting Table object is not editable")
                    return
                }
                this.managePagePreferenceCount("editObj",this.pageCode,this[this.associatedPreferenceList])
                if(record.object_version){
                    var object_revision = record.object_version
                }else{
                    var object_revision =  record.object_revision
                }
                var inputJson =  {"object_id": record.object_id, "object_revision": object_revision, "env_id": this.$session.get('selected_env').id}
                postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(record.object_type == 'PD'){
                            _this.createProcessDoc(record,'E') 
                        }
                        else{
                            _this.createBusinessDoc(record,"E")  
                        }
                    }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            onCopy:function(record){
                if(record.object_type == 'RT'){
                    alert("You cannot copy Reporting Table object")
                    return
                }
                if(record.object_type == 'PD'){
                    this.createProcessDoc(record,'C')
                }
                else{
                    this.createBusinessDoc(record,"C")
                }
                this.managePagePreferenceCount("copyObj",this.pageCode,this[this.associatedPreferenceList])
                
            },
            SendObjectForEdit(object){
                if(object.object_type == 'PD'){
                    this.createProcessDoc(object,'V')
                }else{
                    this.createBusinessDoc(object,"V")
                }
            },
            deleteObject(record){
                let _this = this;
                var url = config.REPOSITORY_API_URL + DELETE_OBJECT
                let deleteData = _this.getDeleteData(record);
                postToServer(this, url, deleteData).then(response => {
                    if(response){
                        this.getProcessDefList(_this.getEnvIpJsonByGrid());          
                        this.showMessage(response,'success')
                    } else {
                        this.showMessage(response)
                    }
                }).catch(ProcessDocError => {
                    this.showMessage(ProcessDocError)
                });
            },
            onShowPD(record,isEnviornmentDetailsFetched){
                this.showpd = false
                this.showbo = false
                if(record.object_type == 'RT'){
                    alert("Reporting Table object is not viewable through the viewer")
                    return
                }
                this.managePagePreferenceCount("readObj",this.pageCode,this[this.associatedPreferenceList])
                if(this.isPageForRepo && !isEnviornmentDetailsFetched){
                    if(record.template === 'Y'){
                        alert('Object should have published in atleast 1 environment to view')
                        return
                    }
                    return this.fetchLatestPublishedEnvDetails(_.cloneDeep(record),'table')
                }
                this.objectdetails  = {'env_id':record.env_id,'object_id':record.object_id,'object_name':record.object_name,'isfromjob':true}
                if(record.object_type === 'PD')
                    this.showpd = true
                else
                    this.showbo = true
                this.viewobj_dialog = true
            },
            onGraphView(record,isEnviornmentDetailsFetched){
                if(record.object_type == 'RT'){
                    alert("Reporting Table object is not viewable through the designer")
                    return
                }
                this.managePagePreferenceCount("readObj",this.pageCode,this[this.associatedPreferenceList])
                if(this.isPageForRepo && !isEnviornmentDetailsFetched){
                    if(record.template === 'Y'){
                        alert('Object should have published in atleast 1 environment to view')
                        return
                    }
                    return this.fetchLatestPublishedEnvDetails(_.cloneDeep(record),'graph')
                }
                if(record.object_type == 'PD'){
                    this.createProcessDoc(record,'R') 
                }else{
                    this.createBusinessDoc(record,"R")  
                } 
            },
            fetchLatestPublishedEnvDetails(record,view_type){
                let _this = this
                let data = {
                    "object_id":record.object_id,"object_version":record.object_revision,"client_id":this.clientId
                }
                postToServer(_this,config.PUBLISHER_URL+'/latest_published_env_id_for_obj',data).then(response=>{
                    record =Object.assign(record, response)
                    if(view_type === 'table')
                        _this.onShowPD(record,true)
                    else
                        _this.onGraphView(record,true)
                }).catch(error=>{
                    console.log('error')
                })
            },
            create_package(){
                if (this.$refs.Package_form.validate()) {
                    
                }
            },
            onCreatePackage(record,packageType){
                if (packageType === 'individual'){
                    this.packageType=packageType
                    this.createPackage()
                }else{
                    this.PackageDetails = true
                    this.packageType = 'all'

                }
            },
            createPackage:function(){
                var _this = this
                var package_id = _this.package_id
                var package_name = _this.package_name
                var package_description = _this.package_description
                var package_display_name = _this.package_display_name
                var package_build_data = {
                    'client_id': _this.clientId,
                    "erp_code": _this.erp_code, 
                    "erp_version": _this.erp_version,
                    'package_objects':[],
                    "package_description": _this.package_description,
                    'package_display_name': package_display_name,
                    "env_id": _this.Environment.id,
                    "env_name": _this.Environment.name,
                    "product_name": window.sessionStorage.getItem('product'),
                    "package_type":this.packageType
                    }
                package_build_data.package_objects = _this.selectedRows
                _this.loader = true 
                postToServer(this, config.PACKAGE_API_URL + '/create_package', package_build_data).then(response => { 
                    this.PackageDetails=false
                    _this.loader = false
                    package_id = response.package_details_id
                    package_name = response.package_name
                    package_display_name = response.package_display_name
                    package_description = response.package_description
                    this.$router.push({name: 'PackageList', params: {erp_code: _this.erp_code, erp_version: _this.erp_version, package_id: package_id, package_name: package_name, package_display_name:package_display_name, package_description:package_description}});
                }).catch(ProcessDocError => {
                    this.showMessage(ProcessDocError)
                })
                this.managePagePreferenceCount("pkgObj",this.pageCode,this[this.associatedPreferenceList])
            },
            downloadBusinessObjectDetailsInExcel(objectList){
                var _this = this
                var data = {"object_list":objectList, "client_id":this.clientId,
                "env_details":_this.Environment}
                _this.loader = true
                postToServer(_this, config.REPOSITORY_API_URL +'/create_business_object_report',data).then(response=>{
                    _this.loader = false
                    var url = config.REPOSITORY_API_URL + "/static/" + response;
                    window.open(url, '_blank');
                }).catch(error=>{
                    _this.loader = false
                })
                this.managePagePreferenceCount("reportObj",this.pageCode,this[this.associatedPreferenceList])
            }
        }
    }
</script>