import{CLIENT_SIDE,SERVER_SIDE} from './../../constants/productconstants.js';

var gridObj = {
    headers:[],
    selectedRows:[],
    showSelect:true,
    showActions:true,
    tableLoading:false,
    rows:[],
    itemkey:'rn',
    total_count:0,
    showInlineFilter:true,
    isCompressionApplicable:true,
    showFilter:false,
    showRowInformation:true,
    sorting_type: SERVER_SIDE,
    filterType: SERVER_SIDE,
    paginationType: SERVER_SIDE,
    actions:[],
    persist_selected_rows:false,
    defaultSelectAll:false,
    is_grid_inline_edit:false,
    updatinglivestatus:false,
    perPage:0,
    is_in_tab:false,
    disableDefaltSorting:true,
    showExport:true,
    showRadio:false,
    showFooter:true,
    showItemPerPage:true,
    showPagination:true,
    allPagesRowSelection:false,
    allPagesRowSelectionList:[]
}

export function getGridObj(){
    return _.cloneDeep(gridObj)
}

export function getGridObjWithFormating(headers,itemkey,isClientSide=true, otherProps={}){
    let newObj = _.cloneDeep(gridObj)
    newObj['headers'] = headers
    newObj['itemkey']= itemkey
    if(isClientSide){
        newObj['sorting_type'] = CLIENT_SIDE
        newObj['filterType'] = CLIENT_SIDE
        newObj['paginationType'] = CLIENT_SIDE
    }
    Object.assign(newObj,otherProps)
    return newObj
}

function getActionObj(iconName, actionName, eventName, actionTitle='', showConfirmation=false, showJobConfirmation=false,multiOptionActionList=[]){
    return {
        "iconName":iconName,
        "actionName":actionName,
        "eventName":eventName,
        "actionTitle":actionTitle,
        "showConfirmation":showConfirmation,
        "showJobConfirmation":showJobConfirmation,
        "multiOptionActionList":multiOptionActionList
    }
}


export const actionObjMapping = [
    getActionObj('save_v2','save','onSave',"Save"),
    getActionObj('cancel_v2','cancel','onCancelEditing', "Cancel Editing"),
    getActionObj('document_edit_v2','edit','onEdit', 'Edit'),
    getActionObj('copy_v2','copy', 'onCopy','Copy'),
    getActionObj('delete_v2','delete','onDelete', 'Delete',true),
    getActionObj('delete_v2','rolerevoke','onRoleRevoke','Revoke User Role',true),
    getActionObj('delete_v2','cancel_request','onCancelRequest', 'Cancel Request',true),
    getActionObj('job_details_v2','request_history','onRequestHistory','Request History'),
    getActionObj('approval_approve_v2','accept','onAccept',"Accept Request/Approval"),
    getActionObj('approval_reject_v2','revoke_request','onRevokeRequest','Reject Request/Approval'),
    getActionObj('job_details_v2','history','onHistory', 'Show History'),
    getActionObj('job_run_v2','re_execute','onReExecute', 'Run Again'),
    getActionObj('job_run_v2','resume','onResume','Resume',true),
    getActionObj('job_suspend_v2','suspend','onPause','Suspend',true),
    getActionObj('job_terminate_v2','terminate','onTerminate','Terminate',true),
    getActionObj('job_run_v2','create', 'onCreate','Create'),
    getActionObj('add_multiple_v2','addmultiple','onAddMultiple','Add Multiple'),
    getActionObj('document_edit_v2','editcommit','onEditCommit','Edit Limit Size'),
    getActionObj('archive_restore_v2','restore','onRestore','Restore'),
    getActionObj('archive_dispose_v2','dispose','onDispose','Dispose'),
    getActionObj('delete_v2','deletecopy','onDeleteCopy','Delete Copy'),
    getActionObj('document_edit_v2','editKey','onEdit','Edit'),
    getActionObj('document_publish_v2','publish','onPublish','Publish'),
    getActionObj('document_unpublish_v2','unPublish','onUnPublish','Unpublish',true),
    getActionObj('archive_synchronize_v2','re-sync','onResync','Synchronize the Archives'),
    getActionObj('archive_unsynchronize_v2','un-sync','onUnsync','UnSynchronize the Sychromized Archives'),
    getActionObj('report_download_v2','onReportDownload','onReportDownload','Download Reports'),
    getActionObj('enquiry','onReportDownload','onEnquiry','Do Enquiry'),
    getActionObj('Database_Table','detailedHistory','onDetailedHistory','Detail History'),
    getActionObj('email_send_v2','onResendEmail','onResendEmail','Re-Send Email'),
    getActionObj('user_password_reset_v2','onResetPassword','onResetPassword','Reset Passowrd'),
    getActionObj('job_details_v2','onUserHistory','onUserHistory','On User History'),
    getActionObj('user_unlock_v2','onUnlockAccount','onUnlockAccount','Unlock Account',true),
    getActionObj('ping_v2','PingAgent','onAgentPing','Ping'),
    getActionObj('New','createVersion','onCreateVersion','Create New Version'),
    getActionObj('move_up','up','onUpArrow','Move Up'),
    getActionObj('move_down','down','onDownArrow','Move Down'),
    getActionObj('document_report_view_v2','showpd','','Show Object In Readonly Mode',false,false,[
        {title:'Show Object In Graph View', event:'ShowObjectGraph'},
        {title:'Show Object In Table View', event:'showObjectTable'}
        ]), // need to revisit
    getActionObj('item_activate','activate','onActivate','Activate',true),
    getActionObj('item_deactivate','deactivate','onDeactivate','Deactivate',true),
    getActionObj('job_suspend_v2','jobplansuspend','onSuspend','Suspend Job Plan',true,true),
    getActionObj('job_terminate_v2','jobplanterminate','onTerminate','Terminate Job Plan',true,true),
    getActionObj('document_edit_v2','jobplanrelease','onEditCommit','Edit Simultenious Job Running Count'),
    getActionObj('dependency_add', 'depadd', 'onDependencyAdd', 'Add Dependency'),
    getActionObj('dependency_remove', 'depremove', 'onDependencyRemove', 'Remove Dependency'),
    getActionObj('document_report_view_v2','viewindv','redirectToDataviewer','Redirect To Pathfinder'),
    getActionObj('Document_Export','CreatePackage','onCreatePackage','Create Package of Objects',true),
    getActionObj('search_v2','enquiry','onEnquiry','Do Enquiry'),
    getActionObj('report_download_v2','downloadReportInExcel','downloadBusinessObjectDetailsInExcel','Download Business Object Details In Excel Format'),
]

function getConfirmationObj(actionName,confirmationHeading, confirmationLabel,confirmationLblSuccess='Yes',confirmationLblCancel='cancel'){
    return {
        'actionName':actionName,
        'confirmationHeading':confirmationHeading,
        'confirmationLabel':confirmationLabel,
        'confirmationLblSuccess':confirmationLblSuccess,
        'confirmationLblCancel':confirmationLblCancel
    }
}

export const confirmationObjMapping = [
    getConfirmationObj('delete','Confirm Delete','Are you sure want to Delete?','Delete','Cancel'),
    getConfirmationObj('rolerevoke','Revoke Role','Are you sure want to Revoke?'),
    getConfirmationObj('cancel_request','Cancel Request','Are you sure want to Cancel?','Yes','No'),
    getConfirmationObj('resume','Resume Job', 'Are you sure want to Resume?','Resume'),
    getConfirmationObj('suspend_final', 'Pause Job', 'Select suspend type from below options','Suspend'),
    getConfirmationObj('suspend_proof', 'Pause Job', 'Are you sure want to Pause?','Suspend','No'),
    getConfirmationObj('terminate', 'Terminate Job', 'Are you sure want to Terminate?','Terminate','No'),
    getConfirmationObj('unPublish', 'Un-Publish','Are you sure want to UnPublish?', 'Un-Publish','No'),
    getConfirmationObj('onUnlockAccount', 'Unlock User Account','Are you sure want to unlock account?','Yes','No'),
    getConfirmationObj('activate','Activate Job','Are you sure want to Activate?','Yes','No'),
    getConfirmationObj('deactivate','Deactivate Job','Are you sure want to Deactivate?','Yes','No'),
    getConfirmationObj('jobplansuspend','Suspend Job Plan', 'Select suspend type from below options','Suspend'),
    getConfirmationObj('jobplanterminate','Terminate Job Plan', 'Select terminate type from below options','Terminate'),
    getConfirmationObj('CreatePackage','Create Package By', 'Select Package Option','Continue', 'Cancel')
]

