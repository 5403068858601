import { post as postToServer } from './serverCall.js';
import config from '../config.json'
import { FETCH_ALL_ERPS, 
		FETCH_ERP_VERSIONS } from '../data/url_constants.js'
import {SERVER_ERROR} from '../data/client_message.js'


export function  fetch_all_erp_designer(that, client_id) {
	var data = {"client_id": client_id}
	var end_point = config.REPOSITORY_API_URL + FETCH_ALL_ERPS
	postToServer(that, end_point, data, true).then(Response => {
       that.erp_list = Response 
       if(that.$route.query.process_def_id){
        // if(that.$route.query.is_read_only){
        //   that.isReadOnly = true
        //   return
        // }

        // now we are going to copy template so user can edit any object from enviroment
        
        //  if(!that.$route.query.process_def_id.includes("CST") && that.erp_list.length === 0 && !this.$route.query.copy){
        //   that.isReadOnly = true
        //   return
        // }
       }

        if(that.$route.query.business_object_graph_id){
        //   if(that.$route.query.is_read_only){
        //   that.isReadOnly = true
        //   return
        // }
        // now we are going to copy template so user can edit any object from enviroment

        //   if(!that.$route.query.business_object_graph_id.includes("CST") && that.erp_list.length === 0 && !this.$route.query.copy){
        //   that.isReadOnly = true
        //   return
        // }
      }
      if (Response.length === 0)
          return

        that.cur_erp_code = Response[0].erp_code
       if(that.$route.query.process_def_id || 
        that.$route.query.business_object_graph_id){
          that.erpDetails = false
        }
        else{
          if(that.erp_list.length > 0){
            that.erpDetails = true
          }
        }

      fetch_erp_version_for_designer(that, client_id, that.cur_erp_code)
      }).catch(error_response => {
        if(error_response){
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = error_response;
          }
          else {
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = SERVER_ERROR;
          }
        });
}   


export function fetch_erp_version_for_designer(that, client_id, erp_code) {
  var data = {"client_id": client_id, "erp_code":erp_code}
  var end_point = config.REPOSITORY_API_URL + FETCH_ERP_VERSIONS
  postToServer(that, end_point, data, true).then(Response => {
       that.erp_version_list = Response
       if (Response.length === 0)
          return

       that.cur_erp_version = Response[0]

      }).catch(error_response => {
        if(error_response){
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = error_response;
          }
          else {
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = SERVER_ERROR;
          }
        });
}



export function  fetch_all_erp(that, client_id, is_package=false) {
  var data = {"client_id": client_id}
  var end_point = config.REPOSITORY_API_URL + FETCH_ALL_ERPS
  postToServer(that, end_point, data, true).then(Response => {
       that.erp_list = Response
       that.erp_code = Response[0].erp_code
       fetch_erp_version(that, client_id, that.erp_code, is_package)
       if(is_package === true){
            that.GetPackageList()
       }
       else{
            that.getAllProcessDefList()
       }
       

         //in case of edit if db response took time
      }).catch(error_response => {
        if(error_response){
            // that.erp_list = []
            // that.snackbar = true
            // that.colorValue = 'error'
            // that.snackbartext = error_response;
          }
          else {
            that.erp_list = []
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = SERVER_ERROR;
          }
       });

}


export function fetch_erp_version(that, client_id, erp_code, is_package=false) {
	var data = {"client_id": client_id, "erp_code":erp_code}
	var end_point = config.REPOSITORY_API_URL + FETCH_ERP_VERSIONS
	postToServer(that, end_point, data, true).then(Response => {
       that.erp_version_list = Response
       that.erp_version = Response[0]
       if(is_package === true){
            that.GetPackageList()
       }
       else{
            that.getAllProcessDefList()
       }
      }).catch(error_response => {
        if(error_response){
            // that.erp_version_list = []
            // that.erp_version = ""
            // that.snackbar = true
            // that.colorValue = 'error'
            // that.snackbartext = error_response;
          }
          else {
            that.erp_version_list = []
            that.erp_version = ""
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = SERVER_ERROR;
          }
        });
}



export function fetch_object_list(that, object_version_list) {
  var data = {"client_id": client_id, "erp_code":erp_code}
  var end_point = config.REPOSITORY_API_URL + FETCH_ERP_VERSIONS
  postToServer(that, end_point, data, true).then(Response => {
       that.erp_version_list = Response
       if(Response.length> 0){
         that.erp_version = Response[0]
       }
      }).catch(error_response => {
        if(error_response){
            that.erp_version_list = []
            that.erp_version = ""
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = error_response;
          }
          else {
            that.erp_version_list = []
            that.erp_version = ""
            that.snackbar = true
            that.colorValue = 'error'
            that.snackbartext = SERVER_ERROR;
          }
      });
}