<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Agent List</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onAgentPing="onAgentPing"></edc-data-grid>
  
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios';
import Breadcrumb from "../Breadcrumbs.vue"
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import { GETAGENTQUEUELIST, PINGAGENTQUEUE} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'Agentlist',
    
    data: function () {
        return {
            userRole:{},
            queue_name: '',
            loader:false,
            // tableList: {
            //     headers: [
            //         { text: 'Machine Name', value: 'machinename', sortable: false, width: '40%', options:[], cssClass:'w16' },
            //         { text: 'Host', value: 'ipaddress', sortable: false, width: '15%', options:[], title: 'Agent Host' },
            //         { text: 'Queue Name', value: 'queuename', sortable: false, width: '20%', title: 'Agent Queue Name' },
            //         { text: 'Worker Count', value: 'workercount', sortable: false, width: '20%', options:[], title: 'Worker Count', dataType:'int'},
                   
            //     ],
            //     actions:[{'text':'PingAgent','key':"machinename", selectType:"single",index:1}],
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     itemkey:'queuename',
            //     envIpJson : {
            //         "filter": [],
            //         "sort": {
            //             "column": "",
            //             "type": ""
            //         },
            //         "page": 1,
            //         "page_size": 5
            //     },
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    { text: 'Machine Name', value: 'machinename', sortable: false, width: '40%', options:[], cssClass:'w16' },
                    { text: 'Host', value: 'ipaddress', sortable: false, width: '15%', options:[], title: 'Agent Host' },
                    { text: 'Queue Name', value: 'queuename', sortable: false, width: '20%', title: 'Agent Queue Name' },
                    { text: 'Worker Count', value: 'workercount', sortable: false, width: '20%', options:[], title: 'Worker Count', dataType:'int'},
                   
                ],
             snackbar:false,
             snackbartext:'',
             colorValue:'success',
             snackbartimeout: Snackbar_Default_Timeout,
             valid:true,
             isloading:false,
             menu:false,
            
        }
    },
    mounted() {
              this.getAgentQueueList();
              this.tableList = this.getGridObj(this.headers,'queuename')
        this.tableList.actions = [{'text':'PingAgent','key':"queuename", selectType:"single",index:1}]
              
    },
    methods: {    
      getAgentQueueList () {
        let _this = this;
        var client_id = _this.$session.get('client_id')
        var input_data = {"client_id": client_id}
        var fetch_agent_endpoint = config.AGENT_MANAGEMENT_URL + GETAGENTQUEUELIST
        postToServer(this, fetch_agent_endpoint, input_data
          ).then(response => {
          if(response){
                _this.tableList.rows = response;
                _this.tableList.total_count =response.length; 
            }else{
                _this.tableList.rows = [];
                _this.tableList.total_count = 0; 
            }
          
        }).catch(error_response => {
          if(error_response){
              _this.loader = null 
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
            }
        });
    
        },
        onAgentPing(record){
           let _this = this;
            var input_data = {"queue_name":record.queuename}
            this.queue_name = record.queuename
            var ping_agent_endpoint = config.AGENT_MANAGEMENT_URL + PINGAGENTQUEUE
            postToServer(this, ping_agent_endpoint, input_data
              ).then(response => {
                  _this.loader = null 
                  _this.snackbar = true
                  _this.colorValue = 'success'
                  _this.snackbartext = response;
                  _this.snackbartext = _this.snackbartext.replace('ping agent','pinged '+_this.queue_name)
            }).catch(error_response => {
              if(error_response){
                  _this.loader = null 
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = error_response;
                  _this.snackbartext = _this.snackbartext.replace('Agent',_this.queue_name)
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            });
            }
    }
}
</script>
<style scoped>
.smaller{
    width:20px;
    height:20px;
    font: 10px
}
</style>