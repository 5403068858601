<template> 
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select ref="envname" v-model="Environment" style="-webkit-appearance: menulist" autofocus @change="GetBusinessObjectAndTableList">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :key="index" :value="env">{{ env.name }}</option>
                </select> 
              </li>
              <li>Business Object Activity Graph</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-row no-gutters class="EDC-Row EDC-RowsSpacing" >
      <v-col cols="3" class="EDC-ColsSpacing">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>Business Object List</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12" class="EDC-ExpansionCol style-2scroll">
                <v-checkbox v-for="(bo_object, index) in BusinessObjectList" :key="index" v-model="bo_object.is_selected" :label="bo_object.business_object_name" class="customCheckbox" hide-details @change="boSelectionChanged(bo_object,index)"/>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Business Object Activities</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12" class="EDC-ExpansionCol">
                <v-checkbox v-for="(activity, index) in activityType" :key="index" v-model="activity.is_selected" :label="activity.name" class="customCheckbox" hide-details dense @change="activitySelectionChanged(activity, index)"/>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Tables</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12"  class="EDC-ExpansionCol style-2scroll">
                <v-checkbox v-for="(table_obj, index) in FilterTableList" :key="index" v-model="table_obj.is_selected" :label="table_obj.display_text" class="customCheckbox" hide-details @change="tableSelectionChanged(table_obj, index)"/>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Dates</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12"  class="EDC-ExpansionCol" style="height:120px !important; overflow-x: hidden !important;">
                <v-row class="EDC-Row">
                  <v-col cols="12" class="EDC-Col">
                    <calender :input="fromDate" label="From Date (YYYY-MM-DD)" @update="setFromDate"/>
                  </v-col>
                </v-row>
                <v-row class="EDC-Row">
                  <v-col cols="12"  class="EDC-Col">
                    <calender :input="toDate"  label="To Date (YYYY-MM-DD)" @update="setToDate"/>    
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-model="panel">
            <v-expansion-panel-header>Data Type & Range</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12"  class="EDC-ExpansionCol" style="height:110px !important">
                <v-row no-gutters>
                  <span id="radioControls">
                    <v-radio-group v-model="whichgraph" row hide-details @change="loadGraph()">
                      <v-radio class="radioClass" :color="checkboxColor" label="By Size" value="size"/>
                      <v-radio class="radioClass" :color="checkboxColor" label="By Record Count" value="count"/>
                    </v-radio-group>
                  </span>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field v-model="maxval" type="number" :label="maxLabel" style="width:95%;" @keypress="ValidateMinMax"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="minval" type="number" :label="minLabel" style="width:95%;" @keypress="ValidateMinMax"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row no-gutters>
          <v-col cols="12" class="text-xs-right">
            <vc-button type="submit" itemText="Apply" @click.native="GetData()"></vc-button>
            <vc-button type="submit" itemText="Reset" @click.native="ResetFilter()"></vc-button>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="9">
        <v-tabs @change="TabChanged" v-model="tabindex">
          <v-tab class="EDC-Tabs">Record Count</v-tab>
          <v-tab class="EDC-Tabs">Activity By Table</v-tab>
          <v-tab class="EDC-Tabs">Data Size</v-tab>
            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12" class="echarts">
                  <IEcharts :option="option" :loading="loading" @click="onClick" @ready="onReady"/>
                </v-col>
              </v-row>
            </v-tab-item>
            
            <v-tab-item>
              <v-col>
                <v-card>
                  <edc-data-grid :dataList="tableList" @onDownload="onDownload" @Updatefilter="filterData" key="tableList"/>
                </v-card>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="1"/>
                <v-col cols="10" class="piechart" align="left">
                  <IEcharts :option="pieoption" :loading="pieloading" @click="onPiClick" @ready="onPiReady"/>
                </v-col>
                <v-col cols="1"/>
              </v-row>
            </v-tab-item>
        </v-tabs>
      </v-col>
      </v-row>
    </v-container>
    
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import IEcharts from 'vue-echarts-v3/src/full.js';
import 'echarts/lib/chart/line'
import 'echarts/lib/component/polar'
import moment from 'moment'
import config from './../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
import {SERVER_ERROR} from './../../../data/client_message.js'
import {ACTIVITY_TYPE_RESTORE,ACTIVITY_TYPE_SELECTIVE_RESTORE, ACTIVITY_TYPE_DISPOSE, BULK_RESTORE, ACTIVITY_TYPE_SELECTIVE_DISPOSE} from './../../../data/job_management.js'
import {GETBUSINESS_OBJECT_BY_ENV}
from './../../../data/url_constants.js';
import _ from 'lodash'
import {
  COLOR_CODE, BTN_COLOR
} from "@/data/macros.js";
import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
import {CHECKBOX_COLOR} from '../../../data/macros.js'
import {
    CLIENT_SIDE,
    SERVER_SIDE
  } from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import Calender from '@/views/calender'

  export default {
    components: {
      IEcharts,
      Calender,
    },
    
    // name: 'ClientJobListNew',
    data: function () {
      return {
        data_list:[],
        panel: 0,
        tabindex:2,
        fromDate:null,
        toDate:null,
        allBOId:"BO0",
        snackbar:false,
        snackbartext:'',
        colorValue:'',
        snackbartimeout: Snackbar_Default_Timeout,
        maxLabel:'Max Size(In MB)',
        minLabel:'Min Size (In MB)',
        maxval:null,
        minval:null,
        checkboxColor:CHECKBOX_COLOR,
        pieloading:false,
        pieoption:{
          title:{ 

            text:"Archive Data Size By Business Object",

            x:'left',
            left:'15%',
          }
        },
        whichgraph:'size',
        pi_echart_instance:null,
        pi_echart_graph:null,
        echart_instance:null,
        echart_graph:null,
        colorCode:COLOR_CODE,
        outlineColor:BTN_COLOR,

        loading:false,
        Environment:{},
        EnvironmentList:[],
        BusinessObjectList:{},
        BusinessObject:{},
        pieSelectedBOs:[],
        pieColorBOMapping:[],
        sendServiceCall:null,
        activityType:[{"id":1,"name":"All","is_selected":true},{"id":2,"name":"Archival","is_selected":true},{"id":3,"name":"Restore","is_selected":true},{"id":4,"name":"Dispose","is_selected":true},{"id":5,"name":"Copy","is_selected":true},{"id":6,"name":"Delete Copy","is_selected":true}],
        selectedActivity:{"id":1,"name":"All"},
        option :{
          xAxis: {
            type: 'category',
            name:'Tables',
            nameGap:30,
            nameLocation:'center',
            axisTick:{
              alignWithLabel:true,
            },
            axisLabel: {
              interval: 0,
              rotate: 30
            },
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: [],
            type: 'bar'
          }]
        },
        // tableList: {
        //     headers: [
        //     ],

        //     actions: [],
        //     sorting_type: CLIENT_SIDE,
        //     filterType: CLIENT_SIDE,
        //     paginationType: CLIENT_SIDE,
        //     total_count: 0,
        //     rows: [],
        //     pageNumber:1,
        //     hideSelect:true,
        //     hideExport:false,
        //     hideShowColumns:true,
        //     hideActions:true,
        //     hideFilter:false,
        //     // syncHeaderScroll:false,
        // },
        tableList: {
                headers:[],
                actions:[],
                rows:[]
            },
        FilterTableList:[],
        table_col_for_size :{ text: 'Size (In MB)', value: 'size',align:'right', width:"15%", options:[]},

        table_col_for_count :{ text: 'Row Count', value: 'row_count_formatted',align:'right', width:"25%", options:[]},
        table_default_headers: [{ text: 'Business Object Name', value: 'business_object_name', cssClass:"w16"},
                { text: 'Table Name', value: 'table_name',cssClass:"w16"},
                {text: 'Table Description', value: 'table_description',cssClass:"w16"}],

        envIpJson : {
                    "filter": [],
                    "sort": [],
                    "page": 1,
                    "page_size": 10
                },
        size_unit:'MB', // currently not using this variable (with below 2).But in future if require we can use this for grid.
        count_unit:'',
        divider:1,
      }
    },
    mounted() {
      this.tableList =  this.getGridObj(this.headers, 'business_object_name', true, {showSelect : false})
      var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
              this.Environment = this.$session.get('selected_env')
              // this.getAllBusinessObjects()
            }
      if(this.tableList.paginationType == CLIENT_SIDE)
            this.envIpJson.page_size="*";
      // this.tableList.headers = this.get_table_headers()
      getEnvironmentList(this);
      // this.getAllBusinessObjects()
      this.GetBusinessObjectAndTableList()
      this.focus_env()
      this.tabindex = 0
      // this.getSeriesData()
    },
    beforeDestroy() {
    },
    methods: {
      TabChanged(item,test){
        var _this = this

        // need to give time to load that tab panel then need to load graph
        setTimeout(function(){
          if(_this.pi_echart_instance && _this.pi_echart_instance.getHeight() <= 200){
            _this.pi_echart_instance.resize()
          }

          if(item === 1 && _this.data_list.length > 0){
          _this.tableList.headers = _this.get_table_headers()
          _this.tableList.rows = _.cloneDeep(_this.data_list)
          _this.data_list = []
        }
        
        },1)
        
      },
      boSelectionChanged(item,index){
        // alert(item.is_selected)
        // alert(index)

        var is_selected = item.is_selected
        if(index === 0){
          // user clicked on all item from item list
          //now change this status to all business objects. If is_selected is true then all bo selected else wise versa

          this.BusinessObjectList.map(function(obj){
            obj.is_selected = is_selected
          })
        }
        else{
          // create new list with only actual business object.Then check selected items from that new list. if selected item count matched with new list then perform selectALL else make selectALL false. First item is ALL in BusinessObjectList list, so do slice from index 1.
          var only_bo_list = this.BusinessObjectList.slice(1,this.BusinessObjectList.length)
          var selected_count = 0
          only_bo_list.map(function(obj){
            if(obj.is_selected)
              selected_count+=1
          })
          this.BusinessObjectList[0].is_selected = only_bo_list.length === selected_count?true:false
        }
      },
      activitySelectionChanged(item,index){
        var is_selected = item.is_selected
        if(index === 0){
          // user clicked on all item from item list
          //now change this status to all activityType. If is_selected is true then all actvity selected else wise versa

          this.activityType.map(function(obj){
            obj.is_selected = is_selected
          })
        }
        else{
          // create new list with only actual activity Type.Then check selected items from that new list. if selected item count matched with new list then perform selectALL else make selectALL false. First item is ALL in activityType list, so do slice from index 1.
          var only_activity_list = this.activityType.slice(1,this.activityType.length)
          var selected_count = 0
          only_activity_list.map(function(obj){
            if(obj.is_selected)
              selected_count+=1
          })
          this.activityType[0].is_selected = only_activity_list.length === selected_count?true:false
        }
      },
      tableSelectionChanged(item,index){
        var is_selected = item.is_selected
        if(index === 0){
          // user clicked on all item from item list
          //now change this status to all FilterTableList. If is_selected is true then all actvity selected else wise versa

          this.FilterTableList.map(function(obj){
            obj.is_selected = is_selected
          })
        }
        else{
          // create new list with only actual FilterTableList.Then check selected items from that new list. if selected item count matched with new list then perform selectALL else make selectALL false. First item is ALL in FilterTableList list, so do slice from index 1.
          var only_table_list = this.FilterTableList.slice(1,this.FilterTableList.length)
          var selected_count = 0
          only_table_list.map(function(obj){
            if(obj.is_selected)
              selected_count+=1
          })
          this.FilterTableList[0].is_selected = only_table_list.length === selected_count?true:false
        }
      },
      setFromDate(dateParam){
          let _this = this;
          _this.fromDate = dateParam;
      },
      setToDate(dateParam){
          let _this = this;
          _this.toDate = dateParam;
      },


      get_table_headers(){
        var headers =  cloneDeep(this.table_default_headers)
        // if(this.whichgraph === "count")
          this.table_col_for_size.text = "Size (In "+this.size_unit+")"
          this.table_col_for_count.text ="Row count"
          if(this.count_unit)
            this.table_col_for_count.text = "Row count (In "+this.count_unit+")"
          headers.push(this.table_col_for_size)
          headers.push(this.table_col_for_count)
        // else
        return headers
      },
      focus_env(){
        if(this.$refs.envname.$el)
          this.$refs.envname.$el.focus()
        else this.$refs.envname.focus()
      },
    onReady(instance,echarts){
      this.echart_instance = instance
      this.echart_graph = echarts
    },
    onClick(){

    },
    legendSelectionChange(params){
      let _this = this
      clearTimeout(this.sendServiceCall)
      console.log(params) 
      var legend_bos = params.selected // this return json object with all bo name against value {bo_name:true,bo_name1:false}
      var selected_bo_list = []
      this.pieSelectedBOs = []
      var keys  = Object.keys(legend_bos)
      for(var k in legend_bos){
        var obj = _.find(this.pieColorBOMapping,["name",k])
        var selectedobj = _.find(this.pieSelectedBOs,["bo_id",obj.bo_id])
        if(legend_bos[k]) // legend is selected
          this.pieSelectedBOs.push({"bo_id":obj.bo_id})
        // {
        //   // now take bo_id for that legend.
        //   // this should return obj. So no need to check the obj got or not
        //   // So now check is that object already available in this.pieSelectedBOs or not. If availble do nothing else add the same
        //   if(!selectedobj)
        // }
        // else{
        //   // now reverse case. User might selected that bo in this.pieSelectedBOs and now disabled it
        //   if(selectedobj){
        //     var index =  _.find(this.pieSelectedBOs,["bo_id",obj.bo_id])
        //     this.pieSelectedBOs.splice(index,1)
        //   }
        // }
      }
      // this.sendServiceCall = setTimeout(function(){
      //   _this.getGraphData('N','Y')
      // },2000)
    },
    onPiReady(instance,echarts){
      this.pi_echart_instance = instance
      this.pi_echart_graph = echarts
      
    },
    onPiClick(event,instance,ECharts){
      // this.BusinessObject = _.find(this.BusinessObjectList,["business_object_id",event.data.bo_id])
      console.log(this.pieSelectedBOs)
      clearTimeout(this.sendServiceCall)
      let _this = this
      // this.performBOselection(event.data.bo_id)
      // this.sendServiceCall = setTimeout(function(){
      //   _this.getGraphData('N','Y')
      // },2000)
    },
    performBOselection(bo_id){
      var index = _.findIndex(this.pieSelectedBOs,["bo_id",bo_id])
      if(index >= 0)
        this.pieSelectedBOs.splice(index,1)
      else {
        this.pieSelectedBOs.push({"bo_id":bo_id})
      }
    },
    getAllBusinessObjects(){
      let _this = this;
        // this.getAllArchieves()
        _this.$session.set('selected_env', this.Environment)
        if(!this.Environment.id)
          return false
        var env_id = this.Environment.id;
        getToServer(this, config.ENGINE_API_URL + GETBUSINESS_OBJECT_BY_ENV+'/'+env_id).then(response => {
          // if(response && response.length > 1)
            response.unshift({"business_object_id":_this.allBOId,"business_object_name":"All"})
            response.map(function(obj){
              obj.is_selected = true
            })
          this.BusinessObjectList = response
          this.BusinessObject = response[0]
          this.getGraphData('N','N')
        }).catch(CurrentJobError => {
          // _this.hideLoader();
          if(CurrentJobError){
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = CurrentJobError;
          }
          else {
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = SERVER_ERROR;
          }
        })
      },
      getGraphData(reset_call,timeout_call,filter_json){

        if(reset_call === 'Y' || timeout_call === 'Y')
          this.envIpJson = {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 10
                }
        var bo_list = []
        let _this = this
        if(reset_call === 'Y'){
          this.BusinessObject = _.find(this.BusinessObjectList,["business_object_id",_this.allBOId])
          this.pieSelectedBOs = []
        }
        else if(timeout_call === 'Y'){
          bo_list = _.map(this.pieSelectedBOs,"bo_id")
          if(bo_list.length === 0)
            bo_list = []
        }
        else{
          this.pieSelectedBOs = []
          bo_list.push(this.BusinessObject.business_object_id)
        }
        if(this.BusinessObject.business_object_id == _this.allBOId && timeout_call !='Y')
        this.pieloading = true
        else{
        this.loading = true
        }
        if(!this.BusinessObject.business_object_id)
          return false
        var data = {"env_id":this.Environment.id,"business_object_id":bo_list,"activity_type":this.selectedActivity.name, "graph_type":this.whichgraph,'filter_json':filter_json}
        postToServer(this, config.JOB_STATISTIC_URL + '/business_object_statistics',data).then(response => {
          this.loading = false
          this.pieloading = false
          if(response){
            if(!filter_json){
              // this.tableList.headers = this.get_table_headers()
              // this.tableList.rows = []
              this.FilterTableList = []
            }
            var data_list = []
            if(this.whichgraph === "count")
              data_list = _.reverse(_.sortBy(response.table_data,'row_count'))
            else
              data_list = _.reverse(_.sortBy(response.table_data,'size'))

            data_list.map(function(obj){
              obj.is_selected = true
            })
            this.data_list = data_list
            this.$nextTick(function(){
              _this.tableList.rows = _.cloneDeep(_this.data_list)
            })
            // this.FilterTableList = cloneDeep(data_list)
            // this.FilterTableList.unshift({"table_name":"All","is_selected":true})
            var bar_limit = 10
            if(response.bar_limit)
              bar_limit = parseInt(response.bar_limit)
            response['bar_graph'] = _.slice(data_list,0,bar_limit)
            this.generategraphData(response)
          }
        }).catch(CurrentJobError => {
        this.loading = false
        this.pieloading = false
          if(CurrentJobError){
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = CurrentJobError;
          }
          else {
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = SERVER_ERROR;
          }
        })
      },
      generategraphData(data,bar_limit){
        var series = []
        var bo_names = []
        var unit = "MB"
        if(data.pie_size_unit)
          unit = data.pie_size_unit
        var devide_by = 1
        if(data.pie_graph){
          var pie_data = data.pie_graph

          if(pie_data.length > 0){
            var max_value = _.maxBy(pie_data,'size')
            // alert(max_value.size)
            if(max_value.size > 10000 && max_value.size < 100000){
              unit = 'GB'
              devide_by = 1024
            }
            else if (max_value.size >= 100000) {
              unit = 'TB'
              devide_by = 1024 * 1024
            }
          }

            for(var i = 0; i < pie_data.length; i++){
              // if(this.whichgraph === "count"){
              //   pie_data[i]['key'] = pie_data[i]['row_count']
              //  }
              //  else

              // Pie will only show size.
              pie_data[i]['key'] = (pie_data[i]['size']/1).toFixed(2)
              bo_names.push({"name":pie_data[i].business_object_name,"bo_id":pie_data[i].business_object_id})
              series.push({"value":pie_data[i].key,"name":pie_data[i].business_object_name,"bo_id":pie_data[i].business_object_id})
            }
            this.designPiGraph(bo_names,series,unit)
          }
          this.generateBarGraph(data.bar_graph,bar_limit)
      },
      designPiGraph(bo_names,series_data,unit){
        this.echart_instance.setOption(this.option, true)
        this.pi_echart_instance.setOption(this.pieoption, true)
        var title = "Data Size By Business Object"
        // if(this.whichgraph === "count")
        //   title = "Data Count By Business Object"
        var options = {
          title:{
            text:"Archive Data Size By Business Object( In "+unit+")",
            x:'left',
            left:'15%',
          },
          tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          toolbox: {
          feature:{
                saveAsImage: {
                  'type':'png',
                  'name':'Archvie Data Size By Business Object',
                  'title':'Download',
                  emphasis:{
                    iconStyle:{
                      textAlign:'right'
                    }
                  }
                }
              }
          },
          legend: {
              orient: 'vertical',
              x: 'right',
              data:bo_names,
              top:'10%',
              right:0,
          },
          series: [
          {
            name:"Business Object",
            type:'pie',
            avoidLabelOverlap: false,
            radius: ['50%', '70%'],
            center : ['35%', '50%'],
            data:series_data,
            // selectedMode:'multiple',
            selectedOffset:"15",
           label: {
              normal: {
                  show: false,
                  position: 'center'
              },
              emphasis: {
                  show: true,
                  textStyle: {
                      fontSize: '30',
                      fontWeight: 'bold'
                  }
              }
          },
          labelLine: {
              normal: {
                  show: false
              }
          },
          }]
        }
      this.pi_echart_instance.setOption(options, true)
      
      this.CreateColorBOMapping()
      },
      CreateColorBOMapping(){
        var pie_options  = this.pi_echart_instance.getOption()
        var pie_color = pie_options.color
        var pie_legends = cloneDeep(pie_options.legend)
        if(pie_legends.length === 0)
          return false
        // in our case we have only one legend so accessed that by 0.
        pie_legends = pie_legends[0].data
        /* Here logic is color get assigned sequencially. legend and color assignment is sequenctial
        So we will assign color sequentially to our pieColorBOMapping object. Also not that there is extra color values compare to the lengends so start loop on lengends.
        Our legend formation is {"name":"","bo_id":""}
         */
         this.pieColorBOMapping = []
         for(var i=0;i<pie_legends.length;i++)
         {
            pie_legends[i]["color"] = pie_color[i]
            this.pieColorBOMapping.push(pie_legends[i])
         }
         console.log('colors',this.pieColorBOMapping)
      },
      generateBarGraph(data,bar_limit){
        var series_data = this.getSeriesData(data,bar_limit)
        var bar_series = series_data.series_data
        var x_axis_val = series_data.table_data
        var unit = series_data.unit
        var graph_title = "Size by table (Top 10)"
        var yAxis_name = "Size (In MB)"
        if(this.whichgraph === "count"){
          graph_title = "Record count by table (Top 10)"
          yAxis_name = "Record Count"
          if (this.count_unit)
          yAxis_name = "Record Count (In "+this.count_unit+")"
        }
        else{
          graph_title = "Size by table (Top 10)"
          if(this.size_unit)
            yAxis_name = "Size (In "+this.size_unit+")"
        }
        
        let _this = this
        this.echart_instance.setOption(this.option, true)
        var options = {
            title:{
              text:graph_title
            },
            tooltip: {
            show:true,
          },
          toolbox: {
          feature:{
                saveAsImage: {
                  'type':'png',
                  'name':graph_title,
                  'title':'Download',
                  emphasis:{
                    iconStyle:{
                      textAlign:'right'
                    }
                  }
                }
              }
          },
            xAxis :{
              type :'category',
              name:'Tables',
              nameGap:30,
              data:x_axis_val,
              nameLocation:'end',
              axisTick:{
                alignWithLabel:true,
              },
              axisLabel: {
                        interval: 0,
                        rotate: 10
                    },
            },
            yAxis:{
              type: 'value',
              name:yAxis_name,
              nameGap:15,
              nameLocation:'end',
              },
            series:bar_series
          }
          this.echart_instance.setOption(options, true)
      },
      getSeriesData(data,bar_limit){
        var series_list = []
        var tables_list = []
        var unit = ''
        var devide_by = 1


        for (var i = 0; i < data.length; i++) {
          var current_row = data[i]
           if(this.whichgraph === "count"){
            current_row['key'] = current_row['row_count']
           }
           else
            current_row['key'] = current_row['size']

          // data[i].key = Math.abs(data[i].key) 
          // now create table stack to show largest table first
            var tbl_obj = _.find(tables_list,["table_name",data[i].table_name])
            if(tbl_obj)
            {
              tbl_obj.total = tbl_obj.total + Number(data[i].key)
              var index = _.findIndex(tables_list,["table_name",data[i].table_name])
              this.$set(tables_list,index,tbl_obj)
            }
            else{
              tables_list.push({"table_name":data[i].table_name,"total":Number(data[i].key)})
            }
        }

        tables_list = _.reverse(_.sortBy(tables_list,"total"))
        //we need only bar_limit tables
        tables_list = _.slice(tables_list,0,bar_limit)

        // define unit and devide_by based on the max
        // var max_value = tables_list[0].total
        // if(this.whichgraph === 'count'){
        //   if(max_value > 1000 && max_value < 1000000){
        //     unit = 'Ks'
        //     devide_by = 1000
        //   }
        //   else if(max_value >=1000000){
        //     unit = 'Million'
        //     devide_by = 1000000
        //   }
        // }
        // else{
        //   if(max_value > 10000 && max_value < 100000){
        //   unit = 'GB'
        //   devide_by = 1024
        // }
        // else if (max_value >= 100000) {
        //   unit = 'TB'
        //   devide_by = 1024 * 1024
        // }
        // }


        for (var i = 0; i < data.length; i++) {
            //current table should present in top barlimit tables
            var tbl_obj = _.find(tables_list,["table_name",data[i].table_name])
            if(!tbl_obj)
              continue
           var current_row = data[i]
           var bo_id = data[i].business_object_id
           var obj = _.find(series_list,['bo_id',bo_id])
           if(obj){
                obj.table_list.push([data[i].table_name,data[i].key])
                var index = _.findIndex(series_list,['bo_id',bo_id])
                this.$set(series_list,index,obj)
              }
              else{
                series_list.push({'bo_id':bo_id,"table_list":[[data[i].table_name,data[i].key]]})
              }
          }

          // create series here (Y axis value).
          //first sort series list
          series_list = _.reverse(_.sortBy(series_list,"total"))
          var graph_series = []
          for (var i = 0; i < series_list.length; i++) {
            graph_series.push(this.getDefaultSeries(series_list[i].table_list,i,series_list[i].bo_id))
          }
          // tables_list = _.reverse(_.sortBy(tables_list,"total"))

          
          // we got table list by 
          var only_tables =_.map(tables_list,"table_name")
          return {"series_data":graph_series,"table_data":only_tables,'unit':unit}
      },
      getDefaultSeries(data,i,bo_id){
        var itemStyle = {}
        var obj = _.find(this.pieColorBOMapping,['bo_id',bo_id])
        if(obj.color)
          itemStyle = {color:obj.color}
        var seriesName = "Table Size"+i
        if (obj.name)
          seriesName = obj.name
        return {
          name:seriesName,
          type:'bar',
          stack:'tables',
          itemStyle:itemStyle,
          data:data,
          label: {
          normal: {
              show: true,
              position: 'inside'
            }
          }
        }
      },
      loadGraph(){
        this.pieSelectedBOs = []
        this.minval = null
        this.maxval = null
        if(this.whichgraph === 'size'){
          this.maxLabel='Max Size(In MB)'
          this.minLabel='Min Size (In MB)'
        }
        else{
          this.maxLabel='Max Row Count'
          this.minLabel='Min Row Count'
        }
        // this.getGraphData('Y')
      },

      filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=[];
            _.set(inputJson, 'page', 1);
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    if(obj.value.split('.')[1]){
                        var value = obj.value.split('.')[1]
                    }else{
                        var value = obj.value.split('.')[0]
                    }
                    let temp = {"column":value,"value": String(stringObj.text).trim()};
                     inputJson.filter.push(cloneDeep(temp));
                })
            });
            this.getGraphData('N','N',inputJson);
        },

      // new methods writting start here

      GetBusinessObjectAndTableList(){
        //method to fetch business object list and table list
        let _this = this;
        this.ResetData()
        _this.$session.set('selected_env', this.Environment)
        if(!this.Environment.id)
          return false
        var client_id = _this.$session.get("client_id")
        var env_id = this.Environment.id;
        var data = {"client_id":client_id, "env_id":env_id, "env_details":this.Environment}
        postToServer(_this, config.JOB_STATISTIC_URL + '/table_business_object_list',data).then(response => {
          _this.BusinessObjectList = []
          if(response.business_object_list){
            response.business_object_list.unshift({"business_object_id":_this.allBOId,"business_object_name":"All"})
            response.business_object_list.map(function(obj){
              obj.is_selected = true
            })
          _this.BusinessObjectList = response.business_object_list
          _this.BusinessObject = response.business_object_list[0]
          }

          if(response.table_list){
            _this.FilterTableList = response.table_list
            _this.FilterTableList.unshift({"table_name":"All", "display_text":"All", "is_selected":true})
          }
          _this.GetData()
        }).catch(ErrorMessage => {
          _this.snackbar = true
          _this.colorValue = 'error'
          _this.snackbartext = ErrorMessage;
        })
      },
      ValidateAndGetFilterData(){
        // This method to use validate filter

        let _this = this
        // validation for business object
        var selected_bo_list = []
        // first check Is user has selected ALL busness object
        if(_this.BusinessObjectList[0].is_selected)
          selected_bo_list.push(_this.allBOId)
        else{
          _this.BusinessObjectList.map(function(obj){
            if(obj.is_selected)
              selected_bo_list.push(obj.business_object_id)
          })
        }
        if(selected_bo_list.length === 0){
          return {"status":false, "error":"Atleast select one business object"}
        }

        // validation for activities
        var selected_activities_list = []
        // first check Is user has selected ALL activities
        if(_this.activityType[0].is_selected)
          selected_activities_list.push("ALL")
        else{
          _this.activityType.map(function(obj){
            if(obj.is_selected)
              selected_activities_list.push(obj.name)
          })
        }
        if(selected_activities_list.length === 0){
          return {"status":false, "error":"Atleast select one activity"}
        } 

        // validation for tables
        var selected_tables_list = []
        // first check Is user has selected ALL tables
        if(_this.FilterTableList[0].is_selected)
          selected_tables_list.push("ALL")
        else{
          _this.FilterTableList.map(function(obj){
            if(obj.is_selected)
              selected_tables_list.push(obj.table_name)
          })
        }
        if(selected_tables_list.length === 0){
          return {"status":false, "error":"Atleast select one table"}
        }

        // check dates. User need select all dates or none
        //if(_this.toDate && !_this.fromDate)
        //  return {"status":false,"error":"Select from date or remove to date"}
        //if(!_this.toDate && _this.fromDate)
        //  return {"status":false,"error":"Select to date or remove from date"}

        // validate min max
        var minval = 0
        if(_this.minval){
          minval = parseInt(_this.minval)
        // to avoid 0001 or 0000 kind of input.parseint will return proper int value
          _this.minval = minval
        }

        var maxval = 0
        if(_this.maxval){
          maxval = parseInt(_this.maxval)
          // to avoid 0001 or 0000 kind of input.parseint will return proper int value
          _this.maxval = maxval
        }
        if(maxval && minval > maxval)
          return {"status":false,"error":"Maximum value should be greater than the minimum value"}


        // Incase of all selected activities belong to "MINUS OPERATION (PURGE,RESTORE,DISPOSE, DELETE COPY)" that time output will be in minus in case of row count.So we need to pass min max count in minus too.
        // Logic is we will check the activity list has any positive value activity (ALL,ARCHIVE,COPY). If list doesnt contain this activities means we need to minus the min max
        if(selected_activities_list.indexOf("ALL") === -1 && selected_activities_list.indexOf("Archival") === -1 && selected_activities_list.indexOf("Copy") === -1){
          minval = minval * -1
          maxval = maxval * -1

          // If value in minus, then min become max and max become min
          var max_dummy = minval
          minval = maxval
          maxval = max_dummy
        }

        var client_id = _this.$session.get("client_id")
        var env_id = this.Environment.id;
        // control reach here, it means all things are valid.Now return data with success message
        var data={"env_id":env_id, "client_id":client_id, "business_object_list":selected_bo_list, "activity_list":selected_activities_list, "table_list":selected_tables_list,"from_date":_this.fromDate,"to_date":_this.toDate,"graph_type":_this.whichgraph,"min_val":minval,"max_val":maxval}
        return {"status":true,"data":data}
      },
      ValidateMinMax(e){
        if(["0","1","2","3","4","5","6","7","8","9"].indexOf(e.key) < 0){
            e.preventDefault()
            return
        }
      },
      GetData(){
        let _this = this
        var data = _this.ValidateAndGetFilterData()
        if(!data.status){
           _this.snackbar = true
          _this.colorValue = 'error'
          _this.snackbartext = data.error;
          return
        }
        // Valid data selected. 
        var data_to_send = data.data
        data_to_send["env_details"] = this.Environment
        postToServer(_this, config.JOB_STATISTIC_URL + '/statistics_details',data_to_send).then(response => {
          if(response){
            this.size_unit = "MB"
              this.count_unit = ''
            var data_list = []
            if(response.size_unit)
              this.size_unit = response.size_unit
            if(response.count_unit)
              this.count_unit = response.count_unit
            // this.tableList.headers = this.get_table_headers()
              // this.tableList.rows = []
              
            // Do sort and reverse to make list higher to lower
            if(this.whichgraph === "count")
              data_list = _.reverse(_.sortBy(response.table_data,'row_count'))
            else
              data_list = _.reverse(_.sortBy(response.table_data,'size'))
            this.data_list = data_list

            _this.$nextTick(function(){
              _this.tableList.rows = _.cloneDeep(_this.data_list)
            })

            var bar_limit = 10
            if(response.bar_limit)
              bar_limit = parseInt(response.bar_limit)
            response['bar_graph'] = data_list
            this.generategraphData(response,bar_limit)

          }
          
        }).catch(ErrorMessage => {
          _this.tableList.rows = [] 
          _this.data_list =[]
          _this.generategraphData({'bar_graph':[]})
          _this.echart_instance.setOption(_this.option, true)
          _this.pi_echart_instance.setOption(_this.pieoption, true)
          _this.snackbar = true
          _this.colorValue = 'error'
          _this.snackbartext = ErrorMessage;
        })
      },
      ResetFilter(){
        this.minval = null
        this.maxval = null
        this.toDate = null
        this.fromDate = null
        this.whichgraph ="size"
        this.BusinessObjectList.map(function(obj){
            obj.is_selected = true
          })
        this.activityType.map(function(obj){
            obj.is_selected = true
          })
        this.FilterTableList.map(function(obj){
            obj.is_selected = true
          })

        this.GetData()
      },
      ResetData(){
        if(this.echart_instance)
          this.echart_instance.setOption(this.option, true)
        if(this.pi_echart_instance)
        this.pi_echart_instance.setOption(this.pieoption, true)
        this.tableList.rows =[]
      },
      onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
         var data = _this.ValidateAndGetFilterData()
         var data_to_send = data.data
         data_to_send["recordType"] = downloadParams.recordType
         data_to_send["client_id"] = this.$session.get('client_id')
         data_to_send["row_option"] =downloadParams.recordType
         data_to_send["env_id"] = _this.Environment.id
         data_to_send["file_format"] =  downloadParams.fileType
         data_to_send["min_range"] = downloadParams.minRange|| null
         data_to_send["max_range"] = downloadParams.minRange|| null
         data_to_send["filter"] = filter
         data_to_send["graph_type"] = this.whichgraph


        // let inputJson={
        //     "recordType":downloadParams.recordType,
        //     "client_id": this.$session.get('client_id'),
        //     "row_option": downloadParams.recordType,
        //     "env_id": _this.Environment.id,
        //     "file_format": downloadParams.fileType,
        //     "min_range": downloadParams.minRange|| null,
        //     "max_range": downloadParams.maxRange||null,
        //     "filter": filter,
        //     "graph_type":this.whichgraph
        // }
        // var final_data = Object.assign({}, data, inputJson);


        var url = config.JOB_STATISTIC_URL+'/export_activity_table';    
        postToServer(this, url, data_to_send).then(response => {
            var url = config.JOB_STATISTIC_URL + "/static/" + response;
            window.open(url, '_blank');
            _this.loader=false;
            
        }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          });
    },
    }
  }
  </script>
  <style scoped>

  .echarts {
    width: 600px;
    height: 400px;
  }

  .piechart {
    width: 500px;
    height: 400px;
  }

  #radioControls .v-input--selection-controls {
   margin-top: 0px !important; 
   padding-top: 0px !important; 
  }

  .customCheckbox >>> label {
  top: 5px !important;
}
.v-input--selection-controls {
     margin-top: 2px; 
     padding-top: 1px; 
}

.EDC-ExpansionCol {
  height: 200px !important;
  overflow: auto !important;
  padding: 0px !important;
  margin: 0px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header{
  min-height:40px !important;
}

.v-expansion-panel-header {
  padding-top:0px !important;
  padding-bottom:0px !important
}
</style>
