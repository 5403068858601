<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col breadcrumbRow">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <router-link to="/jobplanlist">{{ execution_env_name }}</router-link>
                            </li>
                            <li>
                                <router-link to="/jobplanlist">Job Plan List</router-link>
                            </li>
                            <li>Manage Job Plan</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-card elevation-1>
                            <v-row class="EDC-Row">
                                <v-col cols="12" class="EDC-Col" style="padding:8px !important;">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col">
                                            <v-card elevation-1 width="100%">
                                                <v-row class="EDC-Row" align="center" >
                                                    <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                                                    <v-text-field ref="jobPlanName" label="Job Plan Name" v-model="name" type="text" autofocus @blur="untitle_name"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="3" class="EDC-EDC-ColsSpacing ">
                                                    <v-text-field v-model="description" label="Description" :rules="descRules" required/>
                                                    </v-col>
                                                    <v-col cols="1" class="EDC-Col EDC-ColsSpacing" style="float: left;">
                                                        <v-text-field min="1" max="10" v-model="paralleljobcount" type='number'  @blur='validateParellelJobCount' required label="Concurrency (Max.10)" title="Concurrent Jobs Allow To Run (Maximum 10 Jobs)"/>
                                                    </v-col>
                                                    <v-col class="EDC-Col EDC-ColsSpacing">
                                                        <v-autocomplete hide-details dense :items="action_on_error" item-text="action_description" 
                                                        item-value="id" v-model="actionOnError" label="Action On Job Failure">
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="EDC-Row" style="float: right;">
                                                    <v-col cols="12" class="EDC-Col">
                                                        <vc-button type="button" item-text="Save" @click.native="savePlan()"/>
                                                        <vc-button type="button" item-text="Cancel" @click.native="OnCancelBtn()"/>
                                                    </v-col>
                                                </v-row>   
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row">
                                        <v-col cols="12" class="EDC-Col">
                                            <edc-common-job-table :tableItemKey="'id'" :tableHeaders="tableHeaders"
                                            :tableActions="getTableActions" :tableRows="tableRows" :pageFor="'manageplan'"
                                            @addNewJobs="addNewJobs" @addMassJobs="addMassJobs" @onDeleteJobs="onDelete"
                                            :jobPlanId="job_plan_id" :jobPlanName="name" @updateJobFromJobPlan="updateJobFromJobPlan">
                                                 
                                            </edc-common-job-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <loading-panel :loader="loader"></loading-panel>
                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import config from "../../config.json"
    import { post as postToServer } from './../../methods/serverCall.js';
    import { CREATE_JOB_PLAN, UPDATE_JOB_PLAN, GET_JOB_PLAN,DELETE_JOB_PLAN_FROM_JOB } from '../../data/url_constants.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import CommonPlanJobsTable from './commonManageJobPlanAndInstance.vue'
    export default {
        name:'EdcManageJobPlan',
        components:{
            'edc-common-job-table':CommonPlanJobsTable
        },  
        data(){
            return{
                paralleljobcount:1,
                editJobObj:{},
                snackbar: false,
                snackbartext: '',
                colorValue: 'error',
                snackbartimeout: Snackbar_Default_Timeout,
                loader: false,
                name: "Untitled Name",
                description: "",
                tableHeaders: [{
                        text: 'Job / Plan Name',
                        value: 'pdDisplayName',
                        showHtml:true
                    },
                    {
                        text: 'Policy Type',
                        value: 'policy_type',
                    },
                    {
                        text: 'Policy Value',
                        value: 'display_policy',
                    },
                    {
                        text: 'Policy Date',
                        value: 'actual_policy_date',
                    },
                    {
                        text: 'Process Definition Id',
                        value: 'process_definition_id',
                        align:'right',
                    },
                    {
                        text: 'Object Type',
                        value: 'object_type_display',
                    },
                ],
                tableRows:[],
                tableActions:[],
                descRules: [
                    v => !!v || 'Description is required'
                ],
                job_plan_id:"",
                actionOnError:1,
                action_on_error:[],
                execution_environment:this.$route.params.execution_environment,
                execution_env_name:this.$route.params.execution_env_name,
            }
        },
        computed: {
            hasJobId(){
                return this.job_plan_id && this.job_plan_id > 0
            },
            getTableActions(){
                return [
                    {'text': 'newWidOptions', 'role':this.hasJobId, 'key': "sequence_no",index:4,isJobPlan:true,
                    availableOptions:[{title:'Add Job', event:'onAddJob'},{title:'Add Job Plan', event:'addJobPlan'}]},
                    {'text':'edit','key':"sequence_no",selectType:'single',role:true,index:1,'displayText':'Edit Jobs'},
                    {'text':'addmultiple','key':"has_policy",  selectType:"multiple", role:true,index:2, 'displayText':'Create Bulk Jobs', 'cmpValue':'Y'},
                    {'text':'delete','key':"sequence_no", selectType:"multiple", role:true,index:3},
                    {'text':'up','key':"sequence_no", selectType:"multiple", role:true,index:4,displayText:'Move Job Up'},
                    {'text':'down','key':"sequence_no", selectType:"multiple", role:true,index:5,displayText:'Move Job Down'},
                    {'text':'depadd','key':"sequence_no", selectType:"multiple", role:true,index:6,displayText:'Add Dependency'},
                    {'text':'depremove','key':"sequence_no", selectType:"multiple", role:true,index:7,displayText:'Remove Dependency'}
                ]
            }
        },
        mounted() {
            this.getAllJobPlanEventAction()
            this.job_plan_id = this.$route.params.job_plan_id
            if (!this.job_plan_id){
                this.get_default_action()
            }
            if(this.$route.params.job_plan_id)
            {
                this.getJobDetails(); 
            }
            else{
                this.$store.state.jobPlanDetails = {}
            }
        },
        methods: {
            OnCancelBtn(){
                this.$router.push('/jobplanlist')
            },
            untitle_name() {
                if (!this.name || !this.name.trim()) {
                    this.name = 'Untitled Name';
                }
            },
            validateParellelJobCount(){
                if(!this.paralleljobcount || isNaN(this.paralleljobcount)){
                    this.paralleljobcount =1
                    return
                }
                if(parseInt(this.paralleljobcount) < 1)
                        this.paralleljobcount = 1
                else if(parseInt(this.paralleljobcount) > 10)
                        this.paralleljobcount = 10
            },
            getAllJobPlanEventAction(){
                var _this =this
                var data = {
                    "client_id":this.clientId
                }
                postToServer(_this, config.JOB_PLAN_URL+'/get_all_event_action',data).then(response=>{
                    _this.action_on_error = response
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },

            get_default_action(){
                var _this =this
                var data = {
                    "client_id":this.clientId
                }
                postToServer(_this, config.JOB_PLAN_URL+'/get_default_action',data).then(response=>{
                    _this.actionOnError = response.event_action
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            getJobDetails(){
                let _this = this;  
                var job_data = {"job_plan_id": this.job_plan_id, 
                                "client_id":this.$session.get('client_id'),
                                "execution_environment": this.execution_environment,}
                let baseUrl = config.JOB_PLAN_URL+GET_JOB_PLAN;
                _this.loader = true
                postToServer(_this, baseUrl, job_data).then(response => {
                    _this.loader = false
                    _this.$store.state.jobPlanDetails = response;
                    _this.name = response.plan_name;
                    _this.job_plan_id= response.id;
                    _this.description = response.description;
                    _this.actionOnError= response.event_action
                    _this.paralleljobcount = response.parallel_job_count || 1
                    response.job_list.map(planObj=>{
                        // var policy_details = _.uniqBy(planObj.policy_details,"bo_id")
                        var policy_details = _.find(response.recursive_job_list_1,["id",planObj.id])
                        
                        if(policy_details && policy_details.policy_details)
                            policy_details = policy_details.policy_details
                        else
                            policy_details = planObj.policy_details

                        var policy_value = ''
                        var policy_type = ''
                        var policy_date = ''
                        planObj.has_policy = 'N'
                        if(policy_details && Array.isArray(policy_details) && policy_details.length){
                            planObj.has_policy = 'Y'
                            policy_details.map(obj=>{
                                var concated_string =  obj.policy_value + ' ' + obj.value_type
                                if(obj.already_calculated){
                                    concated_string = obj.actual_date + ' Actual Date'
                                }
                                policy_value = policy_value?policy_value + "," + concated_string:obj.policy_value + ' ' + obj.value_type
                                policy_type = policy_type?policy_type + ','+obj.bo_name+'.'+obj.policy_type:obj.bo_name+'.'+obj.policy_type

                                if(obj.actual_date)
                                    policy_date = policy_date? policy_date+","+obj.actual_date:obj.actual_date

                            })
                        }
                        planObj.policy_type = policy_type
                        planObj.display_policy = policy_value
                        planObj.actual_policy_date = policy_date
                        planObj.object_type_display = planObj.object_type
                        if(planObj.object_type){
                            planObj.object_type_display = planObj.object_type.charAt(0).toUpperCase()+planObj.object_type.substring(1)
                            planObj.object_type_display = planObj.object_type_display.replace('_',' ')
                        }
                    })
                    console.log(response.job_list)
                    _this.tableRows = response.job_list
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            getPlanInputData(jobArray){
                if(!jobArray || !jobArray.length)
                    jobArray = this.tableRows || []
                
                let client_id = this.$session.get('client_id');
                let data = {
                    "plan_name": this.name,
                    "client_id": client_id,
                    "execution_environment": this.execution_environment,
                    "execution_env_name": this.execution_env_name,
                    "job_list": jobArray,
                    "id":this.job_plan_id,
                    'description': this.description,
                    "parallel_job_count": this.paralleljobcount,
                    "actionOnError":this.actionOnError
                }
                return data;  
            },
            savePlan(jobArray){
                let _this = this
                var job_data = this.getPlanInputData(jobArray);
                let baseUrl = config.JOB_PLAN_URL+CREATE_JOB_PLAN;
                if(this.job_plan_id)
                    baseUrl = config.JOB_PLAN_URL+ UPDATE_JOB_PLAN;
                _this.loader = true
                postToServer(this, baseUrl, job_data).then(response => {
                    _this.loader = false
                    _this.$store.state.jobPlanDetails = response;
                    _this.job_plan_id = response.id;
                    _this.actionOnError= response.event_action
                    _this.getJobDetails()
                    _this.showMessage('Details Saved successfully','success')
                    
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            showMessage(message, type='error'){
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message;
            },
            addMassJobs(massJobs, JobsToDelete){
                this.onDelete(JobsToDelete, massJobs)
            },
            onDelete(record,mass_jobs){
                let _this = this; 
                let baseUrl = config.JOB_PLAN_URL+DELETE_JOB_PLAN_FROM_JOB; 
                var job_ids = _.map(record,"id")
                var delete_data = {"job_plan_id": this.job_plan_id,"job_plan_job_ids":job_ids,
                                    "actionOnError":_this.actionOnError, 
                                    "client_id":this.$session.get('client_id')}
                postToServer(this, baseUrl, delete_data).then(response => {
                    if(mass_jobs)
                        return _this.AddMultipleJobs(job_ids,mass_jobs)
                    _this.showMessage('Job(s) deleted successfully')
                    _this.getJobDetails()
                }).catch(CurrentJobError => {
                    _this.showMessage(CurrentJobError)
                })
            },
            AddMultipleJobs(deleted_job_ids,new_jobs){
                let _this = this
                var job_plan_data = _.cloneDeep(this.$store.state.jobPlanDetails)
                let jobList = job_plan_data.job_list
                for (var i = 0; i < deleted_job_ids.length; i++) {
                    var obj_index = _.findIndex(jobList,["id",deleted_job_ids[i]])

                    // remove child indent with respective of current job
                    for(var j=obj_index+1;j<jobList.length;j++){
                        // upto the next sibling. and sibling get decided on the indetnation
                        if(jobList[j].instance_details_indentation === jobList[obj_index].instance_details_indentation)
                            break;
                        else
                            jobList[j].indentation = jobList[j].instance_details_indentation - 1
                    }
                    if(obj_index > -1)
                        jobList.splice(obj_index,1)
                }
                for (var i = 0; i < new_jobs.length; i++) {
                    jobList.push(new_jobs[i])
                }
                _this.savePlan(jobList)
            },
            addNewJobs(jobList){
                this.savePlan(jobList)
            },
            updateJobFromJobPlan(jobData){
                var _this = this
                jobData['job_plan_id'] = this.job_plan_id
                let baseUrl = config.JOB_PLAN_URL+'/update_job_from_job_plan';
                this.loader = true
                postToServer(_this, baseUrl, jobData).then(response => {
					this.loader = false;
                    _this.showMessage('Job updated successfully')
                    _this.getJobDetails()
                }).catch(CurrentJobError => {
                    _this.showMessage(CurrentJobError)
                })
            }
        }
    }    
</script>