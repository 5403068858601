<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Scheduler History</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>    
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onSchedule="onSchedule" @onExecute="onExecute" @onDownload="onDownload" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_SCHEDULER_JOB_HISTORY, EXECUTE_NOW, EXPORT_SCHEDULED_HISTORY
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ClientJobListNew',
    data: function () {
        return {  
            headers: [{
                        text: 'Name',
                        value: 'name',
                        width: "20%",
                        title: 'Job Name'
                    },
                    {
                        text: 'Type',
                        value: 'is_recurring',
                        width: "10%",
                        title: 'Job Type'
                    },
                    {
                        text: 'Run Date',
                        value: 'timezone_aware_action_taken_date',
                        width: "20%",
                        title: 'Job Run Date',
                        dataType:'date'
                    },  
                    {
                        text: 'Run Time',
                        value: 'timezone_aware_action_taken_time',
                        width: "20%",
                        title: 'Job Run Time',
                        dataType:'time',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        width: "10%",
                        title: 'Job Status'
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: "20%",
                        title: 'Job Created By'
                    },{
                        text: 'Object ID',
                        value: 'object_id',
                        title: 'Object Id',
                        align:'right',
                        hideInlineFilter: true
                    }              
                ],     
                tableList: {
                headers:[],
                rows:[],
                actions:[],
                
            },   
            // tableList: {
            //     headers: [{
            //             text: 'Name',
            //             value: 'name',
            //             width: "20%",
            //             title: 'Job Name'
            //         },
            //         {
            //             text: 'Type',
            //             value: 'is_recurring',
            //             width: "10%",
            //             title: 'Job Type'
            //         },
            //         {
            //             text: 'Run Date',
            //             value: 'timezone_aware_action_taken_date',
            //             width: "20%",
            //             title: 'Job Run Date',
            //             dataType:'date'
            //         },  
            //         {
            //             text: 'Run Time',
            //             value: 'timezone_aware_action_taken_time',
            //             width: "20%",
            //             title: 'Job Run Time',
            //             dataType:'time',
            //             hideInlineFilter: true
            //         },
            //         {
            //             text: 'Status',
            //             value: 'status',
            //             width: "10%",
            //             title: 'Job Status'
            //         },
            //         {
            //             text: 'Created By',
            //             value: 'created_by',
            //             width: "20%",
            //             title: 'Job Created By'
            //         },{
            //             text: 'Object ID',
            //             value: 'object_id',
            //             title: 'Object Id',
            //             align:'right',
            //             hideInlineFilter: true
            //         }              
            //     ],
            //     actions: [{'text':'execute','key':"status", 'cmpValue':'MISSED',selectType:'single'},
            //                 {'text':'schedule','key':"status", 'cmpValue':'MISSED',selectType:'single'}], //if we need conditional action in row then provide key
            //     sorting_type: SERVER_SIDE,
            //     filterType: SERVER_SIDE,
            //     paginationType: SERVER_SIDE,
            //     // syncHeaderScroll:false,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows:[],
            //     hideSelect:true,
            // },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            loader:false,
            envIpJson : {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 10
            },
            loader:false,
            disableDefaltSorting:true,
        }
    },
    
    mounted() {
        this.envIpJson.page_size = manageScreenDisplay()
        this.tableList = this.getGridObj(this.headers,'status',false,{showSelect : false})
        this.getScheduledJobHistory (this.envIpJson);
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.SCHEDULER_URL+EXPORT_SCHEDULED_HISTORY;    
            postToServer(this, url, inputJson).then(Response => {
                var url = config.SCHEDULER_URL + "/static/" + Response;
                window.open(url, '_blank');
                
            });
        },
        onExecute(record){
            this.executeJob(record.id);
        },
        onSchedule(record){
              this.$router.push({ name: 'schedulejob', params: { job:record, type:'history'}});                        
        },
        executeJob(job_id){
          getToServer(this, config.SCHEDULER_URL + EXECUTE_NOW + job_id
          ).then(response => {
          if(response)
            // this.$toasted.success(response.message);
            this.colorValue = 'success'
            this.snackbar = true
            this.snackbartext = response.message;
            this.getScheduledJobHistory (this.envIpJson);
        }).catch(error_response => {
          if(error_response){
            this.colorValue = 'error'
            this.snackbartext = error_response;
            this.snackbar = true
          }
        //   this.$toasted.error(error_response);
          else{
              this.colorValue = 'error'
            this.snackbartext = 'There is some internal error, Pease try after some time';
            this.snackbar = true
          }
        //   this.$toasted.error('There is some internal error, Pease try after some time');
        });
        },

        getScheduledJobHistory(envIpJson) {
            let inputJson = this.envIpJson;
            var client_id = this.$session.get('client_id');
            let _this = this;
            var input_json = {
                  "client_id":client_id,
                  "filter": inputJson.filter,
                  "page": inputJson.page,
                  "page_size": inputJson.page_size,
                  "sort": inputJson.sort,
                }
            _this.loader = true;
            postToServer(this, config.SCHEDULER_URL + GET_SCHEDULER_JOB_HISTORY, input_json
              ).then(response => {
                _this.loader = false;
              if(response){
                  response.result.map(obj=>{
                        obj["object_id"] = JSON.parse(obj.params).process_definition_id
                        obj.is_recurring = obj.is_recurring == false ? 'One Time' : 'Recurring' 

                  });
                  _this.tableList.rows = response.result;
                  _this.tableList.total_count = response.total;
                  _this.tableList.disableDefaltSorting = true
              }
            }).catch(error_response => {
                _this.loader = false;
              if(error_response){
                  this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
            });    
        },

        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getScheduledJobHistory(inputJson);
        },

        onPerPageChange(perPage, page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getScheduledJobHistory(inputJson);
        },

        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getScheduledJobHistory(inputJson);
        },

        filterData(filterArray, pageno) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getScheduledJobHistory(inputJson);
        },
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>