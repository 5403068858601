import {get as getToServer,post as postToServer } from './serverCall.js';
import config from '../config.json'
import {GET_REVISED_ALL_MAPPED_ENV, ENVIRONMENTLIST_BY_CLIENT} from '../data/url_constants'
export default function(_this){
	return new Promise(function(resolve, reject) {
		getToServer(_this, config.ENVIRONMENT_API_URL + '/get_all_environment'+'/' + _this.$session.get('client_id'), true).then(response => {
			return resolve(response);
		},response => {
			return reject(response);
		})    
	})
}

export function getEnvironmentList(_this,is_from_obj_mgmnt_page,is_from_env_page, is_from_approval,use_service_token) {
    // is_from_obj_mgmnt_page - parameter used for add 2 extra env in case of call come from Object management page
	// is_from_env_page - paramter used for refresh env list incase of when user visit manage_env page and update something.
    //that should need to capture.
    // use_service_token this variable used to fetch all env using service token 
    // becuase in environment service we are fetching roles based on tokenq
    if(_this.$session.get('env_list') && !is_from_env_page)
	{
		_this.EnvironmentList = _this.$session.get('env_list')
        if(is_from_obj_mgmnt_page){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            // _this.EnvironmentList.push({'id': '0', 'name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
        }
		return
	}
    let data = {"client_id":_this.$session.get('client_id')}
    
	postToServer(_this, config.ENVIRONMENT_API_URL + GET_REVISED_ALL_MAPPED_ENV, data ,use_service_token).then(response => {
	if(response){
		_this.$session.set('env_list',response)
        if(is_from_env_page)
            return true
		_this.EnvironmentList = response
        if(is_from_obj_mgmnt_page){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            _this.EnvironmentList.push({'id': '0', 'name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
        }

            // console.log(this.EnvironmentList)
        } else {
        	_this.errorMsg = response
        }
        // _this.focus_env()
    },response => {
        _this.ShowErrorMessage=true
        _this.ErrorMessage=response
    }).catch(EnvironmentError => {
    	if(EnvironmentError){
            // this[l]  = false
            _this.loader = null 
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = EnvironmentError;
        }
        else {
        	_this.snackbar = true
        	_this.colorValue = 'error'
        	_this.snackbartext = SERVER_ERROR;
        }
    })
 }

 export function EnviromentChanged(that){
    try {
        if(!that.$session || !that.$session.get('selected_env') || !that.$session.get('selected_env').id)
            return false
        if(that.$session.get('selected_env').id === that.$session.get('last_selected_env_id'))
            return false
        if(!Number.isInteger(that.$session.get('selected_env').id) || parseInt(that.$session.get('selected_env').id) <= 0)
            return false
        
        if(config.DOMAIN_NAME){
          that.$cookies.set('last_selected_env_id',that.$session.get('selected_env').id,null,null,config.DOMAIN_NAME)
        }

        that.$session.set('last_selected_env_id',that.$session.get('selected_env').id)
        var data_to_send = {"last_env_used":that.$session.get('selected_env').id.toString(),
        "client_id":that.$session.get('client_id'),
        "product_name": that.$session.get('product'),'user_name':that.$session.get('email')}
        var url = config.USER_PROVISION_URL + '/add_user_preference'
        postToServer(that,url,data_to_send,false,true).then(response=>{

        }).catch(Error=>{

        })
    }
    catch(err){

    }
 }


 export function environmentSessionBinding(that,environmentList){
    if(environmentList.length <0)
        return false
    if(!that.$session.get('last_selected_env_id'))
        return false
    var obj = _.find(environmentList,["id",parseInt(that.$session.get('last_selected_env_id'))])
    if(!obj)
        return false
    that.Environment = obj
    that.$session.set('selected_env',obj)
 }