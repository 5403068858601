<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getAllActivities" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li v-if="isActivity">Summarization Activity</li>
              <li v-else>Summarization Activity History</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-dialog v-model="filter_dialog" width="1000" scrollable style="overflow-y:none" persistent>
      <v-col>
        <v-card class="rounded-card" style="height:340px">
          <v-toolbar dark dense>
            <v-col class="text-md-center">
              {{ activity_type }} {{ selectedRows.length }} Summarization
              <v-icon class="text-lg-left" style="color:#dedede; height:22px; float:right" @click="filter_dialog = false">fa-times-circle</v-icon>
            </v-col>
          </v-toolbar>
          <v-col cols="12" id="step_detail_table" style="height:225px; overflow-y:scroll" v-if="activity_type === 'Enquiry'">
            <new-edc-criteria :columns_for_filter="filter_rows_list" :column_name_list="avlColumnList" @savedata="saveCriteria" :new_columns_for_filter="main_filterUI"/>
          </v-col>
            <v-row class="EDC-Row" style="float:right; padding-right:20px !important">
              <v-col cols="12" class="EDC-Col">
                <toggle-button v-model="ExecutionMode" :labels="{checked: 'Proof', unchecked: 'Final'}" :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28" :sync="true" style="margin-top:10px !important; font-size:10px !important" v-show="!is_enquiry"/>

                <vc-button  itemText="Run Job" v-show="!is_enquiry" @click.native="CreateNewJob()"></vc-button>

                <vc-button itemText="Schedule Job" v-show="!is_enquiry" @click.native="CreateScheduleJob()"></vc-button>

                <vc-button itemText="Show Data" v-show="is_enquiry" @click.native="ShowData"></vc-button>

                <vc-button  itemText="Cancel" @click.native="onCancel()"></vc-button>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-dialog>

    <edc-data-grid :dataList="tableList" :loader="loader" @onRestore="onRestore"
      @onSelectedRows="onSelectedRows" @onEnquiry="onEnquiry"  @onDownload="onDownload"
      @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange"
      @onSort="sortBy" @ondblClick="ondblClick" @onDetailedHistory="onEnquiry($event,true)">
    </edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
  import moment from 'moment'
  import config from './../../../config.json'
  import {post as postToServer } from './../../../methods/serverCall.js';
  import {SERVER_ERROR} from './../../../data/client_message.js'
  import {ACTIVITY_TYPE_RESTORE,ACTIVITY_TYPE_SELECTIVE_RESTORE} from './../../../data/job_management.js'
  import {GETALLACTIVITIES, CREATE_NEW_JOB,
    VALIDATE_ACTIVITY_FILTER, RUN_COORDINATION, EXPORT_ACTIVITY_DETAILS}
    from './../../../data/url_constants.js';
  import {CLIENT_SIDE, SERVER_SIDE} from './../../../data/macros.js'
  import _ from 'lodash'
  import criteria from "@/views/newcriteria.vue";
  import {
    COLOR_CODE, BTN_COLOR
  } from "@/data/macros.js";
  import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
  import getUserRole from '../../../methods/GetUserRole.js'
  import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
  import {manageScreenDisplay} from '@/methods/special.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'
  import {SUMMARIZE_F0911_TYPE, F0911_CATALOG_TABLE_NAME,
    F0911_CATALOG_D_TABLE_NAME,F0911_CATALOG_COLUMN_LIST,
    F0911_D_CATALOG_COLUMN_LIST, SUMMARIZE_F4111_TYPE,
    F4111_CATALOG_TABLE_NAME, F4111_CATALOG_D_TABLE_NAME,
    F4111_CATALOG_COLUMN_LIST, F4111_D_CATALOG_COLUMN_LIST}
  from './../../../data/summarization_constant.js'


  export default {
    name: 'SummarizationManagement',
    components: {
      'new-edc-criteria': criteria
    },
    data: function () {
      return {
        main_filterUI:[{
          's_parentheses': '',
          'column_name': '',
          'operator': '',
          'v_type1': 'Value',
          'v_type2': 'Value',
          'value1': '',
          'value2':'',
          'e_parentheses': '',
          'is_drv_table': false,
          'ref_row_id':'',
          'ref_step_id':''
        }],
        colorCode:COLOR_CODE,
        outlineColor:BTN_COLOR,
        showfailed_reason:false,
        job_list: [],
        selected_process_definition:'',
        live_satus: [],
        job_failed_reason:'',
        headers: [
          { text: 'Activity ID', value:'activity_id', width: '130px', align:'right', dataType:'int'},
          { text: 'Activity Type', value:'activity_display_name', width: '130px',title:'Activity Type'},
          { text: 'Process Definition Name', value: 'process_definition_name', width: '200px', cssClass:'w16'},
          { text: 'Ver', value: 'pd_revision', width: '80px' ,align:'right', title: 'Process Definition Version', dataType:'int'},
          { text: 'Processed Bf', value:'localize_processed_bf_count', width: '130px', align:'right', dataType:'int',title:"Processed BF count"},
          { text: 'Summarized count', value:'localize_summarized_count', width: '130px', align:'right', dataType:'int',title:'Summmarized record count '},
          { text: 'Skipped count', value:'localize_skipped_count', width: '130px', align:'right', dataType:'int',title:'skipped record count due to F0902 Archive check'},
          { text: 'Data Older Than' , value: "data_older_than", width: '170px', cssClass:'w16', title: 'Summarized Data Older Than', dataType:'date',hideInlineFilter:true},
          { text: 'Age', value: "age", width: '100px', title: 'Data Age', hideInlineFilter:true},
          { text: 'Job Id' , value: "job_id", width: '120px',align:'right', dataType:'int'},
          { text: 'Performed By' , value: "created_by", width: '180px', cssClass:'w16'},
          { text: 'Performed Date', value: 'activity_date', width: '200px', cssClass:'w16', dataType:'date',hideInlineFilter: true},
          { text: 'Performed Time', value: 'activity_time', width: '200px', cssClass:'w16', dataType:'time', hideInlineFilter: true},
          { text: 'Object ID', value: 'process_definition_id', width: '200px', cssClass:'w16', align: 'right'},
          ],
        tableList: {
          headers:[],
          actions:[],
          rows:[]
        },
        // tableList: {
        //   headers: [
        //   { text: 'Activity ID', value:'activity_id', width: '130px', align:'right', dataType:'int'},
        //   { text: 'Activity Type', value:'activity_display_name', width: '130px',title:'Activity Type'},
        //   { text: 'Process Definition Name', value: 'process_definition_name', width: '200px', cssClass:'w16'},
        //   { text: 'Ver', value: 'pd_revision', width: '80px' ,align:'right', title: 'Process Definition Version', dataType:'int'},
        //   { text: 'Processed Bf', value:'localize_processed_bf_count', width: '130px', align:'right', dataType:'int',title:"Processed BF count"},
        //   { text: 'Summarized count', value:'localize_summarized_count', width: '130px', align:'right', dataType:'int',title:'Summmarized record count '},
        //   { text: 'Skipped count', value:'localize_skipped_count', width: '130px', align:'right', dataType:'int',title:'skipped record count due to F0902 Archive check'},
        //   { text: 'Data Older Than' , value: "data_older_than", width: '170px', cssClass:'w16', title: 'Summarized Data Older Than', dataType:'date',hideInlineFilter:true},
        //   { text: 'Age', value: "age", width: '100px', title: 'Data Age', hideInlineFilter:true},
        //   { text: 'Job Id' , value: "job_id", width: '120px',align:'right', dataType:'int'},
        //   { text: 'Performed By' , value: "created_by", width: '180px', cssClass:'w16'},
        //   { text: 'Performed Date', value: 'activity_date', width: '200px', cssClass:'w16', dataType:'date',hideInlineFilter: true},
        //   { text: 'Performed Time', value: 'activity_time', width: '200px', cssClass:'w16', dataType:'time', hideInlineFilter: true},
        //   { text: 'Object ID', value: 'process_definition_id', width: '200px', cssClass:'w16', align: 'right'},
        //   ],
        //   actions:[],
        //   sorting_type: SERVER_SIDE,
        //   filterType: SERVER_SIDE,
        //   paginationType: SERVER_SIDE,
        //   total_count: 0,
        //   rows: [],
        //   selected_rows: [],
        //   disableDefaltSorting:true,
        //   itemkey:'activity_id'
        // },
        // sorting : config.SORTING_TYPE,
        // envIpJson : {
        //   "filter": [],
        //   "sort": [{
        //     "column_name": "",
        //     "type": ""
        //   }],
        //   "page": 1,
        //   "page_size": 10
        // },
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        auto_increment_id:0,
        snackbar:false,
        snackbartext:'',
        colorValue:'success',
        snackbartimeout: Snackbar_Default_Timeout,
        ExecutionMode: true,
        valid:true,
        loader:false,
        Environment:{},
        EnvironmentList:[],
        timer_timespan :3000,
        selectedRows:[],
        filter_dialog:false,
        avlColumnList:[],
        filter_rows_list:[],
        catalog_table_name:'',
        activity_type:'',
        pd_revisions:'',
        filter_column_tbl_obj: {
          s_parentheses: "",
          column_name: "",
          operators: "",
          v_type: "Value",
          value1: "",
          value2: "",
          e_parentheses: "",
          table_name:""
        },
        userRole:{},
        is_enquiry:false,
        current_row:{},
        enquery_activity_type:ACTIVITY_TYPE_RESTORE,
        step_list:['Archival','Purge','Copy'],
        is_action:false,
        Interval:null,
        search_type:"DB",
        isActivity:true, // defualt load history
        isCallInProgress:false,
      }
    },
    watch:{
        '$store.state.sameComponentRedirectObj':{
            handler(newvalue,oldvalue){
              if(_.isEmpty(newvalue))
                return
              this.isActivity = newvalue.isActivity
              this.managePage()
            },
            deep:true
        },
    },
    mounted() {
     this.avlColumnList = []
     this.selectedRows = []
     this.catalog_table_name =''
      this.tableList = this.getGridObj(this.headers,'activity_id',false)
     this.userRole = this.$session.get('user_role_mapping');
     this.tableList.actions = [
              {'text':'restore','key':"write_in_catalog",selectType:"single",index:1,'displayText':'Unsummarize',role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),cmpValue:'Y'},
              {'text':'enquiry','key':"activity_id",selectType:"multiple",index:2,'displayText':'Show BF Entries'},
              {'text':'detailedHistory','key':"activity_id",selectType:"multiple",index:2, "displayText":"Show Summarized Entries"}
      ]
      var env_value = this.$session.get('selected_env')
      if( env_value && env_value.id != '0' && env_value.id !='all_env'){
        this.Environment = this.$session.get('selected_env')
      }
    getEnvironmentList(this);
    this.focus_env()
    this.managePage()
    },
    beforeDestroy() {
      clearInterval(this.Interval)
    },
    methods: {
      managePage(){
        var _this = this
        // to avoid double call as well as load History data user refresh page manually
        if(this.isCallInProgress){
          setTimeout(()=>{
            _this.isCallInProgress = false
          },50)
          return
        }

        this.isCallInProgress = true
        this.envIpJson = {
          "filter": [],
          "sort": [{
            "column_name": "",
            "type": ""
          }],
          "page": 1,
          "page_size": 10
        }
        this.envIpJson.page_size = manageScreenDisplay()

        this.getAllActivities(this.envIpJson)
      },
      onDownload(downloadParams, filterArray){
        let _this = this;
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(_.cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "row_option": downloadParams.recordType,
            "env_id": _this.Environment.id,
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.ENGINE_API_URL+EXPORT_ACTIVITY_DETAILS
        postToServer(_this, url, inputJson).then(Response => {
          var url = config.ENGINE_API_URL + "/static/" + Response;
          window.open(url, '_blank');
          _this.loader=false;
        }).catch(objError => {
          _this.loader=false;
          if(objError){
              _this.loader = null
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = objError;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
            }
        });
      },
      focus_env(){
        if(this.$refs.envname.$el)
          this.$refs.envname.$el.focus()
        else this.$refs.envname.focus()
      },
      getAllActivities (envIpJson) {
        let _this = this;
        let inputJson = this.envIpJson;
        this.$store.state.boActivityData = []
        this.tableList.rows = []
        if(!this.Environment.id)
          return false
        this.loader = true;
        _this.$session.set('selected_env', this.Environment)
        var env_id = this.Environment.id;
        var client_id = this.$session.get('client_id')
        var data = {"client_id": client_id,
                    "env_id": env_id,
                    "filter": inputJson.filter,
                    "page": inputJson.page,
                    "page_size": inputJson.page_size,
                    "sort": inputJson.sort,
                    "isActivity":this.isActivity}
        postToServer(this, config.ENGINE_API_URL + '/summarized_activity', data).then(response => {
          _this.isCallInProgress = false
          var data = response
          if(data){
            _this.activity_list = data.result;
            var total_count = data.total
            _this.tableList.rows =  _this.activity_list
             this.tableList.total_count = total_count;
          }
          _this.loader =false
        }).catch(CurrentJobError => {
          _this.isCallInProgress = false
          _this.loader =false
          if(CurrentJobError){
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = CurrentJobError;
          }
          else {
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = SERVER_ERROR;
          }
        })
      },
      filterData(filterArray,pageno){
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.filter=filterArray;
        inputJson.page=1
        this.getAllActivities(inputJson)
      },
      onPageChange(page, perPage) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getAllActivities(inputJson);
      },
      onPerPageChange(perPage,page) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getAllActivities(inputJson);
      },
      sortBy: function (sortJson) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.sort = sortJson
          this.getAllActivities(inputJson);
      },
      onRestore: function(record) {
        this.PerformOps('R')
      },
      onSelectedRows(selectedRows){
        this.selectedRows = selectedRows;
        console.log(this.selectedRows)
      },
      onEnquiry: function(record,is_history){
        this.is_action = true
        this.current_row = record[0]
        if(this.current_row.write_in_catalog != 'Y'){
          alert("Catalog writer is off for selected record")
          return
        }
        console.log(record)
        this.activity_type ='Enquiry'
        if(_.uniq(_.map(record,'activity_type')).length >1){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Select same type of activity';
          return false
        }
        this.enquery_activity_type = this.current_row.activity_type
        if (this.step_list.indexOf(this.current_row.activity) >= 0) {
          this.is_action = false
        }
        this.PerformOps('E',is_history)
      },
      GetData(){
        var client_id = this.$session.get('client_id')
        // get policy_details
        var activity_ids = _.map(this.selectedRows,'activity_id')
        var filter_data = _.cloneDeep(this.filter_rows_list)
        if(filter_data.length === 1 && (!filter_data[0].column_name ||!filter_data[0].operator))
          filter_data = []
        if(filter_data.length >= 1){
          // FILTER MEANS SELECTIVE ACTION AND BASED ON BUTTON CLICK WE DECIDE RESTORE OR DISPOSE
          if(this.activity_type === ACTIVITY_TYPE_RESTORE)
            this.activity_type = ACTIVITY_TYPE_SELECTIVE_RESTORE
        }
        var other_activity_details = []
          for (var i =0; i < this.selectedRows.length; i++) {
            var row = this.selectedRows[i]
            other_activity_details.push({"activity_id":row["activity_id"],
                                          "driver_count":row["driver_table_count"],
                                          "data_older_than":this.convertDateFormat(row["data_older_than"]),
                                          "can_dispose_on":this.convertDateFormat(row["can_dispose_on"]),
                                          "created_date":this.convertDateFormat(row["created_date"]),
                                          "created_by_job":row["created_by_job"]})
          }
          return {
            'client_id': client_id,
            'process_definition_id': this.process_definition_id,
            'process_definition_name': this.process_definition_name,
            'created_by': this.$session.get('email'),
            'activity_ids':activity_ids,
            'activity_type': this.selectedRows[0].activity_type === 'Jde_summ_f0911'?'F0911_unsummarize':'F4111_unsummarize',
            'execution_environment': this.Environment.id,
            'execution_env_name': this.Environment.name,
            'mode_of_execution':this.ExecutionMode ? 'proof':'final',
            'exe_type':'on_demand',
            'filter_data':filter_data,
            'table_name':this.catalog_table_name,
            'pd_revision':this.pd_revision,
            'created_by_job':this.created_by_job,
            'other_activity_details':other_activity_details,
            "summarization_type":this.selectedRows[0].activity_type === 'Jde_summ_f0911'?'f0911':'f4111',
            "job_type":"restore"
          }
      },
      convertDateFormat(date_to_convert){
        if (!date_to_convert)
            return ''
        var moment_obj = moment.utc(date_to_convert,this.$session.get('UI_date_format'))
        var fomrated_date = moment(moment_obj).format('YYYY-MM-DD')
        return fomrated_date
      },
      CreateNewJob(){
        var data = this.GetData()
        this.Interval = null
        console.log(data)
        postToServer(this, config.COORDINATOR_API_URL + RUN_COORDINATION, data).then(response => {
          if (response) {
            this.ShowErrorMessage=false;
            this.ShowSuccessMessage = true;
            this.sucessMessage = response.body;
          }}).catch(ProcessDefError => {
            clearInterval(this.Interval)
            if(ProcessDefError){
              this.loader = null
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = ProcessDefError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
          })
        this.Interval = setInterval(() => this.$router.push('/currentjoblist'), 1000);
      },
      CreateScheduleJob(){
       var data = this.GetData()
       data['is_one_time_schedule'] =true
       data['is_restored'] = true
       data['exe_type']='on_schedule'
       this.$router.push({'name' : 'schedulejob', 'params' : { schedule_data: data, type:'new', service_id:config.COORDINATOR_SERVICE_ID, category:'Job', end_point: config.END_POINT_FOR_SCHEDULER}});
     },
     ShowData(){
      var record = this.current_row
      var filter_data = _.cloneDeep(this.filter_rows_list)
      if(filter_data.length === 1 && (!filter_data[0].column_name ||!filter_data[0].operator))
        filter_data = []
      let paramsData = {name: 'showacitvitydata', params: {'activity_ids':_.map(this.selectedRows,'activity_id'),'is_action':this.is_action,'env_id':this.Environment.id,'env_name':this.Environment.name,'bo_name':record.process_definition_name,'activity':this.enquery_activity_type,'filter_data':filter_data,'show_summarization':true}}
      if (record.activity_type == SUMMARIZE_F0911_TYPE){
        paramsData['params']['summarizeFor'] = 'F0911'
        paramsData['params']['summarizationEntryType'] = 'BF'
        paramsData['params']['table_name'] = F0911_CATALOG_TABLE_NAME
        paramsData['params']['unique_identifiers'] = F0911_CATALOG_COLUMN_LIST
        paramsData['params']['drilldownUniqueIdentifiers'] = F0911_D_CATALOG_COLUMN_LIST
        paramsData['params']['drilldownTableName'] = F0911_CATALOG_D_TABLE_NAME
        if(this.search_type=="DB")
          {
            paramsData['params']['table_name'] = F0911_CATALOG_D_TABLE_NAME
            paramsData['params']['unique_identifiers'] = F0911_D_CATALOG_COLUMN_LIST
            paramsData['params']['summarizationEntryType'] = 'Details'
            paramsData['params']['specific_to'] = 'details'
            paramsData['params']['drilldownUniqueIdentifiers'] = F0911_CATALOG_COLUMN_LIST
            paramsData['params']['drilldownTableName'] = F0911_CATALOG_TABLE_NAME
          }
        }
        else if (record.activity_type == SUMMARIZE_F4111_TYPE){
          paramsData['params']['summarizeFor'] = 'F4111'
          paramsData['params']['summarizationEntryType'] = 'BF'
          paramsData['params']['table_name'] = F4111_CATALOG_TABLE_NAME
          paramsData['params']['unique_identifiers'] = F4111_CATALOG_COLUMN_LIST
          paramsData['params']['drilldownUniqueIdentifiers'] = F4111_D_CATALOG_COLUMN_LIST
          paramsData['params']['drilldownTableName'] = F4111_CATALOG_D_TABLE_NAME
          if(this.search_type=="DB")
              {
                paramsData['params']['table_name'] = F4111_CATALOG_D_TABLE_NAME
                paramsData['params']['unique_identifiers'] = F4111_D_CATALOG_COLUMN_LIST
                paramsData['params']['summarizationEntryType'] = 'Details'
                paramsData['params']['specific_to'] = 'details'
                paramsData['params']['drilldownUniqueIdentifiers'] = F4111_CATALOG_COLUMN_LIST
                paramsData['params']['drilldownTableName'] = F4111_CATALOG_TABLE_NAME
              }
          }
          this.$router.push(paramsData);
      },
      ondblClick(record){
        return
        this.tableList.selected_rows = [record]
        this.onRestore()
      },
      PerformOps(type,is_history){
        this.is_enquiry = false
        this.disable_final_mode = false
        this.selectedRows = this.tableList.selectedRows
        this.search_type = "BF"
        if(is_history)
          this.search_type = 'DB'
        this.avlColumnList = []
        this.catalog_table_name =''
        var record = this.selectedRows[0]
        this.current_row = record

        if (record.activity_type == SUMMARIZE_F0911_TYPE){
          this.avlColumnList = F0911_CATALOG_COLUMN_LIST
          if(this.search_type=="DB")
            {
              this.avlColumnList = F0911_D_CATALOG_COLUMN_LIST
            }
          }
        else if (record.activity_type == SUMMARIZE_F4111_TYPE){
          this.avlColumnList = F4111_CATALOG_COLUMN_LIST
          if(this.search_type=="DB")
              {
                this.avlColumnList = F4111_D_CATALOG_COLUMN_LIST
              }
          }
        this.process_definition_id = this.selectedRows[0].process_definition_id
        this.process_definition_name = this.selectedRows[0].process_definition_name
        this.pd_revision = this.selectedRows[0].pd_revision
        this.created_by_job = this.selectedRows[0].job_id
        if(type==='R')
          this.activity_type = ACTIVITY_TYPE_RESTORE
        else if (type === 'E'){
          this.is_enquiry = true
          this.activity_type = 'Enquiry'
        }
        // this.filter_rows_list = this.avlColumnList
        if(!this.filter_rows_list.length)
          this.add_new_filter_row()

        this.filter_dialog =true
        // if(type === 'E'){
        // }
          // this.validateFilter()
      },
      add_new_filter_row() {
        var current_filter_length = this.filter_rows_list.length;
        if (current_filter_length > 0) {
          if (!this.filter_rows_list[current_filter_length - 1]["operation"])
            this.filter_rows_list[current_filter_length - 1]["operation"] = false;
        }
        var obj = _.cloneDeep(this.filter_column_tbl_obj);
        obj["id"] = this.get_new_id();
        obj["table_name"] = this.catalog_table_name
        this.filter_rows_list.push(obj);
      },
      delete_filter_row(idx) {
        var current_filter_length = this.filter_rows_list.length;
        if (current_filter_length > 1 && current_filter_length - 1 === idx)
          delete this.filter_rows_list[idx - 1]["operation"];
        this.filter_rows_list.splice(idx, 1);
        if (this.filter_rows_list.length === 0) {
          var obj = _.cloneDeep(this.filter_column_tbl_obj);
          this.filter_rows_list.push(obj);
        }
      },
      saveCriteria(columns_for_filter) {
        this.filter_rows_list = columns_for_filter;
        console.log('filters', this.filter_rows_list)
      },
      get_new_id() {
        return ++this.auto_increment_id;
      },
      onCancel(){
        this.filter_dialog = false
      },
      validateFilter(call_type){
        var filter_json = {'filter_data':this.filter_rows_list}
        postToServer(this, config.VALIDATION_URL + VALIDATE_ACTIVITY_FILTER, filter_json).then(response => {
          console.log(response)
          if (response) {
           if(typeof response == 'string')
           {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }
          else{
            var empty_json ={}
            if(typeof response =='object' && response.filter_data){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Please specify valid filter';
            }
        }}}).catch(ProcessDefError => {})
      },
    }
  }
  </script>
  <style scoped>

  .radioClass >>> label{
    top: 5px !important;
  }
  .radioClass >>> i{
    font-size: 30px
  }

  /* tr:nth-child(even) {background-color: #E8E8E8;}
  .blurOpacity{
    opacity: 0;
  }
  .showInput {
    visibility: inherit !important;
    width: 50% !important;
  }

  .srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
  }

  table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
  }

  thead {
    line-height: 30px;
    transition: all 10s;
  }

  th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }

  .srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
  } */
  </style>
