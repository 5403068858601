<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getAllActivities" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li v-if="$route.params && $route.params.isFromJobStatus">
                <router-link to="/currentjoblist">Job Status</router-link>
              </li>
              <li>Business Object Activity</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-dialog v-model="filter_dialog" width="1000" scrollable style="overflow-y:none" persistent>
      <v-col>
        <v-card class="rounded-card" style="height:340px">
          <v-toolbar dark dense>
            <v-col class="text-md-center">
              {{ activity_type }} {{ selectedRows.length }} Activities
              <v-icon class="text-lg-left" style="color:#dedede; height:22px; float:right" @click="filter_dialog = false">fa-times-circle</v-icon>
            </v-col>
          </v-toolbar>
          <!-- <v-row style="width:100%;">
            <v-col cols="6">
              <v-row v-show="!is_enquiry" style="width:90%">
                <v-col cols="4" style="text-align:left">
                  <label class="v-label" style="margin-top:15%; text-align:left">Select Mode:
                  </label>
                </v-col>
                <v-col cols="4">
                  <toggle-button v-model="ExecutionMode" :labels="{checked: 'Proof', unchecked: 'Final'}" :color="{'checked':'grey','unchecked':'grey'}" style="margin-top:12%; text-align:left; margin-left:0%" :width="80" :height="30" :sync="true"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
          <v-col cols="12" id="step_detail_table" style="height:225px; overflow-y:scroll">
            <new-edc-criteria :columns_for_filter="filter_rows_list" :column_name_list="avlColumnList" @savedata="saveCriteria" :new_columns_for_filter="main_filterUI"/>
          </v-col>
            <v-row class="EDC-Row" style="float:right; padding-right:20px !important">
              <v-col cols="12" class="EDC-Col">
                <toggle-button v-model="ExecutionMode" :labels="{checked: 'Proof', unchecked: 'Final'}" :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28" :sync="true" style="margin-top:10px !important; font-size:10px !important" v-show="!is_enquiry"/>

                <vc-button  itemText="Run Job" v-show="!is_enquiry" @click.native="validateFilter('job')"></vc-button>

                <vc-button itemText="Schedule Job" v-show="!is_enquiry" @click.native="validateFilter('schedule')"></vc-button>

                <vc-button itemText="Show Data" v-show="is_enquiry" @click.native="ShowData"></vc-button>

                <vc-button  itemText="Cancel" @click.native="onCancel()"></vc-button>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-dialog>

    <edc-data-grid :dataList="tableList" :loader="loader" @onRestore="onRestore" @onSelectedRows="onSelectedRows"
    @onDispose="onDispose" @onEnquiry="onEnquiry" @onDownload="onDownload"
    @onDeleteCopy="onDeleteCopy" @UpdateFilter="filterData" @onUnsync="onUnsync"
    @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy"
     @ondblClick="ondblClick" :predefinedInlineFilter="predefinedInlineFilter"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
  import moment from 'moment'
  import config from './../../../config.json'
  import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
  import {SERVER_ERROR} from './../../../data/client_message.js'
  import {ACTIVITY_TYPE_RESTORE,ACTIVITY_TYPE_SELECTIVE_RESTORE, ACTIVITY_TYPE_DISPOSE, BULK_RESTORE, ACTIVITY_TYPE_SELECTIVE_DISPOSE,UN_SYNC_UP,ACTIVITY_TYPE_DELETE_COPY} from './../../../data/job_management.js'
  import {GETALLACTIVITIES, CREATE_NEW_JOB,
  GETBUSINESS_OBJECT_BY_ENV,VALIDATE_ACTIVITY_FILTER, RUN_COORDINATION, EXPORT_ACTIVITY_DETAILS}
  from './../../../data/url_constants.js';
  import {CLIENT_SIDE, SERVER_SIDE} from './../../../data/macros.js'
  import cloneDeep from 'lodash/cloneDeep';
  import orderBy from 'lodash/orderBy';
  import _ from 'lodash'
  import {mapActions} from 'vuex'
  import criteria from "@/views/newcriteria.vue";
  import {
    COLOR_CODE, BTN_COLOR
  } from "@/data/macros.js";
  import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
  import getUserRole from '../../../methods/GetUserRole.js'
  import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
  import {manageScreenDisplay} from '@/methods/special.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'

  export default {
    components: {
      'new-edc-criteria': criteria
    },
    // name: 'ClientJobListNew',
    data: function () {
      return {
        main_filterUI:[{
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      }],
        colorCode:COLOR_CODE,
        outlineColor:BTN_COLOR,
        showfailed_reason:false,
        job_list: [],
        selected_process_definition:'',
        live_satus: [],
        job_failed_reason:'',
        headers: [
          { text: 'Activity ID', value:'activity_id', width: '130px', align:'right', dataType:'int'},
          { text: 'Business Object', value: 'business_object_name', width: '200px', cssClass:'w16'},
          { text: 'Ver', value: 'bo_revision', width: '80px' ,align:'right', title: 'Business Object Version', dataType:'int'},
          { text: 'Activity' , value: "activity", width: '140px'},
          { text: 'Job Id' , value: "job_id", width: '120px',align:'right', dataType:'int','filtertext':''},
          { text: 'Driver Count', value: 'driver_table_count', width: '150px',align:'right', cssClass:'w16', dataType:'int'},
          { text: 'Performed By' , value: "created_by", width: '180px', cssClass:'w16'},
          { text: 'Performed Date', value: 'activity_date', width: '200px', cssClass:'w16', dataType:'date'},
          { text: 'Performed Time', value: 'activity_time', width: '200px', cssClass:'w16', dataType:'time', hideInlineFilter: true},
          { text: 'Object ID', value: 'business_object_id', width: '200px', cssClass:'w16', align: 'right'},
          ],
          tableList: {
        headers:[],
        actions:[],
        rows:[]
      },
        // tableList: {
        //   headers: [
        //   { text: 'Activity ID', value:'activity_id', width: '130px', align:'right', dataType:'int'},
        //   { text: 'Business Object', value: 'business_object_name', width: '200px', cssClass:'w16'},
        //   { text: 'Ver', value: 'bo_revision', width: '80px' ,align:'right', title: 'Business Object Version', dataType:'int'},
        //   { text: 'Activity' , value: "activity", width: '140px'},
        //   { text: 'Job Id' , value: "job_id", width: '120px',align:'right', dataType:'int','filtertext':''},
        //   { text: 'Driver Count', value: 'driver_table_count', width: '150px',align:'right', cssClass:'w16', dataType:'int'},
        //   { text: 'Performed By' , value: "created_by", width: '180px', cssClass:'w16'},
        //   { text: 'Performed Date', value: 'activity_date', width: '200px', cssClass:'w16', dataType:'date'},
        //   { text: 'Performed Time', value: 'activity_time', width: '200px', cssClass:'w16', dataType:'time', hideInlineFilter: true},
        //   { text: 'Object ID', value: 'business_object_id', width: '200px', cssClass:'w16', align: 'right'},
        //   ],
        //   actions:[],
        //   sorting_type: SERVER_SIDE,
        //   filterType: SERVER_SIDE,
        //   paginationType: SERVER_SIDE,
        //   total_count: 0,
        //   rows: [],
        //   selected_rows: [],
        //   disableDefaltSorting:true,
        //   itemkey:'activity_id',
        //   predefinedInlineFilter:[],
        // },
        sorting : config.SORTING_TYPE,
        envIpJson : {
          "filter": [],
          "sort": [{
            "column_name": "",
            "type": ""
          }],
          "page": 1,
          "page_size": 10
        },
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        auto_increment_id:0,
        snackbar:false,
        snackbartext:'',
        colorValue:'success',
        snackbartimeout: Snackbar_Default_Timeout,
        ExecutionMode: true,
        valid:true,
        loader:false,
        Environment:{},
        EnvironmentList:[],
        Jobid_List :[],
        timer_timespan :3000,
        selectedRows:[],
        BusinessObject:{},
        BusinessObjectList:[],
        filter_dialog:false,
        avlColumnList:[],
        filter_rows_list:[],
        catalog_table_name:'',
        activity_type:'',
        pd_revisions:'',
        filter_column_tbl_obj: {
          s_parentheses: "",
          column_name: "",
          operators: "",
          v_type: "Value",
          value1: "",
          value2: "",
          e_parentheses: "",
          table_name:""
        },
        userRole:{},
        is_enquiry:false,
        current_row:{},
        enquery_activity_type:ACTIVITY_TYPE_RESTORE,
        step_list:['Archival','Purge','Copy'],
        is_action:false,
        Interval:null,
        predefinedInlineFilter:[]
      }
    },
    mounted() {
      this.envIpJson.page_size = manageScreenDisplay()
     this.avlColumnList = []
     this.selectedRows = []
     this.catalog_table_name =''
     this.tableList = this.getGridObj(this.headers,'activity_id',false)
     this.userRole = this.$session.get('user_role_mapping');
     this.tableList.actions = [
     {'text':'restore','key':"activity",'cmpValue':"Archival",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),selectType:"multiple",index:1},
            // {'text':'selectiverestore','key':""},
            {'text':'dispose','key':"activity",'cmpValue':"Archival",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:2},
            {'text':'deletecopy','key':"activity",'cmpValue':"Copy",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:3},
            {'text':'enquiry','key':"activity",selectType:"multiple",index:4},
            {'text':'un-sync','key':"activity",'cmpValue':"Sync Up",selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:5}
            ]
            var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
              this.Environment = this.$session.get('selected_env')
              // this.getAllBusinessObjects()
            }
    if(this.$route.params && this.$route.params.filter){
    this.predefinedInlineFilter = this.$route.params.filter
    }
    else
      this.getAllActivities(this.envIpJson)
    // var bo_value= this.$session.get('selected_bo')
    // if(bo_value){
    //   this.BusinessObject = bo_value
    //   this.getAllArchieves()
    // }
    // this.getAllArchieves();
    // alert(this.$route.path)
    getEnvironmentList(this);
    this.focus_env()
    },
    beforeDestroy() {
      clearInterval(this.Interval)
    },
    methods: {
      ...mapActions(['setBOActivityData']),
      onDownload(downloadParams, filterArray){
        let _this = this;
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "row_option": downloadParams.recordType,
            "env_id": _this.Environment.id,
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.ENGINE_API_URL+EXPORT_ACTIVITY_DETAILS
        postToServer(_this, url, inputJson).then(Response => {
          var url = config.ENGINE_API_URL + "/static/" + Response;
          window.open(url, '_blank');
          _this.loader=false;
        }).catch(objError => {
            _this.loader=false;
            if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
          });
    },
      focus_env(){
        if(this.$refs.envname.$el)
          this.$refs.envname.$el.focus()
        else this.$refs.envname.focus()
      },
      hideLoader(){
        let _this = this;
        setTimeout(function(){
          _this.loader = false;
        },500);
      },


          getAllActivities (envIpJson) {
             let _this = this;
            let inputJson = this.envIpJson;
            this.$store.state.boActivityData = []
            this.tableList.rows = []

            if(!this.Environment.id)
              return false
            // if(!this.BusinessObject.business_object_id)
            //   return false
            this.loader = true;
            _this.$session.set('selected_env', this.Environment)
            _this.$session.set('selected_bo', this.BusinessObject)
            var env_id = this.Environment.id;
            var client_id = this.$session.get('client_id')
            var data = {"client_id": client_id,
                        "env_id": env_id,
                        "filter": inputJson.filter,
                        "page": inputJson.page,
                        "page_size": inputJson.page_size,
                        "sort": inputJson.sort}
            postToServer(this, config.ENGINE_API_URL + GETALLACTIVITIES, data).then(response => {
              var data = response
              if(data){
                _this.activity_list = data.result;
                var total_count = data.total
                _this.tableList.rows =  _this.activity_list
                 this.tableList.total_count = total_count;
              }
              _this.hideLoader();
            }).catch(CurrentJobError => {
              _this.hideLoader();
              if(CurrentJobError){
                _this.loader = null
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = CurrentJobError;
              }
              else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
              }
            })

          },
          filterData(filterArray,pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getAllActivities(inputJson)
        },
          // filterData(filterArray){
          //   let _this = this;
          //   let inputJson = _this.envIpJson;
          //   inputJson.filter=[];
          //   _.set(inputJson, 'page', 1);
          //   filterArray.map((obj, index)=>{
          //     obj.stringArray.map((stringObj, stringIndex)=>{
          //       if(obj.value.split('.')[1]){
          //         var value = obj.value.split('.')[1]
          //       }else{
          //         var value = obj.value.split('.')[0]
          //       }
          //       let temp = {"column":value,"value": String(stringObj.text).trim()};
          //       inputJson.filter.push(cloneDeep(temp));
          //     })
          //   });
          //   this.getAllActivities(inputJson)
          // },

        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllActivities(inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllActivities(inputJson);
        },
          // onPagination(page, perPage) {
          //   let _this = this;
          //   let inputJson = _this.envIpJson;
          //   inputJson.page = page;
          //   inputJson.page_size = perPage;
          //   this.getAllActivities(inputJson);
          // },

        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getAllActivities(inputJson);
        },
        // sortBy: function (key, sortingType) {
        //       let _this = this;
        //       if(!key || !sortingType)
        //           return
        //       let inputJson = _this.envIpJson;
        //       inputJson.sort.column = key;
        //       inputJson.sort.type = sortingType;
        //       this.getAllActivities(inputJson);
        // },
          onRestore: function(record) {
            this.PerformOps('R')
          },
          onSelectedRows(selectedRows){
            this.selectedRows = selectedRows;
            console.log(this.selectedRows)
          },
          onDispose: function(record) {
            this.PerformOps('D')
          },
          onDeleteCopy: function(record) {
            this.PerformOps('DC')
          },
          onEnquiry: function(record){
            this.is_action = true
            this.current_row = record[0]
            console.log(record)
            this.activity_type ='Enquiry'
            if(_.uniq(_.map(record,'business_object_id')).length >1){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Select same business object';
              return false
            }
            this.enquery_activity_type = this.current_row.activity
            if (this.step_list.indexOf(this.current_row.activity) >= 0) {
              this.is_action = false
            }
            this.PerformOps('E')
          },

          getAllBusinessObjects(){
            let _this = this;
            this.avlColumnList = []
            this.catalog_table_name =''
            this.selectedRows =[]
            this.tableList.rows = []
            this.BusinessObjectList=[]
            this.BusinessObject ={}
            if(!this.Environment)
              return false
        // this.getAllArchieves()
        _this.$session.set('selected_env', this.Environment)

        var env_id = this.Environment.id;
        getToServer(this, config.ENGINE_API_URL + GETBUSINESS_OBJECT_BY_ENV+'/'+env_id).then(response => {
          this.BusinessObjectList = response
        }).catch(CurrentJobError => {
          _this.hideLoader();
          if(CurrentJobError){
            _this.loader = null
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = CurrentJobError;
          }
          else {
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = SERVER_ERROR;
          }
        })
      },
      GetData(){
        var client_id = this.$session.get('client_id')
        // get policy_details
        var activity_ids = _.map(this.selectedRows,'activity_id')
        var filter_data = cloneDeep(this.filter_rows_list)
        if(filter_data.length === 1 && (!filter_data[0].column_name ||!filter_data[0].operator))
          filter_data = []
        if(filter_data.length >= 1){
          // FILTER MEANS SELECTIVE ACTION AND BASED ON BUTTON CLICK WE DECIDE RESTORE OR DISPOSE
          if(this.activity_type === ACTIVITY_TYPE_RESTORE)
            this.activity_type = ACTIVITY_TYPE_SELECTIVE_RESTORE
          else if(this.activity_type === ACTIVITY_TYPE_DISPOSE)
            this.activity_type = ACTIVITY_TYPE_SELECTIVE_DISPOSE

        }
        var other_activity_details = []
          for (var i =0; i < this.selectedRows.length; i++) {
            var row = this.selectedRows[i]
            other_activity_details.push({"activity_id":row["activity_id"],
                                          "driver_count":row["driver_table_count"],
                                          "data_older_than":this.convertDateFormat(row["data_older_than"]),
                                          "can_dispose_on":this.convertDateFormat(row["can_dispose_on"]),
                                          "created_date":this.convertDateFormat(row["created_date"]),
                                          "created_by_job":row["created_by_job"]})
          }

          return {
            'client_id': client_id,
            'process_definition_id': this.process_definition_id,
            'process_definition_name': this.process_definition_name,
            'created_by': this.$session.get('email'),
            'activity_ids':activity_ids,
            'activity_type': this.activity_type,
            'execution_environment': this.Environment.id,
            'execution_env_name': this.Environment.name,
            'mode_of_execution':this.ExecutionMode ? 'proof':'final',
            'exe_type':'on_demand',
            'filter_data':filter_data,
            'table_name':this.catalog_table_name,
            'pd_revision':this.pd_revision,
            'created_by_job':this.created_by_job,
            'other_activity_details':other_activity_details
          }

      },
      convertDateFormat(date_to_convert){
        if (!date_to_convert)
            return ''
          var moment_obj = moment.utc(date_to_convert,this.$session.get('UI_date_format'))
          var fomrated_date = moment(moment_obj).format('YYYY-MM-DD')
          return fomrated_date
        },
      CreateNewJob(){
        var data = this.GetData()
        this.Interval = null
        if(this.activity_type === UN_SYNC_UP)
            data['job_type'] = 'sync_up'
        if(data.mode_of_execution === 'final' && (this.activity_type === ACTIVITY_TYPE_DISPOSE || this.activity_type === ACTIVITY_TYPE_SELECTIVE_DISPOSE)){

            var record = this.current_row
            if(!record.can_dispose_on){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'You cannot perform dispose operation beacuse dispose date in not set for this business object in selected environment';
              return false
            }

            // dont allow to user to dispose record if can dispose date >= current date
            var current_date= moment(new Date(),this.$session.get('UI_date_format'))
            var dispose_date = moment(record.can_dispose_on,this.$session.get('UI_date_format'))
            if(current_date <= dispose_date){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'You cannot perform dispose operation beacuse current date is less than can dispose date';
              return false
            }
          }
        // alert(data.mode_of_execution)
        // return false
        console.log(data)
        postToServer(this, config.COORDINATOR_API_URL + RUN_COORDINATION, data).then(response => {
          if (response) {
            this.ShowErrorMessage=false;
            this.ShowSuccessMessage = true;
            this.sucessMessage = response.body;

          }}).catch(ProcessDefError => {
            clearInterval(this.Interval)
            if(ProcessDefError){
              this.loader = null
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = ProcessDefError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
          })
          this.Interval = setTimeout(() => this.$router.push('/currentjoblist'));

        },

        CreateScheduleJob(){
         var data = this.GetData()
         if(this.activity_type === UN_SYNC_UP)
            data['job_type'] = 'sync_up'
         data['is_one_time_schedule'] =true
         data['is_restored'] = true
         data['exe_type']='on_schedule'
         this.$router.push({'name' : 'schedulejob', 'params' : { schedule_data: data, type:'new', service_id:config.COORDINATOR_SERVICE_ID, category:'Job', end_point: config.END_POINT_FOR_SCHEDULER}});
       },
       ShowData(){
        var record = this.current_row
        var filter_data = cloneDeep(this.filter_rows_list)
        if(filter_data.length === 1 && (!filter_data[0].column_name ||!filter_data[0].operator))
          filter_data = []
        this.$router.push({ name: 'showacitvitydata', params: {'activity_ids':_.map(this.selectedRows,'activity_id'),'is_action':this.is_action,'table_name':record.catalog_table_name,'env_id':this.Environment.id,'env_name':this.Environment.name,'bo_name':record.business_object_name,'activity':this.enquery_activity_type,'filter_data':filter_data,'unique_identifiers':JSON.parse(record.unique_identifiers)}});
      },

      ondblClick(record){
        this.tableList.selectedRows = [record]
        this.onRestore()
      },
      PerformOps(type){
        this.is_enquiry = false
        this.disable_final_mode = false
        this.selectedRows = this.tableList.selectedRows
        // this.ExecutionMode = true
        if(this.selectedRows.length <=0){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Select atleast on record';
          return
        }


        // now check if user has selected more than 1 rows but different object or same object with different version
          if(this.selectedRows.length > 1){

            // check same business object selected or not
            var bo_ids = _.uniq(_.map(this.selectedRows,"business_object_id"))
            if(bo_ids.length > 1){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Select same named business objects';
              return false
            }

            // User has selected same BO now check same version selected or not.

            var bo_versions = _.uniq(_.map(this.selectedRows,"bo_revision"))
            if(bo_versions.length > 1){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Select same versions of business object';
              return false
            }

          }

        this.avlColumnList = []
        this.catalog_table_name =''
        var record = this.selectedRows[0]
        this.current_row = record
        let uniqueIds = JSON.parse(record.unique_identifiers)
        for (var i = 0; i< uniqueIds.length; i++) {
          this.avlColumnList.push(uniqueIds[i])
        }
        console.log(this.avlColumnList)
        this.catalog_table_name = this.selectedRows[0].catalog_table_name
        this.process_definition_id = this.selectedRows[0].business_object_id
        this.process_definition_name = this.selectedRows[0].business_object_name
        this.pd_revision = this.selectedRows[0].bo_revision
        this.created_by_job = this.selectedRows[0].job_id
        if(type==='R')
          this.activity_type = ACTIVITY_TYPE_RESTORE
        else if(type ==='D')
          this.activity_type = ACTIVITY_TYPE_DISPOSE

        else if (type === 'E'){
          this.is_enquiry = true
          this.activity_type = 'Enquiry'
        }
        else if (type === 'DC'){
          this.activity_type = ACTIVITY_TYPE_DELETE_COPY
        }
        else if (type === 'US'){
          this.activity_type = UN_SYNC_UP
        }
        // this.filter_rows_list = this.avlColumnList
        if(!this.filter_rows_list.length)
          this.add_new_filter_row()
        this.filter_dialog =true
      },
      add_new_filter_row() {
        var current_filter_length = this.filter_rows_list.length;
        if (current_filter_length > 0) {
          if (!this.filter_rows_list[current_filter_length - 1]["operation"])
            this.filter_rows_list[current_filter_length - 1]["operation"] = false;
        }
        var obj = cloneDeep(this.filter_column_tbl_obj);
        obj["id"] = this.get_new_id();
        obj["table_name"] = this.catalog_table_name
        this.filter_rows_list.push(obj);
      },
      delete_filter_row(idx) {
        var current_filter_length = this.filter_rows_list.length;
        if (current_filter_length > 1 && current_filter_length - 1 === idx)
          delete this.filter_rows_list[idx - 1]["operation"];
        this.filter_rows_list.splice(idx, 1);
        if (this.filter_rows_list.length === 0) {
          var obj = cloneDeep(this.filter_column_tbl_obj);
          this.filter_rows_list.push(obj);
        }
      },
      saveCriteria(columns_for_filter) {
        this.filter_rows_list = columns_for_filter;
        console.log('filters', this.filter_rows_list)
      },
      get_new_id() {
        return ++this.auto_increment_id;
      },
      onCancel(){
        this.filter_dialog = false
      },
      validateFilter(call_type){
        if(this.filter_rows_list.length === 1)
        {
          if(this.filter_rows_list[0].column_name === "")
          {
            if(call_type === 'job')
              return this.CreateNewJob()
            else
              return this.CreateScheduleJob()
          }
        }

        var filter_json = {'filter_data':this.filter_rows_list}
        postToServer(this, config.VALIDATION_URL + VALIDATE_ACTIVITY_FILTER, filter_json).then(response => {
          console.log(response)
          if (response) {
           if(typeof response == 'string')
           {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }
          else{
            var empty_json ={}
            if(typeof response =='object' && response.filter_data){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Please specify valid filter';
            }
            else{
             if(call_type === 'job')
              this.CreateNewJob()
            else
              this.CreateScheduleJob()
          }
        }}}).catch(ProcessDefError => {})
      },
      onUnsync(record){
        this.PerformOps('US')
      },
    }
  }
  </script>
  <style scoped>

  .radioClass >>> label{
    top: 5px !important;
  }
  .radioClass >>> i{
    font-size: 30px
  }

  /* tr:nth-child(even) {background-color: #E8E8E8;}
  .blurOpacity{
    opacity: 0;
  }
  .showInput {
    visibility: inherit !important;
    width: 50% !important;
  }

  .srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
  }

  table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
  }

  thead {
    line-height: 30px;
    transition: all 10s;
  }

  th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }

  .srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
  } */
  </style>
