<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" v-on:change="getPlanList" style="-webkit-appearance: menulist">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Job Plan History</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
     <v-container>
      <v-card>
        <v-row class="EDC-Row" no-gutters>
          <v-tabs v-model="activeTab" non-linear>
            <v-tab class="EDC-Tabs" key="tab1">
              <v-badge :content="tableList.rows.length" inline bordered v-if='tableList.rows.length'>
                <span>Active Job plans</span>
              </v-badge>
              <span v-else>Active Job plans</span>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab2">
              <span>Completed Job plans</span>
            </v-tab>
            <v-tab-item>
              <edc-data-grid key="grid-A" :dataList="tableList" @onTerminate = "onJobTerminate" @onSuspend = "onJobSuspend"  @onCreate='onResume' @onHistory="onHistory" @ondblClick="onHistory"></edc-data-grid>
            </v-tab-item>
            <v-tab-item>
              <edc-jobplanhistory key="pageA" :changedEnv="Environment"></edc-jobplanhistory>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-card>
    </v-container>
   <!--  <edc-data-grid :dataList="tableList"  @onTerminate = "onJobTerminate" @onSuspend = "onJobSuspend" @onDownload="onDownload" @onCreate='onResume'></edc-data-grid> -->

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>    
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_PLAN_LIST,EXECUTE_JOB_PLAN, DELETE_JOB_PLAN, JOB_PLAN_HISTORY_INSTANCE, EXPORT_ALL_JOB_PLAN_LIST} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import getUserRole from '@/methods/GetUserRole.js'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,JOB_PLAN_SERVICE_ID,EXECUTE_ACTION, CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION} from '@/data/macros.js'
import config from '../../config.json'
import {manageScreenDisplay} from '@/methods/special.js'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import jobPlanHistory from './JobPlanHistory.vue'
import {SERVER_ERROR} from '@/data/client_message.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'EventList',
     components:{
            'edc-jobplanhistory':jobPlanHistory
            },
    data: function () {
        return {
            EnvironmentList: [],
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            calendarDetails: this.$store.state.calendarDetails,
            calenderList: [],
            loader:false,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            activeTab:1,
            tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
            headers: [{
                        text: 'Id',
                        value: 'id',
                        disabled: true,
                        width: '10%',
                        align:'right',
                        title: 'Job Plan Id',
                        dataType:'int'
                    },
                    {
                        text: 'Name',
                        value: 'plan_name',
                        disabled: true,
                        width: '20%',
                        title: 'Job Plan Name'
                    },
                     {
                        text: 'Mode',
                        value: 'display_mode_of_execution',
                        disabled: true,
                        width: '20%',
                        title: 'Job Plan Execution Mode'
                    },
                    {
                        text: 'Status',
                        value: 'display_status',
                        width: '20%',
                        title: 'Job Plan Current Status'
                    },
                    {
                        text: 'Running',
                        value: 'running',
                        width: '20%',
                        title: 'Running Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Completed',
                        value: 'completed',
                        width: '20%',
                        title: 'completed Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Waiting',
                        value: 'waiting_for_execution',
                        width: '20%',
                        title: 'Waiting Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Suspended',
                        value: 'suspended',
                        width: '20%',
                        title: 'Suspended Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Error',
                        value: 'error',
                        width: '20%',
                        title: 'error Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Terminated',
                        value: 'terminated',
                        width: '20%',
                        title: 'terminated Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Start Date',
                        value: 'start_date',
                        width: '20%',
                        title: 'Job Plan Start Date',
                        dataType:'date'
                    },
                    {
                        text: 'Start Time',
                        value: 'start_time',
                        width: '20%',
                        title: 'Job Plan Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: '30%',
                        title: 'Job Plan Created By'
                    },
                ],
            userRole:{},
            envIpJson : {
            "filter": [],
            "sort": [{
                "column": "",
                "type": ""
            }],
            "page": 1,
            "page_size": 10
                },
            Environment:{},
            job_plan_id : this.$route.params.job_plan_id,
            job_plan_name : this.$route.params.job_plan_name,
            execution_environment:this.$route.params.execution_environment,
            execution_env_name:this.$route.params.execution_env_name,

        }
    },
    watch:{
        '$store.state.jobPlanDashboardInfo':{
        handler(newValue){
          this.manageLiveJobPlan(newValue)
        }
      },
    },
    mounted() {
        getEnvironmentList(this)
        this.getPlanList();
        this.activeTab = 0
        this.envIpJson.page_size = manageScreenDisplay()-5
        var env_value = this.$session.get('selected_env')
        this.tableList = this.getGridObj(this.headers, 'id', true, {showExport : false})
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            this.getPlanList()
        }
        this.userRole = this.$session.get('user_role_mapping');
        // this.tableList.actions=[{'text':'create','key':"", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,SCHEDULE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION)}]
        this.tableList.actions = [
                    {   'text':'history',
                        'key':"id", 
                        selectType:"single",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, EXECUTE_ACTION),
                        index:5
                    }
                ]
    },
    methods: {
    getPlanList() {
          let _this = this;
          var env_id = this.Environment.id;
            if (!env_id)
              return false
          var env_id = _this.Environment.id
         this.$session.set('selected_env', this.Environment)

          var job_data = {'client_id':this.$session.get('client_id'),
                          'env_id': env_id};
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ '/job_plan_dashboard';
          _this.loader = true
          postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;
              _.forEach(response,function(obj){
                obj = _this.manageObjProps(obj)
              })

              _this.tableList.rows = response;
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        onJobSuspend:function(record,defaultvalue){
            let _this = this;
            var env_id = this.Environment.id;
            if (!env_id)
                return false
            var env_id = _this.Environment.id
            this.$session.set('selected_env', this.Environment)
            var job_data = {'client_id':this.$session.get('client_id'),
                              'env_id': env_id,'job_plan_instance_id':record.id,immediate_suspend:true};
            if(defaultvalue=== 'wait')
                job_data.immediate_suspend = false
            let baseUrl = config.JOB_PLAN_URL +'/suspend_job_plan'
            this.loader = true
            postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;              
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        onJobTerminate:function(record,defaultvalue){
            let _this = this;
            var env_id = this.Environment.id;
            if (!env_id)
                return false
            var env_id = _this.Environment.id
            this.$session.set('selected_env', this.Environment)
            var job_data = {'client_id':this.$session.get('client_id'),
                              'env_id': env_id,'job_plan_instance_id':record.id,immediate_terminate:true};
            if(defaultvalue=== 'wait')
                job_data.immediate_terminate = false
            let baseUrl = config.JOB_PLAN_URL +'/terminate_job_plan'
            this.loader = true
            postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;              
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        onResume:function(record){
            let _this = this;
            var env_id = this.Environment.id;
            if (!env_id)
                return false
            var env_id = _this.Environment.id
            this.$session.set('selected_env', this.Environment)
            var job_data = {'client_id':this.$session.get('client_id'),
                              'env_id': env_id,'job_plan_instance_id':record.id};
            let baseUrl = config.JOB_PLAN_URL +'/resume_job_plan'
            this.loader = true
            postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;              
           }).catch(CurrentJobError => {
               _this.loader=false;
               //this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        manageLiveJobPlan(data){
            // alert('new data receied',data)
            let _this = this
            if(!data.id)
                return
            data = _this.manageObjProps(data)
            let obj = _.find(_this.tableList.rows,['id',data.id])
            if(obj){
                var index = _.findIndex(_this.tableList.rows,["id",data.id])
                this.$set(this.tableList.rows,index,data)
                return
            }
            _this.tableList.rows.unshift(data)
        },
        onHistory(record){
         let _this = this;
         this.$router.push({ name: 'jobplaninstancedetails',
          params: {
           job_plan_id: this.job_plan_id,
           job_plan_instance_id: record.id,
           job_plan_name:record.plan_name,
           env_id: this.Environment.id,
           envname: this.Environment.name}
         }
         );
       },
        manageObjProps(obj){
            obj.display_status ='Running'
            obj.showresume = 'N'
            obj.showterminate = 'N'
            obj.showsuspend = 'N'
            if(obj.mode_of_execution)
                obj.display_mode_of_execution = obj.mode_of_execution.charAt(0).toUpperCase() + obj.mode_of_execution.slice(1)
            if(obj.status)
                obj.display_status = obj.status.charAt(0).toUpperCase()+obj.status.slice(1)
            if(obj.status === 'running')
            {
                obj.showterminate = 'Y'
                obj.showsuspend = 'Y'
            }
            else if(obj.status === 'suspended' || obj.status === 'error'){
                obj.showterminate = 'Y'
                obj.showresume = 'Y'
            }
            return obj
        }
    },
}
</script>

<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>


