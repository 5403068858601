/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'summarization_undo': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M5.583 4.759h-1.64v-.43l.804-1.147v-.014l-.805-1.147v-.43h1.64v.413H4.448l.734 1.02v.303l-.734 1.02h1.136zM2.09 2.113l-.661.53-.662.528.662.53.661.529v-.794c.632.001 1.202.376 1.454.955l.001-.029c0-.825-.633-1.512-1.455-1.58z" aria-label="∑" font-weight="600" font-size="3.528" font-family="IBM Plex Sans" stroke-width=".265"/>'
  }
})
