<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-menu elevation-1  left absolute slot="activator" transition="scale-transition" :close-on-content-click="false" v-model="downloadOption" style="display:inline">
                    <template v-slot:activator="{ on }">
                        <v-icon class="EDC-GridIcon" v-on="on" title="Export Data" id="export">exit_to_app</v-icon>
                    </template>
                    <v-card>
                        <v-card-text style="width:340px">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-radio-group v-model="downloadParams.recordType" row style="margin-top:0px" @change="resetRange(downloadParams.recordType)">
                                        <v-radio class="radioClass" :color="colorCode" label="All" value="all"></v-radio>
                                        <v-radio class="radioClass" :color="colorCode" :disabled="!selectedArray.length" label="Selected_rows" value="selected_rows" ></v-radio>
                                        <v-radio class="radioClass" :color="colorCode" label="Range" value="range" ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" v-if="downloadParams.recordType == 'range'">
                                <v-col class="EDC-Col" cols="6">
                                    <v-text-field type="number" v-model="downloadParams.minRange" style="width:95%;padding-top:0px;margin-top:0px" label="Min Row Range"></v-text-field>
                                </v-col>
                                <v-col class="EDC-Col" cols="6">
                                    <v-text-field type="number" v-model="downloadParams.maxRange" style="width:95%;float:right;padding-top:0px;margin-top:0px" label="Max Row Range"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-radio-group v-model="downloadParams.fileType" row style="margin-top:0px">
                                        <v-radio class="radioClass" :color="colorCode" label="CSV" value="csv"></v-radio>
                                        <v-radio class="radioClass" :color="colorCode" label="XLS" value="xls" ></v-radio>
                                        <v-radio class="radioClass" :color="colorCode" label="PDF" value="pdf" ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <vc-button type="button" @click.native="downloadOption = false" itemText="Cancel" style="float:left"></vc-button>
                                    <vc-button type="button" @click.native="onDownload" itemText="Download" style="float:right"></vc-button>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .customCheckbox >>> label{
    top: 5px !important;
    }
    .radioClass >>> label{
        top: 5px !important;
    }

    .v-btn--fab.v-size--x-small{
        height:24px !important;
        width:24px !important;
        margin:2px;
    }
</style>
<script>
    import {COLOR_CODE} from './../../constants/productconstants.js';

    export default{
        name:'EdcGridDownload',
        props:{
            selectedArray:{
                type:Array,
                default:()=>{
                    return []
                }
            }
        },
        data(){
            return{
                colorCode:COLOR_CODE,
                downloadOption:false,
                downloadParams:{
                    recordType:'all',
                    minRange:0,
                    maxRange:1,
                    fileType:'csv'
                },

            }
        },
        watch:{
            selectedArray:{
                handler(newValue){

                }
            }
        },
        methods:{
            resetRange(recordType){
                if(recordType !=='range'){
                    this.downloadParams.minRange = 0;
                    this.downloadParams.maxRange = 1;
                }
            },
            onDownload(){
                this.$emit('onDownload', this.downloadParams);
                this.downloadOption = false
            },
        }
    }
</script>