<template>
  <div id="configureldap"> 
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Configure LDAP</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="configure_ldap_form" v-model="valid">
        <v-card elevation-1>
          <v-container class="EDC-Container"> 
            <v-col cols="12" class="EDC-Col">
              <v-row no-gutters class="EDC-Row" style="padding-top:15px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="ldap_host" label="LDAP Host" :rules="nameRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="ldap_port" label="LDAP Port" :rules="nameRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox class="customCheckbox EDC-UIComponent" v-model="use_ssl" name="User" label="Use SSL"  dense/>
                </v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="base_dn" label="Base DN" :rules="nameRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="user_id" label="User DN" :rules="nameRules" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="password" label="Password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" :rules="nameRules"
                    :disabled="passworddisabled" required @click:append="show1 = !show1" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing"></v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                <vc-button itemText="Test Connection" @click.native="PerformClick('true')"></vc-button>
                <vc-button itemText="Save" @click.native="PerformClick()"></vc-button>
                <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

  <style scoped>
  .customCheckbox >>> label{
    top: 5px !important;
  }
  .v-text-field.v-text-field--solo .v-input__control{
    min-height: 20px;
  }
  #adduser .v-input--checkbox, label {
    margin-top:2%;
  }
  </style>

  <script>
  import config from "../../config.json"
  import Breadcrumb from "../Breadcrumbs.vue"
  import { post as postToServer } from './../../methods/serverCall.js';
  import { get as getToServer } from './../../methods/serverCall.js';
  import { GET_LDAP_SETTING,MANAGE_LDAP_SETTING,VALIDATE_LDAP_SETTING } from '../../data/url_constants.js'
  import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
  import {
    DATE_TIME_FORMAT
  } from '@/data/manage_account_constants.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'

  export default {
    data () {
      return {
        date_formatitems: DATE_TIME_FORMAT,
        testConnectionLoader:false,
        saveLoader:false,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        valid: false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout: Snackbar_Default_Timeout,
        colorValue:'error',
        loader: null,
        loading: false,
        ldap_host:'',
        ldap_port:'',
        base_dn:'',
        nameRules:[
        v => !!v || 'Field is required'
        ],
        user_id:'',
        password:'',
        show1: false,
        passworddisabled:false,
        use_ssl:true
      }
    },
    mounted () {
      this.GetLDAPSetting()
    },
    methods: {
      PerformClick(is_validate_call) {
        this.snackbar = false
        if (this.$refs.configure_ldap_form.validate()) {
          var client_id = this.$session.get('client_id')
          var data_to_pass = {
            "client_id":client_id,
            "ldap_host":this.ldap_host,
            "ldap_port":this.ldap_port,
            "use_ssl":this.use_ssl,
            "created_by":this.$session.get("email"),
            "base_dn":this.base_dn,
            "username":this.user_id,
            "password":this.password
          }
          if(is_validate_call){
            return this.ValidateConnection(data_to_pass)
          }
          this.SaveData(data_to_pass)
        }
      },
      GetLDAPSetting(){
        var client_id = this.$session.get('client_id')
        var data_to_fetch = {
          "client_id":client_id
        }
        postToServer(this, config.ACCOUNT_MANAGEMENT_URL + GET_LDAP_SETTING, data_to_fetch
          ).then(response  => {
            console.log('response',response)
            this.ldap_host = response.ldap_host
            this.ldap_port = response.ldap_port
            this.base_dn = response.base_dn
            this.user_id = response.user_dn
            this.use_ssl = response.use_ssl?true:false
            this.password = response.ldap_password
          }, (response) => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            console.log('error occured')
            console.log(e)
          })
        },
        SaveData(data_to_save){
          this.saveLoader = true
          console.log('data',data_to_save)
          postToServer(this, config.ACCOUNT_MANAGEMENT_URL + MANAGE_LDAP_SETTING, data_to_save
            ).then(response  => {
              this.saveLoader = false
              window.location.href = "/dashboard"
            }, (response) => {
              this.saveLoader = false
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = response;
            }).catch(e => {
              this.saveLoader = false
              console.log('error occured')
              console.log(e)
            })
          },
          ValidateConnection(data_to_validate){
            this.testConnectionLoader = true;
            console.log('data',data_to_validate)
            postToServer(this, config.ACCOUNT_MANAGEMENT_URL + VALIDATE_LDAP_SETTING, data_to_validate
              ).then(response  => {
                this.testConnectionLoader = false;
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response;
              }, (response) => {
                this.testConnectionLoader = false;
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = response;
              }).catch(e => {
                this.testConnectionLoader = false;
                console.log('error occured')
                console.log(e)
              })
            },
          }
        }
        </script>