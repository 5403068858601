<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid key="grid-A" :dataList="tableList"  @onAddJob="onAddJob"  @addJobPlan="addJobPlan" @onEdit="onEdit"
                        @onUpArrow="onPositionUpDown($event,'up')" @onDownArrow="onPositionUpDown($event,'down')"  @onDependencyAdd="onDependencyAdd" 
                        @onDependencyRemove="onDependencyRemove" @onDelete="onDelete" @onAddMultiple="onAddMultipleJobs"
                        @ondblClick='ondblClick' @onPause="onJobSuspend" @onTerminate = "onJobTerminate"
                        @onResume='onResume'  @onHistory="onHistory">
                        </edc-data-grid>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-dialog eager v-model="showDialog" :width="dialogWidth" persistent style="overflow-y:none; background:white">
                            <v-card class="rounded-card">
                                <v-toolbar dark dense>
                                <v-col class="text-md-center">{{dialogHeader}}
                                    <v-icon class="text-lg-left" style="color:#dedede; height:22px; float:right" @click="showDialog = false">fa-times-circle</v-icon>
                                </v-col>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row class="EDC-Row" style="padding:16px !important;">
                                        <v-col class="EDC-Col" v-if="whichDialog==='mass'">
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <v-autocomplete hide-details="auto" v-model="massValueType" :items="massAddvalueType" label="Value Type"/>
                                                </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <edc-calender label="Start Date" :input="massStartValue" style="margin-right:10px" @update="setMinDate(...arguments)"/>
                                                </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <edc-calender label="End Date" :input="massEndValue" style="margin-right:10px" @update="setMaxDate(...arguments)"/>
                                                </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <vc-button type="button" item-text="Save" @click.native="CreateMassJob"/>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="EDC-Col" v-if="whichDialog === 'newjob'">
                                            <edc-job-list key="joblist" :hideBreadscrumb="true" :showAddMultiple="true" 
                                            @addNewJobsInPlan="addNewJobsInPlan($event)" @closePopUp="closePopUp"></edc-job-list>
                                        </v-col>
                                        <v-col class="EDC-Col" v-if="whichDialog==='newjobplan'">
                                            <edc-job-plan-list key="jobplanlist"
                                            :hideBreadscrumb="true" :showAddMultiple="true" 
                                            @addNewJobsInPlan="addNewJobsInPlan($event)" @closePopUp="closePopUp">
                                            </edc-job-plan-list>
                                        </v-col>
                                        
                                        <v-col class="EDC-Col" v-if="whichDialog==='managejob'">
                                            <edc-manage-job :hideBreadscrumb="true" :hideHistory="true" :hideMode="true" 
                                            :hideRunBtn="true" :hideScheduleBtn="true"
                                             :showEditJobBtn="true" :editJobObj="editJobObj" requestFor="editjobplan" :key='nextId' 
                                             @updateJobFromJobPlan="updateJobFromJobPlan" @closePopUp="closePopUp"></edc-manage-job>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-col>
            <loading-panel :loader="loader"></loading-panel>
            <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import moment from 'moment'
    import {addDependency} from '../../methods/commonFunc.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import edcjoblist from '../jobManagement/jobList.vue'
    import edcjobplanlist from '../jobPlan/JobPlanList.vue'
    import edcmanagejob from '../jobManagement/manageJob.vue'
    export default{
        name:'ManageJobsInThePlan',
        components:{
            'edc-job-list':edcjoblist,
            'edc-job-plan-list':edcjobplanlist,
            'edc-manage-job':edcmanagejob
        },
        props:{
            jobPlanId:{
                type:Number,
                default:1
            },
            jobPlanName:{
                type:String,
                default:''
            },
            pageFor:{
                type:String,
                default:'instance'
            },
            tableItemKey:{
                type:String,
                default:'id'
            },
            tableRows:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            tableHeaders:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            tableActions:{
                type:Array,
                default:()=>{
                    return []
                }
            }

        },
        data(){
            return{
                loader:false,
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout, 
                executionEnvironmentId:'',
                executionEnvironmentName:'',
                jobPlanInstanceId:'',
                clientId:this.$session.get('client_id'),
                tableList: {
                    headers:[],
                    actions:[],
                    rows:[],
                },
                lastSelectedRows:[],
                massAddvalueType:['Days','Months','Quarters','Years'],
                showDialog:false,
                dialogWidth:400,
                whichDialog:'mass',
                dialogHeader:"Add Mass Jobs",
                massValueType:'Years',
                massEndValue:"",
                massStartValue:"",
                selectedJobs:[],
                editJobObj:{},
                nextId:1,
                environmentObj:{}
            }
        },
        computed:{
            getHeaders(){
                return []
            },
            isPageForInstance(){
                return this.pageFor === 'instance'
            }
        },
        watch:{
            'tableActions':{
                handler(newValue){
                    this.tableList.actions = _.cloneDeep(newValue)
                }
            },
            'tableRows':{
                handler(newValue){
                    this.tableList.rows = this.manageDisplayList(_.cloneDeep(newValue),true)
                },
                deep:true
            }
        },
        mounted(){
            this.tableList = this.getGridObj(this.tableHeaders, this.tableItemKey, true, {showExport : false, persist_selected_rows:true,is_in_tab:true})
            this.environmentObj = this.$session.get('selected_env')
            this.executionEnvironmentId = this.environmentObj.id
            this.executionEnvironmentName = this.environmentObj.name
            this.tableList.actions=this.tableActions
        },
        methods: {
            manageDisplayList(rowList,isFirstLoad=false){
                var _this = this
                _.forEach(rowList, (obj,ind)=>{
                    if(_this.isPageForInstance){
                        obj.exe_type = obj.exe_type === 'on_schedule' ? "On Schedule": "On Demand"
                    } else{
                        if(isFirstLoad){
                            obj.instance_details_indentation = obj.indentation
                            obj.instance_details_sequence_no = ind+1
                            obj.parent_sequence_no = 0
                            obj.parent_job = ''
                            if(ind !=0 && obj.indentation){
                                if(rowList[ind-1].indentation < obj.indentation){
                                    obj.parent_sequence_no = rowList[ind-1].instance_details_sequence_no
                                    obj.parent_job = rowList[ind-1].process_definition_name
                                } else{
                                    if(obj.indentation){
                                        let sibling = _this.getAboveSameIndendentRow(obj,rowList,ind)
                                        obj.parent_sequence_no = sibling.parent_sequence_no
                                        obj.parent_job = sibling.parent_job
                                    }
                                    
                                }
                            }
                        }
                    }
                    obj.pdDisplayName = addDependency(obj.process_definition_name, obj.instance_details_indentation)
                    obj.rowIndex = ind
                    
                })
                setTimeout(()=>{
                    _this.keepRowSelected()
                },200)
                return rowList
            },
            keepRowSelected(){
                var _this = this
                _this.tableList.persist_selected_rows = true
                _this.tableList.selectedRows = _.cloneDeep(_this.lastSelectedRows)
            },
            showMessage(message, type='error'){
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message;
            },
            onAddJob() {
                this.showDialog = true
                this.dialogWidth=900
                this.whichDialog='newjob'
                this.dialogHeader="Add New Jobs"
            },
            addJobPlan() {
                this.showDialog = true
                this.dialogWidth=900
                this.whichDialog='newjobplan'
                this.dialogHeader="Add New Job Plan"
            },
            onEdit(record){
                if(record.is_mass_job)
                    return
                this.$store.state.createJobParams={ 'process_definition_id': record.process_definition_id, 
                'process_doc_name': record.process_definition_name, 'activity_id':null,
                'environment':this.environmentObj, 'is_restore':false};
                this.editJobObj = {}
                this.editJobObj={
                    id:this.jobPlanId,
                    job_plan_name:this.jobPlanName,
                    execution_environment: this.environmentObj,
                    execution_env_name: this.executionEnvironmentName,
                    process_definition_id:record.process_definition_id,
                    process_doc_name:record.process_definition_name,
                    job_plan_id:this.jobPlanId,
                    job_sequence:record.sequence_no,
                    "rowIndex":record.rowIndex
                }
                this.nextId++
                this.showDialog = true
                this.dialogWidth=800
                this.whichDialog="managejob"
                this.dialogHeader ="Edit Job"
            },
            onPositionUpDown(records,direction){
                var _this = this
                _this.snackbar = false
                var isUPDirection = direction.toLowerCase() === 'up'?true:false
                var logList = []
                var eligibleRecords = _this.getEligibleRows(records) 
                if(!eligibleRecords || !eligibleRecords.length)
                    return
                let allObjectIndents = _.uniqBy(eligibleRecords,'instance_details_indentation')
                if (allObjectIndents.length > 1){
                    this.showMessage("You have selected some parent and child from different hierarchy.In such cases please select one row at a time.")
                    return
                }
                let rowToSwitch = {}
                if(isUPDirection){
                    // find a nearest row with same or less indentation at updirection for 1st eligible row
                    for(let i=eligibleRecords[0].rowIndex-1;i>=0;i--){
                        let currentRow = _this.tableList.rows[i]
                        if (currentRow.instance_details_indentation <= eligibleRecords[0].instance_details_indentation){
                            rowToSwitch = currentRow
                            break
                        }
                    }
                }else{
                    // find a nearest row with same or less indentation at down direction for last eligible row
                    let lastEligibleRow =  eligibleRecords[eligibleRecords.length-1]
                    for(let i=lastEligibleRow.rowIndex+1;i<_this.tableList.rows.length;i++){
                        let currentRow = _this.tableList.rows[i]
                        if(currentRow.instance_details_indentation <=lastEligibleRow.instance_details_indentation){
                            rowToSwitch = currentRow
                            break
                        }
                    }
                }

                if(_.isEmpty(rowToSwitch)){
                    alert("No qualified row found to switch with the first row "+eligibleRecords[0].pdDisplayName)
                    return
                }

                let seqNumber = rowToSwitch.instance_details_sequence_no
                let seqOfFirstSelectedRow = eligibleRecords[0].instance_details_sequence_no
                // Here we are giving new non existing instance_details_sequence_no to the rowToReplace and its
                // children so they wont cause any issue during selectedRow switch
                let maxSeqNo = _.maxBy(_this.tableList.rows,'instance_details_sequence_no').instance_details_sequence_no
                let tempSeqNo = maxSeqNo + 10
                let newTempSeqNo = _this.manageChildsIndentationAndSequence(rowToSwitch,tempSeqNo,logList)
                if(!isUPDirection){
                    // we need to reserve a slot for the rowToReplace plus its children in case of DOWN direction
                    seqNumber = seqOfFirstSelectedRow+(newTempSeqNo-tempSeqNo) // (bracket) put only for easy reading
                }
                
                for(let i=0;i<eligibleRecords.length;i++){
                    let currentRow = eligibleRecords[i]
                    currentRow.parent_sequence_no = rowToSwitch.parent_sequence_no
                    currentRow.parent_job = rowToSwitch.parent_job                        
                    currentRow.instance_details_indentation = rowToSwitch.instance_details_indentation
                    seqNumber = _this.manageChildsIndentationAndSequence(currentRow,seqNumber,logList)
                }
                // reassign the actual position to the rowToSwitch and its children.
                // If its updirection then we need to assign last generated seqeunce number. 
                // If its down then we need to assign the sequence number of first eligible record.
                if(!isUPDirection)
                    seqNumber = seqOfFirstSelectedRow
                seqNumber = _this.manageChildsIndentationAndSequence(rowToSwitch,seqNumber,logList)
                

                let newRowList = _.sortBy(_this.tableList.rows, "instance_details_sequence_no")
                newRowList = _this.manageIndentationandParentSequence(newRowList)
                _this.tableList.rows = _this.manageDisplayList(newRowList)
                _this.saveSnapshot(logList)
            },
            manageChildsIndentationAndSequence(currentRow, seqNumber, logList, rowList,isCopied=false){
                var _this = this
                if(currentRow.eligible_for_shuffling == 'N'){
                    currentRow['eligible_for_shuffling'] ='Y'
                    logList.push(_this.getJobName(currentRow) + ' made eligible for the running.')
                }
                let childrens = _this.getChildrens(currentRow, rowList)

                // as we are changing instance sequence number , we have to fetch child first because fetching child
                // depends on instance_details_sequence_no
                let oldSeqNumber = currentRow.instance_details_sequence_no
                currentRow.instance_details_sequence_no = seqNumber
                if(isCopied)
                    currentRow.isCopied = true
                
                logList.push(_this.getLogMessage(currentRow,{},'seqchange') + oldSeqNumber)
                seqNumber++
                if(childrens && childrens.length){
                    _.forEach(childrens, (obj)=>{
                        obj.parent_sequence_no =  currentRow.instance_details_sequence_no
                        obj.parent_job = currentRow.process_definition_name 
                        obj.instance_details_indentation = currentRow.instance_details_indentation+1
                        seqNumber=_this.manageChildsIndentationAndSequence(obj, seqNumber, logList,rowList,true)
                    })
                }
                return seqNumber
            },
            onDependencyAdd(records){
                /*
                    While adding dependency
                    1) Perform Common Validation To Get EligibleRecords.
                    2) For each remaining job after removing child
                        a) If job which is not eligible for shuffling make its eligible for the shuffling.
                            Do not make any other change here. 
                        b) Check current job have a parent job if not show error message 
                        and stop the execution.
                        c) If have a eligible parent job then
                            i)   Reset indentation of current job as parent indentation + 1
                            ii)  Update parent_sequence_no for current job as parent job sequence number
                            iii) Do that step i) & ii) recursivly for the child jobs
                        d) Maintain the change history list so backend can log it
                        e) Maintain a list to show user if he made some wrong seletion, 
                            we will show that message before saving the snapshot
                    Note: Here parent job means IMMIDIATE above job
                    3) If we reach to step 1 & have any entry in the history list,
                     then make call to the database to save the new snapshot.
                */
                var _this = this
                _this.snackbar = false
                var logList = []
                var errorLogs = []
                
                var eligibleRecords = _this.getEligibleRows(records)
                if(!eligibleRecords || !eligibleRecords.length)
                    return
                // Step 2
                for(let i=0;i<eligibleRecords.length;i++){
                    let currentRow = eligibleRecords[i]

                    // a
                    if(currentRow.eligible_for_shuffling == 'N'){
                        currentRow['eligible_for_shuffling'] ='Y'
                        logList.push(_this.getJobName(currentRow) + ' made eligible for the running.')
                    }
                    let parentRow = {}
                    if(this.tableList.rows[currentRow.rowIndex-1].instance_details_indentation <= currentRow.instance_details_indentation)
                        parentRow = this.tableList.rows[currentRow.rowIndex-1]
                    else
                        parentRow = this.getAboveSameIndendentRow(currentRow)

                    // b
                    if(_.isEmpty(parentRow)){
                        _this.showMessage("Job "+currentRow.process_definition_name +" dont have any parent.You cannot indented it.")
                        return
                    }
                    // c
                    debugger;
                    _this.addIndentation(currentRow,parentRow,logList, errorLogs)
                }
                
                let newRowList = _this.manageDisplayList(_.cloneDeep(_this.tableList.rows))
                _this.tableList.rows = _this.manageIndentationandParentSequence(newRowList)

                if(errorLogs && errorLogs.length){
                    this.showMessage(_.join(errorLogs))
                } else{
                    _this.saveSnapshot(logList)
                }
            },
            onDependencyRemove(records){
                /*
                    While removing dependency
                    1) Perform Common Validation To Get EligibleRecords.
                    2) For each remaining job after removing child
                        a) If job which is not eligible for shuffling make its eligible for the shuffling.
                            Do not make any other change here. 
                        b) Reset indentation of current job as current indentation - 1 || 0 whichever is greater
                        c) If job have indentation
                            i)  Find a job which sequence < current job and indentation < current indentation-1
                            ii) If job found Update parent_sequence_no for current job as parent job sequence number else mark as 0
                            iii) Do that step i) & ii) recursivly for the child jobs
                        d) Maintain the change history list so backend can log it
                        e) Maintain a list to show user if he made some wrong seletion, 
                            we will show that message before saving the snapshot
                    Note: Here parent job means IMMIDIATE above job
                    3) If we reach to step 1 & have any entry in the history list,
                     then make call to the database to save the new snapshot.
                */
                var _this = this 
                _this.snackbar = false
                var logList = []
                var errorLogs = []
                var eligibleRecords = _this.getEligibleRows(records)
                if(!eligibleRecords || !eligibleRecords.length)
                    return
                // Step 2
                for(let i=0;i<eligibleRecords.length;i++){
                    var currentRow = eligibleRecords[i]
                    if(currentRow.eligible_for_shuffling == 'N'){
                        currentRow['eligible_for_shuffling'] ='Y'
                        logList.push(_this.getJobName(currentRow) + ' made eligible for the running.')
                    }
                    _this.removeIndentation(currentRow, logList, errorLogs, true)
                }
                if(errorLogs && errorLogs.length){
                    this.showMessage(_.join(errorLogs))
                }
                let newRowList = _this.manageIndentationandParentSequence(_.cloneDeep(_this.tableList.rows))
                _this.tableList.rows = _this.manageDisplayList(newRowList)
                _this.saveSnapshot(logList)
            },
            onDelete(records){
                var _this = this
                
                var sortedRecords = _this.sortRecords(records)
                var eligbleRows = this.removeChildRows(sortedRecords)
                if(this.isPageForInstance){
                    let invalidJobs = _.filter(eligbleRows,(obj)=>{
                        return obj.status != 'Waiting for execution'
                    })
                    if(invalidJobs.length){
                        return _this.showMessage("You can only delete the jobs which have status 'Waiting for execution'")
                    }
                }
                var allChilds = []
                _.forEach(eligbleRows,(obj)=>{
                    allChilds.push(obj)
                    let currentRowKids = _this.getAllChildrenRecursivly(obj)
                    allChilds = allChilds.concat(...currentRowKids)
                })
                if(!allChilds.length)
                    return
                if(this.isPageForInstance){
                    this.$emit('onDeleteJobs', _.map(allChilds,"id"))
                }
                else{
                    this.$emit('onDeleteJobs', allChilds)
                    
                }
                
            },
            getEligibleRows(records){
                /*
                    1) Sort the records by rowIndex
                    2) If Any job is running, dont do anything
                    3) If more than 1 row is selected, make sure they are in sequence
                    4) Remove all childs job from the selection, because as per the parent depedency ,
                    we gone re-evalute dependency to the childs 
                */
               var _this = this
                // Step 1
                _this.lastSelectedRows = _.map(records,"id")
                var sortedRecords = _this.sortRecords(records)

                var isRunning = _.filter(records,(obj)=>{
                    return obj.status === 'running' || obj.status ==='Sent for execution'
                })
                if(isRunning && isRunning.length){
                    alert("You cannot select running/send for execution job rows.")
                    return []
                }

                // Step 2
                if(!_this.checkSequentialRecordsSelected(sortedRecords)){
                    this.showMessage('Please select jobs in sequence')
                    return
                }

                // Step 3
                return _this.removeChildRows(sortedRecords)
            },
            sortRecords(records){
                return _.sortBy(records, "rowIndex")
            },
            checkSequentialRecordsSelected(records){
                for(var i=0;i<records.length;i++){
                    if(i===0)
                        continue
                    if(records[i-1].rowIndex+1 != records[i].rowIndex)
                        return false
                    }
                return true
            },
            removeChildRows(records,giveActualTableRow=true){
                var _this = this
                let onlyParentRecords = []
                /*
                    We will run a for loop for each record and we maintain the list of visited rows sequence number
                    and will check if current row parent sequence is in the list and its other than 0

                    giveActualTableRow = True then we will fetch actual row from the tableList Rows because
                    the function giving us records are the deepcopied. So changes wont get reflected immidiately
                */
               let visitedParent = []
               for(let i=0; i<records.length; i++){
                    let currentRecord = records[i]
                    visitedParent.push(currentRecord.instance_details_sequence_no)
                    if (visitedParent.indexOf(currentRecord.parent_sequence_no)===-1 || currentRecord.parent_sequence_no === 0){
                        if(giveActualTableRow){
                            let actualRow = _.find(_this.tableList.rows, ['id',currentRecord.id])
                            if(!actualRow){
                                alert('Failed to find actual row in the table list. Something is wrong')
                                return []
                            }
                            onlyParentRecords.push(actualRow)   
                        }
                        else
                            onlyParentRecords.push(currentRecord)
                    }
               }
               return onlyParentRecords
            },
            getAboveSameIndendentRow(currentRow, rowList,startIndex=0){
                if(!rowList)
                    rowList = this.tableList.rows
                if(currentRow.hasOwnProperty('rowIndex'))
                    startIndex = currentRow.rowIndex
                if(startIndex === 0)
                    return {}
                for(let j=startIndex-1;j>=0;j--){
                    if(rowList[j].instance_details_indentation ===  currentRow.instance_details_indentation)
                        return rowList[j]
                }
                return {}
            },
            getParentRow(currentRow){
                if(currentRow.parent_sequence_no)
                    return this.getTableRowByKey('instance_details_sequence_no',currentRow.parent_sequence_no)
                else{
                    
                }
            },
            getChildrens(currentRow,rowList){
                let immdiateChildrens = []
                if(!rowList)
                    rowList = this.tableList.rows
                for(let i=currentRow.rowIndex+1;i<rowList.length;i++){
                    if(rowList[i].id === currentRow.id)
                        continue
                    if(rowList[i].parent_sequence_no === currentRow.instance_details_sequence_no)
                        immdiateChildrens.push(rowList[i])
                }
                return immdiateChildrens
            },
            getAllChildrenRecursivly(currentRow, allChilds,rowList){
                var _this = this
                if(!allChilds)
                    allChilds = []
                let currentChild = _this.getChildrens(currentRow, rowList)
                if(currentChild.length){
                    _.forEach(currentChild,(obj)=>{
                        allChilds.push(obj)
                        allChilds = _this.getAllChildrenRecursivly(obj,allChilds)
                    })
                }
                return _.uniqBy(allChilds,'id')
            },
            addIndentation(currentRow, parentRow, logList, errorLog){
                var _this = this
                debugger;
                let parentIndentation = parentRow.instance_details_indentation || 0
                parentIndentation = parseInt(parentIndentation)

                //c.i
                if(parentIndentation+1 === currentRow.instance_details_indentation){
                    errorLog.push(_this.getJobName(currentRow)+ " having correct indentation.")
                    return
                }
                currentRow.instance_details_indentation = parentIndentation+1
                logList.push(_this.getLogMessage(currentRow, {}, 'addindent'))

                // c.ii
                currentRow.parent_sequence_no = parentRow.instance_details_sequence_no
                currentRow.parent_job = parentRow.process_definition_name
                logList.push(_this.getLogMessage(currentRow, {}, 'parentupdate'))
                // c.iii
                let currentRowChilds = _this.getChildrens(currentRow)
                if(currentRowChilds && currentRowChilds.length){
                    _.forEach(currentRowChilds, (obj)=>{
                        _this.addIndentation(obj, currentRow,logList, errorLog)
                    })
                }
                return logList, errorLog
            },
            removeIndentation(currentRow, logList, errorLogs,isFirst=false){
                /*
                    For root row we will change the indentation and parent, for other we will 
                    only change the indentation, because hiierarchy will remain same.
                */
                var _this = this
                let parentRow = _this.getParentRow(currentRow)
                if(!_.isEmpty(parentRow)){
                    currentRow.instance_details_indentation = currentRow.instance_details_indentation-1
                    logList.push(_this.getLogMessage(currentRow, {}, 'removeindent'))
                    if(isFirst){
                        currentRow.parent_sequence_no = parentRow.parent_sequence_no
                        currentRow.parent_job = parentRow.parent_job
                        logList.push(_this.getLogMessage(currentRow, _this.getTableRowByKey('parent_sequence_no',currentRow.parent_sequence_no),'parentupdate'));
                    }
                }
                else{
                    errorLogs.push(_this.getJobName(currentRow)+ " having correct indentation.")
                    return logList, errorLogs
                }
                let currentRowChilds = _this.getChildrens(currentRow)
                if(currentRowChilds && currentRowChilds.length){
                    _.forEach(currentRowChilds, (obj)=>{
                        _this.removeIndentation(obj,logList, errorLogs)
                    })
                }
                return logList
            },
            getJobName(currentRow){
                let forJob = " Job "+currentRow.process_definition_name
                return forJob
            },
            getLogMessage(currentRow,parentRow, logFor){
                let forJob = " For "+this.getJobName(currentRow)
                if(logFor === 'addindent')
                    return  forJob+" indentation added by 1"
                if(logFor === 'parentupdate')
                    return forJob+ " now parent is " + this.getJobName(parentRow)
                if(logFor === 'removeindent')
                    return  forJob+" indentation removed by 1"
                if(logFor === 'seqchange')
                    return forJob+ " seqeunce change to "+currentRow.instance_details_sequence_no+" from "
                
            },
            getTableRowByKey(keyToSearch,valueToFind,rowList){
                if(!rowList)
                    rowList = this.tableList.rows
                let searchObj = _.find(rowList,[keyToSearch,valueToFind])
                return searchObj || {}
            },
            manageIndentationandParentSequence(rowList){
                /* 
                    Here we will make sure all immidiate child have proper parent sequence
                */
                for(let i=0;i<rowList.length-1;i++){
                    let currentRow = rowList[i]
                    let childItertion =0
                    for(let j=i+1;j<rowList.length;j++){
                        childItertion++
                        let childRow = rowList[j]
                        if(currentRow.instance_details_indentation === childRow.instance_details_indentation-1){
                            childRow.parent_sequence_no = currentRow.instance_details_sequence_no
                            childRow.parent_job = currentRow.process_definition_name
                            childRow.instance_details_sequence_no = currentRow.instance_details_sequence_no + childItertion
                        }
                        else
                           break;
                    }
                }
                return _.sortBy(rowList,"instance_details_sequence_no")
            },
            saveSnapshot(logList){
                var _this = this
                let jobArray = _.cloneDeep(this.tableList.rows)
                if(this.isPageForInstance){
                    this.$emit("saveSnapshot",jobArray, logList)
                }
                else{
                    _.forEach(jobArray, (obj,index)=>{
                        obj.indentation = obj.instance_details_indentation || 0
                        obj.sequence_no = index+1
                        delete obj.instance_details_indentation
                        delete obj.parent_sequence_no
                        delete obj.parent_job
                    })
                    this.$emit("addNewJobs",jobArray)
                    return
                }
            },
            onAddMultipleJobs(records){
                this.lastSelectedRows = []
                this.selectedJobs = _.cloneDeep(records)
                this.showDialog = true
                this.dialogWidth=400
                this.whichDialog="mass"
                this.dialogHeader ="Add Mass Jobs"
            },
            setMaxDate(param){
                this.massEndValue = param; 
            },
            setMinDate(param){
                this.massStartValue = param; 
            },
            CreateMassJob(){
                var _this = this
                // 1) Validation -1 : Required field validation
                if(!this.massStartValue || !this.massEndValue){
                    this.showMessage("All fields are required")
                    return false
                }

                // 30 is max limit eg. 30 days,30 months, 30 quarters, 30 years,
                /* Based on massValueType we need to perform validation */

                // 2) Validation-2 start date should be less than end date
                var start_date = moment(this.massStartValue,'YYYY-MM-DD').toDate()
                var end_date = moment(this.massEndValue,'YYYY-MM-DD').toDate()
                if(start_date > end_date){
                    this.showMessage('Start date should be less than equal to end date')
                    return false
                }

                // end date should be less all selected jobs policies default date
                for (var i = 0; i < this.selectedJobs.length; i++) {
                    var policies = this.selectedJobs[i].policy_details
                    for (var j=0;j<policies.length;j++) {
                        if(end_date > moment(policies[j].actual_date).toDate()){
                            this.showMessage('Selected end date should be less than actual policy dates of selected policies')
                            return false
                        }
                    }
                }

                // calculate day difference
                // var diffTime = Math.abs(end_date.getTime() - start_date.getTime());
                // var diffDays =  Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                var diffDays = moment(this.massEndValue,'YYYY-MM-DD').diff(moment(this.massStartValue,'YYYY-MM-DD'),'days')

                // 3)Validation-3 if massValueType & day diff validation
                if(this.massValueType == 'Days' && diffDays > 30){
                    this.showMessage('Day different should be less than 30 days')
                    return false
                }
                if(this.massValueType == 'Months' && diffDays > 30*30){
                    this.showMessage('Months different should be less than 30 days')
                    return false
                }
                if(this.massValueType == 'Quarters' && diffDays > 30*120){
                    this.showMessage('Quarters different should be less than 30 days')
                    return false
                }
                if(this.massValueType == 'Years' && diffDays > 30*365){
                    this.showMessage('Years different should be less than 30 days')
                    return false
                }
                // now requirement is calculate the date based on user specification. Now need to consider fiscal year. This requirement given date is 27 may 2019
                var mass_job_json = []
                var unit_value_to_add = 1
                var unit = this.massValueType.toLowerCase()
                if(this.massValueType == 'Days' || this.massValueType == 'Months' || this.massValueType == 'Years'){
                    unit_value_to_add = 1
                }
                else if(this.massValueType == 'Quarters'){
                    unit = 'months'
                    unit_value_to_add = 3
                }
                else{
                    this.showMessage('Value type not found')
                    return false
                }

                _this.loader = true
                while (start_date <= end_date){
                    _.forEach(_this.selectedJobs,function(obj){
                        var new_obj = _.cloneDeep(obj)
                        var policies = new_obj.policy_details
                        for(var i =0 ;i < policies.length;i++){
                        policies[i].already_calculated = true
                        policies[i].actual_date = moment(start_date).format('YYYY-MM-DD')
                        if(policies[i].parameter)
                            delete policies[i].parameter
                        } // for end
                        new_obj['is_mass_job'] = true
                        delete new_obj['id']
                        new_obj['policy_details'] = policies
                        mass_job_json.push(new_obj)
                    })
                    start_date = moment(start_date).add(unit_value_to_add,unit)
                }
                console.log('mass_job_json', mass_job_json)
                _this.loader = false
                _this.$emit("addMassJobs", mass_job_json, _this.selectedJobs)
            },
            addNewJobsInPlan(newJobList){                
                this.$emit("addNewJobs",newJobList)
                this.closePopUp()
            },
            updateJobFromJobPlan(jobPlanData){
                this.$emit("updateJobFromJobPlan", jobPlanData)
                this.closePopUp()
            },
            closePopUp(){
                this.loader = false
                this.showDialog=false
            },
            ondblClick(record){
                if(!this.isPageForInstance)
                    this.onEdit(record)
            },
            onJobSuspend(record, defaultVal){
                this.$emit("onSuspend", record, defaultVal)
            },
            onJobTerminate(record, defaultVal){
                this.$emit("onTerminate", record, defaultVal)
            },
            onHistory(record){
                this.$emit("onHistory", record)
            },
            onResume(record, defaultVal){
                this.$emit("onResume", record)
            }
        },
    }



/* 
Unwanted code. remove it before merging into the release
onUpArrow(records){
    var _this = this
    _this.snackbar = false
    var logList = []
    var errorLogs = []
    var eligibleRecords = _this.getEligibleRows(records) 
    if(!eligibleRecords || !eligibleRecords.length)
        return
    if(eligibleRecords[0].rowIndex === 0){
        this.showMessage(_this.getJobName(eligibleRecords[0])+ " there is no available record for switch.")
        return
    }

    let allObjectIndents = _.uniqBy(eligibleRecords,'instance_details_indentation')
    if (allObjectIndents.length > 1){
        this.showMessage("You have selected some parent and child from different hierarchy.In such cases please select one row at a time.")
        return
    }

    let aboveRowToSwitchWith = {}
    for(let i=eligibleRecords[0].rowIndex-1;i>=0;i--){
        let currentRow = _this.tableList.rows[i]
        if (currentRow.instance_details_indentation <= eligibleRecords[0].instance_details_indentation){
            aboveRowToSwitchWith = currentRow
            break
        }
    }
    if(_.isEmpty(aboveRowToSwitchWith)){
        alert("No qualified row found to switch with the first row "+eligibleRecords[0].pdDisplayName)
        return
    }
    let seqNumber = aboveRowToSwitchWith.instance_details_sequence_no

    // we need to assign new non exists seq number to aboveRowToSwitch to avoid issue during child fetching
    let maxSeqNo = _.maxBy(_this.tableList.rows,'instance_details_sequence_no').instance_details_sequence_no+10
    _this.manageChildsIndentationAndSequence(aboveRowToSwitchWith,maxSeqNo,logList)

    for(let i=0;i<eligibleRecords.length;i++){
        let currentRow = eligibleRecords[i]
        currentRow.parent_sequence_no = aboveRowToSwitchWith.parent_sequence_no
        currentRow.parent_job = aboveRowToSwitchWith.parent_job                        
        currentRow.instance_details_indentation = aboveRowToSwitchWith.instance_details_indentation
        seqNumber = _this.manageChildsIndentationAndSequence(currentRow,seqNumber,logList)
    }
    seqNumber = _this.manageChildsIndentationAndSequence(aboveRowToSwitchWith,seqNumber,logList)
    let newRowList = _.sortBy(_this.tableList.rows, "instance_details_sequence_no")
    newRowList = _this.manageIndentationandParentSequence(newRowList)
    _this.tableList.rows = _this.manageDisplayList(newRowList)
    _this.saveSnapshot(logList)
},
onDownArrow(records){
    var _this = this
    _this.snackbar = false
    var logList = []
    var errorLogs = []
    var eligibleRecords = _this.getEligibleRows(records) 
    if(!eligibleRecords || !eligibleRecords.length)
        return

    let allObjectIndents = _.uniqBy(eligibleRecords,'instance_details_indentation')
    if (allObjectIndents.length > 1){
        this.showMessage("You have selected some parent and child from different hierarchy.In such cases please select one row at a time.")
        return
    }

    let copyRows = _.cloneDeep(_this.tableList.rows)

    // select such row which is next to last selected row
    let rowToSwitch = _this.getEligibleRowForMoveDown(eligibleRecords[eligibleRecords.length-1], copyRows)
    if(_.isEmpty(rowToSwitch)){
        _this.showMessage('There is no elible row down to perform switch.')
        return
    }
    
    // Notedown the seq number we have to assign to rowToSwitch
    let seqOfFirstSelectedRow = eligibleRecords[0].instance_details_sequence_no

    // now switch the sequence of the rowToSwitchChild, but in deepcopy of tablelist
    if(rowToSwitch.eligible_for_shuffling == 'N'){
        rowToSwitch['eligible_for_shuffling'] ='Y'
        logList.push(_this.getJobName(rowToSwitch) + ' made eligible for the running.')
    }
    let seqNumber = _this.manageChildsIndentationAndSequence(rowToSwitch, seqOfFirstSelectedRow, logList, copyRows, true)
    
    for(let i=0;i<eligibleRecords.length;i++){
        let currentRow = eligibleRecords[i]
        
        seqNumber = _this.manageChildsIndentationAndSequence(
            currentRow,seqNumber,logList)
    }

    // now replace rowToSwitch and its childs in actual grid by id
    let allIds = _.map(_this.tableList.rows, "id")
    for(let i=0;i<copyRows.length;i++){
        if(!copyRows[i].isCopied)
            continue
        let indexOfCurrent = allIds.indexOf(copyRows[i].id)
        // remove current element
        _this.tableList.rows.splice(indexOfCurrent,1)
        // add new element at the place of removed element
        _this.tableList.rows.splice(indexOfCurrent,0,copyRows[i])
    }

    let newRowList = _.sortBy(_this.tableList.rows, "instance_details_sequence_no")
    newRowList = _this.manageIndentationandParentSequence(newRowList)
    _this.tableList.rows = _this.manageDisplayList(newRowList)
    _this.saveSnapshot(logList)
},
getEligibleRowForMoveDown(currentRow,rowList){
    var _this = this
    if(!rowList)
        rowList = _this.tableList.rows
    for(let i=currentRow.rowIndex+1;i<rowList.length;i++){
        if(currentRow.instance_details_indentation >= rowList[i].instance_details_indentation)
            return rowList[i]
    }
    return {}
},*/
</script>