<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/get_all_notification_group">Notification Group List</router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row class="EDC-Row EDC-TopRow">
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <v-text-field class="EDC-UIComponent" label="Name of Group" v-model="group_name" hide-details autocomplete="off" dense></v-text-field>
            </v-col>
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColTextarea">
              <v-textarea class="EDC-UIComponent" v-model="group_description" label="Description" value rows="1" hide-details dense/>
            </v-col>
          </v-row>
          <v-divider class="EDC-UIComponent"/>
            <v-row class="EDC-Row"  no-gutters> 
              <v-col cols="12" class="EDC-Col">
                <v-card-title style="font-size: 14px;padding-left:15px !important;font-weight: bold;">Select events to recive notifications for:</v-card-title>
                <v-row class="EDC-Row"  no-gutters>
                  <v-col class="EDC-Col" style="padding-left:12px !important;" cols="3" align="left">
                    <v-checkbox class="customCheckbox" v-model="select_all" @change="selectAllEvents(select_all,)" label="Select All"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="EDC-Row"  no-gutters> 
                  <v-col cols="4" style="padding-left:24px !important;" v-for="(item) in tableList.rows" :key="item.name" class="EDC-Col EDC-ColsSpacing" align="left" >
                      <v-list dense>
                      <v-list-item dense v-for="(event) in item.event_list" :key="event.name" width="100%">
                        <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="start">
                          <v-col class="EDC-Col EDC-ColsSpacing " align="left">
                            <v-checkbox class="customCheckbox" dense hide-details v-model="event.is_map" :label="event.name" @change="handleSelectAll(event.is_map)"></v-checkbox>
                          <!-- </v-col>
                          <v-col class="EDC-Col" align="left"> -->
                          <!-- <v-list-item-title dense style="padding-left:8px !important;">{{event.name}}</v-list-item-title> -->
                          </v-col>
                        </v-row>
                      </v-list-item>
                          </v-list>
                  </v-col>
                </v-row>
              </v-col> 
             <!-- </v-list> -->
            <!-- </v-col> -->
          </v-row>
          <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="end">
            <vc-button itemText="Save" @click.native="saveGroup"></vc-button>
            <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
    
    <!-- <v-expansion-panel focusable>
      <v-card-text>
        <v-col>
          <template v-for="(item, index) in notification_group.all_category">
            <v-col :key="item.text" style="padding:0px;padding-top:3px;padding-bottom:3px"
            >
              <v-row no-gutters>
                <v-col cols="3">
                  <v-checkbox v-model="item.is_default" :label="item.name" :indeterminate="checkindeterminate(item.event_type_list)" :color="colorCode" class="customCheckbox" hide-details @change="selectDefaultAction(item)"/>
                </v-col>
                <v-col cols="9">
                  <v-row no-gutters>
                    <v-col v-for="(mapp, index) in item.event_type_list" :key="index" cols="3">
                      <v-checkbox :key="index" v-model="mapp.is_map" class="customCheckbox" :color="colorCode" :label="mapp.event_type" :value="mapp.is_map" :title="gettitle(mapp)" @change="checkSelectAll(item.event_type_list, item)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-col>
      </v-card-text>
    </v-expansion-panel> -->
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>
<script>
import { post as postToServer } from "./../../methods/serverCall.js";
import { get as getToServer } from "./../../methods/serverCall.js";
import config from "../../config.json";
import { SERVER_ERROR } from "../../data/client_message.js";
import {
  SERVICE_ACTION_DETAILS,
  SET_ROLE_ACTION,
  GET_ALL_ACTIVE_USERS_LIST
} from "../../data/url_constants.js";
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data() {
    return {
      chipBackgroundColor:'rgb(144, 135, 135)',
      notification_group: {
        group_description: '',
        all_events: []
      },
      snackbar: false,
      snackbartext: "",
      colorValue: "error",
      snackbartimeout: Snackbar_Default_Timeout,
      loader: false,
      loading: false,
      selectAll: false,
      action_type: "administrator",
      isDisabled:false,
      group_name: "Untitled Group Name",
      group_description: "",
      all_events: [],
      tableList:{
        headers: [
          { text:'Events', value:'name', title:'Name of Apps', width:'694px'},
          { text:'Checked', value:'all', title:'All', width:'500px', useCheckbox:true, sortable:false, toggleRow:true, useIndeterminateState:false},
          // { text:'Create', value:'Create', title:'Info', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
          // { text:'Update', value:'Update', title:'Error', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
          // { text:'Delete', value:'Delete', title:'Approval', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
        ],
        caption:'notificationGroup',
        actions: [],
        sorting_type: '',
        filterType: '',
        paginationType: '',
        total_count: 0,
        rows: [],
        selected_rows:[],
        showCreateReport:true,
        select_all:false,
        disableDefaltSorting:true,
        hideItemPerPage:true,
        hideActions:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        hideSelect:true,
        hideRowInfo:true,
        hidePagination:true,
        itemkey:'name'
      }
    };
  },
  mounted() {
    if(this.$route.params && this.$route.params.group_id){
      this.getNotificationGroup();
    } else {
      this.getAllEvents();
    }
  },
  methods: {
    gettitle(mapp, selected_events){
      var title = ""
      for (var i = 0; i < this.all_events.length; i++) {
              var single_event = this.all_events[i]
              if (single_event.event_category_code === mapp.event_category_code){
                for(var j=0;j<single_event.events.length;j++){
                  if(single_event.events[j].event_type_code === mapp.event_type){
                    return _.map(single_event.events[j].event_list,"name")
                  }
                }
              }
            }
      return title
    },
    
    checkindeterminate(action_details) {
      let found = false;
      let count = 0;
      action_details.map(function(obj) {
        if (obj.is_map) {
          found = true;
          count++;
        }
      });
      if (found && count !== action_details.length) return true;
      return false;
    },
    // selectAllAction(obj) {
    //   obj.events.map(action => {
    //     action.is_map = obj.isSelected ? true : false;
    //   });
    // },
    selectDefaultAction(item) {
      item.event_type_list.map(event => {
        event.is_map = item.is_default == true ? true : false;
      });
    },
    
    saveGroup() {
      let _this = this
      let atleast_one_category_selected = false
      let dataList = _this.tableList.rows
      _.forEach(dataList,function(obj){
          obj.is_map = false
          _.forEach(obj.event_list,function(event){
                    if(event.is_map){
                      obj.is_map = true
                      return false
                    }
                    })
                    
        })
      // let grpActionObj = _.cloneDeep(this.notification_group)
      // grpActionObj.forEach(function(cat, index){

      //   let cat_events = cat.event_list
      //   cat_events.forEach(function(event){
      //     event.is_map = false
      //   })

        

      //   let cat_obj = dataList[index]
      //   if(!cat_obj)
      //     return
      //   cat_events.forEach(function(evt){
      //     if(cat_obj[evt.event_type]){
      //       evt.is_map=true
      //       atleast_one_category_selected = true
      //     }
      //   })
      // })

      // if (!atleast_one_category_selected) {
      //   this.colorValue = "error";
      //   this.snackbartext = "Select atleast one event";
      //   this.snackbar = true;
      //   return;
      // }
      let inputjson = {}
      if(this.$route.params.group_id){
        inputjson['id'] = this.$route.params.group_id
      }
      inputjson['all_category'] = dataList
      inputjson["client_id"] = this.$session.get("client_id");
      inputjson["created_by"] = this.$session.get("email");
      inputjson["name"] = this.group_name;
      inputjson["product_name"] = "ARCHIVIST"
     inputjson["description"] = this.group_description;

      // console.log('after ',grpActionObj)
      // return false
      let url = config.NOTIFICATION_GROUP_URL + '/manage_notification_group_data';
      
      _this.loader = true;
      postToServer(this, url, inputjson)
        .then(response => {
          this.snackbar = true;
          _this.loader = false;
          this.colorValue = "success";
          this.snackbartext = "Notification Group save Successfully";
          this.$router.push("/get_all_notification_group")
        })
        .catch(DatasourceError => {
          if (DatasourceError) {
            _this.loader = false;
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = DatasourceError;
          } else {
            this.snackbar = true;
            _this.loader = false;
            this.colorValue = "error";
            this.snackbartext = SERVER_ERROR;
          }
        });
    },
    untitle_environment() {
      if (!this.group_name.trim()) {
        this.group_name = "Untitled Group Name";
      }
    },
    createTableList(group_data){
      let _this = this
      _this.tableList.rows = []
      var dataList = []
      var allowed_actions = ["Info","Error","Approval"]
      var tempRow = {"all":false,"Create":false,"Update":false,"Delete":false,"default_actions":[],"service_actions":[],"selected_actions":[],"is_row_editable":true}
      
      //   _.forEach(group_data,function(cat){
      //   let cloned_row =_.cloneDeep(tempRow)
      //   cloned_row['name']=cat['name']
      //   cloned_row['event_category_code'] =cat['event_category_code']
      //   cat.event_list.forEach(function(evt){
      //     cloned_row['service_actions'].push(evt.event_type)
      //     cloned_row[evt.event_type] = false
      //     if(evt.is_map){
      //       cloned_row[evt.event_type] = true
      //       cloned_row['selected_actions'].push(evt.event_type)
      //     }
      //   })
      //   if(cloned_row['service_actions'].length > 0 && cloned_row['service_actions'].length === cloned_row['selected_actions'].length)
      //     cloned_row['all']=true
      //   dataList.push(cloned_row)
      // })
      //if else for different input type as get all events returns array and getnotification returns object
      if(Array.isArray(group_data))
        _this.tableList.rows = group_data
      else{
        _this.tableList.rows = group_data.all_events
      }
    },
    getAllEvents() {
      let _this = this
      let l = this.loader;
      this[l] = !this[l];
      var request_data = {
        'client_id': this.$session.get("client_id"),
        'product_name': "ARCHIVIST"
      };
      this.loading = true;
      var url = config.NOTIFICATION_GROUP_URL + '/get_all_events_by_product';

      postToServer(this, url, request_data)
        .then(Response => {
          this.loading = false;
          _.forEach(Response,function(obj){
          obj.is_map = false
          _.forEach(obj.event_list,function(event){
                      event.is_map = false
                    })
                    
        })
          this.notification_group = Response;
          this.all_events = Response.all_events
          if(this.$route.params.group_id){
            this.group_name = Response.group_name
            ? Response.group_name
            : "Untitled Group Name";
          }
          _this.createTableList(_.cloneDeep(_this.notification_group))
          
        })
        .catch(error_response => {
          this.loading = false;
          if (error_response) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = error_response;
            this[l] = false;
            this.loader = null;
          } else {
            this.loader = null;
            this.colorValue = "error";
            this.snackbartext = LOGIN_FAILED_MESSAGE;
            this[l] = false;
            this.snackbar = true;
          }
        });
    },
    getNotificationGroup() {
      let l = this.loader;
      this[l] = !this[l];
      var _this = this
      var request_data = {
        client_id: this.$session.get("client_id"), 'id': this.$route.params.group_id,
        'product_name': "ARCHIVIST"};
      this.loading = true;
      var url = config.NOTIFICATION_GROUP_URL + '/get_ticket_notification_group_details';
      var _this = this
      postToServer(this, url, request_data)
        .then(Response => {
          this.loading = false;
           _this.select_all = true
           //when every events is true make select all true
          _.forEach(Response.all_events,function(obj){
            _.forEach(obj.event_list,function(event){
                        if(!event.is_map){
                          _this.select_all = false
                          return false
                        }
                      })
                      
        })
          this.notification_group = Response;
          this.group_description = Response.description
          this.all_events = Response.all_events
          if(this.$route.params.group_id){
            this.group_name = Response.name
            ? Response.name
            : "Untitled Group Name";
          }
          _this.createTableList(_.cloneDeep(_this.notification_group))
        })
        .catch(error_response => {
          this.loading = false;
          if (error_response) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = error_response;
            this[l] = false;
            this.loader = null;
          } else {
            this.loader = null;
            this.colorValue = "error";
            this.snackbartext = LOGIN_FAILED_MESSAGE;
            this[l] = false;
            this.snackbar = true;
          }
        });
    },
    selectAllEvents(select_all){
      var _this =this
       let result = _.find(_this.tableList.rows, function(obj) {
                 _.forEach(obj.event_list,function(obj){
                    obj.is_map = select_all
                    })
        });

    },
    handleSelectAll(value){
      var _this =this
      if(!value)
        this.select_all = false
      else{
        this.select_all = true
        _.forEach(_this.tableList.rows,function(obj){
            _.forEach(obj.event_list,function(event){
                        if(!event.is_map){
                          _this.select_all = false
                          return false
                        }
                      })
                      
        })
      }
        
    }
  },
};
</script>
<style scoped>
.customCheckbox >>> label {
  top: 4px !important;
}
.v-expansion-panel >>> .v-expansion-panel__header {
  padding-bottom: 0px !important;
}

.container {
  padding-top: 5px !important;
}

.EDC-TopRow {
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.EDC-GridRow {
  padding-top: 10px !important;
}

.EDC-ButtonRow {
  padding: 10px !important;
}

.EDC-ColTextarea {
  margin-top: -6px !important;
}

.EDC-UIComponent {
  margin: 0px !important;
  padding: 0px !important;
}
</style>