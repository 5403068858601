<template> 
  <div>
    <v-row class="EDC-Row">
        <v-col cols="8" class="EDC-Col">
            <v-row class="EDC-Row breadcrumbRow">
                <v-col cols="12" class="EDC-Col">
                    <ul class="breadcrumb breadcrumbUL">
                        <li>
                        <select v-model="Environment" @change="GetLimitDetailsList" style="-webkit-appearance: menulist" autofocus ref="envname">
                            <option :value="{}">Select Environment</option>
                            <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                        </select>
                        </li>
                        <li>Limit Rules</li>
                    </ul>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
    
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
    import moment from 'moment'
    import config from '../../../config.json'
    import {
        get as getToServer,
        post as postToServer
    } from '../../../methods/serverCall.js';
    import {
        SERVER_ERROR
    } from '../../../data/client_message.js'

    import {
        CLIENT_SIDE,
        SERVER_SIDE
    } from '../../../data/macros.js'
    import cloneDeep from 'lodash/cloneDeep';
    import orderBy from 'lodash/orderBy';
    import _ from 'lodash'
    import environmentList from '../../../methods/EnvironmentList.js'
    import {JOB_MANAGEMENT_SERVICE,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,READ_ACTION} from "../../../data/macros.js"
    import getUserRole from '../../../methods/GetUserRole.js'
    import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import {ALL_PUBLISHED_BUSINESS_OBJ_LIST,GET_ALL_PUBLISHESD_PROCESS_DEF_LIST, FETCH_ALL_LIMIT_RULE, DELETE_LIMIT_RULE, MANAGE_LIMIT_RULE} from '../../../data/url_constants.js';
    export default {
    name: 'JobLimitRules',
        
    data: function () {
        return {
            loader:false,
            // tableList: {
            //     headers: [
            //         {
            //             text: 'Id',
            //             value: 'id',
            //             title: 'Rule Id',
            //             disabled:true,
            //             align:'right',
            //             dataType:'int'
            //         },
            //         {
            //             text: 'Business Object',
            //             value: 'bo_name',
            //             width: "20%",
            //             option:[],
            //             'item_text':'bo_name',
            //             'item_value':'bo_name',
            //             title: 'Business Object Name',
            //             useAutoComplete:true
            //         },
            //         // {
            //         //     text: 'Process Definition',
            //         //     value: 'pd_name',
            //         //     width: "20%",
            //         //     option:[],
            //         //     'item_text':'pd_name',
            //         //     'item_value':'pd_name',
            //         //     title: 'Process Definition Name',
            //         //     useAutoComplete:true
            //         // },
            //         {
            //             text: 'Sample Size',
            //             value: 'sample_count',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Number of iteration to use an sample to ditermine % change',
            //             useTextField:true,
            //             inputType:'number'
            //         },

            //         {
            //             text: '% Change',
            //             value: 'percent_diff',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Minimum % rate change between sample to auto adjust',
            //             useTextField:true,
            //             inputType:'number'
            //         },

            //         {
            //             text: 'Lower Limit',
            //             value: 'lower_limit',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Lower Limit',
            //             useTextField:true,
            //             inputType:'number'
            //         },
            //         {
            //             text: 'Upper Limit',
            //             value: 'upper_limit',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Upper Limit',
            //             useTextField:true,
            //             inputType:'number'
            //         },
            //         {
            //             text: 'Increment',
            //             value: 'increment',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Increment',
            //             useTextField:true,
            //             inputType:'number'
            //         },
                    
            //         {
            //             text: 'Max Time',
            //             value: 'time_limit',
            //             isNumber:true,
            //             width: "15%",
            //             align:'right',
            //             title: 'Transaction Max time',
            //             useTextField:true,
            //             inputType:'number'
            //         },
            //         // {
            //         //     text: 'Direction',
            //         //     value: 'direction',
            //         //     isNumber:true,
            //         //     width: "15%",
            //         //     align:'left',
            //         //     title: 'Direction-> Lower to Upper Or Upper to Lower',
            //         //     option:[{'name':"up","value":"up"},{'name':"down","value":"down"}],
            //         //     'item_text':'name',
            //         //     'item_value':'name',
            //         //     useAutoComplete:true
            //         // },
            //         // {
            //         //     text: 'Minimum Iteration',
            //         //     value: 'limit_rule_count',
            //         //     isNumber:true,
            //         //     width: "15%",
            //         //     align:'right',
            //         //     title: 'Minimum iteration to apply rule',
            //         //     useTextField:true,
            //         //     inputType:'number'
            //         // },
            //     ], 
            //     actions:[], //if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     itemkey:'id',
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     is_grid_inline_edit:true
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    {
                        text: 'Id',
                        value: 'id',
                        title: 'Rule Id',
                        disabled:true,
                        align:'right',
                        dataType:'int'
                    },
                    {
                        text: 'Business Object',
                        value: 'bo_name',
                        width: "20%",
                        option:[],
                        'item_text':'bo_name',
                        'item_value':'bo_name',
                        title: 'Business Object Name',
                        useAutoComplete:true
                    },
                    // {
                    //     text: 'Process Definition',
                    //     value: 'pd_name',
                    //     width: "20%",
                    //     option:[],
                    //     'item_text':'pd_name',
                    //     'item_value':'pd_name',
                    //     title: 'Process Definition Name',
                    //     useAutoComplete:true
                    // },
                    {
                        text: 'Sample Size',
                        value: 'sample_count',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Number of iteration to use an sample to ditermine % change',
                        useTextField:true,
                        inputType:'number'
                    },

                    {
                        text: '% Change',
                        value: 'percent_diff',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Minimum % rate change between sample to auto adjust',
                        useTextField:true,
                        inputType:'number'
                    },

                    {
                        text: 'Lower Limit',
                        value: 'lower_limit',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Lower Limit',
                        useTextField:true,
                        inputType:'number'
                    },
                    {
                        text: 'Upper Limit',
                        value: 'upper_limit',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Upper Limit',
                        useTextField:true,
                        inputType:'number'
                    },
                    {
                        text: 'Increment',
                        value: 'increment',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Increment',
                        useTextField:true,
                        inputType:'number'
                    },
                    
                    {
                        text: 'Max Time',
                        value: 'time_limit',
                        isNumber:true,
                        width: "15%",
                        align:'right',
                        title: 'Transaction Max time',
                        useTextField:true,
                        inputType:'number'
                    },
                    // {
                    //     text: 'Direction',
                    //     value: 'direction',
                    //     isNumber:true,
                    //     width: "15%",
                    //     align:'left',
                    //     title: 'Direction-> Lower to Upper Or Upper to Lower',
                    //     option:[{'name':"up","value":"up"},{'name':"down","value":"down"}],
                    //     'item_text':'name',
                    //     'item_value':'name',
                    //     useAutoComplete:true
                    // },
                    // {
                    //     text: 'Minimum Iteration',
                    //     value: 'limit_rule_count',
                    //     isNumber:true,
                    //     width: "15%",
                    //     align:'right',
                    //     title: 'Minimum iteration to apply rule',
                    //     useTextField:true,
                    //     inputType:'number'
                    // },
                ], 
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            EnvironmentList: [],
            Environment: 0,
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            userRole:{},
            BO_list:[],
            PD_list:[],
        }
    },//
    mounted() {
        var env_value = this.$session.get('selected_env')
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList = this.getGridObj(this.headers,'id',true,{is_grid_inline_edit:true})
        this.tableList.actions = [{'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,UPDATE_ACTION),index:1},
                    {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,CREATE_ACTION),index:2},    
                    {'text':"save","key":"is_row_editable",selectType:"single",index:3},
                    {'text':"cancel","key":"is_row_editable",selectType:"single",index:4},
                    {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,DELETE_ACTION),index:5},
                    ]
        if( env_value && env_value.id != '0'){
            this.Environment = this.$session.get('selected_env')
            this.GetLimitRuleListByEnv()
        }else{
            this.tableList.rows = []
        }
        getEnvironmentList(this);
        this.focus_env()
    },
    methods: {
        
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
        onNew(record){
            if(!this.Environment){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'Please select environment';
                return;
            }

            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
          this.tableList.rows.unshift({'bo_id':'',
                        'pd_id':'',
                        'time_limit':'',
                        'sample_count':'',
                        'upper_limit':'',
                        'increment':'',
                        'lower_limit':'',
                        'limit_rule_count':'',
                        'direction':'up',
                        'percent_diff':'20',
                        'is_row_editable' : true
                });              
        },
        onSave(record){
            let _this = this;
            _this.loader = true;
            if(!record.bo_name  || !record.upper_limit || !record.lower_limit  || !record.sample_count || !record.increment  || !record.percent_diff){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields required';
                this.loader = false
                return false
            }

            if(record.percent_diff >=100 || record.percent_diff < 0 ){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'Please enter valid percentage change value';
                this.loader = false
                return false
            }
            var data_to_send = {
                "client_id":this.$session.get('client_id'),
                "env_id":this.Environment.id,
                "bo_name":record.bo_name,
                "bo_id":_.find(this.BO_list,["bo_name",record.bo_name]).bo_id,
                'time_limit':parseInt(record.time_limit),
                'sample_count':parseInt(record.sample_count),
                'upper_limit':parseInt(record.upper_limit),
                'increment':parseInt(record.increment),
                'lower_limit':parseInt(record.lower_limit),
                'percent_diff':parseInt(record.percent_diff),
                'direction':'up',
                "id":record.id,
                "env_name": this.Environment.name
                }
            var manageLimitRule = config.COORDINATOR_API_URL + MANAGE_LIMIT_RULE; 
            postToServer(_this, manageLimitRule, data_to_send).then(response => {
                _this.snackbar = true
                _this.colorValue = 'success'
                _this.snackbartext = response;
                _this.GetLimitRuleListByEnv()
                _this.loader = false;
            }).catch(error_response => {
                _this.loader = false;
                _this.GetLimitRuleListByEnv();
                if(error_response){
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = error_response;
                }
                else {
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        onCancel(){
            this.GetLimitRuleListByEnv();
        },
        onCellEvent(record){
            this.onEdit(record);
        },
        onDelete(record){
            let _this = this
            var delete_limit_rule = config.COORDINATOR_API_URL + DELETE_LIMIT_RULE;
            var env_id = this.Environment.id
            var var_data = {"client_id":this.$session.get('client_id'), "id": record.id, "env_id": env_id, "env_name": this.Environment.name};
            debugger
            _this.loader = true;
            if(!record.id){
                this.onCancel();
                return;
            }
            postToServer(_this, delete_limit_rule, var_data).then(response => {
                _this.snackbar = true
                _this.loader = false;
                _this.colorValue = 'success'
                _this.snackbartext = response;
                _this.GetLimitRuleListByEnv()
            }).catch(error_response => {
                _this.loader = false;
                if(error_response){
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = error_response;
                }
                else {
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        GetLimitDetailsList(){
            this.$session.set('selected_env', this.Environment)
            var env_id = this.Environment

            if(env_id != "0"){
                this.GetLimitRuleListByEnv()
            }else{
                this.tableList.rows = []
            }
        },

        GetBusinessObjectList(env_id) {
            var user_id = this.$session.get('user_id')
            var client_id = this.$session.get('client_id')
            var get_bo_list_url = config.PUBLISHER_URL + ALL_PUBLISHED_BUSINESS_OBJ_LIST
            var data = {"client_id": client_id, "env_id": env_id}
            var _this = this
            postToServer(_this, get_bo_list_url, data, true).then(response => {
                    response.unshift({"id": 0, "object_id": "BO0", "object_name": "ANY"});
                    response.map((obj)=>{
                    obj.bo_name = obj.object_name
                    obj.bo_id = obj.object_id
                })
                    _this.tableList.headers[1].option = response;
                    this.BO_list = response
                    console.log('bos',response)
                }).catch(error_response => {
                if(error_response){
                    
                }
                else {
                    
                    }
                });
        },
        getProcessDefList (env_id) {
            let _this = this;
            _this.loader = true; 
            var client_id = this.$session.get('client_id')
            var data = {"client_id":client_id,"env_id":env_id}
            postToServer(this, config.PUBLISHER_URL + '/published_process_def_in_env', data).then(response => {
                _this.loader = false;
                response.unshift({"id": 0, "object_id": "PD0", "object_name": "ANY"});
                response.map((obj)=>{
                    obj.pd_name = obj.object_name
                    obj.pd_id = obj.object_id
                })
                this.tableList.headers[2].option = response;
                this.PD_list = response
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    this.tableList.headers[2].option = [];
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        GetLimitRuleListByEnv()
        {
              var env_id = this.Environment.id
            this.GetBusinessObjectList(env_id)
            this.getProcessDefList(env_id)

            let _this = this
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var data = {'client_id': client_id, 'env_id': env_id}
            var get_all_limit_rules = config.COORDINATOR_API_URL + FETCH_ALL_LIMIT_RULE
            postToServer(this, get_all_limit_rules, data).then(response => {
            _this.loader = false;
            this.tableList.rows = response;
            this.tableList.total_count = response.length;
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                this.tableList.rows = []
                // this.snackbar = true
                // this.colorValue = 'error'
                // this.snackbartext = error_response;
            }
            else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });
        }
    }
}
</script>
<style>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>