<template>
  <div id="adduser">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/userlist">User List</router-link>
              </li>
              <li>Add User</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form v-model="valid" ref="add_user_form">
        <v-card elevation-1> 
          <v-container class="EDC-Container">
            <v-row no-gutters align="center" class="EDC-Row" style="padding-top:10px !important">
              <v-col cols="12">
                <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                  <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="first_name" label="First Name" :rules="nameRules" required dense></v-text-field>
                  </v-col>
                  <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="last_name" label="Last Name" :rules="nameRules" required dense></v-text-field>
                  </v-col>
                  <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="email" label="Email" :rules="emailRules" required dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                  <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <v-autocomplete clearable label="Date Format" v-model="date_format" :rules="dateFormatRules" :items="date_formatitems" style="" item-value="python_format" item-text="user_format" dense></v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="EDC-Col">
                    <v-checkbox class="customCheckbox" :label="`Enabled`" :color="colorCode" v-model="is_enabled" :disabled="!show_enable_button" dense></v-checkbox>
                  </v-col>
                  <v-col cols="4" class="EDC-Col">
                    <v-checkbox class="customCheckbox" :label="`Use LDAP Login `" :color="colorCode" v-model="logintype" dense></v-checkbox>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" class="EDC-Row">
                  <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="ldap_login_name" label="LDAP Login Name" dense></v-text-field>
                  </v-col>
                  <v-col cols="1" class="EDC-Col" style="margin-top:-10px !important">
                    <vc-button itemText="Fetch DN" @click.native="fetchUserDN()" :loading="dnloading" :disabled="!ldap_login_name"></vc-button>
                    <!-- <v-btn outline @click="fetchUserDN()" :color="outlineColor" :loading="dnloading" @click.native="loader='loading'" style="border-radius:10px" :disabled="!ldap_login_name">Fetch DN</v-btn> -->
                  </v-col>
                  <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="ldap_user_dn" label="LDAP User DN" dense></v-text-field>
                  </v-col>
                  <v-col cols="4" class="EDC-Col">
                    <v-checkbox class="customCheckbox" :disabled="disabledPasswordNeverExpire" label="Password Never Expire" :color="colorCode" v-model="passwordNeverExpire" dense></v-checkbox>
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" class="EDC-Row">
                  <v-col cols="4" class="EDC-Col">
                    <v-checkbox class="customCheckbox" label="Enable Icon Shuffling On Use" :color="colorCode" v-model="enableIconPreference" dense></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
              <vc-button itemText="Save" @click.native="submit"></vc-button>
              <!-- <v-btn outline @click="submit" :color="outlineColor" :loading="loading" :disabled="loading" @click.native="loader='loading'" style="border-radius:10px">Save</v-btn> -->
              <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
              <!-- <v-btn outline @click="onCancel" :color="outlineColor" style="margin-right: 0px;border-radius:10px">Cancel</v-btn> -->
            </v-row>

          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>

<style scoped>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 20px;
}
#adduser .v-input--checkbox, label {
  margin-top:2%;
}
.EDC-UIComponent{
    margin: 0px !important;
    padding: 0px !important;
}
.customCheckbox >>> label{
  top: 5px !important;
}
</style>

<script>
import config from "../../config.json"
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ADD_USER, GET_USER, UPDATE_USER } from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
import {
  DATE_TIME_FORMAT
} from '@/data/manage_account_constants.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    
export default {
  data () {
      return {
        date_formatitems: DATE_TIME_FORMAT,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        valid: false,
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader: null,
        loading: false,
        show1: false,
        show2: false,
        valid: true,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        date_format: "%m/%d/%Y",
        show_enable_button:true,
        show_password_control:true,
        ldap_user_dn:'',
        nameRules:[
          v => !!v || 'Name is required'
        ],
        is_enabled: true,
        verification_status: true,
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid'
        ],
        pwdRules: [
          v => !!v || 'Password is required',
          v => v.length >= 8 || 'Min 8 characters',
        ],
        confirmPwdRules: [
          v => !!v || 'Confirm Password is required',
          (v) => v == this.password || 'Password and Confirm Password must match'
        ],
        dateFormatRules: [v => !!v || 'Date Format is required.'],
        logintype:false,
        ldap_login_name:'',
        dnloading:false,
        passwordNeverExpire:false,
        enableIconPreference:false

      }
    },
    computed:{
      disabledPasswordNeverExpire(){
        return this.email === this.$session.get('email')
      }
    },
    mounted () {
      if (this.$route.params.userid) {
        this.getUser()
        debugger
        this.show_enable_button = this.$route.params.show_enable_button
        this.show_password_control = false
      }
    },
    methods: {
      onCancel(){
      this.$router.push("/userlist")
      },
      submit () {
        if (this.$refs.add_user_form.validate()) {
          if(this.logintype && !this.ldap_login_name){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'LDAP login name required';
            return false
          }
          if(this.$route.params.userid && this.$route.params.type && this.$route.params.type == 'edit'){
            this.updateUser()
          } else {
            this.addUser()
          }
        }
      },
      clear () {
        this.$refs.add_user_form.reset()
      },
      getUser() {
        console.log(this.$route.params.userid)
        getToServer(this, config.USER_PROVISION_URL + GET_USER +this.$route.params.userid).then(response => {
                var data = response
                this.first_name = data.first_name
                this.last_name = data.last_name
                this.email = data.username
                this.date_format = data.date_format
                this.logintype = data.login_type?true:false
                this.ldap_login_name = data.other_user_name
                this.ldap_user_dn = data.other_user_detail
                if(response.password_never_expires)
                  this.passwordNeverExpire = true
                if(response.enable_icon_preference)
                  this.enableIconPreference = true
                if(data.verification_status === "Waiting for email verification"){
                  this.is_enabled = false
                  this.verification_status = data.verification_status
                }else{
                  this.is_enabled = data.verification_status === "Disabled" ? false : true
                  this.verification_status = this.is_enabled
                }
           }).catch(error_response => {
            if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this.loader = null 
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
        })
     },
      addUser () {
        if(this.logintype &&(!this.ldap_login_name || !this.ldap_user_dn)){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'LDAP deatils required';
          return
        }
        let l = this.loader
        this[l] = !this[l]
          var Authorization = this.$session.get('access_token')
          var client_id = this.$session.get('client_id')
          var UserData = {"username": this.email, "client_id": client_id, "first_name": this.first_name,
           "last_name": this.last_name, "password": this.password, "active": this.is_enabled, 
           "date_format": this.date_format,'login_type':this.logintype ? 'LDAP':'',
           'other_user_name':this.logintype ?this.ldap_login_name:'', 
           'other_user_detail':this.logintype ?this.ldap_user_dn:'',
           'password_never_expires':this.passwordNeverExpire,
            'enable_icon_preference':this.enableIconPreference
          }
          postToServer(this, config.USER_PROVISION_URL + ADD_USER, UserData).then(userResponse  => {
                if(userResponse){
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = 'User added successfully';
                  this.$cookies.set('user_add', true)
                  this.$router.push("/userlist")
                }
            }).catch(error_response => {
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this[l]  = false
              this.loader = null
            }
            else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
              
            }
      });
    },
    updateUser () {
        if(this.logintype &&(!this.ldap_login_name || !this.ldap_user_dn)){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'LDAP deatils required';
          return
        }
        let l = this.loader
        this[l] = !this[l]
          debugger
          var Authorization = this.$session.get('access_token')
          var client_id = this.$session.get('client_id')
          var UserData = {"userid": this.$route.params.userid, "username": this.email, "client_id": client_id, "first_name": this.first_name, "last_name": this.last_name,  "active": this.verification_status,"full_name":this.first_name + " "+this.last_name,
           "date_format": this.date_format,'login_type':this.logintype ? 'LDAP':'',
           'other_user_name':this.logintype ?this.ldap_login_name:'',
           'other_user_detail':this.logintype ?this.ldap_user_dn:'',
           'password_never_expires':this.passwordNeverExpire,
          'enable_icon_preference':this.enableIconPreference}
          postToServer(this, config.USER_PROVISION_URL + UPDATE_USER, UserData).then(userResponse  => {
                if(userResponse){
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = 'User updated successfully';
                  this.$cookies.set('user_add', true)
                  this.$router.push("/userlist")
                }
            }).catch(error_response => {
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this[l]  = false
              this.loader = null
            }
            else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
              
            }
      });
      },
      fetchUserDN(){
          var client_id = this.$session.get('client_id')
          var UserData = {"client_id": client_id,'ldap_user_name':this.ldap_login_name}
          this.dnloading = true
          postToServer(this, config.USER_PROVISION_URL + '/sync_ldap_dn', UserData).then(userResponse  => {
                this.dnloading = false
                if(userResponse)
                  this.ldap_user_dn = userResponse.userdn
            }).catch(error_response => {
              this.dnloading = false
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this[l]  = false
              this.loader = null
            }
            else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
              
            }
      });
      }
    }
  }
</script>