
export const TABLE_LIST = {
    headers: [
        { text: 'Object ID', value: 'object_id',width:'20%', cssClass:'w2', title: 'Object id', sortable: false},
        { text: 'Name', value: 'object_name',width:'20%', cssClass:'w2', title: 'Object Name' },
        { text: 'Type', value: 'object_type_desc',width:'20%', title: 'Object Type' },
        { text: 'Version', value: 'object_version', options:[], align:'right',width:'5%', title: 'Object Version', dataType:'int'},
        { text: 'Erp code', value: 'erp_code', options:[], title: 'Object Erp Code', sortable: false, hideInlineFilter: true},
        { text: 'Erp Version', value: 'erp_version', options:[], align:'right', title: 'Object Erp Version', dataType:'int', sortable: false, hideInlineFilter: true},
        { text: 'Published By', value: 'published_by', options:[],width:'25%', cssClass:'w16', title: 'Object Published By' },
        { text: 'Published Date', value: 'timezone_aware_published_date',width:'15%', dataType:'date', options:[],cssClass:'w16', title: 'Object Published Date'},
        { text: 'Published Time', value: 'timezone_aware_published_time',width:'15%',dataType:'time',options:[],cssClass:'w16', title: 'Object Published Time', hideInlineFilter: true},
    ],
    actions:[],
    rows:[]
}
export const ZERO_ID_LIST = {
    headers: [
        { text: 'Object ID', value: 'object_id',width:'20%', cssClass:'w2', title: 'Object id', sortable: false},
        { text: 'Name', value: 'object_name', cssClass:'w16', title: 'Object Name'},
        { text: 'Type', value: 'object_type_desc', title: 'Object Type'},
        { text: 'Ver', value: 'object_revision', options:[], align:'right', title: 'Object Version', dataType:'int'},
        { text: 'Erp code', value: 'erp_code', options:[], title: 'Object Erp Code'},
        { text: 'Erp Version', value: 'erp_version', options:[], align:'right', title: 'Object Erp Version', dataType:'int'},
        { text: 'Created By', value: 'added_by', options:[], cssClass:'w16', title: 'Object Created By'},
        { text: 'Created Date', value: 'timezone_aware_added_on_date',dataType:'date', options:[], cssClass:'w16', title: 'Object Created Date'},
        { text: 'Created Time', value: 'timezone_aware_added_on_time',dataType:'time', options:[], cssClass:'w16', title: 'Object Created Time', hideInlineFilter: true},
        { text: 'Last Modified By', value: 'modified_by', options:[], cssClass:'w16', title: 'Object Last Modified By'},
        { text: 'Last Modified Date', value: 'timezone_aware_modified_on_date', dataType:'date', options:[], cssClass:'w16', title: 'Object Last Modified Date'},
        { text: 'Last Modified Time', value: 'timezone_aware_modified_on_time', dataType:'time', options:[], cssClass:'w16', title: 'Object Last Modified Time', hideInlineFilter: true},
        { text: 'Env Pub', value: 'env_pub_count', options:[], align:'right', title: 'Object published In(Environment)', dataType:'int'},
        { text: 'Is Template', value: 'template', options:[], align:'right', title: 'Is Template)', dataType:'int'}
    ],
    actions:[],
    rows:[]
}
export const ALL_ENV_LIST = {
    headers: [
        { text: 'Object ID', value: 'object_id',width:'20%', cssClass:'w2', title: 'Object id', sortable: false},
        { text: 'Name', value: 'object_name', cssClass:'w2', title: 'Object Name', width:'20%'},
        { text: 'Type', value: 'object_type_desc', title: 'Object Type', width:'15%'},
        { text: 'Ver', value: 'object_version', options:[], align:'right', title: 'Object Version', width:'5%', dataType:'int'},
        { text: 'Erp code', value: 'erp_code', options:[], title: 'Object Erp Code', sortable: false, hideInlineFilter: true},
        { text: 'Erp Version', value: 'erp_version', options:[], align:'right', title: 'Object Erp Version', dataType:'int', sortable: false, hideInlineFilter: true},
        { text: 'Environment', value: 'environment', cssClass:"w16", title: 'Object Environment', width:'20%'},
        { text: 'Published By', value: 'published_by', options:[], cssClass:'w16', title: 'Object Published By', width:'20%'},
        { text: 'Published Date', value: 'timezone_aware_published_date',dataType:'date', options:[],cssClass:'w16', title: 'Object Published Date', width:'10%'},
        { text: 'Published Time', value: 'timezone_aware_published_time',dataType:'time', options:[],cssClass:'w16', title: 'Object Published Time', width:'10%', hideInlineFilter: true},
    ], 
    actions:[],
    rows:[]              
            
}
export const ENV_IP_JSON = {
    "filter": [],
    "sort": [{
        "column_name": "",
        "type": ""
    }],
    "page": 1,
    "page_size": 10
}