<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Workflow</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onEdit="onEdit" @onDownload="onDownload" @onNew="onNew" @onDelete="onDelete" @onCopy="onCopy" @ondblClick="onEdit"></edc-data-grid>
            
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_ALL_WORKFLOW_OBJECTS, DELETE_OBJECT, EXPORT_WORKFLOW_DETAILS
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import getUserRole from '@/methods/GetUserRole.js'
import {REPOSITORY_SERVICE_ID,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,READ_ACTION} from "@/data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ClientJobListNew',

    data: function () {
        return { 
            headers: [{
                        text: 'Workflow Name',
                        value: 'workflow_name',
                        width: "25%"
                    },
                    {
                        text: 'Created By',
                        value: 'added_by',
                        width: "25%",
                        title: 'Workflow Created By'
                    },
                    {
                        text: 'Created Date',
                        value: 'timezone_aware_added_on_date',
                        dataType:'date',
                        width: "30%",
                        title: 'Workflow Created Date'  
                    },
                    {
                        text: 'Created Time',
                        value: 'timezone_aware_added_on_time',
                        dataType:'time',
                        width: "30%",
                        title: 'Workflow Created Time',
                        hideInlineFilter: true
                    },
                ],       
            // tableList: {
            //     headers: [{
            //             text: 'Workflow Name',
            //             value: 'workflow_name',
            //             width: "25%"
            //         },
            //         {
            //             text: 'Created By',
            //             value: 'added_by',
            //             width: "25%",
            //             title: 'Workflow Created By'
            //         },
            //         {
            //             text: 'Created Date',
            //             value: 'timezone_aware_added_on_date',
            //             dataType:'date',
            //             width: "30%",
            //             title: 'Workflow Created Date'  
            //         },
            //         {
            //             text: 'Created Time',
            //             value: 'timezone_aware_added_on_time',
            //             dataType:'time',
            //             width: "30%",
            //             title: 'Workflow Created Time',
            //             hideInlineFilter: true
            //         },
            //     ],
                
            //     actions: [{'text':'edit','key':"", selectType:"single"},
            //     {'text':'copy','key':"showEdit", selectType:"single"},
            //     {'text':'new','key':"", selectType:"single"},
            //     {'text':'delete','key':"", selectType:"single"}],
            //      //if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: []
            // },
            tableList: {
                headers:[],
                actions:[],
                rows:[]
            },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList =  this.getGridObj(this.headers, 'workflow_name')
        this.tableList.actions=[{'text':'new','key':"workflow_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,CREATE_ACTION),index:1},    
                {'text':'copy','key':"workflow_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,CREATE_ACTION),index:3},
                {'text':'edit','key':"workflow_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID, CREATE_ACTION),index:2},
                {'text':'delete','key':"workflow_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE_ID,DELETE_ACTION),index:4}];

        this.getWorkflowList();    
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option":downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "object_type_code": "WF",
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.IDE_API_URL+EXPORT_WORKFLOW_DETAILS;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.IDE_API_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        onNew:function(){
            let routeData = this.$router.resolve({name: 'workflowide'});
            window.open(routeData.href, '_blank');         
        },
        onCopy:function(record){
            let routeData = this.$router.resolve({ name: 'workflowide',
                query: {'object_id': record.object_id, 'object_revision': record.object_revision,
            'copy':'Y'}});
            window.open(routeData.href, '_blank');
        },
        onEdit(record){
            let routeData = this.$router.resolve({ name: 'workflowide',
                query: { object_id: record.object_id, object_revision: record.object_revision}}); 
            window.open(routeData.href, '_blank');          
        },
        onDelete(record){
            let _this = this
            _this.loader = true;
            var object_list = [{'object_id': record.object_id, 'object_revision':record.object_revision}]
            var delete_data = {'client_id': this.$session.get('client_id'), 'object_list': object_list, 'object_type_code': 'WF'}
            var delete_object_url = config.IDE_API_URL + DELETE_OBJECT
            postToServer(_this, delete_object_url, delete_data).then(response => {
            _this.snackbar = true
            _this.loader = false;
            _this.colorValue = 'success'
            _this.snackbartext = response;
            _this.getWorkflowList()
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            }
            else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
                }
            });
        },
        getWorkflowList () {
            this.tableList.rows = []
			var user_id = this.$session.get('user_id')
            var client_id = this.$session.get('client_id')
			var get_workflow_list_url = config.IDE_API_URL + GET_ALL_WORKFLOW_OBJECTS
            var data = {"client_id": client_id, "object_type_code": "WF"}
			postToServer(this, get_workflow_list_url, data).then(response => {
                    // this.taskList = response
                    this.tableList.rows = response;
                    this.tableList.total_count = response.length;
				}).catch(error_response => {
				// if(error_response){
				// 	this.snackbar = true
				// 	this.colorValue = 'error'
				// 	this.snackbartext = error_response;
				// }
				// else {
				// 	this.snackbar = true
				// 	this.colorValue = 'error'
				// 	this.snackbartext = SERVER_ERROR;
				// 	}
				});
		},
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>