<template>
  <v-container class="EDC-Container">

    <v-row class="EDC-DashboardRow">
      <v-col cols="8" class="EDC-DashboardCol">
        <v-row class="EDC-DashboardRow EDC-DashboardRowSpacing" align="center">
          <v-col cols="12" class="EDC-DashboardCol">
            <ul class="breadcrumb">
              <li>
                <select v-model="Environment" v-on:change="performDataBinding" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Dashboard</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-row no-gutters class="EDC-DashboardRow EDC-DashboardRowSpacing">
      <v-col cols="8" class="EDC-DashboardCol">
        <v-row no-gutters class="EDC-DashboardRow">

          <v-col cols="5" class="EDC-DashboardCol EDC-DashboardColSpacing">
            <v-card class="EDC-VCards" elevation="1">
              <v-row no-gutters class="EDC-DashboardRow" align="center">
                <v-col cols="12" class="EDC-DashboardCol">
                  <span class="EDC-Heading">{{piechartTitle}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="EDC-DashboardRow">
                <v-col cols="12" class="EDC-PieCharts EDC-DashboardCol">
                  <IEcharts :option="pieoption" :loading="pieloading" @click="onPieClick" @ready="onPieReady"/>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3" class="EDC-DashboardCol EDC-DashboardColSpacing">
            <v-row no-gutters class="EDC-DashboardRow">
              <v-col cols="12" class="EDC-DashboardCol">
                  <v-card class="EDC-VCardsMini linkindication" elevation="1" @click="$router.push('/compliancearchive')">

                      <v-row no-gutters class="EDC-DashboardRow">
                        <v-col cols="12" class="EDC-DashboardCol">
                          <span class="EDC-Heading">Archival Score</span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="EDC-DashboardRow">
                        <v-col cols="12" class="EDC-DashboardCol" style="padding-top: 15px !important">
                          <v-chip class="ma-2" color="primary" label link><b>{{completed_archives}} / {{total_archives}}</b></v-chip>
                        </v-col>
                      </v-row>

                  </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-DashboardRow EDC-DashboardRowSpacing">
              <v-col cols="12" class="EDC-DashboardCol">
                  <v-card class="EDC-VCardsMini linkindication" elevation="1" @click="showDisposal()">

                      <v-row no-gutters class="EDC-DashboardRow">
                        <v-col cols="12" class="EDC-DashboardCol">
                          <span class="EDC-Heading">Disposal Score</span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="EDC-DashboardRow">
                        <v-col cols="12" class="EDC-DashboardCol" style="padding-top: 15px !important">
                          <v-chip class="ma-2" color="primary" label link><b>{{completed_dispose}} / {{total_dispose}}</b></v-chip>
                        </v-col>
                      </v-row>

                  </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="EDC-DashboardCol EDC-DashboardColSpacing">
            <v-card class="EDC-VCards scrollbar" id="style-2" elevation="1">
              <v-col cols="12" class="EDC-DashboardCol">
                <v-row no gutter class="EDC-DashboardRow" align="center" style="text-align:left">
                  <v-col cols="8" class="EDC-DashboardCol">
                    <v-badge :content="currentJobItems.length" v-if="currentJobItems.length > 0" inline bordered>
                      <span class="EDC-Heading">Current Jobs</span>
                    </v-badge>
                    <span class="EDC-Heading" v-else>Current Jobs</span>
                  </v-col>
                  <v-col cols="4" class="EDC-DashboardCol">
                    <span class="EDC-Heading linkindication" style="float:right !important; font-size: 10px !important" v-if="currentJobItems.length > 0" @click="$router.push('/currentjoblist')">View All
                    <i class="fas fa-angle-right"></i></span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="EDC-DashboardRow">
                  <v-col cols="12" class="EDC-DashboardCol">
                    <v-list dense v-if="currentJobItems.length > 0">
                      <v-list-item small v-for="item in currentJobItems" :key="item.job_id" style="padding:0px !important" @click="$router.push('/currentjoblist')">
                        <v-list-item-content class="EDC-VListItemContent">
                          <v-row no-gutters class="EDC-DashboardRow">
                            <v-col cols="2" class="EDC-DashboardCol"><v-list-item-title style="padding-left:8px !important;">{{item.job_id}}</v-list-item-title>
                            </v-col>

                            <v-col cols="7" class="EDC-DashboardCol">
                              <v-list-item-title>{{item.process_definition_name}}</v-list-item-title>
                            </v-col>

                            <v-col cols="1" class="EDC-DashboardCol" align="left">
                              <v-list-item-title :title="item.mode_of_execution">{{item.display_mode}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-DashboardCol" align="center">
                              <v-list-item-title :title="item.status"><v-chip :color="getColor(item.status)" style="padding: 8px !important;" x-small>{{item.display_status}}</v-chip></v-list-item-title>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <span v-else class="EDC-NoRecordText EDC-ControlPadding">{{ NoDataFound }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <!-- <v-col cols="4" class="EDC-DashboardCol EDC-DashboardColSpacing">
            <v-card class="EDC-VCards scrollbar" id="style-2" elevation="1">
              <v-col cols="12" class="EDC-DashboardCol">
                <v-row no gutter class="EDC-DashboardRow" align="center" style="text-align:left">
                  <v-col cols="9" class="EDC-DashboardCol">
                    <v-badge :content="approvalItems.length" v-if="approvalItems.length > 0" inline bordered>
                      <span class="EDC-Heading">Recent Approvals</span>
                    </v-badge>
                    <span class="EDC-Heading" v-else>Recent Approvals</span>
                  </v-col>
                  <v-col cols="3" class="EDC-DashboardCol">
                    <span class="EDC-Heading" style="float:right !important; font-size: 10px !important" v-if="approvalItems.length > 0">View All
                    <i class="fas fa-angle-right"></i></span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="EDC-DashboardRow">
                  <v-list dense v-if="approvalItems.length > 0">
                    <v-list-item two-lines v-for="item in approvalItems" :key="item" style="padding:0px !important"@click="getRequestHistory(item)">
                      <v-list-item-content class="EDC-VListItemContent">
                        <v-row no-gutters class="EDC-DashboardRow">
                          <v-col cols="1" class="EDC-DashboardCol">
                            <v-list-item-icon class="EDC-Icon"><i class="material-icons EDC-Icon">{{item.icon}}</i></v-list-item-icon>
                          </v-col>

                          <v-col cols="4" class="EDC-DashboardCol">
                            <v-list-item-title><b>{{item.title1}}</b></v-list-item-title>
                          </v-col>

                          <v-col cols="7" class="EDC-DashboardCol">
                            <v-list-item-title>{{item.title2}}</v-list-item-title>
                          </v-col>
                        </v-row>

                        <v-row no-gutters class="EDC-DashboardRow">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <v-list-item-title :title="item.title3">{{item.title4}}</v-list-item-title>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <span v-else class="EDC-NoRecordText EDC-ControlPadding">{{ NoDataFound }}</span>
                </v-row>
              </v-col>
            </v-card>
          </v-col> -->
        </v-row>

        <v-row no-gutters class="EDC-DashboardRow EDC-DashboardRowSpacing">
          <v-col cols="12" class="EDC-DashboardCol EDC-DashboardColSpacing">
            <v-card class="EDC-VCardsBarCharts" elevation="1">
              <v-row no-gutters class="EDC-DashboardRow">
                <span class="EDC-Heading">Size by Table (Top 10)</span>
              </v-row>
              <v-row no-gutters class="EDC-DashboardRow EDC-BarCharts">
                <IEcharts :option="bar_option" :loading="pieloading" @click="onBarClick" @ready="onBarReady"/>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
        
      <v-col cols="4" class="EDC-DashboardCol">
        <v-row no-gutters class="EDC-DashboardRow"> 
          <v-col cols="12" id="style-2" class="EDC-DashboardCol">
            <v-card class="EDC-VCardsTimeline scrollbar" id="style-2" elevation="1">
              <v-row no-gutters class="EDC-DashboardRow">
                <v-col cols="9" class="EDC-DashboardCol">
                  <span class="EDC-Heading">Business Object Activity</span>
                </v-col>
                <v-col cols="3" class="EDC-DashboardCol">
                  <span  class="EDC-Heading linkindication" style="float:right !important; font-size: 10px !important" @click="$router.push('/allactivity')">View All
                    <i class="fas fa-angle-right"></i>
                  </span>
                </v-col>
              </v-row>

              <v-row no-gutters class="EDC-DashboardRow">
                <v-col cols="12" class="EDC-DashboardCol EDC-VColTLHeader"><b>{{timelineYear}}</b></v-col>
              </v-row>

              <v-row no-gutters class="EDC-DashboardRow">
                <v-col cols="12" class="EDC-DashboardCol" style="padding-left:10px !important; padding-right: 10px !important"> 
                  <v-timeline reverse class="EDC-VTimeline">
                    <v-timeline-item v-for="(item, i) in timelines" :key="i" small class="EDC-VTLItem"  fill-dot>
                      <template v-slot:icon>
                        <v-avatar>
                          <svgicon :name="item.icon_name" color="white" :original="true" class="EDC-Icon" :title="item.activity_type"></svgicon>
                          <!-- <v-icon :fill="false" color="white" :title="item.activity_type">phonelink_off</v-icon> -->
                        </v-avatar>
                      </template>
                      <span @click="ClickOnTimeline(item)" slot="opposite" class="spanFont">
                        <v-chip style="margin-top:1px;margin-bottom:1px;" :color="getColor(item.job_status)" small dark>{{item.job_status}}</v-chip>
                      </span>
                      <v-card dark class="EDC-VCardsTL linkindication" elevation="1">
                        <span @click="ClickOnTimeline(item)" class="EDC-VCardsTitle spanFont" :title="item.business_object_name" style="display: flex;text-align:left;"><b>{{getPaddedJobId(item.job_id)}} | {{formattedDate(item.created_date)}}  <br> {{getWrappedItemText(item.business_object_name)}}</b></span>
                        <!-- <v-card-text class="TimelineCardText"></v-card-text> -->
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {BTN_COLOR} from '@/data/macros.js'
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import {N_USER_APPROVAL, GET_ACTIVITY_DETAILS, UPDATE_TASK_DETAILS, CANCEL_REQUEST,GET_DOWNLOAD_FILE,GETTODAYSGJOB,GET_LAST_SCHEDULE_JOBS} from '@/data/url_constants.js';
import {PIECHART_COLOR_CODE} from '../data/piechart_color_codes.js';
import config from '../config.json'
import dateFormat from '@/methods/DateFormat.js'
import IEcharts from 'vue-echarts-v3/src/full.js'
import {getEnvironmentList} from '../methods/EnvironmentList.js'
import _ from 'lodash'
import Confirmation from "../views/dataTable/Confarmation";
import moment from 'moment'
import {No_Data_Found,GRID_STATUS_COLOR_CODE,PIE_SIZE} from '../constants/constants.js'
import {getPieSeries,getPieChart,getBarSeries,generateBarGraph,getSimpleBarSeries} from '../methods/echartgraphs/edcchart.js'

export default {
  name: "Dashboard",
  components: {
      IEcharts,
      'confirmation-panel':Confirmation
    },
  data () {
    return {
      timelineYear:'',
      compliancedata:{},
      total_archives:0,
      total_dispose:0,
      completed_archives:0,
      completed_dispose:0,
      approvalItems: [],
      NoDataFound: No_Data_Found,
      dialog: false,
      loading: false,
      pieloading:false,
      pieoption:{
        series:[]
      },

      bar_option:{
      },
      valid: null,
      EnvironmentList:[],
      Environment:{},
      pie_echart_instance:null,
      pie_echart_graph:null,
      bar_echart_instance:null,
      bar_echart_graph:null,
      value: '100%',
      outlineColor:BTN_COLOR,
      timelines:[],
      BusinessObjectList:[],
      piechartTitle:'Archive by Business Object',
      currentJobItems:[]
    }            
  },

  mounted() {
    this.timelineYear = this.getOnlyYear()
    var env_value = this.$session.get('selected_env')
    if( env_value && env_value.id != '0' && env_value.id !='all_env'){
          this.Environment = this.$session.get('selected_env')
          // this.performDataBinding()
      }
    getEnvironmentList(this);

    var _this = this
    setTimeout(function(){
      if(_this.$session.get('reportdownloadkey')){
      _this.download_reports()
      }
    },2000)
    
  },

  watch:{
    EnvironmentList(newValue){
      this.performAutoEnvSelection(newValue)
    },
    '$store.state.liveStatus':{
      handler(newValue){
        if(newValue)
          this.updateJobStatus(newValue)
      }
    },
  },
  methods:{
    getOnlyYear(date){
      if(!date){
        return moment().format('YYYY')
      }
      return moment(date,this.$session.get('UI_date_format')).format('YYYY')
    },
    download_reports(){
      var report_key = this.$session.get('reportdownloadkey')
      this.$session.set('reportdownloadkey','')
      var data_to_send = {"client_id":this.$session.get("client_id"),"download_key":report_key}
      var url = config.AGENT_API_URL + GET_DOWNLOAD_FILE
      var report_download_url = config.AGENT_API_URL + "/export_report_in_zip/"
      postToServer(this, url, data_to_send).then(response => {
        if(response){
          report_download_url = report_download_url +response.file_name
          window.open(report_download_url, '_blank');
          _this.loader=false;

        }
      }).catch(error_response => {
      if(error_response){
      }
      else {
        }
      });
    },
    
    onCancelRequest(record){
      let _this = this;
      _this.loader=true;
      var client_id = this.$session.get('client_id')
      var data = {'client_id': client_id, 'approval_id': record.id, 'request_desc': record.request_desc, 'object_name': record.object_name}
      var cancel_req_url = config.PROCESS_APPROVAL_URL + CANCEL_REQUEST
      postToServer(this, cancel_req_url, data).then(response => {
        if(response){
          this.snackbar = true
          this.colorValue = 'success'
          this.snackbartext = response;
          this.performDataBinding()
          _this.loader=false;
        }
      }).catch(error_response => {
      if(error_response){
      }
      else {
        }
      });
    },
    performAutoEnvSelection(data){
      if(data.length <0)
        return false
      let env_id = data[0].id
      if(this.$session.get('last_selected_env_id')){
       env_id = this.$session.get('last_selected_env_id')
      }
      var obj = _.find(data,["id",parseInt(env_id)])
      if(!obj)
        return false
      this.Environment = obj
      this.performDataBinding()
    },
    onPieReady(instance,echarts){
      this.pie_echart_instance = instance
      this.pie_echart_graph = echarts
    },
    onPieClick(event,instance,ECharts){
      this.$router.push({ name: 'boactivitygraph',params:{"from_dashboard":true,"event_data":event.data,"color":event.color}})
    },
    onBarReady(instance,echarts){
      this.bar_echart_instance = instance
      this.bar_echart_graph = echarts
    },
    onBarClick(event,instance,ECharts){
      
    },
    performDataBinding(){
      if(!this.Environment.id)
        return false
        
      let _this = this;
      _this.$session.set('selected_env', this.Environment)

      this.getUserApprovalList();
      this.getActivityDetails();
      this.getGraphData();
      this.getComplianceData()
      this.getCurrentJobs()
    },
    getGraphData(){
      this.pie_echart_instance.setOption(this.pieoption, true)
      this.bar_echart_instance.setOption(this.bar_option,true)
      this.piechartTitle = 'Archive by Business Object'
      let _this = this
      this.pieloading = true
      var data = {"env_id":this.Environment.id,"client_id":this.$session.get('client_id'),"graph_type":'size','is_from_dashboard':true}
      postToServer(this, config.JOB_STATISTIC_URL + '/get_dashboard_graphs',data).then(response => {
        this.pieloading = false
        if(response){
          this.BusinessObjectList = response
          var getPieSeriesData = getPieSeries(response.pie_graph,PIE_SIZE)
          _this.piechartTitle = 'Archive by Business Object (In '+response.pie_size_unit+')'
          this.pie_echart_instance.setOption(getPieChart('Business Object',getPieSeriesData.series_with_limit), true)

          var getBarSeriesData = getSimpleBarSeries(_this,response.table_data,getPieSeriesData.series_with_limit)

          this.bar_echart_instance.setOption(generateBarGraph(getBarSeriesData,response.size_unit), true)
        }
      }).catch(CurrentJobError => {
      this.pieloading = false
      
      })
    },
    formattedDate(dateObj){
      // return date without year and as Month,Date for ex. February,9
      return moment(dateObj,this.$session.get('UI_date_format')).format('MMMM DD')
    },

    getActivityDetails(){
      let inputParams={
        "client_id": this.$session.get('client_id'),
        "env_id":this.Environment.id
      }
      let _this = this;
      _this.timelines = []
      postToServer(this, config.ENGINE_API_URL + GET_ACTIVITY_DETAILS, inputParams).then(response => { 
        if(response.length === 0)
          return
        var data = response
        response.map(function(obj){
          obj.job_status = obj.job_status.charAt(0).toUpperCase() + obj.job_status.slice(1)
          if(obj.activity_type.toLowerCase().includes('archival'))
            obj.icon_name = 'archive_v2'
          else if(obj.activity_type.toLowerCase().includes('restore'))
            obj.icon_name = 'archive_restore_v2'
          else if (obj.activity_type.toLowerCase().includes('dispose'))
            obj.icon_name = 'archive_dispose_v2'
          else if(obj.activity_type.toLowerCase() === 'sync up')
            obj.icon_name = 'archive_synchronize_v2'
          else if(obj.activity_type.toLowerCase() === 'unsync up')
            obj.icon_name = 'archive_unsynchronize_v2'
          else if(obj.activity_type.toLowerCase() === 'copy')
            obj.icon_name = 'copy_v2'
          else if(obj.activity_type.toLowerCase() === 'delete copy')
            obj.icon_name = 'delete_v2'
          else
            obj.icon_name = 'job_details_v2'
        })
        this.timelineYear = this.getOnlyYear(response[0].created_date)
        _this.timelines =  response.slice(0, 10);
      })
    },

    getUserApprovalList(){
      let inputParams={
        "username": this.$session.get('email'),
        "env_id": this.Environment.id,
        "total_records": 10
      }
      let _this = this;
      postToServer(_this, config.PROCESS_APPROVAL_URL +N_USER_APPROVAL, inputParams).then(response => {
      console.log("approvals",response);    
      _this.approvalItems = []
      // {icon: 'check_circle', title1:'78 Job Plan', title2:'14:30 | Nov 27, 19', title3:'Initial archiving plan dummy text 1234'}
      _.forEach(response,function(obj){
        obj.title1 = obj.requester_code.toLowerCase()
        obj.title1 = obj.id + ' ' +obj.title1.charAt(0).toUpperCase() + obj.title1.slice(1);
        if(obj.show_ar_btn)
          obj.icon = 'arrow_forward'
        else if(obj.show_can_btn)
          obj.icon= 'arrow_back'
        else if(obj.request_status === 'cancelled')
          obj.icon = 'close'
        else
          obj.icon = "check_circle"

        obj.title3 = obj.request_statement.replace('execute','run')
        obj.title4 = obj.request_statement.replace('execute','run')
        if(obj.title3.length > 40)
          obj.title4 = obj.title3.substring(0,35)+'...'
        var date_without_miliseconds = obj.created_date.split('.')[0]
        var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")
        var timezoned_start_time = moment(moment_obj).tz(_this.$session.get('time_zone_preference')).format('HH:mm')
        var timezoned_formated_date = moment(moment_obj).tz(_this.$session.get('time_zone_preference')).format('MMM DD, YY')
        obj.title2 = timezoned_start_time +' | '+ timezoned_formated_date
        _this.approvalItems.push(obj)
      })

    })
    },

    ClickOnTimeline(record){
      this.$router.push({ name: 'showjobstepsresult', params: {envname:this.Environment.name,jobname: record.business_object_name, 
              jobid: record.job_id,mode:'final',status:record.job_status, type:'dashboard' }});
    },

    getWrappedItemText(text){
      var newtext = text.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
      return newtext
    },

    getRequestHistory(item){
      var show_ar_btn = false
      var show_delete_btn = false
      if(item.workflow_master_status === 'in_progress' && item.show_ar_btn)
        show_ar_btn = true
      if(item.workflow_master_status === 'in_progress' && item.show_cancel_req)
        show_delete_btn = true
      if(item.node_id){
        var node_id = item.node_id
      }else{
        var node_id = item.recent_node_id
      }

      // let routeData = this.$router.resolve({ name: 'approvalHistoryList'});
      // window.open(routeData.href, '_blank');

      // let routeData = this.$router.resolve({ name: 'requesthistory', params: { request_id: item.workflow_master_id}});

      // this.$session.set('dashboardApprovalDetails',{ request_id: item.workflow_master_id, 'workflow_step_master_id': item.workflow_step_master_id,
      // 'approval_step_id': item.approval_step_id, 'request_for': item.requester_desc, current_status:  item.workflow_master_status, object_name: item.object_name, object_id: item.object_id, requester_type: item.requester_type,request_for: item.requester_desc, back_url: '/dashboard','show_approve_reject_button':show_ar_btn, 'show_delete_button':show_delete_btn, request_for: item.requester_desc, 'approval_step_id': item.id})
        
      //   window.open(routeData.href, '_blank');

      this.$router.push({ name: 'requesthistory', params: { node_id: node_id, request_id: item.id, requester_for: item.requester_for, current_status:  item.request_status, object_name: item.object_name, name_to_join: item.other_details.env_name, back_url: '/dashboard','show_approve_reject_button':show_ar_btn, 'show_delete_button':show_delete_btn, request_for: item.requester_desc, object_id: item.object_id,'workflow_master_cloned_id':item.workflow_master_cloned_id}})
    },

    getComplianceData(){
      let inputParams={
        "client_id": this.$session.get('client_id'),
        "env_id": this.Environment.id      
      }
      let _this = this;
      postToServer(_this, config.JOB_STATISTIC_URL+'/get_job_compliance', inputParams).then(response => {
      _this.compliancedata = response
      _this.total_archives = parseInt(response.completed_archives.length)+parseInt(response.pending_archives.length)
      _this.completed_archives = parseInt(response.completed_archives.length)
      _this.total_dispose = parseInt(response.completed_dispose.length)+parseInt(response.pending_dispose.length)
      _this.completed_dispose = parseInt(response.completed_dispose.length)
    })
    },
    showDisposal(){
      var pending_dispose = this.compliancedata.pending_dispose
      if(pending_dispose.length > 0){
        let activity_ids = []
        _.forEach(pending_dispose,function(obj){
          activity_ids.push(obj.activity_id)
        })
        this.$router.push({ name: 'archives', params: {
            'activity_ids': activity_ids, fromdashboard:true
           }});
      }
    },
    getPaddedJobId(job_id){
      return job_id.toString().padStart(4,'0')
    },
    getColor(value){
        return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    },
    getCurrentJobs(){
      var _this = this
      var env_id = this.Environment.id;
      if (!env_id)
        return false
      var client_id = this.$session.get('client_id')
      var data = {"client_id": client_id,
        "env_id": env_id}
      postToServer(_this, config.ENGINE_API_URL + GETTODAYSGJOB, data).then(response => {
        // here we are showing completed terminated from last 12 hours.As per ed suggestion we are not removing latest finished job.
        _.forEach(response,(obj)=>{
          if(obj.status)
            obj.display_status = obj.status.substring(0,1).toUpperCase()
          if(obj.mode_of_execution)
            obj.display_mode = obj.mode_of_execution.substring(0,1).toUpperCase()
            
        })
        _this.currentJobItems = response
        _this.getScheduleJob()
        _this.getWaitPlannedJob()
        _this.getWaitApprovalJob()
      }).catch(CurrentJobError => {
        _this.getScheduleJob()
        _this.getWaitPlannedJob()
        _this.getWaitApprovalJob()
      })
    },
    updateJobStatus(data){
      let obj = _.find(this.currentJobItems,["job_id",data.job_id])
      if(obj && data.status){
        obj.status = data.status
        obj.display_status = obj.status.substring(0,1).toUpperCase()
      }
    },
    getScheduleJob(){
      let _this = this
      var env_id = this.Environment.id;
      var client_id = this.$session.get('client_id');
      if (!env_id)
        return false
      var data = {"client_id": client_id,
        "env_id": env_id,
        "hours":12
      }
      postToServer(_this, config.SCHEDULER_URL + GET_LAST_SCHEDULE_JOBS, data).then(response => {
        _.forEach(response,(response_row)=>{          
          let modified_row = {"job_id":'N/A'}
          modified_row['process_definition_name'] = response_row.params.process_definition_name
          modified_row['process_definition_id'] = response_row.params.process_definition_id
          modified_row['mode_of_execution'] = response_row.params.mode_of_execution
          modified_row["status"] = 'scheduled'
          modified_row["display_status"] = "W"
          if(modified_row['mode_of_execution'])
            modified_row['display_mode'] = modified_row['mode_of_execution'].substring(0,1).toUpperCase()
          _this.currentJobItems.push(_.cloneDeep(modified_row))
        })
      }).catch(CurrentJobError => {

      })
    },
    getWaitPlannedJob(){
      let _this = this
      var env_id = this.Environment.id;
      var client_id = this.$session.get('client_id');
      if (!env_id)
        return false
      var data = {"client_id": client_id,
      "env_id": env_id}
      postToServer(this, config.JOB_PLAN_URL + '/get_all_waiting_instance', data).then(response => {
        _.forEach(response,(response_row)=>{
          var modified_row ={"job_id":'N/A'}
          modified_row['process_definition_name'] = response_row.process_definition_name
          modified_row['process_definition_id'] = response_row.process_definition_id
          modified_row['mode_of_execution'] = response_row['mode_of_execution']
          modified_row["status"] = 'waiting in plan'
          modified_row["display_status"] = "W"
          if(modified_row['mode_of_execution'])
            modified_row['display_mode'] = modified_row['mode_of_execution'].substring(0,1).toUpperCase()
        })
      }).catch(CurrentJobError=>{
        
      })
    },
    getWaitApprovalJob(){
      let _this = this
      var env_id = this.Environment.id;
      var client_id = this.$session.get('client_id');
      if (!env_id)
        return false
      var data = {"client_id": client_id,
      "env_id": env_id}
      postToServer(this, config.COORDINATOR_API_URL + '/get_approval_waiting_jobs', data).then(response => {
        _.forEach(response,(response_row)=>{
          var modified_row ={"job_id":'N/A'}
          modified_row['process_definition_name'] = response_row.request_data.process_definition_name
          modified_row['process_definition_id'] = response_row.request_data.process_definition_id
          modified_row['mode_of_execution'] = 'Final' 
          modified_row["status"] = 'waiting for approval'
          modified_row["display_status"] = "W"
          if(modified_row['mode_of_execution'])
            modified_row['display_mode'] = modified_row['mode_of_execution'].substring(0,1).toUpperCase()

           _this.currentJobItems.push(_.cloneDeep(modified_row))         
        })        
      }).catch(CurrentJobError => {

      })
    }
  }
}
</script>

<style scoped>



.EDC-VCards {
  margin: 0px !important;
  height: 245px !important;
  overflow:none !important;
}

.EDC-PieCharts {
  margin: 0px !important;
  height: 220px !important;
  overflow:none !important;
}

.EDC-VCardsMini {
  margin: 0px !important;
  height: 120px !important;
}

.EDC-VCardsBarCharts {
  margin: 0px !important;
  height: 245px !important;
  width: 100% !important;
  overflow:none !important;
}

.EDC-BarCharts {
  margin: 0px !important;
  height: 220px !important;
  overflow:none !important;
}

.EDC-VCardsTimeline {
  margin: 0px !important;
  height: 500px !important;
}

.EDC-VColTLHeader {
  background: #FFFFFF !important;
  color:var(--v-apptext-base) !important;
  font-size:16px !important;
  height:26px !important;
}

.EDC-VTimeline {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  max-height: screenHeight !important;
}

.EDC-VTLItem {
  font-size: 13px !important;
  color: #A0A0A0 !important;
  icon-color: #4B4B4B !important;
  padding: 0px !important;
}

.EDC-VCardsTL {
  background: white !important;
  border-color: #A0A0A0 !important;
  margin: 0px !important;
  margin-top: 5px !important;
  padding: 5px !important;
}

.EDC-VCardsTitle {
  text-align: left !important;
  line-height: 20px !important;
  color: #A0A0A0 !important;
  margin: 0px !important;
  padding: 0px !important;
}

.EDC-VBtn {
    height: 16px !important;
    width: 16px !important;
    color: white !important;
    background: blue !important;
    margin: 0px !important;
    padding: 0px !important;
}

.EDC-Icon {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    margin: 0px !important;
    padding: 0px !important;
}



.EDC-Badge {
  color: #2D74C0 !important;
}

.EDC-VChip{
  font-size: 18px !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 80px !important;
  width: 80px !important;
}

.vCardsforgrid{
  margin: 0%;
  padding:0%;
  width: 100%;
}
table.v-table tbody td, table.v-table tbody th {
   height: 20px !important; 
}

table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child) {
     padding: 0px !important; 
}


/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  background-color: white;
}

#style-2::-webkit-scrollbar
{
  width: 5px;
  background-color: white;
}

#style-2::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #DCDCDC;
  
}

.scrollbar
{
  background: white;
  overflow-y: scroll !important;
  margin-bottom: 25px;
  overflow-x: hidden !important;
}
.v-sheet {
  border-radius: 5px;
}

.v-table__overflow {
    width: 98% !importent;
}
.v-list{
  padding:0;
}

.v-list-tile{
  background-color: #f2f2f2;
}

#style-2 div[role=listitem]:nth-child(even){
  background: #F8F8F8 !important;
}
.TimelineCardText{
  color:var(--v-apptext-base) !important;
}

input, select{
  width:100%;
  box-sizing:border-box;
}

.EDC-VSelect {
  padding: 0px !important;
  margin: 0px !important;
  border-style: none !important;
}

#EDC-VSelectWidth {
  width : 100% !important;
}

#EDC-VSelectTempWidth {
  display : none !important;
}

.v-list--dense .v-list-item, .v-list-item--dense {
  height:32px !important;
  min-height:32px !important;
}
</style>