import {PIECHART_COLOR_CODE} from '../../data/piechart_color_codes.js';

const other_bo_id = "BO01"
const other_bo_name = "Others"
export function getPieSeries(data,bo_limit=1000){
	var data_series = []
	var bo_list = []
	var series_with_limit = []
	for(var i = 0; i < data.length; i++){
    bo_list.push(data[i].business_object_name)
    data_series.push({"value":data[i].size,
    						"name":data[i].business_object_name,
    						"bo_id":data[i].business_object_id})
  }
  /* Now merge all business objects which index > bo_limit+1 as other object 
		 bo_limit+1 to avoid such case where datalist has only one extra data
  */
  series_with_limit = _.slice(data_series,0,bo_limit+1)
  if(data_series.length > bo_limit+1){
		let other_items = _.slice(data_series,bo_limit)
		let other_obj = {"value":"0.0", "name":other_bo_name, "bo_id":other_bo_id}  	
		let total_sum = 0.0
		_.forEach(other_items,function(obj){
			total_sum = total_sum + parseFloat(obj.value)
		})
		other_obj["value"] = total_sum.toString()
		series_with_limit[bo_limit]=other_obj
  }
  
  return {"series":data_series,"new_data":data,
  "series_with_limit":series_with_limit}
}


export function getPieChart(series_name,series_data){
	return {
    tooltip: {
      trigger: 'item',
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    },
    label: {
            normal: {
                show: true,
                position: 'right'
            },
            emphasis: {
                show: true,
                textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                }
            }
        },
    labelLine: {
            normal: {
                show: true
            }
        },
    series: [
    {
      name:series_name,
      type:'pie',
      avoidLabelOverlap: true,
      center : ['50%', '47%'],
      radius: ['50%', '70%'],
      data:series_data,
      label: {
          normal: {
              show: true,
              position: 'right'
          },
          emphasis: {
              show: true,
              textStyle: {
                  fontSize: '15',
                  fontWeight: 'bold'
              }
          }
      },
      labelLine: {
          normal: {
              show: true
          }
      },
    }],
    color: PIECHART_COLOR_CODE
  }
}

export function getBarSeries(that,data,desinged_pie_series,which_graph="size",bar_limit=8){
	var series_list = []
  var tables_list = []
  for (var i = 0; i < data.length; i++) {
  	var current_row = data[i]
  	if(which_graph === 'count')
    	current_row['key'] = current_row['row_count']
   	else
    	current_row['key'] = current_row['size']
    // now create table stack to show largest table first
    var tbl_obj = _.find(tables_list,["table_name",data[i].table_name])
    if(tbl_obj){
      tbl_obj.total = tbl_obj.total + Number(data[i].key)
      var index = _.findIndex(tables_list,["table_name",data[i].table_name])
      that.$set(tables_list,index,tbl_obj)
    }
    else{
        tables_list.push({"table_name":data[i].table_name,"total":Number(data[i].key)})
      }
  	}
  tables_list = _.reverse(_.sortBy(tables_list,"total"))
    //we need only bar_limit tables
  tables_list = _.slice(tables_list,0,bar_limit)
  for (var i = 0; i < data.length; i++) {
    //current table should present in top barlimit tables
    var tbl_obj = _.find(tables_list,["table_name",data[i].table_name])
    if(!tbl_obj)
      continue
	  var current_row = data[i]
	  var bo_id = data[i].business_object_id
	  var obj = _.find(series_list,['bo_id',bo_id])
	  if(obj){
      obj.table_list.push([data[i].table_name,data[i].key])
      var index = _.findIndex(series_list,['bo_id',bo_id])
      that.$set(series_list,index,obj)
	  }
	  else{
	    series_list.push({'bo_id':bo_id,"table_list":[[data[i].table_name,data[i].key]]})
	  }
  }
  // create series here (Y axis value).
  //first sort series list
  series_list = _.reverse(_.sortBy(series_list,"total"))
  var graph_series = []
  for (var i = 0; i < series_list.length; i++) {
    graph_series.push(getDefaultSeries(series_list[i].table_list,i,series_list[i].bo_id,desinged_pie_series))
  }
  // tables_list = _.reverse(_.sortBy(tables_list,"total"))

  
  // we got table list by 
  var only_tables =_.map(tables_list,"table_name")
  return {"series_data":graph_series,"table_data":only_tables}

}

function getDefaultSeries(data,i,bo_id,desinged_pie_series){
  var itemStyle = {}
  var obj = _.find(desinged_pie_series,['bo_id',bo_id])
  if(!obj)
  	obj = _.find(desinged_pie_series,['bo_id',other_bo_id])
  if(obj){
  	let indexOfobj = _.indexOf(desinged_pie_series,obj)
    itemStyle = {color:PIECHART_COLOR_CODE[indexOfobj]}
    seriesName = obj.name
  }
  var seriesName = "Table Size"+i
  return {
    name:seriesName,
    type:'bar',
    stack:'tables',
    itemStyle:itemStyle,
    data:data,
    label: {
    normal: {
        show: true,
        position: 'inside'
      }
    }
  }
}


export function getSimpleBarSeries(that,data,which_graph="size",bar_limit=10){
	var series_list = []
  var tables_list = []
  for (var i = 0; i < data.length; i++) {
  	var current_row = data[i]
  	if(which_graph === 'count')
    	current_row['key'] = current_row['row_count']
   	else
    	current_row['key'] = current_row['size']
    // now create table stack to show largest table first
    var tbl_obj = _.find(tables_list,["table_name",data[i].table_name])
    if(tbl_obj){
      tbl_obj.total = tbl_obj.total + Number(data[i].key)
      tbl_obj.total = Number(tbl_obj.total.toFixed(2))
      var index = _.findIndex(tables_list,["table_name",data[i].table_name])
      that.$set(tables_list,index,tbl_obj)
    }
    else{
        tables_list.push({"table_name":data[i].table_name,"total":Number(data[i].key)})
      }
  	}
  tables_list = _.reverse(_.sortBy(tables_list,"total"))
  tables_list = _.slice(tables_list,0,bar_limit)
  // we got table list by 
  var only_tables =_.map(tables_list,"table_name")
  var series_data = _.map(tables_list,"total")
  return {"series_data":series_data,"table_data":only_tables}
}


export function generateBarGraph(series_data,unit,bar_limit=10,whichgraph='size'){
  var bar_series = series_data.series_data
  var x_axis_val = series_data.table_data
  var graph_title = "Size by table (Top 10)"
  var yAxis_name = "Size (In MB)"
  if(whichgraph === "count"){
    graph_title = "Record count by table (Top "+bar_limit+")"
    yAxis_name = "Record Count (In "+unit+")"
  }
  else{
    graph_title = "Size by table (Top "+bar_limit+")"
     yAxis_name = "Size (In "+unit+")"
  }  
  return {
      tooltip: {
      show:true,
    },
    toolbox: {
    feature:{
          saveAsImage: {
            'type':'png',
            'name':graph_title,
            'title':'Download',
            emphasis:{
              iconStyle:{
                textAlign:'right'
              }
            }
          }
        }
    },
      xAxis :{
        type :'category',
        name:'Tables',
        nameGap:30,
        data:x_axis_val,
        nameLocation:'end',
        axisTick:{
          alignWithLabel:true,
        },
        axisLabel: {
                  interval: 0,
                  rotate: 10
              },
      },
      yAxis:{
        type: 'value',
        name:yAxis_name,
        nameGap:15,
        nameLocation:'end',
        },
      // series:bar_series -- existing code
      series:{
      	type:'bar',
      	data:bar_series,
        label: {
            show: true
        },
      },
      color: PIECHART_COLOR_CODE,
      grid:{
        left:45,
        right:70,
        bottom:40,
        top:30,
      },
    }
}


function getSizeUnits(data){
	var devide_by = 1024
	var M1 = 1000000
	/*
		Actual size in database is in byte. 

		Logic is 
		if max size <= 1M (1MB) then we will show that in KB and divide by will be 1024
		else convert that maxsize to KB from byte.
		Now 
		if max size <=1M (1GB) then we will show that data in MB and divide by will be 1024 * 1024
		else convert that maxsize to MB from KB
		Now
		if max size <=1M (1TB) then we will show that in GB and divide by 1024*1024*1024
		else
		we will show data in TB and divide by 1024*1024*1024*1024

	*/
	var max_value = _.maxBy(data,'size')
	if(max_value)
		max_value = max_value.size
	if(max_value <= M1)
		return {"unit":'KB',"devide_by":devide_by}
	max_value = max_value/devide_by
	devide_by = devide_by * 1024
	if(max_value <= M1){
		return {"unit":"MB","devide_by":devide_by}
	}
	max_value = max_value/devide_by
	devide_by = devide_by * 1024
	if(max_value <=M1){
		return {"unit":"GB","devide_by":devide_by}
	}
	devide_by = devide_by *1024
	return {"unit":"TB", "devide_by":devide_by}
}

function calculateCountUnit(){

}


