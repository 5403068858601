<template>
	<v-container class="EDC-Container">
		<loading-panel :loader="loader"></loading-panel>

	</v-container>
</template>
<script>
	import config from '../../config.json'
	import {
		get as getToServer,
		post as postToServer
	} from '@/methods/serverCall.js';
	export default {
		name:"shortcutURLRedirection",
		data(){
			return {
				loader:false
			}
		},
		mounted(){
			if(this.$route.query && this.$route.query.isRedirection){
				this.getShortcutUrlParams(this.$route.query.edc_url_id)	
			}
		},
		methods:{
			getShortcutUrlParams(url_id){
				var _this = this
				let dataToPass = {"id":url_id,"client_id":this.$session.get('client_id')}
				_this.loader = true
				postToServer(_this,config.SHORTCUT_API_URL+'/get_short_by_url',dataToPass).then(response=>{
					let record = response.params
					let environment = {"id":record.execution_environment,"name":record.execution_env_name}
	    			if(_this.$route.path === '/manage_job'){
						this.$store.state.createJobParams={ 'process_definition_id': record.process_definition_id, 
			            'process_doc_name': record.process_definition_name, 'activity_id':null,
			            'environment':environment, 'is_restore':false};
			            this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.process_definition_id, 
			                'process_doc_name': record.process_definition_name, 'activity_id':null,
			                'environment':environment, 'is_from_shortcut':true,
			                previousUrl:'/dashboard',
			                execution_env_name:record.execution_env_name,
			                execution_environment:record.execution_environment, id :this.$route
			                .params.id}});	    				
	    			}
	    			_this.loader = false	
	    		
		    	}).catch(exception=>{
		    		_this.loader = false
		    		alert('problem in fetching url details')
		    		this.$router.push('/dashboard')
		    	})
			}
		}
	}
</script>