<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row" style="padding-top:10px !important" align="center">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb" style="padding-left: 14px !important">
                            <li v-if="schedule_id"><router-link to="/scheduledJobList">Schedule Job List</router-link></li>
                            <li v-else><router-link to="" @click.native="CancelSchedule">Create </router-link></li>
                            <li>Schedule {{category}}</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row class="EDC-Row EDC-ChildPage">
        <!-- <v-card class="EDC-Row"> -->
        <!-- <v-container class="EDC-Row"> -->
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="4">
                <v-card class="EDC-CardSideMargin">
                    <v-container class="EDC-Row">
                        <v-row class="EDC-Row">
                            <span class="EDC-Heading">Schedule - {{jobObject.params.name}}</span>
                        </v-row>
                        <v-row class="EDC-Row EDC-ControlPadding">
                            <calender :input="jobObject.params.start_from" @update="setDate" style="float:left;width:100%" :label="date_label" :dense="true" :isMinDate="true"> </calender>
                        </v-row>
                        <v-row class="EDC-Row EDC-ControlPadding">
                            <timer :inputtime= 'text_clock_model' @update="timeChanged" :label="time_label" style="float:left;width:100% !important;"> </timer>
                        </v-row>
                        <v-row class="EDC-Row EDC-ControlPadding">
                            <v-col cols="12" class="EDC-Col">
                                <v-autocomplete clearable v-model="jobObject.params.timezone" label="Time Zone" :items="timeZoneItems" dense style="float:left;width:100%"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row EDC-ControlPadding">
                           <v-col cols="4" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left" >Execution Type</label>
                        </v-col>

                        <v-col cols="8" class="EDC-Col">
                            <v-radio-group v-model="graph_type" row>
                                <v-radio label="Total Time">
                                    
                                </v-radio>
                                <v-radio label="Total Count">
                                    
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
        <v-col class="EDC-Col" cols="4">
            <v-card class="EDC-CardSideMargin" v-if="jobObject.params.is_recurring">
                <v-container class="EDC-Row">
                    <v-row class="EDC-Row">
                        <span class="EDC-Heading">Recurrance Pattern</span>
                    </v-row>
                    <v-row class="EDC-Row EDC-ControlPadding">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Frequency</label>
                        </v-col>

                        <v-col cols="9" class="EDC-Col">
                            <v-radio-group v-model="jobObject.params.recurring.recurrence_pattern.recurrence_pattern_type" row>
                                <v-radio class="radioClass" :color="colorCode" label="Weekly" value="weekly"></v-radio>
                                <v-radio class="radioClass" :color="colorCode" label="Monthly" value="monthly"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row EDC-ControlPadding" v-if="jobObject.params.recurring.recurrence_pattern.recurrence_pattern_type == 'weekly'">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Which Day</label>
                        </v-col>
                        <v-col cols="9" class="EDC-Col">
                            <v-row class="EDC-Row">
                                <v-checkbox label="Select All" v-model = "weeklySelectAll" @change="toggleSelect" 
                                :color="colorCode" class="customCheckbox">
                            </v-checkbox>
                        </v-row>
                        <v-row class="EDC-Row">
                            <v-col cols="3" class="EDC-Col EDC-ChipColMargin" v-for="(day, index) in dayOfWeeks" :key="index" align="left">
                                <v-chip small :color="colorCode" class="pointer" text-align="center"  text-color="white" @click="day.value = !day.value"  v-if="day.value">{{day.text}}</v-chip>
                                <v-chip small text-align="center" class="pointer" @click="day.value = !day.value" outline v-if="!day.value">{{day.text}}</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-container class="EDC-Row" v-if="jobObject.params.recurring.recurrence_pattern.recurrence_pattern_type == 'monthly'">
                    <v-row class="EDC-Row EDC-ControlPadding">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Which Month</label>
                        </v-col>
                        <v-col cols="9" class="EDC-Col">
                            <v-row class="EDC-Row">
                                <v-checkbox label="Select All" v-model="monthlySelectAll" @change="toggleMonthlySelect" :color="colorCode" class="customCheckbox radioClass">
                                </v-checkbox>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col cols="3" class="EDC-Col EDC-ChipColMargin" v-for="(month, index) in dayOfMonthArray" :key="index" align="left">
                                    <v-chip small :color="colorCode" class="pointer" text-color="white" @click="month.value = !month.value"  v-if="month.value">{{month.text}}</v-chip>
                                    <v-chip small class="pointer" @click="month.value = !month.value" outline v-if="!month.value">{{month.text}}</v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="EDC-Row EDC-ControlPadding">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Monthly Type</label>
                        </v-col>

                        <v-col cols="9" class="EDC-Col">
                            <v-radio-group v-model="which_monthly_type" row>
                                <v-radio class="radioClass" :color="colorCode" label="Date of Month" value="date_of_month"></v-radio>
                                <v-radio class="radioClass" :color="colorCode" label="Day of Month" value="day_of_month"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row EDC-ControlPadding" v-if="which_monthly_type === 'date_of_month'">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Which Date</label>
                        </v-col>
                        <v-col cols="8" class="EDC-Col">
                            <v-select :items="day_array" v-model="jobObject.params.recurring.recurrence_pattern.date_of_month" label="Select Date" dense></v-select>
                        </v-col>
                    </v-row>

                    <v-row class="EDC-Row EDC-ControlPadding" v-if="which_monthly_type === 'day_of_month'">
                        <v-col cols="3" class="EDC-Col EDC-RadioCheckboxLabelPadding">
                            <label class="v-label" style="float:left">Which Day</label>
                        </v-col>
                        <v-col cols="9" class="EDC-Col">
                            <v-row class="EDC-Row">
                                <v-col cols="5" class="EDC-Col">
                                    <v-select v-model="jobObject.params.recurring.recurrence_pattern.which_day_of_week" :items="day_of_week_array" label="Select Week" dense></v-select>
                                </v-col>
                                <v-col cols="5" class="EDC-Col" style="margin-left:10px !important;">
                                    <v-select :items="week_array" v-model="jobObject.params.recurring.recurrence_pattern.selected_week_day" 
                                    label="Select Day" dense></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-card>
    </v-col>
    <v-col class="EDC-Col" cols="4">
        <v-card class="EDC-CardSideMargin" v-if="jobObject.params.is_recurring">
            <v-container class="EDC-Row">
                <v-row class="EDC-Row">
                    <span class="EDC-Heading">Range of Recurrence</span>
                </v-row>
                <v-row class="EDC-Row EDC-ControlPadding">
                    <v-checkbox label="No End Date" v-model="jobObject.params.recurring.range_of_recurrence.end_on" 
                    :color="colorCode" class="customCheckbox radioClass" @change="toggleUntillDate"> 
                </v-checkbox>
            </v-row>
            <v-row class="EDC-Row EDC-ControlPadding">
                <v-text-field v-model="jobObject.params.recurring.range_of_recurrence.end_after_times" label="End after iterations" dense> </v-text-field>
            </v-row>
            <v-row class="EDC-Row EDC-ControlPadding">
                <calender :input="jobObject.params.recurring.range_of_recurrence.until_date" @update="setUntillDate" :dense="true" label="Run Until (YYYY-MM-DD)" :isMinDate="true"> </calender>
            </v-row>
            <v-row class="EDC-Row EDC-ControlPadding">
                <br/>
            </v-row>
        </v-container>
    </v-card>
</v-col>
</v-row>
<!-- </v-container> -->
<!-- </v-card> -->
</v-row>



</div>
</template>
<script>
import config from "../../config.json"
import Calender from '../../views/calender'
import Timer from "../../views/timecontrol"
import Breadcrumb from "../Breadcrumbs.vue"
import cloneDeep from 'lodash/cloneDeep'
import {
    get as getToServer,
    post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../data/client_message.js'
import {
    GET_SCHEDULE,
    SCHEDULE_JOB,
    UPDATE_JOB,
    SCHEDULE_COORDINATION,
    SCHEDULE_JOB_PLAN
} from '../../data/url_constants.js';
import {
    DAY_OF_WEEKS,
    DAY_OF_MONTH_ARRAY,
    WEEK_ARRAY,
    DAY_ARRAY,
    DAY_OF_WEEK_ARRAY,
    JOB_OBJECT
} from '../../data/metaData.js'
import filter from 'lodash/filter'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import loadingPanel from '@/views/loading.vue'
import vcButton from '@/views/button.vue'

var moment = require("moment-timezone");
export default {
    name: 'Schedule',
    components: {
        'calender': Calender,
        'loading-panel':loadingPanel,
        'timer':Timer,
        'vc-button':vcButton,
        Breadcrumb
    },
    data() {
        return {
            category:'Job',
            schedule_id:'',
            disabled: 0,
            snackbar:false,
            snackbartext:'',
            snackbartimeout:5000,
            colorValue:'error',
            loader:false,
            loading: false,
            outlineColor:BTN_COLOR,
            colorCode:COLOR_CODE,
            type:'new',
            weeklySelectAll: true,
            monthlySelectAll: true,
            dayOfWeeks: cloneDeep(DAY_OF_WEEKS),
            dayOfMonthArray: cloneDeep(DAY_OF_MONTH_ARRAY),
            schedule_name: "",
            start_date: "",
            start_time: "",
            timezone: "",
            dayOfMonth: "Date of Month",
            time: null,
            menu2: false,
            modal2: false,
            timeZoneItems: moment.tz.names(),
            WeeklyArray: [],
            monthArray: [],
            row: 'One Time',
            recurrence: 'Weekly',
            week_array: WEEK_ARRAY,
            day_array: DAY_ARRAY,
            day_of_week_array: DAY_OF_WEEK_ARRAY,
            jobObject: cloneDeep(JOB_OBJECT),
            is_one_time_schedule:false,
            iterationRules: [v => /^((\d+(\.\d *)?)|((\d*\.)?\d+))$/.test(v) || 'End after iterations should be numeric'],
            text_clock_model:'',
            dropdown_clock_model:'',
            open_clocklist:false,
            date_label:'Schedule Date (YYYY-MM-DD)',
            time_label:'Schedule Time (HH:MM)',
            which_monthly_type:"date_of_month", // this variable is used for manage montly radio button. Currently its manage by True false value which is wrong. Because you cannot set boolean value to radio button as its get changed based on selection and radio group behaves randomly.DONT USE RADIO VALUES AS BOOLEAN,
            schedule_type: "one_time", // this variable used to identify recurring or one time job.Same mistake done for radio button as explained for 'which_monthly_type' variable
        }
    },
    watch: {
        'jobObject.params.recurring.range_of_recurrence.until_date'(newvalue, o) {
            if (newvalue)
                this.jobObject.params.recurring.range_of_recurrence.end_on = false;
        },
        'jobObject.params.recurring.range_of_recurrence.end_after_times'(newvalue, o) {
            if (newvalue)
                this.jobObject.params.recurring.range_of_recurrence.end_on = false;
        },
        dayOfMonthArray: {
            handler: function (val, o) {
                let selectedMonth = filter(val, 'value');
                this.monthlySelectAll = selectedMonth.length == val.length ? true : false;
            },
            deep: true
        },
        dayOfWeeks: {
            handler: function (val, oldVal) {
                let selectedWeek = filter(val, 'value');
                this.weeklySelectAll = selectedWeek.length == val.length ? true : false;
            },
            deep: true
        },
        schedule_type:{
            handler:function(val){

            }
        }
    },
    mounted() {
        let _this = this;
            // debugger;
        if (this.$route.params.service_id && this.$route.params.end_point) {
            _this.jobObject.params.service_id = this.$route.params.service_id;
            _this.jobObject.params.end_point = this.$route.params.end_point;
            _this.jobObject.params.category = this.$route.params.category;
            this.category = this.$route.params.category;
            debugger
            if(this.$route.params.schedule_data.job_plan_name){
                _this.jobObject.params.name = this.$route.params.schedule_data.job_plan_name
            }else{
                _this.jobObject.params.name = this.$route.params.schedule_data.process_definition_name
            }
            _this.jobObject.params = Object.assign(_this.jobObject.params, this.$route.params.schedule_data)
            _this.jobObject.params.recurring.range_of_recurrence.end_on = true
            console.log(_this.jobObject.params)
        }
        if (this.$route.params.schedule_id) {
            this.schedule_id = this.$route.params.schedule_id
            _this.jobObject.params.category = this.$route.params.category;
            this.category = this.$route.params.category;
            this.getSchedule()
        }
        else{
            this.jobObject.params.timezone = this.$session.get('time_zone_preference')
        }
        if(this.$route.params.type && this.$route.params.type == 'history' && this.$route.params.job){
        this.type = 'history';
        this.jobObject = this.$route.params.job;
        this.Time24to12(this.jobObject.params.start_time)
        // this.jobObject.params.start_time = this.jobObject.params.start_time.HH + ':' + this.jobObject.params.start_time.mm;
        this.jobObject.schedule_id = this.jobObject.id;
        this.jobObject.params.is_recurring = false;
      }
      if(this.$route.params.schedule_data)
        this.is_one_time_schedule =  this.$route.params.schedule_data.is_one_time_schedule
    },
    props: {
        msg: String
    },
    methods: {
        changeScheduleType(val){
            this.jobObject.params.is_recurring = false
                if(val === "recurring")
                    this.jobObject.params.is_recurring = true
        },
        toggleUntillDate(val) {
            if (val) {
                this.jobObject.params.recurring.range_of_recurrence.until_date = null
                this.jobObject.params.recurring.range_of_recurrence.end_after_times = null
            }
        },
        toggleMonthlySelect(val) {
            this.dayOfMonthArray.map(obj => {
                obj.value = val ? true : false;
            })
        },
        toggleSelect(val) {
            this.dayOfWeeks.map(obj => {
                obj.value = val ? true : false;
            });
        },
        saveData() {
            let _this = this;
            _this.loader = true
            var clientId = this.$session.get('client_id')
            var user_id = this.$session.get('email')

            this.jobObject.params.client_id = clientId;
            this.jobObject.params.created_by = user_id;
            let inputParam = _this.getSaveObject(this.jobObject);
            let url = "";
            if (this.$route.params.schedule_id && this.$route.params.type && this.$route.params.type == 'edit') {
                inputParam.id = this.$route.params.schedule_id;
                url = config.SCHEDULER_URL + UPDATE_JOB;
            } else {
                if(this.category === 'Job')
                url = config.COORDINATOR_API_URL + SCHEDULE_COORDINATION;
                if(this.category === 'Job Plan')
                url = config.JOB_PLAN_URL + SCHEDULE_JOB_PLAN;
            }
            postToServer(this, url, inputParam).then(
                    addResponse  => {
                        setTimeout(function () {
                            _this.$router.push('/scheduledJobList');
                        }, 1000);
                        _this.loader = false
                    }).catch(error_response => {
                _this.loader=false;
                if(error_response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
                });

            // postToServer(this, url, inputParam).then(response => {
            //     debugger
            //     // this.$toasted.success(response.message);
            //     this.colorValue = 'success'
            //     this.snackbartext = response.message;
            //     this.snackbar = true
            //     setTimeout(function () {
            //         _this.$router.push('/scheduledJobList');
            //     }, 2000);
            // }, error => {
            //     if (error){
            //     this.colorValue = 'error'
            //     this.snackbartext = error;
            //     this.snackbar = true
            //     }
            //         // this.$toasted.error(error);
            // }).catch(error_response => {

            // })
        },
        getSaveObject(Object) {

            let tempObject = cloneDeep(Object);
            let _this = this;
            tempObject.params.is_recurring =false
            if(_this.schedule_type === "recurring")
                tempObject.params.is_recurring = true;
            tempObject.params.recurring.recurrence_pattern.is_day_of_month =true
            if(_this.which_monthly_type === "date_of_month"){
                tempObject.params.recurring.recurrence_pattern.is_day_of_month = false
                tempObject.params.recurring.recurrence_pattern.which_day_of_week = ""
                tempObject.params.recurring.recurrence_pattern.selected_week_day = ""
            }
            else{
                tempObject.params.recurring.recurrence_pattern.date_of_month = ""
            }
            if (tempObject.params.is_recurring) {
                if (tempObject.params.recurring.recurrence_pattern.recurrence_pattern_type == 'weekly') {
                    // The `_.property` iteratee shorthand. tempObject.params.recurring.recurrence_pattern.month_array
                    let selectedWeek = filter(_this.dayOfWeeks, 'value');
                    tempObject.params.recurring.recurrence_pattern.week_array = [];
                    selectedWeek.map((obj, index) => {
                        tempObject.params.recurring.recurrence_pattern.week_array.push(obj.text);
                    });
                } else {
                    let selectedMonth = filter(_this.dayOfMonthArray, 'value');
                    tempObject.params.recurring.recurrence_pattern.month_array = [];
                    selectedMonth.map((obj, index) => {
                        tempObject.params.recurring.recurrence_pattern.month_array.push(obj.text);
                    });
                }
            }
            // // old clock logic
            // if (tempObject.params.start_time) {
            //     let time = tempObject.params.start_time.split(":");
                // tempObject.params.start_time = {
                //     'HH': time[0],
                //     'mm': time[1]
                // }
            // }

            // new clock logic
            if(this.text_clock_model){
                if(this.text_clock_model.length != 5)
                    return false
                // var split_by_space = this.text_clock_model.split(' ')
                var split_by_colon = this.text_clock_model.split(':')
                var hours = split_by_colon[0] 
                var minute = split_by_colon[1] 
                // if(split_by_space[1] === "PM"){
                //     if(parseInt(hours) != 12)
                //     hours = parseInt(hours) + 12
                // } else {
                //     if(parseInt(hours) == 12)
                //         hours = '00'
                // }
                tempObject.params.start_time = {
                    'HH': hours,
                    'mm': minute
                }
            }

            return tempObject;
        },
        setDate(dateParam) {
            let _this = this;
            _this.jobObject.params.start_from = dateParam;
        },
        setUntillDate(dateParam) {
            let _this = this;
            _this.jobObject.params.recurring.range_of_recurrence.until_date = dateParam;
            _this.jobObject.params.recurring.range_of_recurrence.end_on = false;
        },
        getSchedule() {
            var _this = this
            getToServer(this, config.SCHEDULER_URL + GET_SCHEDULE + this.$route.params.schedule_id).then(response => {
                this.jobObject = response;

                this.Time24to12(response.params.start_time)

                // this.jobObject.params.is_recurring = JSON.parse(this.jobObject.params.is_recurring);
                // this.jobObject.params.recurring.recurrence_pattern.is_day_of_month = JSON.parse(this.jobObject.params.recurring.recurrence_pattern.is_day_of_month);
                _this.which_monthly_type = "date_of_month"
                
                if(this.jobObject.params.recurring.recurrence_pattern.is_day_of_month)
                    _this.which_monthly_type = "day_of_month"

                _this.schedule_type = "one_time"
                if (this.jobObject.params.is_recurring) {
                    _this.schedule_type = "recurring"
                if (this.jobObject.params.recurring.recurrence_pattern.recurrence_pattern_type == 'weekly') {
                    _this.dayOfWeeks.map(function(obj){
                        obj.value = false
                        if(response.params.recurring.recurrence_pattern.week_array.indexOf(obj.text) >=0)
                            obj.value = true
                    })
                } else {
                    _this.dayOfMonthArray.map(function(obj){
                        obj.value = false
                        if(response.params.recurring.recurrence_pattern.month_array.indexOf(obj.text) >=0)
                            obj.value = true
                    })
                }
            }
                
            }).catch(error_response => {

            })
        },
        Time24to12(time){
            var hours = time.HH
            var minute = time.mm
            // var AM_PM = "AM"
            // if(parseInt(hours) >= 12) 
            // {
            //     AM_PM = "PM"
            //     if(parseInt(hours) != 12)
            //     hours = parseInt(hours) - 12
            //     if(hours < 10)
            //         hours = "0"+hours
            // } else {
            // if(parseInt(hours) === 0)
            //     hours = 12
            // }
            this.text_clock_model = hours+":"+minute
        },
        CancelSchedule(){
            // this.$router.push('/process_def_list_for_execution')

            // if(this.$route.params.schedule_data.job_plan_name){
            //     this.$router.push('/jobplanlist')
            // }
            // else{

            // this.$router.go(-1);
            // }
            this.$emit('closeScheduler')
        },
        timeChanged(changedtime){
            this.text_clock_model = changedtime
        },
    },

}
</script>

<style scoped>
 .radioClass >>> label{
    top: 5px !important;
} 

.customCheckbox >>> label{
    top: 5px !important;
}


/* .customCheckbox >>> i{
    top: 5px !important;
} */
.pointer{
    cursor: pointer;
}
.pointer >>> span{
    cursor: pointer;
}
.custWidth{
    width: 55px;
}
.customCheckbox >>> label{
    top: 5px !important;
}
.v-input--selection-controls{
    margin-top:5px !important;
    padding-top: 0px !important; 
}

.v-divider--vertical {
    min-height:10%;
}
.theme--light.v-divider {
    border-color: rgba(0,0,0,.50);
}

.EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
}

.EDC-VCardTitle {
    background: #494949 !important;
    color: white !important;
    text-align: left !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0px !important;
    height: 30px !important;
}

.EDC-ChipColMargin{
    margin-bottom:5px !important;
}

</style>

