<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col" style="padding-left:5px !important">
        <v-row class="EDC-Row" style="padding-top:10px !important">
          <v-col cols="12" class="EDC-Col" style="padding-left:5px !important; padding-right:5px !important">
            <ul class="breadcrumb">
              <li>Publish Request</li>
              <li>{{ this.$route.params.publish_id }}</li>
              <li>
                <router-link to="/processDefinitionList">Object list</router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <!-- <v-card-text>
      <v-row class="EDC-Row" style="float:left">
        <span>Requested By: {{ request_details }}</span>
      </v-row>
    </v-card-text> -->

    <edc-data-grid :dataList="tableList" @onStatus="onStatus" @onDownload="onDownload" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>
    
    <loading-panel :loader="loader"></loading-panel> 

    <edc-snackbar :snackbar="snackbar" :snackbartext="snackbartext" :colorValue="colorValue"></edc-snackbar>

  </div>
</template>
<script>
import Breadcrumb from "../Breadcrumbs.vue";
import config from '../../config.json';
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js';
import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
import _ from 'lodash';
export default {
  name: 'Schedule',

  props: {
    msg: String
  },
    data() {
      return {
        loader:false,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        dialog:false,
        e1: 400,
        search: '',
        valid: false,
        Environment: {},
        EnvironmentList: [],
        selected: [],
        snackbar:false,
        snackbartext:'',
        snackbartimeout:4000,
        colorValue:'error',
        tableList: {
                headers: [
                { text: 'Object', value: 'object_name', sortable: false, width: '200px', options:[],cssClass:"w16", title: 'Object Name'},
                { text: 'Object Type', value: 'object_type', sortable: false, width: '100px', options:[], title: 'Object Type'},
                { text: 'Object Ver.', value: 'object_version', sortable: false, width: '100px', options:[], title: 'Object Version'},
                { text: 'Status', value: 'action', sortable: false, width: '100px', options:[], title: 'Status'},
                { text: 'Requested By', value: 'action_taken_by', sortable: false, cssClass:"w16", options:[], title: 'Requested By'},
                { text: 'Requested Date', value: 'timezone_aware_action_date', sortable: false, cssClass:"w16", options:[], title: 'Publish Request date time'},
                { text: 'Requested Time', value: 'timezone_aware_action_time', sortable: false, cssClass:"w16", options:[], title: 'Publish Request date time'},
                { text: 'Remark', value: 'reason', sortable: false, width: '100px', options:[], cssClass:"w16", title: 'Reason for skipping object to publish'},
                { text: 'Object ID', value: 'object_id', width: '200px', options:[],cssClass:"w16", title: 'Object Id', sortable: false, hideInlineFilter: true},
                { text: 'Source Env', value: 'source_name', sortable: false, width: '200px', options:[],cssClass:"w16", title: 'Source Environment'},
                { text: 'Source Host IP', value: 'source_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host IP'},
                { text: 'Source Host Name', value: 'source_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host Name'},
                { text: 'Target Env', value: 'target_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Environment'},
                { text: 'Target Host IP', value: 'target_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host IP'},
                { text: 'Target Host Name', value: 'target_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host Name'},
                ],
                actions:[],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                hideRowInformation:true,
                hidePerPageArray:true,
                hidePagination:true,
                hideDownload:true
        },
        currentStage:{},
        request_details: {}
      }
    },
  mounted () {
      this.getPublishRequestDetails()
    },
  methods: {
    onSelectedRows(selectedRows){
          this.selected = selectedRows;
    },
    showRule(obj){
      this.currentStage = _.cloneDeep(obj);
      this.currentStage.approver_list = this.currentStage.approver_list ? this.currentStage.approver_list.join(" "):this.currentStage.approver_list;
      this.dialog=true;
    },
    getPublishRequestDetails() {
      debugger
      var req_data = {'publish_id': this.$route.params.publish_id, 
      "client_id": this.$route.params.client_id}
      postToServer(this, config.PUBLISHER_URL + '/publish_request_details', req_data).then(
        response => {
          if(response){
            debugger
          this.request_details = response.result
          this.tableList.rows = response.result
          this.snackbar = true
          this.colorValue = 'success'
          this.snackbartext =response.message;
          } else {
             this.tableList.rows = [];
             this.errorMsg = response
          }
        },response => {
          this.ShowErrorMessage=true
          this.ErrorMessage=response
        }).catch(EnvironmentError => {
          if(EnvironmentError){
               this[l]  = false
               this.loader = null 
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = EnvironmentError;
              }
              else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
              }
        })
      },
    }
}
</script>
<style scoped>
 .radioClass >>> label{
    top: 5px !important;
} 
.radioClass >>> i{
    font-size: 30px
}
.customCheckbox >>> label{
    top: 5px !important;
}
/* .customCheckbox >>> i{
    top: 5px !important;
} */
.pointer{
    cursor: pointer;
}
.pointer >>> span{
    cursor: pointer;
}
.custWidth{
    width: 55px;
}
.customCheckbox >>> label{
    top: 5px !important;
}
</style>

