<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid key="grid-B" :dataList="waitingJobList" @onDelete="deleteWaitingJob" 
                        @onEnquiry="onWaitingEnquiry" @linkCellClicked="linkCellClicked" @onViewPlan="moveToJobPlan"></edc-data-grid>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-dialog v-model="gridDialog" width="900" scrollable style="overflow-y:none;height:200px !important" persistent>
                            <v-col class="EDC-Col">
                            <v-card class="rounded-card" style="height:100%">
                                <v-toolbar dark dense>
                                <v-col class="EDC-Col text-md-center">Approval History
                                    <v-icon class="text-lg-left" @click="gridDialog = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                                </v-col>
                                </v-toolbar>
                                <edc-data-grid key="enqury" :dataList="enquireList"></edc-data-grid>
                            </v-card>
                            </v-col>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import config from './../../../config.json'
    import { post as postToServer } from './../../../methods/serverCall.js';
    import {GET_LAST_SCHEDULE_JOBS,DELETE_JOB_PLAN_OBJECTS} from './../../../data/url_constants.js';
    import getUserRole from '../../../methods/GetUserRole.js'
    import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
    import {addDependency} from '../../../methods/commonFunc.js'

    export default{
        name:'WaitingJobs',
        mixins:[mixinJobManagement],
        props:{
            changedEnvironment:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            newScheduleJobActivited:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                gridDialog:false,
                waitingJobHeaders: [
                    { text: 'Process Doc Name', value: 'process_definition_name', width:"360px", title: 'Process Doc Name',  showHtml:true},
                    { text: 'Status', value: 'status',  title: 'Job Status'},
                    { text: 'Mode', value: 'mode_of_execution',  title: 'Mode of Execution'},
                    { text: 'Exe Type', value: 'exe_type', title:'Execution Type', title:'Execution Type' },
                    { text: 'Job Plan Name', value: 'plan_name',  title: 'Associated Job Plan Name', isLinkCell:true,
                        "linkEvaluationObj":{
                        "cmpheader":"plan_name",
                        "isRequiredValue":true
                        }
                    },
                    { text: 'Policy Date', value: 'policy_details',  title: 'Policy Dates', dataType:'date'},
                    { text: 'Depedended On', value: 'parent_job',  title: 'Depedended On To Finish', dataType:'date'},
                    { text: 'Start date', value: 'start_date',  title: 'Job Start Date', dataType:'date'},
                    { text: 'Start time', value: 'start_time',  title: 'Job Start Time', dataType:'time',hideInlineFilter:true},
                    { text: 'Object ID', value: 'process_definition_id', options:[], title: 'Object ID', align:'right'}, 
                ], 
                waitingJobList: {
                    headers: [], 
                    actions: [],//if we need conditional action in row then provide key
                    rows:[]
                },
                enquireHeaders:[
                    { text: 'Number', value: 'seq_num', width:"20%", disabled:true, dataType:'int'},
                    { text: 'Action', value: 'action', width:"20%", disabled:true },
                    { text: 'Action By', value: 'action_by', width:"20%", disabled:true},
                    { text: 'Remark', value: 'remark',width:"20%",},
                    { text: 'Action Date', value: 'timezone_aware_action_date',width:"10%", dataType:'date'},
                    { text: 'Action Time', value: 'timezone_aware_action_time',width:"10%", dataType:'time', hideInlineFilter:true},
                ],
                enquireList: {
                    headers: [], 
                    actions: [],
                    rows:[]                 
                },
            }
        },
        computed: {

        },
        watch:{
            '$store.state.currentWaitingJobToRunning':{
                handler(newValue){
                this.removeFromWaitingGrid(newValue)
                }
            },
            '$store.state.currentWaitingJobToTerminate':{
                handler(newValue){
                this.removeAllWaitingGrid(newValue)
                }
            },
            'waitingJobList.rows':{
                handler(newValue){
                    this.$emit("onWaitingJobCountUpdated", this.waitingJobList.rows.length)
                },
                deep:true
            },
            'changedEnvironment':{
                handler(newValue){
                    this.Environment = _.cloneDeep(newValue)
                    this.getAllWaitingJobList()
                }
            },
            'newScheduleJobActivited':{
                handler(newValue){
                    this.removeFromWaitingGrid(newValue, 'schedule')
                }
            }
        },
        mounted(){
            this.waitingJobList = this.getGridObj(this.waitingJobHeaders,'id',true,{showExport:false})
            this.enquireList =  this.getGridObj(this.enquireHeaders,'id',true,{showExport:false,showInlineFilter:false})
            this.waitingJobList.actions =[
                {'text':'publish','key':"job_plan_id", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),
                index:1,'actionTitle':"Go To Job Plan",'eventName':"onViewPlan"},
                {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION), index:2},
                {'text':'enquiry','key':"type", cmpValue:'approval', selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION), index:3},
                
            ]
        },
        methods:{
            getAllWaitingJobList(){
                this.waitingJobList.rows = []
                this.getScheduleJob()
                this.getWaitApprovalJob()
                this.getWaitPlannedJob()
            },
            getScheduleJob(){
                let _this = this
                if (!this.isEnvironmentSelected)
                    return false
                var data = _.cloneDeep(this.commonRequestData)
                data['hours'] = 12
                this.$session.set('selected_env', this.Environment)
                postToServer(this, config.SCHEDULER_URL + GET_LAST_SCHEDULE_JOBS, data).then(response => {
                    if(response){
                        for (var i = 0; i < response.length; i++) {
                            var response_row = response[i]
                            var modified_row ={}
                            modified_row['process_definition_name'] = response_row.params.process_definition_name
                            modified_row['process_definition_id'] = response_row.params.process_definition_id
                            modified_row['execution_environment'] ={"id":response_row.params.execution_environment,"env_name":response_row.params.execution_environment_name}
                            modified_row['mode_of_execution'] = response_row.params.mode_of_execution
                            modified_row['id'] = response_row.id
                            modified_row['start_date'] = response_row.next_run_time.split(" ")[0]
                            modified_row['start_time'] = response_row.next_run_time.split(" ")[1]
                            modified_row['exe_type'] ='On Schedule'
                            modified_row['status'] ='Scheduled'
                            if(response_row.type == "job_plan"){
                            modified_row['type'] = response_row.type
                            modified_row['job_plan_instance_status'] = response_row.params.job_plan_instance_status
                            modified_row['job_plan_id'] = response_row.params.job_plan_id
                            modified_row['job_plan_instance_id'] = response_row.params.job_plan_instance_id
                            modified_row['scheduler_id'] = response_row.scheduler_id
                            modified_row['parent_job'] = response_row.params.parent_job
                            }else{
                            modified_row['type'] ='Scheduled'
                            }
                            modified_row['policy_details']= ''
                            if(response_row.params.policy_details){
                            modified_row['policy_details'] = _this.createPolicyDetails(response_row.params.policy_details)
                            }
                            if(response_row.type === 'job_plan'){
                            modified_row['process_definition_name'] = addDependency(response_row.params.process_definition_name,response_row.params.instance_details_indentation)
                            modified_row['plan_name'] = response_row.params.job_plan_name
                            
                            }
                            _this.waitingJobList.rows.push(modified_row)
                        }
                    }
                }).catch(CurrentJobError => {
                    
                })
            },
            getWaitApprovalJob(){
                let _this = this
                if (!this.isEnvironmentSelected)
                    return false
                var data = _.cloneDeep(this.commonRequestData)
                postToServer(this, config.COORDINATOR_API_URL + '/get_approval_waiting_jobs', data).then(response => {
                    if(response){
                        for (var i = 0; i < response.length; i++) {
                            var response_row = response[i]
                            var modified_row ={}
                            modified_row['process_definition_name'] = response_row.request_data.process_definition_name
                            modified_row['process_definition_id'] = response_row.request_data.process_definition_id
                            modified_row['execution_environment'] ={"id":response_row.environment_id,"env_name":response_row.environment_name}
                            modified_row['mode_of_execution'] = 'Final'
                            modified_row['start_date'] = ''
                            modified_row['start_time'] = ''
                            modified_row['exe_type'] ='On Demand'
                            modified_row['type'] ='approval'
                            modified_row['status'] =response_row.status
                            modified_row["id"] = response_row.id
                            _this.waitingJobList.rows.push(modified_row)
                        }
                    }
                }).catch(CurrentJobError => {
                    
                })
            },
            getWaitPlannedJob(){
                let _this = this
                if (!this.isEnvironmentSelected)
                    return false
                var data = _.cloneDeep(this.commonRequestData)
                postToServer(this, config.JOB_PLAN_URL + '/get_all_waiting_instance', data).then(response => {
                    if(response){
                        for (var i = 0; i < response.length; i++) {
                            var response_row = response[i]
                            var modified_row ={}
                            modified_row['process_definition_name'] = response_row.process_definition_name
                            modified_row['process_definition_id'] = response_row.process_definition_id
                            modified_row['execution_environment'] = {"id":this.Environment.id,"env_name":this.Environment.name}
                            modified_row['mode_of_execution'] = response_row['mode_of_execution']
                            modified_row['start_date'] = ''
                            modified_row['start_time'] = ''
                            modified_row['exe_type'] ='Planned Job'
                            modified_row['plan_name'] = response_row['plan_name']
                            modified_row['type'] ='plan'
                            modified_row['status'] = 'Waiting to Run'
                            modified_row['job_plan_id'] = response_row['job_plan_id']
                            modified_row['job_plan_instance_id'] = response_row['job_plan_instance_id']
                            modified_row['id'] = response_row['job_plan_inst_detail_id']
                            modified_row['job_plan_instance_status'] = response_row['job_plan_instance_status']
                            modified_row['policy_details'] = response_row['policy_details']
                            modified_row['process_definition_name']  = addDependency(response_row.process_definition_name,response_row.instance_details_indentation)
                            modified_row['parent_job'] = response_row['parent_job']

                            if (response_row['status'] === 'Sent for approval')
                            modified_row['status'] = 'Sent for approval'
                            modified_row["id"] = response_row.job_plan_inst_detail_id
                            _this.waitingJobList.rows.push(modified_row)
                        }
                    }
                }).catch(CurrentJobError => {
                    this.showMessage(CurrentJobError)
                })
            },
            deleteWaitingJob(record){
                let _this = this;
                let data_to_send={"coordinator_id":record.id}
                let url = config.COORDINATOR_API_URL;
                var deleted_job_plan_id = ''
                if(record.type == 'approval')
                    url = url+'/delete_coordinator';
                else if(record.type == 'Scheduled'){
                    url = config.SCHEDULER_URL+'/remove_next_occurrence';
                    data_to_send.job_id = record.id;
                } 
                else if(record.job_plan_instance_status == "running" || record.status == "running" || record.status == "Waiting to Run" || record.status == "suspended", record.status == "error") {
                    return this.showMessage('You cannot delete planned job.You need to terminate the current running job from that job plan')
                }else if(record.type == "plan" || record.type == "job_plan"){
                    data_to_send = record
                    data_to_send.client_id = this.clientId
                    url = config.JOB_PLAN_URL + DELETE_JOB_PLAN_OBJECTS;
                    data_to_send.env_id = this.Environment.id
                    deleted_job_plan_id = record.job_plan_id
                }
                this.loader = true
                postToServer(this, url, data_to_send, true).then(repository_response => {
                    this.loader = false
                    let index = _.findIndex(_this.waitingJobList.rows, ['id', record.id]);
                    _this.waitingJobList.rows.splice(index,1);
                    if(deleted_job_plan_id){
                        while (true) {
                            let index = _.findIndex(_this.waitingJobList.rows, ['job_plan_id', deleted_job_plan_id]);
                            if(index === -1)
                            break;
                            _this.waitingJobList.rows.splice(index,1);
                        }
                    }
                    this.showMessage(repository_response.message || repository_response)
                }).catch(CurrentJobError => {
                    this.showMessage(CurrentJobError)        
                })
            },
            onWaitingEnquiry(record){
                let url=config.COORDINATOR_API_URL+'/get_approval_history';
                let _this = this;
                let data_to_send={"coordinator_id":record.id}
                this.loader = true
                postToServer(this, url, data_to_send, true).then(repository_response => {
                    this.loader = false
                    this.gridDialog = true;
                    repository_response.map((obj, index)=>{
                        obj.seq_num = index+1;
                    }); 
                    _this.enquireList.rows = repository_response;
                }).catch(CurrentJobError => {
                    _this.showMessage(CurrentJobError)
                });
            },
            removeFromWaitingGrid(data,call_from){
                let _this = this
                var grid_data = _.cloneDeep(_this.waitingJobList.rows)
                for (var i = 0; i < grid_data.length; i++){
                    var current_row = grid_data[i]
                    if(call_from === 'schedule'){
                        if(current_row.process_definition_name === data.process_definition_name && current_row.exe_type === "On Schedule" && current_row.mode_of_execution.toLowerCase() === data.mode_of_execution.toLowerCase()){
                            grid_data.splice(i,1)
                            break
                        }
                    }
                    else{
                        if(current_row.id === data.id && current_row.type === data.row_type){
                            grid_data.splice(i,1)
                            break
                        }
                    }
                }
                _this.waitingJobList.rows = grid_data
            },
            removeAllWaitingGrid(data){
                let _this = this
                var grid_data = _.cloneDeep(_this.waitingJobList.rows)
                for(var j=0;j<data.ids.length;j++){
                    var current_id = data.ids[j]
                    for (var i = 0; i < grid_data.length; i++){
                        var current_row = grid_data[i]
                        if(current_row.id == current_id && current_row.type === data.row_type){
                            grid_data.splice(i,1)
                            break
                        }
                    } 
                }
                _this.waitingJobList.rows = grid_data
            },
            createPolicyDetails(policy_details){
                if(typeof(policy_details) == "string")
                    policy_details =  JSON.parse(policy_details)
                if(!policy_details.length)
                    return ''
                let policyStr = ''
                _.forEach(policy_details,(obj)=>{
                    if(obj.actual_date)
                    policyStr = policyStr?policyStr+','+obj.actual_date:obj.actual_date
                })
                return policyStr
            },
        }
    }
</script>