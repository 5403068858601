<template>
	<v-container class="EDC-Container">
		<v-dialog persistent v-model="showEdcConfirmation" width="500">
			<v-card class="EDC-Container">
				<v-row class="EDC-Row Row-Padding">
					<v-toolbar dense class="EDC-Toolbar">
						<v-toolbar-title>{{confirmationHeader}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<!-- <v-btn icon>
		        			<v-icon>mdi-dots-vertical</v-icon>
		      			</v-btn> -->
					</v-toolbar>
				</v-row>
				<v-row class="EDC-Row Row-Padding">
					<v-col class="EDC-Col" col="12" align="start">{{displayMsg}}</v-col>
				</v-row>
				<v-row class="EDC-Row Row-Padding">
					<v-col class="EDC-Col" cols="12" align="start">
						<v-checkbox small v-model="applyForAll" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :label="applyForAllLabel" v-if="showApplyForAll" class="Checkbox-Options"></v-checkbox>
					</v-col>
				</v-row>
				<v-row class="EDC-Row Row-Padding">
					<v-col class="EDC-Col" cols="12">
						<template v-for="(eachButton,index) in buttonList">
							<vc-button :itemText="eachButton.itemText" :key="index+'btn'" @click.native="buttonSubmit(index)" :itemHelp="eachButton.itemHelp"></vc-button>
						</template>
							<vc-button itemText="Cancel" @click.native="cancelConfirmation" itemHelp="Cancel Confirmation"></vc-button>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<style scoped>
	.Row-Padding{
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}
	.Checkbox-Options >>> label {
		top: 5px !important;
    	font-size: 12px !important
	}
</style>
<script>
	export default {
		name:"edcConfirmationBox",
		data(){
			return{
				buttonList:[],
				displayMsg:'',
				applyForAll:false,
			}
		},
		props:{
			applyForAllLabel:{
				type:String,
				default:'Apply For All'
			},
			buttonOptions:{
				type:Array
			},
			messageForDisplay:{
				type:String,
				default:''
			},
			showApplyForAll:{
				type:Boolean,
				default:false
			},
			showEdcConfirmation:{
				type:Boolean,
				default:false
			},
			confirmationHeader:{
				type:String,
				default:"Confirm"
			}
		},
		watch:{
			showEdcConfirmation:{
				handler(newvalue){

				}
			},
			buttonOptions:{
				handler(newvalue){
					this.buttonList = _.cloneDeep(newvalue)
				}
			},
			messageForDisplay:{
				handler(newvalue){
					this.displayMsg = newvalue
				}
			}
		},
		mounted(){
			this.buttonList = _.cloneDeep(this.buttonOptions)
			this.displayMsg = this.messageForDisplay
		},
		methods:{
			buttonSubmit(index){
				this.$emit('onConfirmationAction',this.buttonList[index].itemValue,this.applyForAll)
				this.showEdcConfirmation = false
			},
			cancelConfirmation(){
				this.$emit('onCancelConfirmation')
				this.showEdcConfirmation = false
			}
		}
	}
</script>