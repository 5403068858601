<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/jobplanhistory">{{ execution_env_name }}</router-link>
              </li>
              <li>
                <router-link to="/jobplanhistory">Job Plan History</router-link>
              </li>
              <li>Instances Details - {{ job_plan_name }}</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onHistory="onHistory" @onDownload="onDownload"  @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import {JOB_PLAN_HISTORY_INSTANCE, EXPORT_JOB_INSTANCE_HISTORY} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import getUserRole from '@/methods/GetUserRole.js'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from '@/data/macros.js'
import config from '../../config.json'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import {SERVER_ERROR} from '@/data/client_message.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  name: 'EventList',

  data: function () {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'success',
      snackbartimeout: Snackbar_Default_Timeout,
      EnvironmentList: [],
      EnvironmentListRules: [v => !!v || 'Environment is required.'],
      calendarDetails: this.$store.state.calendarDetails,
      calenderList: [],
      tableList: {
        headers: [
        {
          text: 'Id',
          value: 'id',
          align:'right',
          title: 'Job Plan Instance Id',
          dataType:'int'
        },
        {
          text: 'Exe Type',
          value: 'exe_type',
          width: '20%',
          title: 'Job Plan Instance Exe Type'
        },
        {
          text: 'Mode',
          value: 'mode_of_execution',
          width: '30%',
          title: 'Job Plan Instance Mode Of Execution'
        },
        {
          text: 'Status',
          value: 'status',
          width: '20%',
          title: 'Job Plan Instance Status'
        },
        {
          text: 'Start Date',
          value: 'start_date',
          width: '20%',
          title: 'Job Plan Instance Start Date',
          dataType:'date'
        },
        {
          text: 'Start Time',
          value: 'start_time',
          width: '20%',
          title: 'Job Plan Instance Start Time',
          dataType:'time',
          hideInlineFilter: true
        },
        {
          text: 'End Date',
          value: 'end_date',
          width: '20%',
          title: 'Job Plan Instance End Date',
          dataType:'date'
        },
        {
          text: 'End Time',
          value: 'end_time',
          width: '20%',
          title: 'Job Plan Instance End Time',
          dataType:'time',
          hideInlineFilter: true
        },
        ],
        actions: [
        {'text':'history','key':"id", selectType:"single",index:1}
                ], //if we need conditional action in row then provide key
        sorting_type: SERVER_SIDE,
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        total_count: 0,
        rows: [],
        select_rows: true,
        //syncHeaderScroll: false,
        },
        userRole:{},
        Environment:{},
        job_plan_id : this.$route.params.job_plan_id,
        job_plan_name : this.$route.params.job_plan_name,
        execution_environment:this.$route.params.execution_environment,
        execution_env_name:this.$route.params.execution_env_name,
        loader:false,
        envIpJson : {
            "filter": [],
            "sort": [{
                "column": "",
                "type": ""
            }],
            "page": 1,
            "page_size": 10
        }
            }
          },
          mounted() {``
            getEnvironmentList(this)
            this.getPlanHistoryInstanceList();
            this.envIpJson.page_size = manageScreenDisplay()
            this.getPlanHistoryInstanceList (this.envIpJson)
            var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
              this.Environment = this.$session.get('selected_env')
              this.getPlanHistoryInstanceList()

            }
        // this.userRole = this.$session.get('user_role_mapping');
        // this.tableList.actions=[{'text':'create','key':"", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,SCHEDULE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION)}]
      },
      methods: {
        onDownload(downloadParams, filterArray){
            let _this = this
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                'job_plan_id': this.job_plan_id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.JOB_PLAN_URL+EXPORT_JOB_INSTANCE_HISTORY;
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.JOB_PLAN_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            })
        },
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
      getPlanHistoryInstanceList() {
        let _this = this;
        _this.loader = true;
        var inputJson = this.envIpJson
        var env_id = this.Environment.id;
        if (!env_id)
          return false
        var env_id = _this.Environment.id
        this.$session.set('selected_env', this.Environment)
        var job_data = {'client_id':this.$session.get('client_id'),
        'execution_environment': env_id,
        'job_plan_id': this.job_plan_id,
        "filter": inputJson.filter,
        "page": inputJson.page,
        "page_size": inputJson.page_size,
        "sort": inputJson.sort};
        let baseUrl = config.JOB_PLAN_URL;
        baseUrl = baseUrl+ JOB_PLAN_HISTORY_INSTANCE;
        postToServer(this, baseUrl, job_data
          ).then(response => {
            _this.loader=false;
            _this.tableList.rows = response.result;
            _this.tableList.total_count = response.total;
          }).catch(CurrentJobError => {
           _this.loader=false;
           this.tableList.rows=[];
           this.snackbar = true;
           this.colorValue = 'error'
           this.snackbartext = CurrentJobError;
         })
        },
       onPageChange(page, perPage) {
           let _this = this;
           let inputJson = _this.envIpJson;
           inputJson.page = page;
           inputJson.page_size = perPage;
           this.getPlanHistoryInstanceList(inputJson);
       },

       onPerPageChange(perPage, page) {
           let _this = this;
           let inputJson = _this.envIpJson;
           inputJson.page = page;
           inputJson.page_size = perPage;
           this.getPlanHistoryInstanceList(inputJson);
       },

       sortBy: function (sortJson) {
           let _this = this;
           let inputJson = _this.envIpJson;
           inputJson.sort = sortJson
           this.getPlanHistoryInstanceList(inputJson);
       },

       filterData(filterArray, pageno) {
           let _this = this;
           let inputJson = _this.envIpJson;
           inputJson.filter=filterArray;
           inputJson.page=1
           this.getPlanHistoryInstanceList(inputJson);
       },
       onHistory(record){
         let _this = this;
         this.$router.push({ name: 'jobplaninstancedetails',
          params: {
           job_plan_id: this.job_plan_id,
           job_plan_instance_id: record.id,
           job_plan_name:this.job_plan_name,
           execution_environment: this.execution_environment,
           execution_env_name: this.execution_env_name}
         }
         );
       },
     }
   }
   </script>

   <style scoped>
   /* tr:nth-child(even) {background-color: #E8E8E8;}
   .blurOpacity{
    opacity: 0;
  }
  .showInput {
    visibility: inherit !important;
    width: 50% !important;
  }

  .srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
  }

  table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
  }

  thead {
    line-height: 30px;
    transition: all 10s;
  }

  th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }

  .srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
  } */
  </style>
