//import '@babel/polyfill'
import Vue from 'vue'
//import './plugins/vuetify'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import 'vuetify/dist/vuetify.min.css'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueBreadcrumbs from 'vue-breadcrumbs'
import store from './store'
import VueSession from 'vue-session'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCookies from 'vue-cookies'
import VueDraggable from 'vue-draggable'
import ToggleButton from 'vue-js-toggle-button'
// import Toasted from "vue-toasted";
import SvgIcon from 'vue-svgicon'
// require('typeface-ibm-plex-sans')
import ibmflex from 'typeface-ibm-plex-sans'

import edcsnackbar from '@/views/edcsnackbar'
import calender from '@/views/calender'
import vcButton from '@/views/button.vue'
import loadingPanel from '@/views/loading.vue'
import edcdatagrid from './common/components/edcDataGrid/datagrid.vue'
import VueClipboard from 'vue-clipboard2'
import {mixinDatagrid} from './common/mixins/mixindatagrid.js'

Vue.use(VueMoment, {
    moment,
})

// Default tag name is 'svgicon'
Vue.use(SvgIcon, {
    tagName: 'svgicon'
})

window.$ = require('jquery')
window.JQuery = require('jquery')

Vue.use(VueDraggable)
Vue.use(VueBreadcrumbs)
Vue.use(BootstrapVue);
Vue.use(VueResource)
Vue.use(VueSession, {'persist': false})
Vue.use(VueCookies)
Vue.use(ToggleButton)
Vue.use(ibmflex)
Vue.use(VueClipboard)

Vue.mixin(mixinDatagrid)


Vue.component('edc-snackbar', edcsnackbar)
Vue.component('edc-calender', calender)
Vue.component('vc-button', vcButton)
Vue.component('loading-panel', loadingPanel)
Vue.component('edc-data-grid', edcdatagrid)

// Vue.use(Toasted, {
//     theme: "primary",
//     duration: 2000,
//     position: "top-right",
//     action: {
//         text: "X",
//         onClick: (e, toastObject) => {
//             toastObject.goAway(0);
//         }
//     }
// });
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')