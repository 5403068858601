<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="4">
                        <edc-data-grid :dataList="OutputTableList" @radioSelectionChange="worktableChanged"
                        @onCellEvent="onCellEvent"></edc-data-grid>
                    </v-col>
                    <v-col class="EDC-Col" cols="8">
                        <worktable-data :worktableObj="workTableObj" :allStepsDescription="allStepsDescription"></worktable-data>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import config from '../../../config.json'
    import {post as postToServer } from './../../../methods/serverCall.js';
    import {GET_WORKTABLE_LIST} from '../../../data/url_constants.js';
    import WorkTableData from './showWorkTableDataNew.vue'

    export default{
        name:"JobWorkTable",
        mixins:[mixinJobManagement],
        components:{
            'worktable-data':WorkTableData
        },
        props:{
            jobId:{
                type:Number,
                default:0
            },
            jobName:{
                type:String,
                default:""
            }
        },
        data(){
            return{
                OutputTableList: {
                    headers:[],
                    actions: [],
                    rows:[]
                },
                outputTableHeaders:[
                    {text:'',value:'radio',useRadio:true,hideSorting:true,hideFilter:true},
                    { text: 'Step Name', value: 'output_table_reference', width:"30%", options:[],align:"left",hideSorting:true,hideFilter:true},
                    { text: 'Table Name', value: 'output_table_name',width:"30%",options:[],align:"left",hideSorting:true,hideFilter:true},
                    { text: 'Row Count', value: 'row_count', width:"15%", options:[],align:"left",hideSorting:true,hideFilter:true},
                ],
                outputTableOtherOptions:{
                    showRadio:true,
                    showSelect:false,
                    showExport:false,
                    showInlineFilter:false,
                    showRowInformation:false
                },
                WorkTableList:[],
                workTableObj:{},
                lastStepName:'',
                allStepsDescription:[]
            }
        },
        watch: {
            jobId:{
                handler(newValue){
                    this.getWorktableList()
                }
            }
        },
        mounted() {
            this.OutputTableList = this.getGridObj(this.outputTableHeaders,'output_table_name',true,this.outputTableOtherOptions)
            this.getWorktableList()
        },
        methods: {
            getWorktableList(){
                let _this = this
                if(!this.jobId)
                    return
                var request_data = {"job_id":this.jobId}
                _this.loader = true
                postToServer(this, config.ENGINE_API_URL + GET_WORKTABLE_LIST,request_data).then(response => {
                    _this.loader = false
                    response.map(function(obj){
                    obj.env_id = _this.environmentId
                    obj.display_name =  obj.output_table_reference +' - '+ obj.output_table_name + ' - ' + obj.row_count + ' - ' + obj.step_type
                    if(obj.table_column_data)
                        obj.table_column_data = JSON.parse(obj.table_column_data)
                    else
                        obj.table_column_data = ''
                    })
                    this.WorkTableList = response
                    this.OutputTableList.rows = response
                    this.OutputTableList.total_count = response.length;
                }).catch(JobStepError => {
                    _this.showMessage(JobStepError)
                })
            },
            worktableChanged(record){
                let worktableobj = _.cloneDeep(record)
                worktableobj['step_name']=record.output_table_reference
                worktableobj['job_id']=this.jobId
                worktableobj["envname"]=this.environmentName
                worktableobj['job_name'] = this.jobName
                worktableobj['available_table_list'] = this.OutputTableList.rows
                this.workTableObj = worktableobj
            },
            onCellEvent(record){
                var _this =this
                // this.worktableobj ={}
                if((record.operation === 'Insert'|| record.operation ==='Analyze' || record.step_type === 'report'||record.operation === 'Duplicate_findings')){
                    if(this.lastStepName !=record.step_name){
                        // below 2 variable used to perform spl operation while performing duplicate step data fetching
                        var is_duplicate = false  // switch datasources
                        var duplicate_filter = {} // filter specific table
                        this.lastStepName = record.step_name
                        var output_table_name = record.table_name
                        if(record.operation === 'Duplicate_findings'){
                            output_table_name = record.processing_table
                            is_duplicate = true
                            duplicate_filter ={"column":"table_name","value":this.lastStepName}
                        }
                        this.workTableObj = {
                            'output_table_name':output_table_name,
                            'env_id':this.environmentId,
                            'output_table_reference':this.lastStepName,
                            'is_duplicate':is_duplicate,
                            'duplicate_filter':duplicate_filter,
                            'step_name':record.output_table_reference,
                            'job_id':this.jobId,
                            'available_table_list':_this.OutputTableList.rows
                        }
                    }
                }
            },
            getAllStepsDescription(){
                var _this = this
                var data = {}
                postToServer(_this, config.REPOSITORY_API_URL +'/get_process_designer_steps_description',data).then(response=>{
                    _this.allStepsDescription =  response
                }).catch(error=>{

                })
            },
        },
    }
</script>