<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getCatalogTableList" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Delete Catalog Request List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="EDC-Row">
      <v-col cols="12" class="EDC-Col">
        <edc-data-grid :dataList="tableList" @onHistory="onHistory" @ondblClick="onHistory"></edc-data-grid>
      </v-col>
    </v-row>
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import config from './../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../../data/macros.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'

export default {
  name: 'RequestList',
  components: {
  },
  data: function () {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'success',
      snackbartimeout: 10,
      loader:false,
      Environment:{},
      EnvironmentList:[],
      tableList: {
        headers: [
        { text: 'Request Id', value:'id'},
        { text: 'Execution Mode', value:'mode_of_execution'},
        { text: 'Deletion Type', value:'env_deletion_type'},
        { text: 'Requested By', value:'created_by'},
        { text: 'Request Status', value: 'status'},
        { text: 'Requested Date', value: 'created_date'},
        { text: 'Requested Time', value: 'created_time'},
        ],
        actions:[],
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        rows: [],
        selected_rows: [],
        disableDefaltSorting:true,
        hideExport:true,
        hideShowColumns:true,
        itemkey:'id'
      },
    }
  },
  computed:{
   
  },
  mounted() {
    this.tableList.actions = [
      {'text':'history','key':"id", selectType:"single",index:1}
    ]
   var env_value = this.$session.get('selected_env')
   if( env_value && env_value.id != '0' && env_value.id !='all_env')
      {
        this.Environment = this.$session.get('selected_env')
      }
      getEnvironmentList(this);
      this.getCatalogTableList()
    },
  methods: {
    getCatalogTableList(){
      var _this = this
      _this.tableList.rows = []
      if(!this.Environment.id)
        return false
      var client_id = this.$session.get('client_id')
      var data = {
        "env_id":this.Environment.id,
        "client_id":client_id
      }
      _this.$session.set('selected_env', this.Environment)
      this.loader =true
      postToServer(this, config.ENGINE_API_URL +'/get_all_catalog_request_list',data).then(response=>{
        _this.loader = false
        _this.tableList.rows = response
      }).catch(error=>{
        _this.loader = false
      })
    },
    onHistory(record){
        this.$router.push({ name: 'catalogDeletionDetails', params: {request_id:record.id}}) 
    }
  }
}
</script>
<style scoped>
</style>
