<template>
  <div style="padding-top:0px;">
    <v-row class="EDC-Row" v-if="!component_call">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row" style="padding-top:10px !important" align="center">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb" style="padding-left: 14px !important">
              <li>Report Download</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    
    <edc-data-grid :dataList="tableList" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData" @onDelete="onDelete" @onCellEvent="onCellEvent" @onReportDownload="onReportDownload" @onSelectedRows="onSelectedRows" @ondblClick="onReportDownload"></edc-data-grid>
    
    <loading-panel :loader="loader"></loading-panel>
    
  </div>
</template>

<script>
// import { post as postToServer } from './../../methods/serverCall.js';
import Breadcrumb from "../Breadcrumbs.vue"
import config from '../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
// import edcdatagrid from '../../views/dataTable/edcdatagrid.vue'
import loadingPanel from './../../views/loading.vue'
import graphtemplate from '../templates/graph_template.vue'
import {DELETE_REPORT_STEP,GET_DOWNLOAD_FILE} from '@/data/url_constants.js';
  export default {
    name: "PublishHistory",
    components: {
      // 'edc-data-grid': edcdatagrid,
      'loading-panel':loadingPanel
    },
    props:{
      jobid:{
       type:String,
       default:""
      }
    },
    data () {
      return {
        search: '',
        snackbar:false,
        snackbartext:'',
        snackbartimeout:4000,
        colorValue:'error',
        selectedRows: [],
        headers: [      
            { text: 'Step Name', value: 'step_name', sortable: false, options:[],cssClass:"w16", width: '20%', title: 'Job Step Name'},
            { text: 'Req Date', value: 'timezone_aware_requested_at', sortable: false, options:[], width: '10%', title: 'Requested Date', dataType:'date'},
            { text: 'Req Time', value: 'timezone_aware_requested_time', sortable: false, options:[], width: '10%', title: 'Requested Time', dataType:'time'},
            { text: 'Status', value: 'is_download_ready', sortable: false, options:[], width: '10%' , title: 'File Download Availability Status'},
            { text: 'File', value: 'filename', sortable: false, options:[], cssClass:"w16",width: '30%', title: 'File Name' },
            { text: 'Size (MB)', value: 'filesize', sortable: false, options:[], width: '8%', 'align': 'right', title: 'File Size In MB', dataType:'int'},
            { text: 'File Type', value: 'file_type', sortable: false, options:[], width: '10%' , title: 'File Type'},
            { text: 'File Creation Status', value: 'file_creation_status', sortable: false, options:[], width: '20%'},
            { text: 'Failed Reason', value: 'failed_reason', sortable: false, options:[], width: '20%'},
        ],
        tableList: {
              headers:[],
              actions: [],
              rows: []
          },
        envIpJson : {
                  "filter": [],
                  "sort": [{
                      "column_name": "",
                      "type": ""
                  }],
                  "page": 1,
                  "page_size": 10
              },
        publish_history: [],
        loader:false,
        show_graph_template:false,
        graphtemplatedata:{},
        disableDefaltSorting:true,
        job_id: '',
        step_id: '',
        component_call:false,
      }
    },
    watch:{
      'jobid':{
        handler(newval){
          this.job_id = newval
          this.component_call = true
          this.getReportDownloadStatus(this.envIpJson);
        }
      }
    },
    mounted() {
      this.tableList =  this.getGridObj(this.headers,'id',true,{'showExport':false})
      this.tableList.actions = [
              {'text':'onReportDownload', selectType:"multiple",directDownload:true,'key':"is_download_ready", cmpValue:'Ready',index:1},
              {'text':'delete', 'key':"job_id", selectType:"multiple", role: true,index:2}
          ]

      if(this.$route.params.frompage){
        this.job_id = this.$route.params.job_id
        this.step_id = this.$route.params.step_id
        this.getReportDownloadStatus(this.envIpJson);
      }
    },
    methods: {
      download_reports(selected_files,job_name){
      let _this = this
      var data_to_send = {"client_id":this.$session.get("client_id"), "job_id":this.job_id,"user_id":this.$session.get("user_id"),"selected_files":selected_files,"job_name":job_name}
      var url = config.AGENT_API_URL + GET_DOWNLOAD_FILE
      var report_download_url = config.AGENT_API_URL + "/export_report_in_zip/"
      postToServer(this, url, data_to_send).then(response => {
        _this.loader=true;
        if(response){
          report_download_url = report_download_url +response
          window.open(report_download_url, '_blank');
          _this.loader=false;

        }
      }).catch(error_response => {
      if(error_response){
      }
      else {
        }
      });
    },

      onDelete(records){
            var _this = this
            _this.loader = true;
            var delete_data = []

            _.forEach(records,function(record){
                delete_data.push({
                'client_id': _this.$session.get('client_id'),
                'filename': record.filename,
                'step_id': record.step_id,
                'file_type': record.file_type,
                'job_id': record.job_id
              })
            })
            var delete_object_url = config.AGENT_API_URL + DELETE_REPORT_STEP
            postToServer(_this, delete_object_url, delete_data).then(response => {
            _this.snackbar = true
            _this.loader = false;
            _this.colorValue = 'success'
            _this.snackbartext = response;
            _this.getReportDownloadStatus(this.envIpJson);
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            }
            else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
                }
            });
        },
      onSelectedRows(selectedRows){
            this.selectedRows = selectedRows;
        },
      getDownloadFileData(record){
            let _this = this;
            let downloadData =  {
                "list_of_files_path": []
                }
            if(!_this.selectedRows.length){
            downloadData.list_of_files_path.push(cloneDeep(record.filename));
            }else{
                _this.selectedRows && _this.selectedRows.map(row=>{
                    downloadData.list_of_files_path.push(cloneDeep(row.filename));    
                })
            }
            return downloadData
        },
      onReportDownload:function(record){
        if(!record.length)
          record =[record]
        let _this = this;
        _this.loader=true;
        var job_name = ""
        var list_of_files_path = []
        // var filename = record.filename
        // var url = config.AGENT_API_URL + "/export_report_in_zip/" + filename;
        //         window.open(url, '_blank');
        //         _this.loader=false;
        for (var i = 0; i < record.length; i++) {
          list_of_files_path.push(record[i].filename)
          if (!job_name)
            job_name = record[i].job_name
        }
        if (list_of_files_path.length > 0){
          _this.download_reports(list_of_files_path,job_name)

        }
        // let downloadData = _this.getDownloadFileData(record);
        // var url = config.AGENT_API_URL+'/export_report_in_zip';   
        // postToServer(_this, url, downloadData).then(Response => {
        //   debugger
        //     window.open(url, '_blank');
        //         _this.loader=false;
            
        // }).catch(objError => {
        //     _this.loader=false;
        //   if(objError){
        //         _this.loader = null
        //         _this.snackbar = true
        //         _this.colorValue = 'error'
        //         _this.snackbartext = objError;
        //       }
        //       else {
        //         _this.snackbar = true
        //         _this.colorValue = 'error'
        //         _this.snackbartext = SERVER_ERROR;
        //       }
        //   })
    },
      hideLoader(){
            let _this = this;
            setTimeout(function(){
                  _this.loader = false; 
              },500);
     },
      getReportDownloadStatus(envIpJson) {
        let inputJson = this.envIpJson;
        var client_id = this.$session.get('client_id')
        var input_json = {
              "client_id":client_id,
              "job_id": this.job_id,
              "filter": inputJson.filter,
              "page": inputJson.page,
              "page_size": inputJson.page_size,
              "sort": inputJson.sort,
            }
        this.loader = true; 
        postToServer(this, config.AGENT_API_URL + '/get_report_status', input_json
          ).then(response => {
            var data = response
            if(data){
              data.map(function(obj){
                if (obj.is_download_ready)
                  obj.is_download_ready = 'Ready'
                else
                  obj.is_download_ready = 'Not Ready'
              })
            this.tableList.rows = data;
            this.tableList.total_count = data.length;
            } else {
                data = []
                this.tableList.rows = data;
                this.tableList.total_count = data.length;
              }
              this.hideLoader();
          }).catch(ProcessDocError => {
            this.tableList.rows = [];
            this.tableList.total_count = 0;
            if(ProcessDocError){
              // this.snackbar = true
              // this.colorValue = 'error'
              // this.tableList.rows = [];
              // this.tableList.total_count =0;
              // this.snackbartext = ProcessDocError;
            }
            else {
              // this.snackbar = true
              // this.colorValue = 'error'
              // this.snackbartext = SERVER_ERROR;
            }
            this.hideLoader();
        });
        },
        filterData(filterArray,pageno){
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.filter=filterArray;
          inputJson.page=1
          this.getReportDownloadStatus(inputJson);
        },
        onPageChange(page, perPage) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getReportDownloadStatus(inputJson);
        },
        onPerPageChange(perPage,page) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getReportDownloadStatus(inputJson);
      },
        sortBy: function (sortJson) {
            let _this = this;
            if(sortJson.length <=0)
                return
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getReportDownloadStatus(inputJson);
        },
        onCellEvent(record){
          this.onReportDownload([record])
        },
    }
  }
</script>
<style>
.iconFormat{
    margin-right: 5px;
    cursor: pointer;
}
</style>

