<template>
  <div>
    <v-layout
      row
      wrap
      pl-4
      mb-3
      class="breadcrumbBackground"
    >
      <v-flex
        xs8
        style="margin:auto"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            pl-3
          >
            <ul class="breadcrumb">
              <li>
                <router-link to="/migration_add_info">
                  Add DB info for migration
                </router-link>
              </li>
            </ul>
          </v-flex>
          <v-flex />
        </v-layout>
      </v-flex>
      <v-flex
        xs4
        text-xs-right
        pr-4
        pb-1
        style=""
      />
    </v-layout>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card class="elevation-7">
        <v-card-text style="padding-top:0px;">
          <v-container style="padding-top:0px;">
            <!-- <v-layout row wrap> -->
            <v-flex xs12>
              <v-layout
                row
                wrap
              >
                <v-flex
                  text-sm-left
                  xs2
                >
                  <label
                    class="v-label"
                    style="margin-top: 13%;"
                  >Database Type:</label>
                </v-flex>
                <v-flex xs9>
                  <v-radio-group
                    v-model="databaseType"
                    row 
                    hide-details
                    :rules="databaseTypeRules"
                    @change="setPort"
                  >
                    <v-radio
                      v-for="db in databaseTypes"
                      :key="db.id"
                      :label="db.name"
                      :value="db.id"
                      :color="colorCode"
                      class="label-margin"
                    />
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="SourceHost"
                    label="Source Host"
                    style="margin-right:10px;"
                    required
                    :rules="HostRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="SourcePort"
                    label="Source Port"
                    style="margin-left:10px;"
                    required
                    :rules="PortRules"
                    @keypress="isNumber"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="SourceDatabase"
                    label="Source Database Name"
                    style="margin-right:10px;"
                    required
                    :rules="DatabaseRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="SourceSchemaName"
                    label="Source Schema Name"
                    style="margin-left:10px;"
                    required
                    :rules="SchemaNameRules"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="SourceUserName"
                    label="Source User Name"
                    style="margin-right:10px;"
                    required
                    :rules="UserNameRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="SourceUserPassword"
                    label="Source Password"
                    style="margin-left:10px;"
                    :append-icon="show1 ? 'visibility_off' : 'visibility'"
                    :type="show1 ? 'text' : 'password'"
                    required
                    :rules="UserPasswordRules"
                    @click:append="show1 = !show1"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-textarea
                    v-model="SourceDescription"
                    label="Source Description"
                    rows="1"
                    style="margin-right:10px;"
                  />
                </v-flex>
                <v-flex
                  v-if="databaseType === 'db2' || databaseType === 'db2i'"
                  xs6
                >
                  <v-text-field
                    v-model="Sourcedb2nickname"
                    label="Database Nickname(if available)"
                    style="margin-left:10px;"
                  />
                </v-flex>
              </v-layout>
              <!-- destination info -->
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationHost"
                    label="Destination Host"
                    style="margin-right:10px;"
                    required
                    :rules="HostRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationPort"
                    label="Destination Port"
                    style="margin-left:10px;"
                    required
                    :rules="PortRules"
                    @keypress="isNumber"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationDatabase"
                    label="Destination Database Name"
                    style="margin-right:10px;"
                    required
                    :rules="DatabaseRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationSchemaName"
                    label="Destination Schema Name"
                    style="margin-left:10px;"
                    required
                    :rules="SchemaNameRules"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationUserName"
                    label="Destination User Name"
                    style="margin-right:10px;"
                    required
                    :rules="UserNameRules"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="DestinationUserPassword"
                    label="Destination Password"
                    style="margin-left:10px;"
                    :append-icon="show1 ? 'visibility_off' : 'visibility'"
                    :type="show1 ? 'text' : 'password'"
                    required
                    :rules="UserPasswordRules"
                    @click:append="show1 = !show1"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-textarea
                    v-model="DestinationDescription"
                    label="Destination Description"
                    rows="1"
                    style="margin-right:10px;"
                  />
                </v-flex>
                <v-flex
                  v-if="databaseType === 'db2' || databaseType === 'db2i'"
                  xs6
                >
                  <v-text-field
                    v-model="Destinationdb2nickname"
                    label="Database Nickname(if available)"
                    style="margin-left:10px;"
                  />
                </v-flex>
                <v-flex
                  v-if="databaseType === 'oracle'"
                  xs6
                >
                  <v-text-field
                    v-model="DestinationDBlink"
                    label="Destination DB link"
                    style="margin-left:10px;"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- </v-layout> -->
          </v-container>
        </v-card-text>
      </v-card>
      <v-layout
        row
        wrap
        mt-5
      >
        <v-flex
          xs12
          class="text-xs-right"
          mr-2
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:10px"
            @click="submit"
          >
            Save
          </v-btn> 
          <!-- <v-btn outline :color="outlineColor" style="border-radius:10px" :loading="testConnectionLoader" 
                  @click="TestDBConnection" >
                    Test Connection
                </v-btn> -->
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:10px"
            @click="onCancel"
          >
            Cancel
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <connectionstring v-if="type_connection_sting" />
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :right="true"
      :timeout="snackbartimeout"
      :color="colorValue"
    >
      {{ snackbartext }}
    </v-snackbar>
  </div>
</template>
<script>
import config from '../../config.json'
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ADD_DATASOURCE, DS_AND_DB_TYPES, CHECK_CONNECTION, UPDATE_DATASOURCE, GET_ALL_AGENT_MASTER } from '../../data/url_constants.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
export default {
     name: 'Schedule',
     components: {
    Breadcrumb
  },
  props: {
    msg: String
  },
     data() {
      return {
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        testConnectionLoader:false,
        valid: false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout:4000,
        colorValue:'error',
        loader: "loading",
        loading: false,
        databaseTypes: [],
        datasourceProperties: [],
        databaseType: "ms_sql",
        SourceHost: '',
        DestinationHost: '',
        SourcePort: '',
        DestinationPort: '',
        SourceUserName: '',
        DestinationUserName: '',
        SourceUserPassword: '',
        DestinationUserPassword: '',
        SourceDatabase: '',
        DestinationDatabase: '',
        SourceSchemaName: '',
        DestinationSchemaName: '',
        SourceDescription: '',
        DestinationDescription: '',
        Sourcedb2nickname:'',
        Destinationdb2nickname:'',
        DestinationDBlink:'',
        type_connection_sting:  false,
        show1: false,
        row:'datasource',
        databaseTypeRules: [v => !!v || 'Database Type is required.'],
        HostRules: [v => !!v || 'Host is required.'],
        PortRules: [v => !!v || 'Port is required.',
                              v => v.length <= 6 || 'Port must be 4 digit'],
        UserNameRules: [v => !!v || 'User Name is required.'],
        UserPasswordRules: [v => !!v || 'Password is required.'],
        DatabaseRules: [v => !!v || 'Database Name is required.', v => /^[a-zA-Z0-9-_ ]+$/.test(v) || 'Database Name must be valid'],
        SchemaNameRules: [v => !!v || 'Schema Name is required.', v => /^[a-zA-Z0-9-_ ]+$/.test(v) || 'Schema Name must be valid'],
      }
    },
  mounted () {
       this.GetDSAndDBTypes() 
    },
  methods: {
    onCancel(){
      this.$router.push("/datasourcelist")
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    submit () {
        if (this.$refs.form.validate()) {
          // Native form submission is not yet supported
          this.Generate_Connection_String()
        if(this.$route.params.datasourceid && this.$route.params.type && this.$route.params.type == 'edit'){
              this.updateData()
        } else {
          this.addDatasource()
        }
        }
    },
    setPort(){
          let portArray = {"ms_sql":"1433","oracle":"1521","db2":"50000","db2i":"446"}
          this.SourcePort = portArray[this.databaseType];
          this.DestinationPort = portArray[this.databaseType];
    },
    GetDSAndDBTypes() {
        getToServer(this, config.DATA_SOURCE_URL + DS_AND_DB_TYPES + this.$route.params.datasourceid, true).then(response => {
                var data = response
                // this.databaseTypes.push({"id":"Select DB type","name":"Select DB type"})
                for (var i = 0; i< data.database_name_dict.database.length; i++)
                {
                  this.databaseTypes.push(data.database_name_dict.database[i])
                }
                for (var j = 0; j< data.datasource_property_dict.datasource_property.length; j++)
                {
                  this.datasourceProperties.push(data.datasource_property_dict.datasource_property[j])
                }
                if(this.$route.params.datasourceid)
                  {
                    this.Host=data.datasource_details.datasource_info.host
                    this.Port=data.datasource_details.datasource_info.port
                    this.UserName=data.datasource_details.datasource_info.user
                    this.UserPassword=data.datasource_details.datasource_info.password
                    this.Database=data.datasource_details.datasource_info.database_name
                    this.SchemaName=data.datasource_details.datasource_info.schema_name
                    this.databaseType=data.datasource_details.datasource_info.database_type
                    this.row=data.datasource_details.datasource_info.datasource_type
                    this.Description = data.datasource_details.datasource_info.description
                    if(data.datasource_details.datasource_info.db2nickname)
                      this.db2nickname = data.datasource_details.datasource_info.db2nickname

                  }
                  this.setPort()    

           }).catch(error_response => {
            if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this.loader = null 
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
        })
     },
    addDatasource(){
      var client_id = this.$session.get('client_id')
      var datasource_input_details = {
            "client_id": client_id,
            "source_ip": this.SourceHost,
            "source_port": this.SourcePort,
            "source_db_name": this.Database,
            "source_schema_name": this.SourceSchemaName,
            "source_db_type": this.row,
            "source_description": this.SourceDescription,
            // "source_db_info": "",
            "source_user": this.SourceUserName,
            "source_password": this.SourceUserPassword,
            "source_connection_string": this.source_connection_string,
            "dest_ip": this.DestinationHost,
            "dest_port": this.DestinationPort,
            "dest_db_type": this.row,
            "dest_db_name": this.DestinationDatabase,
            // "dest_db_info": "",
            "dest_user": this.DestinationUserName,
            "dest_password": this.DestinationUserPassword,
            "dest_schema_name": this.DestinationSchemaName,
            "dest_description": this.DestinationDescription,
            "dest_connection_string": this.dest_connection_string,
            "dest_db_link": this.DestinationDBlink
          }
      if(this.databaseType === "db2" || this.databaseType === "db2i")
      {
        datasource_input_details["source_db2nickname"] =this.Sourcedb2nickname
        datasource_input_details["dest_db2nickname"] =this.Destinationdb2nickname
      }
      // var add_datasource_end_point = config.DATA_SOURCE_URL + ADD_DATASOURCE
      var add_datasource_end_point = "http://192.168.1.144:8041/add_db_info"
      postToServer(this, add_datasource_end_point, datasource_input_details
        ).then(datasourceResponse  => {
            if(datasourceResponse){
              this.colorValue = 'success'
              this.snackbar = true
              this.snackbartext = datasourceResponse;
              // this.$router.push("/migrate_users")
              this.$router.push(
                {name: 'MigrateUsers', 'params': {db_info_id: datasourceResponse}});
            }
        }).catch(DatasourceError => {
          if(DatasourceError){
              this.loader = null 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = DatasourceError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
      });
    },
    updateData () {
      var client_id = this.$session.get('client_id')
      var user_id = this.$session.get('user_id')
      var datasource_details = {
        'client_id': client_id,
        'user_id':user_id,
        'host': this.Host,
        'port': this.Port,
        'user': this.UserName,
        'password': this.UserPassword,
        'database_name': this.Database,
        'schema_name': this.SchemaName,
        'database_type': this.databaseType,
        'datasource_id':this.$route.params.datasourceid,
        'description':this.Description,
        'datasource_type':this.row,
        'connection_string':this.connection_string,
      }
      if(this.databaseType === "db2" || this.databaseType === "db2i")
      {
        datasource_details["db2nickname"] =this.db2nickname
      }
      postToServer(this, config.DATA_SOURCE_URL + UPDATE_DATASOURCE, datasource_details
        ).then(datasourceResponse  => {
        if(datasourceResponse){
          this.colorValue = 'success'
          this.snackbar = true
          this.snackbartext = datasourceResponse;
          this.$cookies.set('datasource_added', true)
          this.$router.push("/datasourcelist")
        }
      }).catch(DatasourceError => {
      if(DatasourceError){
          this.loader = null 
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = DatasourceError;
        }
        else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });
    },

    Generate_Connection_String(){
      if(this.databaseType=='ms_sql'){
          this.source_connection_string='mssql://'+this.SourceUserName+':'+this.SourceUserPassword+'@'+this.SourceHost+':'+this.SourcePort+'/'+this.SourceDatabase+"?driver=SQL Server"

          this.dest_connection_string='mssql://'+this.DestinationUserName+':'+this.DestinationUserPassword+'@'+this.DestinationHost+':'+this.DestinationPort+'/'+this.DestinationDatabase+"?driver=SQL Server"
      }
      else if(this.databaseType=='oracle'){
          this.source_connection_string='oracle+cx_oracle://'+this.SourceUserName+':'+this.SourceUserPassword+'@'+this.SourceHost+':'+this.SourcePort+'/'+this.SourceDatabase+"?driver=FreeTDS"

          this.dest_connection_string='oracle+cx_oracle://'+this.DestinationUserName+':'+this.DestinationUserPassword+'@'+this.DestinationHost+':'+this.DestinationPort+'/'+this.DestinationDatabase+"?driver=FreeTDS"
      }
      else if(this.databaseType=='db2'){
          this.source_connection_string='ibm_db_sa://'+this.SourceUserName+':'+this.SourceUserPassword+'@'+this.SourceHost+'/'+this.SourceDatabase

          this.dest_connection_string='ibm_db_sa://'+this.DestinationUserName+':'+this.DestinationUserPassword+'@'+this.DestinationHost+'/'+this.DestinationDatabase
      } else if(this.databaseType=='db2i'){
          this.source_connection_string='ibm_db_sa+pyodbc400://'+this.SourceUserName+':'+this.SourceUserPassword+'@'+this.SourceHost+'/'+this.SourceDatabase

          this.dest_connection_string='ibm_db_sa+pyodbc400://'+this.DestinationUserName+':'+this.DestinationUserPassword+'@'+this.DestinationHost+'/'+this.DestinationDatabase
      }
    },
  }
}
</script>

<style>
  .label-margin label{
    margin-top:5%;
  }
</style>
