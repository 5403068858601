<template>
    <div> 
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Package Builder</li>
                            <li v-if="erp_list.length > 0 "><select v-model="erp_code" autofocus style="-webkit-appearance: menulist;" @change="fetch_erp_version()">
                                <option v-for="code in erp_list" v-bind:key="code.erp_code" :value="code.erp_code">{{code.erp_code_desc}}</option>
                                </select> </li>
                            <li v-if="erp_list.length > 0">
                                <select v-model="erp_version" autofocus style="-webkit-appearance: menulist;" @change="getAllProcessDefList(envIpJson)">
                                <option v-for="version in erp_version_list" v-bind:key="version.id" :value="version">{{version}}</option>
                                </select>
                            <li v-if="erp_list.length == 0">All Erps
                            </li>
                            
                            
                        
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onCreatePackage="onCreatePackage" @onRowSelected="onRowSelected" @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy"></edc-data-grid>

        <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert>

        <v-dialog v-model="PackageDetails" width="500" persistent>
            <v-col>
                <v-card class="rounded-card">
                    <v-toolbar dark dense>
                        <v-col class="text-md-center">New Package Details
                            <v-icon class="text-lg-left" @click="PackageDetails = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                        </v-col>
                    </v-toolbar>
                    <v-container grid-list-md align-content-space-around style="padding:10px">
                        <v-form ref="Package_form" v-model="valid" @submit.prevent="create_package">
                            <v-col>
                                <v-row style="width:100%;">
                                    <v-col style="text-align: center">
                                        <v-text-field label="Package Name" v-model="package_display_name" style="margin-right:10px" @blur="!package_description?package_description=package_display_name:false" required :rules="PackageDisplayNameRules"></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-textarea label="Package Description" rows=1 v-model="package_description" style="margin-right:10px" required :rules="PackageDescriptionRules"></v-textarea>
                                        <v-autocomplete :items="releaseTypeList" v-model="releaseType" item-text="name" item-value="id" dense hide-details>

                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-spacer></v-spacer>
                                            <vc-button @click.native="createPackage"  itemText="Save"></vc-button>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-form>
                    </v-container>
                </v-card>
            </v-col>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

        <loading-panel :loader="loader"></loading-panel>

    </div>
</template>

<script>
import Simplert from 'vue2-simplert'
import config from '../../config.json'
import { post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import {BTN_COLOR} from '@/data/macros.js'
import {
    fetch_all_erp,
    fetch_erp_version
    } from "./../../methods/erp_details.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'DemoGrid',
    data: function () {
        return {
            outlineColor:BTN_COLOR,
            loader:false,
            selectedRows:[],
            promo_path_id: null,
            process_def: null,
            search: '',
            process_doc_list: [],
            erp_list: [],
            erp_version_list: {},
            erp_code: "",
            erp_version : "",
            isDisabled: false,
            PackageDetails: false,
            package_display_name: '',
            PackageDisplayNameRules: [v => !!v || 'Package Display Name is required.'],
            package_description: '',
            PackageDescriptionRules: [v => !!v || 'Package Description is required.'],
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    { text: 'Name', value: 'object_name', sortable: true, cssClass:'w16', title: 'Object Name'},
                    { text: 'Object Id', value: 'object_id', sortable: true, cssClass:'w16', width: '5%', title: 'Object Id'},
                    { text: 'Type', value: 'object_type_desc', sortable: true, cssClass:'w16', width: '5%', title: 'Object Type'},
                    { text: 'Ver', value: 'object_revision', sortable: true, options:[], align:'right', cssClass:'w16', width: '5%', title: 'Object Version', dataType:'int'},
                    { text: 'Erp Version', value: 'erp_version', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Version', dataType:'int'},
                    { text: 'Erp code', value: 'erp_code', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Code'},
                    { text: 'Created By', value: 'added_by', sortable: true, options:[], cssClass:'w16', title: 'Object Created By'},
                    { text: 'Created Date', value: 'timezone_aware_added_on_date', sortable: true, options:[], cssClass:'w16', title: 'Object Created Date', dataType:'date'},
                    { text: 'Created On', value: 'timezone_aware_added_on_time', sortable: false, options:[], cssClass:'w16', title: 'Object Created On', dataType:'time', hideInlineFilter: true},
                    { text: 'Last Modified By', value: 'modified_by', sortable: true, options:[],cssClass:'w16', title: 'Object Last Modified By' },
                    { text: 'Last Modified Date', value: 'timezone_aware_modified_on_date', sortable: true,  options:[], cssClass:'w16', title: 'Object Last Modified Date', dataType:'date'},
                    { text: 'Last Modified Time', value: 'timezone_aware_modified_on_time', sortable: false,  options:[], cssClass:'w16', title: 'Object Last Modified Time', dataType:'time', hideInlineFilter: true}
                ],     
            snackbar:false,
            snackbartext:'',
            snackbartimeout: Snackbar_Default_Timeout,
            colorValue:'success',
            valid:true,
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],
                    "sort": [{
                        "column": "object_name",
                        "type": "asc"
                    }],
                    "page": 1,
                    "page_size": 10
                },
            isloading:false,
            releaseType:'CST',
            packageType:'all'
        }
    },
    computed:{
        releaseTypeList(){
            if(this.erp_list.length){
                return [
                    {"id":"GA","name":"Stable Release Package"},
                    {"id":"FX","name":"Package For Bug Fix"},
                    {"id":"CST","name":"Custom Package"}
                ]
            }
            else
                return [
                    {"id":"FX","name":"Package For Bug Fix"},
                    {"id":"CST","name":"Custom Package"}
                ]
        }
    },
    watch:{
        erp_list(newval){
        }
    },
    
    components: {
        Simplert
    },
    
    mounted() {
        this.tableList =  this.getGridObj(this.headers, 'indexid', false)
        this.tableList.actions = 
        [
            {
                'text':'CreatePackage','key':"indexid", selectType:"multiple", role:true, index:1,
                options:[
                        {"value": "all", "text":'All in one package'} ,
                        {"value":"individual", "text": 'Individual package by dependent objects'}
                    ],
                'optionKey':'showMultipleOption','optionKeyValue':'Y'
        }
    ]
        var client_id = this.$session.get("client_id")
        var erp_code1 = this.erp_code
        fetch_all_erp(this, client_id)
        if (this.erp_list.length==0) {
            this.getAllProcessDefList(this.envIpJson)   
        }

    },
    
    methods: {
        fetch_erp_version(){
            var client_id = this.$session.get("client_id")
            fetch_erp_version(this, client_id, this.erp_code)
            //this.getAllProcessDefList() 
           
        },
        onRowSelected(selectedRows){
            this.selectedRows = selectedRows; 
        },
        getAllProcessDefList (envIpJson) {
            var _this = this;
            _this.loader = true;
            if (this.erp_code == "select" || this.erp_code == "" || this.erp_version=="select" || this.erp_version==""){
                this.tableList.rows = [];
                this.tableList.total_count =0; 
            }
            var client_id = this.$session.get('client_id')
            var t_data = {
                'product_name': "ARCHIVIST",
                'erp_code': _this.erp_code, 
                'erp_version':_this.erp_version, 
                'client_id': client_id, 
                "filter": _this.envIpJson.filter,
                "page": _this.envIpJson.page,
                "page_size": _this.envIpJson.page_size,
                "sort": _this.envIpJson.sort}
            postToServer(this, config.REPOSITORY_API_URL + '/get_objects_for_package_building', t_data
            ).then(response => {
                var data = response
                _this.loader = false
                if(data){
                    _.forEach(data.result,(obj,ind)=>{
                        obj.showMultipleOption = 'Y'
                        obj.indexid=ind+1
                    })
                this.tableList.rows = data.result;
                this.tableList.total_count = data.total; 
                // data.map(function(obj,idx){
                //     obj['unique_id'] = idx+1
                // }) 
                this.process_doc_list = data
                } else {
                    data = []
                    this.tableList.rows = data;
                    this.tableList.total_count = data.length; 
                }
            }).catch(ProcessDocError => {
                _this.loader = false
                if(ProcessDocError){
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.tableList.rows = [];
                    this.tableList.total_count =0; 
                    this.snackbartext = ProcessDocError;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        onCreatePackage:function(record, packageType){
            if (packageType === 'individual'){
                this.packageType=packageType
                this.createPackage()
            }else{
                this.PackageDetails = true
                this.packageType = 'all'

            }

            // this.packageType = 'all'
            // if(packageType)
            //     this.packageType = packageType
            // this.PackageDetails= true
        },
        create_package(){
        if (this.$refs.Package_form.validate()) {
            
        }
        },
        createPackage:function(){
            var _this = this
            if (this.packageType === 'all' && !this.$refs.Package_form.validate()) {
                return
            }
            var package_id = _this.package_id
            var package_name = _this.package_name
            var package_description = _this.package_description
            var package_display_name = _this.package_display_name
            var package_build_data = {
                'client_id': _this.$session.get('client_id'),
                "erp_code": _this.erp_code, 
                "erp_version": _this.erp_version,
                'package_objects':[],
                "package_description": _this.package_description,
                'package_display_name': package_display_name,
                'release_type':_this.releaseType,
                'package_type':_this.packageType
                }
            package_build_data.package_objects = _this.selectedRows
            _this.loader = true 
            //_this.tableList.rows;
            postToServer(this, config.PACKAGE_API_URL + '/create_package', package_build_data).then(response => { 
                this.PackageDetails=false
                _this.loader = false
                package_id = response.package_details_id
                package_name = response.package_name
                package_display_name = response.package_display_name
                package_description = response.package_description
              this.$router.push({name: 'PackageList', params: {erp_code: _this.erp_code, erp_version: _this.erp_version, package_id: package_id, package_name: package_name, package_display_name:package_display_name, package_description:package_description}});
                }).catch(ProcessDocError => {
                    _this.loader = false
                    if(ProcessDocError){
                        this.PackageDetails = true;
                        this.loader = false;
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = ProcessDocError;
                        this.tableList.rows = data;
                        this.tableList.total_count =data.length; 
                        // setTimeout(() => {  _this.loader = false; console.log(ProcessDocError); }, 5000);
                        setTimeout(function(){
                            console.log(ProcessDocError);
                        },5000);
                        
                        
                    }
                    else {
                        this.PackageDetails = false
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                })
        },
        filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getAllProcessDefList(this.inputJson)
        },
           onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllProcessDefList(this.inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllProcessDefList(this.inputJson);
        },
        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getAllProcessDefList(this.inputJson);
        },
    }
}
</script>

<style scoped>
</style>