<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>All Publish Requests</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onStatus="onStatus" @onDownload="onDownload"  @onCellEvent="onCellEvent" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
// import { post as postToServer } from './../../methods/serverCall.js';
import Breadcrumb from "../Breadcrumbs.vue"
import config from '../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import graphtemplate from '../templates/graph_template.vue'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import { PUBLISH_HISTORY, PUBLISH_HISTORY_CANCEL_REQUEST, EXPORT_PUBLISH_HISTORY, EXPORT_PUBLISH_REQUEST_LIST} from '@/data/url_constants.js';

  export default {
    name: "PublishHistory",
    
    data () {
      return {
        search: '',
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        headers: [
                { text: 'Request ID', value: 'request_id', width: '100px', options:[], title: 'Request Id', dataType:'int'},
                { text: 'Object', value: 'object_name', width: '200px', options:[],cssClass:"w16", title: 'Object Name'},
                { text: 'Object Type', value: 'object_type', width: '100px', options:[], title: 'Object Type'},
                { text: 'Object Ver.', value: 'object_version', width: '100px', options:[], title: 'Object Version', dataType:'int'},
                { text: 'Status', value: 'action', width: '100px', options:[], title: 'Status'},
                { text: 'Requested by', value: 'action_taken_by', width: '100px', options:[], cssClass:"w16", title: 'Requested by'},
                { text: 'Requested Date', value: 'timezone_aware_action_date', cssClass:"w16", options:[], title: 'Publish Request date time', dataType:'date'},
                { text: 'Requested Time', value: 'timezone_aware_action_time', cssClass:"w16", options:[], title: 'Publish Request date time', dataType:'time', hideInlineFilter: true},
                { text: 'Remark', value: 'reason', width: '100px', options:[], cssClass:"w16", title: 'Reason for skipping object to publish'},
                { text: 'Object ID', value: 'object_id', width: '200px', options:[],cssClass:"w16", title: 'Object Id', align: 'right'},
                { text: 'Source Env', value: 'source_name', width: '200px', options:[],cssClass:"w16", title: 'Source Environment'},
                { text: 'Source Host IP', value: 'source_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host IP'},
                { text: 'Source Host Name', value: 'source_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host Name'},
                { text: 'Target Env', value: 'target_name', width: '200px', options:[], cssClass:"w16", title: 'Target Environment'},
                { text: 'Target Host IP', value: 'target_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host IP'},
                { text: 'Target Host Name', value: 'target_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host Name'},
                ],
        // tableList: {
        //         headers: [
        //         { text: 'Request ID', value: 'request_id', width: '100px', options:[], title: 'Request Id', dataType:'int'},
        //         { text: 'Object', value: 'object_name', width: '200px', options:[],cssClass:"w16", title: 'Object Name'},
        //         { text: 'Object Type', value: 'object_type', width: '100px', options:[], title: 'Object Type'},
        //         { text: 'Object Ver.', value: 'object_version', width: '100px', options:[], title: 'Object Version', dataType:'int'},
        //         { text: 'Status', value: 'action', width: '100px', options:[], title: 'Status'},
        //         { text: 'Requested by', value: 'action_taken_by', width: '100px', options:[], cssClass:"w16", title: 'Requested by'},
        //         { text: 'Requested Date', value: 'timezone_aware_action_date', cssClass:"w16", options:[], title: 'Publish Request date time', dataType:'date'},
        //         { text: 'Requested Time', value: 'timezone_aware_action_time', cssClass:"w16", options:[], title: 'Publish Request date time', dataType:'time', hideInlineFilter: true},
        //         { text: 'Remark', value: 'reason', width: '100px', options:[], cssClass:"w16", title: 'Reason for skipping object to publish'},
        //         { text: 'Object ID', value: 'object_id', width: '200px', options:[],cssClass:"w16", title: 'Object Id', align: 'right'},
        //         { text: 'Source Env', value: 'source_name', width: '200px', options:[],cssClass:"w16", title: 'Source Environment'},
        //         { text: 'Source Host IP', value: 'source_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host IP'},
        //         { text: 'Source Host Name', value: 'source_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Source Host Name'},
        //         { text: 'Target Env', value: 'target_name', width: '200px', options:[], cssClass:"w16", title: 'Target Environment'},
        //         { text: 'Target Host IP', value: 'target_host_ip', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host IP'},
        //         { text: 'Target Host Name', value: 'target_host_name', sortable: false, width: '200px', options:[], cssClass:"w16", title: 'Target Host Name'},
        //         ],
        //         actions: [],
        //           // {'text':'revoke','key':"action",'cmpValue':"PENDING", selectType:"single"}],
        //         sorting_type: SERVER_SIDE,
        //         filterType: SERVER_SIDE,
        //         paginationType: SERVER_SIDE,
        //         total_count: 0,
        //         rows: [],
        //         selected_rows: [],
        //         itemkey: 'request_id',
        //         hideSelect: true
        //     },
        envIpJson : {
                  "filter": [],
                  "sort": [{
                      "column_name": "",
                      "type": ""
                  }],
                  "page": 1,
                  "page_size": 10
              },
        tableList: {
                headers:[],
                actions:[],
                rows:[]
            },
        publish_history: [],
        loader:false,
        show_graph_template:false,
        graphtemplatedata:{},
        disableDefaltSorting:true,
      }
    },
    mounted() {
      this.envIpJson.page_size = manageScreenDisplay()
      this.getPublishHistory(this.envIpJson);
      this.tableList =  this.getGridObj(this.headers, 'request_id', false, {showSelect : false})

    },
    methods: {
      onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "row_option":downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "env_id": "",
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.PUBLISHER_URL+EXPORT_PUBLISH_REQUEST_LIST;    
        postToServer(_this, url, inputJson).then(Response => {
            var url = config.PUBLISHER_URL + "/static/" + Response;
            window.open(url, '_blank');
            _this.loader=false;
            
        }).catch(objError => {
            _this.loader=false;
          if(objError){
                _this.loader = null
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = objError;
              }
              else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
              }
          })
    },
      hideLoader(){
            let _this = this;
            setTimeout(function(){
                  _this.loader = false; 
              },500);
     },
     onStatus(record){
        this.$router.push({name:"publishapprovalstatus", params: {'request_id': record.approval_request_id, 'objectName': record.object_name,'fromEnv':record.from_env,'toEnv':record.to_env}})
        },
      onCellEvent(record){
        this.$router.push({ name: 'PublishRequestDetails', params: {'publish_id': record.request_id}})
        },
      getPublishHistory (envIpJson) {
        let inputJson = this.envIpJson;
        var client_id = this.$session.get('client_id')
        var input_json = {
              "client_id":client_id,
              "filter": inputJson.filter,
              "page": inputJson.page,
              "page_size": inputJson.page_size,
              "sort": inputJson.sort,
            }
        this.loader = true;
        postToServer(this, config.PUBLISHER_URL + "/all_publish_request_details", input_json
          ).then(response => {
            var data = response
            if(data){
            this.tableList.rows = data.result;
            this.tableList.total_count = data.total;
            this.publish_history = data.result
            } else {
                data = []
                this.tableList.rows = data.result;
                this.tableList.total_count = data.total;
                this.publish_history = data.result
              }
              this.hideLoader();
          }).catch(ProcessDocError => {
            if(ProcessDocError){
              this.snackbar = true
              this.colorValue = 'error'
              this.publish_history = []
              this.tableList.rows = [];
              this.tableList.total_count =0;
              this.snackbartext = ProcessDocError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
            this.hideLoader();
        });
      },
      filterData(filterArray,pageno){
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.filter=filterArray;
        inputJson.page=1
        this.getPublishHistory(inputJson);
      },
      
      onPageChange(page, perPage) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.page = page;
        inputJson.page_size = perPage;
        this.getPublishHistory(inputJson);
      },
      onPerPageChange(perPage,page) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.page = page;
        inputJson.page_size = perPage;
        this.getPublishHistory(inputJson);
      },

      sortBy: function (sortJson) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.sort = sortJson
        this.getPublishHistory(inputJson);
      },
    }
  }
</script>
<style>
.iconFormat{
    margin-right: 5px;
    cursor: pointer;
}
</style>

