<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <select v-model="Environment" @change="getAllAPIs" style="-webkit-appearance: menulist" autofocus ref="envname">
                                <option :value="{}">Select Environment</option>
                                <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li>API List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <edc-data-grid :dataList="tableList" @onCellEvent="showDetails"  @onHistory="showDetails" @ondblClick="showDetails" @onEnquiry="onEnquiry"></edc-data-grid>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        <v-dialog v-model="helpDialaog" persistent width="840">
            <v-card class="parentCard">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <v-toolbar dark dense>
                                    <v-col class="text-md-center">
                                    '{{helpForAPI}}' API Help
                                    <v-icon class="text-lg-left" style="color:#dedede; height:22px; float:right"
                                        @click="helpDialaog = false">fa-times-circle</v-icon>
                                    </v-col>
                                </v-toolbar>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <v-card class="templateCard style-2scroll">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col">
                                            <span class="apicard" v-html="helpHtml">

                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <vc-button itemText="Copy URL & Payload" @click.native="copyToClipBoard"> </vc-button>
                                <vc-button itemText="Close"> </vc-button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog> 
    </div>
</template>
<style>
    .apitemplatetbl{
        width: 800px !important;
        padding-top:8px I !important;
        padding-bottom:0px !important;
        padding-left:8px I !important;
        padding-right:8px I !important;
        table-layout: fixed !important;
        margin-left: 16px !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
    .templateTH {
        padding:8px !important;
        text-align: left !important;
        width:128px !important;
        border: 1px solid black !important;
    }

    .templateTD {
        padding:8px !important;
        text-align: left !important;
        word-break: break-word !important;
        font-size: 13px !important;
        width: 630px !important;
        white-space:pre-line;
        word-break: break-all;
        border: 1px solid black !important;
    }

    .templateTDWithUL {
        padding:8px !important;
        text-align: left !important;
        word-break: break-word !important;
        font-size: 13px !important;
        width: 630px !important;
        border: 1px solid black !important;
    }

    .templateUL{
        /* padding-top:8px !important; */
        padding-bottom:0px !important;
        padding-left:0px I !important;
        padding-right:0px I !important;
        margin-bottom:0px !important;

    }
    .templateCard{
        height: 64vh !important;
        overflow-y:auto;
        margin-bottom: 8px !important;
    }

    .parentCard{
        height: 80vh !important;
        overflow:hidden;
    }
</style>
<script>
import config from '../../config.json'
import {get as getToServer,post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import {CLIENT_SIDE} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getEnvironmentList} from '@/methods/EnvironmentList.js'
// import data from './metaData.js'
export default {
    name: 'EDCEnvironmentList',
    data: function () {
        return {
            loader:false,
            // tableList: {
            //     headers: [
            //         { text: 'API', value: 'api', title: 'API' },
            //         { text: 'API Method', value: 'api_method', title: 'REST HTTP Method' },
            //         { text: 'API For', value: 'api_help_text', title: 'API can be use for' }
            //     ],
            //     actions:[],//if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll:false,
            //     disableDefaltSorting:true,
            //     hideExport:true,
			//     hideShowColumns:true,
			//     hideFilter:true,
            // },
            tableList: {
                headers:[],
                actions:[],
                rows:[]
            },
            headers: [
                    { text: 'API', value: 'api', title: 'API' },
                    { text: 'API Method', value: 'api_method', title: 'REST HTTP Method' },
                    { text: 'API For', value: 'api_help_text', title: 'API can be use for' }
                ],
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            isloading:false,
            helpDialaog:false,
            helpHtml:"",
            helpForAPI:'',
            currentRow:{},
            EnvironmentList:[],
            Environment:{}

        }
    },

    mounted() {
        this.tableList =  this.getGridObj(this.headers, 'id')
        this.tableList.actions=[{'text':'history','key':"id", selectType:"single", role:true,index:1, 
        'title':"View API Help"},
        // {'text':'enquiry','key':"id", selectType:"single", role:true,index:1, 
        // 'displayText':"View API Called History"},
        ];
        getEnvironmentList(this)

        var env_value = this.$session.get('selected_env')
        if(!_.isEmpty(env_value)){
            this.Environment = this.$session.get('selected_env')
            this.getAllAPIs()
        }
    },
    methods: {
        showError(error_message){
            this.loader = false;
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_message || SERVER_ERROR;
        },
        getAllAPIs(){
            let _this = this;  
            _this.tableList.rows = []
            if(!this.Environment || !this.Environment.id)
                return
            _this.loader=true;
            var url = config.GATEWAY_API_URL+'/get_all_api_list_with_env';
            postToServer(this, url, this.Environment).then(gatewayResponse => {
                _this.loader = false;
                _this.tableList.rows = gatewayResponse;
                _this.tableList.total_count =gatewayResponse.total; 
            }).catch(EnvError => {
                _this.showError(EnvError)
          })
        },
        showDetails:function(record){
            this.helpHtml = record.api_document
            this.helpForAPI = record.api
            this.helpDialaog = true
            this.currentRow = _.cloneDeep(record)
        },
        copyToClipBoard(){
            var _this = this
            var record = this.currentRow
            let copyData = "URL: "+record.url +'\n\n\nHeaders: '+record.request_header+"\n\n\nPayload: "+record.request_template
            navigator.clipboard.writeText(copyData)
            _this.snackbar = true
            _this.colorValue = 'success'
            _this.snackbartext = "URL with help text data copied to clipboard."
        },
        onEnquiry(record){
            this.$router.push({ name: 'restAPICalledHistory', params: {
                'url_for':record.api
            }})
        }
    }
}
</script>
