<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getRequestList" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>My Approvals</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onRequestHistory="onRequestHistory" @onDownload="onDownload" @onAccept="onAccept" @onRevokeRequest="onRevokeRequest" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData" @ondblClick="onRequestHistory"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-dialog v-model="dialog" width="300">
      	<v-form ref="update_task_form" v-model="valid">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
	                		<span><b>Task Name: {{ task_name }}</b></span>
	              		</v-toolbar>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing">
	             		<v-text-field v-if="comments_required === true" v-model="reason" label="User comments" :rules="reasonRules" required @keypress="reasonKeypress" class="EDC-TextField" hide-details dense></v-text-field>
	            		<v-text-field v-else v-model="reason" label="User comments" class="EDC-TextField" hide-details dense></v-text-field>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
	              		<vc-button itemText="Submit" @click.native="update_request_submit"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
      	</v-form>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_ALL_TASK_DETAILS, UPDATE_TASK_DETAILS, GET_ALL_APPROVALS, EXPORT_APPROVALS_HISTORY, EXPORT_ALL_APPROVALS
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {getEnvironmentList} from '@/methods/EnvironmentList.js'
import {
  COLOR_CODE, BTN_COLOR
} from "@/data/macros.js";
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ManageTaskApprovalList',
    
    data: function () {
        return {
        	colorCode:COLOR_CODE,
      		outlineColor:BTN_COLOR,
        	userRole:{},
        	EnvironmentList: [],
            Environment: {},
            EnvironmentListRules: [v => !!v || 'Environment is required.'],  
			headers: [
                	{ text: 'Req. ID', value: 'id', width:'2%', align:'right', title: 'Approval Request ID', dataType:'int'},
                    { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For'},
                    { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description' },
                    { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
                    { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
                    { text: 'Req. Date', value: 'timezone_aware_created_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
                    { text: 'Req. Time', value: 'timezone_aware_created_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
                ], 
			tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            // tableList: {
            //     headers: [
            //     	{ text: 'Req. ID', value: 'id', width:'2%', align:'right', title: 'Approval Request ID', dataType:'int'},
            //         { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For'},
            //         { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description' },
            //         { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
            //         { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
            //         { text: 'Req. Date', value: 'timezone_aware_created_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
            //         { text: 'Req. Time', value: 'timezone_aware_created_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
            //     ],
            //     actions: [{'text':'revoke_request','key':"show_ar_btn","cmpValue":true, selectType:"single",index:1},
            //               {'text':'accept','key':"show_ar_btn","cmpValue":true, selectType:"single",index:2},
            //               {'text':'request_history','key':"requester_desc", selectType:"single",index:3}
            //     ], //if we need conditional action in row then provide key
            //     sorting_type: SERVER_SIDE,
            //     filterType: SERVER_SIDE,
            //     paginationType: SERVER_SIDE,
            //     total_count: 0,
			// 	rows: [],
			// 	selected_rows: [],
			// 	syncHeaderScroll:false,
			// 	disableDefaltSorting:true,
			// 	itemkey: 'id'
            // },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            loading: false,
			dialog: false,
			task_name: '',
			search: '',
			pagination: {},
			TaskList : [],
			ShowSuccessMessage: false,
			ErrorMessage:'',
			SuccessMessage:'',
			ShowErrorMessage:false,
			reason: null,
			task_obj_id:'',
			task_id: '',
			process_workflow_id: '',
			level: '',
			task_action_type : '',
			process_name : '',
            is_process : false,
            request_desc: '',
            object_name: '',
            requester_code: '',
            reasonRules:[
				v => !!v || 'User comments are required.'
            ],
            record:{},
            loader:false,
            comments_required: false,
            envIpJson: {
                "filter": [],
                "sort": [{"coumn_name":"","type":""}],
                "page": 1,
                "page_size": 10
            },
        }
    },
    mounted() {
    	this.envIpJson.page_size = manageScreenDisplay()
        this.tableList = this.getGridObj(this.headers,'id',false)
		this.tableList.actions = [{'text':'revoke_request','key':"show_ar_btn","cmpValue":true, selectType:"single",index:3},
                          {'text':'accept','key':"show_ar_btn","cmpValue":true, selectType:"single",index:2},
                          {'text':'request_history','key':"requester_desc", selectType:"single",index:1}
                ],
    	this.userRole = this.$session.get('user_role_mapping');
        if(this.$session.get('selected_env')){
            this.Environment = this.$session.get('selected_env')
            this.getRequestList(this.envIpJson)
        }
        getEnvironmentList(this,false, false, true);
        this.focus_env();
    },
    methods: {
    	// onRequestHistory(record){
     //        this.$router.push({ name: 'requesthistory', params: { request_id: record.id, current_status:  record.request_status, object_name: record.object_name, name_to_join: 'My Approval', back_url: '/managetaskforapprovallist'}})
     //    },
     	reasonKeypress(e){
     		if(e.key === "Enter"){
     			e.preventDefault()
     			this.update_request_submit()
     		}
     	},
    	focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
		onDownload(downloadParams, filterArray){
			let _this = this
			_this.loader=true;
	        let filter=[];
	        filterArray.map((obj, index)=>{
	            obj.stringArray.map((stringObj, stringIndex)=>{
	                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
	                    filter.push(cloneDeep(temp));
	            })
	        });
	        let inputJson={
	            "recordType":downloadParams.recordType,
	            "row_option":downloadParams.recordType,
	            "client_id": this.$session.get('client_id'),
	            "username": this.$session.get('email'),
	            "env_id": _this.Environment.id,
	            "file_format": downloadParams.fileType,
	            "min_range": downloadParams.minRange|| null,
	            "max_range": downloadParams.maxRange||null,
	            "filter": filter
	        }
	        var url = config.PROCESS_APPROVAL_URL+EXPORT_ALL_APPROVALS;    
	        postToServer(this, url, inputJson).then(Response => {
	        		var url = config.PROCESS_APPROVAL_URL + "/static/" + Response;
	                window.open(url, '_blank');
	                this.loader=false;
	            }).catch(objError => {
	            _this.loader=false;
	            if(objError){
	                  this.loader = null
	                  this.snackbar = true
	                  this.colorValue = 'error'
	                  this.snackbartext = objError;
	                }
	                else {
	                  this.snackbar = true
	                  this.colorValue = 'error'
	                  this.snackbartext = SERVER_ERROR;
	                }
	          });
        },
        update_request_submit () {
			if (this.$refs.update_task_form.validate()) {
				this.update_task()
			}
		},
		update_task() {
			if(!this.reason && this.task_action_type == 'reject'){
				return false
			}
			const l = this.loader
			this[l] = !this[l]
			var user_id = this.$session.get('user_id') 
			if (this.task_action_type == 'accept' || this.task_action_type == 'blanket_approved')
				{
					var current_status = "accepted"
				}
			else if (this.task_action_type == 'reject')
				{
					var current_status = "rejected"
				}
			var env_name = ""
			if(this.Environment){
				var env_name = this.Environment.name
			}
			var update_task_url = config.PROCESS_APPROVAL_URL + UPDATE_TASK_DETAILS + this.task_obj_id
			var updated_data = {
				"action": current_status,
				"remark": this.reason,
				"client_id": this.$session.get('client_id'),
				"user_name":this.user_name,
				"user_id":this.user_id,
				"client_name": this.client_name,
				"node_id_from_email": this.node_id,
				"workflow_master_cloned_id": this.workflow_master_cloned_id,
				"object_id": this.object_id,
				"object_name": this.object_name,
				"is_blanket_approval": this.is_blanket_approval,
				"remark": this.reason,
				"workflow_name": this.workflow_name,
				"request_desc": this.request_desc,
				"env_name": env_name,
				"requester_code": this.requester_code,
				"env_id": this.Environment.id

			}
			postToServer(this, update_task_url, updated_data).then(response =>{
			this.dialog = false
			this.ShowSuccessMessage=true
			this.ShowErrorMessage=false
			this.SuccessMessage=response.current_state
			if (response == 'rejected'){
	            this.snackbar = true
	            this.colorValue = 'success'
	            this.snackbartext = 'You have rejected the request'
	        } else {
	            this.snackbar = true
	            this.colorValue = 'success'
	            this.snackbartext = 'You have accepted the request'
	        }
			this.getRequestList()
			}).catch(error_response => {
			if(error_response){
				this.snackbar = true
				this.colorValue = 'error'
				this.snackbartext = error_response;
				this[l]  = false
				this.loader = null
			}
			else {
				this.snackbar = true
				this.colorValue = 'error'
				this.snackbartext = SERVER_ERROR;
				this[l]  = false
				}
			});
		},
        onAccept(record){
        	this.task_name = record.task_name
        	this.comments_required = false
        	this.dialog = true
            this.record = record;
            this.RequestUpdate(record,'accept');
        },
        onRevokeRequest(record){
        	this.task_name = record.task_name
        	this.comments_required = true
        	this.dialog = true
            this.record = record;
            this.RequestUpdate(record,'reject');
        },
        onRequestHistory(record){
        	var show_ar_btn = false
        	if(record.request_status === 'In progress')
        		show_ar_btn = true
        	this.$router.push({ name: 'requesthistory', params: { request_id: record.id, current_status:  record.request_status, object_name: record.object_name, name_to_join: 'My Approvals', back_url: '/managetaskforapprovallist','show_approve_reject_button':show_ar_btn,request_for: record.requester_desc,object_id: record.object_id,'workflow_master_cloned_id':record.workflow_master_cloned_id,'node_id':record.node_id}})
        },
        getRequestList(envIpJson) {
        	if(!envIpJson)
        		envIpJson = this.envIpJson
        		if(Number.isInteger(this.Environment.id) && this.Environment.id >=0)
					this.$session.set('selected_env', this.Environment)
			    let _this = this;
			    _this.loader = true;
				var username = _this.$session.get('email')
				var client_id = this.$session.get('client_id')
				var data = {
					'env_id': _this.Environment.id,
					'client_id': client_id, 'username': username,
					"filter": envIpJson.filter,
					"page": envIpJson.page,
					"page_size": envIpJson.page_size,
					"sort": envIpJson.sort }
				var get_task_list_url = config.PROCESS_APPROVAL_URL + GET_ALL_APPROVALS
				postToServer(_this, get_task_list_url, data).then(response => {
					_this.tableList.total_count = response.total;
					_this.tableList.rows = response.result
					_this.loader=false;
				}).catch(error_response => {
					_this.loader = false; 
		            if(error_response){
		            	if(error_response == "Unauthorized Request"){
		            		this.snackbar = true
							this.colorValue = 'error'
							this.snackbartext = error_response;
		            	}else{
		                	this.tableList.rows = [];
		            	}
		            }
		            else {
		                this.snackbar = true
		                this.colorValue = 'error'
		                this.snackbartext = SERVER_ERROR;
		            }
				});
		},
		RequestUpdate (task_obj, type) {
			this.reason = '',
			this.task_obj_id = task_obj.id,
			this.node_id = task_obj.recent_node_id
			this.task_name = task_obj.object_name,
			this.is_blanket_approval = task_obj.is_blanket_approval,
			this.created_by = task_obj.created_by
			this.task_action_type = type
			this.current_state = task_obj.current_state
			this.requester_code = task_obj.requester_code
			this.workflow_master_cloned_id = task_obj.workflow_master_cloned_id
			this.user_name = task_obj.user_name
			this.user_id = task_obj.user_id
			this.client_name = task_obj.client_name
			this.object_id = task_obj.object_id
			this.object_name = task_obj.object_name
			this.request_desc = task_obj.request_desc
			this.requester_code = task_obj.requester_code
		},

		onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

        onPerPageChange(perPage, page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

		sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getRequestList(inputJson);
        },

		filterData(filterArray, pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getRequestList(inputJson);
        },
    }
}
</script>
<style scoped>

.EDC-TextField {
 	padding-top:60px !important;
 	padding-left:10px !important;
 	padding-right:10px !important;
}

</style>