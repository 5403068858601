<template>
    <v-container class="EDC-DashboardContainer">
        <v-row no gutters class="EDC-DashboardRow">
            <v-col cols="8" align-self="start" class="EDC-DashboardCol" style="padding-left:5px !important">
                <v-row class="EDC-DashboardRow" style="padding-top:0px !important">
                    <v-col cols="12" class="EDC-DashboardCol" style="padding-left:5px !important; padding-right:5px !important">
                        <ul class="breadcrumb breadcrumbBackground" style="font-size: 14px">
                        <li>
                        <span @click="$router.go(-1)">
                        <a>{{Environment.name}}</a>
                        </span>
                        </li>
                        <li>Compliance Archival</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <v-row no gutters class="EDC-DashboardRow">
            <v-col cols="12" class="EDC-DashboardCol">
                <edc-data-grid :dataList="tableList" @onCreate="onExecute"></edc-data-grid></vc-data-table>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>

<script>
    import Breadcrumb from "../../Breadcrumbs.vue"
    import config from '../../../config.json'
    import cloneDeep from 'lodash/cloneDeep';
    import orderBy from 'lodash/orderBy';
    import getUserRole from '../../../methods/GetUserRole.js'
    import {CLIENT_SIDE, SERVER_SIDE} from '../../../data/macros.js'
    import edcdatagrid from '../../../views/dataTable/edcdatagrid.vue'
    import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
    import { get as getToServer, deleteFromServer, post as postToServer } from '../../../methods/serverCall.js';
    import {manageScreenDisplay} from '@/methods/special.js'
    export default {
        name: 'Compliance Archival',
        data: function () {
            return {
            tableList: {
                headers: [
                    { text: 'Id', value: 'id',align:'right', title: 'Id'},
                    { text: 'Process Doc Name', value: 'pd_name',  options:[], title: 'Process Doc Name'},
                    { text: 'Business Object Name', value: 'bo_name',  width: '100px',  title: 'Business Object Name'},
                    { text: 'Archival Date', value: 'actual_date', options:[], title: 'Archival Date'},
                    { text: 'Value Type', value: 'value_type',  title: 'Policy Value Type'},
                    { text: 'Last Archive Date', value: 'data_older_than', title: 'Last Archive Date'},
                    { text: 'Last Created Date', value: 'created_date', width: '100px' , title: 'Last Archive Created Date'}
                ],
                actions: [],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                select_rows:true,
                showCreateReport:true,
                disableDefaltSorting:true,
                itemkey:'id',
            },
            envIpJson : {
                    "filter": [],
                    "sort": [],
                    "page": 1,
                    "page_size": 10
                },
            snackbar:false,
            snackbartext:'',
            snackbartimeout:5000,
            colorValue:'success',
            loader:false,
            EnvironmentList:[],
            Environment:{},
            userRole:{},
            loader:false,
            }
        },

        components: {
        'edc-data-grid':edcdatagrid        
        },
        mounted(){
            this.envIpJson.page_size = manageScreenDisplay()
            var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
                  this.Environment = this.$session.get('selected_env')
              }

            this.tableList.actions=[{'text':'create','key':"id", selectType:"single", role:true,index:1, 'displayText':'Run'}
            ];

            getEnvironmentList(this);
            this.getComplianceData()
        },
        methods:{
            getComplianceData(){
              let inputParams={
                "client_id": this.$session.get('client_id'),
                "env_id": this.Environment.id      
              }
              let _this = this;
              _this.loader = true
              postToServer(_this, config.JOB_STATISTIC_URL+'/get_job_compliance', inputParams).then(response => {
              _this.loader = false
              console.log("compliance",response)
              _this.tableList.rows = response.pending_archives 
              _.forEach(_this.tableList.rows,function(obj,index){
                obj.id = index+1
                if(obj.value_type === 'actual_date')
                    obj.value_type = 'Actual Date'
                if(obj.last_archive_data){
                    obj.data_older_than = obj.last_archive_data.data_older_than
                    obj.created_date = obj.last_archive_data.created_date
                }

              }) 
              _this.tableList.total_count = response.pending_archives.length
            })
            },
            onExecute(record){
                this.$store.state.createJobParams={ 'process_definition_id': record.pd_id, 
                    'process_doc_name': record.pd_name, 'activity_id':null,
                     'environment':this.Environment, 'is_restore':false};


                this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.pd_id, 
                'process_doc_name': record.pd_name, 
                'environment':this.Environment, 'is_restore':false,
                execution_env_name:this.Environment.name,
                execution_environment:this.Environment.id}});
            }
        }
    }
</script>