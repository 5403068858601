<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getRequestList" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Manage Approval Request List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onRequestHistory="onRequestHistory" @onCancelRequest="onCancelRequest" @onDownload="onDownload" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData" @ondblClick="onRequestHistory"></edc-data-grid>
        
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_ALL_REQUEST_DETAILS, CANCEL_REQUEST, EXPORT_ALL_REQUEST
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {getEnvironmentList} from '@/methods/EnvironmentList.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ManageTaskApprovalList',
    
    data: function () {
        return {
        	userRole:{},
        	EnvironmentList: [],
            Environment: {},
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            // tableList: {
            //     headers: [
            //     	{ text: 'Req. ID', value: 'id', width:'2%', align:'right', title: 'Approval Request ID', dataType:'int'},
            //         { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For' },
            //         { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description'},
            //         { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
            //         { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
            //         { text: 'Req. Date', value: 'timezone_aware_added_on_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
            //         { text: 'Req. Time', value: 'timezone_aware_added_on_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
            //     ],
            //     actions: [{'text':'cancel_request','key':"show_cancel_req", "cmpValue":true, selectType:"single",index:1}, 
            //     {'text':'request_history','key':"requester_desc", selectType:"single",index:0}
            //     ], //if we need conditional action in row then provide key
            //     sorting_type: SERVER_SIDE,
            //     filterType: SERVER_SIDE,
            //     paginationType: SERVER_SIDE,
            //     total_count: 0,
			// 	rows: [],
			// 	selected_rows: [],
			// 	syncHeaderScroll:false,
			// 	disableDefaltSorting:true,
            //     itemkey:'id'
            // },
            tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
            headers: [
                	{ text: 'Req. ID', value: 'id', width:'2%', align:'right', title: 'Approval Request ID', dataType:'int'},
                    { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For' },
                    { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description'},
                    { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
                    { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
                    { text: 'Req. Date', value: 'timezone_aware_added_on_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
                    { text: 'Req. Time', value: 'timezone_aware_added_on_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
                ],
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            loading: false,
			dialog: false,
			task_name: '',
			search: '',
			pagination: {},
			TaskList : [],
			ShowSuccessMessage: false,
			ErrorMessage:'',
			SuccessMessage:'',
			ShowErrorMessage:false,
			reason : '',
			task_obj_id:'',
			task_id: '',
			process_workflow_id: '',
			level: '',
			task_action_type : '',
			process_name : '',
            is_process : false,
            reasonRules:[
				v => !!v || 'Reason is required'
            ],
            record:{},
            loader: false,
            envIpJson : {
                "filter": [],
                "sort": [{"coumn_name":"","type":""}],
                "page": 1,
                "page_size": 10
            },
        }
    },
    beforeCreate(){
     this.userRole = this.$session.get('user_role_mapping');   
    },
    mounted() {
        this.envIpJson.page_size = manageScreenDisplay()
    	this.userRole = this.$session.get('user_role_mapping');
        this.tableList = this.getGridObj(this.headers, 'id',false)
        if(this.$session.get('selected_env')){
            this.Environment = this.$session.get('selected_env')
            this.getRequestList(this.envIpJson)
        }
        getEnvironmentList(this,false, false, true);
        this.focus_env();
    },
    methods: {
    	onRequestHistory(record){

    		var show_delete = false
    		if(record.request_status === 'In progress')
    			show_delete = true

            this.$router.push({ name: 'requesthistory', params: { request_id: record.id, current_status:  record.request_status, object_name: record.object_name, name_to_join: 'My Requests', back_url: '/manageapprovalrequestlist', other_details: record.other_details,request_for: record.requester_desc,show_delete_button:show_delete,object_id:record.object_id}})
        },
    	focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
        onCancelRequest(record){
        	let _this = this;
        	_this.loader=true;
			var client_id = this.$session.get('client_id')
			var data = {
				'id': record.id,
				'client_id': client_id, 
				'approval_id': record.id, 
				'request_desc': record.request_desc,
				'object_id': record.object_id,
				'object_name': record.object_name, 
				'env_id': this.Environment.id, 
				'env_name': this.Environment.name,
                'node_id': record.recent_node_id}
			var cancel_req_url = config.PROCESS_APPROVAL_URL + CANCEL_REQUEST
			postToServer(this, cancel_req_url, data).then(response => {
				if(response){
					this.snackbar = true
					this.colorValue = 'success'
					this.snackbartext = response;
					this.getRequestList()
					_this.loader=false;
				}
			}).catch(error_response => {
			if(error_response){
			}
			else {
				}
			});
        },
        onDownload(downloadParams, filterArray){
	        let _this = this; 
	        _this.loader=true;
	        let filter=[];
	        filterArray.map((obj, index)=>{
	            obj.stringArray.map((stringObj, stringIndex)=>{
	                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
	                    filter.push(cloneDeep(temp));
	            })
	        });
	        let inputJson={
	            "recordType":downloadParams.recordType,
	            "row_option": downloadParams.recordType,
	            "client_id": _this.$session.get('client_id'),
	            "username": _this.$session.get('email'),
	            "env_id": _this.Environment.id,
	            "file_format": downloadParams.fileType,
	            "min_range": downloadParams.minRange|| null,
	            "max_range": downloadParams.maxRange||null,
	            "filter": filter
	        }
	        var url = config.PROCESS_APPROVAL_URL+EXPORT_ALL_REQUEST;    
	        postToServer(this, url, inputJson).then(Response => {
	            var url = config.PROCESS_APPROVAL_URL + "/static/" + Response;
	            window.open(url, '_blank');
	            this.loader=false;
	            
	        }).catch(objError => {
	            _this.loader=false;
	            if(objError){
	                  _this.loader = null
	                  _this.snackbar = true
	                  _this.colorValue = 'error'
	                  _this.snackbartext = objError;
	                }
	                else {
	                  _this.snackbar = true
	                  _this.colorValue = 'error'
	                  _this.snackbartext = SERVER_ERROR;
	                }
	          })
	    },  
        getRequestList(envIpJson) {
            if(!envIpJson)
                envIpJson = this.envIpJson
			if(Number.isInteger(this.Environment.id) && this.Environment.id >=0)
				this.$session.set('selected_env', this.Environment)
            let _this = this;
            _this.loader = true;
            var username = _this.$session.get('email')
			var data = {
                'env_id': _this.Environment.id,
                'username': username,
                "filter": envIpJson.filter,
                "page": envIpJson.page,
                "page_size": envIpJson.page_size,
                "sort": envIpJson.sort}
			var get_task_list_url = config.PROCESS_APPROVAL_URL + GET_ALL_REQUEST_DETAILS
			postToServer(this, get_task_list_url, data).then(response => {
				var _this = this
                debugger
                _this.tableList.total_count = response.total;
				_this.tableList.rows = response.result
				_this.loader = false; 

			}).catch(error => {
                _this.loader = false;
                if(error){
                    this.loader = null                     
                    this.tableList.rows = []; 
                    this.tableList.total_count=0;                   
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
		},
        
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

        onPerPageChange(perPage, page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getRequestList(inputJson);
        },

        filterData(filterArray, pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getRequestList(inputJson);
        },
    }
}
</script>
<style scoped>

</style>