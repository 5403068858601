
export const ACTIVITY_TYPE_ARCHIVAL = 'Archival'
export const ACTIVITY_TYPE_PURGE = 'Purge'
export const ACTIVITY_TYPE_COPY = 'Copy'
export const ACTIVITY_TYPE_RESTORE = 'Restore'
export const ACTIVITY_TYPE_SELECTIVE_RESTORE = 'Selective Restore'
export const ACTIVITY_TYPE_DISPOSE = 'Dispose'
export const ACTIVITY_TYPE_SELECTIVE_DISPOSE = 'Selective Disposal'
export const ACTIVITY_TYPE_MOVE = 'Move'
export const ACTIVITY_TYPE_DELETE_COPY = 'Delete Copy'
export const ACTIVITY_TYPE_FORGOT = 'Forget'
export const BULK_RESTORE ='Bulk Restore'
export const SYNC_UP = 'Sync Up'
export const UN_SYNC_UP = "Unsync Up"


export const jobTypeHeader = { text: 'Job Type', value: 'type_of_job', width: '100px', title: 'Job Type', dataType:'boolean',isLinkCell:true,
"linkEvaluationObj":{
  "cmpheader":"mode_of_execution",
  "expectedValues":["final","Final"],
  "isConditional":true
}, "useDropdownForSearch":true, searchOptions:[
  {"text":"Normal", "value":"Normal"},
  {"text":"Restoration", "value":"Restoration"},
  {"text":"F0911 Unsummarization", "value":"F0911 Unsummarization"},
  {"text":"F4111 Unsummarization", "value":"F4111 Unsummarization"},
  {"text":"Sychronization", "value":"Sychronization"},
  {"text":"Un-Sychronization", "value":"Un-Sychronization"},
  {"text":"Disposal", "value":"Disposal"},
  {"text":"Delete Copy", "value":"Delete Copy"}
], searchItemText:'text',searchItemValue:'value', searchMultiple:false}

export const jobModeHeader ={ text: 'Mode', value: 'mode_of_execution', width: '70px', options:[], title: 'Mode Of Execution', "useDropdownForSearch":true, searchOptions:[
    {"text":"Proof", "value":"proof"},
    {"text":"Final", "value":"final"}], searchItemText:'text',searchItemValue:'value'}

export const jobEXEType = { text: 'Run Type' , value: "exe_type", width: '70px', options:[], cssClass:'w16', title: 'Job Execution Type', "useDropdownForSearch":true, searchOptions:[
    {"text":"On Demand", "value":"On Demand"},
    {"text":"On Schedule", "value":"On Schedule"}], searchItemText:'text',searchItemValue:'value'}


export const jobStatusOnHistory = { text: 'Status', value: 'status', options:[], title: 'Job Status',useChip:true,chipFor:'status', "useDropdownForSearch":true, searchOptions:[
    {"text":"Completed", "value":"Completed"},
    {"text":"Terminated", "value":"Terminated"}], searchItemText:'text',searchItemValue:'value'}


export const jobStatusActiveJob = { text: 'Status', value: 'status_to_show', options:[], title: 'Job Status',useChip:true,chipFor:'status', "useDropdownForSearch":true, searchOptions:[
    {"text":"Initializing", "value":"Initializing"},
    {"text":"Validating", "value":"Validating"},
    {"text":"Preparing", "value":"Preparing"},
    {"text":"Running", "value":"Running"},
    {"text":"Suspending", "value":"Suspending"},
    {"text":"Suspended", "value":"Suspended"},
    {"text":"Terminating", "value":"Terminating"},
    ], searchItemText:'text',searchItemValue:'value'}


export const jobStatusWithWaiting = { text: 'Status', value: 'status', options:[], title: 'Job Status',useChip:true,chipFor:'status', "useDropdownForSearch":true, searchOptions:[
      {"text":"Initializing", "value":"Initializing"},
      {"text":"Validating", "value":"Validating"},
      {"text":"Preparing", "value":"Preparing"},
      {"text":"Running", "value":"Running"},
      {"text":"Suspending", "value":"Suspending"},
      {"text":"Suspended", "value":"Suspended"},
      {"text":"Terminating", "value":"Terminating"},
      {"text":"Error", "value":"Error"},
      {"text":"Waiting for execution", "value":"Waiting for execution"},
      ], searchItemText:'text',searchItemValue:'value'}


