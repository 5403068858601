<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getRequestList" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Approval Request History List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onRequestHistory="onRequestHistory" @onDownload="onDownload" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData" @ondblClick="onRequestHistory"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_ALL_TASK_DETAILS, UPDATE_TASK_DETAILS, GET_ALL_APPROVALS_HISTORY, EXPORT_APPROVALS_HISTORY
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {getEnvironmentList} from '@/methods/EnvironmentList.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ManageTaskApprovalList',
    
    data: function () {
        return {
            EnvironmentList: [],
            Environment: {},
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            headers: [
                    { text: 'Req. ID', value: 'id', align:'right', width:'2%', title: 'Approval Request ID', dataType:'int'},
                    { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For'},
                    { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description'},
                    { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
                    { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
                    { text: 'Req. Date', value: 'timezone_aware_created_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
                    { text: 'Req. Time', value: 'timezone_aware_created_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
                ],
                tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            // tableList: {
            //     headers: [
            //         { text: 'Req. ID', value: 'id', align:'right', width:'2%', title: 'Approval Request ID', dataType:'int'},
            //         { text: 'Req. For', value: 'requester_desc', width:'15%', title: 'Approval Request For'},
            //         { text: 'Description', value: 'request_statement', width:'30%', title: 'Approval Request Description'},
            //         { text: 'Status', value: 'request_status', width:'10%', title: 'Approval Request Status'},
            //         { text: 'Req. By', value: 'created_by', width:'20%', title: 'Approval Requested By'},
            //         { text: 'Req. Date', value: 'timezone_aware_created_date', dataType:'date', width:'10%', title: 'Approval Requested Date'},
            //         { text: 'Req. Time', value: 'timezone_aware_created_time', dataType:'time', width:'10%', title: 'Approval Requested Time', hideInlineFilter: true}
            //     ],
            //     actions: [{'text':'request_history','key':"requester_desc", selectType:"single",index:1}], //if we need conditional action in row then provide key
            //     sorting_type: SERVER_SIDE,
            //     filterType: SERVER_SIDE,
            //     paginationType: SERVER_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows:[],
            //     syncHeaderScroll:false,
            //     disableDefaltSorting:true,
            //     itemkey: 'id'
            // },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            loading: false,
            dialog: false,
            task_name: '',
            search: '',
            pagination: {},
            TaskList : [],
            ShowSuccessMessage: false,
            ErrorMessage:'',
            SuccessMessage:'',
            ShowErrorMessage:false,
            reason : '',
            task_obj_id:'',
            task_id: '',
            process_workflow_id: '',
            level: '',
            task_action_type : '',
            process_name : '',
            is_process : false,
            record:{},
            envIpJson : {
                "filter": [],
                "sort": [{"coumn_name":"","type":""}],
                "page": 1,
                "page_size": 10
            },
            loader:false,
        }
    },
    beforeCreate(){
     this.userRole = this.$session.get('user_role_mapping');   
    },
    mounted() {
        this.envIpJson.page_size = manageScreenDisplay()
        this.tableList = this.getGridObj(this.headers,'id',false)
        this.tableList.actions = [{'text':'request_history','key':"requester_desc", selectType:"single",index:1}]
        this.userRole = this.$session.get('user_role_mapping');
        if(this.$session.get('selected_env')){
            this.Environment = this.$session.get('selected_env')
            this.getRequestList(this.envIpJson)
        }
        getEnvironmentList(this,false, false, true);
        this.focus_env();
    },
    methods: {
        focus_env(){
            if(this.$refs.envname.$el){
                this.$refs.envname.$el.focus()
            }else{
                this.$refs.envname.focus()
            }
        },
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "username": _this.$session.get('email'),
                "env_id": _this.Environment.id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.PROCESS_APPROVAL_URL+EXPORT_APPROVALS_HISTORY;    
            postToServer(this, url, inputJson).then(Response => {
                var url = config.PROCESS_APPROVAL_URL + "/static/" + Response;
                window.open(url, '_blank');
                this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        onRequestHistory(record){
            this.$router.push({ name: 'requesthistory', params: { request_id: record.id, current_status:  record.request_status, object_name: record.object_name, object_id: record.object_id, requester_type: record.requester_type,request_for: record.requester_desc, name_to_join: 'Approval History', back_url: '/approvalhistorylist'}})
        },
        getRequestList(envIpJson) {
            if(!envIpJson)
                envIpJson = this.envIpJson
            if(Number.isInteger(this.Environment.id) && this.Environment.id >=0)
                this.$session.set('selected_env', this.Environment)
            let _this = this
            _this.loader = true;
            var user_process_details = this.$session.get('user_process_details')
            var username = this.$session.get('email')
            var input_json = {
                'username':username,
                'env_id': this.Environment.id,
                "filter": envIpJson.filter,
                "page": envIpJson.page,
                "page_size": envIpJson.page_size,
                "sort": envIpJson.sort
            }
            var get_task_list_url = config.PROCESS_APPROVAL_URL + GET_ALL_APPROVALS_HISTORY
            postToServer(this, get_task_list_url, input_json).then(response => {
                var _this = this
                _this.tableList.total_count = response.total;
                _this.tableList.rows = response.result
                _this.loader = false;
            }).catch(error => {
                _this.loader = false;
                if(error){
                    this.loader = null                     
                    this.tableList.rows = []; 
                    this.tableList.total_count=0;                   
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

        onPerPageChange(perPage, page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getRequestList(inputJson);
        },

        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getRequestList(inputJson);
        },

        filterData(filterArray, pageno) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getRequestList(inputJson);
        },
    }
}
</script>
<style scoped>

</style>