/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom_in': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.646.53A2.12 2.12 0 00.53 2.645c0 1.166.951 2.117 2.117 2.117a2.1 2.1 0 001.294-.447l1.427 1.426c.248.287.66-.125.373-.373L4.315 3.94c.279-.358.446-.807.446-1.295C4.761 1.479 3.812.53 2.646.53zm0 .529c.88 0 1.586.706 1.586 1.586 0 .88-.706 1.588-1.586 1.588-.88 0-1.587-.708-1.587-1.588 0-.88.707-1.586 1.587-1.586zm-.132.53v.925h-.926v.264h.926v.926h.263v-.926h.928v-.264h-.928V1.59h-.263z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
