// serverCallMixin.js
import { post as postToServer } from '../methods/serverCall.js';
import config from './../config.json';

/**
 * Vue.js mixin for handling server calls related to user preferences.
 * Provides methods for fetching, managing, and clearing user preferences.
 */
export const mixinPagePreference={
    data() {
        return {
            productName: 'archivist',
            clientId: '', // Initialize with empty strings
            userName: '',
            sampleSize:10
        };
    },
    mounted(){
        this.clientId = this.$session.get("client_id")
        this.userName = this.$session.get("email")
    },
    methods: {
        /**
         * Get common data for API calls.
         * @param {string} pageCode - Page code for the preference.
         * @param {string} preferenceFor - Preference type.
         * @returns {Object} - Common data object.
         */
        getCommonDataForPagePreferences(pageCode, preferenceFor) {
            return {
                "client_id": this.clientId,
                "user_name": this.userName,
                "product_name": this.productName,
                "page_code": pageCode,
                "preference_for": preferenceFor
            };
        },
        /**
         * Fetch page preferences from the server.
         * @param {string} pageCode - Page code for the preference.
         * @param {Array} currentActions - Current actions list.
         * @param {string} associatedPreferenceList - Associated preference as string. Pass here variable name as key.
         * @param {string} preferenceFor - Preference type. Default 'pageGrid'.
         */
        getPagePreferences(pageCode, currentActions, associatedPreferenceList, preferenceFor = 'pageGrid') {
            var data = this.getCommonDataForPagePreferences(pageCode, preferenceFor);

            postToServer(this, config.USER_PROVISION_URL + '/get_user_preference_for_page', data).then(response => {
                if (!_.isEmpty(response)) {
                this[associatedPreferenceList] = JSON.parse(response.page_preference);
                } else {
                let preferenceDetails = [];
                _.forEach(currentActions, (obj) => {
                    if (!obj.iconFor)
                    return
                    preferenceDetails.push({
                    "iconFor": obj.iconFor,
                    "clickCount": 0,
                    "defaultIndex": obj.index
                    });
                });
                this[associatedPreferenceList] = preferenceDetails;
                this.managePagePreferences(pageCode, preferenceDetails, preferenceFor);
                }
            }).catch(error => {
                console.log("Error in preference fetching");
            });
        },
        /**
         * Manage page preferences on the server.
         * @param {string} pageCode - Page code for the preference.
         * @param {Array} preferenceDetails - Preference details to be managed.
         * @param {string} preferenceFor - Preference type. Default 'pageGrid'
         */
        managePagePreferences(pageCode, preferenceDetails, preferenceFor = 'pageGrid') {
            var data = this.getCommonDataForPagePreferences(pageCode, preferenceFor);
            data['page_preference'] = JSON.stringify(preferenceDetails);
            postToServer(this, config.USER_PROVISION_URL + '/manage_user_preference_for_page', data).then(response => {
                // Preference managed successfully
            }).catch(error => {
                console.log("Error in preference saving");
            });
            },
            /**
             * Clear preferences for a page on the server.
             * @param {string} pageCode - Page code for the preference.
             * @param {string} preferenceFor - Preference type.
             */
            clearPreferences(pageCode, preferenceFor) {
            var data = this.getCommonDataForPagePreferences(pageCode, preferenceFor);
            postToServer(this, config.USER_PROVISION_URL + '/delete_user_preference_for_page', data).then(response => {
                // Preferences deleted successfully
            }).catch(error => {
                console.log("Error in preference deleting");
            });
        },
        /**
         * Update indexes of actions based on preferences.
         * @param {Array} actions - List of actions to update.
         * @param {Array} preferences - List of preferences.
         * @returns {Array} - Updated list of actions.
         */
        updateIndexes(tableActions, preferences) {
            var _this = this
            let actionsWithoutIconFor = _.filter(tableActions, (obj)=>{
                return !obj.iconFor
            })
            let actions = _.filter(tableActions, (obj)=>{
                return obj.iconFor
            })
            // Create a map to store preferences based on iconFor
            const preferencesMap = new Map();
            preferences.forEach(preference => {
                // here we have created key value pair -> key is iconFor(value) and value is whole object of iconFor
                // for example below
                // {"iconFor":"editObj","clickCount":5,defaultIndex:"1"} => {"editObj":{"iconFor":"editObj","clickCount":5,defaultIndex:"1"}}
                preferencesMap.set(preference.iconFor, preference);
            });

            // Sort actions based on clickCount and defaultIndex from preferences
            actions.sort((a, b) => {
                // Retrieve preference objects for the corresponding iconFor from the preferencesMap
                const prefA = preferencesMap.get(a.iconFor);
                const prefB = preferencesMap.get(b.iconFor);


                // Compare clickCount first with sample. If for A & B , clickCount less than sample size 
                // then do the sorting on default index
                if (prefA.clickCount <_this.sampleSize && prefB.clickCount < _this.sampleSize) {
                    // If clickCounts are different,    sort by clickCount in descending order
                    return prefA.defaultIndex - prefB.defaultIndex;
                    // Higher clickCount should come first
                }


                // Compare clickCount first
                if (prefA.clickCount !== prefB.clickCount) {
                    // If clickCounts are different,    sort by clickCount in descending order
                    return prefB.clickCount - prefA.clickCount;
                    // Higher clickCount should come first
                }

                // If clickCounts are equal, compare defaultIndex
                // Sorting in ascending order of defaultIndex (lower defaultIndex will come first)
                return prefA.defaultIndex - prefB.defaultIndex;
            });

            // Assign indexes starting from 2, because index 1 assigned for addNew
            let index = 2;
            actions.forEach(action => {
                action.index = index++;
            });
            console.log(preferences)
            return actionsWithoutIconFor.concat(actions);
        },
        managePagePreferenceCount(iconFor,pageCode, associatedPreferenceList){
            let currentCountObj = _.find(associatedPreferenceList,["iconFor",iconFor])
            if(currentCountObj){
                currentCountObj.clickCount = currentCountObj.clickCount+1
                this.managePagePreferences(pageCode, associatedPreferenceList)
            }
        }
    }
};
