<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<vc-button itemText="openConfirmation" @click.native="testConfirmation">
				
			</vc-button>
		</v-row>
			<edc-confirmation :showEdcConfirmation="showEdcConfirmation" :buttonOptions="edcConfirmationOption" :messageForDisplay="msgInConfirmation" @onConfirmationAction="onConfirmationAction" :showApplyForAll="true" @onCancelConfirmation="showEdcConfirmation=false"></edc-confirmation>
		<!-- </v-dialog> -->
	</v-container>
</template>
<script>
	import edcConfirmationBox from './edcConfirmationBox'
	export default {
		name:"edcConfirmationBoxText",
		components:{
			'edc-confirmation':edcConfirmationBox
		},
		data(){
			return{
				showEdcConfirmation:false,
				edcConfirmationOption:[{"itemText":"Override","itemValue":"override","itemHelp":"Override existing version"},
									   {"itemText":"New Version","itemValue":"newversion","itemHelp":"Create a new version"},
									   {"itemText":"Skip","itemValue":"skip","itemHelp":"Skip import"}],
				msgInConfirmation:'',
				counter:0
			}
		},
		methods:{
			testConfirmation(){
				this.showEdcConfirmation = true
				this.msgInConfirmation = "Message "+this.counter
			},
			onConfirmationAction(confirmationValue,isForAll){
				var _this = this
				alert(confirmationValue)
				this.counter++
				_this.showEdcConfirmation = false
				if(isForAll){
					alert('is for all')
					return
				}
				setTimeout(()=>{
					_this.msgInConfirmation = "Message "+_this.counter
					_this.showEdcConfirmation = true
				},1000)
			}
		}
	}
</script>