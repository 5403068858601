import io from 'socket.io-client';
import config from '../config.json';

export function live_details(that){
	try
	{
		if(!that || !that.$session || !that.$session.get('WEB_SOCKET_URL'))
			return false
		var socket = ""
		var websocketPart = that.$session.get('WEB_SOCKET_URL').split(":")
		// websocket url is protocol://ip:port so split will have 3 values and we need port only
		var socket_url = window.location.protocol +"//"+window.location.hostname+":"+websocketPart[websocketPart.length-1]
		console.log("socket_url",socket_url)
		if(config.PROTOCOL === "HTTPS")
			socket =io(socket_url, {  secure: true, reconnect: true,rejectUnauthorized: false } );
		else
			socket = io(socket_url)
	    
		socket.on(that.$session.get('client_id'),function(data){
			  console.log(data.type)
		      if(data.type === 'jobstatus_notification'){
		      	that.setNotification(data.message)
		      	return
		      }
		      if(data.type === 'livestatus'){
		      	that.setLiveStatus(data.data_to_update)
		      	return
		      }
		      if(data.type ==='rowcount_notification'){
		      	that.setRowCounts(data.data_to_update)
		      	return
		      }
		      if(data.type ==='sqllogs_livestatus'){
		      	that.setSqlLogsLiveStatus(data.data_to_update)
		      	return
		      }
		      if(data.type === "query_time_notification"){
	      		that.setTransactionTableObj(data.data_to_update)
	      		return
	      	  }
	      	  if(data.type === "current_waiting_job_to_running"){
	      	  	that.setCurrentWaitingJobToRunning(data.data_to_update)
	      	  	return
	      	  }
	      	  if(data.type === "waiting_to_terminate_status"){
	      	  	that.setCurrentWaitingJobToTerminate(data.data_to_update)
	      	  	return
	      	  }
	      	  if(data.type === "Migration"){
	      	  	console.log(data)
	      	  	that.setmigrationInfo(data)
	      	  	return
	      	  }
	      	  if(data.type === "Migration"){
	      	  	console.log(data)
	      	  	that.setmigrationInfo(data)
	      	  	return
	      	  }
	      	  if(data.type === 'dashboard_status_update'){
	      	  	console.log(data)
	      	  	that.setjobPlanDashboardInfo(data.data_to_update)
	      	  }
	      	  if(data.type === 'job_estimate_time'){
	      	  	console.log(data)
	      	  	console.log("In eastimate notification")
	      	  	that.setJobEstimateRemainingTime(data.data_to_update)
	      	  }
	      	  if(data.type === 'summarization_count_notification'){
	      	  	console.log(data)
	      	  	that.setSummarizedRowCounts(data.data_to_update)
	      	  }
			  if(data.type === "job_plan_live_status"){
				that.setJobPlanLiveStatus(data.data_to_update)
			  }
		})

		socket.on(that.$session.get('email'),function(data){
			that.setNotification(data.message)
		    return
		})

	}
	catch(error){

	}
}