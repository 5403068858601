<template>
	<div style="background-color:white;">
		<objectBOTabularView></objectBOTabularView>

	</div>
</template>
<script>
import Criteria from './newcriteria.vue';
import objectBOTabularView from './objectPDTabularView.vue'

export default {
	name:'testtemplate',
	components:{
		'objectBOTabularView':objectBOTabularView,
	},
	data(){
		return{
			mainFilter:[],
			grpFilter:[],
			mainfilteroperation:null,
		}
	},
	mounted(){
	},
	methods:{
		
	}
}
</script>
