import {
    SERVER_SIDE
  } from '../data/macros.js'

var tblObj ={
    headers:[],
    itemkey:'rn',
    rows:[],
    total_count:0,
    selected_rows:[],
    sorting_type: SERVER_SIDE,
    filterType: SERVER_SIDE,
    paginationType: SERVER_SIDE,
    primary_keys:[],
    inputJson:{},
    hideSelect:true,
    actions:[
       
    ],
    moreInformatonDetails:{"showMoreInfo":true},
    perPage:10,
    pageNumber:1,
    disableDefaltSorting:true,
    hideDownload:true,
    hideShowColumns:true,
    hideSelect:true,
    showDataviewerRedirection:true,
    actual_total_count:0
}

export function getDataviewerTbleObj(){
    let dataviewerTblObj = _.cloneDeep(tblObj)
    return dataviewerTblObj
}