<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Deleted Users list</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <edc-data-grid :dataList="tableList" @onActivate="onActivateUser" @onDelete="onDelete"></edc-data-grid>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
    </div>
</template>
<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, deleteFromServer, post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from './../../data/client_message.js'
import {
    GET_ALL_DELETED_USER,ACTIVATE_USER,
} from './../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from './../../data/macros.js'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep';
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    USER_PROVISIONING_SERVICE_ID,
    CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    data: function () {
        return {
            userRole:{},
            loader: false,
            // tableList: {
            //     headers: [{
            //             text: 'Name',
            //             value: 'full_name',
            //             width: "20%",
            //             title: 'User Name'
            //         },
            //         {
            //             text: 'User Id',
            //             value: 'username',
            //             width: "20%",
            //             title: 'User Email ID'
            //         },
            //         {
            //             text: 'Deleted By',
            //             value: 'modified_by',
            //             width: "20%",
            //             title: 'Deleted By'
            //         },
            //         {
            //             text: 'Deleted Date',
            //             value: 'timezone_aware_modified_date',
            //             width: "20%",
            //             title: 'User Deleted Date',
            //             dataType:'date'
            //         },
            //         {
            //             text: 'Deleted Time',
            //             value: 'timezone_aware_modified_time',
            //             width: "20%", 
            //             title: 'User Deleted Time',
            //             dataType:'time',
            //             hideInlineFilter: true
            //         }
            //     ],
            //     actions:[],
            //     hideFilter: true,
            //     hideExport: true,
            //     hideShowColumns: true,
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     itemkey:'username',
            //     selected_rows: [],
            //     disableDefaltSorting:true
            //     // syncHeaderScroll:false,
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [{
                        text: 'Name',
                        value: 'full_name',
                        width: "20%",
                        title: 'User Name'
                    },
                    {
                        text: 'User Id',
                        value: 'username',
                        width: "20%",
                        title: 'User Email ID'
                    },
                    {
                        text: 'Deleted By',
                        value: 'modified_by',
                        width: "20%",
                        title: 'Deleted By'
                    },
                    {
                        text: 'Deleted Date',
                        value: 'timezone_aware_modified_date',
                        width: "20%",
                        title: 'User Deleted Date',
                        dataType:'date'
                    },
                    {
                        text: 'Deleted Time',
                        value: 'timezone_aware_modified_time',
                        width: "20%", 
                        title: 'User Deleted Time',
                        dataType:'time',
                        hideInlineFilter: true
                    }
                ],
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
        }
    },
    mounted() {
        let _this = this
        _this.userRole = this.$session.get('user_role_mapping');
        this.tableList = this.getGridObj(this.headers,'id')
        _this.tableList.actions = [{'text':'activate','key':"showactivate", selectType:"multiple", role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,UPDATE_ACTION), index:2, cmpValue: 'YES',panelTitle:'Activate Users',panelText:'Are you sure want to Activate Users?'},
                                   {'text':'delete','key':'showactivate', selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,UPDATE_ACTION),index:1}], //if we need conditional action in row then provide key
        _this.getDeletedUsersList();
    },
    methods: {
        //Function to get the deleted user list
        getDeletedUsersList(){
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            _this.tableList.rows = [];
            getToServer(_this, config.USER_PROVISION_URL + GET_ALL_DELETED_USER + client_id).then(response => {
                _this.loader = false;
                if (response) {
                    _this.tableList.rows = response;
                    _this.tableList.total_count = response.length;
                    _this.tableList.rows.map(function(obj){               
                            obj.showactivate = 'YES';
                    })
                } else {
                    _this.tableList.rows = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                // if (error_response) {
                //     this.loader = null
                //     this.snackbar = true
                //     this.colorValue = 'error'
                //     this.snackbartext = error_response;
                // } else {
                //     this.snackbar = true
                //     this.colorValue = 'error'
                //     this.snackbartext = SERVER_ERROR;
                // }
            });
        },
        //Function to activate the deleted users
        onActivateUser:function(record){
            let _this = this;
            _this.activateuser(record);
        },
        activateuser(record){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var data_to_send = record
            postToServer(_this, config.USER_PROVISION_URL + ACTIVATE_USER, data_to_send).then(response => {
                this.getDeletedUsersList()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        },
        onDelete:function(record){
            var _this = this
            var data_to_send = record
            var del_url = config.USER_PROVISION_URL + '/hard_delete_user'
            postToServer(_this, del_url, data_to_send).then(response =>{
                _this.getDeletedUsersList()
            }).catch(err=>{
                _this.getDeletedUsersList()
            })
        }
    },
}
</script>