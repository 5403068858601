<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row" v-if="!isCallFromJob">
                    <v-col class="EDC-Col">
                        <ul class="breadcrumb" style="padding-left: 14px !important">
                            <li>Report Download</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onCellEvent="onCellEvent"
                          @onReportDownload="onReportDownload" 
                          @ondblClick="onReportDownload">
                        </edc-data-grid>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import config from '../../../config.json'
    import {post as postToServer } from './../../../methods/serverCall.js';
    import {DELETE_REPORT_STEP,GET_DOWNLOAD_FILE} from '@/data/url_constants.js';
    export default{
        name:"JobReports",
        mixins:[mixinJobManagement],
        props:{
            jobId:{
                type:Number,
                default:0
            },
            jobName:{
                type:String,
                default:""
            }
        },
        data () {
            return {
                headers: [      
                    { text: 'Step Name', value: 'step_name', sortable: false, options:[],cssClass:"w16", width: '20%', title: 'Job Step Name'},
                    { text: 'Req Date', value: 'timezone_aware_requested_at', sortable: false, options:[], width: '10%', title: 'Requested Date', dataType:'date'},
                    { text: 'Req Time', value: 'timezone_aware_requested_time', sortable: false, options:[], width: '10%', title: 'Requested Time', dataType:'time'},
                    { text: 'Status', value: 'is_download_ready', sortable: false, options:[], width: '10%' , title: 'File Download Availability Status'},
                    { text: 'File', value: 'filename', sortable: false, options:[], cssClass:"w16",width: '30%', title: 'File Name' },
                    { text: 'Size (MB)', value: 'filesize', sortable: false, options:[], width: '8%', 'align': 'right', title: 'File Size In MB', dataType:'int'},
                    { text: 'File Type', value: 'file_type', sortable: false, options:[], width: '10%' , title: 'File Type'},
                    { text: 'File Creation Status', value: 'file_creation_status', sortable: false, options:[], width: '20%'},
                    { text: 'Failed Reason', value: 'failed_reason', sortable: false, options:[], width: '20%'},
                ],
                tableList: {
                    headers:[],
                    actions: [],
                    rows: []
                },
            }
        },
        computed: {
            isCallFromJob(){
                return this.jobId > 0
            }
        },
        watch:{
            jobId:{
                handler(newValue){

                }
            },
            jobName:{
                handler(newValue){

                }
            }
        },
        mounted() {
            this.tableList =  this.getGridObj(this.headers,'id',true,{'showExport':false})
            this.tableList.actions = [
                {'text':'onReportDownload', selectType:"multiple",directDownload:true,'key':"is_download_ready", cmpValue:'Ready',index:1},
                {'text':'delete', 'key':"job_id", selectType:"multiple", role: true,index:2}
            ]
            this.getReportDownloadStatus()
        },
        methods:{
            getReportDownloadStatus(){
                var _this = this
                var input_json = {
                    "client_id":this.clientId,
                    "job_id": this.jobId,
                }
                this.loader = true; 
                this.tableList.rows = []
                postToServer(this, config.AGENT_API_URL + '/get_report_status', input_json).then(response=>{
                    var data = response
                    if(data){
                        data.map(function(obj){
                        if (obj.is_download_ready)
                            obj.is_download_ready = 'Ready'
                        else
                            obj.is_download_ready = 'Not Ready'
                        })
                        this.tableList.rows = data;
                        this.tableList.total_count = data.length;
                    }
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                })
            },
            onCellEvent(record){
                this.onReportDownload([record])
            },
            downloadReport(selected_files,job_name){
                let _this = this
                var data_to_send = {
                    "client_id":this.clientId, 
                    "job_id":this.jobId,"user_id":this.userName,
                    "selected_files":selected_files,"job_name":job_name || this.jobName
                }
                var url = config.AGENT_API_URL + GET_DOWNLOAD_FILE
                var report_download_url = config.AGENT_API_URL + "/export_report_in_zip/"
                _this.loader=true
                postToServer(this, url, data_to_send).then(response => {
                    _this.loader=false;
                    if(response){
                        report_download_url = report_download_url +response
                        window.open(report_download_url, '_blank');
                    }
                }).catch(error_response => {
                    this.showMessage(error_response)
                });
            },
            onDelete(records){
                var _this = this
                var delete_data = []
                _.forEach(records,function(record){
                    delete_data.push({
                        'client_id': _this.clientId,
                        'filename': record.filename,
                        'step_id': record.step_id,
                        'file_type': record.file_type,
                        'job_id': record.job_id
                    })
                })
                var delete_object_url = config.AGENT_API_URL + DELETE_REPORT_STEP
                postToServer(_this, delete_object_url, delete_data).then(response => {
                    _this.showMessage(response,"success")
                    _this.getReportDownloadStatus();
                }).catch(error_response => {
                    _this.showMessage(error_response)
                });
            },
            onReportDownload(record){
                if(!record.length)
                    record =[record]
                let _this = this;
                var job_name = ""
                var list_of_files_path = []
        
                for (var i = 0; i < record.length; i++){
                    list_of_files_path.push(record[i].filename)
                    if (!job_name)
                        job_name = record[i].job_name
                }
                if (list_of_files_path.length > 0){
                    _this.downloadReport(list_of_files_path,job_name)
                }
            }
        }
    }
</script>