<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Calendar Rule</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        
        <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @onEdit="onEdit"></edc-data-grid>
    
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_ALL_EXECUTION_RULE, GET_ALL_PUBLISHESD_PROCESS_DEF_LIST,DELETE_EXECUTION_RULE_MAPPING,
     GET_ALL_EXECUTION_RULE_MAPPING, ADD_EXECUTION_RULE_MAPPING, UPDATE_EXECUTION_RULE_MAPPING} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import config from '../../config.json'
import environmentList from '../../methods/EnvironmentList.js'
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name:'ProcessExecutionRule',
        
        data: function () {
        return {
            //    tableList: {
            //     headers: [
            //         { text: 'Process Definition', value: 'object_name', width:"20%",option:[], 
            //             'item_text':'object_name', 'item_value':'object_name', title: 'Process Definition Name', useAutoComplete:true},
            //         { text: 'Environment', value: 'env_name', width:"20%",option:[], 
            //             'item_text':'name', 'item_value':'name', title: 'Process Definition Environment Name', useAutoComplete:true},
            //         { text: 'Job Calendar', value: 'calendar_name', width:"20%",option:[], 
            //             'item_text':'name', 'item_value':'name', title: 'Process Definition Job Calendar Name', useAutoComplete:true},    
            //         { text: 'Action On Start', value: 'action_on_exe_start_name', width:"20%",option:[], 
            //                 'item_text':'action', 'item_value':'action', useAutoComplete:true},
            //         { text: 'Action On End', value: 'action_on_exe_end_name', width:"20%",option:[], 
            //                 'item_text':'action', 'item_value':'action', useAutoComplete:true}                                                  
            //     ], 
            //     actions: [
            //         {'text': 'edit', 'key': "id", selectType:"single", role:true, index:1},
            //         {'text': 'new', 'key': "id", 'enabled':this.Environment, role:true, index:2},
            //         {'text':"save","key":"is_row_editable", selectType:"single", index: 3},
            //         {'text':"cancel","key":"is_row_editable", selectType:"single", index: 4},
            //         {'text': 'delete', 'key': "id", selectType:"single", role:true, index:5},
            //     ],//if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     is_row_edit:true,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll:false,
            //     is_grid_inline_edit:true,
            //     itemkey:'id'
            // },
            headers: [
                    { text: 'Process Definition', value: 'object_name', width:"20%",option:[], 
                        'item_text':'object_name', 'item_value':'object_name', title: 'Process Definition Name', useAutoComplete:true},
                    { text: 'Environment', value: 'env_name', width:"20%",option:[], 
                        'item_text':'name', 'item_value':'name', title: 'Process Definition Environment Name', useAutoComplete:true},
                    { text: 'Job Calendar', value: 'calendar_name', width:"20%",option:[], 
                        'item_text':'name', 'item_value':'name', title: 'Process Definition Job Calendar Name', useAutoComplete:true},    
                    { text: 'Action On Start', value: 'action_on_exe_start_name', width:"20%",option:[], 
                            'item_text':'action', 'item_value':'action', useAutoComplete:true},
                    { text: 'Action On End', value: 'action_on_exe_end_name', width:"20%",option:[], 
                            'item_text':'action', 'item_value':'action', useAutoComplete:true}                                                  
                ], 
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },  
            snackbar:false,
            snackbartext:'',
            loader:false,            
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,            
        }},
        mounted(){
            this.tableList = this.getGridObj(this.headers,'id',true,{is_grid_inline_edit:true})
            this.getCalendarList();
            this.getProcessDefName();
            this.getEnvironmentList();
            this.getAllExecutionRule();
            this.getAllRule();
            this.tableList.actions =  [
                    {'text': 'edit', 'key': "id", selectType:"single", role:true, index:1},
                    {'text': 'new', 'key': "id", 'enabled':this.Environment, role:true, index:2},
                    {'text':"save","key":"is_row_editable", selectType:"single", index: 3},
                    {'text':"cancel","key":"is_row_editable", selectType:"single", index: 4},
                    {'text': 'delete', 'key': "id", selectType:"single", role:true, index:5},
                ]
            // this.tableList.actions = [{
            //     'text': 'edit',
            //     'key': "id",
            //     selectType:"single",
            //     role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, UPDATE_ACTION),
            //     index:1
            // },{
            //     'text': 'new',
            //     'key': "id",
            //     'enabled':this.Environment,
            //     role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, CREATE_ACTION),
            //     index:2,
            // },{
            //     'text':"save","key":"is_row_editable", selectType:"single", index: 3
            // },{
            //     'text':"cancel","key":"is_row_editable", selectType:"single", index: 4
            // },{
            //     'text': 'delete',
            //     'key': "id",
            //     selectType:"single",
            //     role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, DELETE_ACTION),
            //     index:5
            // }]
        },

        methods:{
            onValueUpdate(record){
                console.log(record.action_on_exe_start_name)
                if (record.action_on_exe_start_name == 'Terminate'){
                    record.action_on_exe_end_name = 'No Action'
                } else if (record.action_on_exe_start_name == 'Suspend' && !record.id){
                    record.action_on_exe_end_name = 'Resume'
                }
            },
            getCalendarList(){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var request_data = {'client_id': client_id}
            postToServer(_this, config.JOB_CALENDAR_URL + '/get_all_calendar', request_data).then(response => {
                this.tableList.headers[2].option = response;
                _this.loader = false;
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    // this.loader = null
                    // this.snackbar = true
                    // this.colorValue = 'error'
                    // this.snackbartext = error_response;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
            },
            getAllRule(){
                let url =  config.JOB_CALENDAR_URL + GET_ALL_EXECUTION_RULE_MAPPING;
                let inputJson={
                    'client_id':this.$session.get('client_id')
                }
                postToServer(this, url, inputJson).then(response  => {
                        this.tableList.rows = response;
                        this.tableList.total_count = response.length;
                        // this.getRuleList();
                    }).catch(error => {
                        // this.getRuleList();
                        this.tableList.rows =[];
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });
            },
            onNew(){
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'First fillup the open row';
                    return
                }

                this.tableList.rows.unshift({
                    'object_name':'',
                    'env_name':'',
                    'calendar_name':'',
                    'action_on_exe_start_name':'',
                    'action_on_exe_end_name':'',
                    'is_row_editable' : true,
                });
            },
            onDelete(record){  
                let url = config.JOB_CALENDAR_URL+ DELETE_EXECUTION_RULE_MAPPING;
                let inputJson = {id:record.id}
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        this.getAllRule();
                        // this.getRuleList();
                    }).catch(error => {
                        this.getAllRule();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            },
            onSave(record){
                if(!record.object_name || !record.env_name || !record.calendar_name || !record.action_on_exe_start_name || !record.action_on_exe_end_name){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'All fields are required';
                    return false
                }
                this.saveRule(record);            
            },
            saveRule(record){
            let envObj = _.find(this.tableList.headers[1].option, ['name', record.env_name]);
            let actionOnStartObj = _.find(this.tableList.headers[3].option, ['action', record.action_on_exe_start_name]);
            let actionOnEndObj = _.find(this.tableList.headers[4].option, ['action', record.action_on_exe_end_name]);
            let processDefObj = _.find(this.tableList.headers[0].option, ['object_name', record.object_name]);
            let calendarObj = _.find(this.tableList.headers[2].option, ['name', record.calendar_name]);
            if(envObj && calendarObj && processDefObj){
                let inputJson = {'client_id':this.$session.get('client_id'), 
                                'object_id':processDefObj.object_id, 
                                'object_version':processDefObj.object_version,
                                'object_name':processDefObj.object_name, 
                                'env_id':envObj.id+"", 
                                'env_name':envObj.name, 
                                'id':record.id,
                                'calendar_id':calendarObj.id,
                                'calendar_name':calendarObj.name,
                                'action_on_exe_start':actionOnStartObj.action_code,
                                'action_on_exe_start_name':actionOnStartObj.action,
                                'action_on_exe_end':actionOnEndObj.action_code,
                                'action_on_exe_end_name':actionOnEndObj.action
                            };
                    let url =  config.JOB_CALENDAR_URL;    
                    if(record.id)
                     url = url+ UPDATE_EXECUTION_RULE_MAPPING;
                    else
                     url = url+ ADD_EXECUTION_RULE_MAPPING;
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        this.getAllRule();
                    }).catch(error => {
                        // this.getRuleList();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            }
            },
            onCancel(){
                this.getAllRule()
            },
            getProcessDefName(){
                let _this = this;
            _this.loader = true; 
            var client_id = this.$session.get('client_id')
            getToServer(this, config.PUBLISHER_URL + GET_ALL_PUBLISHESD_PROCESS_DEF_LIST+client_id, true).then(response => {
                _this.loader = false;                
                response.unshift({"object_id": 'PD0','object_version':'0' , "object_name": "ANY"});
                this.tableList.headers[0].option = response;
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    this.tableList.headers[0].option = [];
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });             
        },
        getEnvironmentList(){
            let _this = this;
                environmentList(this).then(response=>{
                    if(response){
                        response.unshift({"id": 'ENV0', "name": "ANY"});
                        this.tableList.headers[1].option = response;
                    }
                },response=>{
                    this.ShowErrorMessage=true
                    this.ErrorMessage=response
                })
        },
        getAllExecutionRule(){
            let _this = this;
            _this.loader = true; 
            var client_id = this.$session.get('client_id');
            getToServer(this, config.JOB_CALENDAR_URL + GET_ALL_EXECUTION_RULE, true).then(response => {
                _this.loader = false;
                let actionOnStart = _.cloneDeep(response);
                let resumeIndex = _.findIndex(actionOnStart, ['action', 'Resume']);
                actionOnStart.splice(resumeIndex,1);

                let actionOnEnd = _.cloneDeep(response);
                let suspendIndex = _.findIndex(actionOnEnd, ['action', 'Suspend']);
                actionOnEnd.splice(suspendIndex,1);
               let terminateIndex =  _.findIndex(actionOnEnd, ['action', 'Terminate']);
               actionOnEnd.splice(terminateIndex,1);
                this.tableList.headers[3].option = actionOnStart;
                this.tableList.headers[4].option = actionOnEnd;
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    this.tableList.headers[2].option = [];
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        }
        }   
}
</script>
