<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Security Role</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onDownload="onDownload" @onNew="addRole" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import { GET_ALL_ROLE, EXPORT_ALL_ROLES, DELETE_SECURITY_ROLE} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION, UPDATE_ACTION, DELETE_ACTION} from "./../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import data from './metaData.js'
export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            userRole:'',
            // tableList: {
            //     headers: [
            //         { text: 'Role Name', value: 'role', width: '20%', title: 'Security Role Name' },
            //          { text: 'Role Type', value: 'role_type', width: '20%', title: 'Security Role Type' },
            //         { text: 'Application List', value: 'service_list', width: '60%', title: 'Security Application List' }

            //         // { text: 'Environment Type', value: 'type', width: '20%' },
            //     ],
            //     actions:[],
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll:false,
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    { text: 'Role Name', value: 'role', width: '20%', title: 'Security Role Name' },
                     { text: 'Role Type', value: 'role_type', width: '20%', title: 'Security Role Type' },
                    { text: 'Application List', value: 'service_list', width: '60%', title: 'Security Application List' }

                    // { text: 'Environment Type', value: 'type', width: '20%' },
                ],
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true, 
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 5
                },
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            page: 1,
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList = this.getGridObj(this.headers,'role')
        this.tableList.actions=[{'text':'new','key':"role", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION),index:1},    
                
                {'text':'edit','key':"role", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,UPDATE_ACTION),index:2},
                // {'text':'delete','key':"role", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,DELETE_ACTION),index:3}
                ];
        this.getAllRole();
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.Security_URL+EXPORT_ALL_ROLES;    
            postToServer(this, url, inputJson).then(s_Response => {
                var url = config.Security_URL + "/static/" + s_Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
          })
        },
        getAllRole() {
            var client_id = this.$session.get('client_id')
            var url = config.Security_URL + GET_ALL_ROLE;
            let data = {"client_id":client_id}
            postToServer(this, url,data).then(Response => {
                
                if(Response)
                this.tableList.rows = Response;                
            });
        },
        addRole(){
            let _this = this;
        _this.$router.push('/ManageRole');
        
        },
        onEdit:function(record){
            let _this = this;
            debugger
            if(record.role === "superadmin" && record.role_type === "administrator"){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'User cannot edit superadmin role';
                return;
            }
            // this.$router.push({ name: 'manageenvironment', params: { environment_id: record.id, type:'edit' }})
            this.$router.push({ name: 'ManageRole', params: { role_id: record.role_id, role_type: record.role_type }});
        },
        onDelete:function(record){
            let _this = this
            _this.loader = true;
            var role_id = record.role_id
            var delete_object_url = config.Security_URL + DELETE_SECURITY_ROLE + role_id
            getToServer(_this, delete_object_url).then(response => {
            _this.snackbar = true
            _this.loader = false;
            _this.colorValue = 'success'
            _this.snackbartext = response;
            this.getAllRole()
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            }
            else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
                }
            });
        }
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>