<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>EDC Instance Details</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js';
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            loader: "loading",
            snackbartimeout: Snackbar_Default_Timeout,
            loading: false,
            tableList:{
            headers: [{text: 'Instance Name', value: 'instance_name', width:"20%", useTextField:true,'item_text':'instance_name', 'item_value':'instance_name'},
                      {text: 'Instance IP', value: 'consul_ip', width:"20%", useTextField:true,'item_text':'consul_ip', 'item_value':'consul_ip'},
                      {text: 'Created By', value: 'created_by', width:"20%", 'item_text':'created_by', 'item_value':'created_by'},
                      {text: 'Created Date', value: 'created_date', width:"20%",'item_text':'created_date', 'item_value':'created_date'},
                      {text: 'Modified By', value: 'modified_by', width:"20%", 'item_text':'modified_by', 'item_value':'modified_by'},
                      {text: 'Modified Date', value: 'modified_date', width:"20%",'item_text':'modified_date', 'item_value':'modified_date'}], 
            actions: [],//if we need conditional action in row then provide key
            sorting_type: CLIENT_SIDE,
            filterType: CLIENT_SIDE,
            paginationType: CLIENT_SIDE,
            total_count: 0,
            is_row_editable:true,
            rows: [],
            selected_rows: [],
            syncHeaderScroll:false,
            disableDefaltSorting:true,
            itemkey:'id_1',
            is_grid_inline_edit:true
            },
        }
    },
    mounted(){
        this.tableList.actions = [
        {'text':'edit','key':"id_1", selectType:"single", role:true, index:1},
        {'text':'new','key':"id_1", role:true, index:2},    
        {'text':"save","key":"is_row_editable",selectType:"single", index:3},
        {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
        {'text':'delete','key':"id_1", selectType:"single", role:true, index:5}];
        this.GetAllInstances();
    },
    methods:{
        GetAllInstances(){
            var _this = this
            var data_to_send = {"client_id": _this.$session.get('client_id')}
            postToServer(_this, config.ACCOUNT_MANAGEMENT_URL+"/all_instance_details_by_client_id", data_to_send).then(response=>{
                if(response){
                    _this.tableList.rows = response
                }
                else{
                    _this.tableList.rows = []
                }
            }).catch(errorresponse=>{
                _this.tableList.rows = []
            })
        },
        onNew() {
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
            this.tableList.rows.unshift({
                'instance_name':'',
                'consul_ip': '',
                'is_row_editable': true,
            });
        },
        onSave(record){
            if(!record.instance_name || !record.consul_ip){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            let instance_data = {
                "id": record.id_1,
                "client_id": this.$session.get('client_id'),
                "instance_name": record.instance_name,
                "remote_instance_consul_host": record.consul_ip
            }
            record.id_1 ? this.updateEdcInstance(instance_data) : this.saveEdcInstance(instance_data);
        },
        saveEdcInstance(instance_data){
            var url = config.ACCOUNT_MANAGEMENT_URL+"/add_instance_details"
            postToServer(this, url, instance_data).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "EDC Instance added successfully";
                this.GetAllInstances();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },
        updateEdcInstance(instance_data) {  
            var url = config.ACCOUNT_MANAGEMENT_URL + '/update_instance_details'
            postToServer(this, url, instance_data).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "EDC Instance updated successfully";
                this.GetAllInstances();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },
        onCancel(){
            this.GetAllInstances();
        },
        onDelete(record) {
            let inputJson = {
                'id':record.id_1,
                "client_id": this.$session.get('client_id')
            }
            let url = config.ACCOUNT_MANAGEMENT_URL + '/delete_instance_details'              
            postToServer(this, url, inputJson).then(response  => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'EDC Instance deleted successfully';
                this.GetAllInstances();
            }).catch(error_response => {
                this.GetAllInstances();
                if(error_response){
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });              
        },
    }
}
</script>