<template>
    <div>
        <v-layout row wrap pl-4 class="breadcrumbBackground">
            <v-flex xs8 style="margin:auto">
                <v-layout row wrap>
                    <v-flex xs4 pl-3>
                        <ul class="breadcrumb">
                            <li>
                                <router-link to="/userlist">User List</router-link>
                            </li>
                            <li>User History</li>
                        </ul>
                    </v-flex>
                     <v-flex xs8>
                    </v-flex>
                </v-layout>                
            </v-flex>
            <v-flex xs4 text-xs-right pr-4 pb-1 style="">
               
            </v-flex>
        </v-layout>
        <v-card align-center>
            <v-card-title primary-title>
                <v-toolbar dark dense absolute height="35px">
                    <v-flex dark dense absolute  class="text-md-left">User History Details</v-flex>
                </v-toolbar>
            </v-card-title>
            <v-data-table
                :items="user_details"
                class="elevation-1 text-md-left"
                hide-actions
                hide-headers
               >
                <template slot="items" slot-scope="props">
                    <td class="text-md-left" width="500px">{{ props.item.name }}</td>
                    <td class="text-md-left">{{ props.item.value }}</td>
                </template>
            </v-data-table>
        </v-card>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
            {{ snackbartext }}
        </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>
<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, deleteFromServer, post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from './../../data/client_message.js'
import {
    GET_USER_HISTORY
} from './../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from './../../data/macros.js'
import _ from 'lodash'
import dataTable from './../../views/dataTable/datatable.vue'
import loadingPanel from './../../views/loading.vue'
import cloneDeep from 'lodash/cloneDeep';
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    USER_PROVISIONING_SERVICE_ID,
    CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'

export default {
    name: 'ClientJobListNew',
    data: function () {
        return {
        	user_details: [],
			created_date: '',
			last_login_date: '',
			failed_login_attempt_count: '',
			user_pwd_expired: '',
			verification_status: '',
            loader: false,
            snackbar: false,
            snackbartext: '',
            snackbartimeout: 5000,
            colorValue: 'success',
            valid: true,
        }
    },
    components: {
        'vc-data-table': dataTable,
        'loading-panel': loadingPanel
    },
    mounted() {
        this.getUsersHistoryDetails();
    },
    methods: {
        getUsersHistoryDetails() {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var data = {'username': this.$route.params.username}
            postToServer(_this, config.USER_PROVISION_URL + GET_USER_HISTORY, data).then(response => {
                _this.loader = false;
                if (response) {
                	var data = response;
			        var foo = []
                    foo.push()
                    var user_pwd_expired = response.user_pwd_expired ? 'Yes': 'No'
                    if(response.is_locked){
                    	var verification_status = "By invalid login attempts"
                    }else{
                    	var verification_status = response.verification_status == 'Inactive' ? 'By another user':'No'
                    }
                    foo.push({'name': 'Created Date', 'value': response.created_date},
                        {'name': 'User logins', 'value': response.last_login_date},
                        {'name': 'User invalid password attempts', 'value': response.failed_login_attempt_count},
                        {'name': 'User password expired', 'value': user_pwd_expired}, 
                        {'name': 'User disabled', 'value': verification_status})
                    this.user_details = foo
                }
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
    }
}
</script>