<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                        <li>
                            <select v-model="Environment" v-on:change="onEnvironmentChange" style="-webkit-appearance: menulist" autofocus ref="envname">
                            <option :value="{}">Select Environment</option>
                            <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                            </select>
                        </li>
                        <li>Job Status</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row" style="padding-top:8px !important;">
                    <v-col class="EDC-Col">
                        <v-card>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-tabs v-model="activeTab" non-linear @change="tabChange">
                                        <v-tab class="EDC-Tabs" key="tab1">
                                            <v-badge :content="activeJobCount" inline bordered v-if='activeJobCount>0'>
                                                <span>Active Jobs</span>
                                            </v-badge>
                                            <span v-else>Active Jobs</span>
                                        </v-tab>
                                        <v-tab class="EDC-Tabs" key="tab2">
                                            <v-badge :content="waitingJobCount" v-if="waitingJobCount>0" inline bordered>
                                                <span>Waiting Jobs</span>
                                            </v-badge>
                                            <span v-else>Waiting Jobs</span>
                                        </v-tab>
                                        <v-tab class="EDC-Tabs" key="tab3">
                                        <!-- Nirav asked to chnage Job's History to Completed Jobs on dated 15-05-2020 -->
                                            Completed Jobs
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="activeTab">
                                        <v-tab-item>
                                            <edc-active-job :changedEnvironment="Environment" @onActiveJobCountUpdated="activeJobCount=$event" @newScheduleJobFired="newScheduleJobActivited=$event">

                                            </edc-active-job>
                                        </v-tab-item>
                                        <v-tab-item eager>
                                            <edc-waiting-job :changedEnvironment="Environment" :newScheduleJobActivited="newScheduleJobActivited" @onWaitingJobCountUpdated="waitingJobCount=$event">
                                                
                                            </edc-waiting-job>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <edc-completed-job :changedEnvironment="Environment"></edc-completed-job>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import activeJobs from './activeJobs.vue'
    import waitingJobs from './waitingJobs.vue'
    import completedJobs from './completedJobs.vue'
    export default{
        name:'JobStatus',
        mixins:[mixinJobManagement],
        components:{
            'edc-active-job':activeJobs,
            'edc-waiting-job':waitingJobs,
            'edc-completed-job':completedJobs
        },
        props:{
            
        },
        data(){
            return{
                activeTab:0,
                activeJobCount:0,
                waitingJobCount:0,
                newScheduleJobActivited:{}
            }
        },
        watch:{
            'activeJobCount':{
                handler(newValue){

                }
            },
            'waitingJobCount':{
                handler(newValue){

                }
            },
        },
        mounted(){
            this.activeTab=0
        },
        methods: {
            onEnvironmentChange(){

            },
            tabChange(){
                
            }
            
        }, 
    }
</script>