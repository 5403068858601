<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Service Report Details</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onDownload="onDownload"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>
<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, post as postToServer} from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import { CREATE_SERVICE_REPORT, EXPORT_SERVICE_REPORT_DATA} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "./../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import data from './metaData.js'

export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            loader:false,
            userRole:'',
            // tableList: {
            //     headers: [{
            //         text: 'Service Name',
            //         value: 'service_name',
            //         width: '25%',
            //         title: 'Service Name'},
            //         {
            //         text: 'Release Version', 
            //         value: 'release_version', 
            //         width: '25%', 
            //         title: 'Service relase version'},
            //         {
            //         text: 'Release Date', 
            //         value: 'release_date', 
            //         width: '25%', 
            //         title: 'Service Release Date',
            //         dataType:'date'},
            //         {
            //         text: 'Deploy Date', 
            //         value: 'deploy_date', 
            //         width: '25%', 
            //         title: 'Service Deploy Date',
            //         dataType:'date'}],
            //     actions:[],
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     hideSelect:true,
            //     hideShowColumns:true,
            //     // syncHeaderScroll:false,
            //     selected_rows: [],
            //     disableDefaltSorting:true,
            // },
            // //  flag:true,
            //  snackbar:false,
            //  snackbartext:'',
            //  colorValue:'success',
            //  showEnvType:false,
            //  valid:true, 
            //  sorting : config.SORTING_TYPE,
            // envIpJson : {
            //         "filter": [],
            //         "sort": {
            //             "column": "",
            //             "type": ""
            //         },
            //         "page": 1,
            //         "page_size": 5
            //     },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [{
                    text: 'Service Name',
                    value: 'service_name',
                    width: '25%',
                    title: 'Service Name'},
                    {
                    text: 'Release Version', 
                    value: 'release_version', 
                    width: '25%', 
                    title: 'Service relase version'},
                    {
                    text: 'Release Date', 
                    value: 'release_date', 
                    width: '25%', 
                    title: 'Service Release Date',
                    dataType:'date'},
                    {
                    text: 'Deploy Date', 
                    value: 'deploy_date', 
                    width: '25%', 
                    title: 'Service Deploy Date',
                    dataType:'date'}],
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            page: 1,
        }
    },
    mounted() {
        this.createServiceReport();
        this.tableList = this.getGridObj(this.headers,'id',true,{showSelect : false})

    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                'service_id_list': [],
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
            }
            var url = config.AUTHENTICATION_URL+EXPORT_SERVICE_REPORT_DATA;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.AUTHENTICATION_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        createServiceReport() {
            let _this = this; 
            _this.loader=true;
            var url = config.AUTHENTICATION_URL + CREATE_SERVICE_REPORT
            var data_to_send = {'service_id_list': [], 'client_id': this.$session.get('client_id')}
            postToServer(_this, url, data_to_send).then(Response => {
                
                if(Response.product){
                var product = {}
                product = Response.product
                delete Response.product;
                }
                var keys = _.keys(Response)
                var service_data = []
                for (var i = 0; i < keys.length; i++) {
                    //check for message key. If json contain message key, its means something wrong with the one of the service
                    if(!Response[keys[i]].message){
                        Response[keys[i]].service_name = _.capitalize(Response[keys[i]].service_name).replace(/_/g, ' ');
                        service_data.push(Response[keys[i]])
                    }
                }
                service_data = _.reverse(_.sortBy(service_data,"release_date"))
                service_data.unshift({"service_name":"UI","release_version":config.RELEASE_VERSION,"release_date":config.RELEASE_DATE, "deploy_date":service_data.length > 0?service_data[0].deploy_date:"N/A"})
                 if(product){
                product.service_name = _.capitalize(product.service_name).replace(/_/g, ' '); 
                service_data.unshift(product)}
                this.tableList.rows = service_data;
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>