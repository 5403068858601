<template>
  <div>
    <v-layout
      row
      wrap
      pl-3
      style="background-color:RGB(224,232,247)"
    >
      <v-flex
        xs5
        style="margin: auto;"
      >
        <ul class="breadcrumb">
          <li><a href="/dashboard">Home</a></li>
          <li>Calender</li>
          <li>
            <select
              v-model="calendarDetails"
              autofocus
              style="-webkit-appearance: menulist;"
              @change="getEventList"
            >
              <option :value="{}">
                Select Calender
              </option>
              <option
                v-for="(cal, index) in calenderList"
                :key="index"
                :value="cal"
              >
                {{ cal.name }}
              </option>
            </select>
          </li>
        </ul>
      </v-flex>
      <v-flex
        xs7
        row
        justify-end
      >
        <v-flex xs6 />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        pl-3
        pr-2
      >
        <vc-data-table
          :data="tableList"
          @onEdit="onEdit"
          @onNew="onNew"
          @onDelete="onDelete"
          @onCopy="onCopy"
        />
      </v-flex>
    </v-layout>
  </div>    
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_ALL_CALENDER, GET_ALL_EVENT} from '@/data/url_constants.js'
import dataTable from '../../views/dataTable/datatable.vue'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import config from '../../config.json'
export default {
    name: 'EventList',
    components: {
        'vc-data-table': dataTable
    },
    data: function () {
        return {
            calendarDetails: this.$store.state.calendarDetails,
            calenderList: [],
            tableList: {
                headers: [{
                        text: 'event name',
                        value: 'params.name',
                        disabled: true,
                        width: '22%'
                    },
                    {
                        text: 'event start time',
                        value: 'params.start_time',
                        disabled: true,
                        width: '30%'
                    },
                    {
                        text: 'event end time',
                        value: 'params.end_time',
                        inputKey: 'valueType',
                        inputValue: 'date',
                        width: '18%'
                    },
                    {
                        text: 'timezone',
                        value: 'params.timezone',
                        inputKey: 'parameter',
                        inputValue: 'mode',
                        dataType: 'toggle',
                        disabled: true,
                        width: '15%'
                    },
                    {
                        text: 'event type',
                        value: 'params.is_recurring',
                        inputKey: 'parameter',
                        inputValue: 'mode',
                        dataType: 'toggle',
                        disabled: true,
                        width: '15%'
                    }
                ],
                actions: [{
                        'text': 'edit',
                        'key': "",
                        selectType: "single",
                        role: true
                    },
                    {
                        'text': 'new',
                        'role':this.calendarDetails,
                        'key': "",
                        role: true
                    },
                    {
                        'text': 'copy',
                        'key': '',
                        selectType: "single",
                        role: true
                    },
                    {
                        'text': 'delete',
                        'key': "",
                        selectType: "single",
                        role: true
                    }
                ], //if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                select_rows: true,
                syncHeaderScroll: false,
            },
        }
    },
    mounted() {
        this.getCalenderList();
        if(this.calendarDetails){
            this.getEventList();
        }
    },
    methods: {
        getCalenderList() {
            let inputParams = {
                "client_id": this.$session.get('client_id')
            }
            postToServer(this, config.JOB_CALENDAR_URL + GET_ALL_CALENDER, inputParams).then(response => {
                this.calenderList = response;
            })
        },
        getEventList() {
            this.$store.state.calendarDetails = this.calendarDetails;
            let inputParams = {
                "calendar_id": this.calendarDetails.id
            }
            postToServer(this, config.JOB_CALENDAR_URL + GET_ALL_EVENT, inputParams).then(response => {
                this.tableList.rows = response;
                this.tableList.rows.map(obj => {
                    obj.params.is_recurring = obj.params.is_recurring !== 'false' ? 'Recurring' : 'One Time';
                    obj.params.start_time = obj.params.event_start_time.HH + ':' + obj.params.event_start_time.mm;
                    obj.params.end_time = obj.params.event_end_time.HH + ':' + obj.params.event_end_time.mm;
                })
            },error=>{
                this.tableList.rows = [];
            })
        },
        onNew() {
            if(this.calendarDetails)
            this.$router.push({
                name: 'managejobcalendar',
                'params': {
                    calendarDetails: this.calendarDetails
                }
            });
        },
        onEdit(record) {
            this.$router.push({
                name: 'managejobcalendar',
                    'params': {
                    eventDetails: record,
                    calendarDetails: this.calendarDetails,
                    type: 'edit'
                }
            });
        },
        onCopy(record) {
            this.$router.push({
                name: 'managejobcalendar',
                'params': {
                    eventDetails: record,
                    calendarDetails: this.calendarDetails,
                    type: 'copy'
                }
            });
        },
        onDelete(){

        }
    },
}
</script>

