<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-data-table dense border="0px" :items="jobSummary" :items-per-page='15' class="elevation-1" hide-default-header hide-default-footer>
                            <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="(item,index) in items" :key="index+'summary'" >
                                    <td class="text-md-left" width="200px">{{ item.name }}</td>
                                    <td class="text-md-left">{{ item.value }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    </v-container>
</template>
<style scoped>
    tr:nth-child(even) {background-color: #E8E8E8;}
</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import {post as postToServer } from '../../../methods/serverCall.js';
    import config from '../../../config.json'

    export default{
        name:"JobSummary",
        mixins:[mixinJobManagement],
        props:{
            jobId:{
                type:Number,
                default:0
            },
            changedJobDetails:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            jobPlanName:{
                type:String,
                default:''
            }
        },
        data(){
            return{
                jobSummary:[],
                jobType:''
            }
        },
        computed: {
            isFinalModeJob(){
                return this.jobMode === "final"
            }
        },
        watch:{
            'jobId':{
                handler(newValue){
                    this.getJobSummary()
                }
            },
            'changedJobStatus':{
                handler(newValue){

                }
            },
            'jobPlanName':{
                handler(newValue){

                }
            },
            '$store.state.liveStatus':{
                handler(newValue){
                    if(this.jobId === newValue.job_id){
                        this.jobSummary[2].value = newValue.status.charAt(0).toUpperCase() + newValue.status.slice(1)
                        if(newValue.end_date && newValue.end_date!="None")
                            this.jobSummary[5].value = newValue.end_date+" "+newValue.end_time
                    }
                }
            },
        },
        mounted(){
            this.getJobSummary()
        },
        methods: {
            getJobSummary(){
                var _this = this
                if(!this.jobId)
                    return
                var data ={"job_id":this.jobId,env_id:this.environmentId}
                this.loader = true
                postToServer(this, config.ENGINE_API_URL + '/get_job_details', data).then(response => {
                    _this.loader = false
                    var keys  = Object.keys(response)
                    var dataList = []
                    for (var i = 0; i <keys.length; i++) {
                        if(keys[i] == "job_type"){
                            var job_type = "Normal"
                            if(response.is_restored)
                                job_type="Restoration"
                            if(job_type === "Normal" && response.job_type === "sync_up")
                                job_type = "Sychronization"
                            if(job_type === "Restoration" && response.job_type === "sync_up")
                                job_type = "Un-Sychronization"
                            this.jobType = job_type
                            dataList.push({"name":"Job Type","value":job_type,"index":9})
                        }
                        if(keys[i] === 'activity_ids' && response.activity_ids){
                            dataList.push({"name":"Activity Ids","value":response.activity_ids,"index":10})
                        }
                        if(keys[i] === "process_definition_version")
                            dataList.push({"name":"Process Definition Version","value":response.process_definition_version ? response.process_definition_version:'N/A',"index":2})
                        if(keys[i] === "mode_of_execution"){
                            var mode = response.mode_of_execution.charAt(0).toUpperCase()+response.mode_of_execution.slice(1)
                            dataList.push({"name":"Run Mode","value":mode,"index":3})
                        }

                        // if you change this index then also make change in live status watch
                        if(keys[i] === "status"){
                            var status = response.status.charAt(0).toUpperCase()+response.status.slice(1)
                            dataList.push({"name":"Status","value":status,"index":4})
                        }

                        if(keys[i] === 'policy_dates')
                            dataList.push({"name":"Policies","value":response.policy_dates ? response.policy_dates:'N/A',"index":5})

                        if(keys[i] === "start_date")
                            dataList.push({"name":"Start Date & Time","value":response.start_date +' '+response.start_time,"index":6})

                        if(keys[i] === "end_date")
                        {
                            var end_date = 'NA'
                            if(response.end_date)
                                end_date = response.end_date +' '+response.end_time
                            dataList.push({"name":"End Date & Time","value":end_date,"index":7})
                        }
                        if(keys[i] === "total_running_time"){
                            dataList.push({"name":"Processing Time","value":response.total_running_time,"index":8})
                        }
                        if(keys[i] === "duration"){
                            dataList.push({"name":"Duration","value":response.duration,"index":9})
                        }

                        if(keys[i] === "created_by")
                            dataList.push({"name":"Initiated By","value":response.created_by,"index":10})

                        if(keys[i] === "archives_id")
                            dataList.push({"name":"Generated activity ids", "value":response.archives_id, "index":12})

                    }
                    if(this.jobPlanName){
                        dataList.push({"name":"Plan Name","value":this.jobPlanName,"index":11})
                    } else{
                        dataList.push({"name":"Plan Name","value":"N/A","index":11})
                    }
                    this.jobSummary = _.sortBy(dataList,"index")
                    console.log(response)
                    this.$emit("manageJobTabs",{
                        "hasBusinessTables":response.is_exists_bo_table_data || false,
                        "hasSummarizationRecords":response.is_exists_summ_count_data || false,
                        "jobType":this.jobType
                    })
                }).catch(error=>{
                    _this.showMessage(error)
                })

            }
        },

    }
</script>