<template>
  <div id="addCalendar">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow" align="center">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/calendar">Job Calendar</router-link>
              </li>
              <li>Manage Calendar</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="add_user_form" v-model="valid">
        <v-card elevation-1>
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col">
              <v-row class="EDC-Row EDC-TopRow">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Name of Calendar" v-model="name" ref="calendarName" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="description" label="Description" :rules="descRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColAutocomplete">
                  <v-autocomplete v-model="job_initialization_rule" :items="jobInitializationLule" item-text="action" item-value="action_code" label="Job Initialization Action on Blackout Time" :rules="calendarRules" dense/>
                </v-col>
              </v-row>
                
              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                <vc-button :loading="loading" :disabled="loading" itemText="Save" @click.native="submit"></vc-button>
                <vc-button itemText="Cancel" @click.native="onCancel()"></vc-button>
              </v-row>
            </v-col>
          </v-container>

          <v-row no-gutters class="EDC-Row">
            <span class="EDC-Heading">Calendar Event List</span>
          </v-row>

          <edc-data-grid :dataList="tableList" @onEdit="onEdit" @onValueUpdate="onValueUpdate" @onNew="onNew" @onDelete="onDelete" @onCopy="onCopy"/>
            
        </v-card>
      </v-form>
    </v-container>

    <simplert ref="simplert" :use-radius="true" :use-icon="true"/>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<style scoped>
  .v-text-field.v-text-field--solo .v-input__control{
    min-height: 20px;
  }

  /*#addCalendar .v-input--checkbox, label {
    margin-top:2%;
  }*/

  .EDC-TopRow{
    padding-top: 20px !important;
  }

  .EDC-ColAutocomplete{
    padding-top: 2px !important;
  }
</style>

<script>
import Simplert from 'vue2-simplert'
import config from "../../config.json"
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ADD_USER, GET_USER,MANAGE_EVENT_ACTIVE, UPDATE_USER, GET_ALL_EVENT, GET_EVENT, JOB_INITIALIZATION_RULE} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'    
import {SERVER_ERROR} from '@/data/client_message.js'
import cloneDeep from 'lodash/cloneDeep'
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    components: {
        Simplert,
    },
  data () {
      return {
        job_initialization_rule:"",
			  jobInitializationLule:[],
        calendarDetails: this.$store.state.calendarDetails,
        tableList: {
            headers:[],
            rows:[],
            actions:[]
        },
        headers: [{
                text: 'Name',
                value: 'name',
                width:'10%',
            },{
                text: 'Type',
                value: 'is_recurring',
                width:'7%',
                // cssClass:'w16'
            },
            {
                text: 'Description',
                value: 'description',
                width:'50%',
                // cssClass:'w16'
            },{
                text: 'Next Occurrence',
                value: 'next_occurrence',
                width:'17%',
                // cssClass:'w16'
                hideInlineFilter: true
            },{
                text: 'Status',
                value: 'status',
                width:'10%',
                // cssClass:'w16'
            },
            {
                text:'Is Active',
                value:'is_active',
                dataType:'toggleInput',
                checked:'Active',
                unchecked:'InActive',
                hideInlineFilter: true,
                itemkey:"id"
            }  
        ],
        actions: [], //if we need conditional action in row then provide key
                
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader: null,
        loading: false,
        show1: false,
        show2: false,
        valid: true,
        name: "Untitled Name",
        description: "",
        nameRules:[
          v => !!v || 'Name is required'
        ],
        descRules: [
          v => !!v || 'Description is required'
        ],
        calendarRules: [
          v => !!v || 'Job Initialization Action on Blackout Time is required'
        ],
        logintype:false,
        calendar_rule: '',
        calendar_rule_list: [],
        calendar_rule_map_id: '',
        checkbox: false
      }
    },
    mounted () {
      /* if(this.$store.state.eventList && this.$route.params.manageJobCalendar){
          if(this.$route.params.eventDetails){
            let obj = this.$route.params.eventDetails;
            obj.is_recurring = obj.is_recurring !== 'false' ? 'Recurring' : 'One Time';
            obj.exception_start_time = obj.exception_start_time.HH + ':' + obj.exception_start_time.mm;
            obj.exception_end_time = obj.exception_end_time.HH + ':' + obj.exception_end_time.mm;
            this.tableList.rows.push(obj);
          }
      }else */ 
      /* if(this.$route.params.calendarDetails){
        } */
      this.tableList =  this.getGridObj(this.headers, 'id')
      this.actions = [
        {
                'text': 'edit',
                'key': "eligible_for_edit",
                'cmpValue':"Y",
                selectType: "single",
                role: true,
                index:1
            },
            {
                'text': 'new',
                'role':this.calendarDetails,
                'key': "id",
                role: true,
                index:3
            },{
                'text': 'delete',
                'key': "id",
                selectType: "single",
                role: true,
                  index:2
            }
        ]
      this.tableList.actions = this.actions
      
      if(this.$route.params.calendarDetails){
        this.setEventList(this.$route.params.calendarDetails);
        this.name = this.$route.params.calendarDetails.name;
        this.description = this.$route.params.calendarDetails.description;
        this.job_initialization_rule = this.$route.params.calendarDetails.job_initialization_rule;
      }
      this.getInitializationList();

      if(this.$refs.calendarName.$el)
        this.$refs.calendarName.$el.focus()
      else this.$refs.calendarName.focus()
    },
    methods: {
        getInitializationList(){
          getToServer(this, config.JOB_CALENDAR_URL + JOB_INITIALIZATION_RULE).then(response => {
            this.jobInitializationLule = response;
          }).catch(error_response => {
              this.snackbar = true
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
          }); 
        },
        onValueUpdate(record){
          let index = _.findIndex(this.tableList.rows,['name', record.name]);
          this.tableList.rows[index].is_active = record.is_active;
          postToServer(this, config.JOB_CALENDAR_URL + MANAGE_EVENT_ACTIVE, record).then(response => {
            var data = response
          }).catch(error_response => {
            this.snackbar = true
            this.colorValue = 'error'
            this.tableList.rows[index].is_active = !record.is_active;
            this.snackbartext = error_response
          })
        },
        setEventList(object) {
            let inputParams = {
                "calendar_id": object.id
            }
            let _this = this;
            _this.tableList.rows=[];
            postToServer(this, config.JOB_CALENDAR_URL + GET_EVENT, inputParams).then(response => {
                if(response.calendar_exceptions)
                {
                  response.calendar_exceptions.map(obj => {
                    obj.is_recurring = obj.is_recurring ? 'Recurring' : 'One Time';
                    obj.exception_start_time = obj.exception_start_time.HH + ':' + obj.exception_start_time.mm;
                    obj.exception_end_time = obj.exception_end_time.HH + ':' + obj.exception_end_time.mm;
                    obj.eligible_for_edit = "Y"
                    if (obj.status && obj.status.toLowerCase() === 'in blackout'){
                      obj.eligible_for_edit = "N"

                    }
                    _this.tableList.rows.push(obj);
                  })
                }else{
                  _this.tableList.rows=[];
                }
            },error=>{

            })
        },
        onNew() {
          if(!this.$route.params.calendarDetails){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = "Please Save Calendar First";
            return;
          }
          this.$store.state.eventList = this.tableList.rows;
          if(this.$route.params.calendarDetails){
              this.$store.state.calendarDetails = this.$route.params.calendarDetails; 
            }else{
              this.calendarDetails={
                'name':this.name,
                'description':this.description
              }
              this.$store.state.calendarDetails = this.calendarDetails; 
            }
            this.$router.push({
              name: 'managejobcalendar',
              'params': {
                  calendarDetails: this.calendarDetails
              }
            });
        },
        onEdit(record) {
          this.$store.state.eventList = this.tableList.rows;
          if(this.$route.params.calendarDetails){
              this.$store.state.calendarDetails = this.$route.params.calendarDetails; 
            }else{
              this.calendarDetails={
                'name':this.name,
                'description':this.description
              }
              this.$store.state.calendarDetails = this.calendarDetails; 
            }
            this.$router.push({
                name: 'managejobcalendar',
                    'params': {
                    eventDetails: record,
                    calendarDetails: this.calendarDetails,
                    previousRecord:cloneDeep(record),
                    type: 'edit'
                }
            });
        },
        onCopy(record) {
            this.$store.state.calendarDetails = this.$route.params.calendarDetails; 
            this.$router.push({
                name: 'managejobcalendar',
                'params': {
                    eventDetails: record,
                    calendarDetails: this.calendarDetails,
                    type: 'copy'
                }
            });
        },
        onDelete(record){
           let _this = this;        
           if(!record.id){
             let index = _.findIndex(_this.tableList.rows,['name',record.name]);
             _this.tableList.rows.splice(index,1);
             return;
           }    
            var deleteData = {"id": record.id}
            postToServer(_this, config.JOB_CALENDAR_URL + '/remove_calendar_exception', deleteData).then(response => {
                // _this.getEventList();
                _this.setEventList(_this.$route.params.calendarDetails);
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        },
      onCancel(){
         this.$router.push("/calendar")
      },
      submit () {
        if (this.$refs.add_user_form.validate()) {
          if(this.logintype && !this.ldap_login_name){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'LDAP login name required';
            return false
          }
          if(this.$route.params.calendarDetails && this.$route.params.calendarDetails.id && this.$route.params.type == 'edit'){
            this.updateCalendar(true)
          } else {
            this.addCalendar(true)
          }
        }
      },
      clear () {
        this.$refs.add_user_form.reset()
      },
      untitle_calendar(){
      if (!this.name || !this.name.trim()){
        this.name = 'Untitled Calendar';
      }
    },
    ShowConfirm(object){
        let confirmFn = function () {
        if (object.id) {
          this.$parent.updateCalendar(false)
        } else {
          this.$parent.addCalendar(false)
        }
        }
        let obj = {
          title: 'Update Calendar',
          message: 'Default calendar already exists, This action will make it not default, Proceed?',
          type: 'info',
          useConfirmBtn: true,
          onConfirm: confirmFn
        }
        this.$refs.simplert.openSimplert(obj)
      },
      addCalendar(check_default) {
        let l = this.loader;
        this[l] = !this[l]
          var Authorization = this.$session.get('access_token')
          var client_id = this.$session.get('client_id');
          var calendarData = {
            "name": this.name, 
            "client_id": client_id, 
            "description": this.description, 
            "created_by": this.$session.get('email'),
            "rule_id": this.calendar_rule.id,
            "job_initialization_rule":this.job_initialization_rule
            
          }
          postToServer(this, config.JOB_CALENDAR_URL + '/add_calendar', calendarData).then(response  => {
                if(response.status == 'WARN'){
                  this.ShowConfirm(calendarData)
                } else {
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = 'Calendar added successfully';
                  this.$cookies.set('calendar_add', true)
                  this.$router.push("/calendar")
                }
            }).catch(error_response => {
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this[l]  = false
              this.loader = null
            }
            else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
              
            }
      });
    },
    updateCalendar(check_default) {
        let l = this.loader
        this[l] = !this[l]
          var Authorization = this.$session.get('access_token')
          var client_id = this.$session.get('client_id')
         
           var updateData = {
            "id": this.$route.params.calendarDetails.id,
            "name": this.name, 
            "client_id": client_id, 
            "description": this.description, 
            "modified_by": this.$session.get('email'),
            "job_initialization_rule":this.job_initialization_rule}    
          postToServer(this, config.JOB_CALENDAR_URL + '/update_calendar', updateData).then(response  => {
                if(response.status == 'WARN'){
                  this.ShowConfirm(updateData)
                } else {
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = 'Calendar updated successfully';
                  this.$cookies.set('user_add', true)
                  this.$router.push("/calendar")
                }
            }).catch(error_response => {
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this[l]  = false
              this.loader = null
            }
            else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              this[l]  = false
              this.loader = null
              
            }
      });
      }
    }
  }
</script>