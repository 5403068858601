<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li v-if="$route.params.type == 'jobPlan'" >
                                <a  @click="backToJobPlan">{{environmentName}}</a>
                            </li>
                            <li v-else>
                                <span @click="$router.go(-1)"><a>{{environmentName}}</a></span>
                            </li>
                            <li v-if="$route.params.type == 'jobHistory'">
                                <router-link to="/currentjoblist">Job Status</router-link>
                            </li>
                            <li v-if="$route.params.type == 'currentJobList'">
                                <router-link to="/currentjoblist">Job Status</router-link>
                            </li>
                            <li v-if="$route.params.type == 'archive'">
                                <router-link to="/archives">Manage Archives</router-link>
                            </li>
                            <li v-if="$route.params.type == 'jobPlan'">
                                <a @click="backToJobPlan">Job Plan - [{{$route.params.job_plan_name}}]</a>
                            </li>
                            <li v-if="$route.params.type == 'manageRunningInstance'">
                                <a @click="backToManageRunningPlanInstance">Job Plan Running Instance - [{{$route.params.instance_id}}]</a>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row" style="margin-left:16px !important;margin-top:8px !important;">
                    <v-col class="EDC-Col">
                        <v-card>
                            <v-row class="EDC-Row" no-gutters>
                                <v-col class="EDC-Col" cols="12" align="start">
                                    <span class="EDC-Heading">{{jobName}} ({{jobId}})</span>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <v-tabs v-model="activeTab" non-linear @change="tabChanged">
                                        <v-tab class="EDC-Tabs" key="tab1">Summary</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab2" v-if="hasBusinessTables">Business Tables</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab3" v-if="hasSummarizationRecords">Summarization Details</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab4" v-if="hasFileArchiveDetails">File Archive Details</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab5">Logs</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab6" v-if="showReports">Reports</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab7" v-if="hasWorkTables">Work Tables</v-tab>
                                        <v-tab class="EDC-Tabs" key="tab8" v-if="hasStatistics">Statistic</v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="activeTab">
                                        <v-tab-item key="tab1">
                                            <v-row class="EDC-Row">
                                                <v-col cols="5">
                                                    <job-summary :jobId="jobId" :jobPlanName="jobPlanName" :changedJobDetails="changedJobDetails" @manageJobTabs="manageJobTabs">

                                                    </job-summary>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab-item key="tab2" v-if="hasBusinessTables">
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <business-tables :jobId="jobId" :jobMode="jobMode" :jobName="jobName"></business-tables>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab-item key="tab3" v-if="hasSummarizationRecords">
                                            <v-row class="EDC-Row">
                                                <v-col cols="6">
                                                    <summarization-details :jobId="jobId" :jobMode="jobMode" :jobType="jobType"></summarization-details>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab-item key="tab4" v-if="hasFileArchiveDetails">

                                        </v-tab-item>
                                        <v-tab-item key="tab5">
                                            <job-logs :jobId="jobId" :jobMode="jobMode" :jobName="jobName"></job-logs>
                                        </v-tab-item>
                                        <v-tab-item key="tab6" v-if="showReports">
                                            <job-report :jobId="jobId" :jobMode="jobMode" :jobName="jobName"></job-report>
                                        </v-tab-item>
                                        <v-tab-item key="tab7" v-if="hasWorkTables">
                                            <job-worktable :jobId="jobId" :jobName="jobName"></job-worktable>
                                        </v-tab-item>
                                        <v-tab-item key="tab8" v-if="hasStatistics">
                                            <job-statistic-graph :jobId="jobId"></job-statistic-graph>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import jobSummary from './jobSummary.vue'
    import businessTables from './businessTables.vue'
    import SummarizationDetails from './summarizationDetails.vue'
    import jobLogs from './jobLogs.vue'
    import jobReports from './jobReports.vue'
    import jobWorktables from './jobWorktables.vue'
    import jobStatisticsInGraphMode from './jobStatisticsInGraphMode.vue'
    export default{
        name:"jobDetails",
        mixins:[mixinJobManagement],
        components:{
            'job-summary':jobSummary,
            'business-tables':businessTables,
            'summarization-details':SummarizationDetails,
            'job-logs':jobLogs,
            'job-report':jobReports,
            'job-worktable':jobWorktables,
            'job-statistic-graph':jobStatisticsInGraphMode
        },
        data(){
            return{
                activeTab:0,
                hasBusinessTables:false,
                hasSummarizationRecords:false,
                hasFileArchiveDetails:false,
                jobPlanName:'',
                jobMode:'',
                jobName:'',
                jobId:0,
                jobStatus:'',
                jobType:'',
                isDuplicateFound:'N',
                showWorkTables:false,
                changedJobDetails:{},
                showReports:false
            }
        },
        computed:{
            hasWorkTables(){
                return this.jobStatus && (['completed','terminated'].indexOf(this.jobStatus.toLowerCase())===-1 || this.isDuplicateFound === 'Y' || this.showWorkTables)
            },
            hasStatistics(){
                return this.jobMode.toLowerCase() === 'final' && this.hasBusinessTables
            }
        },
        watch:{
            '$store.state.updatedRowCounts':{
                handler(newValue){
                    if(newValue && newValue.length && newValue[0].job_id === this.jobId && !this.hasBusinessTables)
                        this.hasBusinessTables = true
                }
            },
            '$store.state.liveStatus':{
                handler(newValue){
                    if(this.jobId === newValue.job_id)
                        this.jobStatus = newValue.status.charAt(0).toUpperCase() + newValue.status.slice(1)
                }
            }
        },
        mounted() {
            this.jobId = this.$route.params.jobid
            this.jobName = this.$route.params.jobname
            this.jobMode = this.$route.params.mode
            this.jobStatus = this.$route.params.status.charAt(0).toUpperCase() + this.$route.params.status.slice(1)
            this.isDuplicateFound = this.$route.params.isDuplicateFound
            this.showWorkTables = this.$route.params.showWorkTables
            this.jobPlanName = this.$route.params.jobPlanName
            this.showReports = this.$route.params.isReportAvailable==='Y'?true:false
        },
        methods: {
            backToJobPlan(){
                this.$router.push({ name: 'jobplaninstancedetails',params: this.$route.params});
            },
            backToManageRunningPlanInstance(){
                let _this = this;
                this.$router.push({ name: 'ManageJobPlanInstance',
                    params: {
                        job_plan_id: this.$route.params.job_plan_id,
                        job_plan_instance_id: this.$route.params.job_plan_instance_id,
                        job_plan_name:this.$route.params.job_plan_name,
                        env_id: this.environmentId,
                        env_name: this.environmentName
                    }
                });
            },
            manageJobTabs(tabProps){
                this.hasBusinessTables = tabProps.hasBusinessTables
                this.hasSummarizationRecords = tabProps.hasSummarizationRecords
                this.hasFileArchiveDetails= tabProps.hasFileArchiveDetails
                this.jobType = tabProps.jobType
            },
            tabChanged(){

            }
        },
    }
</script>