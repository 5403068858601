var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{EDCHeaderContainer:!_vm.isGrayTheme,EDCHeaderContainerGray:_vm.isGrayTheme}},[_c('v-row',{staticClass:"mb-12 topLines",attrs:{"align":"center","dense":"","no-gutters":""}},[(!_vm.isGrayTheme)?_c('v-col',{staticClass:"EDC-PageHeader primary--text",staticStyle:{"text-align":"left !important","padding-left":"30px !important"},attrs:{"cols":"2"}},[_vm._v(" archivist ")]):_c('v-col',{staticClass:"EDC-PageHeader graythmprimary--text",staticStyle:{"text-align":"left !important","padding-left":"30px !important"},attrs:{"cols":"2"}},[_vm._v(" archivist ")]),_c('v-col',{attrs:{"cols":"8"}},[(!_vm.hideHeader)?_c('v-row',{staticClass:"mb-12",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{class:{normal:_vm.isSelected !== 'Home',focus:_vm.isSelected == 'Home'},on:{"click":function($event){return _vm.routeUrl('/dashboard')}}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Home',focus:_vm.isSelected == 'Home','primary--text':_vm.isSelected==='Home' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Home'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Home' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Home' && _vm.isGrayTheme },attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Home',_vm.routeUrl('/dashboard')}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"5%"},attrs:{"name":"Menu_Home","original":true}}),_c('span',[_vm._v(" Home")])],1)]}}],null,false,744125431)})],1),(_vm.isMenuBelongToCurrentProduct('run'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Run',focus:_vm.isSelected == 'Run','red--text':_vm.isSelected == 'Run'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Run',focus:_vm.isSelected == 'Run','primary--text':_vm.isSelected==='Run' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Run'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Run' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Run' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Run'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"2%"},attrs:{"name":"Menu_Run","original":true}}),_c('span',[_vm._v(" Run")])],1)]}}],null,false,2907155778)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRunMenu),expression:"showRunMenu"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.run),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name)+" ")])],1):_vm._e()}),1)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('approve'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Approve',focus:_vm.isSelected == 'Approve'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Approve',focus:_vm.isSelected == 'Approve','primary--text':_vm.isSelected==='Approve' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Approve'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Approve' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Approve' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Approve'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"1%"},attrs:{"name":"Menu_Approve","original":true}}),_c('span',[_vm._v(" Approve")])],1)]}}],null,false,609128231)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.approve),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('data management'))?_c('v-col',{class:{normal:_vm.isSelected !== 'dataManagement',focus:_vm.isSelected == 'dataManagement'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'dataManagement',focus:_vm.isSelected == 'dataManagement','primary--text':_vm.isSelected==='dataManagement' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='dataManagement'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='dataManagement' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='dataManagement' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='dataManagement'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"2%"},attrs:{"name":"Menu_Data_Management","original":true}}),_c('span',[_vm._v(" Data Management")])],1)]}}],null,false,1250106647)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataManagementMenu),expression:"showDataManagementMenu"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.dataManagement),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('develop'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive','primary--text':_vm.isSelected==='Archive' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Archive'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Archive' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Archive' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Archive'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"Menu_Develop","original":true}}),_c('span',[_vm._v(" Develop")])],1)]}}],null,false,993704744)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDevlop),expression:"showDevlop"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.archive),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('configure'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Configure',focus:_vm.isSelected == 'Configure'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Configure',focus:_vm.isSelected == 'Configure','primary--text':_vm.isSelected==='Configure' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Configure'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Configure' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Configure' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Configure'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"1%"},attrs:{"name":"Menu_Configure","original":true}}),_c('span',[_vm._v(" Configurations")])],1)]}}],null,false,1958296747)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfigure),expression:"showConfigure"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.topNavigation.configure),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfigure),expression:"showConfigure"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Notification Group")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,3540732504)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.notificationGroup),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Rules")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,3834642199)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.rule),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Agent")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,2193607795)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.agent),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],2)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('admin'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Admin',focus:_vm.isSelected == 'Admin'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Admin',focus:_vm.isSelected == 'Admin','primary--text':_vm.isSelected==='Admin' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Admin'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Admin' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Admin' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Admin'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"User_Admin","original":true}}),_c('span',[_vm._v(" Admin")])],1)]}}],null,false,150901159)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.topNavigation.admin),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Manage Catalog")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,3628143384)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.catalogs),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3+tile.name,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Client Settings")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,1762844758)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.clientsettings),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3+tile.name,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Packages")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,2842276515)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.package),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3+tile.name,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],2)],1)],1):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticClass:"mb-12",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticStyle:{"text-align":"right !important","padding-right":"20px !important"},attrs:{"cols":"11"}},[(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"elevation":"0","fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Notifications","id":"smallicon"}},[_vm._v("notifications_active")])])]}}],null,false,2810276592)}):_vm._e(),(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Settings","id":"smallicon"}},[_vm._v("language")])])]}}],null,false,2224655002)}):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn","title":_vm.getUserTitle()}},on),[_c('span',{class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"id":"profile"}},[_vm._v(_vm._s(_vm.initials))])])]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Edit Profile"},on:{"click":function($event){return _vm.routeUrl('/userprofile')}}},[_vm._v("Edit Profile")])],1):_vm._e(),(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Change Password"},on:{"click":function($event){return _vm.routeUrl('/changepassword')}}},[_vm._v("Change Password")])],1):_vm._e(),_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Sign out"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sign out")])],1)],1)],1),(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"border":"0px !important"},attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"Applications","id":"smallicon"}},[_vm._v("apps")])])]}}],null,false,4279851092)},[(_vm.applicationList.length)?_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.applicationList),function(item,idx){return _c('v-list-item',{key:item.product_name},[_c('v-list-tile-title',{attrs:{"id":item.product_name},on:{"click":function($event){return _vm.redirectProduct(item)}}},[_vm._v(_vm._s(item.product_name))])],1)}),1):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"right":true,"top":true,"timeout":_vm.snackbartimeout,"color":_vm.colorValue},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbartext)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }