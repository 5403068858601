<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="8" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <router-link to="/rest_apis">API -> {{ this.$route.params.url_for }}</router-link>
                            </li>
                            <li>Called History</li>
                        </ul>
                    </v-col>
                    <v-col cols="4"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <edc-data-grid :dataList="tableList" @onPageChange="onPageChange"
                @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<style scoped>
</style>
<script>
import config from '../../config.json'
import {get as getToServer,post,post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import {SERVER_SIDE} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    name: 'EDCRESTAPIHISTORY',
    data: function () {
        return {
            loader:false,
            tableList: {
                headers: [
                    { text: 'URL Called', value: 'request_url', title: 'API Called' },
                    { text: 'Request Data', value: 'request_data', title: 'API Request Data' },
                    { text: 'HTTP Code', value: 'response_code', title: 'API Response HTTP Code' },
                    { text: 'Response Data', value: 'response_data', title: 'API Response Data' },
                    { text: 'Requested Date', value: 'requested_date', title: 'API Request Date' ,hideInlineFilter: true},
                    { text: 'Requested Time', value: 'requested_time', title: 'API Request Time',hideInlineFilter: true },
                    { text: 'Requested By', value: 'requested_by', title: 'API Request Call By' },
                    { text: 'Response Date', value: 'responded_date', title: 'API Responsed Date',hideInlineFilter: true },
                    { text: 'Response Time', value: 'responded_time', title: 'API Responsed Time',hideInlineFilter: true },
                ],
                actions:[],//if we need conditional action in row then provide key
                sorting_type: SERVER_SIDE,
                filterType: SERVER_SIDE,
                paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideSelect:true,
                hideExport:true,
			    hideShowColumns:true,
			    hideFilter:false,
            },
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            envIpJson : {
                "filter": [{"column_name":"request_uri","operator":"_eq_","value1":""}],
                "sort": [],
                "page": 1,
                "page_size": 10
            },
        }
    },
    mounted(){
        if(this.$route.params.url_for)
            this.envIpJson['filter'] = [{"column_name":"request_uri","operator":"_eq_","value1":this.$route.params.url_for}]
        this.getAllAPIs(this.envIpJson)
    },
    methods:{
        getAllAPIs(envIpJson){
            let _this = this; 
            if(!envIpJson)
                envIpJson = this.envIpJson
            _this.tableList.rows = []
            _this.loader = true
            postToServer(_this,config.GATEWAY_API_URL+'/get_api_called_history', _this.envIpJson).then(gatewayResponse => {
                _this.loader = false;
                _this.tableList.rows = gatewayResponse.result;
                _this.tableList.total_count =gatewayResponse.total; 
            }).catch(EnvError => {
                _this.showError(EnvError)
          })
        },
        filterData(filterArray,pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            filterArray.push({"column_name":"request_uri","operator":"_eq_","value1":this.$route.params.url_for})
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getAllAPIs(inputJson);
        },
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllAPIs(inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllAPIs(inputJson);
        },
        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getAllAPIs(inputJson);
        },
        showError(error_message){
            this.loader = false;
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_message || SERVER_ERROR;
        }
    }
}
</script>