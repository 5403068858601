<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                            <v-col cols="12" class="EDC-Col">
                                <ul class="breadcrumb breadcrumbUL">
                                    <li>
                                        <router-link to="/dashboard">
                                            {{ environmentName }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/jobplanhistory" v-if="isFromHistory">Job Plan History - {{ jobPlanName }}</router-link>
                                        <router-link :to="{name: 'ManageJobPlanInstance',params:manageJobParamObj}" v-if="isFromInstance">Job Plan Instance - {{ jobPlanName }}</router-link>
                                    </li>
                                    <li>Plan Details </li>
                                </ul>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <v-card>
                                    <v-tabs v-model="activeTab" non-linear>
                                        <v-tab class="EDC-Tabs" key="tab1">
                                            <v-badge :content="jobTableList.rows.length" inline bordered>
                                                <span>Instance Details</span>
                                            </v-badge>
                                        </v-tab>
                                        <v-tab class="EDC-Tabs" key="tab2">
                                            <span>Instance logs</span>
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="activeTab">
                                        <v-tab-item eager>
                                            <edc-data-grid key="grid-A" :dataList="jobTableList" @onHistory="onHistory" @onDownload="onDownload">
                                            </edc-data-grid>
                                        </v-tab-item>
                                        <v-tab-item eager>
                                            <edc-data-grid key="grid-b" :dataList="logTableList"></edc-data-grid>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <loading-panel :loader="loader"></loading-panel>

                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import { post as postToServer } from '@/methods/serverCall.js';
    import {JOB_PLAN_INSTANCE_HISTORY_DETAILS, EXPORT_JOB_PLAN_INST_DETAILS} from '@/data/url_constants.js'
    import {manageScreenDisplay} from '@/methods/special.js'
    import config from '../../config.json'
    import {SERVER_ERROR} from '@/data/client_message.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import {addDependency} from '@/methods/commonFunc.js'

    export default {
        name:'JobPlanInstanceDetails',
        data(){
            return {
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout,
                activeTab:0,
                jobTableList: {
                    headers:[],
                    actions:[],
                    rows:[],
                },
                jobHeaders: [{
                    text: 'Job Id',
                    value: 'job_id',
                    align:'right',
                    dataType:'int'
                },
                {
                    text: 'Seq No',
                    value: 'instance_details_sequence_no',
                    align:'right',
                    dataType:'int'
                },
                {
                    text: 'Process definition name',
                    value: 'displayPDName',
                    showHtml:true
                },
                {
                    text: 'Status',
                    value: 'status',
                    useChip:true,
                    chipFor:"status"
                },
                {
                    text: 'Start Date',
                    value: 'start_date',
                    width: '20%',
                    dataType:'date'
                },
                {
                    text: 'Start time',
                    value: 'start_time',
                    hideInlineFilter: true
                },
                {
                    text: 'End Date',
                    value: 'end_date',
                    dataType:'date'
                },
                {
                    text: 'End time',
                    value: 'end_time',
                    hideInlineFilter: true
                }],
                logTableList: {
                    headers:[],
                    rows:[],
                    actions:[]
                },      
                logHeaders: [
                    {
                        text: 'Log Id',
                        value: 'log_id',
                        dataType:'int'
                    },
                    {
                        text: 'Job id',
                        value: 'job_id',
                        dataType:'int'
                    },
                    {
                        text: 'Description',
                        value: 'description',
                        width: '50%'
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        title: 'Job Plan Log Created By',
                        dataType:'date'
                    },
                    {
                        text: 'Created Date',
                        value: 'created_date',
                        title: 'Job Plan Log Created Date',
                        dataType:'date'
                    },
                    {
                        text: 'Created Time',
                        value: 'created_time',
                        title: 'Job Plan Log Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    },
                ],
                loader:false,
            }
        },
        computed:{
            clientId(){
                return this.$session.get('client_id')
            },
            environmentName(){
                return this.$route.params.envname
            },
            environmentId(){
                return this.$route.params.env_id
            },
            isFromInstance(){
                return this.$route.params.isFromInstance
            },
            jobPlanName(){
                return this.$route.params.job_plan_name
            },
            jobPlanId(){
                return this.$route.params.job_plan_id
            },
            jobPlanInstanceId(){
                return this.$route.params.job_plan_instance_id
            },
            isFromHistory(){
                return !this.isFromInstance
            },
            commonRequestData(){
                return {
                    'client_id':this.clientId,
                    'execution_environment': this.environmentId,
                    'job_plan_instance_id': this.jobPlanInstanceId
                }
            },
            modeOfExecution(){
                return this.$route.params.modeOfExecution
            },
            manageJobParamObj(){
                return{
                    'env_id': this.environmentId, 
                    'job_plan_id': this.jobPlanId,
                    'job_plan_name':this.jobPlanName,
                    'mode_of_execution':this.modeOfExecution,
                    'env_name':this.environmentName
                }
                
            }
        },
        mounted(){
            this.jobTableList = this.getGridObj(this.jobHeaders, 'instance_details_sequence_no', true, {showExport : false})
            this.logTableList = this.getGridObj(this.logHeaders, 'log_id', true, {showExport : false})
            this.jobTableList.actions =[
              {'text':'history','key':"job_id", selectType:"single",index:1}
            ]
            this.getPlanHistoryInstanceDetailsList()
            this.getLogList()
        },
        methods:{
            onDownload(){

            },
            showMessage(message, type='error'){
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message;
            },
            getPlanHistoryInstanceDetailsList() {
                let _this = this;
                _this.loader = true;
                if (!this.environmentId)
                    return false
                let baseUrl = config.JOB_PLAN_URL;
                baseUrl = baseUrl+ JOB_PLAN_INSTANCE_HISTORY_DETAILS;
                postToServer(this, baseUrl, _this.commonRequestData).then(response => {
                    _this.loader=false;
                    _.forEach(response, function(obj){
                        obj['displayPDName'] = addDependency(obj['process_definition_name'],obj.instance_details_indentation)
                })
                    _this.jobTableList.rows = response;
                }).catch(CurrentJobError => {
                        _this.showMessage(CurrentJobError)
                })
            },
            getLogList(){
                let _this = this;
                if (!this.environmentId)
                    return false
                let baseUrl = config.JOB_PLAN_URL;
                baseUrl = baseUrl+ '/get_job_instance_logs';
                _this.loader = true
                postToServer(this, baseUrl, _this.commonRequestData).then(response => {
                    _this.loader=false;
                    _this.logTableList.rows = response;
                }).catch(CurrentJobError => {
                    _this.showMessage(CurrentJobError)
                })
            },
            onHistory(record){
                let _this = this;
                this.$router.push({
                    name: 'showjobstepsresult', 
                    params: { 
                        env_id: this.environmentId,
                        envname:this.environmentName,
                        jobid: record.job_id,
                        jobname: record.process_definition_name,
                        status:record.status, 
                        type:'jobPlan',
                        job_plan_id: this.jobPlanId,
                        job_plan_name: this.jobPlanName,
                        job_plan_instance_id:this.jobPlanInstanceId,
                        'isFromInstance':this.isFromInstance,
                        'modeOfExecution':this.modeOfExecution
                        
                    }
                });
            },
        }
    }
</script>