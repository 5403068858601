<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Users list</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        
        <edc-data-grid :dataList="tableList" @onNew="addUser" @onCellEvent="onCellEvent" @onEdit="onEdit"  @onDelete="onDelete" @filterData="filterData" @onDownload="onDownload" @onResendEmail="onResendEmail" @onResetPassword="onResetPassword" @onUserHistory="onUserHistory" @onUnlockAccount="onUnlockAccount" @ondblClick="onEdit"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
    </div>
</template>

<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, deleteFromServer, post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from './../../data/client_message.js'
import {
    GET_ALL_USER, RESEND_USER_EMAIL, EXPORT_ALL_USERS, UNLOCK_USER_ACCOUNT, DELETE_USER
} from './../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from './../../data/macros.js'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep';
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    USER_PROVISIONING_SERVICE_ID,
    CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    data: function () {
        return {
            userRole:{},
            loader: false,
            headers: [
                {
                    text: 'Name',
                    value: 'full_name',
                    width: "30%",
                    title: 'User Name'
                },
                {
                    text: 'User Id',
                    value: 'username',
                    width: "30%",
                    title: 'User Email ID'
                },
                {
                    text: 'Created Date',
                    value: 'timezone_aware_created_date',
                    width: "15%",
                    title: 'User Created Date',
                    dataType:'date'
                },
                {
                    text: 'Created Time',
                    value: 'timezone_aware_created_time',
                    width: "15%",
                    title: 'User Created Time',
                    dataType:'time',
                    hideInlineFilter: true
                },
                {
                    text: 'Status',
                    value: 'verification_status',
                    width: "10%",
                    title: 'User Verification Status'
                }
            ],
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
        }
    },
    
    mounted() {
        this.tableList = this.getGridObj(this.headers,'username')
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [
            {
                'text': 'new',
                'key': "username",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION),
                index:1
            },
            {
                'text': 'edit',
                'key': "username",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,UPDATE_ACTION),
                index:2
            },
            {
                'text': 'delete',
                'key': "username",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,DELETE_ACTION),
                index:3
            },
            {
                'text': 'onResendEmail',
                'key': "verification_status",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION),
                index:4,
                cmpValue:'Waiting for email verification'
            },
            {
                'text': 'onResetPassword',
                'key': "verification_status",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION),
                cmpValue:'Active',
                index:5
            },
            {
                'text': 'onUserHistory',
                'key': "verification_status",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION),
                index:6,
                cmpValue:'Waiting for email verification'
            },
            {
                'text': 'onUnlockAccount',
                'key': "is_locked",
                selectType:"single",
                role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION),
                index:6,
                cmpValue:true
            }
        ], //if we need conditional action in row then provide key
        this.getUsersList();
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.USER_PROVISION_URL+EXPORT_ALL_USERS;    
            postToServer(this, url, inputJson).then(Response => {
                var url = config.USER_PROVISION_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        onCellEvent(record){
            this.onEdit(record);
        },
        addUser(){
            let _this = this;
            _this.$router.push('/adduser');
        
        },
        filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": stringObj};
                     inputJson.filter.push(cloneDeep(temp));
                })
            });
        },
        onDelete:function(record){
            let _this = this;
            if(record.user_id ==this.$session.get('user_id'))
            {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'You cannot delete your self';
                return false
            }
            _this.deleteUser(record);
            // let inputJson = _this.envIpJson;
            // this.get_environment_by_client_id(inputJson);
        },
        addBulkUser(){
            let _this = this;
            _this.$router.push('/addbulkuser');
        },
        onEdit:function(record){
            let _this = this;
            // issue no.161 dont allow user to inactive him self
            var show_enable_button = true
            if(record.user_id ==this.$session.get('user_id'))
                show_enable_button = false

            this.$router.push({ name: 'update_user', params: { userid: record.user_id, type:'edit',show_enable_button:show_enable_button }})
        },
        onResendEmail:function(record){
            var data  = {'user_id': record.user_id, 'username': record.username}
            postToServer(this, config.USER_PROVISION_URL + RESEND_USER_EMAIL, data).then(response => {
                this.loader = null
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'Verification mail sent successfully';
              }, response => {
                this.loader = null
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = response;
              }).catch(e => {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
              })
            
        },
        onResetPassword:function(record){
            let _this = this;
            // issue no.161 dont allow user to inactive him self
            var show_enable_button = true
            if(record.user_id ==this.$session.get('user_id'))
                show_enable_button = false
            this.$router.push({ name: 'ResetUserPassword', params: { username: record.username,show_enable_button:show_enable_button, first_name:record.first_name, last_name: record.last_name }})
        },
        onUnlockAccount:function(record){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var data_to_send = {'username': record['username']}
            postToServer(_this, config.USER_PROVISION_URL + UNLOCK_USER_ACCOUNT, data_to_send).then(response => {
                _this.loader = false;
                this.getUsersList()
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        onUserHistory:function(record){
            let _this = this;
            // issue no.161 dont allow user to inactive him self
            var show_enable_button = true
            if(record.user_id ==this.$session.get('user_id'))
                show_enable_button = false
            this.$router.push({ name: 'userHistory', params: { username: record.username,show_enable_button:show_enable_button }})
        },
        getUsersList() {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            getToServer(_this, config.USER_PROVISION_URL + GET_ALL_USER + client_id).then(response => {
                _this.loader = false;
                if (response) {
                    _this.tableList.rows = response;
                    _this.tableList.total_count = response.length;
                } else {
                    _this.tableList.rows = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        deleteUser(record){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var data_to_send = {'user_id': record.user_id, 'username': record.username}
            postToServer(_this, config.USER_PROVISION_URL + DELETE_USER, data_to_send).then(response => {
            // var data = JSON.parse(response.bodyText)
                this.getUsersList()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        }
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>