<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs1 />
      <v-flex
        xs5
        pl-3
      >
        <v-layout
          row
          wrap
          style="text-align:center"
          cols="2"
        >
          <h6>Click Here to Migrate Users from Archivist to EDC</h6>
        </v-layout>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:5px"
            @click="migrateUser"
          >
            Migrate User
          </v-btn>
        </v-layout>
        <br>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <h6>Click Here to Migrate Datasources from Archivist to EDC</h6>
        </v-layout>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:5px"
            @click="migrateDS"
          >
            Migrate DS
          </v-btn>
        </v-layout>
        <br>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <h6>Click Here to Migrate Environment from Archivist to EDC</h6>
        </v-layout>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:5px"
            @click="migrateENV"
          >
            Migrate Environment
          </v-btn>
        </v-layout>
        <br>
      </v-flex>
      <v-flex
        xs6
        pl-3
      >
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <h6>Click Here to Migrate Engine Data from Archivist to EDC</h6>
        </v-layout>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:5px"
            @click="migrateEngineData"
          >
            Migrate Engine Data
          </v-btn>
        </v-layout>
        <br>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <h6>Click Here to Migrate PD from Archivist to EDC</h6>
        </v-layout>
        <v-layout
          row
          wrap
          style="text-align:center"
        >
          <v-btn
            outline
            :color="outlineColor"
            style="border-radius:5px"
            @click="migratePD"
          >
            Migrate Process Definition
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :right="true"
      :top="true"
      :timeout="snackbartimeout"
      :color="colorValue"
    >
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>
    <script>
    import Simplert from 'vue2-simplert'
    import {fileUpload, post as postToServer} from "@/methods/serverCall.js"
    import config from '../../config.json'
    import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
    import dataTable from '../../views/dataTable/datatable.vue'
    import {BTN_COLOR} from '@/data/macros.js'
    export default {
    components: {
        Simplert,
        'vc-data-table': dataTable,
    },
      data () {
        return {
          file: '',
          snackbartext:'',
          snackbartimeout:5000,
          snackbar:false,
          colorValue:"success",
          outlineColor:BTN_COLOR,
        }
    },
    methods:{
        migrateUser(){
          var req_data = {}
          req_data['client_id'] = this.$session.get('client_id')
          // req_data['db_info_id'] = this.$route.params.db_info_id
          req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
          
          postToServer(this, 'http://192.168.1.101:8041/migrate_users', req_data).then(response  => {
              if(response){
                this.colorValue = 'success'
                this.snackbar = true
                this.snackbartext = response;
                this.$router.push("/migrate_users")
              }
            }).catch(error_response => {
              if(error_response){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
              }
            })
        },
        migrateDS(){
          var req_data = {}
          req_data['client_id'] = this.$session.get('client_id')
          // req_data['db_info_id'] = this.$route.params.db_info_id
          req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
          
          postToServer(this, 'http://192.168.1.100:8041/migrate_datasource', req_data).then(response  => {
              if(response){
                this.colorValue = 'success'
                this.snackbar = true
                this.snackbartext = response;
                this.$router.push("/migrate_users")
              }
            }).catch(error_response => {
              if(error_response){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
              }
            })
        },
        migrateENV(){
          var req_data = {}
          req_data['client_id'] = this.$session.get('client_id')
          // req_data['db_info_id'] = this.$route.params.db_info_id
          req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
          
          postToServer(this, 'http://192.168.1.100:8041/migrate_environment', req_data).then(response  => {
              if(response){
                this.colorValue = 'success'
                this.snackbar = true
                this.snackbartext = response;
                this.$router.push("/migrate_users")
              }
            }).catch(error_response => {
              if(error_response){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
              }
            })
        },
        migrateEngineData(){
          var req_data = {}
          req_data['client_id'] = this.$session.get('client_id')
          // req_data['db_info_id'] = this.$route.params.db_info_id
          req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
          
          postToServer(this, 'http://192.168.1.100:8041/migrate_engine_data', req_data).then(response  => {
              if(response){
                this.colorValue = 'success'
                this.snackbar = true
                this.snackbartext = response;
                this.$router.push("/migrate_users")
              }
            }).catch(error_response => {
              if(error_response){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
              }
            })
          },
          migrateSecRoleMaster(){
            var req_data = {}
            req_data['client_id'] = this.$session.get('client_id')
            req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
            
            postToServer(this, 'http://192.168.1.100:8041/migrate_security_role_master', req_data).then(response  => {
                if(response){
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = response;
                  this.$router.push("/migrate_users")
                }
              }).catch(error_response => {
                if(error_response){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                }
              })
          },
          migratePD(){
            var req_data = {}
            req_data['client_id'] = this.$session.get('client_id')
            req_data['db_info_id'] = 'a296c79f88484f46900517bd18d0c626'
            
            postToServer(this, 'http://192.168.1.22:8041/migrate_process_def', req_data).then(response  => {
                if(response){
                  this.colorValue = 'success'
                  this.snackbar = true
                  this.snackbartext = response;
                  this.$router.push("/migrate_users")
                }
              }).catch(error_response => {
                if(error_response){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                }
              })
          }
    }
    }
    </script>
