<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <edc-data-grid key="JobTabGrid-A" :dataList="businessTableList" 
                @onDownload="onBusinessTablesDownload" 
                @redirectToDataviewer="redirectToPathfinder"></edc-data-grid>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import config from '../../../config.json'
    import {post as postToServer } from '../../../methods/serverCall.js';
    import {GETSTEPSROWDETAILS,EXPORT_OPERATION_WISE_ROW} from '../../../data/url_constants.js';
    import {getPathfinderProduct} from "@/methods/commonFunc.js"
    import {getURLByDomainByProduct} from '@/methods/domain_management.js'

    export default{
        name:"businessTables",
        mixins:[mixinJobManagement],
        props:{
            jobId:{
                type:Number,
                default:0
            },
            jobMode:{
                type:String,
                default:''
            },
            jobName:{
                type:String,
                default:''
            },
        },
        data(){
            return{
                operationHeaders: [
                    { text: 'Business Object Name', value: 'business_object_name', cssClass:"w16", options:[],align:"left"},
                    { text: 'Action', value: 'activity_type',width:"10%", options:[], },
                    { text: 'Table Name', value: 'table_name',cssClass:"w16",},
                    { text: 'Expected', value: 'formated_expected_rows',align:'right', width:"10%", options:[], title: 'Expected Row Count', dataType:'int',sortColumn:'expected_row'},
                    { text: 'Processed', value: 'formated_processed_rows',align:'right', width:"20%", options:[], align:'right', title: 'Processed Row Count', dataType:'int',sortColumn:'process_row'},
                    { text: 'Skipped', value: 'formated_skipped_rows',align:'right', width:"20%", options:[], align:'right', title: 'Skipped rows due to duplicate found', dataType:'int',sortColumn:'skipped_row'},
                    { text: 'Size (In MB)', value: 'size',align:'right', width:"20%", options:[], title: 'Size In MB', dataType:'int'},
                    {text: '% Completed', value: 'completed',align:'right', cssClass:"w16", options:[], title: 'Percentage Completed', hideInlineFilter:true},
                    { text: 'Row Size (In MB)', value: 'row_size',  cssClass:"w16", options:[], align:'right', title: 'Row Size In MB', dataType:'int'},
                    { text: 'Index Size (In MB)', value: 'index_size',  cssClass:"w16", options:[], align:'right', title: 'Index Size In MB', dataType:'int'},
                ],
                businessTableList: {
                    headers:[],
                    actions: [],
                    rows: []
                },
            }
        },
        watch: {
            jobId:{
                handler(newValue){
                    this.getBusinessTablesAffectedRowCounts()
                }
            },
            '$store.state.updatedRowCounts':{
                handler(newValue){
                    this.UpdateRowCounts(newValue)
                }
            },
        },
        mounted() {
            this.businessTableList = this.getGridObj(this.operationHeaders,'id')
            this.businessTableList.actions = [
                {'text':'viewindv','key':"table_name", selectType:"single",index:1,role:true}
            ]
            this.getBusinessTablesAffectedRowCounts()
        },
        methods: {
            getBusinessTablesAffectedRowCounts(){
                var _this = this
                if(!this.jobId)
                    return
                var job_data = {"job_id": this.jobId, "env_id":this.environmentId}
                this.loader = true
                postToServer(this, config.ENGINE_API_URL + GETSTEPSROWDETAILS, job_data).then(response => {
                    response.map((obj)=>{
                        // obj.skipped_row = 0
                        obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
                        if(!obj.skipped_row)
                            obj.skipped_row = "0"
                        if(this.mode === 'proof'){
                            obj.completed = '0.00'
                        }
                        else{
                            obj.completed = '100.00'
                            if(obj.expected_row > 0 && obj.process_row < obj.expected_row)
                                obj.completed = ((obj.process_row+parseInt(obj.skipped_row))/obj.expected_row *100).toFixed(2)
                        }
                    });
                    _this.businessTableList.rows = response;
                    _this.businessTableList.total_count = response.length;
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            onBusinessTablesDownload(downloadParams,filterArray){
                let _this = this;
                let filter=[];
                filterArray.map((obj, index)=>{
                    obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
                    })
                });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option":downloadParams.recordType,
                    "client_id": this.clientId,
                    "env_id": this.environmentId,
                    "job_id": this.jobId,
                    "mode":this.jobMode,
                    "job_name": this.jobName,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter
                }
                var url = config.ENGINE_API_URL+EXPORT_OPERATION_WISE_ROW;
                _this.loader=true;
                postToServer(_this, url, inputJson).then(Response => {
                    var url = config.ENGINE_API_URL + "/static/" + Response;
                    window.open(url, '_blank');
                    _this.loader=false;

                }).catch(objError => {
                    _this.showMessage(objError)
                });
            },
            redirectToPathfinder(record){
                let _this = this
                var redirectionObj  = getPathfinderProduct(_this)
                let data_to_send = {
                    "env_id":this.environmentId,
                    "client_id":this.clientId,
                    "archivist_ds_type":["source_data","target_data"],
                    "request_for":"business_data",
                    "job_name":_this.jobName,
                    "job_id":this.jobId,
                    "table_list":[],
                    "selected_table":{"table_name":record.table_name}
                }
                _.forEach(_this.businessTableList.rows,(obj)=>{
                    data_to_send.table_list.push({"output_table_name":obj.table_name})
                })
                _this.loader=true
                var cache_url = config.EDC_CACHE_SERVICE_URL + "/add_dataviewer_redirect_key"
                postToServer(_this, cache_url, data_to_send).then(response=>{
                    _this.loader=false
                    if(response){
                        var archivist_redirect_key=response
                        // write code here to create key and pass that key to the function as query_string
                        let query_string = '&archivist_redirect_key='+archivist_redirect_key// append key here
                        let url = getURLByDomainByProduct(_this,redirectionObj,'login',query_string)
                        window.open(url, '_blank');
                    }
                }).catch(error_response=>{
                    _this.showMessage(error_response)
                })
            },
            UpdateRowCounts(data){
                let _this = this
                data.map((obj)=>{
                    var data_already_in_row = false
                    for(var i = _this.businessTableList.rows.length - 1; i >= 0; i--){
                        if(_this.businessTableList.rows[i].job_id === obj.job_id  && _this.businessTableList.rows[i].business_object_id === obj.business_object_id && _this.businessTableList.rows[i].step_id===obj.step_id && _this.businessTableList.rows[i].table_name === obj.table_name){
                            data_already_in_row = true
                            _this.businessTableList.rows[i].expected_row = obj.expected_row
                            _this.businessTableList.rows[i].process_row = obj.process_row
                            _this.businessTableList.rows[i].formated_expected_rows = obj.formated_expected_rows
                            _this.businessTableList.rows[i].formated_processed_rows = obj.formated_processed_rows
                            _this.businessTableList.rows[i].formated_skipped_rows = obj.formated_skipped_rows
                            _this.businessTableList.rows[i].size = obj.size
                            _this.businessTableList.rows[i].process_row = obj.process_row
                            _this.businessTableList.rows[i].index_size = obj.index_size
                            _this.businessTableList.rows[i].row_size = obj.row_size

                            _this.businessTableList.rows[i].completed = '100.00'
                            if(_this.businessTableList.rows[i].expected_row > 0 && _this.businessTableList.rows[i].process_row < _this.businessTableList.rows[i].expected_row){
                                _this.businessTableList.rows[i].completed = (obj.process_row/_this.businessTableList.rows[i].expected_row *100).toFixed(2)
                            }
                            break;
                        }
                    }
                    if(!data_already_in_row && this.jobId === obj.job_id){
                        obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
                        if(this.mode === 'proof'){
                            obj.completed = '0.00'
                        }else{
                            obj.completed = '100.00'
                            if(obj.expected_row > 0 && obj.process_row < obj.expected_row){
                                obj.completed = (obj.process_row/obj.expected_row *100).toFixed(2)
                            }
                        }
                    _this.businessTableList.rows.push(obj)
                    }
                })
            },
        },
    }
</script>