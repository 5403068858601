<template>
	<div id="edcgraph">
		<div id="paper" ref="elpaper" style="transform-origin: top left;transform: scale(1);">
			
		</div>
		<div class="paper" id="paper-multiple-papers-small" :style="{'top':minimapTop}" style="position: absolute;right: 3%;background:#E5E8E8;draggable:true;" v-show="showMinimap">
			
		</div>
		<div style="position: absolute; right: 3%;font-size:30px;" :style="{'top':minimapTop}" v-show="showMinimap">
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="zoom_in"  :original="true" title="Zoom In" @click="ZoomAction('zoom_in')">
            </svgicon>
			<br>
			 <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="history_v2"  :original="true" title="Reset Zoom" @click="ZoomAction('zoom_reset')">	
             </svgicon>
			<br>
			<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="zoom_out"  :original="true" title="Zoom Out" @click="ZoomAction('zoom_out')">
			</svgicon>
		</div>

		<context-menu id="context-menu" ref="ctxMenu" v-if="!isReadOnly && isGraphForProcessDesigner">
			<li style="font-weight:bold" @mouseover="mouseOverEvent($event,'selection')" 
			@mouseout="mouseOutEvent($event)" v-bind:class="{disabled : !isCellSelected}" 
			@click="contextMenuClicked('cut')" ref="elcut">Cut</li>
			<li style="font-weight:bold" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)" 
			v-bind:class="{disabled : !isCellSelected}" @click="contextMenuClicked('copy')" ref="elcopy">Copy</li>
			<li style="font-weight:bold" @mouseover="mouseOverEvent($event,'copied')" @mouseout="mouseOutEvent($event)" 
			@click="contextMenuClicked('paste')" v-bind:class="{disabled : !elementWrapperCutCopied.id}" ref="elpaste">Paste</li>
			<li style="font-weight:bold" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)" 
			v-bind:class="{disabled : !isCellSelected}" @click="contextMenuClicked('delete')" ref="eldelete">Delete</li>
		</context-menu>

		<context-menu id="cellCntxMenu" ref="cellCntxMenu" style="padding:0px" v-if=" isGraphForBusinessObject">
			<li @click="manageContextMenu('showfilter')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold">Filter...</li>
			 <li @click="manageContextMenu('showSelfJoin')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold">Self Join...</li>
			<li @click="contextMenuClicked('delete')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold" v-if="!isReadOnly">Delete</li>
		</context-menu>
		<loading-panel :loader="graphLoader"></loading-panel>

		<context-menu id="identifierCntxMenu" ref="identifierCntxMenu" style="padding:0px" v-if=" isGraphForBusinessObject">
			<li @click="manageContextMenu('showcols')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold">Define...</li>
			<li @click="manageContextMenu('clearidentifiers')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold" v-if="!isReadOnly">Clear</li>
		</context-menu>

		<!-- <context-menu id="linkCntxtMenu" ref="linkCntxtMenu" style="padding:0px" v-if="!isReadOnly && isGraphForBusinessObject">
			<li @click="manageContextMenuForLink('openjoinpanel')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)" class="bold"
			 >Join...</li>
			<li @click="manageContextMenuForLink('openfilterpanel')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold" >Filter...</li>
			<li @click="manageContextMenuForLink('deletelink')" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)"
			 class="bold" v-if="!isReadOnly">Delete</li>
		</context-menu> -->

		<loading-panel :loader="loader"></loading-panel>

	</div>
</template>
<script>
	const joint = require('jointjs')
	import contextMenu from 'vue-context-menu'
	import graphlib from 'graphlib'
	import { shapes, dia,layout } from 'jointjs'
	import * as edcGraphConstantsObj from "../../methods/edcGraph/constants.js";
	import * as edcLinkObj from "../../methods/edcGraph/links.js"
	import * as edcPaperObj from "../../methods/edcGraph/papers.js"
	import * as edcZoomObj from "../../methods/edcGraph/zoom.js";
	import * as edcContextMenuObj from "../../methods/edcGraph/contextmenu.js"
	import * as edcAnonymousObj from  "../../methods/edcGraph/anonymousFunctions.js"
	import * as edcLinktoolObj from "../../methods/edcGraph/linktool.js"
	import * as shapesObj from "../../methods/edcGraph/shapes.js"
	import * as rubberbandObj from "../../methods/edcGraph/rubberband.js"
	import * as graphObj from "../../methods/edcGraph/graph.js"
	import * as cellObj from "../../methods/edcGraph/cells.js"
	import * as hierarchyObj from "../../methods/edcGraph/hierarchy.js"
	import * as commonObj from '../../methods/edcGraph/commonFunctions.js'

	// import { VueContext } from 'vue-context';
	export default {
		name:'edcGraph',
		components:{
			'context-menu':contextMenu
		},
		data(){
			return {
				minimapTop:'370px',
				graphHeight:'450px',
				edcGraph: new dia.Graph({},{ cellNamespace: shapes }),
				paperHeight:1600,
				paperWidth:1600,
				mainPaper:'',
				minimapPaper:'',
				mainPaperScale:1, // this variable is modified in the edcZoom.js page
				lensScale:1, // this variable is modified in the edcZoom.js page
				isCellSelected:false,
				elementWrapperCutCopied:{},
				elementCutCopied:{},
				selectedElementForContextMenu:{},
				businessObjectCell:{},
				businessObjectCellWrapper:{},
				rightClickOn:'',
				graphTimer:0,
				loader:false,
			    dragDroppedCell:{},
			}
		},
		props:{
			mainPaperDimentions:{
				type:Object
			},
			newObject:{
				type:Object
			},
			graphFor:{
				type:String,
				default:""
			},
			isReadOnly:{
				type:Boolean,
				default:true
			},
			businessObjectShapeActions:{
				type:Object
			},
			graphLoader:{
				type:Boolean,
				default:false
			},
			filterChangedFor:{
				type:Object
			},
			cellToRemove:{
				type:Object
			},
			dataForGraphRedesign:{
				type:Object
			},
			validationGraphObj:{
				type:Object
			},
			updatedStepData:{
				type:Object
			},
			showMinimap:{
				type:Boolean,
				default:true,
			},
			tableDescriptionList:{
				type:Array
			},
			autoArrangeGraph:{
				type:Boolean,
				default:false
			},
			changedBOForPolicy:{

			},
			updatedReferenceDetails:{
				type:Object
			},
			openCell:{
				type:Object
			},
			copyJoinJson:{
				type:Array
			},
			closeCell:{
				type:Object
			},
		},
		computed:{
			isGraphForProcessDesigner(){
				return this.graphFor === 'process_designer'
			},
			isGraphForBusinessObject(){
				return this.graphFor === 'business_object'
			},
			isGraphForSelectionDesigner(){
				return this.graphFor === 'data_selection'
			},
			isRightClickOnBusinessKey(){
				return this.rightClickOn === edcGraphConstantsObj.BUSINESS_EDC_CELL_CONTAINER_TYPE
			},
			isRightClickOnBusinessCell(){
				return this.rightClickOn === edcGraphConstantsObj.BUSINESS_OBJECT_KEYS_CONTAINER_TYPE
			},
			isRightClickOnBusinessLink(){
				return this.rightClickOn === 'link'
			},
		},	
		watch:{
			'mainPaperDimentions':{
				handler(newValue){
					if(!newValue)
						return
					this.setPaperDimentions()
				}
			},
			'newObject':{
				handler(newValue){
					if(newValue){
						// this.edcGraph.addCells()
						if(newValue.edc_type)
							this.manageNewObject(newValue)
						else
							newValue.addTo(this.edcGraph);

					}
				}
			},
			'businessObjectShapeActions':{
				handler(newValue){
					if(newValue && newValue.action){
						this.manageBusinessObjectShape(newValue.action)
					}
				}
			},
			'filterChangedFor':{
				handler(newValue){
					if(newValue && newValue.cell_id){
						this.manageFilterIcon(newValue)
					}
				}
			},
			'cellToRemove':{
				handler(newValue){
					if(newValue.cell_id || newValue.link_id)
						this.removeCell(newValue)
				}
			},
			'dataForGraphRedesign':{
				handler(newValue){
					if(newValue.cells)
						this.RedesignGraph(newValue)
				}
			},
			'validationGraphObj':{
				handler(newValue){
					if(newValue){
						if(this.isGraphForBusinessObject)
							cellObj.highlightUnHighlightInvalidBO(this.edcGraph,this.mainPaper,newValue.isHeighlight,newValue.cell_id,newValue.message)
						if(this.isGraphForProcessDesigner)
							cellObj.highlightUnHighlightInvalidCell(this.edcGraph,this.mainPaper,newValue.cellList)
					}
				}
			},
			'isReadOnly':{
				handler(newValue){

				}
			},
			'updatedStepData':{
				handler(newValue){
					if(newValue.step_id){
						this.updateStepData(newValue)
					}
				}
			},
			'showMinimap':{
				handler(newValue){

				}
			},
			'tableDescriptionList':{
				handler(newValue){
					edcAnonymousObj.addTableDescription(newValue,this.edcGraph)
				}
			},
			'autoArrangeGraph':{
				handler(newValue){
					if(newValue){
						this.autoArrangeEDCGraph()
					}
				}
			},
			'changedBOForPolicy':{
				handler(newValue){
					if(!_.isEmpty(newValue))
						var replaced_data = commonObj.replacePolicyValues(this.edcGraph,newValue)
				}
			},
			'updatedReferenceDetails':{
				handler(newValue){
					if(this.isGraphForProcessDesigner && !_.isEmpty(newValue)){
						if(newValue.action_type === 'step_name_changed'){
							let element = this.edcGraph.getCell(newValue.update_details.step_id)
							if(element){
								element.prop('stepname',newValue.update_details.new_step_name)
								let parentCell = element.getParentCell()
								if(parentCell){
									parentCell.attr('label/text',edcAnonymousObj.getWrappedText(newValue.update_details.new_step_name,parentCell.size().width,joint,2,10))
									parentCell.attr('label/title',newValue.update_details.new_step_name)
								}
							}
						} else if(newValue.action_type==='columns_removed'){
							console.log('newValue',newValue)
							let allReferences = this.manageColumnRemovedForUndoRedo(newValue.update_details,[])
							console.log('allReferences',allReferences)
							let valueToPass = _.cloneDeep(newValue.update_details)
							valueToPass['column'] = valueToPass.columns_removed[0]
							valueToPass['allReferences'] = allReferences
							console.log('valueToPass',valueToPass)
							this.sendNonGraphEvent('remove_column',valueToPass)
						}
						this.updateStepReferenceForProcessDesigner(newValue)
					}
				}
			},
			'dragDroppedCell':{
				handler(newValue){
					if(!newValue||!newValue.id)
						return
					// we have to send add element event only when user added table using drag option
					this.sendElementEvent('add_element',{'element':_.cloneDeep(newValue)})
				}
			},
			'openCell':{
				handler(newValue){
					if(!newValue||(!newValue.cell_id && !newValue.link_id))
						return
					if(newValue.cell_id){
						// for redo/undo we need to open cell on key press
						let element = edcAnonymousObj.getEmbeddedCell(this.edcGraph.getCell(newValue.cell_id))
						let eventName = 'doubleclick'
						if(newValue.eventName)
							eventName = newValue.eventName
						if(element){
							this.sendElementEvent(eventName,{"element":element,"is_redo_undo":true})
						}
					}
					else if(newValue.link_id){
						if(this.isGraphForBusinessObject){
							// In case of join, we have to make sure is link already exists or not in the graph. If not we have to add that link first.
							let link = this.edcGraph.getLinks().filter(link=>link.id === newValue.link_id)
							if(!link.length){
								link = this.addGraphLink(newValue.linkData.link)
							} else {
								link = link[0]
							}
							let source = this.edcGraph.getCell(link.source().id)
							let target = this.edcGraph.getCell(link.target().id)
							this.sendLinkEvent(newValue.eventName,{"link":link,"source":source,"target":target,"linkData":newValue.linkData})
						}
					}
				}
			},
			'copyJoinJson':{
				handler(newValue){

				}
			},
			'closeCell':{
				handler(newValue){
					this.operationPerformOnWrapper(newValue.step_id)
				}
			}
		},
		beforeDestroy() {
			clearInterval(this.graphTimer);
		},
		mounted(){
			let _this = this;
			_this.setPaperDimentions()
			var paperMainContainer = document.getElementById("main_container");
			_this.graphHeight = (window.innerHeight-130)+'px';
			_this.minimapTop = (window.innerHeight-260)+'px';//60 internal minimap height + 140 top navigation height

			var paperHeight = _this.paperHeight;
			var paperWidth = _this.paperWidth;
			var currentScale = 1;
			var gridSize = edcGraphConstantsObj.GRID_SIZE;
			var gridColor = edcGraphConstantsObj.GRID_COLOR;
			var edcGraph = _this.edcGraph;
			var mainPaper = new dia.Paper({
				cellViewNamespace: shapes,
				el: $("#paper"),
				model: edcGraph,
				gridSize: gridSize,
				drawGrid: {
					name: "mesh",
					args: {
						color: gridColor
					}
				},
				height: paperHeight,
				width: paperWidth,
				restrictTranslate: true,
				defaultLink: edcLinkObj.getDefaultLinkProperies(),
				validateConnection: function(cellViewS, magnetS, cellViewT, magnetT, end, linkView){
					// Prevent linking from output ports to input ports within one element.
					if (cellViewS === cellViewT) 
						return false;
					return true
				},
				validateMagnet: function(cellView, magnet) {
					// Note that this is the default behaviour. Just showing it here for reference.
					// Disable linking interaction for magnets marked as passive (see below `.inPorts circle`).
					return magnet.getAttribute('magnet') !== 'passive';
				},
				interactive: { 
					vertexAdd: false 
				},
				// Enable marking available cells & magnets
				markAvailable: false,
				// Enable link snapping within 75px lookup radius
				snapLinks: { 
					radius: 75 
				},
				linkPinning:false,
			});
			var paperSmallWidth = 1;
			var paperSmallHeigth = 1;
			if(_this.showMinimap){
				paperSmallWidth = 160
				paperSmallHeigth = 160
			}
			var paperSmall = new dia.Paper({
				cellViewNamespace: shapes,
				el: $("#paper-multiple-papers-small"),
				model: edcGraph,
				width: paperSmallWidth,
				height: paperSmallHeigth,
				gridSize: 1,
				interactive: false,
				restrictTranslate: true,
				defaultLink: edcLinkObj.getDefaultLinkProperies()
			});
			var paperSmallScale = paperSmallWidth / paperWidth;
			paperSmall.scale(paperSmallScale);
			_this.mainPaper = mainPaper
			_this.minimapPaper = paperSmall
			var lensWidth = paperMainContainer.clientWidth * paperSmallScale;
			var lensHeight = paperMainContainer.clientHeight * paperSmallScale;
			$("#paper-multiple-papers-small").prepend(
				"<div id='lens' class='lens' style='width:" +
				lensWidth +
				"px;height:" +
				lensHeight +
				"px;cursor: pointer;'> </div>"
			);

			var paperSmallContainer = document.getElementById(
				"paper-multiple-papers-small"
			);
			var lens = document.getElementById("lens");

			/*move lens on mouse move on paper*/
			lens.addEventListener("mousemove", lensmovelistner);
			paperSmallContainer.addEventListener("mousemove", lensmovelistner);
			/*and also for touch screens:*/
			// lens.addEventListener("touchmove", lensmovelistner);
			// paperSmallContainer.addEventListener("touchmove", lensmovelistner);

			function lensmovelistner(e) {
				if (detectLeftButton(e)) {
					edcPaperObj.MoveLens(
						e,
						lens,
						paperSmallContainer,
						paperMainContainer,
						paperSmallScale
					);
				}
			}

			function detectLeftButton(evt) {
				return edcPaperObj.isLeftMouseButtonDown();
			}

			paperMainContainer.addEventListener("scroll", moveLensonscrolllistner);

			function moveLensonscrolllistner(e) {
				edcPaperObj.MoveLensOnScroll(e, lens, paperMainContainer, paperSmallScale);
			}



			$("#paper").attr("tabindex", 0).on("mouseover", function () {
				this.focus();
			}).on("keydown", function (e) {
				edcPaperObj.handleKeyevents(e, _this,_this.edcGraph,_this.graphFor);
			});

			var draggingStartAt = {}
		    var isDraggingInProcess = false
		    var rectAreaCells=[]
		    var rubberRect = shapesObj.getRubberBandRect()
		    var rubberRectId = ""
		    var positionChange=false
			// paper and link events
			mainPaper.on("link:connect",function(linkView){
				let link = linkView.model
				console.log("before cyclick check",JSON.stringify(new Date()))
				var is_cycle_exists = edcLinkObj.RestrictCyclicConnection(
					_this.edcGraph,
					graphlib,
					linkView
				);
				console.log("after cyclick check",JSON.stringify(new Date()))
				if (is_cycle_exists) return false;
				console.log("before validate outgoing",JSON.stringify(new Date()))
				var valid_outgoing_link = edcLinkObj.ValidateOutgoingLink(_this.edcGraph, linkView);
				console.log("after validate outgoing",JSON.stringify(new Date()))
				if (!valid_outgoing_link) return false;
				console.log("before validate incoming",JSON.stringify(new Date()))
				var valid_incoming_link = edcLinkObj.ValidateIncomingLink(_this.edcGraph, linkView);
				console.log("after validate incoming",JSON.stringify(new Date()))
				if (valid_incoming_link) {
					let linkHierarchyName= ''
					if(_this.isGraphForBusinessObject)
						linkHierarchyName =edcLinkObj.GetHierarchyName(_this.edcGraph,link.target().id,linkHierarchyName)
					link.prop('hierarchy_name',linkHierarchyName)
					_this.sendLinkEvent("connect",{"link":link,"source":_this.edcGraph.getCell(link.source().id),"target":_this.edcGraph.getCell(link.target().id)})
				}
				
			}).on("link:pointerdblclick",function(linkView){
				let link = linkView.model
				let source = _this.edcGraph.getCell(link.source().id)
				let target = _this.edcGraph.getCell(link.target().id)
				_this.sendLinkEvent('doubleclick',{"link":link,"source":source,"target":target})
				
			}).on("link:contextmenu",function(linkView){

			}).on('link:mouseenter', function(linkView) {
		        linkView.showTools();
		    }).on('link:mouseleave', function(linkView) {
		        linkView.hideTools();
		    })

			// paper and element events
			mainPaper.on("element:mouseover",function(elementView){
				let element = elementView.model
				_this.isCellSelected = true
				_this.selectedElementForContextMenu = element

				
				// to add the shadow to the all embedded cells
				let embeddedCell = edcAnonymousObj.getEmbeddedCell(element)
				if(!embeddedCell.prop('is_embed'))
					return				
				// embeddedCell.attr('border/filter',edcAnonymousObj.addShadowToElement())
				embeddedCell.attr('border/strokeWidth',20)
				embeddedCell.attr('border/stroke','transparent')

				if(_this.isGraphForProcessDesigner)
					shapesObj.manageShapeIcon(embeddedCell,true)

			}).on("element:mouseout",function(elementView){

			}).on('element:mouseleave',function(elementView){
		    	let element = elementView.model
		    	element.removeAttr('border/filter')
		    	// to remove the shadow from the all embedded cells
		    	let embeddedCell = edcAnonymousObj.getEmbeddedCell(element)
		    	if(!embeddedCell.prop('is_embed'))
					return
				// embeddedCell.removeAttr('border/filter')
		    	embeddedCell.attr('border/strokeWidth',2)
		    	embeddedCell.attr('border/stroke','#000000')
				if(_this.isGraphForProcessDesigner)
					shapesObj.manageShapeIcon(embeddedCell,false)

		    }).on("element:pointerdblclick",function(elementView,event){
		    	let element = elementView.model
				if(element.prop('is_rubber'))
					return
				rubberbandObj.removeRubberBandRect(_this,rubberRectId,mainPaper,rectAreaCells)
				let embeddedCell = edcAnonymousObj.getEmbeddedCell(element)
				if (event.target && event.target.tagName && (event.target.tagName.toUpperCase() === 'TEXT'||event.target.tagName.toLowerCase() === 'tspan')){
					_this.selectedElementForContextMenu = {}
					if(_this.isGraphForProcessDesigner && !_this.isReadOnly){
						let node = document.createElement("INPUT");  
						let oldText = embeddedCell.prop('stepname')       
						node.setAttribute("type", "text");    
						node.setAttribute("value", embeddedCell.prop('stepname'));      
						node.setAttribute("id", "htmltext"); 
						node.setAttribute("edc_element_id",element.id)            
						node.style.position = 'absolute';
						node.style.left = (element.position().x+5)+'px';
						node.style.top = (element.position().y+element.size().height+12)+'px';
						node.style.width = (element.size().width)+'px';
						node.style['font-size'] = "10px";
						node.focus()
						node.addEventListener("blur", function(obj){
							// alert(obj)
							let newText = document.getElementById('htmltext').value
							if(!newText)
								newText = oldText
							newText = newText
							element.attr('label/text',edcAnonymousObj.getWrappedText(newText,element.size().width,joint,2,10))
							element.attr('label/title',newText)
							embeddedCell.prop('stepname',newText)
							if(node){
								node.remove()
							}
						let update_details = {"action_type":"step_name_changed","update_details":{"new_step_name":newText,"step_id":embeddedCell.id,"step_name":oldText}}
						_this.updateStepReferenceForProcessDesigner(update_details)
						_this.sendNonGraphEvent('step_name_changed',update_details)
						_this.operationPerformOnWrapper()
						});
						node.addEventListener("keydown", function(obj){
							if(obj.keyCode === 13){
								let newText = document.getElementById('htmltext').value
								if(!newText)
									newText = oldText
								newText = newText
								let wrappedText = edcAnonymousObj.getWrappedText(newText,element.size().width,joint,2,10)
								element.attr('label/text',wrappedText)
								embeddedCell.prop('stepname',newText)
								element.attr('label/title',newText)
								if(node)
									node.remove()
							}
						});

						mainPaper.el.appendChild(node);
						element.attr('label/text','')
					}
					return
				}
				_this.sendElementEvent('doubleclick',{"element":embeddedCell})

			}).on("element:pointerclick",function(elementView){

				let element = elementView.model
				if(element.prop('is_rubber'))
					return
				rubberbandObj.removeRubberBandRect(_this,rubberRectId,mainPaper,rectAreaCells)
				_this.sendElementEvent('click',{"element":element})

			}).on("element:contextmenu",function(elementView){
				let element = elementView.model
				if(_this.isGraphForProcessDesigner)
					_this.$refs.ctxMenu.open();
				else if(_this.isGraphForBusinessObject){
					_this.selectedElementForContextMenu = element
					_this.rightClickOn = element.prop('edc_cell_type')
					if(_this.isRightClickOnBusinessKey){
						_this.$refs.identifierCntxMenu.open()
					}
					else if(_this.isRightClickOnBusinessCell){
						_this.$refs.cellCntxMenu.open()
					}
				}
			}).on("element:pointerup",function(elementView){
				if(positionChange){
					// to avoid smart routing load, we are emiting graph change event here
					edcAnonymousObj.PerformSmartRouting(_this.edcGraph,elementView.model,mainPaper,paperSmall)
					_this.emitGraphData('change')
					positionChange = false
				}
			})

			// paper and cell events
			mainPaper.on("cell:pointerup",function(elementView){

			})


			// only paper events
			mainPaper.on("blank:contextmenu",function(){
				if(_this.isGraphForProcessDesigner)
					_this.$refs.ctxMenu.open();
			}).on("blank:mouseover",function(){
				_this.isCellSelected = false
				_this.selectedElementForContextMenu = {}
			}).on("blank:pointerdown",function(event){
				isDraggingInProcess = true
				draggingStartAt = {'x':event.offsetX,'y':event.offsetY}
			}).on("blank:pointerup",function(event){
				isDraggingInProcess = false
				rectAreaCells = rubberbandObj.resizeRubberRect(_this,rubberRectId,joint,mainPaper,rectAreaCells)

			}).on('blank:pointermove',function(event){
				if(isDraggingInProcess)
					rubberbandObj.drawRubberRect(_this,rubberRectId,rubberRect,draggingStartAt,event)
			}).on('blank:pointerclick',function(event){
				rubberbandObj.removeRubberBandRect(_this,rubberRectId,mainPaper,rectAreaCells)
			})

			// graph and cell events
			edcGraph.on("change:position",function(cell,position,event){
				if(cell.prop('is_rubber')){
					rubberbandObj.moveRubberBandRect(_this,joint,rubberRectId,mainPaper,rectAreaCells,event)
				}
				else
				{
					positionChange = true
					// return
					// // need to write logic to perform smart routing after user stop moving the cell
					// clearInterval(smartRoutingTimer)
					// smartRoutingTimer = setTimeout(function(){
					// 	edcAnonymousObj.PerformSmartRouting(_this.edcGraph,cell,mainPaper,paperSmall)
					// 	alert('smart route performed')
					// 	clearInterval(smartRoutingTimer)
					// 	},2000)
				}
				
			}).on("add", function (cell) {

				if(cell.prop('is_wrapper'))
					return

				if(cell.prop('is_rubber'))
					rubberRectId = cell.id
				if(cell.get('type') === 'link')
				{
					edcLinktoolObj.manageLinktools(cell,mainPaper,joint,_this)
				}
				else if (cell.prop('edc_cell_type')){
					let kwargs = {
						'element':cell
					}
					_this.sendElementEvent('add',kwargs)
					edcAnonymousObj.PerformSmartRouting(_this.edcGraph,cell,mainPaper,paperSmall)
				}
				_this.emitGraphData('add')
				_this.operationPerformOnWrapper()
			}).on("remove", function (cell,custom,eventSource) {
				if(cell.prop('edc_cell_type')){
					let kwargs = {
							'element':cell
						}
					_this.sendElementEvent('remove',kwargs)	
				}
				else if(cell.isLink()){
					_this.sendLinkEvent('remove',{'link':cell,'eventSource':eventSource})
				}
				_this.emitGraphData('remove')
				_this.operationPerformOnWrapper()
			})

			_this.manageBusinessObjectShape('newdesign')
			if (!this.graphTimer) 
				this.graphTimer = setInterval(this.emitGraphData, 3000);
		},
		methods:{
			manageColumnRemovedForUndoRedo(update_details,referenceDetails,isRecursive=false){
				var _this = this
				let column_removed = update_details.columns_removed[0]
				let current_column =  column_removed.column_name
					if(column_removed.alias_name)
						current_column = column_removed.alias_name
				let caller_step_id = update_details.step_id
			    let caller_step_name = update_details.step_name
				let allElements = this.edcGraph.getElements().filter(element=>element.id !=caller_step_id && element.prop('is_embed'))

				if(!allElements || !allElements.length)
					return
				for(let i=0;i<allElements.length;i++){
					let currentElement = allElements[i]
					let step_data = currentElement.prop('step_data')
					if(!step_data || _.isEmpty(step_data))
						continue

					if(['Archival','Copy','Purge','Duplicate'].indexOf(currentElement.prop('steptype')) > -1){
						if(!step_data.drv_table || _.isEmpty(step_data.drv_table))
							continue
						if(!step_data.selected_table_data || !step_data.drv_table.select_table_list || step_data.drv_table.select_table_list.length != 1 || step_data.drv_table.select_table_list[0] != caller_step_name)
							continue

						let replaceJson = {}
						if(step_data.drv_table.selection){
  							let index = step_data.drv_table.selection.indexOf(current_column)
  							if(index>-1)
  								replaceJson = {'selection':{"index":index,'column':current_column}}
						}
						if(step_data.drv_table.order_by){
							let obj = _.find(step_data.drv_table.order_by,["column_name",current_column])
  							if(obj)
  								replaceJson['order_by'] = {"index":step_data.drv_table.order_by.indexOf(obj),"order_column":_.cloneDeep(obj)}
						}
						replaceJson['step_id'] = currentElement.id
						replaceJson['steptype'] = currentElement.prop('steptype') 
						replaceJson['isRecursive'] = isRecursive
						referenceDetails.push(_.cloneDeep(replaceJson))
					}
					else if(['Selection','Merge','Minus'].indexOf(currentElement.prop('steptype')) > -1 && update_details.columnDeletionType !="only_from_current_step"){
						// in case only current step deletion we are not removing referene from the dataselection step, but we do for the archival steps.
						let replaceJson = {}
						if(step_data.selection && step_data.selection.length){
							let columns_from_selection = []
							for(let i=0;i<step_data.selection.length;i++){
								let current_row = step_data.selection[i]
				      			if(current_row.table_name === caller_step_name && (current_row.is_drv_table === 'true' || current_row.is_drv_table === true)&&current_row.column_name === current_column){
				      				columns_from_selection.push({'index':i,'selection':current_row})
				      				if(update_details.columnDeletionType==='delete_cascading_reference'){
										let new_update_data ={"step_id":currentElement.id,"step_name":currentElement.prop('stepname'),"columns_removed":[current_row],'columnDeletionType':update_details.columnDeletionType,'removedColumnIndex':i}
										_this.manageColumnRemovedForUndoRedo(new_update_data,referenceDetails,true)
									}
									break
				      			}
							}
							if(columns_from_selection.length)
								replaceJson['columns_from_selection'] = columns_from_selection
						}
						if(step_data.join){
							let joins = []
							for(let i=0;i<step_data.join.length;i++){
								let current_join = step_data.join[i]
		      					let key = _.keys(current_join)[0]
		      					let join_json = current_join[key]
      							if((join_json.jfrom === caller_step_name && (join_json.jfrom_drv_table === "true" || join_json.jfrom_drv_table === true)) ||(join_json.jto === caller_step_name && (join_json.jto_drv_table === "true" || join_json.jto_drv_table === true))){
		      						let conditions = join_json.condition
		      						for(let j=0;j<conditions.length;j++){
		      							let current_condtion = conditions[j]
		      							if(current_condtion.from_column === current_column || current_condtion.to_column === current_column){
		      								joins.push({'link_id':key,'join_json':join_json})
      										break;
      									}
		      						}
      							} 
							}
							if(joins.length)
								replaceJson['joins'] = joins
						}
						if(step_data.filter){
							let filters={}
							for(let i=0;i<step_data.filter.length;i++){
								let current_row = step_data.filter[i]
								var column_name = current_row.column_name
  								var table_name = _.split(column_name,'.')[0]
  								var filter_column_name = _.split(column_name,'.')[1]
  								if(table_name!=caller_step_name || current_row.is_drv_table === 'false' || current_row.is_drv_table === false)
  									continue
  								if(filter_column_name === current_column){
  									filters['filter'] = step_data.filter
  									filters['main_filterUI'] = step_data.main_filterUI
  									filters['secondary_filterUI'] = step_data.secondary_filterUI
  									break;
  								}
							}
							if(filters.filter)
								replaceJson['filters'] = filters
						}
						if(!_.isEmpty(replaceJson)){
							replaceJson['step_id'] = currentElement.id
							replaceJson['steptype'] = currentElement.prop('steptype') 
							referenceDetails.push(_.cloneDeep(replaceJson))
						}
					}					
				}
				return referenceDetails
			},
			emitGraphData(eventtype='updateparentgraph'){
				let graphData = graphObj.zipEdcGraph(_.cloneDeep(this.edcGraph),this.graphFor)
				this.sendGraphEvent('self',eventtype,{'graphData':graphData,'edcGraph':_.cloneDeep(this.edcGraph)})
			},
			setPaperDimentions(){
				if(!this.mainPaperDimentions)
					return
				this.paperWidth = this.mainPaperDimentions.width
				this.paperHeight = this.mainPaperDimentions.height
				return true
			},
			ZoomAction(action) {
				edcZoomObj.performZoomActions(this, action);
			},
			manageBusinessObjectShape(action){
				let _this = this
				if(!_this.isGraphForBusinessObject)
					return
				if(action === 'newdesign'){
					let businessList = shapesObj.getBusinessObjectKeysCell()
					if(!businessList || businessList.length!=2)
						alert('Error in creating business object cell')
					_this.businessObjectCell = businessList[0]
					_this.businessObjectCellWrapper = businessList[1]
					_this.edcGraph.addCells([_this.businessObjectCell,_this.businessObjectCellWrapper])
				}
				if(action === 'newdesign' || action==='redesign'){
					let eventData = {
						"element":_this.businessObjectCell,
						"wrapper":_this.businessObjectCellWrapper
					}
					_this.sendElementEvent('addbusinessshape',eventData)
				}
				if(action === 'visible' || action==='redesign'){
					_this.businessObjectCell.attr('./visibility', 'visible')
					_this.businessObjectCellWrapper.attr('./visibility', 'visible')
				} else if(action === 'hidden'){
					_this.businessObjectCell.attr('./visibility', 'hidden')
					_this.businessObjectCellWrapper.attr('./visibility', 'hidden')
				}
				else if(action === 'remove'){

				}
			},
			manageNewObject(newObject){
				if(newObject.edc_type === 'bo_table'){
					let newTableCells = shapesObj.getEdcTableShapeWithWrapper(newObject)
					// newTableCell.addTo(this.edcGraph)
					this.edcGraph.addCells(newTableCells)
				}
				else if(newObject.edc_type === 'bo_auto_link'){
					let newlink = edcLinkObj.getDefaultLinkProperies()
					newlink.prop("labels/0/attrs/text/text","1")
					newlink.prop("labels/1/attrs/text/text","1")
					newlink.prop("has_flattening_restriction",true)
					newlink.source(newObject.source)
					newlink.target(newObject.target)
					newlink.addTo(this.edcGraph)

					this.sendLinkEvent('addbusinesslink',{"link":newlink,"target":newObject.target})
				}
				else if(newObject.edc_type === 'process_designer' && newObject.is_element){
					// add element to graph
					if(newObject.is_dragged)
						edcPaperObj.dragDropOnMainPaper(newObject, this.mainPaper,this.edcGraph,this)
					else if(newObject.is_redo_undo){
						this.edcGraph.addCells(graphObj.unzipElement(this,newObject.element,this.graphFor));
					} else {
						console.log('wrong choice')
					}
				}
				else if(newObject.edc_type === 'process_designer' && newObject.is_link){
					if(newObject.is_redo_undo)
						this.addGraphLink(newObject.link)
				}

				if(newObject.relatedLinks && newObject.relatedLinks.length){
					for(let i=0;i<newObject.relatedLinks.length;i++){
						this.addGraphLink(newObject.relatedLinks[i])
					}
				}
			},
			addGraphLink(link){
				let graphElements = this.edcGraph.getElements()
				/* Here we need to add each link in between the cell. Before that we need to make sure source and target elements are present on the graph.*/
				if(!link.source ||!link.source.id ||!link.target ||!link.target.id)
					return
				if(!this.edcGraph.getCell(link.source.id) || !this.edcGraph.getCell(link.target.id))
					return
				let unZippedLink = graphObj.unzipLink(link,this.graphFor,graphElements)
				this.edcGraph.addCells(unZippedLink)
				return unZippedLink
			},
			sendPaperEvents(eventSource,eventType,kwargs){
				
			},
			sendGraphEvent(eventSource,eventType,kwargs){
				this.$emit('sendGraphEvent',eventType,kwargs)
			},
			sendCellEvents(eventType,kwargs){

			},
			sendElementEvent(eventType,kwargs){
				// create UnDo/ReDo stack object
				let stackObj = {}
				if(eventType === 'add_element'||eventType === 'remove_element'){
					if(kwargs && kwargs.element && kwargs.element.prop('is_embed')){
						let zipElement = graphObj.zipElement(kwargs.element,this.graphFor)
						if(eventType === 'add_element'){
							stackObj = {"user_action":'add_element',"element":zipElement,"action_to_take":'remove_element'}			
						}else if(eventType === 'remove_element'){
							stackObj = {"user_action":'remove_element',"element":zipElement,"action_to_take":'add_element','relatedLinks':kwargs.relatedLinks}
							
						}
					}
				}
				if(!_.isEmpty(stackObj))
					kwargs['stackObj'] = stackObj
				this.$emit('sendElementEvent',eventType,kwargs)
			},
			sendLinkEvent(eventType,kwargs){
				let _this = this
				let zippedLink = graphObj.zipLink(kwargs.link,_this.graphFor)
				if(eventType === 'remove'){
					/* 
						If eventSource exists it means user deleted that link using delete icon. 
					*/
					let eventSource = kwargs.eventSource
					if(eventSource && eventSource.tool){
						kwargs['stackObj'] = {"user_action":"remove_link","link":zippedLink,'action_to_take':'add_link'}
					}
				}
				else if(eventType === 'connect'){
					kwargs['stackObj'] = {"user_action":"add_link","link":zippedLink,'action_to_take':'remove_link'}
				}

				this.$emit('sendLinkEvent',eventType,kwargs)
			},
			sendNonGraphEvent(eventType,kwargs){
				this.$emit('sendNonGraphEvent',eventType,kwargs)
			},
			contextMenuClicked(actionType){
				edcContextMenuObj.contextMenuItemClick(this,actionType,this.edcGraph,this.graphFor)
			},
			mouseOverEvent(obj,condition){
				edcContextMenuObj.mouseOverEvent(this,obj,condition)
			},
			mouseOutEvent(obj){
				edcContextMenuObj.mouseOutEvent(this,obj)
			},
			manageContextMenu(action){
				let element = edcAnonymousObj.getEmbeddedCell(this.edcGraph.getCell(this.selectedElementForContextMenu))
				let kwargs = {"element":element}
				this.sendElementEvent(action,kwargs)
			},
			// manageContextMenuForLink(action){
			// 	let kwargs = {"link":this.selectedLinkForContextMenu}
			// 	this.sendLinkEvent(action,kwargs)
			// },
			manageFilterIcon(details){
				let element = edcAnonymousObj.getEmbeddedCell(this.edcGraph.getCell(details.cell_id))
				if(element.prop('edc_cell_type'))
				{
					if(details.action === 'add')
						shapesObj.addFilterToShape(element)
					else
						shapesObj.removeFilterFromShape(element)
				}
				this.emitGraphData('cellpropchange')
			},
			removeCell(cellData){
				let cell_id = cellData.cell_id || cellData.link_id
				if(this.edcGraph.getCell(cell_id)){
					if(this.edcGraph.getCell(cell_id).getParentCell())
						this.edcGraph.getCell(cell_id).getParentCell().remove()
					else
						this.edcGraph.getCell(cell_id).remove()
				}
			},
			RedesignGraph(graphData){
				let _this = this
				let unzippedGraph = graphObj.unzipGraph(_this,graphData,_this.graphFor)
				this.edcGraph.fromJSON(JSON.parse(JSON.stringify(unzippedGraph)));
				// add link tools
				let links = this.edcGraph.getLinks()
				links.forEach(link=>{
					edcLinktoolObj.manageLinktools(link,_this.mainPaper,joint,_this)
				})
				if(_this.isGraphForBusinessObject){
					this.businessObjectCell = _this.edcGraph.getCell(this.businessObjectCell.id)
					this.businessObjectCellWrapper = _this.edcGraph.getCell(this.businessObjectCellWrapper.id)

					// add description to the table
					let elements = this.edcGraph.getElements()
					let elementsWithoutDescription = []
					elements.forEach(element=>{
						if(element.prop('is_special_cell') || !element.prop('is_embed')||element.prop('table_type') != 'table')
							return
						// if table_name and table_description is equal it means description needed
						if(element.prop('table_name') === element.prop('table_description'))
							elementsWithoutDescription.push(element.prop('table_name'))
					})
					if(elementsWithoutDescription.length)
						_this.$emit('loadTableDescription',elementsWithoutDescription)
				}
				if(_this.isGraphForProcessDesigner){
					// add description to the table
					let elements = this.edcGraph.getElements()
					elements.forEach(element=>{
						if(element.prop('steptype') === 'Report'){
							let parentCell = element.getParentCell()
							this.edcGraph.getCell(parentCell.id).remove()
							// this.edcGraph.getCell(element.id).remove()
						}
					})
				}
				_this.operationPerformOnWrapper()
				_this.manageBusinessObjectShape('redesign')
				_this.emitGraphData('redesign')
			},
			updateStepData(dataToUpdate){
				let element = this.edcGraph.getCell(dataToUpdate.step_id)
				element.prop('stepname', dataToUpdate.stepname)
				element.prop('step_data', dataToUpdate.step_data)
				element.prop('bus_id', dataToUpdate.bus_id)
				element.prop('bussinessObjectName', dataToUpdate.bussinessObjectName)
				element.prop('bus_id', dataToUpdate.bus_id)
				edcAnonymousObj.updateParentWrapperText(element)
			},
			autoArrangeEDCGraph(){
				let cells = this.edcGraph.getCells()
				let cellsWithoutWrapper = []
				cells.forEach(cell=>{
					if(cell.prop('is_embed') || cell.isLink())
						cellsWithoutWrapper.push(cell)
				})
				let revised_cells = hierarchyObj.createBusinessObjHierarchy(cellsWithoutWrapper)
				console.log('cells',revised_cells)
				for(var i=0;i<revised_cells.length;i++){
					for(var j=0;j<cells.length;j++){
						if(revised_cells[i].id === cells[j].id){
							let translateX = revised_cells[i].position.x
							let translateY = revised_cells[i].position.y
							cells[j].position(translateX,translateY)
							let parentCell = cells[j].getParentCell()
							parentCell.position(translateX,translateY)
							break;
						}
					}
				}
			},
			updateStepReferenceForProcessDesigner(updatedReferenceDetails){
				try{
					console.log('updatedReferenceDetails',updatedReferenceDetails)
					// code need to write for alias change. column delete then reflect in join etc
					var update_details = updatedReferenceDetails.update_details
					var action_type = updatedReferenceDetails.action_type
					if(action_type === 'update_column_references'){
						this.updateColumnReferences(update_details)
						return
					}
					var column_to_remove = ''
					var columnDeletionType = update_details.columnDeletionType
					if(action_type === 'columns_removed' && update_details.columns_removed.length){
						column_to_remove = update_details.columns_removed[0].column_name
						if(update_details.columns_removed[0].alias_name)
							column_to_remove = update_details.columns_removed[0].alias_name
					}
					var cells = this.edcGraph.getElements()
			      	if(cells.length <= 1)
			      	// 1 cell available means only that caller cell available
			      		return
			      	var caller_step_id = update_details.step_id
			      	var caller_step_name = update_details.step_name

			      	for (var i = 0; i < cells.length; i++) {
			      		var current_el = cells[i]
			      		// no change for current cell
			      		if(current_el.id === caller_step_id)
			      			continue

			      		// check current step has step_data or not
			      		if(!current_el.prop('step_data'))
			      			continue

			      		// no need to check for procedure
			      		if(current_el.prop('steptype') === 'Procedure')
			      			continue

			      		var step_data = current_el.prop('step_data') 
			      		if(['Archival','Copy','Purge','Report','Duplicate'].indexOf(current_el.prop('steptype')) > -1){
			      			// if drv table json not present in step_data. No need to check
			      			if(!step_data.drv_table)
			      				continue

			      			// if driver is not as step then continue
			      			if(!step_data.drv_table.is_drv_table || step_data.drv_table.is_drv_table === "false") 
			      				continue

			      			if(step_data.selected_table_data && step_data.drv_table.select_table_list && step_data.drv_table.select_table_list.length === 1 && step_data.drv_table.select_table_list[0] === caller_step_name){

			      				// delete cell
			      				if(action_type === "step_deleted"){
			      					step_data.selected_table_data = {}
			      					step_data.drv_table = {}
			      				}

			      				if(action_type === 'step_name_changed'){

			      					step_data.selected_table_data ={"title":update_details.new_step_name,"type":"S"}

			      					step_data.drv_table.select_table_list[0] = update_details.new_step_name
			      				}
			      				else if(action_type === 'add_columns'){
			      					for (var j = 0; j < update_details.column_names.length; j++) {
			      					let column_name_to_add = update_details.column_names[j].column_name
			      					if(update_details.column_names[j].alias_name)
			      						column_name_to_add = update_details.column_names[j].alias_name

			      					step_data.drv_table.order_by.splice(update_details.column_names[j].index,0,{"column_name":column_name_to_add,"type":false,"report_alias_name": update_details.column_names[j].report_alias_name})
			      					step_data.drv_table.selection.splice(update_details.column_names[j].index,0,column_name_to_add)
			      					 } 
			      				}
			      				else if(action_type === "columns_removed"){
			      					var columns_removed = update_details.columns_removed
			      					for(var j=0;j < columns_removed.length;j++){
			      						var current_column_json = columns_removed[j]
			      						var current_column =  current_column_json.column_name
			      						if(current_column_json.alias_name)
			      							current_column = current_column_json.alias_name
			      						step_data.drv_table.selection.splice(step_data.drv_table.selection.indexOf(current_column),1)
			      						var obj = _.find(step_data.drv_table.order_by,["column_name",current_column])
			      						if(obj)
			      							step_data.drv_table.order_by.splice(step_data.drv_table.order_by.indexOf(obj),1)
			      					}
			      				}
			      				else if (action_type === 'column_alias_change'){
			      					// if alias is changed,then just remove all order by and and selection and place new one
			      					let all_columns = update_details.all_columns
			      					step_data.drv_table.selection = []
			      					step_data.drv_table.order_by = []
			      					for(let i=0;i<all_columns.length;i++){
			      						var current_column_json = all_columns[i]
			      						var current_column =  current_column_json.column_name
			      						if(current_column_json.alias_name)
			      							current_column = current_column_json.alias_name
			      						step_data.drv_table.order_by.push({"column_name":current_column,"type":false})
			      						step_data.drv_table.selection.push(current_column)
			      					}
			      				}
			      			}

			      		}
			      		else if(['Selection','Merge','Minus'].indexOf(current_el.prop('steptype')) > -1){

			      			if(action_type === 'step_name_changed' || action_type === "step_deleted" || action_type === 'column_alias_change' || action_type === "columns_removed"){
			      				// in case of column remove, if columnDeletionType is only_from_current_step then we no need to delete from the dataselection step but we have to delete from archival,purge,copy step.
			      				if( action_type === "columns_removed" && columnDeletionType==='only_from_current_step')
			      					continue
			      			// change graph cells
				      			if(step_data.graph && step_data.graph.cells){
				      				for(var j=step_data.graph.cells.length - 1; j>=0;j--){
				      					var current_cell = step_data.graph.cells[j]
				      					if(current_cell.type === "link")
				      						continue
				      					if(current_cell.table_name && current_cell.table_name === caller_step_name && (current_cell.is_drv_table === 'true' || current_cell.is_drv_table === true) && current_cell.table_type==='step'){
				      						if(action_type === 'step_name_changed'){
					      						current_cell['table_name'] = update_details.new_step_name
					      						current_cell['table_description'] =  update_details.new_step_name
				      						}
				      						else if(action_type === "step_deleted"){
				      							step_data.graph.cells.splice(j,1)
				      							// now delete related links
				      							
				      							for (var k = step_data.graph.cells.length - 1; k >= 0; k--) {
				      								var obj = step_data.graph.cells[k]
				      								var obj_id = ''
				      								if(obj.type === "link" && (current_cell.id === obj.target.id || current_cell.id === obj.source.id)){
				      									obj_id = obj.id
				      									step_data.graph.cells.splice(k,1)

				      									//delete join related to that link
										      			if(step_data.join){
										      				for (var l = 0; l < step_data.join.length; l++) {
										      					var current_row = step_data.join[l]
										      					var key = _.keys(current_row)[0]
										      					if(obj_id === key)
										      						step_data.join.splice(l,1)
										      				}
										      			}
				      								}
				      							}
				      						}
				      					} 
				      				}
				      			}
				      			// change in selection
				      			if(step_data.selection){
				      				for(var j=step_data.selection.length-1; j>=0;j--){
				      					var current_row = step_data.selection[j]
				      					if(current_row.table_name === caller_step_name && (current_row.is_drv_table === 'true' || current_row.is_drv_table === true)){
				      						if(action_type === 'step_name_changed')
				      							current_row.table_name = update_details.new_step_name
				      						else if(action_type === "step_deleted")
				      							step_data.selection.splice(j,1)
				      						else if(action_type === 'column_alias_change'){
				      							if(current_row.column_name === update_details.old_alias)
				      								current_row.column_name = update_details.new_alias
				      						} else if(action_type === 'columns_removed' && current_row.column_name === column_to_remove){
				      							step_data.selection.splice(j,1)
			      								// write recurrusion. RECURSION commited because nirav suggest that we should not delete anything in CASCAD way.
			      								if(columnDeletionType==='delete_cascading_reference'){
			      									let update_data ={"step_id":current_el.id,"step_name":current_el.prop('stepname'),"columns_removed":[current_row],'columnDeletionType':columnDeletionType}
			      									this.updateStepReferenceForProcessDesigner({"action_type":"columns_removed","update_details":update_data})
			      								}
				      						}
				      					}	
				      				}
				      			}

				      			// change for filter
				      			if(step_data.filter){
				      				this.manageSelectionFilter(action_type,caller_step_name,update_details, step_data.filter)
				      			}
				      			// change for filter
				      			if(step_data.main_filterUI){
				      				this.manageSelectionFilter(action_type,caller_step_name,update_details, step_data.main_filterUI)
				      			}

				      			// change the table list
				      			if(step_data.new_select_table_list){
				      				for(var j=step_data.new_select_table_list.length-1; j>=0;j--){
				      					var current_tbl = step_data.new_select_table_list[j]
				      					if(current_tbl.table_type === 'step' && current_tbl.table_name === caller_step_name)
				      						if(action_type === 'step_name_changed')
				      							current_tbl.table_name = update_details.new_step_name
				      						else if(action_type === 'step_deleted')
				      							step_data.new_select_table_list.splice(j,1)
				      				}
				      				step_data.select_table_list = _.map(step_data.new_select_table_list,"table_name") 
				      			}

				      			// change the join list
				      			if(step_data.join){
				      				for (var j = 0; j < step_data.join.length; j++) {
				      					var current_row = step_data.join[j]
				      					var key = _.keys(current_row)[0]
				      					var join_json = current_row[key]
				      					if(join_json.jfrom === caller_step_name && (join_json.jfrom_drv_table === "true" || join_json.jfrom_drv_table === true) && action_type === 'step_name_changed'){
				      						join_json.jfrom = update_details.new_step_name
				      					}

				      					if(join_json.jto === caller_step_name && (join_json.jto_drv_table === "true" || join_json.jto_drv_table === true) && action_type === 'step_name_changed'){
				      						join_json.jto = update_details.new_step_name
				      					}

				      					var conditions = current_row[key].condition
				      					for (var k = 0; k < conditions.length; k++) {
				      						var current_condtion = conditions[k]
				      						if(action_type === 'step_name_changed'){
				      							current_condtion.jfrom = join_json.jfrom
				      							current_condtion.jto = join_json.jto
				      						} else if(action_type === 'column_alias_change'){
				      							if(join_json.jfrom === caller_step_name && (join_json.jfrom_drv_table === "true" || join_json.jfrom_drv_table === true)){
				      								if(current_condtion.from_column === update_details.old_alias)
				      									current_condtion.from_column = update_details.new_alias
				      							} else if(join_json.jto === caller_step_name && (join_json.jto_drv_table === "true" || join_json.jto_drv_table === true)){
				      								if(current_condtion.to_column === update_details.old_alias)
				      									current_condtion.to_column = update_details.new_alias
				      							}
				      						} else if(action_type === 'columns_removed'){
				      							if((join_json.jfrom === caller_step_name && (join_json.jfrom_drv_table === "true" || join_json.jfrom_drv_table === true)) ||(join_json.jto === caller_step_name && (join_json.jto_drv_table === "true" || join_json.jto_drv_table === true))){
				      								if(current_condtion.from_column === column_to_remove || current_condtion.to_column === column_to_remove){
				      									conditions.splice(k,1)
				      								}
				      							} 
				      						}
				      					}
				      				}
				      			}
			      			} // action_type === 'step_name_changed' block end
			      		}
			      	} // end of cells.length loop
			    }
		      	catch(error){
		      		this.snackbar = true
		            this.colorValue = 'error'
		            this.snackbartext = 'Error in updatation';
		      		console.log("Error in updatation",error)
		      	}
			}, // end of function
			manageSelectionFilter(action_type,caller_step_name,update_details,filter){
				for(var j=filter.length-1; j>=0;j--){
  					var current_row = filter[j]
  					if(current_row.is_drv_table === 'false' || current_row.is_drv_table === false)
  						continue

  					var column_name = current_row.column_name
  					var table_name = _.split(column_name,'.')[0]
  					var filter_column_name = _.split(column_name,'.')[1]
  					if(table_name === caller_step_name){
  						if(action_type === 'step_name_changed')
  						current_row.column_name = _.replace(column_name,table_name+".",update_details.new_step_name+".")
  						else if(action_type === 'step_deleted'){
  							filter.splice(j,1)	
  						} else if (action_type === 'column_alias_change'){
  							current_row.column_name = _.replace(column_name,"."+update_details.old_alias,"."+update_details.new_alias)
  						} else if (action_type === 'columns_removed' && filter_column_name===update_details.columns_removed[0].column_name){
  							filter.splice(j,1)
  						}
  					}
  				}
  				//now make sure you have removed operation key from last filter else it will create issue at the time of query generation
  				if(filter.length > 0)
  					delete filter[filter.length-1].operation
			},
			updateColumnReferences(allReferences){
				let allValidStepIds = _.map(allReferences,"step_id")
				let elements = this.edcGraph.getElements().filter(element=>allValidStepIds.indexOf(element.id)>-1)
				for(let i=0;i<elements.length;i++){
					let currentElement = elements[i]
					let currentReference = _.find(allReferences,['step_id',currentElement.id])
						if(!currentReference)
							continue
					let step_data = currentElement.prop('step_data')
					if(['Archival','Copy','Purge','Duplicate'].indexOf(currentElement.prop('steptype')) > -1){
						if(!currentReference.isRecursive)
							// non recursive is updated by the selection step it self. for recursive we have to write a code. 
							return
						if(currentReference.selection)
							step_data.drv_table.selection.splice(currentReference.selection.index,0,currentReference.selection.column)
  						if(currentReference.order_by)
  							step_data.drv_table.order_by.splice(currentReference.order_by.index,0,currentReference.order_by.order_column)
					}
					else if(['Selection','Merge','Minus'].indexOf(currentElement.prop('steptype')) > -1){
						
						if(currentReference.columns_from_selection){
							currentReference.columns_from_selection.forEach((obj)=>{
								step_data.selection.splice(obj.index,0,obj.selection)
							})
						}
						if(currentReference.joins){
							for(let j=0;j<step_data.join.length;j++){
								let key = _.keys(step_data.join[j])[0]
								let join_obj = _.find(currentReference.joins,['link_id',key])
								if(join_obj){
									step_data.join[j][key]=join_obj.join_json
									break;
								}
							}
						}
						if(currentReference.filters){
							step_data.filter = currentReference.filters.filter
							step_data.main_filterUI = currentReference.filters.main_filterUI
							step_data.secondary_filterUI = currentReference.filters.secondary_filterUI
						}
					}
				}
			},
			operationPerformOnWrapper(step_to_bold){
				if(this.isGraphForProcessDesigner)
					commonObj.operationPerformOnWrapper(this.edcGraph,1,step_to_bold)
			}
		}
	}
</script>
<style>
#htmltext {
	/*text-transform: uppercase;*/
}
</style>