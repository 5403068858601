export const DEFAULT_DESCRIPTION = ''
import * as constantObj from './constants.js'

var dataselection_steptype = ['selection','merge','minus']
var archival_steptype = ['archival','purge','copy']

export function getColDescription(substringIndex,column_name,all_col_description,is_drv_table)
    {
      if (all_col_description && all_col_description.length == 0)
        return DEFAULT_DESCRIPTION

      // incase of drv table we will match the whole column name. 
      // In case of drv table we storing whle column name
      if (is_drv_table == true || is_drv_table === 'true')
        substringIndex = 0
      let col_alias = column_name.substring(substringIndex)
      var description_obj = _.find(all_col_description,["column_name",col_alias])
      if (description_obj && description_obj.description)
        return description_obj.description
      return DEFAULT_DESCRIPTION
    }

export function getUniqueName(name,element,graph){
  let elements = graph.getElements()
  for (var i = 0; i < elements.length; i++) {
    if(element && element.id === elements[i].id)
        continue
    if(elements[i].prop('edc_cell_type') === constantObj.PROCESS_DESIGNER_EDC_CELL_TYPE && elements[i].prop('stepname') 
        && elements[i].prop('stepname').toLowerCase()===name.toLowerCase()){
      name = name+'(1)'
      // reset tuhe counter
      i=0
    }
  }
  return name
}

export function getAllSelectedBusinessObjsDetails(graph){
    var elements = graph.getElements()
    var business_object_list = []
    if(elements){
      for(var i=0;i<elements.length;i++){
        if(elements[i].prop('steptype') === 'Archival' ||elements[i].prop('steptype') === 'Copy' ||elements[i].prop('steptype') === 'Purge'){
          if(elements[i].prop('bus_id')){
            business_object_list.push({"bus_id":elements[i].prop('bus_id'),"bus_name":elements[i].prop('bussinessObjectName')})
          }
        }
      }
    }
  return business_object_list
}


function getElementDetails(element,include_duplicate) {
    if(!element.prop('is_embed'))
        return
    var source_name = element.prop('stepname')
    var step_data = element.prop('step_data')
    var step_id = element.id
    let seqNo = element.prop('seqNo')
    var step_type = element.prop('steptype') 
    // if(element.prop('steptype') !== 'Merge' && element.prop('steptype') !== 'Minus' && element.prop('steptype') !== 'Selection')
    //     var step_type = element.prop('steptype')
    if(element.prop('steptype') !== 'Merge' && element.prop('steptype') !== 'Minus' 
        && element.prop('steptype') !== 'Selection' && element.prop('steptype') !== 'Duplicate')
        return false
    // if step type is duplicate but include duplicate is false then return false
    if (element.prop('steptype') === 'Duplicate' && !include_duplicate)
        return false
    var column_list = []
    var cols_description = []
    var column_list_references = []
        // debugger;
        if (step_data.selection) {
            var selection_data = step_data.selection
            for (var i = 0; i < selection_data.length; i++) {

                /* this code added for manage column references.
                    As there are few existing designed PDs on cloud, so we need to take care of this code change.
                    Logic is we will store step_id,row_id, alias and column name
                    1) First check step id and row_id values present
                    2) Then add it into the column_list_references variable
                    */
                    if(step_id && selection_data[i].id){
                        column_list_references.push({
                            "step_id":step_id,
                            "step_name": source_name,
                            "row_id":selection_data[i].id,
                            "column_name": selection_data[i].alias_name ? selection_data[i].alias_name:selection_data[i].column_name,
                            "actual_name":selection_data[i].actual_name,
                            "col_format":_.cloneDeep(selection_data[i].col_format)
                        })
                    }

                    // add report description
                    cols_description.push({
                        "column_name":selection_data[i].alias_name ? selection_data[i].alias_name:selection_data[i].column_name,
                        "description":selection_data[i].report_alias_name,
                        "actual_column_name":selection_data[i].column_name
                    })


                    if (selection_data[i].alias_name) {
                        column_list.push(selection_data[i].alias_name)
                        continue;
                    }
                    if (selection_data[i].column_name)
                        column_list.push(selection_data[i].column_name)
                }
            }
            
            else if(step_data.drv_table && step_data.drv_table.selection){
                for(var i=0;i< step_data.drv_table.selection.length;i++){
                    column_list.push(step_data.drv_table.selection[i])
                }
            }

            return { 'source_name': source_name, 'columns': column_list, 'selection':step_data.selection ,
            'step_type':step_type,'column_list_references': column_list_references,"step_id":step_id,
            'cols_description': cols_description,'seqNo':seqNo }
        }

export function getAllIncomingLinksSourceDetails(graph, element, source_detail_list,include_duplicate=false) {
// include_duplicate variable is used to add duplicate step data as selection data incase of report step
var links = graph.getConnectedLinks(element, { inbound: true })
    // var source_detail_list =[]
    for (var i = 0; i < links.length; i++) {
        var source = links[i].get('source')
        var data = getElementDetails(graph.getCell(source.id),include_duplicate)
        if (data)
            source_detail_list.push(data)
        getAllIncomingLinksSourceDetails(graph, graph.getCell(source), source_detail_list, include_duplicate)
    }
return _.sortBy(source_detail_list,"seqNo")
// return source_detail_list
}


function getSelectionSelectedPolicies(step_data,selectedPolicies){
    if(!step_data)
        return selectedPolicies
    if(!step_data.filter)
        return selectedPolicies
    var filters = step_data.filter
    for (var i = filters.length - 1; i >= 0; i--) {
        if(filters[i].v_type1 && filters[i].v_type1.toLowerCase()==='date' && filters[i].datedetails1 && filters[i].datedetails1.is_policy){
            selectedPolicies = fetchPolicyFromDateDetails(filters[i].datedetails1,selectedPolicies)
        }
        if(filters[i].v_type2 && filters[i].v_type2.toLowerCase()==='date' && filters[i].datedetails2 && filters[i].datedetails2.is_policy){
            selectedPolicies = fetchPolicyFromDateDetails(filters[i].datedetails2, selectedPolicies)
        }
    }
    return selectedPolicies
}

function getMergeMinusSelectedPolicies(step_data,selectedPolicies){
    if(!step_data.filter)
        return selectedPolicies
    var all_filters = step_data.filter
    console.log('all_filters',all_filters)
    var keys = Object.keys(all_filters)
    for(var j=keys.length-1;j>=0;j--){
        var filters = all_filters[keys[j]]
        if(!filters)
            continue
        for (var i = filters.length - 1; i >= 0; i--) {
            if(filters[i].v_type1 && filters[i].v_type1.toLowerCase()==='date' && filters[i].datedetails1 && filters[i].datedetails1.is_policy){
                selectedPolicies = fetchPolicyFromDateDetails(filters[i].datedetails1,selectedPolicies)
            }
            if(filters[i].v_type2 && filters[i].v_type2.toLowerCase()==='date' && filters[i].datedetails2 && filters[i].datedetails2.is_policy){
                selectedPolicies = fetchPolicyFromDateDetails(filters[i].datedetails2, selectedPolicies)
            }
        }
    }
    return selectedPolicies
}


function getProcedurePolicies(step_data,selectedPolicies){
    if(!step_data)
        return selectedPolicies
    if(!step_data.params)
        return selectedPolicies
    for(var i=0;i<step_data.params.length;i++){
        if(step_data.params[i].datedetails && step_data.params[i].datedetails.is_policy)
            selectedPolicies = fetchPolicyFromDateDetails(step_data.params[i].datedetails, selectedPolicies)
    }
    return selectedPolicies
}

export function getselectedPolicies(graph){
    var selectedPolicies = []
    var elements = graph.getElements()
    for (var i = elements.length - 1; i >= 0; i--) {
        
        if(!elements[i].attributes.step_data)
            continue
        if(!elements[i].attributes.steptype)
            continue
        var used_policies = []
        if(elements[i].attributes.steptype.toLowerCase() === 'selection' || elements[i].attributes.steptype.toLowerCase() === 'merge' || elements[i].attributes.steptype.toLowerCase() === 'minus'){
            selectedPolicies = getSelectionSelectedPolicies(elements[i].attributes.step_data,selectedPolicies)
        }
        else if(elements[i].attributes.steptype.toLowerCase() === 'archival' || elements[i].attributes.steptype.toLowerCase() === 'copy' || elements[i].attributes.steptype.toLowerCase() === 'purge'){
            selectedPolicies = getSelectionSelectedPolicies(elements[i].attributes.step_data.drv_table,selectedPolicies)
        }
        else if(elements[i].attributes.steptype.toLowerCase() === 'procedure'){
            selectedPolicies = getProcedurePolicies(elements[i].attributes.step_data,selectedPolicies)
        }
    }
    return selectedPolicies
}

function fetchPolicyFromDateDetails(datedetails,selectedPolicies){
    var date_details = {
        'policy_name':datedetails.policy_name,
        'policy_sub_type_name':datedetails.policy_sub_type_name,
        'bo_id':datedetails.bo_id,
        'bo_name':datedetails.bo_name
    }
    if(!_.find(selectedPolicies,date_details))
        selectedPolicies.push(date_details)
    return selectedPolicies
}

export function replacePolicyValues(graph,dataForReplace){
    let elements = graph.getElements()
    for(var i=0;i<elements.length;i++){
        if(!elements[i].prop('steptype'))
            continue
        let step_data = {}
        if(dataselection_steptype.indexOf(elements[i].prop('steptype').toLowerCase())>-1 &&!_.isEmpty(elements[i].prop('step_data'))){
            step_data = elements[i].prop('step_data')
            checkAndReplaceEachFilter(step_data,dataForReplace)
        }
        else if(archival_steptype.indexOf(elements[i].prop('steptype').toLowerCase())>-1 &&!_.isEmpty(elements[i].prop('step_data'))){
            if(_.isEmpty(elements[i].prop('step_data').drv_table))
                continue

            step_data = {"filter":elements[i].prop('step_data').drv_table.filter, 
            'main_filterUI':elements[i].prop('step_data').main_filterUI, 
            "secondary_filterUI":elements[i].prop('step_data').secondary_filterUI}

            checkAndReplaceEachFilter(step_data,dataForReplace)
        }
        else if(elements[i].prop('steptype').toLowerCase() === 'procedure'){
            if(!elements[i].prop('step_data') ||!elements[i].prop('step_data').params)
                continue
            let params = elements[i].prop('step_data').params
            for(let j=0;j<params.length;j++){
                if(params[j].datedetails && params[j].datedetails.is_policy){
                    params[j]['datedetails1'] = pparams[j].datedetails
                    replaceTheBODetailsInPolicy(params[j],false,dataForReplace)
                    params[j]['value'] = params[j]['value1']
                    delete params[j]['datedetails1']
                    delete params[j]['value1']
                }
            }
        }
    }
    return
}

function identifyDateFilter(filter,dataForReplace){
    if(filter.v_type1 && filter.v_type1.toLowerCase()==='date' && filter.datedetails1 && filter.datedetails1.is_policy){
            replaceTheBODetailsInPolicy(filter,false,dataForReplace)
    }
    if(filter.v_type2 && filter.v_type2.toLowerCase()==='date' && filter.datedetails2 && filter.datedetails2.is_policy){
            replaceTheBODetailsInPolicy(filter,false,dataForReplace)
    }
}

function checkAndReplaceEachFilter(step_data,dataForReplace){
    if(!step_data.filter)
        return
    let filters = step_data.filter
    for (var i = filters.length - 1; i >= 0; i--) {
        identifyDateFilter(filters[i],dataForReplace)
    }

    if(!step_data.main_filterUI || !step_data.main_filterUI.length)
        return
    let mainfilters = step_data.main_filterUI
    for (var i = mainfilters.length - 1; i >= 0; i--) {
        identifyDateFilter(mainfilters[i],dataForReplace)
    }

    if(!step_data.secondary_filterUI || !step_data.secondary_filterUI.groupList || !step_data.secondary_filterUI.groupList.length)
        return
    return replaceFilterFromSecondary(step_data.secondary_filterUI.groupList,dataForReplace)

}

function replaceFilterFromSecondary(groupList,dataForReplace){
    for(var i=0;i<groupList.length;i++){
        let filters = {"filter":groupList[i].new_columns_for_filter}
        checkAndReplaceEachFilter(filters,dataForReplace)
        if(groupList[i].childs)
            replaceFilterFromSecondary(groupList[i].childs,dataForReplace)
    }
    return
}

function replaceTheBODetailsInPolicy(filter,is_second_date,dataForReplace){
    let new_bo = dataForReplace.new_bo
    let old_bo = dataForReplace.old_bo
    let datedetails = {}
    if(is_second_date)
        datedetails = filter.datedetails2
    else
        datedetails = filter.datedetails1

    if(datedetails['bo_id'] != old_bo.id)
        return filter
    datedetails['bo_id'] = new_bo.id
    datedetails['bo_name'] = new_bo.name
    datedetails['policy_name'] = new_bo.name
    let policy_value = datedetails['policy_name'] +'.'+datedetails['policy_sub_type_name']
    datedetails['policy_value'] = policy_value
    datedetails['value'] = datedetails['format']+'['+policy_value+']'

    if(is_second_date){
        filter['value2'] = datedetails['format']+'['+policy_value+']'
        filter.datedetails2 = datedetails
    }
    else{
        filter['value1'] = datedetails['format']+'['+policy_value+']'
        filter.datedetails1 = datedetails
    }
    return filter
}

export function operationPerformOnWrapper(graph,startNumber=1,step_to_bold=''){
    graph.getSources().forEach(function (el) {
        startNumber = numberEachElement(el,graph,startNumber,step_to_bold)
    })
}

function numberEachElement(element,graph,startNumber,step_to_bold){
    if(element.prop('is_wrapper'))
        return startNumber
    let parentCell = element.getParentCell()
    if(!parentCell)
        return startNumber
    parentCell.attr('label/font-weight',"normal")
    parentCell.attr('label/fill',"black")
    if(step_to_bold === element.id){
        parentCell.attr('label/font-weight',"bold")
        parentCell.attr('label/fill',"blue")
    }
    if(!parentCell)
        return startNumber
    let parentText = element.prop('stepname')
    parentText = "["+startNumber +"] "+parentText
    element.prop('seqNo',startNumber)
    startNumber++
    parentCell.attr('label/text',parentText)
    var childrens = graph.getSuccessors(element);
    if(childrens.length){
       return numberEachElement(childrens[0],graph,startNumber,step_to_bold)
    }
    return startNumber
}