<template>
	<table ref="table"
		class="scrolling"
		:class="{ scrolly: scrollVertical, scrollx: scrollHorizontal }"
		:style="tableStyle">
		<thead name="thead" ref="thead"
			:style="syncHeaderScroll && scrollVertical ? stubScrollbarStyle : ''"
			@dragenter="onDragEnterHeader"
			@dragover.prevent="onDragOverHeader"
			@drop="onDropHeader"
			><slot name="thead"/></thead>
		<tbody name="tbody" ref="tbody"
			@scroll.passive="updateSyncedScroll"><slot name="tbody"/></tbody>
		<tfoot name="tfoot" ref="tfoot"
			v-if="includeFooter"
			:class="{ scrollsync: syncFooterScroll }"
			:style="syncFooterScroll && scrollVertical ? stubScrollbarStyle : ''"
			><slot name="tfoot"/></tfoot>
	</table>
</template>
<script>
export default {
	name: "VueScrollingTable",
	props: {
		deadAreaColor: { type: String, required: false, default: "#ffffff" },
		includeFooter: { type: Boolean, required: false, default: false },
		syncHeaderScroll: { type: Boolean, required: false, default: true },
		syncFooterScroll: { type: Boolean, required: false, default: true },
		scrollHorizontal: { type: Boolean, required: false, default: true },
		scrollVertical: { type: Boolean, required: false, default: true },
	},
	computed: {
		tableStyle() {
			return `background-color: ${this.deadAreaColor};`
		},
		stubScrollbarStyle() {
			return `background-color: ${this.deadAreaColor};
				-ms-scrollbar-base-color: ${this.deadAreaColor};
				-ms-scrollbar-face-color: ${this.deadAreaColor};
				-ms-scrollbar-highlight-color: ${this.deadAreaColor};
				-ms-scrollbar-track-color: ${this.deadAreaColor};
				-ms-scrollbar-arrow-color: ${this.deadAreaColor};
				-ms-scrollbar-shadow-color: ${this.deadAreaColor};
				-ms-scrollbar-darkshadow-color: ${this.deadAreaColor};
				scrollbar-base-color: ${this.deadAreaColor};
				scrollbar-face-color: ${this.deadAreaColor};
				scrollbar-highlight-color: ${this.deadAreaColor};
				scrollbar-track-color: ${this.deadAreaColor};
				scrollbar-arrow-color: ${this.deadAreaColor};
				scrollbar-shadow-color: ${this.deadAreaColor};
				scrollbar-darkshadow-color: ${this.deadAreaColor};`
		},
	},
	watch: {
		deadAreaColor() {
			this.setColors()
		},
	},
	mounted: function() {
		var tbl_el = ''
		if(this.$refs.table.$el){
          tbl_el = this.$refs.tbody.$el
		}
        else{
        	tbl_el = this.$refs.tbody
        }

        // tbl_el.addEventListener("scroll",testfun);

        // function testfun(e){
        // 	var new_Var = tbl_el
        // }

		this.setColors()
		this.updateSyncedScroll()

		// var tbl_el = ''
		// if(this.$refs.tbody.$el){
  //         tbl_el = this.$refs.tbody.$el
		// }
  //       else{
  //       	tbl_el = this.$refs.tbody
  //       }

        // tbl_el.addEventListener("scroll",testfun);

        // function testfun(e){
        	// e.preventDefault()
        	// var top_scroll = tbl_el.scrollTop
        	// var hasVScroll = tbl_el.scrollHeight > tbl_el.clientHeight;
        	// if (!hasVScroll)
        	// 	return false
        	// var new_val = tbl_el.scrollLeft
        	// if(top_scroll === 0 && new_val === 0)
        	// 	return
        	// var scroll_width = tbl_el.scrollWidth
        	// var client_width = tbl_el.clientWidth

        	// var sum_of_scroll_client = client_width + new_val
        	// if(scroll_width % sum_of_scroll_client <= 8)
        	// 	tbl_el.scrollLeft = new_val - 8
        // }
	},
	methods: {
		updateSyncedScroll() {
			const b = this.$refs.tbody
			const l = b.scrollLeft
			if (this.scrollHorizontal) {
				if (this.syncHeaderScroll) {
					const h = this.$refs.thead
					if (h.scrollLeft !== l) {
						h.scrollLeft = l
					}
				}
				if (this.includeFooter && this.syncFooterScroll) {
					const f = this.$refs.tfoot
					if (f.scrollLeft !== l) {
						f.scrollLeft = l
					}
				}
			}
			this.$emit("scroll", b.scrollTop, l, b.scrollHeight, b.scrollWidth)
		},
		setColors() {
			const s = this.$refs.table.style
			s.setProperty("--dead-area-color", this.deadAreaColor)
		},
		onDragEnterHeader(e) {
			this.$emit("header-dragenter", e)
		},
		onDragOverHeader(e) {
			this.$emit("header-dragover", e)
		},
		onDropHeader(e) {
			this.$emit("header-drop", e)
		},
	},
}
</script>
<style>
table.scrolling {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
	height: 100%;
	border-collapse: collapse;
	overflow: hidden;
	/* Use this to create a "dead" area color if table is too wide for cells */
	background-color: #ffffff;
	--dead-area-color: #ffffff;
}

table.scrolling thead,
table.scrolling tfoot {
	/* Grow automatically to fit content, don't shrink it proportionately to the body. */
	flex: 0 0 auto;
	display: block;
	/* Horizontal scrolling, when allowed, is controlled by JS, not a scroll bar. */
	overflow: hidden;
}

table.scrolling tbody {
	display: block;
	flex: 1 1 auto;
	/* Disable all scrolling by default */
	overflow: hidden;
}

/* Turn on vertical scrolling for all elements so scroll bars take up the same space */
table.scrolling.scrolly tbody,
table.scrolling.scrolly thead.scrollsync,
table.scrolling.scrolly tfoot.scrollsync {
	overflow-y: scroll;
}

/* Turn on horizontal scrolling for the body only */
table.scrolling.scrollx tbody {
	overflow-x: scroll;
}

/*
For Webkit, use "dead area" color to hide vertical scrollbar functions in the header and footer.
Since WebKit supports CSS variables and style attributes don't support pseudo-classes, use variables.
Display is set because otherwise Chrome ignores the other styling.
TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and this creates an extra block. No impact on iOS Safari.
*/
table.scrolling.scrolly thead.scrollsync::-webkit-scrollbar {
	display: block;
	background-color: var(--dead-area-color);
}
table.scrolling.scrolly thead.scrollsync::-webkit-scrollbar-track {
	background-color: var(--dead-area-color);
}

/* IE11 adds an extra tbody, have to hide it. */
table.scrolling tbody:nth-child(3) {
	display: none;
}

/* The one caveat to scrolling this way: a hard-set width is required. Can override in thead/tbody slot. */
table.scrolling td,
table.scrolling th {
	border: 1px solid #ddd;

	/* These must all be set the same in your overriding CSS */
	width: 10em;
	min-width: 10em;
	max-width: 10em;

	/* Important in case your data is too long for your cell */
	overflow: hidden;
	word-wrap: break-word;
}

table.scrolling td {
	background-color: #fff;
}

table.scrolling th {
	background-color: #f7f7f7;
}
</style>
