export function getToolbox(joint,that){
	var verticesTool = new joint.linkTools.Vertices();
    var segmentsTool = new joint.linkTools.Segments();
    var sourceArrowheadTool = new joint.linkTools.SourceArrowhead();
    var targetArrowheadTool = new joint.linkTools.TargetArrowhead();
    var sourceAnchorTool = new joint.linkTools.SourceAnchor();
    var targetAnchorTool = new joint.linkTools.TargetAnchor();
    var boundaryTool = new joint.linkTools.Boundary();
    var removeButton = new joint.linkTools.Remove({
        distance: 20,
        markup:[{
			    tagName: 'circle',
			    selector: 'button',
			    attributes: {
			        'r': 10,
			        'fill': '#607D8B',
			        'cursor': 'pointer',
			    }
			}, {
			    tagName: 'path',
			    selector: 'icon',
			    attributes: {
			        // 'd': 'M -5 -5 5 5 M -5 5 5 -5',
			        'd':'M -4 -6  4 -6 M -6 -3  6 -3 M -4 0  4 0 M -4 0  4 0 M -4 1 4 1 M -4 2 4 2 M -4 3 4 3 -4 4 4 4 Z',
			        'fill': 'none',
			        'stroke': '#FFFFFF',
			        'stroke-width': 2,
			        'pointer-events': 'none',
			    },
			}]
    });
    let toolList = [removeButton]
    if(that.isReadOnly || that.readonly)
    	toolList = []
    
    if(that.isGraphForBusinessObject){
    	toolList.push(getCustomtool(joint,that))
	   	var toolsView = new joint.dia.ToolsView({
	        tools: toolList
	    });
    }
    else{
    	var toolsView = new joint.dia.ToolsView({
	        tools: toolList
		});
    }
    return toolsView
}

function getCustomtool(joint,that){
		joint.linkTools.InfoButton = joint.linkTools.Button.extend({
	    name: 'info-button',
	    options: {
	        markup: [{
	            tagName: 'circle',
	            selector: 'button',
	            attributes: {
	                'r': 10,
	                'fill': '#607D8B',
	                'cursor': 'pointer'
	            }
	        }, {
	            tagName: 'path',
	            selector: 'icon',
	            attributes: {
	                // 'd': 'M -2 4 2 4 M 0 3 0 0 M -2 -1 1 -1 M -1 -4 1 -4',
	                'd':'M -6 -2  6 -2 M -4 1 4 1 M -2 4 2 4 Z',
	                'fill': 'none',
	                'stroke': '#FFFFFF',
	                'stroke-width': 2,
	                'pointer-events': 'none',
	            }
	        }],
	        distance: -30,
	        offset: 0,
	        action: function(evt) {
	            // alert('View id: ' + this.id + '\n' + 'Model id: ' + this.model.id);
	            that.sendLinkEvent('linkfilter',{'link':this.model,
	            								"source":that.edcGraph.getCell(this.model.source().id),
	            								"target":that.edcGraph.getCell(this.model.target().id)})
	        }
	    }
	});

	var infoButton = new joint.linkTools.InfoButton();
	return infoButton
}

export function manageLinktools(cell,mainPaper,joint,that){
	let linkView = cell.findView(mainPaper);
    linkView.addTools(getToolbox(joint,that));
    linkView.hideTools();
}