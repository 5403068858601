import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        bussinessObjects: [],
        cacheData: {},
        errorList: {},
        boActivityData:[],
        selectiveRestoreData:[],
        createJobParams:{},
        modeValue:true,
        calendarDetails:{},
        eventList:[],
        jobDetails:{
            'name':"",
            'description':"",
            'items':[]
        },
        notification:'',
        liveStatus:{},
        updatedRowCounts:[],
        sqllogslivestatus:{},
        transctionTableObj:{},
        jobPlanDetails:{},
        currentWaitingJobToRunning:{},
        currentWaitingJobToTerminate:{},
        moreColumnInfo:[],
        migrationInfo:{},
        themeType:'',
        jobPlanDashboardInfo:{},
        sameComponentRedirectObj:{},
        jobEstimateRemainingTime:{},
        summarizedRowCounts:{},
        jobPlanLiveStatus:{}
    },
    mutations: {
        setBussinessObjects(state, list) {
            state.bussinessObjects = list;
        },
        setCacheData(state, obj) {
            state.cacheData[obj.key] = obj.value;
        },
        setBOActivityData(state, list){
            state.boActivityData = list;
        },
        setSelectiveRestoreData(state, list){
            state.selectiveRestoreData = list
        },
        setNotification(state,notification){
            state.notification = notification
        },
        setLiveStatus(state,obj){
            state.liveStatus = obj
        },
        setRowCounts(state,list){
            state.updatedRowCounts = list
        },
        setSqlLogsLiveStatus(state,obj){
            state.sqllogslivestatus = obj
        },

        setTransactionTableObj(state,obj){
            state.transctionTableObj = obj
        },
        setjobPlanDetails(state,obj){
            state.jobPlanDetails = obj

        },
        setCurrentWaitingJobToRunning(state,obj){
            state.currentWaitingJobToRunning = obj
        },
        setCurrentWaitingJobToTerminate(state,obj){
            state.currentWaitingJobToTerminate = obj
        },
        setmoreColumnInfo(state, list) {
            state.moreColumnInfo = list;
        },
        setmigrationInfo(state,obj){
            state.migrationInfo = obj
        },
        setthemeInfo(state,theme){
            state.themeType = theme
        },
        setjobPlanDashboardInfo(state,obj){
            state.jobPlanDashboardInfo = obj
        },
        setsameComponentRedirectObj(state,obj){
            state.sameComponentRedirectObj = obj
        },
        setJobEstimateRemainingTime(state,obj){
            state.jobEstimateRemainingTime = obj
        },
        setSummarizedRowCounts(state,obj){
            state.summarizedRowCounts = obj
        },
        setJobPlanLiveStatus(state, obj){
            state.jobPlanLiveStatus = obj
        }
    },
    actions: {
        setBussinessObjects: (context, list) => {
            context.commit('setBussinessObjects', list);
        },
        setCacheData: (context, obj) => {
            context.commit('setCacheData', obj);
        },
        setBOActivityData:(context,obj) =>{
            context.commit('setBOActivityData',obj)
        },
        setSelectiveRestoreData(context, list){
            context.commit('setSelectiveRestoreData',list)
        },
        setNotification(context,notification){
            context.commit('setNotification',notification)
        },
        setLiveStatus(context,obj){
            context.commit('setLiveStatus',obj)
        },
        setRowCounts(context,list){
            context.commit('setRowCounts',list)
        },
        setSqlLogsLiveStatus(context,obj){
            context.commit('setSqlLogsLiveStatus',obj)
        },

        setTransactionTableObj(context,obj){
            context.commit('setTransactionTableObj',obj)
        },
        setJobPlanDetails(context,obj){
            context.commit('setJobPlanDetails',obj)
        },
        setCurrentWaitingJobToRunning(context,obj){
            context.commit('setCurrentWaitingJobToRunning',obj)
        },
        setCurrentWaitingJobToTerminate(context,obj){
            context.commit('setCurrentWaitingJobToTerminate',obj)
        },
        setmoreColumnInfo(context,list) {
            context.commit('setmoreColumnInfo',list)
        },
        setmigrationInfo(context,obj){
            context.commit('setmigrationInfo',obj)
        },
        setthemeInfo(context,theme){
            context.commit('setthemeInfo',theme)
        },
        setjobPlanDashboardInfo(context,obj){
            context.commit('setjobPlanDashboardInfo',obj)
        },
        setsameComponentRedirectObj(context,obj){
            context.commit('setsameComponentRedirectObj',obj)
        },
        setJobEstimateRemainingTime(context,obj){
            context.commit('setJobEstimateRemainingTime',obj)
        },
        setSummarizedRowCounts(context,obj){
            context.commit('setSummarizedRowCounts',obj)
        },
        setJobPlanLiveStatus(context, obj){
            context.commit('setJobPlanLiveStatus',obj)
        }

    }
})