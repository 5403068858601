<template>
    <v-container class="EDC-Grid-Container">
        <v-row class="EDC-Grid-Top-Row">
            <v-col class="EDC-Grid-Top-Col">
                <v-row class="EDC-Row" v-if="isGridForPathfinder">
                    <v-col class="EDC_Grid-Col" cols="6">
                        <!-- pathfinder heading/ access error-->
                    </v-col>
                    <v-col class="EDC_Grid-Col" cols="6">
                        <!-- pathfinder heading/ access error-->
                    </v-col>
                </v-row>
                <v-row class="EDC-Grid-Top-Row">
                    <v-col class="EDC-Grid-Top-Col">
                        <v-card class="EDC-Grid-Card">
                            <v-row class="EDC-Row">
                                <!--- Additional Filters Place -->
                                <v-col class="EDC-Col">

                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" v-if="showIconExportRow">
                                <!--row for Actions, export and chips-->
                                <v-col class="EDC-Col" style="height: 32px !important;" cols="11">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col inline-with-checkbox">
                                            <!--- Left For Action Panel-->
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col" cols="6" align="left" v-if="dataList.showSelect">
                                                    <svgicon v-if="showAddNew" title="Add New" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="add_v2" :original="true" @click="createEvent('record', 'onNew')"></svgicon>
                                                    <v-menu  bottom absolute transition="scale-transition" v-if="showAddNewWithOption" close-on-content-click close-on-click>
                                                        <template v-slot:activator="{ on }">
                                                            <svgicon style="display: inline-flex !important;" class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="add_v2" :original="true" v-on="on"></svgicon>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item  dense v-for="(item, index) in addWithNewOptions"
                                                            :key="index" @click="createEvent('record', item.event)" style="cursor:pointer">
                                                                <v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <!-- <action-panel :key="ActionPanelremountTime" :selectedArray="selected" @createEvent="createEvent" :data="gridActionObject" @onCancelEditing="onCancelEditing"></action-panel> -->
                                                    <action-panel :key="ActionPanelremountTime" :selectedArray="selected" :classifiedActions="gridActionObject.actions" @createEvent="createEvent"  @onCancelEditing="onCancelEditing">

                                                    </action-panel>
                                                </v-col>
                                                <v-col class="EDC-Col" cols="6" v-if="selectedChips.length" align="left">
                                                    
                                                </v-col>
                                                <!-- <v-col v-else class="EDC-Col" cols="5" align="left">
                                                        <v-checkbox label="Test"></v-checkbox>
                                                </v-col> -->
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                
                                <v-col class="EDC-Col" cols="1" v-show="dataList.showExport" align="end">
                                    <!--- Right Export and Other Icons-->
                                    <!-- <action-panel key="downloadAction" @onDownload="onDownload" :showDownload="dataList.showExport"> </action-panel> -->
                                        <grid-download @onDownload="onDownload" :selectedArray="selected">

                                        </grid-download>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                    <!--- Actual Grid Component  -->
                                    <!--NOTE: SELECT ALL Not work for the even vuetify grid for different pages-->

                                    <v-data-table dense v-model="selected" :multi-sort="!dataList.singleSort"
                                    :headers="dataList.headers"
                                    :items="rowsToDisplay"
                                    :item-key="dataList.itemkey"
                                    :page="page"
                                    :items-per-page="perPage"
                                    :server-items-length="dataList.total_count"
                                    :show-select="dataList.showSelect"
                                    class="elevation-1"
                                    hide-default-footer
                                    :loading="dataList.tableLoading"
                                    @page-count="pageCount = $event" @update:options="updateTableOptions" 
                                    :id="gridId">
                                    
                                        <template v-for="(h,i) in dataList.headers" v-slot:[`header.${h.value}`]="{ header }">
                                            <v-tooltip bottom :key="'h'+i">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" @click="headerClick($event)">{{h.displayHeader || h.text}}</span>
                                                </template>
                                                <span>{{getColTooltips(h.text)}}</span>
                                            </v-tooltip>
                                        </template>

                                        <template v-slot:body="{ items }">
                                            <tbody style="cursor:pointer;">
                                                <tr  v-if="showInlineFilter">
                                                    <td v-if="dataList.showSelect" class="text-align:left;">

                                                    </td>
                                                    <td v-for="(h,i) in dataList.headers" :key="i+'fitlerheader'" style="text-align:left" :class="{'hideCol':h.align===hideColProp}">
                                                        <span v-if="!h.hideInlineFilter">
                                                            <v-autocomplete clearable v-if="h.useDropdownForSearch" :items="h.searchOptions" hide-details dense :item-text="h.searchItemText"  :item-value="h.searchItemValue" v-model="h.filtertext" @change="applyInlineFilter($event,h, h.filtertext)" @click:clear="clearInlineFilter($event,h,h.filtertext)" :multiple="h.searchMultiple||false" :title="h.filtertext">
                                                            </v-autocomplete>
                                                            <v-text-field v-else clearable rounded id="inlineFilter" type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" hide-details dense @click:clear="clearInlineFilter($event,h,h.filtertext)" @keyup.prevent="keyUpInlineFilter($event,h, h.filtertext)" autocomplete="off" @blur="applyInlineFilter($event,h, h.filtertext,true)"></v-text-field>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr v-if="!dataListHasRows">
                                                    <td :colspan="dataList.headers.length" class="EDC-NoRecordText">{{NoDataFound}}</td>
                                                </tr>
                                                <template v-for="(item,index) in items">
                                                    <tr v-if="dataListHasRows" :key="'item'+index" @dblclick="rowDoubleClick(item,index)" @click="manageHighLight($event,index,item)" :class="{'EDC-HighlightGridRow': highlightedRows.indexOf(index)>-1}">
                                                        <td v-if="dataList.showSelect">
                                                            <v-checkbox v-model="selected" :value="item" style="margin:0px;padding:0px" hide-details color="primary-lighten2" @change="rowSelectionChanged(item,index)">
                                                            </v-checkbox>
                                                        </td>
                                                        <template v-for="(header,headerindex) in dataList.headers">
                                                            <td :key="'header'+headerindex" :style="{'text-align':header.align|| 'left'}"  :title="item[header.display_text_key] || item[header.value]" :class="{'hideCol':header.align===hideColProp}">
                                                                <v-progress-circular v-if="header.useProgress"
                                                                    :rotate="-90"
                                                                    :width="2"
                                                                    :size="24"
                                                                    color="primary"
                                                                    :value="item[header.value]"
                                                                    ><span style="font-size:10px !important;">{{item[header.value]}}</span>
                                                                </v-progress-circular>
                                                                
                                                                <v-chip style="margin-top:1px;margin-bottom:1px;" v-else-if="header.useChip" :color="getColor(header.chipFor,item[header.value])" small dark>{{getFormatedData(header,item[header.value])}}</v-chip>

                                                                <!-- v-checkbox in td -->
                                                                <v-checkbox v-else-if="header.useCheckbox && item.is_row_editable" v-model="item[header.value]" :value="item[header.value]" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :indeterminate="item.useIndeterminateState" @change="checkChanged($event,header,item)" :disabled="setDisabled(header,item)"></v-checkbox>

                                                                <!-- v-autocomplete in td . It should not be 'clearable'.If you keep clearable it will cause the problem in add policy-->
                                                                <v-autocomplete v-else-if="header.useAutoComplete && item.is_row_editable" :multiple="header.useMultiple || false" :return-object="header.returnObject || false" hide-details="auto" v-model="item[header.value]"  :items="header.option" dense :item-text="header.item_text" :label="header.label || 'select'" :item-value="header.item_value" @input="changeAutoCompleteSelection($event,item,header,headerindex,index)"></v-autocomplete>

                                                                <!--Edc calender in td. Keep this above the textbox. Because on policy date if user select actual date policy value type then this should get displayed-->
                                                                <edc-calender :input="item[header.value]" @update="setDate(item, header.value, ...arguments)"
                                                                v-else-if="item.is_row_editable && ((item.value_type === 'Actual Date' && header.useTextField) || header.useCalender)" :desne="true"> </edc-calender>


                                                                <!-- v-textbox in td -->
                                                                <v-text-field v-else-if="header.useTextField && item.is_row_editable" hide-details="auto" v-model="item[header.value]" dense class="EDC-TextField" @keypress="validateInput($event,header.inputType)" @keyup="AddRowForGridEditing(item)" :disabled="header.disabled" @focus="AddRowForGridEditing(item)" @blur="AddRowForGridEditing(item,header,index)" :label="header.label"></v-text-field>

                                                                <v-radio-group v-model="single_selected_row"  hide-details dense v-else-if="header.useRadio" @change="radioSelectionChange(item)">
                                                                    <v-radio class="radioClass" :color="colorCode" :value="item" hide-details dense>

                                                                    </v-radio>
                                                                </v-radio-group>
                                                                <!-- normal td -->
                                                                <span v-else-if='header.avoidTrim' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>

                                                                <span v-else-if='isRowFullfillLinkCriteria(header,item)' @click="linkCellClicked($event,header,item)" class="grid-cell-link"> {{getFormatedData(header,item[header.value],item,header.is_key_json)}} </span>
                                                                <span v-else-if="header.showHtml" v-html="item[header.value]"></span>
                                                                <span v-else @click="copyToClipboard($event,header)">{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" style="max-height:48px;" v-if="showTableFooter">
                                <v-col class="EDC-Col inline-with-checkbox" cols="4" align="left" v-if="dataList.showItemPerPage">
                                    <v-select  :items="perPageArray" hide-details v-model="perPage" style="width:48px;padding:0px;" @input="onPerPageChange"></v-select>
							        <span style="position: absolute;left:72px;margin-top:-22px;" class="EDCTableFooter">Rows</span>
                                </v-col>
                                <v-col class="EDC-Col" v-else>

                                </v-col>
                                <v-col class="EDC-Col" cols="4" align-self="center">
                                    <span class="EDCTableFooter" v-if="dataList.showRowInformation">
                                        <span v-if="rowsToDisplay.length > 0">
                                            {{ (page - 1) * perPage + 1 }} - {{ (page - 1) * perPage + rowsToDisplay.length }} of {{ dataList.actual_total_count || dataList.total_count }}
                                        </span>
                                        <span class="EDCTableFooter" v-else>
                                            0 - 0 of 0
                                        </span>
                                    </span>
                                </v-col>
                                <v-col class="EDC-Col" cols="4" align="end">
                                    <v-pagination v-if="dataList.showPagination" style="justify-content:flex-end !important;float:right;"  v-model="page" :length="pageCount" :total-visible="pageNumberTotalVisible" @input="onPageChange"></v-pagination>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .EDC-Grid-Container{

    }

    .EDC-Grid-Top-Row{

    }

    .EDC-Grid-Card{

    }
    .moreInfoTD{
	    padding:8px !important;
    }

    .inline-with-checkbox{
        /* Exact 16 not working */
        padding-left: 14px !important;
        
    }
</style>


<script>
    import{CLIENT_SIDE,SERVER_SIDE,No_Data_Found,COLOR_CODE} from './../../constants/productconstants.js';
    import {FLITER_JOIN_OPERATOR_ARRAY_JSON,GRID_STATUS_COLOR_CODE,OEPRATOR_TO_EDC_OPERATOR} from './../../constants/operators.js'
    import {getGridObj} from './constants.js'
    import moment from 'moment'
    import ActionPanel from "./revisedActionPanel.vue"
    import {isValidNumber} from './../../methods/validations.js'
    import * as commonFuncObj from "./../../methods/common.js"
    import {getJDEDate} from './../../methods/DateFormat.js'
    import * as JDEFormatterObj from "./JDEFormatter.js"
    import gridDownload from './gridDownload.vue'
    export default{
        name:"EdcDataGrid",
        components:{
            'action-panel':ActionPanel,
            'grid-download':gridDownload
        },
        props:{
            dataList:{
                type:Object,
                default:()=>{
                    return getGridObj()
                }
            },
            gridId:{
                type:String,
                default:"edcGrid"
            },
            predefinedInlineFilter:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            gridFor:{
			    type:String,
			    default:'dataDisplay'
		    },
            removedRow:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            liveStreamRow:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                colorCode:COLOR_CODE,
                page:1,
                perPage: 10,
                pageNumberTotalVisible:7,
                pageCount:0,
                perPageArray:[5,10,15,20,25,50,100],
                rowsToDisplay:[],
                compressVeritcalSpace:false,
                inlineFilterList:[],
                ActionPanelremountTime:1,
                hideColProp:' d-none',
                clickOnSort:false, // this variable is used to avoid sort call on first grid load.In case of server side grid updateOptions method get called. That method make getData col 2-3 time unnecessary.To avoid this overhead added this clickOnSort variable.
  		        selected:[],
                updatedFilter:[],
                filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
		        operator_to_edc_operator:OEPRATOR_TO_EDC_OPERATOR,
  		        NoDataFound: No_Data_Found,
                selectedChips:[],
  		        oldSelectedChips:[],
  		        allHeaders:[],
  		        visibleCols:[],
                downloadOption:false,
                downloadParams:{
                    recordType:"all",
                    minRange:"",
                    maxRange:"",
                    fileType:"xlsx",
                    topN:0,
                },
                gridActionObject:{},
                singleSelectedRow:{},
                allPagesRowSelectionList:[],
                highlightedRows:[],
                columnList:[],
                FilterJsonList:[],
                sortJson:[],
                single_selected_row:{},
            }
        },
        computed:{
            isRowSelected(){
                return this.dataList.selectedRows.length
            },
            isGridForPathfinder(){
			    return this.gridFor === 'pathfinder'
		    },
            isCompressionApplicable(){
			    return this.dataList.isCompressionApplicable && this.isGridForPathfinder
		    },
            showInlineFilter(){
                // if(!this.isCompressionApplicable)
                //     return true
                if(this.compressVeritcalSpace)
                    return false
                return this.dataList.showInlineFilter && (this.dataList.total_count > 5 || this.inlineFilterList.length>0)
            },
            dataListHasRows(){
                return this.dataList.rows.length > 0
            },
            showAddNew(){
                var obj = _.find(this.dataList.actions,["text","new"])
                return !_.isEmpty(obj) && this.selected.length === 0
		    },
            showAddNewWithOption(){
                var obj = _.find(this.dataList.actions,["text","newWidOptions"])
                if(_.isEmpty(obj) || !obj.role || this.selected.length >0)
                    return false
                return true
            },
            addWithNewOptions(){
                if(this.showAddNewWithOption){
                    var options = _.find(this.dataList.actions, ['text', 'newWidOptions']);
                    return options.availableOptions
                }
                return []
            },
            showTableFooter(){
                // if(!this.isCompressionApplicable)
                //     return true
                if(this.compressVeritcalSpace)
                    return false
                if(!this.dataList.showFooter)
                    return false
                return this.dataList.total_count > 5
            },
            isGridForWorkTable(){
                return this.gridFor === 'worktable'
            },
            isInlineEditGrid(){
			    return this.dataList.isInlineEditGrid || this.dataList.is_grid_inline_edit
		    },
            isUpdateLiveStatusGrid(){
                return this.dataList.updatinglivestatus
            },
            showIconExportRow(){
                return this.dataList.showExport || this.dataList.showActions
            }
        },
        watch:{
            'dataList':{
                handler(newValue){

                },
                deep:true
            },
            'dataList.headers':{
                handler(newValue){

                }
            },
            'dataList.rows':{
                handler(newValue){
                    if(newValue.length >0 && (this.isUpdateLiveStatusGrid || this.isInlineEditGrid) && this.selected.length > 0)
                    {

                    } else {
                        if(this.dataList.DefaultSelectAll)
  					        this.selected = newValue
  				        else
  					        this.selected = []

                        this.highlightedRows = []
                    }
                    if(this.dataList.paginationType!=SERVER_SIDE)
                    {
                        //code added for manish sir change.mail date: 11/march/2020
                        if(this.isInlineEditGrid && this.inlineFilterList.length > 0 && this.selected.length > 0)
                            return
                        this.dataList.total_count = newValue.length
                        this.performClientSidePagination()
                    }
                    else{
                        this.rowsToDisplay = newValue
                    }
                    this.manageSelected(this.selected)
                },
                deep:true
            },
            'dataList.selectedRows':{
                handler(newValue){
                    if(!newValue.length || !this.dataList.persist_selected_rows)
                        return
                    // need right logic for job plan job moving
                    	this.selected = _.filter(this.dataList.rows, (obj)=>{
                            return newValue.indexOf(obj[this.dataList.itemkey]) > -1
                        })
                    // this.$nextTick(function () {
                    //     this.selected = newValue
                    // })
                    this.dataList.persist_selected_rows = false
                    this.manageSelected(newValue, false, true)
                }
            },
            'dataList.actions':{
                handler(newValue){

                }
            },
            'dataList.total_count':{
                handler(newValue){
                    // if(!this.dataList.actual_total_count)
                    //     this.dataList.actual_total_count = this.dataList.total_count
                }
            },
            'removedRow':{
                // To fix this bug => terminated one of two jobs and when we selected the remaining job the options were inverted from what you would expect. For example, the job is not selected and you see the resume button. If you select the job the selections disappear.
                handler(newValue){
                    if(!_.isEmpty(newValue))
                        this.selected = []
                }
  	        },
            'rowsToDisplay':{
                handler(newValue){
                    var _this = this
                    if(newValue.length >0 && (this.isUpdateLiveStatusGrid || this.isInlineEditGrid) && this.selected.length > 0)
                    {

                    } else {
                        if(this.dataList.DefaultSelectAll)
  					        this.selected = newValue
                        else if(this.dataList.allPagesRowSelection && this.dataList.allPagesRowSelectionList && this.dataList.allPagesRowSelectionList.length){
                            // now add item as a selected if that new row present in the this.dataList.allPagesRowSelectionList
                            let selectedItems = [] // defining diffrent variable just to avoid emit on every add item
                            for(let i=0;i<newValue.length;i++){
                                if(this.dataList.allPagesRowSelectionList.indexOf(newValue[i])>-1)
                                    selectedItems.push(newValue[i])
                            }
                            this.$nextTick(()=>{
                                _this.selected = selectedItems
                            })
                        }
                        else{
							if(!this.compressVeritcalSpace)
								this.selected = []
						}
  				    }
                    this.manageSelected(this.selected)
                }
            },
            'selectedChips':{
                handler(newValue){
                    // if newValue
                }
            },
            'selected':{
                handler(newValue){
                    this.manageSelected(newValue,true)
                    this.manageCompression()
                }
            },
            'perPage':{
                handler(newValue){
                    this.selected = []
                }
            },
            'page':{
                handler(newValue){
                    this.selected = []
                }
            },
            'predefinedInlineFilter':{
                handler(newValue){
                    if(_.isEmpty(newValue) || _.isEmpty(this.dataList) || _.isEmpty(this.dataList.headers))
                        return
                    for(let i=0;i<newValue.length;i++){
                        let currentFilter = newValue[i]
                        let isLastFilter = newValue.length-1 != i // on last inline filter we have to call the function
                        for(let j=0;j<this.dataList.headers.length;j++){
                            let currentHeader = this.dataList.headers[j]
                            if(currentFilter.column_name === currentHeader.value){
                                    currentHeader.filtertext =  currentFilter.value1
                                    this.applyInlineFilter({},currentHeader,currentFilter.value1,isLastFilter)
                                    break;
                            }
                        }
                    }
                }
            },
            'dataList.isCompressionApplicable':{
                handler(newValue){
                    // this.compressVeritcalSpace = newValue
                }
            },
            'gridFor':{
                handler(newValue){

                }
            },
            'liveStreamRow':{
                handler(newvalue){
                    this.manageNewPushedRow(_.cloneDeep(newvalue))
                }
            },
            'dataList.is_in_tab':{
                handler(newValue){
                    if(newValue){
                        this.managePerPage()
                    }
                }
            }
        },
        mounted(){
            if(this.dataList.perPage && this.dataList.perPage > 0)
			    this.perPage = this.dataList.perPage
            else{
                this.managePerPage()
            }
            this.allHeaders = _.cloneDeep(this.dataList.headers)
            this.visibleCols = _.cloneDeep(this.dataList.headers)
        },
        methods: {
            managePerPage(){
                this.perPage = commonFuncObj.manageScreenDisplay()
                if(this.dataList.is_in_tab){
                    this.perPage =  this.perPage - 5 < 5?5:this.perPage - 5
                }
            },
            manageSelected(newValue,isRowSelectionChange=false, isFromSelectedRowsWatch=false){
                if(this.isUpdateLiveStatusGrid){
                    if(!isRowSelectionChange){
                        return
                    }
                }
                if (!isFromSelectedRowsWatch)
                    this.dataList.selectedRows = newValue
			    let selectedForEmit = _.cloneDeep(newValue)
                if(this.dataList.allPagesRowSelection)
				    selectedForEmit = _.cloneDeep(this.dataList.allPagesRowSelectionList)
                this.$emit('onRowSelected',selectedForEmit,this.dataList.unique_id)
			    this.gridActionObject = _.cloneDeep(this.dataList)
                // logic for show icons properly.
                this.gridActionObject.actions = []
                if(newValue.length === 0)
      	            return false
                var actions = _.cloneDeep(this.dataList.actions)
                // every action should have the index
			    if(actions.length > 0 && !actions[0].index)
			        return
                actions = _.sortBy(_.cloneDeep(this.dataList.actions),"index")
                var row = _.cloneDeep(newValue[0])
			    // Every action json should contain the 'key' key.Cheack the same.
			    var action_with_key = _.every(actions,'key')

			    // if not every action json contain the 'key' key then return
			    if(!action_with_key)
			        return false
                
                // logic for single selected row
			    if(newValue.length === 1){
                    // now we have to show only that icons which has 'key' and cmp value match (if it has cmp value)
		            var valid_actions = []
		            for (var i = 0; i < actions.length; i++) {
		  	            var current_action = actions[i]

                        // continue new action
                        if(current_action.text === 'new' || current_action.text === 'newWidOptions')
                            continue

                        // grid download icon
                        if(current_action.text === 'GridDownload'){
                            // valid_actions.push(current_action)
                            continue
                        }
                        
                        if(current_action.text === 'edit' && this.selected[0].is_row_editable)
                            continue

                        var key = current_action.key
                        if(row[key]){

                            // now check is that action has cmp value key
                            if(current_action.cmpValue){
                                if(current_action['cmpValue'] === row[key])
                                    valid_actions.push(current_action)
                                continue
                            }
                            if(current_action.cmpValueList){
                                if(row[key] && current_action.cmpValueList.indexOf(row[key].toLowerCase()) >-1)
                                    valid_actions.push(current_action)
                                continue
                            }
                            valid_actions.push(current_action)
                        }
                    }
                    this.ActionPanelremountTime++
                    this.gridActionObject.actions = _.cloneDeep(valid_actions)
                }
                else{
                    this.gridActionObject.actions = []
                    var multiple_actions = []
                    for (var i = 0; i < actions.length; i++) {
                        var current_action = actions[i]
                        if (current_action.selectType  === 'multiple'){
                            if(current_action['cmpValue']){
                                let key = current_action['key']
                                let cmpValueMatchedArray = _.filter(newValue,function(obj){
                                    return obj[key] === current_action['cmpValue']
                                })
                                if(cmpValueMatchedArray.length!=newValue.length)
                                    continue
                            }
                            multiple_actions.push(current_action)
                        }
                    }
                    this.ActionPanelremountTime++
                    this.gridActionObject.actions = _.cloneDeep(multiple_actions)
                }
            },
            getOperatorNameById(operator,value1,value2)
            {
                if(operator === '_eq_')
                    return "= "+ value1
                if(operator === '_not_eq_')
                    return "!= "+value1
                if(operator === '_lt_')
                    return "< "+value1
                if(operator === '_gt_')
                    return "> "+value1
                if(operator === '_lt_eq_')
                    return "<= "+value1
                if(operator === '_gt_eq_')
                    return ">= "+value1
                if(operator === '_is_n_')
                    return "Is null"
                if(operator === '_is_nt_n_')
                    return "In not Null"
                if(operator === '_sl_')
                    return "like "+value1+"%"
                if(operator === '_el_')
                    return "like %"+value1
                if(operator === '_cl_')
                    return "like %"+value1+"%"
                if(operator === '_nt_cl_')
				    return "not like %"+value1+"%"
			    if(operator === '_bet_' || operator === '_nt_bet_')
				    return "between "+value1+" AND "+value2
                if(operator === '_in_')
                    return "in ("+value1+")"
                if(operator === '_n_in_')
                    return "not in ("+value1+")"
            },
            onUpdateFilter(filter_list){
                if(!filter_list)
                    filter_list = this.updatedFilter
                if(this.dataList.filterType === SERVER_SIDE){
                    this.$emit('UpdateFilter', _.cloneDeep(filter_list),1)
                    this.page = 1
                }
                else
                    this.onClientFilter(_.cloneDeep(filter_list))

                // console.log(this.updatedFilter)
            },
            setDate(obj, key, param){
                obj[key] = param;
            },
            onClientFilter(filterData){
                var new_rows = this.dataList.rows
                _.forEach(filterData,function(filterObj,index){
                    let column_name = filterObj.column_name
                    let operator = filterObj.operator
                    let value1 = filterObj.value1
                    if(!value1)
                        value1 = ''
                    let operation = filterObj.operation // true means OR , false means AND
                    let temp_rows = _.filter(new_rows,function(rowobj){
                        if(operator === '_eq_')
                            return rowobj[column_name] == value1 || rowobj[column_name].toString().toLowerCase() == value1.toString().toLowerCase()
                        else if(operator === '_not_eq_')
                            return rowobj[column_name] != value1
                        else if(operator === '_lt_')
                            return rowobj[column_name] < value1
                        else if(operator === '_gt_')
                            return rowobj[column_name] > value1
                        else if(operator === '_lt_eq_')
                            return rowobj[column_name] <= value1
                        else if(operator === '_gt_eq_')
                            return rowobj[column_name] >= value1
                        else if(operator === '_is_n_')
                            return rowobj[column_name] == 'N/A'
                        else if(operator === '_is_nt_n_')
                            return rowobj[column_name] != 'N/A'
                        else if(operator === '_sl_')
                            return rowobj[column_name] && (rowobj[column_name].startsWith(value1) || rowobj[column_name].toLowerCase().startsWith(value1.toLowerCase()))
                        else if(operator === '_el_')
                            return rowobj[column_name] && (rowobj[column_name].endsWith(value1) || rowobj[column_name].toLowerCase().endsWith(value1.toLowerCase()))
                        else if(operator === '_cl_')
                            return rowobj[column_name] && (rowobj[column_name].includes(value1) || rowobj[column_name].toLowerCase().includes(value1.toLowerCase()))
                    })
                    new_rows = temp_rows
                })
                this.dataList.total_count = new_rows.length
                this.dataList.clientFilteredRows = new_rows
                this.page = 1
                this.performClientSidePagination(new_rows)
            },
            onPageChange(number){
                this.highlightedRows = []
                if(this.dataList.paginationType === SERVER_SIDE)
                    this.$emit('onPageChange', number,this.perPage)
                else
                    this.performClientSidePagination()
            },
            onPerPageChange(number){
                this.page = 1
                if(this.dataList.paginationType === SERVER_SIDE)
                    this.$emit('onPerPageChange', number,this.page)
                else
                    this.performClientSidePagination()
            },
            onSort(sortJson){
                if(!sortJson)
                    sortJson = []
                if(this.dataList.sorting_type === SERVER_SIDE){
                    this.$emit('onSort', sortJson)
                }
                else {
                    let sort_cols=[]
                    let sort_types = []
                    let sorteddatarows = []
                    for (var i = 0; i < sortJson.length; i++) {
                        // in case of some numeric values, we are doing formatting. But for user, still its an
                        // numeric field. So in that case we are passing extra paramter in the header json
                        // as a sortColumn. If sort column exists add that column into sort cols else add actual
                        // column as it is.
                        let columnHeader = _.find(this.dataList.headers,["value",sortJson[i].column_name])
                        if(columnHeader && columnHeader.sortColumn)
                            sort_cols.push(columnHeader.sortColumn)
                        else
                            sort_cols.push(sortJson[i].column_name)
                        sort_types.push(sortJson[i].type)
                    }
                    if(sort_cols.length > 0){
                        let displayed_rows = this.dataList.rows
                        if(this.dataList.clientFilteredRows)
                            displayed_rows = this.dataList.clientFilteredRows
                        sorteddatarows = _.orderBy(displayed_rows,sort_cols,sort_types)
                        this.performClientSidePagination(sorteddatarows)
                    }
                    else
                        this.performClientSidePagination()
                }
            },
            performClientSidePagination(rows){
                if(!rows)
                {
                    if(this.dataList.clientFilteredRows)
                        rows = this.dataList.clientFilteredRows
                    else
                        rows = this.dataList.rows
                }
                if(this.page <= 1 || rows.length < this.perPage)
                    this.rowsToDisplay= rows.slice(0,this.perPage)
                else{
                    let data_from = (this.page - 1)* this.perPage
                    let data_to = this.page * this.perPage
                    this.rowsToDisplay = rows.slice(data_from,data_to)
                }
            },
            chipDeleted(item,index){
			    var _this = this
	  		    /* item deleted from selection chip.
				find out index of deleted item and remove same item from the _this.updatedFilter.
				Then emit the UpdateFilter function
				*/
  				// delete from _this.updatedFilter
  				_this.updatedFilter.splice(index,1)
  				_this.selectedChips.splice(index,1)
  				// remove operation key from last filter row
  				if(_this.updatedFilter.length > 0)
  					delete _this.updatedFilter[_this.updatedFilter.length - 1].operation

  				// reasign selectedChips to oldChips

  				let new_merged_filter_list = _this.getRevisedFilter()

  				// emit updateFilter
  				_this.onUpdateFilter(new_merged_filter_list)

  			},
            getRevisedFilter(){
  				var _this = this
  				var updatedFilterCopy = []
                this.FilterJsonList = []
  				var new_merged_filter_list = _.cloneDeep(_this.updatedFilter)
  				new_merged_filter_list = new_merged_filter_list.concat(_.cloneDeep(_this.inlineFilterList))

  				if(new_merged_filter_list.length === 0)
  					return updatedFilterCopy
  				var columns = _.uniq(_.map(new_merged_filter_list,"column_name"))
  				for(let i=0;i<columns.length;i++){
					var columnHeaderObj = _.find(_this.dataList.headers,['column_name',columns[i]])
					var column_other_details = _.find(this.columnList,["column_name",columns[i]])
					if(new_merged_filter_list[i].isInlineFilter){
						//get operator from filter value
						var is_op = commonFuncObj.separate_operator(new_merged_filter_list[i].actual_value1, true)
						//logic for null. If user put only = in the inline filter it means he/she want to fetch null value
						if(new_merged_filter_list[i].actual_value1 === '='){
							new_merged_filter_list[i]['operator'] =  '_is_n_'
							new_merged_filter_list[i]['value1'] = ''
						}

						//logic for not null. If user put only != or <> in the inline filter it means he/she want to fetch not null value
						else if(new_merged_filter_list[i].actual_value1 === '<>' || new_merged_filter_list[i].actual_value1 === '!='){
							new_merged_filter_list[i]['operator'] =  '_is_nt_n_'
							new_merged_filter_list[i]['value1'] = ''
						}
						//logic for contains , start with and end with
						else if (new_merged_filter_list[i].actual_value1.includes('%'))
						{
							let indx_percent = new_merged_filter_list[i].value1.indexOf('%')
							let operator_count = (new_merged_filter_list[i].value1.split("%").length - 1)
							//when count is more than 1 for % apply conatains
							if(operator_count > 1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx%']
							}
							//when index of %  is o apply ends with
							else if(indx_percent == 0){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx']
							}
							//when % is last character in filter apply starts with
							else if(indx_percent === new_merged_filter_list[i]['value1'].length-1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['xx%']
							}

							new_merged_filter_list[i]['value1'] = ""+new_merged_filter_list[i].value1.replace('%', '')
						}
						//logic for anyother operator except %
						else if (this.operator_to_edc_operator[is_op]){
							new_merged_filter_list[i]['operator']=  this.operator_to_edc_operator[is_op]
							new_merged_filter_list[i]['value1'] = new_merged_filter_list[i]['value1'].replace(is_op, '')
						}


						// here we have to write code to manage worktable filters -> date and number
						if(this.isGridForWorkTable){
							if(columnHeaderObj){
								if(JDEFormatterObj.isJDEDate(columnHeaderObj.column_type)){
									new_merged_filter_list[i]['value1'] = getJDEDate(new_merged_filter_list[i]['value1'], this.$session.get('UI_date_format'))
								}
								else if(commonFuncObj.isNumericDataType(columnHeaderObj.data_type)){
									new_merged_filter_list[i]['value1'] =commonFuncObj.replaceText(new_merged_filter_list[i]['value1'])
								}
							}
						}
					}
  					var dataType = ''
  					var column_actual_name = ''
  					if(column_other_details){
  						column_actual_name = column_other_details.column_actual_name
  						dataType = column_other_details.edcdataType
  					}
  					else
  						column_actual_name = columns[i]
                    var firstFilter = true // to add parenthesis on first same column filter
                    for(let j=0;j<new_merged_filter_list.length;j++){
                        var currentFilter = _.cloneDeep(new_merged_filter_list[j])
                        currentFilter['s_parentheses'] = ''
                        currentFilter['e_parentheses'] = ''
                        delete currentFilter.operation
                        if(currentFilter.column_name === columns[i]){
                            if(firstFilter){
                                currentFilter['s_parentheses'] = '('
                                firstFilter =false
                            }
                            currentFilter['operation'] = true // autobind OR between same column
                            currentFilter['column_name'] = column_actual_name

                            if(dataType === 'date'){
                                if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
                                    currentFilter['datedetails1']={
                                        format:this.$session.get('date_format'),
                                        value:currentFilter['value1']
                                    }
                                }
                                if(currentFilter['value1'] && is_op){
                                    currentFilter['datedetails1']={
                                        format:this.$session.get('date_format'),
                                        value:currentFilter['value1']
                                    }
                                }
                                if(currentFilter['value2']){
                                    currentFilter['datedetails2']={
                                        format:this.$session.get('date_format'),
                                        value:currentFilter['value2']
                                    }
                                }
                            }
                            updatedFilterCopy.push(currentFilter)
                        }
                    }
					if(updatedFilterCopy.length > 0){
						updatedFilterCopy[updatedFilterCopy.length - 1]['operation'] = false // autobind AND between two column
						updatedFilterCopy[updatedFilterCopy.length - 1]['e_parentheses'] = ')'
					}
				}
			    // remove operation from last filter
			    delete updatedFilterCopy[updatedFilterCopy.length - 1].operation
                this.FilterJsonList = _.cloneDeep(updatedFilterCopy)
			    return updatedFilterCopy
		    },
            updateTableOptions(options){
                var sortByJson = []
                if(options.sortBy.length > 0 && options.sortDesc.length > 0){
                    this.clickOnSort = true
                    for (var i = 0; i<options.sortBy.length; i++) {
                        let data = {}
                        data['column_name'] = options.sortBy[i]
                        data['type'] =options.sortDesc[i]? "desc":"asc"
                        sortByJson.push(data)
                    }
                }
                if(this.clickOnSort){
                    this.sortJson = _.cloneDeep(sortByJson)
                    this.onSort(sortByJson)
                }
            },
            hideDataGridColumns(){
                var dnone = this.hideColProp
                // this.dataList.headers = []
                for(let i=0;i<this.dataList.headers.length;i++){
                    if(this.dataList.headers[i]['align'] != dnone)
                        this.dataList.headers[i]['default_align'] = this.dataList.headers[i]['align']? this.dataList.headers[i]['align'] : 'left'
                    let obj = _.find(this.visibleCols,["text",this.dataList.headers[i].text])
                    if(!obj){
                        this.dataList.headers[i]['align'] = dnone
                    }
                    else{
                        if(this.dataList.headers[i]['default_align'])
                            this.dataList.headers[i]['align'] = this.dataList.headers[i]['default_align']
                    }
                }
            },
            getFormatedData(header,text,item,is_key_json){
                if(!text && text != 0){
                    if(is_key_json){
                        let actual_key = header.value
                        let keys = actual_key.split('.')
                        /* Here I am considering only two keys for timebieing*/
                        text = item[keys[0]][keys[1]]
                    }
                    else
                        return 'N/A'
                }
                // this specific validation is to remove transaction id from row count column in sql grid
                if(header.validate === 'number'){
                    if(isNaN(text))
                        return ''
                }
                if(!this.dataList.useWrapping){
                    if(header.display_text_key)
                        return item[header.display_text_key] 
                    return text
                }
                let new_text = text
                let isDate = false
                if(!isNaN(text)){
                    if(header.column_type === 'DATEW' || header.column_type === 'DATEG')
                    {
                        // its juliean date. Now need to convert into normal date

                        let days = (text%1000) - 1
                        let years = parseInt(text/1000) + 1900
                        let convertedDate = moment(years+"01-01","YYYY-MM-DD")
                        new_text = convertedDate.add('days',days).format("YYYY-MM-DD")
                        header['align'] = 'left'
                    }
                    return new_text
                }
                let header_text = header.text
                new_text = new_text.replace(/\n/g, '').trim()
                if((header_text.length+2) < new_text.length){
                    let string_to_return = new_text.substring(0,header_text.length-1)
                    return string_to_return+'..'
                }

                // if((new_text.length+2) > 12){
                // 	let string_to_return = new_text.substring(0,11)
                // 	return string_to_return+'..'
                // }
                else
                    return new_text
            },
            getColTooltips(headertext){
                let _this = this
                let description = ''
                let obj = _.find(_this.dataList.headers,["text",headertext])
                if(obj && obj.title)
                    return obj.title
                return headertext
            },
            onDownload(downloadParams){
                this.$emit('onDownload',downloadParams,_.cloneDeep(this.updatedFilter))
            },
            createEvent(recordType,eventName, defaultVal){
                var _this = this
                let record=(recordType == 'single') ? _.cloneDeep(this.selected[0]):this.selected;
                if(eventName == 'onEdit' && this.isInlineEditGrid){
                    let key = this.dataList.itemkey
                    let obj = _.find(this.dataList.rows,[key,record[key]])
                    if(obj){
                        // let index = _.indexOf(this.dataList.rows,obj)
                        obj.is_row_editable = true
                        this.AddRowForGridEditing(obj)
                        this.$emit('onValueUpdate',record)
                    }

                }
                else {
                        if(this.isCompressionApplicable){
                            _this.compressVeritcalSpace = true
                            _this.rowsToDisplay = _this.selected
                        }
                    this.$emit(eventName, record, defaultVal);
                    if(this.isInlineEditGrid) // to overcome select all rows default.Please check if condition in datalist.row and rowTodisplay watch
                        this.selected = []
                }
                if(this.isUpdateLiveStatusGrid){
                    // change icons once user perform the actions
                    setTimeout(()=>{
                        _this.selected = []
                    },800)
                }
            },
            onCancelEditing(record){
                this.$emit('onCancel', record);
                this.selected = []
            },
            getColor(chipFor,value){
                if(!chipFor || !value)
                    return 'grey'
                if(chipFor === 'status'){
                    return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
                }
            },
            validateInput(event,inputType){
                if(!inputType)
                    return true
                var keycode = event.which
                if(inputType === 'number'){
                    if(isValidNumber(keycode))
                        return true

                }
                event.preventDefault()
            },
            radioSelectionChange(record){
		        this.highlightedRows = []
    	        this.$emit('radioSelectionChange', record);
            },
            copyToClipboard(event,header){
                if(!header.copyToClipboard)
                    return
                var source = event.srcElement
                var textArea = document.createElement("textarea");
                textArea.value = source.textContent;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("Copy");
                textArea.remove();
            },
            copyDataToClipboard(copiedString){
                var _this = this
                _this.$copyText(copiedString).then(function (e) {
                    _this.$emit('copyDataToClipboard','success')
                    console.log(e)
                }, function (e) {
                    _this.$emit('copyDataToClipboard','error')
                    console.log(e)
                })
            },
            changeAutoCompleteSelection(event_value,item,header,headerindex,itemindex){
                this.AddRowForGridEditing(item)
                this.$emit('onValueUpdate',item,event_value,header,headerindex,itemindex)
            },
            AddRowForGridEditing(item,header,itemindex){
                if(this.dataList.hideSelect)
                    return
                this.$nextTick(function () {
                    this.$set(this.selected, 0, item);
                })
                
                this.$emit('calculatePolicy',this.rowsToDisplay[itemindex],itemindex)
                
            },
            rowDoubleClick(record,recordIndex){
                if(!this.isInlineEditGrid)
                    this.$emit('ondblClick',record,recordIndex)
                else
                {
                    this.selected = [record]
                    this.createEvent('single','onEdit')
                }
            },
            headerClick(event){
			    event.preventDefault()
    		},
            getIndeterminateState(header,item){
			    // if(!hea)
		    },
            setDisabled(header,item){
                if(!this.dataList.caption)
                    return false
                if(this.dataList.caption ==="adminRole" || this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
                    let service_actions = item.service_actions
                    if(header.text === 'All')
                        return false
                    if(service_actions.indexOf(header.value)>-1)
                        return false
                }
                return true
            },
            checkChanged(event_value,header,item){
			    if(!header.toggleRow || !this.dataList.caption)
				    return
                if(this.dataList.caption ==="adminRole"||this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
                        let service_actions = item.service_actions
                    if(header.text === 'All'){
                        if(!event_value) // this event_value come as a null.make it proper boolean
                            event_value = false
                        service_actions.forEach(function(obj){
                            item[obj] = event_value
                        })
                        return
                    }
                    else{
                        let atleastOneChecked=false
                        let atleastOneUnchecked=false
                        // let obj = _.find(this.dataList.rows,["text","All"])
                        // if(!obj)
                        // 	return
                        service_actions.forEach(function(obj){
                            if(!atleastOneChecked){
                                if(item[obj])
                                    atleastOneChecked = true
                            }
                            if(!atleastOneUnchecked){
                                if(!item[obj])
                                        atleastOneUnchecked = true
                            }
                        })
                        // if(!atleastOneChecked && !atleastOneUnchecked){
                        // 	item.useIndeterminateState = false
                        // }
                        // if(atleastOneChecked && atleastOneUnchecked)
                        // {
                        // 	item.useIndeterminateState = true
                        // }
                        item.all = false
                        if(atleastOneChecked)
                        {
                            let default_actions = item.default_actions
                            default_actions.forEach(function(obj){
                                item[obj] = true
                            })
                        }
                        if(atleastOneChecked && !atleastOneUnchecked){
                            item.all = true
                        }

                    }
                }
		    },
		    applyInlineFilter(event,header,filtertext,fromLostFocus){
                if(!filtertext)
                    return
                let column_name = header.value

                let removed_obj = _.remove(this.inlineFilterList,function(obj){
                    return obj.column_actual_name === column_name
                })

                let filterjson= {"column_name":header.text,"column_actual_name":header.value,"value1":filtertext,"operator":"_cl_","hideKey":true,'isInlineFilter':true}
                if(header.dataType === 'int' || header.dataType === 'date' || header.dataType === 'boolean' || header.align === 'right')
                    filterjson['operator'] = "_eq_"
                if(header.dataType === 'date'){
                    filterjson['datedetails1']={
                        format:this.$session.get('date_format'),
                        value:filtertext
                    }
                }
                filterjson['actual_value1'] = header.filtertext
                this.displayCriteriaPopUp(true)
                // this.updatedFilter.push(_.cloneDeep(filterjson))
                this.inlineFilterList.push(_.cloneDeep(filterjson))
                // header.filtertext = ""
                // On enter, reapply all the inline filters value. For example, a user can type inline filters for 3 different columns but he will press Enter on the last filter text box. Expected behaviour is all 3 filters will get applied to the grid.
                if(fromLostFocus)
                    return
                this.saveCriteria()
            },
            displayCriteriaPopUp(onlyAddFilters){
                var _this = this
                if(this.columnList.length === 0 && this.dataList.headers.length > 0){
                    _.forEach(_this.dataList.headers,function(obj){
                        _this.columnList.push({'column_name':obj.text,"column_actual_name":obj.value,"edcdataType":obj.dataType})
                    })
                }
                // if(_this.oldSelectedChips.length === _this.selectChips.length)
                if(!onlyAddFilters)
                    this.showCriteria = true
            },
            saveCriteria(){
                this.search = ''
                var _this = this
                var search_string = ''
                _this.selectChips = []
                _this.oldSelectedChips = []

                // reshuffle the filters set by user.For example all same name columns will come as toghether with OR and different name will come as AND.
                var updatedFilter_length = _this.updatedFilter.length
                if( updatedFilter_length > 0){
                    // first findout all same name columns

                    // var updatedFilterCopy = _this.getRevisedFilter()


                    // _this.updatedFilter = updatedFilterCopy

                    _.forEach(_this.updatedFilter,function(obj,index){

                        // need to add operation, incase of multiple filter
                        // if(updatedFilter_length > 1 && index!=(updatedFilter_length-1)){
                        // 	obj['operation'] = false // autobind and condtion.
                        // }

                        if(obj.column_name && obj.operator){
                            search_string = _this.selectChips.push(obj.column_name + " "+ _this.getOperatorNameById(obj.operator,obj.value1,obj.value2))

                            // // in case of no last row , obj.operator will be undefined
                            // if(obj.operation === true || obj.operation === false)
                            // {
                            // 	var operation = obj.operation?" OR ": " AND "
                            // 	search_string = search_string + operation
                            // }

                        }
                    })
                }
                _this.oldSelectedChips = _this.selectChips
                _this.search = search_string
                _this.showCriteria = false

                let new_merged_filter_list = _this.getRevisedFilter()
                // emit updateFilter
                _this.onUpdateFilter(new_merged_filter_list)

            },
		    clearInlineFilter(event,header,filtertext){
			    let column_name = header.value
			    let value1 = filtertext
                // let removed_obj = _.remove(this.updatedFilter,function(obj){
                // 	return obj.column_name === column_name && obj.value1 === value1
                // })
                let removed_obj = _.remove(this.inlineFilterList,function(obj){
                    return obj.column_actual_name === column_name && obj.value1 === value1
                })
                header.filtertext = ''
                this.saveCriteria()
            },
            keyUpInlineFilter(event,header,filtertext){
                // 8 backspace, 46 delete
                if((event.keyCode === 8 || event.keyCode===46) && !filtertext){
                    let column_name = header.value
                    let removed_obj = _.remove(this.inlineFilterList,function(obj){
                    return obj.column_actual_name === column_name
                })
                this.saveCriteria()
                }
            },
            isChipShow(index){
                if(this.updatedFilter.length < index || this.updatedFilter[index].hideKey)
                    return false
                return true
            },
            rowSelectionChanged(row,index){
                if(this.isCompressionApplicable)
                    return this.manageCompression();
                if(!this.dataList.itemkey || !this.dataList.allPagesRowSelection || !row[this.dataList.itemkey])
                    return

                // here logic is if row's item key present in the allPagesRowSelectionList then remove it else add it
                if(this.dataList.allPagesRowSelectionList.indexOf(row)>-1)
                    this.dataList.allPagesRowSelectionList.splice(row,1)
                else
                    this.dataList.allPagesRowSelectionList.push(row)
            },
            manageCompression(){
                if(!this.isCompressionApplicable)
                    return ;
                if(this.selected.length === 0){
                    this.compressVeritcalSpace = false
                    this.rowsToDisplay = this.dataList.rows
                    this.highlightedRows = []
                    this.$emit('clearDrillDown')
                    return
                }
            },
            manageHighLight(event, index, item){
                if(item.is_row_editable)
                    return
                if(this.highlightedRows.indexOf(index)===-1){
                    // if(event.ctrlKey)
                        this.highlightedRows.push(index)
                    // else
                        // this.highlightedRows = [index]
                }
                else
                    this.highlightedRows.splice(this.highlightedRows.indexOf(index),1)
            },
            isRowFullfillLinkCriteria(header,record){
                if(!header.isLinkCell ||_.isEmpty(header.linkEvaluationObj) || _.isEmpty(record) || !record[header.value])
                        return false;
                else{
                        let linkEvaluationObj = header.linkEvaluationObj
                        if(linkEvaluationObj.isConditional)
                            // not all cells going to have a link
                            return linkEvaluationObj.expectedValues.indexOf(record[linkEvaluationObj.cmpheader])>-1
                        else if(linkEvaluationObj.isRequiredValue){
                            if(record[linkEvaluationObj.cmpheader])
                                return true
                            return false
                        }
                            // all cells are going to have a link
                        return true;
                }
            },
            linkCellClicked(event,header,record){
                this.$emit("linkCellClicked",event,header,record)
            },
            manageNewPushedRow(row){
                let _this = this
                if(!row)
                    return
                if(this.FilterJsonList.length){
                    let is_fit_in_filter = _this.onClientFilter(this.FilterJsonList,[row],true)
                    if(!is_fit_in_filter || !is_fit_in_filter.length)
                        return
                }
                if(row.operationRowType === 'add')
                    this.dataList.total_count++
                if(row.liveDataFor === 'sqlLog'){
                    let is_existing_row = _.find(_this.dataList.rows,["job_log_id",row.job_log_id])
                    if(is_existing_row){
                        var index = _.findIndex(_this.dataList.rows,["job_log_id",row.job_log_id])
                        this.$set(_this.dataList.rows,index,row)
                        return
                    }
                    else{
                        // if sort is only on job_log_id and its an descending then only insert new row at top of the list and user on the first page
                        if(this.sortJson.length === 1 && this.sortJson[0]['column_name'] === 'job_log_id' && this.sortJson[0]['type'] === 'desc' && this.page === 1){
                            let new_list = _.cloneDeep(_this.dataList.rows)
                            new_list.unshift(row)
                            // doing this resorting to handle if any row get recevied late
                            let new_sorted_list = _.sortBy(new_list,'job_log_id')
                            new_list = _.reverse(new_sorted_list)
                            _this.dataList.rows = new_list.slice(0,_this.perPage)
                        }
                        else if(_this.dataList.rows.length < _this.perPage){
                            _this.dataList.rows.push(row)
                        }
                    }
                /* Not sure how sort if data already in sorted manner. Instead on that implented logic for sort if done by job_log_id */
                // this.onSort(this.sortJson,true)
                }
            },
        },

    }
</script>

<style>
th{
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
	font-size: 12px !important;
}

td{
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
	font-size: 12px !important;
}

tbody tr:nth-of-type(even) {
	background-color:var(--v-datatablealternate-base) !important;
}

.v-pagination__item{
	font-size: 12px !important;
}

.EDCTableFooter {
	font-size: 12px !important;
}

.v-select__selection{
	font-size: 12px !important;
}
td {
	border:none !important;
}

.EDC-VCardColumn{
	height: 60px !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
	overflow: none !important;
}

.EDC-VCombobox{
	display: inline-block !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
}

.v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important;
}

/*.v-text-field {
	padding-top: 0px !important;
	margin-top: 0px !important;
}*/

table {
	border-top:1px var(--v-secondary-lighten3) solid;
}

table {
	color:var(--v-apptext-base) !important;
}
table .theme--light.v-icon{
	color:var(--v-primary-base) !important;
}

.hideCol {
	display:none;
}

.keep-spaces { white-space: pre-wrap; }

/*
*  STYLE 2
*/

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1) !important;
	border-radius: 10px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar
{
	width: 5px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	background-color: #DCDCDC !important;

}

.scrollbar
{
	background: white !important;
	overflow-y: hidden !important;
	margin-bottom: 25px !important;
	overflow-x:  scroll !important;
}
.v-sheet {
	border-radius: 5px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot{
	padding-left:3px !important;
	padding-top:0px !important;
	padding-right:3px !important;
	padding-bottom:0px !important;
	height:20px !important;
}

.v-text-field--rounded {
	border: 2px solid;
    border-radius: 5px;
    border-color: var(--v-primary-base) !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-text-field__slot {
	min-width:16px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner{
	margin-top:0px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon{
	height:20px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon > .v-icon.v-icon{
	font-size:16px !important;
}

.grid-cell-link{
	text-decoration: underline !important;
	cursor:pointer !important;
}

</style>