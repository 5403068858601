<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <select v-model="Environment" @change="getAllMediaObjects" style="-webkit-appearance: menulist" autofocus ref="envname">
                                <option :value="{}">Select Environment</option>
                                <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li>Available Media Objects</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid :dataList="tableList" @onDownload="onDownload"></edc-data-grid> 
                    </v-col>
                </v-row>
                <loading-panel :loader="loader"></loading-panel>

                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    
    import config from '../../config.json'
    import { post as postToServer } from '../../methods/serverCall.js';
    import {SERVER_ERROR} from '../../data/client_message.js'
    import {getEnvironmentList} from '../../methods/EnvironmentList.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'

    export default{
        name:'EDCMediaObjects',
        data(){
            return {
                Environment:{},
                EnvironmentList:[],
                headers:[
                    { text: 'Associated Table', value: 'associate_table', title: 'Table Associated Media Object'},
                    { text: 'Media Object Name', value: 'media_obj_name', title: 'Media Object Name'},
                    { text: 'Media Object Formation', value: 'query_formation', title: 'Media Object Query Formation'},
                    { text: 'Media Object Query String', value: 'associate_join', title: 'Media Object Query String'},
                    { text: 'Involved Columns', value: 'columns_in_associate_join', title: 'Columns Involved To Form Media Object'},
                    { text: 'Filter String', value: 'additional_filter', title: 'Filter String'},
                    { text: 'Is Distinct', value: 'apply_distinct', title: 'Query Using Like Query'},
                    // { text: 'Is Using Like', value: 'is_like_query', title: 'Query Using Like Query'},
                ],
                tableList: {
                    headers:[],
                    actions:[],
                    rows:[]
                },
                loader:false,
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout,  
            }
        },
        mounted() {
            this.tableList = this.getGridObj(this.headers,'id',true, {showSelect:false})
            getEnvironmentList(this);
            this.Environment = this.$session.get('selected_env')
            this.getAllMediaObjects()
        },
        methods: {
            getAllMediaObjects(){
                if(_.isEmpty(this.Environment) || !this.Environment.id)
                    return
                var _this = this
                var data ={
                    "db_type":this.Environment.environment_database_type
                }
                _this.tableList.rows = []
                _this.loader = true
                postToServer(_this, config.JDE_FORMATER_URL+'/get_all_media_objects_formation', data).then(response=>{
                    _this.loader = false
                    _this.tableList.rows = response
                }).catch(error=>{
                    _this.loader = false
                })
            },
            onDownload(downloadParams, filterArray){
                let _this = this; 
                _this.loader=true;
                let filter=[];
                // filterArray.map((obj, index)=>{
                //     obj.stringArray.map((stringObj, stringIndex)=>{
                //         let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                //             filter.push(cloneDeep(temp));
                //     })
                // });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option": downloadParams.recordType,
                    "client_id": this.$session.get('client_id'),
                    "env_id": "",
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter,
                    "db_type":this.Environment.environment_database_type
                }
                var url = config.JDE_FORMATER_URL+'/download_all_media_objects_formation';    
                postToServer(this, url, inputJson).then(Response => {
                    var url = config.JDE_FORMATER_URL + "/static/" + Response;
                    window.open(url, '_blank');
                    _this.loader=false;
                    
                }).catch(objError => {
                    _this.loader=false;
                    if(objError){
                        _this.loader = null
                        _this.snackbar = true
                        _this.colorValue = 'error'
                        _this.snackbartext = objError;
                        }
                        else {
                        _this.snackbar = true
                        _this.colorValue = 'error'
                        _this.snackbartext = SERVER_ERROR;
                        }
                })
            },
        },
    }
</script>