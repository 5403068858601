<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row" style="padding-top:10px !important" align="center">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb" style="padding-left: 14px !important">
                            <li>Security Role Details</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
    
        <edc-data-grid :dataList="tableList" @onDownload="onDownload" @onNew="onNew" @onEdit="onEdit" @onRoleRevoke="onRoleRevoke" @onCancel="onCancel" @onSave="onSave"></edc-data-grid>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import { GET_ALL_ROLE, GET_ALL_USERS_LIST, GET_USER_WISE_ROLE, REVOKE_ROLE, EXPORT_USER_WISE_ROLES, SET_USER_ROLE_MAPPING} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "./../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import data from './metaData.js'

export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            userRole:'',
            userlist:[],
            roleList:[],
            tableList: {
                headers: [
                    { text: 'Role Name', value: 'role_name', width: '50%', title: 'Security Role Name', option:[], 'item_text':'role_name', 'item_value':'role_name', useAutoComplete:true},
                    // { text: 'Role Type', value: 'role_type', width: '30%', title: 'Security Role Type', option:[], 'item_text':'role_type', 'item_value':'role_type', useAutoComplete:true },
                    { text: 'Username', value: 'username', width: '40%', title: 'Security Role username', option:[], 'item_text':'username', 'item_value':'username', useAutoComplete:true}
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                is_row_editable:true,
                is_grid_inline_edit:true,
                itemkey:'role_name',
            },
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true, 
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 5
                },
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            page: 1,
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[
            {'text':'edit','key':"role_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION), index:1},
            {'text':'new','key':"role_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION), index:2},
            {'text':"save","key":"is_row_editable",selectType:"single", index:3},
            {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
            {'text':'rolerevoke', 'key':"role_name", selectType:"multiple",role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE), index:5}];
        if(this.tableList.paginationType == CLIENT_SIDE)
            this.envIpJson.page_size="*";
        this.getAllRoleDetails(this.envIpJson);
        this.getAllRole();
        this.getUserRole();
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": "",
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.Security_URL+EXPORT_USER_WISE_ROLES;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.Security_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        getAllRoleDetails(envIpJson) {
            var client_id = this.$session.get('client_id')
            var url = config.Security_URL + GET_USER_WISE_ROLE + client_id;
            envIpJson.client_id = client_id
            let inputJson = envIpJson;
            postToServer(this, url, inputJson).then(Response => {
                if(Response){
                    this.tableList.rows = Response;  
                }
            });
        },
        // addRole(){
        //     let _this = this;
        //     _this.$router.push('/manageuserrole');
        // },
        // onEdit:function(record){
        //     let _this = this;
        //     // this.$router.push({ name: 'manageenvironment', params: { environment_id: record.id, type:'edit' }})
        //     this.$router.push({ name: 'manageuserrole', params: { role_id: record.role_id, role_type: record.role_type }});
        // },
        onRoleRevoke: function(record){
            var client_id = this.$session.get('client_id')
            var data = {'client_id':client_id, 'role_id': record[0].role_id, 'username': record[0].username, 'role_name': record[0].role_name, 'user_id': record[0].user_id}
            var url = config.Security_URL + REVOKE_ROLE
            postToServer(this, url, data).then(Response => {
                if(Response){
                    this.getAllRoleDetails(this.envIpJson); 
                    this.colorValue = 'success'
                    this.snackbar = true
                    this.snackbartext = Response;
                }
            }).catch(ProcessDocError => {
                if(ProcessDocError){
                    this.getAllRoleDetails(this.envIpJson); 
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.tableList.rows = [];
                    this.tableList.total_count =0; 
                    this.snackbartext = ProcessDocError;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        onNew(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fillup the open row';
                return
            }
          this.tableList.rows.unshift({ 
                'role_name':'',
                'username':'',
                'is_row_editable' : true
                });              
        },
        onCancel(){
            this.getAllRoleDetails(this.envIpJson);
        },
        onSave(record){
            if(!record.role_name || !record.username){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required.';
                return false
            }
            this.saveUser(record);            
        },
        saveUser(record) {
            if (!this.userInput.role_id) {
                this.colorValue = 'error'
                this.snackbartext = 'Please select role';
                this.snackbar = true;
                return;
            }
            var selected_users = _.filter(this.userList,['isselected',true])
            if (!selected_users) {
                this.colorValue = 'error'
                this.snackbartext = 'Select atleast one user';
                this.snackbar = true;
                return;
            }
            var selected_env_list = _.map(selected_envs, 'id')
            var selected_user_list = _.map(selected_users, ['user_id', 'username'])
            var newUserList = _.map(selected_users,function(data){
                return {'user_id':data.user_id,'username':data.username}
            })
            this.userInput.env_id = selected_env_list
            this.userInput.user_list = newUserList
            var url = config.Security_URL + SET_USER_ROLE_MAPPING;
            postToServer(this, url, this.userInput).then(Response => {
                   this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = "User added to group successfully";
            }).catch(error_response => {
                if (error_response) {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = error_response;
                } else {
                       this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = error_response;
                }
            });
        },
        GetUserList(){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id": client_id}
            var get_user_url = config.Security_URL + GET_ALL_USERS_LIST
            postToServer(_this, get_user_url, data).then(response => {
                var userlist = response;
                this.tableList.headers[1].option = response;
                userlist.map(function(obj){
                    obj.isselected = false
                })
                _this.userList = userlist
            }).catch(error_response => {
            }); 
        },
        getAllRole() {
            var client_id = this.$session.get('client_id')
            var url = config.Security_URL + GET_ALL_ROLE + client_id;
            getToServer(this, url).then(Response => {
                this.roleList = Response;
                this.tableList.headers[0].option = response;
            });
        },
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>