<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                            <v-col class="EDC-Col">
                                <ul class="breadcrumb breadcrumbUL">
                                <li>
                                    <router-link to="/currentjoblist">Active Jobs</router-link>
                                </li>
                                <li>
                                    <router-link to="/currentjoblist">{{ executionEnvironmentName }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/currentjoblist">{{ jobPlanName }}</router-link>
                                </li>
                                <li><router-link to="/currentjoblist">Manage Job Plan Instance</router-link></li>
                                <li class="EDC-Heading">{{jobPlanName}} ({{jobPlanInstanceId}})</li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row  class="EDC-Row " style="padding:8px !important;padding-bottom:0px !important;">
                    <v-col  cols="6" class="EDC-Col  EDC-ColsSpacing">                                    
                        <v-card class="card_class">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col subHeader" align="left">
                                    Plan Details
                                </v-col>
                            </v-row> 
                            <v-row class="EDC-Row" style="padding-top:8px !important;padding-bottom:8px !important;">
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Status: <b>{{ displayPlanStatus }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Mode: <b>{{ modeOfExecution }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Completed Jobs: <b>{{ completedJobs }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Terminated Jobs: <b>{{ terminatedJobs }}</b>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" style="padding-bottom:32px !important;">
                                <!-- <v-col cols="3" class="EDC-Col" align="left">
                                    Running Jobs: <b>{{ runningJobs }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Suspended Jobs: <b>{{ suspendedJobs }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Error Jobs: <b>{{ errorJobs }}</b>
                                </v-col>
                                <v-col cols="3" class="EDC-Col" align="left">
                                    Waiting Jobs: <b>{{ waitingJobs }}</b>
                                </v-col> -->
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col" cols="3" align="left">
                                    <vc-button :addMarginCss="false" itemText="Resume" @click.native="JobPlanResume()" :disabled="isDisableResumebtn" :iconObject="{'name':'job_run_v2','title':'Resume Job Plan'}"></vc-button>
                                </v-col>
                                <v-col class="EDC-Col"  cols="3" align="left">
                                    <vc-button :addMarginCss="false" itemText="Suspend"  @click.native="JobPlanSuspend()" :disabled="isDisableSuspendbtn" :iconObject="{'name':'job_suspend_v2','title':'Suspend Job Plan'}"></vc-button>
                                </v-col>
                                <v-col class="EDC-Col"  cols="3" align="left">
                                    <vc-button :addMarginCss="false" itemText="Terminate"  @click.native="JobPlanTerminate()"  :disabled="isDisableTerminatedbtn" :iconObject="{'name':'job_terminate_v2','title':'Terminate Job Plan'}"></vc-button>
                                </v-col>
                                <v-col class="EDC-Col"  cols="3" align="left">
                                    <vc-button :addMarginCss="false" itemText="View More.."   @click.native="GetJobPlanInstanceLogs()" :iconObject="{'name':'job_details_v2','title':'View Logs Of The Plan'}"></vc-button>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col class="EDC-Col  EDC-ColsSpacing">
                        <v-card  class="card_class">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col subHeader" align="left">
                                    Plan Configuration
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" style="padding-top:16px !important;">
                                <v-col cols="2" class="EDC-Col">
                                    <v-text-field type="number" min="1" max="10" label="Concurrency (Max.10)" @blur='validateParellelJobCount' v-model="parellel_job_count" required  class="EDC-UIComponent" dense title="Concurrent Jobs Allow To Run (Maximum 10 Jobs)"></v-text-field>
                                </v-col>
                                <v-col class="EDC-Col" style="padding-left:16px !important;">
                                    <v-autocomplete style="padding-top:4px !important;" hide-details dense :items="action_on_error" item-text="action_description" 
                                    item-value="id" v-model="actionOnError" label="Action On Job Failure">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" v-if="tableRows.length">
                                <v-col class="EDC-Col" align="right">
                                    <vc-button itemText="Save Configuration"  @click.native="saveCofiguration()"></vc-button>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        
                        <edc-common-job-table key="jobinplan" :tableItemKey="'id'" :tableHeaders="tableHeaders"
                        :tableActions="getTableActions" :tableRows="tableRows" :pageFor="'instance'" 
                        @addNewJobs="addNewJobs" @onDeleteJobs="onDelete" @onSuspend = "onJobSuspend" 
                        @onTerminate = "onJobTerminate"  @onResume='onJobResume' @onHistory="onHistory"
                        @saveSnapshot="saveSnapshot">
                                
                        </edc-common-job-table>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row" v-if="tableRows.length">
                    <v-col class="EDC-Col" align="right" style="padding-right: 8px !important;padding-bottom:24px!important;">
                        <vc-button itemText="Save Changes" :disabled="isAutoSave" @click.native="saveSnapshotClicked()"></vc-button>
                    </v-col>
                </v-row>
            </v-col>
            <loading-panel :loader="loader"></loading-panel>
            <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        </v-row>
    </v-container>
</template>
<style scoped>
    .radioClass >>> label{
        padding-top:4px !important;
    }
    .card_class {
        height: 100%;
        padding:8px !important;
        padding-bottom:8px
    }
    .subHeader{
        font-size: 13px !important;
        font-weight: bold !important;
    }
</style>
<script>
    import config from '../../config.json'
    import { post as postToServer } from '../../methods/serverCall.js';
    import {COLOR_CODE} from '@/data/macros.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import CommonPlanJobsTable from './commonManageJobPlanAndInstance.vue'
    import {EXECUTEJOB, TERMINATEJOB, RESUMEJOB, SUSPENDJOB, RESUME_COORDINATION} from '../../data/url_constants.js';
    import {jobStatusWithWaiting} from "@/data/job_management.js"

    export default{
        name:'ManageJobPlanInstance',
        props:{
        },
        components:{
            'edc-common-job-table':CommonPlanJobsTable
        },
        data(){
            return{
                colorCode: COLOR_CODE,
                loader:false,
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout, 
                executionEnvironmentId:'',
                executionEnvironmentName:'',
                jobPlanId:'',
                modeOfExecution:'',
                jobPlanName:'',
                jobPlanInstanceId:'',
                clientId:this.$session.get('client_id'),
                tableHeaders: [
                    {
                        text: 'Sequence',
                        value: 'instance_details_sequence_no',
                        hideInlineFilter: true,
                        align:'right'
                    },
                    {
                        text: 'Job / Plan Name',
                        value: 'pdDisplayName',
                        showHtml:true
                    },
                    {
                        text: 'Dependent On',
                        value: 'parent_job',
                    },
                    // _.cloneDeep(jobStatusWithWaiting),
                    {
                        text: 'Status',
                        value: 'statusToDisplay', 
                        options:[], 
                        title: 'Job Status',
                        useChip:true,
                        chipFor:'status'
                    },
                    {
                        text: 'Policies',
                        value: 'policy_details',
                        hideInlineFilter: true
                    },
                ],
                tableRows:[],
                tableActions:[],
                isAutoSave:false,
                parellel_job_count:0,
                running_status:"",
                ParellelJobCountRules: [v => !!v || 'Parellel Job count is required.'],
                action_on_error:[],
                actionOnError:1,
                statusUpdatedBy:"",
                savedRows:[],
                logList:[],
                completedJobs:0,
                terminatedJobs:0,
                runningJobs:0,
                suspendedJobs:0,
                errorJobs:0,
                totalJobs:0,
                waitingJobs:0
            }
        },
        computed:{
            showAddNew(){
                // if(this.statusUpdatedBy == "scheduler"){
                //     return false
                // }
                return this.running_status !='completed' && this.running_status !='terminated' && this.running_status !='terminating'
            },
            isDisableSuspendbtn(){
                var statuslist = ["suspended", "suspending", 'Sent for approval', 'scheduled', 'terminated', 'completed', 'terminating']
                return statuslist.includes(this.running_status)
                // return this.running_status === "suspended" || this.running_status === "suspending" || this.running_status == 'Sent for approval' || this.running_status == 'scheduled'
            },
            isDisableResumebtn(){
                var not_status_list = ["suspended", "error"]
                var status_list = ['Sent for approval', 'scheduled', 'terminated', 'completed', 'terminating']
                return !not_status_list.includes(this.running_status) || status_list.includes(this.running_status)

                // return !(this.running_status === 'suspended' || this.running_status === "error") ||  this.running_status == 'Sent for approval' || this.running_status == 'scheduled'
            },
            isDisableTerminatedbtn(){
                var status_list = ["suspending", 'Sent for approval', 'scheduled', 'terminated', 'completed', 'terminating']
                return status_list.includes(this.running_status)
                //return this.running_status === "suspended" || this.running_status === "suspending"  || this.running_status == 'Sent for approval' || this.running_status == 'scheduled'
            },
            getTableActions(){
                return [
                    {'text': 'newWidOptions', 'role':this.showAddNew, 'key': "sequence_no",index:1,isJobPlan:true, 
                        availableOptions:[ 
                            {title:'Add Job', event:'onAddJob'}, {title:'Add Job Plan', event:'addJobPlan'}]
                    },
                    { 'text': 'suspend', selectType: "single", role:true, index:2, key:'showsuspend', cmpValue:'Y',
                     options:[ {"value": "immediate", "text":'Immediate'} , 
                                {"value":"wait", "text": 'After Running Job(s) Completed/Terminated'}],
                                isConfirmationForJobMgmnt:true 
                    },
                    { 'text':'resume', selectType:"single", role:true, index:3, "displayText":"Resume",
                     key:'showresume', cmpValue:'Y'}, 
                    {
                        'text': 'terminate',
                        selectType: "single",
                        role:true,
                        index:4,
                        key:'showterminate',
                        cmpValue:'Y',
                        options:[
                            {"value": "immediate", "text":'Immediate'} ,
                            {"value":"wait", "text": 'After Running Job(s) Completed/Terminated'}
                        ],
                        isConfirmationForJobMgmnt:true
                    },
                    {'text':'up','key':"id", selectType:"multiple", role:true,index:5,displayText:'Move Job Up'},
                    {'text':'down','key':"id", selectType:"multiple", role:true,index:6,displayText:'Move Job Down'},
                    {'text':'depadd','key':"id", selectType:"multiple", role:true,index:7,displayText:'Add Dependency'},
                    {'text':'depremove','key':"id", selectType:"multiple", role:true,index:8,displayText:'Remove Dependency'},
                    {'text':'delete','key':"status", selectType:"multiple", role:true,index:9,displayText:'Delete Jobs',
                    'cmpValue':'Waiting for execution'},
                    { 'text':'history', 'key':"id", selectType:"single", role:true, index:10 }
                ]
            },
            displayPlanStatus(){
                return this.running_status?this.running_status.charAt(0).toUpperCase() + this.running_status.slice(1):''
            }
        },
        watch:{
            '$store.state.jobPlanLiveStatus':{
                handler(newValue){
                    if(newValue.instance_id === this.jobPlanInstanceId){
                        let findObj = _.find(this.tableRows, ['id', newValue.instance_detail_id])
                        if(findObj){
                            let index_obj = this.tableRows.indexOf(findObj)
                            if(newValue.status == 'completed' || newValue.status == "terminated"){
                                this.tableRows.splice(index_obj, 1)
                            }
                            findObj.status = newValue.status
                            findObj.job_id = newValue.job_id

                            this.manageObjProps(findObj)
                        }
                        
                    }
                }
            },
            '$store.state.jobPlanDashboardInfo':{
                handler(newValue){
                    if(newValue.instance_id === this.jobPlanInstanceId){
                        this.running_status = newValue.status
                        this.completedJobs = newValue.completed
                        this.terminatedJobs = newValue.terminated
                        this.runningJobs = newValue.running
                        this.errorJobs = newValue.error
                        this.suspendedJobs = newValue.suspended
                        this.waitingJobs = newValue.waiting_for_execution
                    }
                }
            }
        },
        mounted(){
            this.executionEnvironmentId = this.$route.params.env_id
            this.executionEnvironmentName = this.$route.params.env_name
            this.modeOfExecution = this.$route.params.mode_of_execution
            this.jobPlanId = this.$route.params.job_plan_id
            this.jobPlanName = this.$route.params.job_plan_name
            this.userRole = this.$session.get('user_role_mapping');
            this.getAvailableJobs()
            this.getAllJobPlanEventAction()
        },
        methods: {
            getAllJobPlanEventAction(){
                var _this =this
                var data = {
                    "client_id":this.clientId
                }
                postToServer(_this, config.JOB_PLAN_URL+'/get_all_event_action',data).then(response=>{
                    _this.action_on_error = response
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            getAvailableJobs(){
                var _this =this
                var data = {
                    "client_id":this.clientId,
                    "job_plan_id":this.jobPlanId,
                    'env_id':this.executionEnvironmentId,
                     'plan_instance_id': _this.jobPlanInstanceId
                    // 'show_completed':true
                }
                this.tableRows = []
                postToServer(_this, config.JOB_PLAN_URL+'/manage_unprocessed_job_list',data).then(response=>{
                    _this.loader = false
                    _this.jobPlanInstanceId = response.instance_id
                    _this.jobPlanId = response.job_plan_id
                    _this.actionOnError= response.event_action
                    _this.parellel_job_count = response.parellel_job_count
                    _this.running_status = response.running_status
                    _this.statusUpdatedBy = response.status_updated_by
                    _this.terminatedJobs = response.terminated
                    _this.completedJobs = response.completed
                    response.job_list.forEach(obj=>{
                        _this.manageObjProps(obj)
                    })
                    console.log("manage_unprocessed_job_list=> ", response)
                    _this.tableRows = response.job_list
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            manageObjProps(obj){
                obj.showresume = 'N'
                obj.showterminate = 'N'
                obj.showsuspend = 'N'
                if(obj.status == 'Waiting for execution')
                {
                    obj.showresume = 'N'
                    obj.showterminate = 'N'
                    obj.showsuspend = 'N'
                }
                if(obj.status === 'running')
                {
                    obj.showterminate = 'Y'
                    obj.showsuspend = 'Y'
                }
                else if(obj.status === 'suspended' || obj.status === 'error'){
                    obj.showterminate = 'Y'
                    obj.showresume = 'Y'
                }
                obj.statusToDisplay = obj.status.charAt(0).toUpperCase()+obj.status.substring(1)
                return obj
            },
            showMessage(message, type='error'){
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message;
            },
            addNewJobs(newJobArray){
                var _this = this
                var data = {
                    "client_id":this.$session.get('client_id'),
                    "job_list":newJobArray,
                    "job_plan_instance_id":_this.jobPlanInstanceId,
                    "job_plan_id": _this.jobPlanId,
                    "execution_environment":this.executionEnvironmentId,
                    "execution_env_name":this.executionEnvironmentName
                }
                _this.loader = true
                postToServer(_this,config.JOB_PLAN_URL +'/add_object_to_instance',data).then(response=>{
                    _this.loader = false
                    _this.showMessage("New job(s)/plan added successfully in the job plan running instance.")
                    _this.getAvailableJobs()
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            onDelete(jobsToDelete){
                var _this = this
                if(!jobsToDelete.length)
                    return
                var data = {
                    "delete_jobs":jobsToDelete,
                    "instance_id":_this.jobPlanInstanceId,
                    "client_id":_this.clientId,
                    "env_id":_this.executionEnvironmentId
                }
                _this.loader = true
                postToServer(_this, config.JOB_PLAN_URL +'/delete_jobs_from_instance', data).then(response=>{
                    _this.loader = false
                    _this.lastSelectedRows = []
                    setTimeout(()=>{
                        _this.getAvailableJobs()
                    },1000)
                    _this.showMessage("Plan updated successfully","success")
                }).catch(error=>{
                    _this.showMessage(error)
                })
            },
            saveSnapshot(rows, logList){
                this.savedRows = rows
                this.logList = logList
            },
            saveSnapshotClicked(){
                var rows = this.savedRows
                var logList = this.logList
                var _this = this
                var data = {
                    "client_id":this.$session.get('client_id'),
                    "updated_job_list":rows,
                    "instance_id":_this.jobPlanInstanceId,
                    "job_plan_id": _this.jobPlanId,
                    "logs":logList
                }
                _this.loader = true
                postToServer(_this, config.JOB_PLAN_URL +'/update_job_plan_instance', data).then(response=>{
                    _this.loader = false
                    _this.showMessage("Plan instance updated successfully","success")
                    response.job_list.forEach(obj=>{
                        _this.manageObjProps(obj)
                    })
                    _this.tableRows = response.job_list
                }).catch(error=>{
                    _this.getAvailableJobs()
                    _this.showMessage(error)
                })
            },
            saveCofiguration(){
                var _this = this
                var data = {
                    "client_id":this.$session.get('client_id'),
                    "instance_id":_this.jobPlanInstanceId,
                    "parellel_job_count": _this.parellel_job_count,
                    "actionOnError": _this.actionOnError,
                    "job_plan_id": this.jobPlanId
                }
                _this.loader = true
                postToServer(_this, config.JOB_PLAN_URL +'/update_instance_configuration', data).then(response=>{
                    _this.loader = false
                    _this.showMessage("Plan updated successfully","success")
                }).catch(error=>{
                    _this.loader = false
                    _this.getAvailableJobs()
                    _this.showMessage(error)
                })
            },
            onJobSuspend(record, defaultVal){
                var _this = this
                var job_data = {
                    "job_id":record.job_id,
                    "job_exe_auto_id": record.job_exe_auto_id,
                    "client_id":this.$session.get('client_id'),
                    "env_id":this.executionEnvironmentId,
                    "env_name": this.executionEnvironmentName
                }
                if(defaultVal === 'suspend_after_commit')
                    job_data['status'] = defaultVal
                postToServer(this, config.ENGINE_API_URL + SUSPENDJOB, job_data
                    ).then(response => {
                    _this.getAvailableJobs()
                    }).catch(SuspendJobError => {
                    if(SuspendJobError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SuspendJobError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                })
            },
            onJobTerminate(record,defaultvalue){
                var _this = this
                var job_data = {
                    "job_id":record.job_id,
                    "client_id":this.$session.get('client_id'),
                    "status":"terminate",
                    "env_id":this.executionEnvironmentId,
                    "env_name": this.executionEnvironmentName
                }
                if(defaultvalue === 'terminate_after_commit')
                    job_data['status'] = defaultvalue
                postToServer(this, config.ENGINE_API_URL + TERMINATEJOB,job_data
                    ).then(response => {
                        _this.getAvailableJobs()
                }).catch(TerminateJobError => {
                    if(TerminateJobError){
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = TerminateJobError;
                    }
                    else {
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
                })
            },
            onJobResume(record,defaultvalue){
                var _this = this
                var job_data = {
                'job_id':record.job_id,
                'client_id':this.$session.get('client_id'),
                "env_id":this.executionEnvironmentId,
                "env_name": this.executionEnvironmentName,
                "process_definition_id":record.process_definition_id
                }
                postToServer(this, config.COORDINATOR_API_URL + RESUME_COORDINATION, job_data
                ).then(response => {
                    var data = response
                    if(data){
                    record.perform = null;
                    record.status="completed"
                    }
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'info'
                    this.snackbartext = response.message;

                }).catch(ResumeJobError => {
                    if(ResumeJobError){
                    this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = ResumeJobError;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
                })
            },            
            onHistory(record){
                let _this = this;
                if(record.job_id){
                    this.$router.push({ name: 'showjobstepsresult', 
                        params: {
                                env_id: this.executionEnvironmentId, 
                                envname:this.executionEnvironmentName,
                                jobname: record.process_definition_name, 
                                jobid: record.job_id,
                                instance_id: this.jobPlanInstanceId,
                                job_plan_id:this.jobPlanId,
                                mode:record.mode_of_execution,
                                status:record.status,
                                type:'manageRunningInstance',
                                show_last_page:true ,
                                job_plan_name:this.jobPlanName,
                    }})
                }
                else{
                    this.snackbar = true;
                    this.colorValue = 'error'
                    this.snackbartext = "Job is in waiting state. Check history once start running";
                }
            },
            
            JobPlanResume(){
                var job_data = {'client_id':this.$session.get('client_id'),
                                'env_id': this.executionEnvironmentId,
                                'job_plan_instance_id':this.jobPlanInstanceId};
                let baseUrl = config.JOB_PLAN_URL +'/resume_job_plan'
                this.loader = true
                postToServer(this, baseUrl, job_data
                ).then(response => {
                    this.loader=false;
                    this.getAvailableJobs()
                                 
                }).catch(CurrentJobError => {
                    this.loader=false;
                    this.snackbar = true;
                    this.colorValue = 'error'
                    this.snackbartext = CurrentJobError;
                    })
            },

            JobPlanSuspend(){
                var job_data = {'client_id':this.$session.get('client_id'),
                                'env_id': this.executionEnvironmentId,
                                'job_plan_instance_id':this.jobPlanInstanceId,
                                immediate_suspend:true};
                let baseUrl = config.JOB_PLAN_URL +'/suspend_job_plan'
                this.loader = true
                postToServer(this, baseUrl, job_data
                ).then(response => {
                    this.getAvailableJobs()       
                }).catch(CurrentJobError => {
                    this.loader=false;
                    this.snackbar = true;
                    this.colorValue = 'error'
                    this.snackbartext = CurrentJobError;
                })
            },
            JobPlanTerminate(){
                var job_data = {'client_id':this.$session.get('client_id'),
                                'env_id': this.executionEnvironmentId,
                                'job_plan_instance_id':this.jobPlanInstanceId,
                                immediate_terminate:true};
                let baseUrl = config.JOB_PLAN_URL +'/terminate_job_plan'
                this.loader = true
                postToServer(this, baseUrl, job_data
                ).then(response => {
                    this.getAvailableJobs()           
                }).catch(CurrentJobError => {
                    _this.loader=false;
                    this.snackbar = true;
                    this.colorValue = 'error'
                    this.snackbartext = CurrentJobError;
                    })
                    this.loader=false;

            },
            validateParellelJobCount(){
                if(!this.parellel_job_count || isNaN(this.parellel_job_count)){
                    this.parellel_job_count =1
                    return
                }
                if(parseInt(this.parellel_job_count) < 1)
                        this.parellel_job_count = 1
                else if(parseInt(this.parellel_job_count) > 10)
                        this.parellel_job_count = 10
            },

            GetJobPlanInstanceLogs() {
                let _this = this;
                this.$router.push({ 
                        name: 'jobplaninstancedetails',
                        params: {
                            job_plan_id: this.jobPlanId,
                            job_plan_instance_id: _this.jobPlanInstanceId,
                            job_plan_name:this.jobPlanName,
                            env_id: this.executionEnvironmentId,
                            envname: this.executionEnvironmentName,
                            'isFromInstance':true,
                            'modeOfExecution':this.modeOfExecution
                        }
                    }
                );

                // var env_id = this.executionEnvironmentId;
                // var job_data = {'client_id':this.$session.get('client_id'),
                //                 'env_id': env_id,
                //                 'job_plan_instance_id': this.jobPlanInstanceId};
                // let baseUrl = config.JOB_PLAN_URL;
                //     baseUrl = baseUrl+ '/get_job_instance_logs';
                // _this.loader = true
                // postToServer(this, baseUrl, job_data
                //     ).then(response => {
                //     _this.loader=false;
                //     _this.logTable.rows = response;
                // }).catch(CurrentJobError => {
                //     _this.loader=false;
                //     this.logTable.rows=[];
                //     this.snackbar = true;
                //     this.colorValue = 'error'
                //     this.snackbartext = CurrentJobError;
                //     })
                },
        },
    }
</script>