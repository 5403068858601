<template>
    <div>
        <v-layout row wrap pl-4 style="background-color:RGB(224,232,247)">
            <v-flex pl-3 style="margin:auto; text-align: left">
                <v-layout row wrap>
                    <ul class="breadcrumb" >
                        <li>Package Builder</li>

                        <li v-if="this.erp_code.length!=0">{{this.erp_code}}</li>
                        <li v-if="this.erp_version.length!=0">{{this.erp_version}}</li>
                    </ul>
                </v-layout>
            </v-flex>        
        </v-layout>
        <v-layout row wrap>
            <v-flex xs6 mt-3 pl-5 style="text-align: left">
                <v-layout row wrap>
                    <v-textarea label="Package Description" rows=1
                    v-model="package_description" style="margin-right:10px;"></v-textarea>
                </v-layout>
            </v-flex>
        </v-layout>
            <vc-data-table :data="tableList"></vc-data-table>
         <v-flex row wrap xs12 ml-1>
             <v-btn @click="createPackage" outline :color="outlineColor" style="border-radius:10px">Create Package</v-btn>
         </v-flex>
         <simplert :useRadius="true"
            :useIcon="true"
            ref="simplert">
          </simplert> 
        <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
            {{ snackbartext }}
        </v-snackbar>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>
<script>
import Simplert from 'vue2-simplert'
import moment from 'moment'
// import Breadcrumb from "../../Breadcrumbs.vue"
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import { FETCH_ERP_OBJECT_VERSIONS  } from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import dataTable from '../../views/dataTable/datatable.vue'
import LoadPanel from "../../views/loading.vue"
import {BTN_COLOR} from '@/data/macros.js'
import {
    fetch_all_erp,
    fetch_erp_version
    } from "./../../methods/erp_details.js";

// import dummyData from './tableData.js'
// import data from './metaData.js'//
export default {
    name: 'DemoGrid',
    data: function () {
        return {
            outlineColor:BTN_COLOR,
            loader:false,
            selectedRows:[],
            promo_path_id: null,
            process_def: null,
            search: '',
            process_doc_list: [],
            package_description: '',
            // EnvironmentListRules: [v => !!v || 'Environment is required.'],
            tableList: {
                headers: [
                    { text: 'Name', value: 'object_name', sortable: false, cssClass:'w16', title: 'Object Name'},
                    { text: 'Type', value: 'object_type_desc', sortable: false, cssClass:'w16', width: '5%', title: 'Object Type'},
                    { text: 'Ver', value: 'object_revision', sortable: false, options:[], align:'right', cssClass:'w16', width: '5%', title: 'Object Version'},
                    { text: 'Erp Version', value: 'erp_version', sortable: false, options:[], align:'right', cssClass:'w16', title: 'Object Erp Version'},
                    { text: 'Erp code', value: 'erp_code', sortable: false, options:[], align:'right', cssClass:'w16', title: 'Object Erp Code'},
                    { text: 'Created By', value: 'added_by', sortable: false, options:[], cssClass:'w16', title: 'Object Created By'},
                    { text: 'Created Date', value: 'timezone_aware_added_on_date',type:'date', sortable: false, options:[], cssClass:'w16', title: 'Object Created Date'},
                    { text: 'Created On', value: 'timezone_aware_added_on_time',type:'date', sortable: false, options:[], cssClass:'w16', title: 'Object Created On'},
                    { text: 'Last Modified By', value: 'modified_by', sortable: false, options:[],cssClass:'w16', title: 'Object Last Modified By' },
                    { text: 'Last Modified Date', value: 'modified_on', sortable: false,  options:[], cssClass:'w16', title: 'Object Last Modified Date'}
                ],               
                actions: [],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                select_rows:false,
                disableDefaltSorting:true,
            },
            //  flag:true,
             snackbar:false,
             snackbartext:'',
             snackbartimeout:5000,
             colorValue:'success',
             showEnvType:false,
             valid:true,
             dbTypefltrAry:[],
             nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
             chips: [],
             items: [],
             sorting : config.SORTING_TYPE,
             envIpJson : {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 5
                },
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            perPageArray: [5, 10, 50, 'all'],
            page: 1,
            totalPages:1,
            totalRecords:1,
            filterKey: "",
            sortingData: [],
            inputArray: {
                'Environment Type': [],
                'Name': [],
                'Database Type': [],
                'Database Location':[],
                'Action': []
            },
            sortKey: '',
            sortOrders: {
                'type': 1,
                'name': 1,
                'environment_database_type': 1,
                'databases_locations':1,
                'Action': 1
            },
            object_list :this.$route.params.object_list,
            erp_code: this.$route.params.erp_code,
            erp_version : this.$route.params.erp_version
        }
    },
    components: {
    'vc-data-table': dataTable,
    Simplert,
    'loading-panel':LoadPanel
    },
    mounted() {
        this.object_list =this.$route.params.object_list 
        this.erp_code= this.$route.params.erp_code
        this.erp_version = this.$route.params.erp_version
        this.fetch_object_list()
    },
    methods: {
         fetch_object_list(){
            var _this = this
            var url = config.REPOSITORY_API_URL + FETCH_ERP_OBJECT_VERSIONS
            var data = {"object_id_version_list": this.object_list}
            postToServer(this, url, data).then(response => {
                var data = response
                _this.loader = false
                if(data){
                this.tableList.rows = data;
                this.tableList.total_count = data.length; 
                this.process_doc_list = data
                } else {
                    data = []
                    this.tableList.rows = data;
                    this.tableList.total_count = data.length; 
                }
            }).catch(ProcessDocError => {
                _this.loader = false
                if(ProcessDocError){
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.tableList.rows = [];
                    this.tableList.total_count =0; 
                    this.snackbartext = ProcessDocError;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        createPackage:function(){
            var _this = this
            var package_id = this.package_id
            var package_name = this.package_name
            var description = ""
            var package_display_name = "sdsd"
            var package_build_data = {
                'client_id': _this.$session.get('client_id'),
                "erp_code": _this.erp_code, 
                "erp_version": _this.erp_version,
                'package_objects':[],
                "package_description": _this.package_description,
                'package_display_name':package_display_name
                }
            package_build_data.package_objects = _this.tableList.rows;
            postToServer(this, config.PACKAGE_API_URL + '/create_package', package_build_data).then(response => { 
                package_id = response.package_details_id
                package_name = response.package_name
                description = response.package_description
              this.$router.push({name: 'PackageObject', params: {package_id: package_id, package_name: package_name, package_description:description}});
                }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null 
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0; 
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                })
        }
    }
}
</script>
<style scoped>
</style>