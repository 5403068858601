<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid :dataList="tableList" @onHistory="onHistory" @onPageChange="onPageChange"
                        @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"
                        @onReExecute="onReExecute" @onDelete="onDelete" @onDownload="onDownload"
                        @ondblClick="onHistory" @onShowPD="onShowPD" @showObjectTable="onShowPD"
                        @ShowObjectGraph="onGraphView" @linkCellClicked="showActivityDetails"></edc-data-grid>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-dialog v-model="viewpd_dialog" max-width="800" height='600' eager persistent>
                            <v-card class="rounded-card">
                                <v-toolbar dark dense max-height="40">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col text-md-center">PD Viewer
                                            <v-icon class="text-lg-left" @click="viewpd_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row no-gutters class="EDC-Row">
                                        <v-col class="EDC-Col">
                                            <edc-pdviewer :objectdetails="objectdetails" key="pdviewer">

                                            </edc-pdviewer>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <loading-panel :loader="loader"></loading-panel>

                <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import config from '../../../config.json'
    import { post as postToServer } from '../../../methods/serverCall.js';
    import { GETJOBHISTORY, EXPORT_JOB_HISTORY_DETAILS, DELETE_JOB_DATA }from '../../../data/url_constants.js';
    import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
    import getUserRole from '../../../methods/GetUserRole.js'
    import {manageScreenDisplay} from '@/methods/special.js'
    import {jobTypeHeader, jobModeHeader, jobEXEType, jobStatusOnHistory} from "@/data/job_management.js"
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'

    export default{
        name:'JobHistory',
        mixins:[mixinJobManagement],
        props:{
            changedEnvironment:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                tableList: {
                    headers: [],
                    actions: [],
                    rows: []
                },
                tableHeader:[
                    { text: 'Id', value: 'id', align:'right', title: 'Job Id', dataType:'int'},
                    { text: 'Process Doc Name', value: 'process_definition_name',  width: '150px', options:[],  title: 'Process Doc Name'},
                    _.cloneDeep(jobTypeHeader),
                    _.cloneDeep(jobModeHeader),
                    _.cloneDeep(jobEXEType),
                    _.cloneDeep(jobStatusOnHistory),
                    { text: 'Plan Name', value: 'job_plan_name', options:[], title: 'Job Plan Name'},
                        { text: 'Duration', value: "duration", options:[],align:'right', title: 'Duration', hideInlineFilter: true},
                    {text: 'Policy Date', value: 'actual_date', options:[], title: 'Job Policy Date', dataType:'date'},
                    { text: 'Processing Time', value: "total_running_time", options:[],align:'right', title: 'Job Total Processing Time in Mins', hideInlineFilter: true},
                    { text: 'Start Date', value: "timezone_aware_start_date", dataType:'date',  options:[], title: 'Job Start Date'},

                    { text: 'Start Time', value: "timezone_aware_start_time", options:[], title: 'Job Start Time', dataType:'time', hideInlineFilter: true},
                    { text: 'End Date', value: "timezone_aware_end_date", dataType:'date', options:[], title: 'Job End Date'},
                    { text: 'End Time', value: "timezone_aware_end_time", options:[], title: 'Job End Time', dataType:'time', hideInlineFilter: true},
                        { text: 'Dup Found', value: 'is_execute_duplicate', options:[], title: 'Duplicate Found &/or Skipped'},
                    { text: 'Initiated By', value: "created_by", cssClass:'w16',width: '140px', options:[], title: 'Job Execution Initiated By'},
                    { text: 'Report', value: "report_step_available",  options:[], title: 'Report Step Available', dataType:'boolean'},

                    { text: 'Ver', value: 'process_definition_revision',  width: "10px;", align:'right', title: 'Job Version', dataType:'int'},
                    { text: 'Object ID', value: 'process_definition_id', width: '200px', options:[], title: 'Object ID', align:'right'},
                ],
                envIpJson : {
                    "filter": [],
                    "sort": [{"coumn_name":"id","type":"desc"}],
                    "page": 1,
                    "page_size": 10
                },
                pageType:'jobHistory'
            }
        },
        computed: {
            
        },
        watch:{
            '$store.state.liveStatus':{
                handler(newValue){
                    if(newValue && ['completed','terminated'].indexOf(newValue.status)>-1)
                        this.getAllJobHisotry(this.envIpJson)
                }
            },
            changedEnvironment:{
                handler(newvalue){
                    this.Environment = newvalue
                    this.getAllJobHisotry(this.envIpJson)
                }
            },
        },
        mounted() {
            this.tableList = this.getGridObj(this.tableHeader,'id',false)
            this.envIpJson.page_size = manageScreenDisplay()
            this.userRole = this.$session.get('user_role_mapping');
            this.tableList.actions = [
                {'text':'history','key':"id", selectType:"single",index:1},
                {'text':'re_execute','key':"showreexcute", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),cmpValue:'Y',index:2},
                {'text': 'delete', 'key': 'id', selectType:"multiple", index:4,role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,'delete')},
                {'text': 'showpd', 'key': 'showreexcute', cmpValue:'Y', selectType:"single", index:5, role:true}
            ]
            this.getAllJobHisotry(this.envIpJson)
        },
        methods:{
            onDownload(downloadParams, filterArray){
                let _this = this;
                _this.loader=true;

                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option": downloadParams.recordType,
                    "client_id": _this.$session.get('client_id'),
                    "env_id": _this.Enviro,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filterArray
                }
                var url = config.ENGINE_API_URL+EXPORT_JOB_HISTORY_DETAILS;
                postToServer(_this, url, inputJson).then(Response => {
                    var url = config.ENGINE_API_URL + "/static/" + Response;
                    window.open(url, '_blank');
                    _this.loader=false;
                }).catch(objError => {
                    _this.showMessage(objError)
                });
            },
            filterData(filterArray,pageno){
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.filter=filterArray;
                inputJson.page=1
                this.getAllJobHisotry(inputJson);
            },
            onPageChange(page, perPage) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getAllJobHisotry(inputJson);
            },
            onPerPageChange(perPage,page) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getAllJobHisotry(inputJson);
            },
            sortBy: function (sortJson) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.sort = sortJson
                this.getAllJobHisotry(inputJson);
            },
            getAllJobHisotry (envIpJson,hideLoader=false) {
                let inputJson = this.envIpJson;
                if(!this.isEnvironmentSelected)
                    return
                let _this = this;
                this.$session.set('selected_env', this.Environment)
                _this.tableList.rows =[]
                _this.tableList.total_count = 0
                var job_data = {
                    "client_id":this.clientId,
                    "filter": inputJson.filter,
                    "page": inputJson.page,
                    "page_size": inputJson.page_size,
                    "sort": inputJson.sort,
                    "env_id": this.Environment.id
                }
                this.loader = true
                postToServer(this, config.ENGINE_API_URL + GETJOBHISTORY, job_data).then(response => {
                    _this.loader = false
                    var data = response
                    if(data){
                        _.forEach(data.result,(obj,index)=>{
                            obj.job_id = obj.id
                            if(obj.is_restored || obj.job_type === "sync_up")
                                obj.showreexcute ='N'
                            else
                                obj.showreexcute = 'Y'
                            _this.changeCommonJobProperties(obj,index)
                        })
               
                        _this.tableList.rows = data.result;
                        _this.tableList.total_count = data.total
                    } 
                }).catch(CurrentJobError => {
                    this.showMessage(CurrentJobError);
                })
            },
            onDelete(record){
                this.snackbar = false
                let _this = this
                var job_ids = _.map(record,"id")
                var job_data = this.commonRequestData()
                Object.assign(job_data, {"deleted_from_job_id_list":true,"job_id_list": job_ids})
                this.loader = true;
                postToServer(this, config.ENGINE_API_URL + DELETE_JOB_DATA, job_data).then(response => {
                    _this.showMessage(response,"success")
                    _this.getAllJobHisotry(_this.envIpJson);
                }
                ).catch(CurrentJobError => {
                    _this.showMessage(CurrentJobError)
                })
            },
            showActivityDetails(event, header,record){
                let filter={
                    "column_name": "job_id",
                    "column_actual_name": "job_id",
                    "value1": record.id+"", // number to string
                    "operator": "_eq_",
                    "hideKey": true,
                    "isInlineFilter": true,
                    "actual_value1":record.id+"", // number to string
                    "s_parentheses": "(",
                    "e_parentheses": ")"
                }
                this.$router.push({ name: 'businessobjectactivitylist', params: {'filter':[filter],'isFromJobStatus':true}});
            },
        }
    }
</script>