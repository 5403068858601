<template>
  <div
    id="app"
    style="background:white"
  >
    <vue-editor
      v-model="obj_description"
      placeholder="Description"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

 <script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props:['vueEditorObj'],

  data() {
    return {
      obj_description: "",
      customToolbar:  [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                [{ 'header': 1 }, { 'header': 2 }],
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image', 'video', 'formula'],
                [{ 'direction': 'rtl' }],
                ['clean'],
              ]
    };
  },
  watch: {
    vueEditorObj(newvalue,oldvalue){
      this.obj_description = ''
      if(newvalue && newvalue.description)
        this.obj_description = this.vueEditorObj.description
    },
    obj_description(newvalue){
        this.$emit('DescriptionChange',newvalue);
    }
  }
};
</script>