<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col" style="padding-left:5px !important">
        <v-row class="EDC-Row" style="padding-top:10px !important">
          <v-col cols="12" class="EDC-Col" style="padding-left:5px !important; padding-right:5px !important">
            <ul class="breadcrumb">
              <li>Environment Role Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import _ from 'lodash'
import environmentList from '@/methods/EnvironmentList.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data() {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      loader: "loading",
      snackbartimeout: Snackbar_Default_Timeout,
      loading: false,
      roleList:[],
      EnvironmentList:[],
      envIpJson: {
        "filter": [],
        "sort": [{
            "column_name": "",
            "type": ""
        }],
        "page": 1,
        "page_size": 5
      },
      tableList:{
        headers: [
          {text: 'Environment', value: 'env_name', width:"50%", option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true},
          {text: 'Role', value: 'role', width:"50%", option:[], 'item_text':'role', 'item_value':'role', useAutoComplete:true}
        ], 
        actions: [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        is_row_editable:true,
        rows: [],
        selected_rows: [],
        syncHeaderScroll:false,
        disableDefaltSorting:true,
        itemkey:'id',
        is_grid_inline_edit:true
      },
    }
  },

  mounted() {
    this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable", selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable", selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    this.GetAllRoleList();
    this.GetEnvironmentList();
    this.GetRoleList();
  },

  methods: {
    GetAllRoleList() {
      let data = {
        'client_id':this.$session.get('client_id')}
      var url = config.Security_URL + '/get_all_env_role_mapping'
      postToServer(this, url, data).then(Response => {
        if(Response){
            this.tableList.rows = Response;
        }
      });
    },

    GetEnvironmentList() {
      environmentList(this).then(response=>{
        if(response)
          this.EnvironmentList = response;
          this.tableList.headers[0].option = response;
      },response=>{
          this.ShowErrorMessage=true
          this.ErrorMessage=response
      })
    },

    GetRoleList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id}
      var get_role_url = config.Security_URL + '/get_environmental_roles'
      postToServer(_this, get_role_url, data).then(response => {
      var rolelist = response;
      this.tableList.headers[1].option = response;
        rolelist.map(function(obj){
            obj.isselected = false
        })
        _this.roleList = rolelist
      }).catch(error_response => {
      }); 
    },

    onNew() {
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(obj)
      {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'First fiilup the open row';
        return
      }
      this.tableList.rows.unshift({
        'env_name':'',
        'role':'',
        'is_row_editable': true,
      });
    },

    onCancel() {
      this.GetAllRoleList();
    },

    onSave(record) {
      if(!record.env_name || !record.role){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'All fields are required';
        return false
      }
      record.id ? this.updateRole(record) : this.saveRole(record);            
    },

    saveRole(record) {
      let data = {
        'client_id':this.$session.get('client_id'),
        'role_id':_.find(this.roleList,['role',record.role]).role_id,
        'env_id':_.find(this.EnvironmentList,['name',record.env_name]).id}
      var url = config.Security_URL + '/manage_environmental_role'
      postToServer(this, url, data).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "Role added Successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    updateRole(record) {
      let data = {
        'id':record.id,
        'client_id':this.$session.get('client_id'),
        'role_id':_.find(this.roleList,['role',record.role]).role_id,
        'env_id':_.find(this.EnvironmentList,['name',record.env_name]).id}
      var url = config.Security_URL + '/manage_environmental_role'
      postToServer(this, url, data).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "Role updated Successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    onDelete(record) {
      let inputJson = {
        'client_id':this.$session.get('client_id'),
        'id':record.id}
      let url = config.Security_URL + '/delete_environmental_role';              
      postToServer(this, url, inputJson).then(response  => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = response;
        this.GetAllRoleList();
      }).catch(error_response => {
        this.GetAllRoleList();
        if(error_response){
          this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = error_response;
        } else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });               
    },
  }
}
</script>
<style scoped>

</style>