<template>
    <div style="display:inline">
        <div v-if="selectedArray.length" style="display:inline">
            
            
            <!-- <v-tooltip bottom v-if="getReportDownload"
                v-show="selectedArray.length <=1 || reportDownloadObject.selectType == 'multiple'">
                <v-icon class="iconFormat" slot="activator"
                    @click="createEvent(reportDownloadObject.selectType, 'onReportDownload')"
                    show="!reportDownloadObject.key || record[reportDownloadObject.key]">
                    fas fa-download</v-icon>
                <span>Report download</span>
            </v-tooltip> -->
            <v-tooltip bottom v-if="getScheduleObject"
                v-show="selectedArray.length <=1 || scheduleObject.selectType == 'multiple'">
                <v-icon slot="activator"
                    v-show="!scheduleObject.key || selectedArray[0][scheduleObject.key] == scheduleObject.cmpValue"
                    @click="createEvent(scheduleObject.selectType, 'onSchedule')" class="iconFormat">far fa-clock
                </v-icon>
                <span>Schedule</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getExecuteObject"
                v-show="selectedArray.length <=1 || executeObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.execute" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                    <!-- <v-icon slot="activator" v-on="on" v-show="!executeObject.key || 
                                        selectedArray[0][executeObject.key] == executeObject.cmpValue"
                        class="iconFormat align-icon">fa-play</v-icon> -->

                        <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_run_v2" slot="activator" v-show="!executeObject.key || 
                                        selectedArray[0][executeObject.key] == executeObject.cmpValue" :original="true" title="Execute"></svgicon>

                        
                    </template>
                    <confirmation-panel heading="Start Run" label="Are you sure want to execute?"
                        lblSuccess="Execute" @onCancel="popOver.execute = false" @onSuccess="createEvent(executeObject.selectType, 'onExecute');
                                            popOver.execute = false"></confirmation-panel>
                </v-menu>
                <span>Execute</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getStartObject"
                v-show="selectedArray.length <=1 || startObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.start" :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on }">
                    <v-icon slot="activator" v-on="on"
                        v-show="selectedArray[0].perform == 'start' || 
                                        (selectedArray[0].status == 'completed' && selectedArray[0].is_restored != 'Restoration')"
                        class="iconFormat align-icon">fa-play</v-icon>
                    </template>
                    <confirmation-panel heading="Start Run" label="Are you sure want to start?"
                        lblSuccess="Execute" @onCancel="popOver.start = false" @onSuccess="createEvent(startObject.selectType, 'onExecute');
                                            popOver.start = false"></confirmation-panel>
                </v-menu>
                <span>Start</span>
            </v-tooltip>
            
            

            <v-tooltip bottom v-if="getSaveObject"
                v-show="selectedArray.length <=1 || saveObject.selectType == 'multiple'">
<!--                 <v-icon class="iconFormat" slot="activator" @click="createEvent(saveObject.selectType, 'onSave')"
                    v-show="!saveObject.key || selectedArray[0][saveObject.key]">fas fa-save</v-icon> -->
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="save_v2" slot="activator" @click="createEvent(saveObject.selectType, 'onSave')"
                    v-show="!saveObject.key || selectedArray[0][saveObject.key]" :original="true" title="Save"></svgicon>

                <span>Save</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getCancelObject"
                v-show="selectedArray.length <=1 || cancelObject.selectType == 'multiple'">
                <!-- <v-icon class="iconFormat" slot="activator" @click="onCancelEditing(selectedArray[0])"
                    v-show="!cancelObject.key || selectedArray[0][cancelObject.key]">fas fa-times</v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="cancel_v2" slot="activator" @click="onCancelEditing(selectedArray[0])"
                    v-show="!cancelObject.key || selectedArray[0][cancelObject.key]" :original="true" title="Cancel"></svgicon>


                <span>cancel</span>
            </v-tooltip>
            <v-tooltip bottom style="top:5px" v-if="getRevoke"
                v-show="selectedArray.length <=1 || revokeObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.revokeObj" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                    <v-icon slot="activator" v-on="on" class="iconFormat align-icon"
                        v-show="!revokeObject.key || selectedArray[0][revokeObject.key] == revokeObject.cmpValue">fa-ban
                    </v-icon>
                </template>
                    <confirmation-panel heading="Cancel Request" label="Are you sure want to Cancel?"
                        lblSuccess="Yes" @onCancel="popOver.revokeObj = false"
                        @onSuccess="createEvent(revokeObject.selectType, 'onRevoke');popOver.revokeObj = false">
                    </confirmation-panel>
                </v-menu>
                <span>Reject</span>
            </v-tooltip>
            <v-tooltip bottom style="top:5px" v-if="getRoleRevoke"
                v-show="selectedArray.length <=1 || roleRevokeObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.roleRevokeObj" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator" class="iconFormat align-icon"
                        v-show="!roleRevokeObject.key || selectedArray[0][roleRevokeObject.key] == roleRevokeObject.cmpValue">fa-ban
                    </v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid" v-on="on" name="delete_v2" slot="activator" @v-show="!roleRevokeObject.key || selectedArray[0][roleRevokeObject.key] == roleRevokeObject.cmpValue" :original="true" title="Remove"></svgicon>
                </template>
                    <confirmation-panel heading="Revoke Role" label="Are you sure want to Revoke?"
                        lblSuccess="Yes" @onCancel="popOver.roleRevokeObj = false"
                        @onSuccess="createEvent(roleRevokeObject.selectType, 'onRoleRevoke');popOver.roleRevokeObj = false">
                    </confirmation-panel>
                </v-menu>
                <span>Remove</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getStatus"
                v-show="selectedArray.length <=1 || onStatusObject.selectType == 'multiple'">
                <v-icon slot="activator" @click="createEvent(onStatusObject.selectType, 'onStatus')"
                    v-show="!onStatusObject.key || selectedArray[0][onStatusObject.key]" class="iconFormat">
                    fa-info-circle</v-icon>
                <span>Status</span>
            </v-tooltip>
            <v-tooltip bottom style="top:5px" v-if="getCancelRequest"
                v-show="selectedArray.length <=1 || cancelRequestObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.cancelRequestObject" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator" class="iconFormat align-icon"
                        v-show="!cancelRequestObject.key || selectedArray[0][cancelRequestObject.key] == cancelRequestObject.cmpValue">
                        fa-ban</v-icon> -->
                        <template v-slot:activator="{ on }">
                        <svgicon class="svg-icon-grid svg-fill-grid" v-on="on" name="delete_v2" slot="activator" v-show="!cancelRequestObject.key || selectedArray[0][cancelRequestObject.key] == cancelRequestObject.cmpValue" :original="true" title="Cancel Request"></svgicon>

                    </template>
                    <confirmation-panel heading="Cancel Request" label="Are you sure want to Cancel?"
                        lblSuccess="Yes" @onCancel="popOver.cancelRequestObject = false"
                        @onSuccess="createEvent(cancelRequestObject.selectType, 'onCancelRequest');popOver.cancelRequestObject = false">
                    </confirmation-panel>
                </v-menu>
                <span>Cancel request</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getRequestHistory"
                v-show="selectedArray.length <=1 || RequestHistoryObject.selectType == 'multiple'">
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="job_details_v2" slot="activator" @click="createEvent(RequestHistoryObject.selectType, 'onRequestHistory')"
                    v-show="!RequestHistoryObject.key || selectedArray[0][RequestHistoryObject.key]" :original="true" title="Request History"></svgicon>

                <span> Request History</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getAccept"
                v-show="selectedArray.length <=1 || acceptObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator" @click="createEvent(acceptObject.selectType, 'onAccept')"
                    v-show="!acceptObject.key || selectedArray[0][acceptObject.key]" class="iconFormat">fa-check
                </v-icon> -->
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="approval_approve_v2" slot="activator" @click="createEvent(acceptObject.selectType, 'onAccept')"
                    v-show="!acceptObject.key || selectedArray[0][acceptObject.key]" :original="true" title="Accept"></svgicon>
                <span>Accept</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getRevokeRequest"
                v-show="selectedArray.length <=1 || revokeRequestObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator" @click="createEvent(revokeRequestObject.selectType, 'onRevokeRequest')"
                    v-show="!revokeRequestObject.key || selectedArray[0][revokeRequestObject.key]" class="iconFormat">
                    fa-ban</v-icon> -->
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="approval_reject_v2" slot="activator" @click="createEvent(revokeRequestObject.selectType, 'onRevokeRequest')"
                    v-show="!revokeRequestObject.key || selectedArray[0][revokeRequestObject.key]" :original="true" title="Reject"></svgicon>
                <span>Reject</span>
            </v-tooltip>
            
            <v-tooltip bottom v-if="getHistory"
                v-show="selectedArray.length <=1 || historyObject.selectType == 'multiple'">

                <template v-slot:activator="{ on }">
                    
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_details_v2" :original="true" @click="createEvent(historyObject.selectType, 'onHistory')"
                        v-show="!historyObject.key || selectedArray[0][historyObject.key]" :title="historyObject.title||'History'"></svgicon>

                </template>
                <span> History</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getReExecute && reExecuteObject.role"
                v-show="selectedArray.length <=1 || reExecuteObject.selectType == 'multiple'">
                <template v-slot:activator="{ on }">                    
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_run_v2" :original="true"  v-show="!reExecuteObject.key || selectedArray[0][reExecuteObject.key] === reExecuteObject.cmpValue"  @click="createEvent(reExecuteObject.selectType, 'onReExecute')" title="Run Again"></svgicon>
                </template>
                <span>Run again</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getResume && resumeObject.role"
                v-show="selectedArray.length <=1 || resumeObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.resume" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!resumeObject.key || selectedArray[0][resumeObject.key] == 'resume'"
                        class="iconFormat align-icon">fas fa-retweet</v-icon> -->
                    <template v-slot:activator="{ on }">

                    <svgicon class="svg-icon-grid svg-fill-grid" v-on="on" name="job_run_v2" slot="activator" v-show="!resumeObject.key || selectedArray[0][resumeObject.key] == 'resume'" :original="true" title="Resume"></svgicon>
                    </template>
                    <confirmation-panel heading="Resume Job" label="Are you sure want to Resume?"
                        lblSuccess="Resume" @onCancel="popOver.resume = false"
                        @onSuccess="createEvent(resumeObject.selectType, 'onResume');popOver.resume = false">
                    </confirmation-panel>
                </v-menu>
                <span>Resume</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getSuspend && suspendObject.role"
                v-show="selectedArray.length <=1 || suspendObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.pause" :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!suspendObject.key || selectedArray[0][suspendObject.key] == suspendObject.cmpValue"
                        class="iconFormat align-icon">fas fa-ban</v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_suspend_v2" :original="true" title="Pause"></svgicon>
                </template>

                    <suspendconfirmation-panel v-if="selectedArray.length === 1 && selectedArray[0]['mode_of_execution'] === suspendObject.optionKeyValue" 
                    heading="Pause Job" label="Select suspend type from below options" :customOptions="suspendObject.options"
                        lblSuccess="Suspend" @onCancel="popOver.pause = false"
                        @onSuccess="createEvent(suspendObject.selectType, 'onPause',$event);popOver.pause = false">
                    </suspendconfirmation-panel>

                    <confirmation-panel v-else heading="Pause Job" label="Are you sure want to Pause?"
                        lblSuccess="Pause" @onCancel="popOver.pause = false"
                        @onSuccess="createEvent(suspendObject.selectType, 'onPause');popOver.pause = false">
                    </confirmation-panel>

                    

                </v-menu>
                <span>Pause</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getTerminate && terminateObject.role"
                v-show="selectedArray.length <=1 || terminateObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.terminate" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!terminateObject.key || selectedArray[0][terminateObject.key] !== ''"
                        class="iconFormat align-icon">far fa-times-circle</v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_terminate_v2" slot="activator" v-show="!terminateObject.key || selectedArray[0][terminateObject.key] !== ''" :original="true" title="Terminate"></svgicon>
                </template>
                    <confirmation-panel heading="Terminate Job" label="Are you sure want to Terminate?"
                        lblSuccess="Terminate" @onCancel="popOver.terminate = false"
                        @onSuccess="createEvent(terminateObject.selectType, 'onTerminate');popOver.terminate = false">
                    </confirmation-panel>
                </v-menu>
                <span>Terminate</span>
            </v-tooltip>
                 
            <v-tooltip bottom v-if="getCreateExeObject && createExecutableObject.role"
                v-show="selectedArray.length <=1 || createExecutableObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator"
                    v-show="!createExecutableObject.key || selectedArray[0][createExecutableObject.key]"
                    @click="createEvent(createExecutableObject.selectType, 'onCreate');" class="iconFormat">fas fa-plus-square
                </v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="job_run_v2" slot="activator" v-show="!createExecutableObject.key || selectedArray[0][createExecutableObject.key] != 'N'"
                    @click="createEvent(createExecutableObject.selectType, 'onCreate');" :original="true" :title="createExecutableObject.displayText || 'Create'"></svgicon>

                <!-- <span v-if="createExecutableObject.displayText">{{createExecutableObject.displayText}}</span> -->
                <span>{{createExecutableObject.displayText || 'Create'}}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getAddMultipleObject && addMultipleObject.role"
                v-show="selectedArray.length <=1 || addMultipleObject.selectType == 'multiple'">
                <svgicon class="svg-icon-grid svg-fill-grid" name="add_multiple_v2" slot="activator" v-show="!addMultipleObject.key || selectedArray[0][addMultipleObject.key]"
                    @click="createEvent(addMultipleObject.selectType, 'onAddMultiple');" :original="true" :title="addMultipleObject.displayText || 'Add Multiple'"></svgicon>

                <span>{{addMultipleObject.displayText || 'Add Multiple'}}</span>
            </v-tooltip>
             <v-tooltip bottom v-if="getEditCommitsObject && editCommitsObject.role"
                v-show="selectedArray.length <=1 || editCommitsObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator"
                    v-show="!editCommitsObject.key || selectedArray[0][editCommitsObject.key] == editCommitsObject.cmpValue"
                    @click="createEvent(editCommitsObject.selectType, 'onEditCommit');" class="iconFormat">fa-edit
                </v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_edit_v2" slot="activator" v-show="!editCommitsObject.key || selectedArray[0][editCommitsObject.key] == editCommitsObject.cmpValue"
                    @click="createEvent(editCommitsObject.selectType, 'onEditCommit');" :original="true" title="Edit Limit Size"></svgicon>

                <span>Edit Commit Size</span>
            </v-tooltip>
            <!-- <v-tooltip bottom v-if="getEditObject && editObject.role"
                v-show="selectedArray.length <=1 || editObject.selectType == 'multiple'">
                <img src="./images/edit.svg" slot="activator" alt="edit" height="32" width="32"
                    @click="createEvent(editObject.selectType, 'onEdit')" v-if="!selectedArray[0].is_row_editable"
                    v-show="!editObject.key || !selectedArray[0][editObject.key]" style="margin-right:5px" />
                <span>Edit</span>
            </v-tooltip> -->
            <v-tooltip bottom v-if="getEditObject && editObject.role && !selectedArray[0].is_row_editable" v-show="selectedArray.length <=1 || editObject.selectType == 'multiple'">



                <!-- <v-icon class="iconFormat" slot="activator" @click="createEvent(editObject.selectType, 'onEdit')"
                    v-show="!editObject.key || selectedArray[0][editObject.key] != ''">far fa-edit</v-icon> -->
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_edit_v2" slot="activator" @click="createEvent(editObject.selectType, 'onEdit')"
                    v-show="!editObject.key || selectedArray[0][editObject.key] != ''" :original="true" title="Edit"></svgicon>

                <span>Edit</span>
            </v-tooltip>
            
            <v-tooltip bottom v-if="getRestore && restoreObject.role"
                v-show="selectedArray.length <=1 || restoreObject.selectType == 'multiple'">
                <!-- <v-icon class="iconFormat" slot="activator" @click="createEvent(restoreObject.selectType, 'onRestore')"
                    v-show="!restoreObject.key || selectedArray[0][restoreObject.key] == restoreObject.cmpValue">
                    fas fa-recycle</v-icon> -->

                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" :name="restoreObject.svgname || 'archive_restore_v2'" slot="activator" @click="createEvent(restoreObject.selectType, 'onRestore')"
                    v-show="!restoreObject.key || selectedArray[0][restoreObject.key] == restoreObject.cmpValue" :original="true" title="Restore"></svgicon>

                <span>Restore</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getDispose && disposeObject.role"
                v-show="selectedArray.length <=1 || disposeObject.selectType == 'multiple'">
                <!-- <v-icon class="iconFormat" slot="activator" @click="createEvent(disposeObject.selectType, 'onDispose')"
                    v-show="!disposeObject.key || selectedArray[0][disposeObject.key]== disposeObject.cmpValue">
                    fas fa-trash</v-icon> -->

                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="archive_dispose_v2" slot="activator" @click="createEvent(disposeObject.selectType, 'onDispose')"
                    v-show="!disposeObject.key || selectedArray[0][disposeObject.key]== disposeObject.cmpValue" :original="true" title="Dispose"></svgicon>

                <span>Dispose</span>
            </v-tooltip>


            <v-tooltip bottom v-if="getDeleteCopy && deleteCopyObject.role"
                v-show="selectedArray.length <=1 || deleteCopyObject.selectType == 'multiple'">
                <!-- <v-icon class="iconFormat" slot="activator" @click="createEvent(deleteCopyObject.selectType, 'onDeleteCopy')"
                    v-show="!deleteCopyObject.key || selectedArray[0][deleteCopyObject.key]== deleteCopyObject.cmpValue">
                    fas fa-trash-alt</v-icon> -->
                    <svgicon class="svg-icon-grid svg-fill-grid" name="delete_v2" slot="activator" @click="createEvent(deleteCopyObject.selectType, 'onDeleteCopy')"
                    v-show="!deleteCopyObject.key || selectedArray[0][deleteCopyObject.key]== deleteCopyObject.cmpValue" :original="true" title="Delete Copy"></svgicon>

                <span>Delete Copy</span>
            </v-tooltip>

            <!-- <v-tooltip bottom v-if="getDeleteCopy"
                v-show="selectedArray.length <=1 || deleteCopyObject.selectType == 'multiple'">
                <v-icon class="iconFormat" slot="activator"
                    @click="createEvent(deleteCopyObject.selectType, 'onDeleteCopy')"
                    show="!deleteCopyObject.key || record[deleteCopyObject.key]">
                    work_off</v-icon>
                <span>Delety Copy</span>
            </v-tooltip> -->


            <v-tooltip bottom v-if="getEditKeyObject && editKeyObject.role"
                v-show="selectedArray.length <=1 || editKeyObject.selectType == 'multiple'">
                <!-- <v-icon class=" iconFormat" slot="activator" @click="createEvent(editKeyObject.selectType, 'onEdit')">
                    far fa-edit</v-icon> -->
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_edit_v2" slot="activator" @click="createEvent(editKeyObject.selectType, 'onEdit')" :original="true" title="Edit"></svgicon>

                <span>Edit</span>
            </v-tooltip>

            <!-- <v-tooltip bottom v-if="getCopy && copyObject.role"
                v-show="selectedArray.length <=1 || copyObject.selectType == 'multiple'">
                <img src="./images/copy.svg" slot="activator" alt="copy" height="32" width="32" style=""
                    @click="createEvent(copyObject.selectType, 'onCopy')" style="margin-right:5px;cursor:pointer" />
                <span>Copy</span>
            </v-tooltip> -->
            

            <!-- <v-tooltip bottom v-if="getDelete && deleteObject.role"
                v-show="selectedArray.length <=1 || deleteObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.delete" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <img src="./images/delete.svg" slot="activator" alt="delete" height="32" width="32"
                        style="margin-right:5px;cursor:pointer" />
                    <confirmation-panel heading="Confirm Action" label="Are you sure want to delete?"
                        lblSuccess="Delete" @onCancel="popOver.delete = false" @onSuccess="createEvent(deleteObject.selectType, 'onDelete');
                                            popOver.delete = false"></confirmation-panel>
                </v-menu>
                <span>Delete</span>
            </v-tooltip> -->
            
            <v-tooltip bottom v-if="getCopy && copyObject.role" v-show="selectedArray.length <=1 || copyObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator" @click="createEvent(copyObject.selectType, 'onCopy')"
                class="iconFormat">far fa-copy</v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="copy_v2" slot="activator" @click="createEvent(copyObject.selectType, 'onCopy')" :original="true" 
                :title="copyObject.title||'Copy'"></svgicon>
                <span>Copy</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getDelete && deleteObject.role" v-show="selectedArray.length <=1 || deleteObject.selectType == 'multiple'">    
                <v-menu slot="activator" left v-model="popOver.delete" :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                     <template v-slot:activator="{ on }">
                        <!-- <v-icon v-on="on"  class="EDC-GridIcon">delete</v-icon> -->
                        <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="delete_v2" title="Delete"></svgicon>
                    </template>

                    <confirmation-panel heading="Confirm Action" label="Are you sure want to delete?"
                        lblSuccess="Delete" @onCancel="popOver.delete = false" @onSuccess="createEvent(deleteObject.selectType, 'onDelete');
                        popOver.delete = false"></confirmation-panel>
                </v-menu>
                <span>Delete</span>
            </v-tooltip>



            <v-tooltip bottom v-if="getPublishObject && publishObject.role"
                v-show="selectedArray.length <=1 || publishObject.selectType == 'multiple'">
                <!-- using onPublish as multiple in object list -->
                <!-- <v-icon class="iconFormat" slot="activator"
                    v-show="!publishObject.key || selectedArray[0][publishObject.key]"
                    @click="createEvent(publishObject.selectType, 'onPublish')">fas fa-upload</v-icon> -->
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_publish_v2" slot="activator" v-show="!publishObject.key || selectedArray[0][publishObject.key]"
                    @click="createEvent(publishObject.selectType, 'onPublish')" :original="true" title="Publish">
                        
                    </svgicon>

                <span>Publish</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getUnPublishObject && unPublishObject.role"
                v-show="selectedArray.length <=1 || unPublishObject.selectType == 'multiple'">
                <!-- using Un-Publish as multiple in object list -->
                <v-menu slot="activator" left v-model="popOver.unPublish" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator" class="iconFormat align-icon">
                        fa-times</v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="document_unpublish_v2" slot="activator" :original="true" title="Un-Publish"></svgicon>
                </template>
                    <confirmation-panel heading="UnPublish Object" label="Are you sure want to UnPublish?"
                        lblSuccess="Un-Publish" @onCancel="popOver.unPublish = false"
                        @onSuccess="createEvent(unPublishObject.selectType, 'onUnPublish');popOver.unPublish = false">
                    </confirmation-panel>
                </v-menu>
                <span>Un-Publish</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getCheck && checkObject.role"
                v-show="selectedArray.length <=1 || checkObject.selectType == 'multiple'">
                <!-- <v-icon slot="activator" @click="createEvent(checkObject.selectType, 'checkLink')" class="iconFormat">
                    fa-check</v-icon>
                <span>Use This Object</span> -->
                <svgicon class="svg-icon-grid svg-fill-grid" name="document_publish_v2" slot="activator" @click="createEvent(checkObject.selectType, 'checkLink')" :original="true" title="Publish">
                    
                </svgicon>

                <span>Publish</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getJobPlanObject && jobPlanObject.role"
                v-show="selectedArray.length <=1 || jobPlanObject.selectType == 'multiple'">
                <!-- using onPublish as multiple in object list -->
                <v-icon class="iconFormat" slot="activator"
                    v-show="!jobPlanObject.key || selectedArray[0][jobPlanObject.key]"
                    @click="createEvent(jobPlanObject.selectType, 'onCreateJobPlan')">fas fa-expand-arrows-alt</v-icon>
                <span>Create Job Plan</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getShowGraphObject && showGraphObject.role"
                v-show="selectedArray.length <=1 || showGraphObject.selectType == 'multiple'">
                <v-icon class=" iconFormat" slot="activator" @click="createEvent(showGraphObject.selectType, 'onShowGraph')" v-show="!showGraphObject.key || selectedArray[0][showGraphObject.key] == showGraphObject.cmpValue">
                    fa-chart-line</v-icon>
                <span>Show Graph Analysis</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getResyncObject && ResyncObject.role"
                v-show="selectedArray.length <=1 || ResyncObject.selectType == 'multiple'">
                <!-- <v-icon class=" iconFormat" slot="activator" @click="createEvent(ResyncObject.selectType, 'onResync')" v-show="!ResyncObject.key || selectedArray[0][ResyncObject.key] == ResyncObject.cmpValue">
                    sync</v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="archive_synchronize_v2" slot="activator" @click="createEvent(ResyncObject.selectType, 'onResync')" v-show="!ResyncObject.key || selectedArray[0][ResyncObject.key] == ResyncObject.cmpValue" :original="true" title="Sync Up"></svgicon>
                <span>Sync Up</span>
            </v-tooltip>
            
            <v-tooltip bottom v-if="getUnsyncObject && UnsyncObject.role"
                v-show="selectedArray.length <=1 || UnsyncObject.selectType == 'multiple'">
                <!-- <v-icon class=" iconFormat" slot="activator" @click="createEvent(UnsyncObject.selectType, 'onUnsync')" v-show="!UnsyncObject.key || selectedArray[0][UnsyncObject.key] == UnsyncObject.cmpValue">
                    sync_disabled</v-icon> -->

                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="archive_unsynchronize_v2" slot="activator" @click="createEvent(UnsyncObject.selectType, 'onUnsync')" v-show="!UnsyncObject.key || selectedArray[0][UnsyncObject.key] == UnsyncObject.cmpValue" :original="true" title="Unsync Up"></svgicon>
                <span>Unsync Up</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getCreateReportObject"
                v-show="selectedArray.length <=1 || CreateReportObject.selectType == 'multiple'">
                <!-- <v-icon class=" iconFormat" slot="activator"
                    @click="createEvent(CreateReportObject.selectType, 'onCreateJobReport')"
                    v-show="!CreateReportObject.key || selectedArray[0][CreateReportObject.key] == CreateReportObject.cmpValue">fas fa-chart-line
                </v-icon> -->
                <svgicon class="svg-icon-grid svg-fill-grid" name="Report_New" slot="activator"  @click="createEvent(CreateReportObject.selectType, 'onCreateJobReport')"
                    v-show="!CreateReportObject.key || selectedArray[0][CreateReportObject.key] == CreateReportObject.cmpValue" :original="true"></svgicon>

                <span>{{CreateReportObject.displayText || 'Create Report'}}</span>
            </v-tooltip>
            
            <v-tooltip bottom v-if="getReportDownload && reportDownloadObject.showPopup"
            v-show="selectedArray.length <=1 || reportDownloadObject.selectType == 'multiple'">
            <v-menu bottom absolute slot="activator" transition="scale-transition" :close-on-content-click="false" v-model="reportDownloadOption" style="display:inline">
                <!-- <v-icon class="iconFormat" slot="activator"
                show="!reportDownloadObject.key || record[reportDownloadObject.key]">
            fas  fa-file-download</v-icon> -->
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="report_download_v2" slot="activator" show="!reportDownloadObject.key || record[reportDownloadObject.key]" :original="true" title="Report Download"></svgicon>
            <v-card>
                <v-card-text style="width:340px">
                    <v-layout row wrap pl-3 pr-3>
                        <v-flex xs12>
                            <v-layout row>
                                <v-checkbox :color="colorCode" label="XLS"  v-model="reportDownloadParams.xls" class="customCheckbox">
                                </v-checkbox>
                                <v-checkbox :color="colorCode" label="CSV"  v-model="reportDownloadParams.csvfile" class="customCheckbox">
                                </v-checkbox>
                                <v-checkbox :color="colorCode" label="PDF"  v-model="reportDownloadParams.pdf" class="customCheckbox">
                                </v-checkbox>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <vc-button type="button" @click.native="reportDownloadOption = false" itemText="Cancel" style="float:left"></vc-button>
                            <vc-button v-if="getReportDownload && getReportDownload.buttonText" type="button" @click.native="createEvent(reportDownloadObject.selectType, 'onReportDownload')" itemText="Save" style="float:right"></vc-button>
                            <vc-button v-else type="button" @click.native="createEvent(reportDownloadObject.selectType, 'onReportDownload')" itemText="Download" style="float:right"></vc-button>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-menu>
        <span>Report download</span>
    </v-tooltip>

    <v-tooltip bottom v-if="getReportDownload && reportDownloadObject.directDownload"
            v-show="selectedArray.length <=1 || reportDownloadObject.selectType == 'multiple'">
                <!-- <v-icon class="iconFormat" slot="activator"
                 v-show="!reportDownloadObject.key || selectedArray[0][reportDownloadObject.key] == reportDownloadObject.cmpValue" @click.native="createEvent(reportDownloadObject.selectType, 'onReportDownload')">
            fas fa-download</v-icon> -->

            <svgicon class="svg-icon-grid svg-fill-grid" name="report_download_v2" slot="activator" 
            v-show="!reportDownloadObject.key || selectedArray[0][reportDownloadObject.key] == reportDownloadObject.cmpValue"
             @click.native="createEvent(reportDownloadObject.selectType, reportDownloadObject.emitEvent || 'onReportDownload')" 
             :original="true" :title="reportDownloadObject.title || 'Report Download'"></svgicon>
              <span>Report download</span>
    </v-tooltip>
    <v-tooltip bottom v-if="getEnquiry"
            v-show="selectedArray.length <=1 || enquiryObject.selectType == 'multiple'">
            <!-- <v-icon class="iconFormat" slot="activator" @click="createEvent(enquiryObject.selectType, 'onEnquiry')"
                v-show="!enquiryObject.key || selectedArray[0][enquiryObject.key] == enquiryObject.cmpValue || selectedArray[0][enquiryObject.key]!= ''">
                fab fa-searchengin</v-icon> -->

            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="search_v2" slot="activator" @click="createEvent(enquiryObject.selectType, 'onEnquiry')"
                v-show="!enquiryObject.key || selectedArray[0][enquiryObject.key] == enquiryObject.cmpValue || selectedArray[0][enquiryObject.key]!= ''" :original="true" :title="enquiryObject.displayText || 'Enquiry'"></svgicon>

            <span>Enquiry</span>
        </v-tooltip>
        <v-tooltip bottom v-if="getDetailedHistory"
        v-show="selectedArray.length <=1 || detailedHistory.selectType == 'multiple'">
        <!-- <v-icon slot="activator" @click="createEvent(detailedHistory.selectType, 'onDetailedHistory')"
            v-show="!detailedHistory.key || selectedArray[0][detailedHistory.key]" class="iconFormat" >fa-table
        </v-icon> -->

        <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="Database_Table" slot="activator" @click="createEvent(detailedHistory.selectType, 'onDetailedHistory')"
                v-show="!detailedHistory.key || selectedArray[0][detailedHistory.key] == detailedHistory.cmpValue || selectedArray[0][detailedHistory.key]!= ''" :original="true" title="Detail Enquiry"></svgicon>

        <span>More Details </span>
    </v-tooltip>
        <v-tooltip bottom v-if="getResendEmailObject"
            v-show="selectedArray.length <=1 || ResendEmailObject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator"
                @click="createEvent(ResendEmailObject.selectType, 'onResendEmail')"
                v-show="selectedArray[0][ResendEmailObject.key] === ResendEmailObject.cmpValue ">far fa-envelope-open</v-icon> -->
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="email_send_v2" slot="activator" @click="createEvent(ResendEmailObject.selectType, 'onResendEmail')"
                v-show="selectedArray[0][ResendEmailObject.key] === ResendEmailObject.cmpValue " :original="true" title="Re-Send Email"></svgicon>

            <span>Re-send Email</span>
        </v-tooltip>
        <v-tooltip bottom v-if="getResetPasswordObject"
            v-show="selectedArray.length <=1 || ResetPasswordObject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator"
                @click="createEvent(ResetPasswordObject.selectType, 'onResetPassword')"
                v-show="selectedArray[0][ResetPasswordObject.key] === ResetPasswordObject.cmpValue">fas fa-lock-open
            </v-icon> -->
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="user_password_reset_v2" slot="activator" @click="createEvent(ResetPasswordObject.selectType, 'onResetPassword')"
                v-show="selectedArray[0][ResetPasswordObject.key] === ResetPasswordObject.cmpValue" :original="true" title="Reset Password"></svgicon>
            <span>Reset Password</span>
        </v-tooltip>
        <v-tooltip bottom v-if="getUserHistoryObject"
            v-show="selectedArray.length <=1 || UserHistoryObject.selectType == 'multiple'">
            
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="job_details_v2" slot="activator" @click="createEvent(UserHistoryObject.selectType, 'onUserHistory')"
                v-show="!UserHistoryObject.key || selectedArray[0][UserHistoryObject.key]" :original="true" title="History"></svgicon>
            <span>History</span>
        </v-tooltip>
        <v-tooltip bottom style="top:5px" v-if="getUnlockAccountObject"
            v-show="selectedArray.length <=1 || UnlockAccountObject.selectType == 'multiple'">
            <v-menu slot="activator" left v-model="popOver.roleRevokeObj" :close-on-content-click="false"
                :nudge-width="200" offset-x>
                <!-- <v-icon slot="activator" class="iconFormat align-icon"
                    v-show="!UnlockAccountObject.key || selectedArray[0][UnlockAccountObject.key] == UnlockAccountObject.cmpValue">fas fa-user-check
                </v-icon> -->
                <template v-slot:activator="{ on }">
                <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="user_unlock_v2" slot="activator" v-show="!UnlockAccountObject.key || selectedArray[0][UnlockAccountObject.key] == UnlockAccountObject.cmpValue" :original="true" title="Unlock Account"></svgicon>
            </template>
                <confirmation-panel heading="Unlock user account" label="Are you sure want to unlock account?"
                    lblSuccess="Yes" @onCancel="popOver.roleRevokeObj = false"
                    @onSuccess="createEvent(UnlockAccountObject.selectType, 'onUnlockAccount');popOver.roleRevokeObj = false">
                </confirmation-panel>
            </v-menu>
            <span>Unlock account</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getAgentpingObject"
            v-show="selectedArray.length <=1 || AgentpingObject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator" @click="createEvent(UnsyncObject.selectType, 'onUnsync')" v-show="!UnsyncObject.key || selectedArray[0][UnsyncObject.key] == UnsyncObject.cmpValue">
                sync_disabled</v-icon> -->

            <svgicon class="svg-icon-grid svg-fill-grid" name="ping_v2" slot="activator" @click="createEvent(AgentpingObject.selectType, 'onAgentPing')" :original="true" :title="AgentpingObject.displayText || 'Ping Agent'"></svgicon>
            <span>{{ AgentpingObject.displayText || 'Ping Agent' }}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getCreatePackage"
            v-show="selectedArray.length <=1 || CreatePackageObject.selectType == 'multiple'">
                <svgicon class="svg-icon-grid svg-fill-grid" name="Document_Export" slot="activator" @click="createEvent(CreatePackageObject.selectType, 'onCreatePackage')"
                v-show="!CreatePackageObject.key || selectedArray[0][CreatePackageObject.key]" :original="true" title="Create Package"></svgicon>
            <span>Create Package</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getCreateVersionObject &&createVersionObject.role"
            v-show="selectedArray.length <=1 || createVersionObject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator"
                @click="createEvent(createVersionObject.selectType, 'onCreateVersion')"
                v-show="!createVersionObject.key || selectedArray[0][createVersionObject.key]"> fas fa-external-link-alt
            </v-icon> -->

            <svgicon class="svg-icon-grid svg-fill-grid" name="New" slot="activator" @click="createEvent(createVersionObject.selectType, 'onCreateVersion')"
                v-show="!createVersionObject.key || selectedArray[0][createVersionObject.key]" :original="true" :title="createVersionObject.displayText || 'Create New Version'"></svgicon>

            <span>Create New Version</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getUpArrowObject && upArrowObject.role"
            v-show="selectedArray.length <=1 || upArrowObject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator"
                @click="createEvent(upArrowObject.selectType, 'onUpArrow')"
                v-show="!upArrowObject.key || selectedArray[0][upArrowObject.key]" :title="upArrowObject.displayText || 'Move Up'"> arrow_upward
            </v-icon> -->
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="move_up" slot="activator"
                @click="createEvent(upArrowObject.selectType, 'onUpArrow')"
                v-show="!upArrowObject.key || selectedArray[0][upArrowObject.key]" :title="upArrowObject.displayText || 'Move Up'"></svgicon>

            <span>{{upArrowObject.displayText || 'Move Up'}}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="getDownArrowObject && downArrowOject.role"
            v-show="selectedArray.length <=1 || downArrowOject.selectType == 'multiple'">
            <!-- <v-icon class=" iconFormat" slot="activator"
                @click="createEvent(downArrowOject.selectType, 'onDownArrow')"
                v-show="!downArrowOject.key || selectedArray[0][downArrowOject.key]" :title="downArrowOject.displayText || 'Move Down'"> arrow_downward
            </v-icon> -->
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="move_down" slot="activator"
                @click="createEvent(downArrowOject.selectType, 'onDownArrow')"
                v-show="!downArrowOject.key || selectedArray[0][downArrowOject.key]" :title="downArrowOject.displayText || 'Move Down'"></svgicon>

            <span>{{ downArrowOject.displayText || 'Move Down'}}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getShowPDObject && showPDObject.role"
            v-show="selectedArray.length <=1 || showPDObject.selectType == 'multiple'">
            <v-menu   bottom absolute transition="scale-transition" slot="activator"  v-model="popOver.activate"  close-on-content-click close-on-click>
            <template v-slot:activator="{ on }">
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" slot="activator" name="document_report_view_v2" v-on="on"
                v-show="!showPDObject.key || selectedArray[0][showPDObject.key]" :title="showPDObject.displayText || 'Show Object In Readonly Mode'"></svgicon>
            </template>

            <v-list>
                 <v-list-item  dense v-for="(item, index) in newMenu"
                :key="index" @click="createEvent(showPDObject.selectType, item.event)" style="cursor:pointer">
                <v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-tooltip>

        <v-tooltip bottom v-if="getActivate && activateObject.role"
                v-show="selectedArray.length <=1 || activateObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.activate" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                   
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="item_activate" slot="activator" v-show="!activateObject.key || selectedArray.every((Currentvalue)=>Currentvalue[activateObject.key]=== activateObject.cmpValue )" :original="true" title="Activate"></svgicon>
                </template>
                    <confirmation-panel heading="Deactive Job" label="Are you sure want to Activate?"
                        lblSuccess="Activate" @onCancel="popOver.activate = false"
                        @onSuccess="createEvent(activateObject.selectType, 'onActivate');popOver.activate = false">
                    </confirmation-panel>
                </v-menu>
                <span>Activate</span>
            </v-tooltip>

         <v-tooltip bottom v-if="getDeactivate && deactivateObject.role"
                v-show="selectedArray.length <=1 || deactivateObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.deactivate" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="item_deactivate" slot="activator" v-show="!deactivateObject.key ||selectedArray.every((Currentvalue)=>Currentvalue[deactivateObject.key]=== deactivateObject.cmpValue )" :original="true" title="Deactivate"></svgicon>
                </template>
                    <confirmation-panel heading="Deactive Job" label="Are you sure want to Deactivate?"
                        lblSuccess="Deactivate" @onCancel="popOver.deactivate = false"
                        @onSuccess="createEvent(deactivateObject.selectType, 'onDeactivate');popOver.deactivate = false">
                    </confirmation-panel>
                </v-menu>
                <span>Deactivate</span>
            </v-tooltip>
            
             <v-tooltip bottom v-if="getJobPlanSuspend && jobPlanSuspendObject.role"
                v-show="selectedArray.length <=1 || jobPlanSuspendObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.jobplanpsuspend" :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!suspendObject.key || selectedArray[0][suspendObject.key] == suspendObject.cmpValue"
                        class="iconFormat align-icon">fas fa-ban</v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_suspend_v2" v-show="!jobPlanSuspendObject.key || selectedArray[0][jobPlanSuspendObject.key] == 'Y'" :original="true" title="Suspend"></svgicon>
                </template>
                    <suspendconfirmation-panel heading="Suspend Job Plan" label="Select suspend type from below options"
                        lblSuccess="Suspend" @onCancel="popOver.jobplanpsuspend = false"
                        @onSuccess="createEvent(jobPlanSuspendObject.selectType, 'onSuspend',$event);popOver.jobplanpsuspend = false">
                    </suspendconfirmation-panel>
                </v-menu>
                <span>JSuspend Job Plan</span>
            </v-tooltip>
            <v-tooltip bottom v-if="getJobPlanTerminate && jobPlanTerminateObject.role"
                v-show="selectedArray.length <=1 || jobPlanTerminateObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.jobplanterminate" :close-on-content-click="false"
                    :nudge-width="200" offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!terminateObject.key || selectedArray[0][terminateObject.key] !== ''"
                        class="iconFormat align-icon">far fa-times-circle</v-icon> -->
                    <template v-slot:activator="{ on }">
                    <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="job_terminate_v2" slot="activator" v-show="selectedArray[0][jobPlanTerminateObject.key] == 'Y'" :original="true" title="Terminate"></svgicon>
                </template>
                   <suspendconfirmation-panel heading="Terminate Job Plan" label="Select terminate type from below options?"
                        lblSuccess="Terminate" @onCancel="popOver.jobplanterminate = false"
                        @onSuccess="createEvent(jobPlanTerminateObject.selectType, 'onTerminate',$event);popOver.jobplanterminate = false">
                    </suspendconfirmation-panel>
                </v-menu>
                <span>Terminate Job Plan</span>
            </v-tooltip>


             <v-tooltip bottom v-if="getEditJobPlanReleaseObject && editJobPlanReleaseObject.role"
                v-show="selectedArray.length <=1 || editJobPlanReleaseObject.selectType == 'multiple'">
                <v-menu slot="activator" left v-model="popOver.editjobplanprelease" :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <!-- <v-icon slot="activator"
                        v-show="!suspendObject.key || selectedArray[0][suspendObject.key] == suspendObject.cmpValue"
                        class="iconFormat align-icon">fas fa-ban</v-icon> -->
                    <template v-slot:activator="{ on }">
                     <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_edit_v2" slot="activator" v-show="!editJobPlanReleaseObject.key || selectedArray[0][editJobPlanReleaseObject.key] == editJobPlanReleaseObject.cmpValue"
                    @click="createEvent(editJobPlanReleaseObject.selectType, 'onEditCommit');" :original="true" title="Edit Limit Size"></svgicon>
                </template>
                </v-menu>
                <span>Edit Job Plan Release Size</span>
            </v-tooltip>

            <v-tooltip bottom v-if="getDependencyAddObject && dependencyAddObject.role"
            v-show="selectedArray.length <=1 || dependencyAddObject.selectType == 'multiple'">
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="dependency_add" slot="activator"
                @click="createEvent(dependencyAddObject.selectType, 'onDependencyAdd')"
                v-show="!dependencyAddObject.key || selectedArray[0][dependencyAddObject.key]" :title="dependencyAddObject.displayText || 'Add Dependency'"></svgicon>

            <span>{{dependencyAddObject.displayText || 'Add Dependency'}}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="getDependencyRemoveObject && dependencyRemoveObject.role"
            v-show="selectedArray.length <=1 || dependencyRemoveObject.selectType == 'multiple'">
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="dependency_remove" slot="activator"
                @click="createEvent(dependencyRemoveObject.selectType, 'onDependencyRemove')"
                v-show="!dependencyRemoveObject.key || selectedArray[0][dependencyRemoveObject.key]" :title="dependencyRemoveObject.displayText || 'Remove Dependency'"></svgicon>

            <span>{{ dependencyRemoveObject.displayText || 'Remove Dependency'}}</span>
        </v-tooltip>


        <v-tooltip bottom v-if="getViewInDataviewerObject && getViewInDataviewerObject.role"
            v-show="selectedArray.length <=1 || viewInDataviewerObject.selectType == 'multiple'">
            <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="document_report_view_v2" slot="activator"
                @click="createEvent(viewInDataviewerObject.selectType, 'redirectToDataviewer')"
                v-show="!viewInDataviewerObject.key || selectedArray[0][viewInDataviewerObject.key]" :title="viewInDataviewerObject.displayText || 'View In Datataviewer'"></svgicon>

            <span>{{ viewInDataviewerObject.displayText || 'View In Datataviewer'}}</span>
        </v-tooltip>

        </div>
        <!-- <div v-if="!data.hideDownload"> -->
            <v-tooltip v-if="showDownload">
                <v-menu elevation-1  left absolute slot="activator" transition="scale-transition" :close-on-content-click="false" v-model="downloadOption" style="display:inline">
                    <template v-slot:activator="{ on }">
                        <v-icon class="EDC-GridIcon" v-on="on" title="Export Data" id="export">exit_to_app</v-icon>
                    </template>
                    <span>Download</span>
                    <v-card>
                        <v-card-text style="width:340px">
                            <v-layout row wrap pl-3 pr-3>
                                <v-flex xs12>
                                   <v-radio-group v-model="downloadParams.recordType" row style="margin-top:0px" @change="resetRange(downloadParams.recordType)">
                                    <v-radio class="radioClass" :color="colorCode" label="All" value="all"></v-radio>
                                    <v-radio class="radioClass" :color="colorCode" :disabled="!selectedArray.length" label="Selected_rows" value="selected_rows" ></v-radio>
                                    <v-radio class="radioClass" :color="colorCode" label="Range" value="range" ></v-radio>
                                </v-radio-group>
                            </v-flex>
                            <v-flex xs6 v-if="downloadParams.recordType == 'range'">
                                <v-text-field type="number" v-model="downloadParams.minRange" style="width:95%;padding-top:0px;margin-top:0px" label="Min Row Range"></v-text-field>
                            </v-flex>
                            <v-flex xs6 v-if="downloadParams.recordType == 'range'">
                                <v-text-field type="number" v-model="downloadParams.maxRange" style="width:95%;float:right;padding-top:0px;margin-top:0px" label="Max Row Range"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-radio-group v-model="downloadParams.fileType" row style="margin-top:0px">
                                    <v-radio class="radioClass" :color="colorCode" label="CSV" value="csv"></v-radio>
                                    <v-radio class="radioClass" :color="colorCode" label="XLS" value="xls" ></v-radio>
                                    <v-radio class="radioClass" :color="colorCode" label="PDF" value="pdf" ></v-radio>
                                </v-radio-group>
                            </v-flex>
                            <v-flex xs12>
                                <vc-button type="button" @click.native="downloadOption = false" itemText="Cancel" style="float:left"></vc-button>
                                <vc-button type="button" @click.native="onDownload" itemText="Download" style="float:right"></vc-button>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-tooltip>
    <!-- </div> -->
    </div>
</template>
<script>
import{CLIENT_SIDE, PER_PAGE, PER_PAGE_ARRAY} from '../../data/macros.js';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
import Confirmation from "./Confarmation";
import SuspendConfirm from "./SuspendConfirm";
import LoadPanel from "../loading"
import {COLOR_CODE} from '@/data/macros.js'
import Calender from '../../views/calender'
import dateFormat from '@/methods/DateFormat.js'
import vcButton from '@/views/button.vue'
import '../../compiled-icons';

export default {
    name: 'dataTable',
    data: function () {        
            return {
            downloadOption:false,
            downloadParams:{
                recordType:"all",
                minRange:"",
                maxRange:"",
                fileType:"csv"
            },
            popOver:{
                revokeObj:false,
                createJobReportObj:false,
                roleRevokeObj:false,
                unPublish:false,
                delete:false,
                resume:false,
                pause:false,
                terminate:false,
                cancelRequestObject:false,
                start:false,
                execute:false,
                reexcute:false,
                jobplanpsuspend:false
            },
            items: [
                { title: 'Suspend Immediate                 ' },
                { title: 'Suspend After Running Job Finished' }],
            newMenu:[
            {title:'Show Object In Graph View', event:'ShowObjectGraph'},
            {title:'Show Object In Table View', event:'showObjectTable'}
            ],  
            activateObject:{},    
            createObject:{},
            checkObject:{},
            deleteObject:{},
            deactivateObject:{},
            copyObject:{},
            onStatusObject:{},
            newWidOption:{},
            selectiverestoreObject:{},
            suspendObject:{},
            jobPlanSuspendObject:{},
            editJobPlanReleaseObject:{},
            jobPlanTerminateObject: {},
            pauseObject:{},
            resumeObject:{},
            terminateObject:{},
            editKeyObject:{},
            editObject:{},
            executeObject:{},
            createObject:{},
            publishObject:{},
            scheduleObject:{},
            revokeObject:{},
            createVersionObject:{},
            historyObject:{},
            startObject:{},
            unPublishObject:{},
            saveObject:{},
            cancelObject:{},
            detailedHistory:{},
            acceptObject:{},
            restoreObject:{},
            publishButtonObject:{},
            selectivedisposeObject:{},
            disposeObject:{},
            enquiryObject:{},
            deleteCopyObject:{},
            createExecutableObject:{},
            editCommitsObject:{},
            reExecuteObject:{},
            jobPlanObject:{},
            showGraphObject:{},
            UserHistoryObject: {},
            ResetPasswordObject: {},
            ResyncObject:{},
            UnsyncObject:{},
            CreateReportObject: {},
            AgentpingObject:{},
            CreatePackageObject:{},
            addMultipleObject:{},
            upArrowObject:{},
            downArrowOject:{},
            showPDObject:{},
            dependencyAddObject:{},
            dependencyRemoveObject:{},
            viewInDataviewerObject:{},
            colorCode:COLOR_CODE,
            reportDownloadParams:{
            recordType:"all",
            minRange:"",
            maxRange:"",
            fileType:"csv",
            xls:true,
            csvfile:false,
            pdf:false,
            },
            reportDownloadOption:false,
            }},
        props: {
            data:{
                tyep:Object,
                default:function(){
                    return {}
                },
            },
            selectedArray:{
                type:Array,
                default: function () {
                    return []
                },
            },
            showDownload:{
                type:Boolean,
            }
        },
        components:{
        'confirmation-panel':Confirmation,
        'suspendconfirmation-panel': SuspendConfirm,
        'calender':Calender,
        'vc-button':vcButton,
    },
    computed:{
        getNewObjWidOpt: function(){
            this.newWidOption = _.find(this.data.actions, ['text', 'newWidOptions']);
            return this.newWidOption
        },
        getNewObject: function(){
            this.createObject = _.find(this.data.actions, ['text', 'new']);
            return this.createObject;
        },
        getPublishObject: function(){
           this.publishObject = _.find(this.data.actions, ['text', 'publish']);
           return this.publishObject;
        },
        getScheduleObject:function(){
           this.scheduleObject = _.find(this.data.actions, ['text', 'schedule']);
           return this.scheduleObject;
        },
        getUnPublishObject: function(){
            this.unPublishObject =  _.find(this.data.actions, ['text', 'unPublish']);
            return this.unPublishObject;
        },
        getExecuteObject(){
           this.executeObject =  _.find(this.data.actions, ['text', 'execute']);
           return this.executeObject;
        },
        getCreateExeObject(){
           this.createExecutableObject =  _.find(this.data.actions, ['text', 'create']);
           return this.createExecutableObject;
        },

        getAddMultipleObject(){
           this.addMultipleObject =  _.find(this.data.actions, ['text', 'addmultiple']);
           return this.addMultipleObject;
        },

        getEditCommitsObject(){
           this.editCommitsObject =  _.find(this.data.actions, ['text', 'editcommit']);
           return this.editCommitsObject;
        },
        getStartObject(){
            this.startObject =  _.find(this.data.actions, ['text', 'start']);
            return this.startObject;
        },
        getEditKeyObject(){
           this.editKeyObject =  _.find(this.data.actions, ['text', 'editKey']);
           return this.editKeyObject;
        },
        getCreateVersionObject(){
           this.createVersionObject =  _.find(this.data.actions, ['text', 'createVersion']);
           return this.createVersionObject;
        },
        getDeactivate(){
            this.deactivateObject = _.find(this.data.actions, ['text', 'deactivate']);
            return this.deactivateObject;
        },
        getActivate(){
            this.activateObject = _.find(this.data.actions, ['text', 'activate']);
            return this.activateObject;
        },
        getResendEmailObject(){
            this.ResendEmailObject =  _.find(this.data.actions, ['text', 'onResendEmail']);
            return this.ResendEmailObject;
        },
        getResetPasswordObject(){
            this.ResetPasswordObject =  _.find(this.data.actions, ['text', 'onResetPassword']);
            return this.ResetPasswordObject;
        },
        getUserHistoryObject(){
            this.UserHistoryObject =  _.find(this.data.actions, ['text', 'onUserHistory']);
            return this.UserHistoryObject;
        },
        getCreateReportObject(){
            this.CreateReportObject =  _.find(this.data.actions, ['text', 'onCreateJobReport']);
            return this.CreateReportObject;
        },
        getEditObject(){
            // debugger;
           this.editObject =  _.find(this.data.actions, ['text', 'edit']);
           return this.editObject;
        },
        getPublishButton(){
            this.publishButtonObject =  _.find(this.data.actions, ['text', 'publishBtn']);
            return this.publishButtonObject;
        },
        getSaveObject:function(){
            this.saveObject = _.find(this.data.actions, ['text', 'save']);
            return this.saveObject;
        },
        getCancelObject(){
            this.cancelObject = _.find(this.data.actions, ['text', 'cancel']);
            return this.cancelObject;
        },
        getSuspend(){
            this.suspendObject =  _.find(this.data.actions, ['text', 'suspend']);
            return this.suspendObject;
        },
        getResume(){
            this.resumeObject = _.find(this.data.actions, ['text', 'resume']);
            return this.resumeObject;
        },
        getTerminate(){
            this.terminateObject =  _.find(this.data.actions, ['text', 'terminate']);
            return this.terminateObject;
        },
        getJobPlanTerminate(){
            this.jobPlanTerminateObject =  _.find(this.data.actions, ['text', 'jobplanterminate']);
            return this.jobPlanTerminateObject;
        },
        getJobPlanSuspend(){
            this.jobPlanSuspendObject =  _.find(this.data.actions, ['text', 'jobplansuspend']);
            return this.jobPlanSuspendObject;
        },
        getEditJobPlanReleaseObject(){
           this.editJobPlanReleaseObject =  _.find(this.data.actions, ['text', 'jobplanrelease']);
           return this.editJobPlanReleaseObject;
        },
        getReExecute(){
            this.reExecuteObject =  _.find(this.data.actions, ['text', 're_execute']);
            return this.reExecuteObject;
        },
        getHistory(){
            this.historyObject =  _.find(this.data.actions, ['text', 'history']);
            return this.historyObject;
        },
        getRequestHistory(){
            this.RequestHistoryObject =  _.find(this.data.actions, ['text', 'request_history']);
            return this.RequestHistoryObject;
        },
        getDetailedHistory(){
            this.detailedHistory =  _.find(this.data.actions, ['text', 'detailedHistory']);
            return this.detailedHistory;
        },
        getCopy(){
            this.copyObject = _.find(this.data.actions, ['text', 'copy']);
            return this.copyObject;
        },
        getDelete(){
            this.deleteObject = _.find(this.data.actions, ['text', 'delete']);
            return this.deleteObject;
        },
        getCheck(){
             this.checkObject = _.find(this.data.actions, ['text', 'check']);
             return this.checkObject;
        },
        getAccept(){
             this.acceptObject =  _.find(this.data.actions, ['text', 'accept']);
             return this.acceptObject;
        },
        getStatus(){
            this.onStatusObject = _.find(this.data.actions, ['text', 'status']);
            return this.onStatusObject;
        },
        getRevoke(){
            this.revokeObject = _.find(this.data.actions, ['text', 'revoke']);
            return this.revokeObject;
        },
        getRevokeRequest(){
            this.revokeRequestObject = _.find(this.data.actions, ['text', 'revoke_request']);
            return this.revokeRequestObject;
        },
        getRoleRevoke(){
            this.roleRevokeObject = _.find(this.data.actions, ['text', 'rolerevoke']);
            return this.roleRevokeObject;
        },
        getCancelRequest(){
            this.cancelRequestObject = _.find(this.data.actions, ['text', 'cancel_request']);
            return this.cancelRequestObject;
        },
        
        getRestore(){
            this.restoreObject = _.find(this.data.actions, ['text', 'restore']);
            return this.restoreObject;
        },
        getSelectiveRestore(){
            this.selectiverestoreObject = _.find(this.data.actions, ['text', 'selectiverestore']);
            return this.selectiverestoreObject;
        },
        getDispose(){
            this.disposeObject = _.find(this.data.actions, ['text', 'dispose']);
            return this.disposeObject;
        },
        getSelectiveDispose(){
            this.selectivedisposeObject = _.find(this.data.actions, ['text', 'selectivedispose']);
            return this.selectivedisposeObject;
        },
        getReportDownload(){
            this.reportDownloadObject = _.find(this.data.actions, ['text', 'onReportDownload']);
            return this.reportDownloadObject;
        },
        getEnquiry(){
            this.enquiryObject = _.find(this.data.actions, ['text', 'enquiry']);
            return this.enquiryObject;
        },
        getDeleteCopy(){
            this.deleteCopyObject = _.find(this.data.actions, ['text', 'deletecopy']);
            return this.deleteCopyObject;
        },
        getJobPlanObject: function(){
           this.jobPlanObject = _.find(this.data.actions, ['text', 'jobplan']);
           return this.jobPlanObject;
        },
        getShowGraphObject(){
           this.showGraphObject =  _.find(this.data.actions, ['text', 'showgraph']);
           return this.showGraphObject;
        },
        getResyncObject(){
           this.ResyncObject = _.find(this.data.actions, ['text', 're-sync']);
           return this.ResyncObject;
        },
        getUnsyncObject(){
            this.UnsyncObject = _.find(this.data.actions, ['text', 'un-sync']);
           return this.UnsyncObject;
        },
        getUnlockAccountObject(){
            this.UnlockAccountObject =  _.find(this.data.actions, ['text', 'onUnlockAccount']);
            return this.UnlockAccountObject;
        },
        getAgentpingObject(){
            this.AgentpingObject =  _.find(this.data.actions, ['text', 'PingAgent']);
            return this.AgentpingObject;
        },
        getCreatePackage(){
            this.CreatePackageObject =  _.find(this.data.actions, ['text', 'CreatePackage']);
            return this.CreatePackageObject;
        },
        getUpArrowObject(){
            this.upArrowObject = _.find(this.data.actions,['text','up'])
            return this.upArrowObject
        },
        getDownArrowObject(){
            this.downArrowOject = _.find(this.data.actions,['text','down'])
            return this.downArrowOject
        },
        getDependencyAddObject(){
            this.dependencyAddObject = _.find(this.data.actions,['text','depadd'])
            return this.dependencyAddObject
        },
        getDependencyRemoveObject(){
            this.dependencyRemoveObject = _.find(this.data.actions,['text','depremove'])
            return this.dependencyRemoveObject
        },
        getShowPDObject(){
            this.showPDObject = _.find(this.data.actions,['text','showpd'])
            return this.showPDObject
        },
        getViewInDataviewerObject(){
            this.viewInDataviewerObject = _.find(this.data.actions,['text','viewindv'])
            return this.viewInDataviewerObject || {}
        }
    },
    watch:{
        selectedArray:{
            handler(newValue,oldvalue){
                this.getSaveObject;
                this.getCancelObject;
                // if(newValue.length === 0)
                //     this.showInFirst = true
            },
            deep:true
        },
        showInFirst:{
            handler(newValue,oldValue){

            }
        },
        showDownload:{
            handler(newValue){
            }
        }
    },
    mounted(){
        
    },
    methods:{
         createEvent(recordType,eventName, defaultVal){
            let record ={};
            if(eventName === 'onReportDownload'){
                defaultVal = this.reportDownloadParams
                this.$emit('createEvent',recordType,eventName, defaultVal);
                this.reportDownloadOption = false
                return
            }
            this.$emit('createEvent',recordType,eventName, defaultVal);
            
        },
        onCancelEditing(record){
            this.$emit('onCancelEditing', record);
        },
        onDownload(){
            this.$emit('onDownload', this.downloadParams);
            this.downloadOption = false
        },
        resetRange(recordType){
            if(recordType !=='range'){
              this.downloadParams.minRange = '';
              this.downloadParams.maxRange = '';
            }
        }
    },
 }       

</script>
<style scoped>
.iconFormat{
    font-size: 21px;
}
.customCheckbox >>> label{
  top: 5px !important;
}
.radioClass >>> label{
    top: 5px !important;
}

.v-btn--fab.v-size--x-small{
    height:24px !important;
    width:24px !important;
    margin:2px;
}

  /*.svg-fill{
    fill:red;
}*/
</style>