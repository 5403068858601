<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <edc-data-grid key="grid-A" :removedRow="removedRow" :dataList="tableList" @onHistory="onHistory" 
                @onTerminate="onTerminate" @onResume="onResume" 
                @onPause="onPause" @onCellEvent="onCellEvent" @onReExecute="onReExecute" 
                @onEditCommit="onEditCommit" @onShowGraph="onShowGraph"
                @onDownload="onDownload" @onCreateJobReport="onCreateJobReport" @ondblClick="onHistory"
                @onShowPD="onShowPD" @showObjectTable="onShowPD"  
                @ShowObjectGraph="onGraphView" @linkCellClicked="linkCellClicked" @onViewPlan="moveToJobPlan">
                </edc-data-grid>
            </v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-dialog v-model="editcommit_dialog" width="unset" eager persistent>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                            <v-card class="rounded-card">
                                <v-toolbar dark dense>
                                <v-col class="EDC-Col text-md-center">Change Limit Size
                                    <v-icon class="text-lg-left" @click="editcommit_dialog = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                                </v-col>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row no-gutters class="EDC-Row">
                                        <v-col class="EDC-Col">
                                            <edc-data-grid key="editlimit" :dataList="limitTableList" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-dialog>
                <v-dialog v-model="viewpd_dialog" width="unset" height='600' eager persistent>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                            <v-card class="rounded-card">
                                <v-toolbar dark dense max-height="40">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col text-md-center">PD Viewer
                                            <v-icon class="text-lg-left" @click="viewpd_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row no-gutters class="EDC-Row">
                                        <v-col class="EDC-Col">    
                                            <edc-pdviewer :objectdetails="objectdetails" key="pdviewer">
                                                
                                            </edc-pdviewer>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-dialog>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
</template>
<style scoped>

</style>
<script>
    import moment from 'moment'
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import {jobTypeHeader, jobModeHeader, jobEXEType, jobStatusActiveJob} from "@/data/job_management.js"
    import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "./../../../data/macros.js"
    import { GETTODAYSGJOB, TERMINATEJOB,SUSPENDJOB,SAVE_NEW_COMMIT_SIZE, RESUME_COORDINATION,EXPORT_TODAYS_JOB} from '../../../data/url_constants.js';
    import config from './../../../config.json'
    import { post as postToServer } from './../../../methods/serverCall.js';
    import getUserRole from '../../../methods/GetUserRole.js'

    export default{
        name:'ActiveJobs',
        mixins:[mixinJobManagement],
        props:{
            changedEnvironment:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                removedRow:{},
                tableList:{
                    headers:[],
                    actions:[],
                    rows:[]
                },
                ActiveJobheaders: [
                    { text: 'Job id', value: 'id', align:'right', title: 'Job Id', dataType:'int'},
                    { text: 'Process Doc Name', value: 'process_definition_name', title:'Process Doc Name' },
                    _.cloneDeep(jobTypeHeader),
                    _.cloneDeep(jobStatusActiveJob),
                    { text: 'Plan Name', value: 'plan_full_name', options:[], title: 'Job Plan Name', isLinkCell:true,
                    "linkEvaluationObj":{
                    "cmpheader":"plan_name",
                    "isRequiredValue":true
                    }},
                    { text: 'Step', value: 'running_step_count', width: '140px', options:[], title: 'Job Step Count', hideInlineFilter:true},
                    { text: '(%)', value: 'running_step_percentage', options:[],align:'right',title:'Running Step Percentage',useProgress:true, progressSize:100, hideInlineFilter:true},
                    // { text: 'Remaining Time (Sec)', value: 'remaining_time', title: 'Remaining Time In Seconds',align:'right' },
                    _.cloneDeep(jobModeHeader),
                    _.cloneDeep(jobEXEType),
                    { text: 'Policy Date', value: 'actual_date', options:[], title: 'Job Policy Date', dataType:'date'},
                    { text: 'Start date', value: 'start_date', dataType:'date', options:[], title: 'Job Start Date' },
                    { text: 'Start time', value: 'start_time', dataType:'time', options:[], title: 'Job Start Time', hideInlineFilter:true},
                    { text: 'Dup Found', value: 'is_execute_duplicate', options:[], title: 'Duplicate Found &/or Skipped'},
                    { text: 'Initiated By', value: "created_by", cssClass:'w16', options:[], title: 'Job Execution Initiated By'},
                    { text: 'Report', value: 'report_step_available', options:[], title: 'Report Step Available'},
                    { text: 'Object ID', value: 'process_definition_id', width: '200px', options:[], title: 'Object ID', align:'right'},
                ],
                limitTableList:{
                    headers:[],
                    actions:[],
                    rows:[]
                },
                limitHeaders:[
                    { text: 'Job id', value: 'job_id', width:"25%", dataType:'int'},
                    { text: 'Step Name', value: 'step_name', width:"25%" },
                    { text: 'Step Type', value: 'step_type', width:"25%"},
                    { text: 'Limit', value: 'commit_size',width:"25%", isNumber:true, useTextField:true, inputType:'number', dataType:'int'},
                ],
                pageType:'currentJobList',
                editcommit_dialog:false,
                viewpd_dialog:false,
            }
        },
        computed: {
            
        },
        watch:{
            '$store.state.liveStatus':{
                handler(newValue){
                    this.getRealTimeStatus(newValue)
                }
            },
            'changedEnvironment':{
                handler(newValue){
                    this.Environment = _.cloneDeep(newValue)
                    this.getAllTodaysJob()
                }
            },
            'tableList.rows':{
                handler(newValue){
                    this.$emit("onActiveJobCountUpdated",this.tableList.rows.length)
                },
                deep:true
            }
        },
        mounted(){
            this.tableList = this.getGridObj(this.ActiveJobheaders,'id',true,{"updatinglivestatus":true})
            this.limitTableList = this.getGridObj(this.limitHeaders, 'step_name',true,{perPage:5,showExport:false,showInlineFilter:false,is_grid_inline_edit:true})
            this.tableList.actions = [
                {'text':'history','key':"id", selectType:"single",index:1},
                {'text':'suspend','key':"perform", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),cmpValue:'suspend',index:2,
                options:[
                    {"value": "immediate", "text":'Immediate'} ,
                    {"value":"suspend_after_commit", "text": 'After Current Transaction Finished'}
                ],'optionKey':'mode_of_execution','optionKeyValue':'Final'},
                {'text':'resume','key':"perform", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:3,
                    'cmpValue':'resume',  
                },
                {'text':'publish','key':"job_plan_id", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),
                index:3,'actionTitle':"Go To Job Plan",'eventName':"onViewPlan"},
                {'text':'terminate','key':"perform", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:4},
                {'text':'editcommit','key':"allow_edit_commit_size", selectType:"single",cmpValue:'Y', role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:6},
                {'text': 'showpd', 'key': 'is_restored', cmpValue:'Normal', selectType:"single", index:9, role:true}
            ]
            this.limitTableList.actions =[
                {'text':'edit', 'key':"job_id", selectType:"single", role:true, index:1},
                {'text':"save", "key":"isSave", selectType:"single", 'key':"is_row_editable", role:true, index:2},
                {'text':"cancel", "key":"isCancel", selectType:"single", 'key':"is_row_editable", role:true, index:3}
            ]
            this.Environment = _.cloneDeep(this.changedEnvironment)
            this.getAllTodaysJob()
        },
        methods: {
            getAllTodaysJob () {
                let _this = this
                if (!this.isEnvironmentSelected)
                    return false
                this.$session.set('selected_env', this.Environment)
                this.tableList.rows = []
                this.loader = true;
                postToServer(this, config.ENGINE_API_URL + GETTODAYSGJOB, this.commonRequestData).then(response => {
                    if(response){
                        let revisedData = _this.getRevisedDataList(response)
                        _.forEach(revisedData, (job,index)=>{
                            job.id=job.job_id
                            this.changeActiveJobProperties(job)
                            this.changeCommonJobProperties(job,index)
                        })
                        this.tableList.rows = revisedData
                        
                    }
                    this.loader = false
                }).catch(CurrentJobError => {
                    this.showMessage(CurrentJobError)
                })
            },
            getRevisedDataList(data){
                let finishedJobList = ['completed','terminated']
                let filteredJobs = _.filter(data,(obj)=>{
                    return finishedJobList.indexOf(obj.status.toLowerCase()) === -1
                })
                return filteredJobs
            },
            changeActiveJobProperties(job){
                job.updatinglivestatus = true
                job.showreexcute = 'Y'
                if(job.is_restored || job.job_type === "sync_up")
                    job.showreexcute ='N'
                
                this.manageJobState(job.status, job)

                job.allow_edit_commit_size = 'Y'
                if(job.mode_of_execution && job.mode_of_execution.toLowerCase() ==='proof'){
                    job.allow_edit_commit_size = 'N'
                }
            },
            manageJobState(jobState, job){
                if(job.job_plan_id){
                    job.perform = ''
                    return
                }
                switch(jobState){
                    case 'suspended':
                        job.perform = 'resume';
                        job.status="suspended";
                        break;
                    case 'error':
                        job.perform = 'resume';
                        job.status="error";
                        break;
                    case 'running':
                        job.perform = 'suspend';
                        job.status="running";
                        break;
                    case 'initializing':
                        job.perform = 'suspend';
                        job.status="initializing";
                        job.showreexcute ='N';
                        break;
                    case 'validating':
                        job.perform = 'suspend';
                        job.status="validating";
                        job.showreexcute ='N';
                        break;
                    case 'preparing':
                        job.perform = 'suspend';
                        job.status="preparing";
                        job.showreexcute ='N';
                        break;
                    case 'suspending':
                        job.perform = '';
                        job.status="suspending";
                        job.showreexcute ='N';
                        break;
                    case 'terminating':
                        job.perform = '';
                        job.status="terminating";
                        job.showreexcute ='N';
                        break;
                    default:
                        job.perform = '';
                }
            },
            onDownload(downloadParams, filterArray){
                let _this = this; 
                let filter=[];
                filterArray.map((obj, index)=>{
                    obj.stringArray.map((stringObj, stringIndex)=>{
                        let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                            filter.push(cloneDeep(temp));
                    })
                });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option": downloadParams.recordType,
                    "client_id": this.$session.get('client_id'),
                    "env_id": this.Environment.id,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter
                }
                var url = config.ENGINE_API_URL+EXPORT_TODAYS_JOB;   
                _this.loader=true;
                postToServer(_this, url, inputJson).then(Response => {
                    var url = config.ENGINE_API_URL + "/static/" + Response;
                    window.open(url, '_blank');
                    _this.loader=false;
                    
                }).catch(objError => {
                    this.showMessage(objError)
                })
            },
            onTerminate(record){
                this.perform("terminate", record);
            },
            onResume(record){
                this.perform("resume", record);
            },
            onPause(record, extraVal){
                this.perform("suspend", record, extraVal);
            },
            perform(option, record, extraVal){
                var job_id = record.job_id
                var job_exe_auto_id = record.job_exe_auto_id
                switch(option){
                    case 'suspend':
                        record.perform = 'resume';
                        record.status="suspending"
                        this.SuspendJob(job_id,job_exe_auto_id,record, extraVal)
                        break;
                    case 'resume':
                        record.perform = 'suspend';
                        record.status="resuming";
                        this.ResumeJob(job_id,record)
                        break;
                    case 'terminate':
                        record.perform = null;
                        record.status="terminating"
                        this.TerminateJob(job_id,job_exe_auto_id)
                        break;
                    default:
                        record.perform = null
                }
            },
            getRealTimeStatus(update_data){
                var job_id = update_data.job_id
                var obj = _.find(this.tableList.rows, ["job_id",job_id])
                if(!obj){
                    //send call to api
                    return this.getSingleJobDetails(job_id)
                }
                obj.updatinglivestatus = true
                if(update_data.running_step_count !='None (None)')
                obj.running_step_count = update_data.running_step_count
                obj.running_step_percentage = update_data.running_step_percentage
                obj.status = update_data.status
                //this start_datetime_without_timezone,end_datetime_without_timezone variable added in pubsub we dont know any timezone about user.So conversion need to add UI side
                this.manageJobState(obj.status, obj)
                if(!obj.start_time){ 
                    if(update_data.start_datetime_without_timezone){
                        var date_without_miliseconds = update_data.start_datetime_without_timezone.split('.')[0]
                        var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")
                        var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
                        obj.start_time = timezoned_start_time
                    }
                    else 
                        obj.start_time = update_data.start_time
                }
                obj.details = update_data.details
                obj.process_definition_version = update_data.process_definition_version
                obj.allow_edit_commit_size = 'Y'
                if(obj.mode_of_execution === 'Proof'){
                    obj.allow_edit_commit_size = 'N'
                }
        
                if(obj.status)
                    obj.status_to_show = obj.status.charAt(0).toUpperCase() + obj.status.slice(1)
        
                if(['completed','terminated'].indexOf(obj.status)>-1){
                    let removeObj = _.remove(this.tableList.rows, ["job_id",job_id])
                    this.removedRow = removeObj
                }
                else{
                    var index = _.findIndex(this.tableList.rows,["job_id",job_id])
                    this.$set(this.tableList.rows,index,obj)
                }
            },
            SuspendJob(job_id,job_exe_auto_id,extraVal){
                var jobData = _.cloneDeep(this.commonRequestData)
                Object.assign(jobData,{"job_id":job_id, "job_exe_auto_id":job_exe_auto_id})
                if(extraVal === 'suspend_after_commit')
                    job_data['status'] = extraVal
                this.loader = true
                postToServer(this, config.ENGINE_API_URL + SUSPENDJOB, jobData).then(response => {
                    this.loader = false
                }).catch(SuspendJobError => {
                    this.showMessage(SuspendJobError)
                })
            },
            ResumeJob(job_id,record){
                var jobData = _.cloneDeep(this.commonRequestData)
                Object.assign(jobData,{"job_id":job_id,"process_definition_id":record.process_definition_id})
                this.loader = true
                postToServer(this, config.COORDINATOR_API_URL + RESUME_COORDINATION, jobData).then(response => {
                    this.loader = false
                }).catch(SuspendJobError => {
                    this.showMessage(SuspendJobError)
                })
            },
            TerminateJob(job_id,job_exe_auto_id){
                var jobData = _.cloneDeep(this.commonRequestData)
                Object.assign(jobData,{"job_id":job_id, "job_exe_auto_id":job_exe_auto_id,"status":"terminate"})
                this.loader = true
                postToServer(this, config.ENGINE_API_URL + TERMINATEJOB, jobData).then(response => {
                    this.loader = false
                }).catch(SuspendJobError => {
                    this.showMessage(SuspendJobError)
                })
            },
            onEditCommit(record){
                let _this = this
                this.editcommit_dialog = true
                var job_id = record.job_id
                var input_json = {"job_id":job_id,"client_id":this.clientId}
                _this.limitTableList.rows = []
                _this.loader = true
                postToServer(this, config.ENGINE_API_URL +'/get_commit_size', input_json).then(commitChangeResponse => {
                    _this.loader = false
                    _this.limitTableList.rows = commitChangeResponse
                }).catch(commitChangeError => {
                    _this.loader = false
                });
            },
            onSave(record,iter=0){
                var _this = this
                iter++
                this.loader = true
                var job_id = record.job_id
                var limit = record.commit_size
                if(!limit || isNaN(limit) || parseInt(limit)<1){
                    this.showMessage('specify valid limit for step '+ this.limitTableList.rows[i]['step_name'])
                    return false
                }
                record["client_id"] = this.clientId
                record["user_name"] = this.userName
                record["request_by"] = "user"
                postToServer(this, config.ENGINE_API_URL +SAVE_NEW_COMMIT_SIZE, record).then(repository_response => {
                    this.loader = false
                    _this.limitTableList.rows = repository_response
                }).catch(repository_response_error => {
                    if(iter<8) // save commit size throws an error
                        return _this.onSave(record,iter)
                    this.showMessage('Set commit size failed. Please try again')
                });
            },
            onCancel(record){
                this.onEditCommit(record)
            },
            getSingleJobDetails(job_id){
                var data = {"env_id":this.environmentId,"job_id":job_id}
                var _this = this
                postToServer(this, config.ENGINE_API_URL +'/get_job_details', data,true).then(response=>{
                    var obj = response
                    if(['completed','terminated'].indexOf(obj.status)>-1){
                        // if object in completed or terminate state, no need to add into list
                        return false
                    }
                    obj.id = obj.job_id
                    _this.changeActiveJobProperties(obj)
                    _this.changeCommonJobProperties(obj,10)
                    var is_obj_alreay_there = _.find(_this.tableList.rows,["job_id",job_id])
                    if(!is_obj_alreay_there)
                        _this.tableList.rows.unshift(obj)
                    if(obj.exe_type === "On Schedule")
                        this.$emit('newScheduleJobFired',obj)
                }).catch(error_msg =>{

                })
            },
        },  
    }
</script>