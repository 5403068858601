import {OEPRATOR_TO_EDC_OPERATOR} from './../constants/operators.js'
import {DATABASE_NUMERIC_DATATYPE} from './../constants/metaData.js';


export function manageScreenDisplay(){
	var window_height = window.innerHeight
	var gridpage_size = 5
	if(window_height < 500)
		gridpage_size = 5
  else if(window_height <700){
      gridpage_size = 10
  }
  else if(window_height <800){
      gridpage_size = 15
  }

  else if(window_height <900){
      gridpage_size = 20
  }
  else {
  	gridpage_size = 25
  }
	// this.$session.set('gridpagesize',gridpage_size)
  // that.envIpJson.page_size = gridpage_size
	return gridpage_size
}

export function isNumericDataType(data_type=''){
	return data_type && DATABASE_NUMERIC_DATATYPE.indexOf(data_type.toLowerCase()) >=0
}

export function separate_operator(targetText='', operator= false){
    if(!targetText)
      return ''
    // saparate operator from value
    if (targetText.includes('%')){
      targetText = targetText.replace('%', '')
    }
    else{
      var op_1 = ""+ targetText.substring(0, 2)
      var op_2 =""+ targetText.substring(0, 1)

      if(OEPRATOR_TO_EDC_OPERATOR[op_1]){
        targetText = targetText.replace(op_1, '')
        var op = op_1
      }
      else if(OEPRATOR_TO_EDC_OPERATOR[op_2]){
        targetText = targetText.replace(op_2, '')
        var op = op_2
      }
    }
    //when operator is true return operator
    if (operator)
      return op
    //return targetText when operator is false
    return targetText
    }


export function getUrlForRedirection(queryObject,addProcessRemark=false){
  let query_params = _.keys(queryObject)
  let redirect_to = ''
  let query_string = ''
  let urlForRedirection = ''
  for(let i=0;i<query_params.length;i++){
    let param = query_params[i]
    if(param === 'redirect_to')
      redirect_to = '/'+queryObject[param]
    else{
      if(!query_string)
          query_string = '?'+getQueryParam(param,queryObject)
      else
        query_string=query_string+"&"+getQueryParam(param,queryObject)
    }

  }
  urlForRedirection = redirect_to+query_string
  if(addProcessRemark)
    urlForRedirection = urlForRedirection+'&isRedirection=T'
return urlForRedirection
}

function getQueryParam(param,queryObject){
  return param+"="+queryObject[param]
}


export function getReadonlyRedirectionUrl(graphFor,environment,record){
  let additionalUrl = "&last_env_id="+environment.id+"&last_env_name="+environment.name+
  "&graphFor="+graphFor+"&product_name=archivist"

  let object_revision = record.object_version
  if(record.object_revision)
      object_revision =  record.object_revision
  additionalUrl = additionalUrl+"&object_revision="+object_revision+"&object_id="+record.object_id+"&object_type_code="+record.object_type_code
  additionalUrl = additionalUrl +'&is_read_only=true'
  return additionalUrl
}

export function calculateRatio(num_1,num_2){
  let max_num = num_1 > num_2?num_1:num_2

  for(let i=max_num; i>1; i--) {

      if((num_1 % i) == 0 && (num_2 % i) == 0) {
          num_1=num_1/i;
          num_2=num_2/i;
      }
  }
  return num_1+":"+num_2;
}


export function calculateRatioWithRespectToOne(num_1,num_2){
  if (num_1 === 0)
    return "0:0"
  let max_num = num_1 > num_2?num_1:num_2
  let min_num = num_1 === max_num?num_2:num_1
  let division = Math.round((max_num/min_num),0)
  return division+':'+"1"
}

export function calculatePercentage(number,divider){
  if(divider === 0)
    return 0
  else
    return (number/divider) * 100
}


export function getPathfinderProduct(that){
  return _.find(that.$session.get('applications_configuration'),['product_name','PATHFINDER'])
}

export function stripDateFormat(targetText){
	if(!targetText)
		return ''
	// saparate operator from value
	if (targetText.includes('/')){
		/,/g, '-'
		targetText = targetText.replaceAll('/', '')

	}
	else if(targetText.includes('-')){
		targetText = targetText.replaceAll('-', '')
	}

	return targetText
}

export function removeNonNumericText(textVal){
	if(!textVal)
		return "0"
	// convert textVal to string in case number came
	textVal = textVal+''
	let newTextVal = textVal.replace(/\D/g,'')
	if(!newTextVal)
		return "0"
	else
		return newTextVal
}

export function expliteConversionOfData(textValue){
	return "DECODE(REPLACE(TRANSLATE(TRIM(\'+textValue+\'),'1234567890','##########'),'#')"
}

export function replaceText(targetText='',textToReplace='',replaceWith=''){
	if(targetText!=0 && !targetText)
		return ''
	// number to string
	targetText = targetText + ""
	if (!textToReplace)
		return targetText.replace(/[^0-9]/g, replaceWith)
	else{
		while (targetText.includes(textToReplace)){
		targetText = targetText.replace(textToReplace,replaceWith)
		}
	}
	return targetText
}