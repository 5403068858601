<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link :to="back_url">{{ name_to_join }}</router-link>
              </li>
              <li>Request Details</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
  
    <v-container>
      <v-card>
        <v-tabs v-model="activetab" non-linear @change="TabChanged">
          <v-tab class="EDC-Tabs" key="tab1">Request Details</v-tab>
          <v-tab class="EDC-Tabs" key="tab2">Approval Details</v-tab>
          <v-tab-item>
            <v-row no-gutters>
              <v-col cols="12">
                <v-col cols="9">
                  <table border="1" style="width:90%" cellpadding="10">
                    <tr>
                      <th class="EDC-TableCell">Request ID</th>
                      <td class="EDC-TableCell">{{ request_id }}</td>
                    </tr>

                    <tr>
                      <th class="EDC-TableCell">Request For</th>
                      <td class="EDC-TableCell">{{ request_for }}</td>
                    </tr>

                    <tr>
                      <th class="EDC-TableCell">Request Status</th>
                      <td class="EDC-TableCell">{{ current_status | capitalize }}</td>
                    </tr>

                    <tr v-if="requester_code === 'POL'">
                      <th class="EDC-TableCell">Policy Id</th>
                      <td class="EDC-TableCell">{{ object_id }}</td>
                    </tr>

                    <tr v-if="requester_code === 'POL'">
                      <th class="EDC-TableCell">Request Details</th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">Action</th>
                            <td class="EDC-TableCell">{{ other_details.request_category | capitalize }}</td>
                            <th class="EDC-TableCell">Environment</th>
                            <td class="EDC-TableCell">{{ other_details.env_name }}</td>
                          </tr>
                          <tr>
                            <th class="EDC-TableCell">Request By</th>
                            <td class="EDC-TableCell">{{ other_details.audit_data.requested_by }}</td>
                            <th class="EDC-TableCell">Request Date/time</th>
                            <td class="EDC-TableCell">{{ convertDate(other_details.audit_data.requested_date) }}</td>
                          </tr>
                        </table>

                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">Attribute</th>
                            <th class="EDC-TableCell">Current Value</th>
                            <th v-if="other_details.request_category === 'update'" class="EDC-TableCell">New Value</th>
                          </tr>
                          <tr>
                            <td class="EDC-TableCell">Business Object</td>
                            <td class="EDC-TableCell">{{ other_details.old_bo_name }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_bo_name }}</td>
                          </tr>

                          <tr>
                            <td class="EDC-TableCell">Process Definition</td>
                            <td class="EDC-TableCell">{{ other_details.old_pd_name }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_pd_name }}</td>
                          </tr>

                          <tr>
                            <td class="EDC-TableCell">Policy Type</td>
                            <td class="EDC-TableCell">{{ other_details.old_policy_type }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_policy_type }}</td>
                          </tr>

                          <tr>
                            <td class="EDC-TableCell">Value Type</td>
                            <td class="EDC-TableCell">{{ other_details.old_value_type }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_value_type }}</td>
                          </tr>

                          <tr>
                            <td class="EDC-TableCell">Value</td>
                            <td class="EDC-TableCell">{{ other_details.old_value }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_value }}</td>
                          </tr>

                          <tr>
                            <td class="EDC-TableCell">Fiscal Year</td>
                            <td class="EDC-TableCell">{{ other_details.old_fiscal_year }}</td>
                            <td v-if="other_details.request_category === 'update'" class="EDC-TableCell">{{ other_details.new_fiscal_year }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr v-if="requester_code === 'JOB' || requester_code === 'JOB_PLAN'">
                      <th class="EDC-TableCell">Request Details</th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">
                              Request Id
                            </th>

                            <td class="EDC-TableCell">
                              {{ request_id }}
                            </td>
                            <th class="EDC-TableCell">
                              Request Type
                            </th>
                            <td class="EDC-TableCell">
                              {{ request_job_approval_type }}
                            </td>
                          </tr>

                          <tr>
                            <th class="EDC-TableCell">
                              Job Name
                            </th>

                            <td class="EDC-TableCell">
                              {{ other_details.object_name }}
                            </td>
                            <th class="EDC-TableCell">
                              Environment
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.env_name }}
                            </td>
                          </tr>

                          <tr v-if="requester_code === 'JOB' && other_details.job_details.restore_criteria">
                            <th class="EDC-TableCell">
                              Restore Criteria
                            </th>
                            <td
                              class="EDC-TableCell"
                              colspan="3"
                            >
                              {{ restore_criteria }}
                            </td>
                          </tr>

                          <tr>
                            <th class="EDC-TableCell">
                              Request By
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.audit_data.requested_by }}
                            </td>
                            <th class="EDC-TableCell">
                              Request Date/time
                            </th>
                            <td class="EDC-TableCell">
                              {{ convertDate(other_details.audit_data.requested_date) }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>


                    <tr v-if="(requester_code === 'JOB' || requester_code === 'JOB_PLAN') && other_details.job_details.limit_details && other_details.job_details.limit_details.length > 0">
                      <th class="EDC-TableCell">
                        Job Parameter
                      </th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">
                              Step Name
                            </th>
                            <th class="EDC-TableCell">
                              Step Type
                            </th>
                            <th class="EDC-TableCell">
                              Commit Size
                            </th>
                          </tr>

                          <tr
                            v-for="(limits, index) in other_details.job_details.limit_details"
                            :key="index"
                          >
                            <td class="EDC-TableCell">
                              {{ limits.step_name }}
                            </td>

                            <td class="EDC-TableCell">
                              {{ limits.step_type }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ limits.limit }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr v-if="(requester_code === 'JOB' || requester_code === 'JOB_PLAN') && other_details.job_details.policy_details && other_details.job_details.policy_details.length > 0">
                      <th class="EDC-TableCell">
                        Policy Details
                      </th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">
                              Object Name
                            </th>
                            <th class="EDC-TableCell">
                              Policy Type
                            </th>
                            <th class="EDC-TableCell">
                              Policy Date
                            </th>
                          </tr>

                          <tr
                            v-for="(policies, index) in other_details.job_details.policy_details"
                            :key="index"
                          >
                            <td class="EDC-TableCell">
                              {{ policies.bo_name }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ policies.policy_type }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ convertOnlyDate(policies.actual_date) }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr v-if="requester_code === 'JOB' && other_details.params && other_details.params.other_activity_details">
                      <th class="EDC-TableCell">
                        Activity Details
                      </th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th
                              class="EDC-TableCell"
                              style=""
                            >
                              Archive ID
                            </th>
                            <th class="EDC-TableCell">
                              Job ID
                            </th>
                            <th class="EDC-TableCell">
                              Driver Count
                            </th>
                            <th class="EDC-TableCell">
                              Archive Date
                            </th>
                            <th class="EDC-TableCell">
                              Dispose Date
                            </th>
                            <th class="EDC-TableCell">
                              Archive Created Date
                            </th>
                          </tr>

                          <tr
                            v-for="(object, index) in other_details.params.other_activity_details"
                            :key="index"
                          >
                            <td class="tdrightjustify">
                              {{ object.activity_id }}
                            </td>
                            <td class="tdrightjustify">
                              {{ object.created_by_job }}
                            </td>
                            <td class="tdrightjustify">
                              {{ object.driver_count }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ convertOnlyDate(object.data_older_than) }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ convertOnlyDate(object.can_dispose_on) }}
                            </td>
                            <td class="EDC-TableCell">
                              {{ convertOnlyDate(object.created_date) }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>


                    <tr v-if="(requester_code === 'JOB' || requester_code === 'JOB_PLAN') && other_details.from_request === 'scheduler'">
                      <th class="EDC-TableCell">
                        Schedule Details
                      </th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">
                              Schedule Name
                            </th>

                            <td class="EDC-TableCell">
                              {{ other_details.params.name }}
                            </td>
                            <th class="EDC-TableCell">
                              Execution Type
                            </th>
                            <td
                              v-if="!other_details.params.is_recurring"
                              class="EDC-TableCell"
                            >
                              One Time
                            </td>
                            <td
                              v-else
                              class="EDC-TableCell"
                            >
                              Reccuring
                            </td>
                          </tr>


                          <tr>
                            <th class="EDC-TableCell">
                              Schedule Date
                            </th>

                            <td
                              class="EDC-TableCell"
                              colspan="3"
                            >
                              {{ convertDateFormat(other_details.params) }}
                            </td>
                            <!-- <th class="EDC-TableCell">Schedule Time</th>
                      <td class="EDC-TableCell">{{other_details.params.start_time.HH}}:{{other_details.params.start_time.mm}}</td> -->
                          </tr>
                          <!-- <tr>
                      <th class="EDC-TableCell">Timezone</th>
                      <td class="EDC-TableCell" colspan="3">{{other_details.params.timezone}}</td>
                      
                    </tr> -->
                        </table>

                        <table border="1" style="width:98%; margin:1%">
                          <tr>
                            <th class="EDC-TableCell">
                              Recurring Pattern
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.params.recurring.recurrence_pattern.recurrence_pattern_type | capitalize }}
                            </td>
                          </tr>

                          <tr>
                            <th class="EDC-TableCell">
                              Recurrence Details
                            </th>
                            <td
                              v-if="other_details.params.recurring.recurrence_pattern.recurrence_pattern_type === 'monthly'"
                              class="EDC-TableCell"
                            >
                              {{ getRecurringString(other_details.params.recurring.recurrence_pattern.month_array) }}
                            </td>

                            <td
                              v-else
                              class="EDC-TableCell"
                            >
                              {{ getRecurringString(other_details.params.recurring.recurrence_pattern.week_array) }}
                            </td>
                          </tr>

                          <tr>
                            <th class="EDC-TableCell">
                              End On
                            </th>
                            <td
                              v-if="other_details.params.recurring.range_of_recurrence.end_on"
                              class="EDC-TableCell"
                            >
                              No End Date
                            </td>
                            <td
                              v-else-if="other_details.params.recurring.range_of_recurrence.end_after_times"
                              class="EDC-TableCell"
                            >
                              after {{ other_details.params.recurring.range_of_recurrence.end_after_times }} iterations
                            </td>

                            <td
                              v-else
                              class="EDC-TableCell"
                            >
                              After {{ convertOnlyDate(other_details.params.recurring.range_of_recurrence.until_date) }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr v-if="requester_code === 'PUB'">
                      <th class="EDC-TableCell">
                        Publish Object Details
                      </th>
                      <td>
                        <table border="1" style="width:98%; margin:1%">
                          <tr
                            v-for="(object, index) in other_details.pub_object_details"
                            :key="index"
                          >
                            <th class="EDC-TableCell">
                              Object Name
                            </th>
                            <td class="EDC-TableCell">
                              {{ object.object_name }}
                            </td>
                            <th class="EDC-TableCell">
                              Object Version
                            </th>
                            <td class="EDC-TableCell">
                              {{ object.object_version }}
                            </td>
                          </tr>
                          <tr>
                            <th class="EDC-TableCell">
                              Source Environment
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.publish_from_name }}
                            </td>
                            <th class="EDC-TableCell">
                              Target Environment
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.env_name }}
                            </td>
                          </tr>
                          <tr>
                            <th class="EDC-TableCell">
                              Requested By
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.audit_data.requested_by }}
                            </td>
                            <th class="EDC-TableCell">
                              Requested Date\time
                            </th>
                            <td class="EDC-TableCell">
                              {{ other_details.audit_data.requested_date }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-col>
            </v-row>

            <v-row no gutters>
              <v-col cols="8">
                  <vc-button v-if="show_approve_reject_button" itemText="Reject" @click.native="onReject"></vc-button>

                  <vc-button v-if="show_approve_reject_button" itemText="Accept" @click.native="onAccept"></vc-button>

                  <vc-button v-if="show_delete_button" @click.native="onCancelRequest()" itemText="Delete"></vc-button>        
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <edc-data-grid :dataList="tableList"/>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    <v-dialog v-model="dialog" width="300">
      <v-form ref="update_task_form" v-model="valid">
        <v-col cols="12" class="EDC-Col">
          <v-card elevation-1>
            <v-row no-gutters class="EDC-Row">
              <v-toolbar dark dense absolute style="width:100%">
                <span><b>Task Name: {{ task_name }}</b></span>
              </v-toolbar>
            </v-row>
            <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
              <v-text-field v-if="comments_required === true" v-model="reason" label="User comments" :rules="reasonRules" required @keypress="reasonKeypress" hide-details dense class="EDC-TextField"/>
              <v-text-field v-else v-model="reason" label="User comments" hide-details dense class="EDC-TextField"/>
            </v-row>
            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
              <vc-button type="submit" :loading="loading" :disabled="loading" itemText="Submit" @click.native="update_request_submit"></vc-button>
            </v-row> 
          </v-card>
        </v-col>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import config from '../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import {GET_REQUEST_HISTORY,CANCEL_REQUEST,UPDATE_TASK_DETAILS} from '../../data/url_constants.js'
import {
  CLIENT_SIDE,
  SERVER_SIDE} from '@/data/macros.js'
  import {
  COLOR_CODE, BTN_COLOR
} from "@/data/macros.js";
  import Confirmation from "../../views/dataTable/Confarmation";
  var moment = require("moment-timezone")
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import data from './metaData.js'

export default {
  name: 'DemoGrid',
      components: {
        // 'confirmation-panel':Confirmation
      },
  data: function () {
    return {
      colorCode:COLOR_CODE,
      outlineColor:BTN_COLOR,
      request_id:'',
      request_for:'',
      WorkTableList:[],
      workflow_name: '',
      activetab:1,
      table_type:'Work Table',
      work_tbl_name:'',
      worktableobj:{},
      loader:false,
      job_id:"",
      job_name:"",
      mode:"final",
      status:"",
      rowlimit:100,
      name_to_join: 'Approval History',
      back_url: '/approvalhistorylist',
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      snackbartimeout: Snackbar_Default_Timeout,
      current_status:'',
      requester_type: null,
      object_id: null,
      other_details: null,
      request_details: [],
      requester_code:'',
      request_job_approval_type:'',
      tableList: {
        headers: [
        { text: 'Name', value: 'node_name',width:'20%' },      
        { text: 'Approver Name', value: 'username',width:'20%' },
        { text: 'Action', value: 'action',width:'10%' },
        { text: 'Action Date', value: 'tz_action_date', sortable: false, dataType:'date', width:'10%'},
        { text: 'Action Time', value: 'tz_action_time', sortable: false, dataType:'time', width:'10%', hideInlineFilter: true},
				// { text: 'Action Time', value: 'timezone_aware_action_time',sortable: false,width:'20%'},
				{ text: 'Remark', value: 'remark',width:'20%'},
				{ text: 'Email sent date', value: 'timezone_aware_email_sent_date', sortable: false,width:'20%', dataType:'date'},
				{ text: 'Email sent time', value: 'timezone_aware_email_sent_time',sortable: false,width:'20%', dataType:'time', hideInlineFilter: true}
        ],
            // actions: ['edit','execute','publish'],
            actions:[],//if we need conditional action in row then provide key
            sorting_type: CLIENT_SIDE,
            filterType: CLIENT_SIDE,
            paginationType: CLIENT_SIDE,
            total_count: 0,
            rows: [],
            disableDefaltSorting:true,
            hideSelect: true
          },
          show_delete_button:false,
          show_approve_reject_button:false,
          restore_criteria:'',
          popOver:{
            delete:false
          },
          object_name:'',
          dialog:false,
          valid:false,
          task_name:'',
          comments_required:false,
          reason:'',
          reasonRules:[
          v => !!v || 'User comments are required.'
          ],
          loading:false,
          approveRejectObj:{},
        }
      },
     watch:{
     },
      mounted() {
        this.activetab = 0
        // console.log(this.name_to_join)
        this.workflow_name=this.$route.params.object_name
        this.current_status=this.$route.params.current_status
        this.object_id=this.$route.params.object_id
        this.object_name = this.$route.params.object_name
        this.requester_type=this.$route.params.requester_type
        this.request_id = this.$route.params.request_id
        this.request_for = this.$route.params.request_for
        this.show_delete_button = this.$route.params.show_delete_button
        this.show_approve_reject_button = this.$route.params.show_approve_reject_button
        this.back_url = this.$route.params.back_url
        this.name_to_join = this.$route.params.name_to_join
        if (this.$route.params.request_id) 
        {

         this.GetApprovalDetails();
       } 
     },
     methods: {
      TabChanged(){
			if(this.activetab === 1){
			  this.getRequestDetails();
			  this.GetApprovalDetails();
			}
			var _this = this
		},
		getRecordPerPage(){
			let inputJson = this.envIpJson;
			inputJson.page_size = this.perPage;
			this.get_environment_by_client_id(inputJson);
		},
   GetApprovalDetails(){
     var time_zone = this.$session.get('time_zone_preference')
     var get_request_history_url = config.PROCESS_APPROVAL_URL + GET_REQUEST_HISTORY
     var data = {'approval_id': this.$route.params.request_id}
     postToServer(this, get_request_history_url, data).then(response => {
       if(response){
        var data = response.request_data
        data.map(function(obj){
          if(obj.action)
            obj.action = obj.action.charAt(0).toUpperCase() + obj.action.slice(1)
          if(obj.remark)
            obj.remark = obj.remark.charAt(0).toUpperCase() + obj.remark.slice(1)
        })
        this.tableList.rows = data
        this.tableList.total_count = response.total;
        this.other_details = response.other_details
        this.requester_code = this.other_details.requester_code
        this.request_job_approval_type = "Job Approval"
        if(this.requester_code === 'JOB' && this.other_details.from_request === 'scheduler')
          this.request_job_approval_type ='Schedule Job'
        else if(this.requester_code === 'JOB_PLAN')
          this.request_job_approval_type ='Job Plan Approval ('+response.other_details.job_plan_name+')'
        if(this.other_details.job_details && this.other_details.job_details.restore_criteria)
          this.getRestoreCriteriaString(this.other_details.job_details.restore_criteria)
        // console.log(response.other_details)
        // alert(response.other_details.request_code)
        // this.getRequestDetails();
      }
    }).catch(error_response => {
     if(error_response){
				// this.snackbar = true
				// this.colorValue = 'error'
				// this.snackbartext = error_response;
			}
			else {
				// this.snackbar = true
				// this.colorValue = 'error'
				// this.snackbartext = SERVER_ERROR;
      }
    });
  },
  getRequestDetails(){
   if(this.other_details){
    var request_details = this.other_details
    var keys  = Object.keys(request_details)
	          	// index used for sorting
	          	var data_list = []
	          	for (var i = 0; i <keys.length; i++) {
		            // if(keys[i] === "process_definition_name")
		            //   data_list.push({"name":"Process Definition Name","value":response.process_definition_name,"index":1})
		            if(keys[i] == "object_name"){
                  var object_name = request_details.object_name
                  data_list.push({"name":"Object Name","value":object_name,"index":0})
                }	

                if(keys[i] == "env_name"){
                  var env_name = request_details.env_name
                  data_list.push({"name":"Environment Name","value":env_name,"index":1})
                }
                if(keys[i] == "old_bo_name"){
                  var old_bo_name = request_details.old_bo_name
                  data_list.push({"name":"Business object Name","value":old_bo_name,"index":2})
                }
                if(keys[i] == "old_pd_name"){
                  var old_pd_name = request_details.old_pd_name
                  data_list.push({"name":"Process Definition Name","value":old_pd_name,"index":3})
                }
                if(keys[i] == "old_policy_type"){
                  var old_policy_type = request_details.old_policy_type
                  data_list.push({"name":"Policy Type","value":old_policy_type,"index":4})
                }
                if(keys[i] == "old_value_type"){
                  var old_value_type = request_details.old_value_type
                  data_list.push({"name":"Value Type","value":old_value_type,"index":5})
                }
                if(keys[i] == "old_value"){
                  var old_value = request_details.old_value
                  data_list.push({"name":"Value","value":old_value,"index":6})
                }
                if(keys[i] == "old_fiscal_year"){
                  var old_fiscal_year = request_details.old_fiscal_year
                  data_list.push({"name":"Fiscal Year","value":old_fiscal_year,"index":7})
                }
                this.request_details = _.sortBy(data_list,"index")
              }
            }
            this.getRequestDetailsNew()
          },
          getRequestDetailsNew(){
            if(this.other_details){
              var request_details = this.other_details
              var keys  = Object.keys(request_details)

              if(request_details.requester_code=== 'POL'){
                return
              }

              // index used for sorting
              var data_list = []
              for (var i = 0; i <keys.length; i++) {
                // if(keys[i] === "process_definition_name")
                //   data_list.push({"name":"Process Definition Name","value":response.process_definition_name,"index":1})
                if(keys[i] == "object_name"){
                  var object_name = request_details.object_name
                  data_list.push({"name":"Object Name","value":object_name,"index":0})
                } 
                
                if(keys[i] == "env_name"){
                  var env_name = request_details.env_name
                  data_list.push({"name":"Environment Name","value":env_name,"index":1})
                }
                if(keys[i] == "old_bo_name"){
                  var old_bo_name = request_details.old_bo_name
                  data_list.push({"name":"Business object Name","value":old_bo_name,"index":2})
                }
                if(keys[i] == "old_pd_name"){
                  var old_pd_name = request_details.old_pd_name
                  data_list.push({"name":"Process Definition Name","value":old_pd_name,"index":3})
                }
                if(keys[i] == "old_policy_type"){
                  var old_policy_type = request_details.old_policy_type
                  data_list.push({"name":"Policy Type","value":old_policy_type,"index":4})
                }
                if(keys[i] == "old_value_type"){
                  var old_value_type = request_details.old_value_type
                  data_list.push({"name":"Value Type","value":old_value_type,"index":5})
                }
                if(keys[i] == "old_value"){
                  var old_value = request_details.old_value
                  data_list.push({"name":"Value","value":old_value,"index":6})
                }
                if(keys[i] == "old_fiscal_year"){
                  var old_fiscal_year = request_details.old_fiscal_year
                  data_list.push({"name":"Fiscal Year","value":old_fiscal_year,"index":7})
                }
                this.request_details = _.sortBy(data_list,"index")
              }
            }

          },
          convertDate(date_to_convert,zone,only_date){
            var date_format = this.$session.get('UI_date_format')
            var time_zone = this.$session.get('time_zone_preference')
            var moment_obj = moment.utc(date_to_convert, "YYYY-MM-DD HH:mm:ss")
            if (zone)
              var moment_obj = moment(date_to_convert, "YYYY-MM-DD HH:mm:ss",zone)
            var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('MMM DD, YYYY')
            var zone = moment().tz(this.$session.get('time_zone_preference')).format('z')
            if(!only_date){
              var timezoned_end_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
              return timezoned_formated_date +' / '+timezoned_end_time + ' ('+zone+')'
            }
             return  timezoned_formated_date + ' ('+zone+')'
          },

          convertDateFormat(date_to_convert){

            var date_val = date_to_convert.start_from
            var time_val = date_to_convert.start_time.HH +':'+date_to_convert.start_time.mm
            return this.convertDate(date_val+' '+time_val+':00',date_to_convert.timezone)
            // var moment_obj = moment.utc(date_to_convert.start_from, "YYYY-MM-DD")
            // var formated_date = moment(moment_obj).format('MMM DD, YY')
            // return formated_date
          },

          convertOnlyDate(date_to_convert){
            if(!date_to_convert)
              return ''
            return this.convertDate(date_to_convert,false,true)
          },
          getRestoreCriteriaString(criteria){
            var data = {"client_id":this.$session.get('client_id'),'criteria':criteria}
            var get_request_history_url = config.PROCESS_APPROVAL_URL + '/restoration_criteria'
            postToServer(this, get_request_history_url, data).then(response => {
              if(response){
                this.restore_criteria = response.criteria_str
              }
            }).catch(error_response => {
              console.log(error_response)
              return ''
            })
          },
          onCancelRequest(){
            let _this = this;
            _this.loader=true;
            var EnvironmentObj = this.$session.get('selected_env')
            var client_id = this.$session.get('client_id')
            var data = {
              'id': this.request_id,
              'client_id': client_id, 
              'approval_id': this.request_id, 
              'request_desc': '',
              'object_id': this.object_id,
              'object_name': this.object_name, 
              'env_id': EnvironmentObj.id, 
              'env_name': EnvironmentObj.name}
              var cancel_req_url = config.PROCESS_APPROVAL_URL + CANCEL_REQUEST
              postToServer(this, cancel_req_url, data).then(response => {
                _this.loader=false;
                if(response){
                  this.snackbar = true
                  this.colorValue = 'success'
                  this.snackbartext = response;
                }
                this.show_delete_button = false;
                  // this.GetApprovalDetails();
                }).catch(error_response => {
                  if(error_response){
                  }
                  else {
                  }
                });
              },
              reasonKeypress(e){
                if(e.key === "Enter"){
                  e.preventDefault()
                  this.update_request_submit()
                }
              },
              update_request_submit () {
                if (this.$refs.update_task_form.validate()) {
                  this.update_task()
                }
              },
              update_task() {
                if(!this.reason && this.task_action_type == 'reject'){
                  return false
                }
                const l = this.loader
                this[l] = !this[l]
                var user_id = this.$session.get('user_id')
                
                var env_name = ""
                if(this.Environment){
                  var env_name = this.Environment.name
                }
                var update_task_url = config.PROCESS_APPROVAL_URL + UPDATE_TASK_DETAILS + this.request_id
                this.approveRejectObj["remark"] = this.reason
                this.loading = true
                postToServer(this, update_task_url, this.approveRejectObj).then(response =>{
                  this.loading = false
                  this.dialog = false
                  this.show_approve_reject_button = false
                  if (response == 'rejected'){
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = 'You have rejected the request'
                  } else {
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = 'You have accepted the request'
                  }
                  this.GetApprovalDetails()
                }).catch(error_response => {
                  this.loading = false
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = error_response;
                });
              },
              onAccept(){
                this.task_name = this.$route.params.request_for
                this.comments_required = false
                this.dialog = true
                this.RequestUpdate('accept');
              },
              onReject(){
                this.task_name = this.$route.params.request_for
                this.comments_required = true
                this.dialog = true
                this.RequestUpdate('reject');
              },
              RequestUpdate (type) {
                this.reason = '',
                this.approveRejectObj = {}
                if (type == 'accept' || type == 'blanket_approved')
                {
                  var current_status = "accepted"
                }
                else
                {
                  var current_status = "rejected"
                }
                var EnvironmentObj = this.$session.get('selected_env')
                this.approveRejectObj = {
                  "action": current_status ,
                  "client_id": this.$session.get('client_id'),
                  "user_name":this.$session.get('email'),
                  "user_id":this.$session.get('user_id'),
                  "client_name": this.$session.get('client_name'),
                  "node_id_from_email": this.$route.params.node_id,
                  "workflow_master_cloned_id": this.$route.params.workflow_master_cloned_id,
                  "object_id": this.object_id,
                  "object_name": this.object_name,
                  "is_blanket_approval": false,
                  "remark": this.reason,
                  "workflow_name": this.$route.params.workflow_name,
                  "request_desc": this.$route.params.request_for,
                  "env_name": EnvironmentObj.env_name,
                  "requester_code": this.requester_code,
                  "env_id": EnvironmentObj.id
                }
              },
              getRecurringString(data_list){
                var data_string = ''
                for (var i = 0; i < data_list.length; i++) {
                  if(!data_string)
                    data_string = data_list[i]
                  else
                    data_string = data_string + ', ' + data_list[i]
                }
                return data_string
              }
            }
          }
          </script>
          <style scoped>

          .graph_div {
            width:500px !important;
            height:500px !important;
            overflow: auto !important;
          }


          /* tr:hover td {background:#A9A9A9;} */
          .blurOpacity{
            opacity: 0;
          }
          .showInput {
            visibility: inherit !important;
            width: 50% !important;
          }

          .srch-ip {
            width: 0px;
            background: white;
            color: black;
            float: right;
            transition: all 1s;
            visibility: hidden;
          }
          #radioControls .v-input--selection-controls {
           margin-top: 0px !important; 
           padding-top: 0px !important; 
         }

         tr:nth-child(even) {background-color: white;}

        .EDC-TableCell {
          text-align: left !important;
          padding-left: 5px !important;
          height: 30px !important;
        }

        .tdrightjustify {
          text-align:right;
          padding-right:1%;
        }

        table, td, th {
          border: 1px solid black !important;
        }

        .EDC-TextField {
          padding-top:60px !important;
          padding-left:10px !important;
          padding-right:10px !important;
        }

        /* table {
          border: 2px solid grey;
          border-radius: 3px;
          background-color: #fff;
          cursor: pointer;
        }

        thead {
          line-height: 30px;
          transition: all 10s;
        }

        th {
          background-color: grey;
          color: rgba(255, 255, 255, 0.66);
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        } */

        /* td {
          background-color: #f9f9f9;
        } */

        /* th,
        td {
          min-width: 120px;
          padding: 10px 20px;
        } */

        /* th.active {
          color: #fff;
        }

        th.active .arrow {
          opacity: 1;
        }

        .arrow {
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          margin-left: 5px;
          opacity: 0.66;
        }

        .arrow.asc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #fff;
        }

        .arrow.dsc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #fff;
        }

        .srch-icon {
          font-size: 16px;
          color: white;
          float: right;
          margin-top: 4%;
        } */
        </style>