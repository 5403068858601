<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Scheduled Job List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row></v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onDownload="onDownload" @onCopy="onCopy" @onNew="onNew" 
        @onActivate="onActivate" @onDeactivate="onDeactivate" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"
        ></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'
import {
    GET_SCHEDULE_JOBS, 
    REMOVE_JOB,
    ACTIVE_DEACTIVE,
    EXPORT_SCHEDULED_JOBS
} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {SCHEDULER_SERVICE, UPDATE_ACTION,DELETE_ACTION, READ_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'ClientJobListNew',
    data: function () {
        return {        
            loader:false,
            headers: [{
                        text: 'Name',
                        value: 'name',
                        width: "20%",
                        title: 'Job Name'
                    },
                    {
                        text: 'Categaory',
                        value: 'params.category',
                        width: "10%",
                        title: 'Job Type',
                        is_key_json:true
                    },
                    {
                        text: 'Type',
                        value: 'params.is_recurring',
                        width: "10%",
                        title: 'Job Type',
                        is_key_json:true
                    },
                    {
                        text: 'Schedule Description',
                        value: 'description',
                        width: "50%",
                        title: 'Job Schedule Description',
                        copyToClipboard:true
                    },
                    {
                        text: 'Next Run Time',
                        value: 'next_run_time',
                        width: "20%",
                        hideInlineFilter: true,
                        title: 'Job Next Run Time'
                    },
                    {
                        text: 'Last Run Time',
                        value: 'last_run_time',
                        width: "20%",
                        hideInlineFilter: true,
                        title: 'Job Last Run Time'
                    },
                    {
                        text: 'Object ID',
                        value: 'object_id',
                        title: 'Object Id',
                        align:'right'
                    }                 
                ],
                tableList: {
                headers:[],
                rows:[],
                actions:[]
            },  
            // tableList: {
            //     headers: [{
            //             text: 'Name',
            //             value: 'name',
            //             width: "20%",
            //             title: 'Job Name'
            //         },
            //         {
            //             text: 'Categaory',
            //             value: 'params.category',
            //             width: "10%",
            //             title: 'Job Type',
            //             is_key_json:true
            //         },
            //         {
            //             text: 'Type',
            //             value: 'params.is_recurring',
            //             width: "10%",
            //             title: 'Job Type',
            //             is_key_json:true
            //         },
            //         {
            //             text: 'Schedule Description',
            //             value: 'description',
            //             width: "50%",
            //             title: 'Job Schedule Description',
            //             copyToClipboard:true
            //         },
            //         {
            //             text: 'Next Run Time',
            //             value: 'next_run_time',
            //             width: "20%",
            //             hideInlineFilter: true,
            //             title: 'Job Next Run Time'
            //         },
            //         {
            //             text: 'Last Run Time',
            //             value: 'last_run_time',
            //             width: "20%",
            //             hideInlineFilter: true,
            //             title: 'Job Last Run Time'
            //         },
            //         {
            //             text: 'Object ID',
            //             value: 'object_id',
            //             title: 'Object Id',
            //             align:'right'
            //         }                 
            //     ],
            //     actions: [], //if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     syncHeaderScroll:false,
            //     selected_rows:[],
            // },
            snackbar: false,
            snackbartext: '',
            snackbartimeout: Snackbar_Default_Timeout,
            colorValue: 'success',
            valid: true,
            userRole:{},
        }
    },
    
    mounted() {
        var env_value = this.$session.get('selected_env')
        this.tableList = this.getGridObj(this.headers,'name')
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [{'text':'edit','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,UPDATE_ACTION),index:1},
                    {'text':'delete','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,DELETE_ACTION),index:2},
                    {'text':'activate','key':"showactivate", selectType:"multiple", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,UPDATE_ACTION), index:3, cmpValue: 'YES'},
                    {'text':'deactivate','key':"showdeactivate", selectType:"multiple", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,UPDATE_ACTION), index:4 , cmpValue: 'YO' }]
        this.getScheduledJobs();    
    },
    methods: {

        onNew(){
            this.$router.push({ name: 'ManagePolicy'});            
        },
        onCopy(record){            
            this.$router.push({ name: 'schedulejob', params: { schedule_id: record.id, type:'copy' }});            
        },
        onEdit(record){
            this.$router.push({ name: 'schedulejob', params: { schedule_id: record.id, type:'edit' }});            
        },
         onActivate(record){
            
            this.toggleJob(record, "activate")            
        },
         onDeactivate(record){
           
            this.toggleJob(record, "deactivate")           
        },
          toggleJob(job_id, operation) {
            var map=_.map(job_id,"id")
            var data = {"job_id":map, 
                    "operation":operation}
            postToServer(this, config.SCHEDULER_URL + ACTIVE_DEACTIVE ,data).then(response => {
            // this.$toasted.success(response);
            this.colorValue = 'success'
            this.snackbartext = response;
            this.snackbar = true
            this.getScheduledJobs();
            }).catch(error_response => {
            this.loader = false;
                  if(error_response){
                      this.loader = false 
                      this.snackbar = true
                      this.colorValue = 'error'
                      this.snackbartext = error_response;
                    }
                    else {
                      this.snackbar = true
                      this.colorValue = 'error'
                      this.snackbartext = SERVER_ERROR;
                    }
            })
        },
        onDelete:function(record){
            this.deleteEnvionment(record.id);
            // let inputJson = _this.envIpJson;
            // this.get_environment_by_client_id(inputJson);
        },
        deleteEnvionment(env_id) {
            getToServer(this, config.SCHEDULER_URL + REMOVE_JOB + env_id).then(response => {
            // this.$toasted.success(response);
            this.colorValue = 'success'
            this.snackbartext = response;
            this.snackbar = true
            this.getScheduledJobs();
            }).catch(error_response => {
            this.loader = false;
                  if(error_response){
                      this.loader = false 
                      this.snackbar = true
                      this.colorValue = 'error'
                      this.snackbartext = error_response;
                    }
                    else {
                      this.snackbar = true
                      this.colorValue = 'error'
                      this.snackbartext = SERVER_ERROR;
                    }
            })
        },
        getScheduledJobs () {
        let _this = this
        _this.loader = true;
        // var client_id = this.$session.get('client_id');
        var getScheduledData = {'client_id': this.$session.get('client_id')}
        postToServer(this, config.SCHEDULER_URL + GET_SCHEDULE_JOBS, getScheduledData
            ).then(response => {   
          _this.loader = false;      
          if(response){
              this.tableList.rows = response;
              this.tableList.total_count = response.length;
              this.tableList.rows.map(function(obj){
                obj["object_id"] = obj.params.process_definition_id
                // if (obj.next_run_time === "Deactivated")
                //     obj.showactivate = 'N'
                // else
                //     obj.showdeactivate = 'N'
                switch(obj.next_run_time){
                  case 'Deactivated':
                  obj.showactivate = 'YES';
                  break;
                  default:
                  obj.showdeactivate = 'YO';
                    }
              })
          }          
        }).catch(error_response => {
            _this.loader = false;
          if(error_response){
              this.tableList.rows = [];
              this.loader = false 
            //   this.snackbar = true
            //   this.colorValue = 'error'
            //   this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
        });
    
        },
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filterArray
            }
            var url = config.SCHEDULER_URL+EXPORT_SCHEDULED_JOBS;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.SCHEDULER_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          });
        }
        
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>