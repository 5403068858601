<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="12">
                        <v-card>
                        <v-card-title class="cardHeader"> Record Summary </v-card-title>
                        <v-card-text>
                            <table border="1" style="width:100% !important;" cellpadding="8">
                                <tr>
                                <th class="EDC-TableCell" style="width:50% !important;"></th>
                                <th class="EDC-TableCell" style="width:20% !important;">Expected</th>
                                <th class="EDC-TableCell" style="width:20% !important;">Processed</th>
                                <th class="EDC-TableCell" style="width:10% !important;">%</th>
                                </tr>

                                <tr>
                                <th class="EDC-TableCell" style="width:50% !important;">Balance Forward (BF) Records</th>
                                <td class="tdrightjustify">{{expectedBF}}</td>
                                <td class="tdrightjustify">{{processedBF}}</td>
                                <td class="tdrightjustify">{{percentageBF}}</td>
                                </tr>

                                <tr>
                                <th class="EDC-TableCell">{{summarizationFor}} Detail Records To Summarize</th>
                                <td class="tdrightjustify">{{expectedDetails}}</td>
                                <td class="tdrightjustify">{{processedDetails}}</td>
                                <td class="tdrightjustify">{{percentageDetails}}</td>
                                </tr>

                                <tr>
                                <th class="EDC-TableCell">Ratio (Detail to BF)</th>
                                <td class="tdrightjustify">{{expectedRatio}}</td>
                                <td class="tdrightjustify">{{processedRatio}}</td>
                                <td class="tdrightjustify"></td>
                                </tr>

                                <tr v-if="isF0911">
                                <th class="EDC-TableCell">F0911 detail skipped due to archived F0902</th>
                                <td class="tdrightjustify">{{skippedCount}}</td>
                                <td class="tdrightjustify"></td>
                                <td class="tdrightjustify"></td>
                                </tr>
                            </table>
                        </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row" style="padding-top:8px !important;" v-if="showOptions">
                    <v-col class="EDC-Col" cols="12">
                        <v-card>
                        <v-card-title class="cardHeader" v-if="isF4111"> Selected Options </v-card-title>
                        <v-card-text>
                            <table border="1" style="width:100% !important;" cellpadding="8">
                            <tr v-if="showGLICU">
                                <th class="EDC-TableCell" style="width:50% !important;">Balance Forward GL Batch Number</th>
                                <td class="EDC-TableCell">{{glicuValue}}</td>
                            </tr>
                            <tr v-if="showGLICU">
                                <th class="EDC-TableCell" style="width:50% !important;">Balance Forward GL Batch Type</th>
                                <td class="EDC-TableCell">G</td>
                            </tr>
                            <tr v-if="isF4111">
                                <th class="EDC-TableCell" style="width:50% !important;">Summarized By</th>
                                <td class="EDC-TableCell">{{summarizedBy}}</td>
                            </tr>

                            <tr v-if="isF4111">
                                <th class="EDC-TableCell" style="width:50% !important;white-space: initial;">Write BF record If Summarize Transaction Quantity & Extended Cost Is 0</th>
                                <td class="EDC-TableCell">{{writeBFifZero}}</td>
                            </tr>
                            </table>
                        </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    tr:nth-child(even) {background-color: white;}
    .tdrightjustify {
        text-align:right;
        padding-right:8px !important;
    }

    table, td, th {
        border: 1px solid black !important;
    }

    .cardHeader{
        font-size:16px !important;
        padding-left:16px !important;
        padding-top:0px !important;
        padding-bottom:4px !important;
    }
</style>
<script>
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import {calculateRatioWithRespectToOne,calculatePercentage} from '@/methods/commonFunc.js'
    import config from '../../../config.json'
    import { post as postToServer } from '../../../methods/serverCall.js';
    export default{
        name:"SummarizationDetails",
        mixins:[mixinJobManagement],
        props:{
            jobId:{
                type:Number,
                default:0
            },
            jobMode:{
                type:String,
                default:''
            },
            jobType:{
                type:String,
                default:''
            }
        },
        data(){
            return{
                expectedBF:0,
                processedBF:0,
                percentageBF:0,
                expectedDetails:0,
                processedDetails:0,
                percentageDetails:0,
                expectedRatio:"",
                processedRatio:"",
                summarizationFor:'',
                skippedCount:0,
                glicuValue:'',
                summarizedBy:'',
                writeBFifZero:'',
            }
        },
        computed:{
            isF0911(){
                return this.summarizationFor==='F0911'
            },
            isF4111(){
                return this.summarizationFor==='F4111'
            },
            showGLICU(){
                return this.isF0911  && this.jobMode === 'final' && this.jobType === 'Normal'
            },
            showOptions(){
                return this.showGLICU || this.isF4111
            }
        },
        watch:{
            '$store.state.summarizedRowCounts':{
                handler(newValue){
                this.updateSummarizationRow(newValue)
                }
            },
        },
        mounted(){
            this.getSummarizedRecordCount()
        },
        methods:{
            updateSummarizationRow(sumarizationData){
                var _this = this
                if(this.jobId!=sumarizationData.job_id)
                    return
                // considering single row will be present in the summarization record
                _this.$nextTick(()=>{
                    _this.manageSummarizationCount(sumarizationData)
                })
            },
            manageSummarizationCount(countData){
                this.expectedBF = countData.localize_expected_bf_count
                this.processedBF = countData.localize_processed_bf_count
                this.expectedDetails = countData.localize_expected_summarized_count
                this.processedDetails = countData.localize_summarized_count
                this.skippedCount = countData.localize_skipped_count
                if(!this.summarizationFor)
                    this.summarizationFor = countData.table_name

                this.expectedRatio = calculateRatioWithRespectToOne(countData.expected_summarized_count,countData.expected_bf_count)
                if(countData.summarized_count > 0 && countData.processed_bf_count > 0)
                    this.processedRatio = calculateRatioWithRespectToOne(countData.summarized_count,countData.processed_bf_count)
                this.percentageBF = parseInt(calculatePercentage(countData.processed_bf_count,countData.expected_bf_count))
                this.percentageDetails = parseInt(calculatePercentage(countData.summarized_count,countData.expected_summarized_count))
            },
            manageSummarizationOptions(optionData){
                if(optionData.glicu_value)
                    this.glicuValue = optionData.glicu_value
                if(optionData.summarize_by){
                    optionData.summarize_by = optionData.summarize_by.replace('summarize_by_','')
                    this.summarizedBy = optionData.summarize_by
                }
                this.writeBFifZero = 'No'
                if(optionData.write_bf_record_if_quantity_0)
                    this.writeBFifZero = 'Yes'
            },
            getSummarizedRecordCount(){
                let _this = this;
                var job_data = {"job_id": this.jobId, "env_id":this.environmentId}
                _this.loader = true
                postToServer(this, config.ENGINE_API_URL + '/get_summarization_count', job_data).then(response => {
                    _this.loader = false
                    if(response.summarize_count && response.summarize_count.length)
                        _this.manageSummarizationCount(response.summarize_count[0])
                    if(response.summarize_option)
                        _this.manageSummarizationOptions(response.summarize_option)
                }).catch(JobStepError => {
                    _this.showMessage(JobStepError)
                })
            },
        }
    }
</script>