
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {SERVER_ERROR} from '@/data/client_message.js'
import {getURLByDomainByProduct} from '@/methods/domain_management.js'
import {getReadonlyRedirectionUrl} from './../methods/commonFunc.js'
import objectPDTabularView from './../views/objectPDTabularView.vue'
import {getEnvironmentList} from './../methods/EnvironmentList.js'


export const mixinJobManagement={
    components:{
        'edc-pdviewer':objectPDTabularView
    },
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,         
            loader:false,
            Environment:{},
            EnvironmentList:[],
            userRole:{},
            viewpd_dialog:false,
            objectdetails:{}
        }
    },
    computed: {
        clientId(){
            return this.$session.get('client_id')
        },
        userName(){
            return this.$session.get('email')
        },
        isEnvironmentSelected(){
            return !_.isEmpty(this.Environment) && this.Environment.id
        },
        environmentId(){
            return this.Environment.id
        },
        environmentName(){
            return this.Environment.name
        },
        commonRequestData(){
            return {"env_id":this.environmentId,"client_id":this.clientId,"env_name":this.environmentName}
        },
    },
    mounted(){
        var env_value = this.$session.get('selected_env')
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
        }
        getEnvironmentList(this)
    },
    methods: {
        changeCommonJobProperties(job,rowIndex){
            job.is_restored = job.is_restored ? "Restoration" : "Normal"
            if (job.job_type === "sync_up" && job.is_restored === "Normal")
                job.is_restored = "Sychronization"
            else if (job.job_type === "sync_up" && job.is_restored === "Restoration")
                job.is_restored = "Un-Sychronization"
            if(job.activity_type === 'Dispose')
                job.is_restored = "Disposal"
            else if(job.activity_type === 'Delete Copy')
                job.is_restored = "Delete Copy"

            if(!job.type_of_job)
                job.type_of_job = job.is_restored
            job.status_to_show = ''
            if(job.status){
                job.status_to_show = job.status.charAt(0).toUpperCase() + job.status.slice(1)
                job.status = job.status_to_show
            }
            job.row_id = rowIndex

            job.mode_of_execution = job.mode_of_execution.charAt(0).toUpperCase() + job.mode_of_execution.slice(1)
            job.exe_type = job.exe_type === 'on_schedule' ? "On Schedule": "On Demand"
        },
        showMessage(message,type='error'){
            this.loader = false
            this.snackbar = true
            this.colorValue = type
            this.snackbartext = message || SERVER_ERROR;
        },
        onHistory(record){
            let _this = this;
            let showSummarization = false

            if(record.is_exists_summarization_step)
                showSummarization=true

            let paramsData = {
                env_id: this.environmentId, 
                envname:this.environmentName,
                jobname: record.process_definition_name, 
                jobid: record.job_id,
                mode:record.mode_of_execution,
                status:record.status, 
                job_plan_name:record.plan_name,
                type:this.pageType,
                isDuplicateFound:record.is_execute_duplicate,
                'showSummarization':showSummarization,
                'isReportAvailable':record.report_step_available
            }
            // console.log("paramsdata",paramsData)
            _this.$router.push({ 
                name: 'showjobstepsresult', 
                params: paramsData
            });
        },
        onCellEvent(record){
            this.onHistory(record)
        },
        onShowGraph(record){
            this.$router.push({ 
                name: 'livetblgraph',
                params: { 
                    envname:this.environmentName,
                    jobname: record.process_definition_name, 
                    env_id:this.environmentId,
                    jobid: record.job_id,
                    status:record.status_to_show
                }
            });
        },
        onReExecute(record){
            let commonData = { 
                'process_definition_id': record.process_definition_id, 
                'process_doc_name': record.process_definition_name, 
                'activity_id':null,'environment':this.Environment, 
                'is_restore':false,
                'job_id':record.job_id
            }
            this.$store.state.createJobParams = _.cloneDeep(commonData);
            Object.assign(commonData, {'is_rexecute_request':true,'exe_mode':record.mode_of_execution})
            this.$router.push({ 
                name: 'createJob', 
                params:commonData
            });
        },
        onShowPD(record){
            this.objectdetails  = {
                'env_id':this.environmentId,
                'object_id':record.process_definition_id,
                'object_name':record.process_definition_name,
                'isfromjob':true,
                'policy_date':record.actual_date
            }
            this.viewpd_dialog = true
        },
        onGraphView(record){
            let designerHostDetails =_.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
            let additionalUrl = getReadonlyRedirectionUrl('PD',this.Environment,{'object_id':record.process_definition_id,'object_version':record.process_definition_version,'object_type':'process definition'})
            let url = getURLByDomainByProduct(this, designerHostDetails,'login',additionalUrl)
            window.open(url, '_blank');  
        },
        onCreateJobReport(record){
            let _this = this;
            this.$router.push({ 
                name: 'SelectReportDownload', 
                params: {
                    'client_id': this.clientId,
                    'env_id': this.environmentId, 
                    'job_id_list': [record.id]
                }
            });
        },
        linkCellClicked(event, header,record){
            if((header.value === 'plan_full_name'|| header.value === 'plan_name') && record.job_plan_id){
                this.moveToJobPlan(record)
            }
        },
        moveToJobPlan(record){
            this.$router.push({ 
                name: 'ManageJobPlanInstance', params: {
                'env_id': this.Environment.id, 
                'job_plan_id': record.job_plan_id,
                'job_plan_name':record.plan_name,
                'mode_of_execution':record.mode_of_execution,
                env_name:this.Environment.name
            }});
        }
    },
}