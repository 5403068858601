<template>
    <v-row class="EDC-Row">
        <v-col class="EDC-Col">
            <div style="display:inline;">
                <div v-if="selectedArray.length" style="display:inline;">
                    <template v-for="(item,index) in availableActions">
                        <action-component v-if="index < actionInFirstBatch || isJustOneMoreInFirstBatch" :key="index+'action'" style="display:inline" :actionObject="item" @createEvent="createEvent">

                        </action-component>
                    </template>
                    <span style="display:inline" v-if="showMoreOptions">
                        <v-menu transition="scale-transition" :close-on-content-click="false" offset-x right>
                            <template v-slot:activator="{ on }">
                                <svgicon class="svg-icon-grid svg-fill-grid"  v-on="on"  name="More" slot="activator" title="Show More Options" :original="true">
                                </svgicon>     
                            </template>
                            <div style="display:inline;padding:2px !important;">
                                <template v-for="(item,index) in availableActions">
                                    <action-component v-if="index >= actionInFirstBatch" :key="index+'action'" style="display:inline" :actionObject="item" @createEvent="createEvent">

                                    </action-component>
                                </template>
                            </div>
                        </v-menu>
                    </span>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<style scoped>
.iconFormat{
    font-size: 21px;
}
.customCheckbox >>> label{
  top: 5px !important;
}
.radioClass >>> label{
    top: 5px !important;
}

.v-btn--fab.v-size--x-small{
    height:24px !important;
    width:24px !important;
    margin:2px;
}

  /*.svg-fill{
    fill:red;
}*/
</style>
<script>
    import {actionObjMapping, confirmationObjMapping} from './constants.js'
    import '../../../compiled-icons';
    import actionComponent from './actionComponent.vue'
    export default{
        name:'ActionPanel',
        components:{
            'action-component':actionComponent
        },
        props: {
            classifiedActions:{
                tyep:Array,
                default:function(){
                    return []
                },
            },
            selectedArray:{
                type:Array,
                default: function () {
                    return []
                },
            }
        },
        data(){
            return {
                actionInFirstBatch:3,
            }
        },
        computed:{
            availableActions(){
                var actionList = []
                // console.log('this.classifiedActions=>',this.classifiedActions)
                for(var i=0;i<this.classifiedActions.length;i++){
                    /*  The datagrid already giving us the actions with SelectType (show on single/multiple rows).
                        So here we no need to check it. Here we will only manage following props of the action.
                            1) Icon Name based on actionText
                            2) Event Name
                            3) Confirmation Required Or Not
                                If Required
                                    a) Type of confirmation (normal confirm / job confirm)
                                    b) Confirmation Header
                                    c) Message
                                    d) Other details like Ok/Cancel button Text
                            4) Change actionTitle with displayText
                            5) Check action has a listItems
                    */
                    let currentAction = _.cloneDeep(this.classifiedActions[i])
                    let actionMapObj = _.find(actionObjMapping,['actionName',currentAction.text])
                    if(!actionMapObj){
                        alert(currentAction.text + ' Action mapping not found')
                        continue
                    }
                    // actionObjMapping is const type, so you should not modify it, so thats why creating the copy
                    let copyOfAction = _.cloneDeep(actionMapObj)

                    // now overwriting all exists props of ActionMapObj with currentAction like actionTitle, eventName etc
                    Object.assign(copyOfAction,currentAction)

                    // now reassign overwritted object to the currentAction
                    currentAction = copyOfAction
                    
                    if(currentAction.text === 'suspend'){
                        if(this.selectedArray[0][currentAction.optionKey] === 'Proof')
                            var hasConfirmation = _.find(confirmationObjMapping, ['actionName', 'suspend_proof'])
                        else{
                            var hasConfirmation = _.find(confirmationObjMapping, ['actionName', 'suspend_final'])
                            hasConfirmation['isConfirmationForJobMgmnt'] = true
                        }
                    }
                    else if(currentAction.text === 'CreatePackage'){
                        if(this.selectedArray.length > 1){
                            var hasConfirmation = _.find(confirmationObjMapping, ['actionName', currentAction.text])
                            hasConfirmation['isConfirmationForJobMgmnt'] = true
                        }else{
                            currentAction.showConfirmation = false
                        }
                    }
                    else
                        var hasConfirmation = _.find(confirmationObjMapping, ['actionName', currentAction.text])
                    if(hasConfirmation)
                        Object.assign(currentAction, hasConfirmation)
                    if(currentAction.displayText)
                        currentAction.actionTitle = currentAction.displayText
                    actionList.push(currentAction)
                }
                return actionList
            },
            isJustOneMoreInFirstBatch(){
                return this.availableActions.length <= this.actionInFirstBatch+1
            },
            showMoreOptions(){
                return this.availableActions.length > this.actionInFirstBatch && !this.isJustOneMoreInFirstBatch
            }
        },
        watch:{
            'classifiedActions':{
                handler(newValue){

                }
            },
            'selectedArray':{
                handler(newValue){
                    
                }
            }
        },
        mounted() {
            // console.log('in mou?nted=>',this.classifiedActions)  
        },
        methods: {
            createEvent(selectedType, eventName, defaultValue){
                if(eventName === 'onCancelEditing')
                    this.$emit(eventName, this.selectedArray[0])
                else
                    this.$emit('createEvent',selectedType, eventName, defaultValue)
            }
        },
    }
</script>