<template>
  <div>
    <v-layout
      row style="margin:0px;padding:0px;"
      wrap
      pl-4
      class="breadcrumbBackground"
    >
      <v-flex
        xs8
        style="margin:auto"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            pl-3
          >
            <ul class="breadcrumb">
              <li>Manage Migration</li>
            </ul>
          </v-flex>
        </v-layout>                
      </v-flex>
      <v-flex
        xs4
        text-xs-right
        pr-4
        pb-1
        style=""
      />
    </v-layout>
    <v-flex xs12>
      <v-layout
        row
        wrap
      >
        
        <v-flex xs12  style="margin:0px;padding:0px;">
          <v-stepper v-model="steper_index">
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="steper_index > 1"
                editable
              >
                Configuration
              </v-stepper-step>

              <v-stepper-step
                step="2"
                :complete="steper_index > 2"
                editable
              >
                Non Environment Based Data Transfer
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                step="3"
                :complete="steper_index > 3"
                editable
              >
                Environment Settings
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                step="4"
                :complete="steper_index > 4"
                editable
              >
                Environment Based Data Transfer
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items style="margin:0px;padding:0px;">
              <v-stepper-content step="1">
                <v-card style="margin-top:16px;margin-bottom:16px;"
                  height="400px"
                >
                <v-row class="EDC-Row" >
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-radio-group v-model="config_type" required hide-details row  class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in config_type_list" :key="index" :label="n.name" :value="n.id" dense></v-radio>
                          </v-radio-group>
                </v-col>
                </v-row>
                <v-col cols="12" v-if="config_type=='file'" class="EDC-Col">
                <v-row class="EDC-Row" style="padding-top:20px !important">
                   <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                     <label class="v-label" style="float:left" dense>Select XML File</label>
                   </v-col>
                   <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <label>File
                        <input
                          id="xmlfile"
                          ref="xmlfile"
                          type="file"
                          accept=".xml"
                          @change="uploadXMLFile()"
                        >
                      </label>
                  </v-col>
                </v-row>
                <v-row class="EDC-Row">
                   <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                    <label class="v-label" style="float:left" dense>Select Properties File</label>
                   </v-col>
                   <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                    <label>File
                        <input
                          id="file"
                          ref="file"
                          type="file"
                          accept=".properties"
                          @change="uploadConfigeFile()"
                        >
                      </label>
                  </v-col>
                </v-row>
                <v-row class="EDC-Row">
                   <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                    <vc-button
                        type="button"
                        item-text="Submit Files"
                        @click.native="submitFile"
                      />
                   </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" v-if="config_type=='manuel'" class="EDC-Col">
                <v-form ref="form" v-model="valid">
                <v-row class="EDC-Row" >
                   <!-- <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <label class="v-label" style="float:left" dense>Fill Config Details Manually</label>
                </v-col> -->
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                  <label class="v-label" style="float:left;font-weight: bold;" dense>Source Database Details</label>
                </v-col>
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                  <label class="v-label" style="float:left" dense>Select Database Type</label>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                <v-radio-group v-model="source_database_type" required hide-details row  class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in database_type_list" :key="index" :label="n.name" :value="n.id" dense></v-radio>
                          </v-radio-group>
                </v-col>
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Host Name" v-model="source_host_name"  :rules="hostNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Database Port" v-model="source_database_port" :rules="portRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Database Name" v-model="source_database_name"  :rules="databaseNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="User Name" v-model="source_user_name"   :rules="userNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Passowrd" v-model="source_password" :append-icon="show2 ? 'visibility_off' : 'visibility'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" required :rules="passwordRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Schema Name" v-model="source_schema" :rules="schemaNameRules" dense></v-text-field>
                </v-col>
                </v-row>
                <v-row class="EDC-Row" >
                <!-- <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="User Name"  :rules="firstNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Passowrd"  :rules="firstNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Schema Name"  :rules="firstNameRules" dense></v-text-field>
                </v-col> -->
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                  <label class="v-label" style="float:left;font-weight: bold;" dense>Catalog Database Details</label>
                </v-col>
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                  <label class="v-label" style="float:left" dense>Select Database Type</label>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                <v-radio-group v-model="catalog_database_type" required hide-details row  class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in database_type_list" :key="index" :label="n.name" :value="n.id" dense></v-radio>
                          </v-radio-group>
                </v-col>
                </v-row>
                <v-row class="EDC-Row" >
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Host Name" v-model="catalog_host_name" :rules="hostNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Database Port" v-model="catalog_database_port" :rules="portRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Database Name" v-model="catalog_database_name" :rules="databaseNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="User Name" v-model="catalog_user_name" :rules="userNameRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Passowrd" v-model="catalog_password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" required :rules="passwordRules" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="padding-top:20px !important">
                   <v-text-field label="Schema Name"  v-model="catalog_schema" :rules="schemaNameRules" dense></v-text-field>
                </v-col>
                </v-row>
                <v-row class="EDC-Row">
                   <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
                    <vc-button
                        type="button"
                        item-text="Submit Details"
                        @click.native="submitManuelDetails"
                      />
                   </v-col>
                </v-row>
                </v-form>
                </v-col>
                  <!-- <v-layout
                    style="margin-left:1%;"
                    row
                    wrap
                  >
                    <v-flex
                      text-sm-left
                      xs2
                    >
                     
                    </v-flex>
                    <v-flex xs3>
                      <label>File
                        <input
                          id="xmlfile"
                          ref="xmlfile"
                          type="file"
                          accept=".xml"
                          @change="uploadXMLFile()"
                        >
                      </label> -->

                      <!--  <vc-button type="button"  itemText="Submit File" @click.native="submitFile"></vc-button> -->
                    <!-- </v-flex>
                  </v-layout> -->

                  <!-- <v-layout
                    style="margin-left:1%;"
                    row
                    wrap
                  >
                    <v-flex
                      text-sm-left
                      xs2
                    >
                      Select Properties File
                    </v-flex>
                    <v-flex xs3>
                      <label>File
                        <input
                          id="file"
                          ref="file"
                          type="file"
                          accept=".properties"
                          @change="uploadConfigeFile()"
                        >
                      </label>
                    </v-flex>
                    <v-flex xs2 />
                  </v-layout>
                    
                  <v-layout>
                    <v-flex xs2 />
                    <v-flex xs2>
                      <vc-button
                        type="button"
                        item-text="Submit Files"
                        @click.native="submitFile"
                      />
                    </v-flex>
                  </v-layout> -->
                </v-card>
                <v-layout
                  row style="margin:8px !important;"
                  wrap
                >
                  <v-flex xs8 />
                  <v-flex xs4>
                    <vc-button
                      type="button"
                      :loading="reset_all_loading"
                      item-text="Reset All"
                      @click.native="ResetAllNonEnvData('all')"
                    />
                    <vc-button
                      type="button"
                      item-text="Continue"
                      @click.native="nextStep(1)"
                    />
                  </v-flex>
                </v-layout>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card
                  style="margin-top:20px;margin-bottom:16px;"
                  height="320px"
                >
                <v-layout
                    row
                    wrap
                  >
                  <v-flex xs12 v-if="message_to_display">
                    <span style="float:left;padding-left:16px;padding-bottom:8px;font-size:13px;"><b>{{message_to_display}}</b></span>
                  </v-flex>
                </v-layout>
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs2>
                      Migrate Users
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="user_progress_value === 0"
                        type="button"
                        item-text="Start"
                        @click.native="performUserOperation('start')"
                      />
                      <vc-button
                        v-else
                        type="button"
                        item-text="Stop"
                        :disabled="user_progress_value === 100"
                        @click.native="performUserOperation('stop')"
                      />
                      <vc-button
                        v-if="user_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('users')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="user_progress_value"
                        color="teal"
                      >
                        {{ user_running_count }}
                      </v-progress-circular>
                    </v-flex>

                    <v-flex xs2>
                      Migrate Roles
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="role_progress_value === 0 && user_progress_value === 100"
                        type="button"
                        item-text="Start"
                        @click.native="performRoleOperation('start')"
                      />
                      <vc-button
                        v-else-if="role_progress_value != 0"
                        type="button"
                        item-text="Stop"
                        :disabled="role_progress_value === 100"
                        @click.native="performRoleOperation('stop')"
                      />
                      <vc-button
                        v-if="role_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('security_role')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="role_progress_value"
                        color="teal"
                      >
                        {{ role_running_count }}
                      </v-progress-circular>
                    </v-flex>
                  </v-layout>


                  <v-layout
                    row
                    wrap
                    style="margin-top:2%;"
                  >
                    <v-flex xs2>
                      Migrate Datasources
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="role_progress_value === 100 && ds_progress_value === 0"
                        type="button"
                        item-text="Start"
                        @click.native="performUserOperation('start')"
                      />
                      <vc-button
                        v-else-if="ds_progress_value != 0"
                        type="button"
                        item-text="Stop"
                        :disabled="ds_progress_value === 100"
                        @click.native="performUserOperation('stop')"
                      />
                      <vc-button
                        v-if="ds_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('datasource')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="ds_progress_value"
                        color="teal"
                      >
                        {{ ds_running_count }}
                      </v-progress-circular>
                    </v-flex>

                    <v-flex xs2>
                      Migrate Environment
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="env_progress_value === 0 && ds_progress_value === 100"
                        type="button"
                        item-text="Start"
                        @click.native="performEnvOperation('start')"
                      />
                      <vc-button
                        v-else-if="env_progress_value != 0"
                        type="button"
                        item-text="Stop"
                        :disabled="env_progress_value === 100"
                        @click.native="performEnvOperation('stop')"
                      />
                      <vc-button
                        v-if="env_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('environments')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="env_progress_value"
                        color="teal"
                      >
                        {{ env_running_count }}
                      </v-progress-circular>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout row wrap style="margin-top:2%;">
                      <v-flex xs8></v-flex>
                      <v-flex xs4>
                      <vc-button type="button" itemText="Reset Non Env Base Data" @click.native="ResetAllNonEnvData('all')"></vc-button>
                    </v-flex>
                    </v-layout> -->
                </v-card>

                <v-layout
                  row style="margin:8px !important;"
                  wrap
                >
                  <v-flex xs8 />
                  <v-flex xs4>
                    <vc-button
                      type="button"
                      :loading="reset_all_loading"
                      item-text="Reset All"
                      @click.native="ResetAllNonEnvData('all')"
                    />
                    <vc-button
                      type="button"
                      item-text="Continue"
                      @click.native="nextStep(2)"
                    />
                  </v-flex>
                </v-layout>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card
                style="margin-top:20px;margin-bottom:16px;"
                  height="320px"
                >
                  <v-layout
                    row
                    wrap
                    style="margin-left:1%;"
                  >
                    <v-flex
                      text-sm-left
                      xs3
                    >
                      <label
                        class="v-label"
                        style="margin-top: 5%;"
                      >Environment Type:</label>
                    </v-flex>
                    <v-flex xs9>
                      <v-radio-group
                        v-model="databaseType"
                        row 
                        hide-details
                        @change="loadDS"
                      >
                        <v-radio
                          v-for="db in databaseTypes"
                          :key="db.id"
                          :label="db.name"
                          :value="db.id"
                          :color="colorCode"
                          class="label-margin"
                        />
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    style="margin-left:1%;"
                  >
                    <v-flex
                      text-sm-left
                      xs2
                    >
                      <label
                        class="v-label"
                        style="margin-top: 5%;"
                      >Select Test Env:</label>
                    </v-flex>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="test_env"
                        clearable
                        autocomplete
                        :items="test_env_list"
                        item-text="name"
                        item-value="id"
                        label="Select Test Env"
                        style="margin-right:10px; margin-top:6px;"
                      />
                    </v-flex>

                    <v-flex
                      text-sm-left
                      xs2
                    >
                      <label
                        class="v-label"
                        style="margin-top: 5%;"
                      >Select Production Env:</label>
                    </v-flex>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="prod_env"
                        clearable
                        autocomplete
                        :items="prod_env_list"
                        item-text="name"
                        item-value="id"
                        label="Select Production Env"
                        style="margin-right:10px; margin-top:6px;"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    style="margin-left:1%;"
                  >
                    <v-flex
                      text-sm-left
                      xs2
                    >
                      <label
                        class="v-label"
                        style="margin-top: 5%;"
                      >Select Sum Test Env:</label>
                    </v-flex>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="sum_test_env"
                        clearable
                        autocomplete
                        :items="test_env_list"
                        item-text="name"
                        item-value="id"
                        label="Select Sum Test Env"
                        style="margin-right:10px; margin-top:6px;"
                      />
                    </v-flex>
                        
                    <v-flex
                      text-sm-left
                      xs2
                    >
                      <label
                        class="v-label"
                        style="margin-top: 5%;"
                      >Select Sum Production Env:</label>
                    </v-flex>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="sum_pro_env"
                        clearable
                        autocomplete
                        :items="prod_env_list"
                        item-text="name"
                        item-value="id"
                        label="Select Sum Production Env"
                        style="margin-right:10px; margin-top:6px;"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs9 />
                    <v-flex xs3>
                      <vc-button
                        type="button"
                        item-text="Save"
                        @click.native="AddEnvMigrationSettings"
                      />
                      <!-- <vc-button type="button" itemText="Reset"></vc-button> -->
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-layout
                  row style="margin:8px !important;"
                  wrap
                >
                  <v-flex xs8 />
                  <v-flex xs4>
                    <vc-button
                      type="button"
                      :loading="reset_all_loading"
                      item-text="Reset All"
                      @click.native="ResetAllNonEnvData('all')"
                    />
                    <vc-button
                      type="button"
                      item-text="Continue"
                      @click.native="nextStep(3)"
                    />
                  </v-flex>
                </v-layout>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card
                style="margin-top:20px;margin-bottom:16px;"
                  height="320px"
                >
                <v-layout
                    row
                    wrap
                  >
                  <v-flex xs12 v-if="message_to_display">
                    <span style="float:left;padding-left:16px;padding-bottom:8px;font-size:13px;"><b>{{message_to_display}}</b></span>
                  </v-flex>
                </v-layout>
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs2>
                      Migrate PDs
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="pd_progress_value === 0"
                        type="button"
                        item-text="Start"
                        @click.native="performPDOperation('start')"
                      />
                      <vc-button
                        v-else
                        type="button"
                        item-text="Stop"
                        :disabled="pd_progress_value === 100"
                        @click.native="performPDOperation('stop')"
                      />
                      <vc-button
                        v-if="pd_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('process_definition')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="pd_progress_value"
                        color="teal"
                      >
                        {{ pd_running_count }}
                      </v-progress-circular>
                    </v-flex>

                    <v-flex xs2>
                      Migrate Workflow
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="workflow_progress_value === 0 && pd_progress_value === 100"
                        type="button"
                        item-text="Start"
                        @click.native="performWKOperation('start')"
                      />
                      <vc-button
                        v-else-if="workflow_progress_value != 0"
                        type="button"
                        item-text="Stop"
                        :disabled="workflow_progress_value === 100"
                        @click.native="performWKOperation('stop')"
                      />
                      <vc-button
                        v-if="workflow_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('workflow')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="workflow_progress_value"
                        color="teal"
                      >
                        {{ workflow_running_count }}
                      </v-progress-circular>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    style="margin-top:2%;"
                  >
                    <v-flex xs2>
                      Migrate Engine
                    </v-flex>
                    <v-flex xs2>
                      <vc-button
                        v-if="workflow_progress_value === 100 && engine_progress_value === 0"
                        type="button"
                        item-text="Start"
                        @click.native="performEngineOperation('start')"
                      />
                      <vc-button
                        v-else-if="engine_progress_value != 0"
                        type="button"
                        item-text="Stop"
                        :disabled="engine_progress_value === 100"
                        @click.native="performEngineOperation('stop')"
                      />

                      <vc-button
                        if="engine_progress_value != 0"
                        item-text="Resume Job"
                        @click.native="performResumeJob()"
                      />

                      <!-- <v-text-field v-model="jobs_for_sql" 
                                    placeholder="comma seprated job ids" solo hide-details></v-text-field>

                      <vc-button
                        if="engine_progress_value != 0"
                        item-text="Migrate Sql" :loading="btn_loading"
                        @click.native="performSqlMigration()"
                      /> -->

                      <vc-button
                        v-if="engine_progress_value === 100"
                        item-text="Reset"
                        @click.native="ResetAllNonEnvData('engine')"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="progress_circle_width"
                        :value="engine_progress_value"
                        color="teal"
                      >
                        {{ engine_running_count }}
                      </v-progress-circular>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    style="margin-top:2%;"
                  >
                    <v-flex xs8 />
                    <v-flex xs4>
                      <vc-button
                        type="button"
                        item-text="Reset Env Base Data"
                        @click.native="ResetAllNonEnvData('env_wise_section')"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-layout
                  row
                  wrap style="margin:8px !important;"
                >
                  <v-flex xs8 />
                  <v-flex xs4>
                    <vc-button 
                      type="button" 
                      :loading="reset_all_loading"
                      item-text="Reset All"
                      @click.native="ResetAllNonEnvData('all')"
                    />
                    <vc-button
                      type="button"
                      item-text="Continue"
                      @click.native="nextStep(4)"
                    />
                  </v-flex>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-flex>
    <simplert
      ref="simplert"
      :use-radius="true"
      :use-icon="true"
    />
  </div>
</template>
<script>
import Simplert from 'vue2-simplert'
import moment from 'moment';
import _ from 'lodash';
import config from '../../config.json'
import { fileUpload, get as getToServer, deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import vcButton from '@/views/button.vue'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import {MANAGEPROPERYFILE} from '../../data/url_constants.js'



export default {
      components:{
        'vc-button':vcButton,
        Simplert
      },
      data () {
        return {
          jobs_for_sql:'',
          colorCode:COLOR_CODE,
          steper_index:1,
          running_steper_index:1,
          total_step:4,
          user_progress_value:0,
          user_process_timer:null,
          role_progress_value:0,
          role_process_timer:null,
          progress_circle_width:15,
          ds_progress_value:0,
          ds_process_timer:null,
          env_progress_value:0,
          env_process_timer:null,
          databaseType: "ms_sql",
          databaseTypes:[{"id":"oracle","name":"Oracle"},
                          {"id":"ms_sql","name":"MS Sql"},
                          {"id":"db2i","name":"DB2i"},
                          {"id":"db2luw","name":"DB2LUW"}
                    ],
          test_env_list: [],
          prod_env_list: [],
          show1: false,
          show2: false,
          database_type_list:[{id:"ms_sql",name:"MS-SQL"},
                              {id:"oracle",name:"Oracle"},
                              {id:"db2",name:"DB2 LUW"},
                              {id:"db2i",name:"DB2 i-series"}],
          config_type:"file",                            
          config_type_list:[{id:"file",name:"Upload Config Files"},
                              {id:"manuel",name:"Fill Details Manually"}],                              
          test_env:'',
          prod_env:'',
          sum_pro_env:'',
          sum_test_env:'',
          pd_progress_value:0,
          workflow_progress_value:0,
          engine_progress_value:0,
          reset_all_loading:false,
          confige_file:'',
          xml_file:'',
          user_running_count:'0/0',
          role_running_count:'0/0',
          ds_running_count:'0/0',
          env_running_count:'0/0',
          pd_running_count:'0/0',
          workflow_running_count:'0/0',
          engine_running_count:'0/0',
          btn_loading:false,
          source_database_type:"oracle",
          source_host_name:'',
          source_database_port:'',
          source_database_name:'',
          source_user_name:'',
          source_password:'',
          source_schema:'',
          catalog_database_type:"oracle",
          catalog_host_name:'',
          catalog_database_port:'',
          catalog_database_name:'',
          catalog_user_name:'',
          catalog_password:'',
          catalog_schema:'',
          message_to_display:'',
          hostNameRules: [v => !!v || 'Host Name is required.'],
          portRules:  [v => !!v || 'Port is required.',
                              v => v.length <= 6 || 'Port must be 4 digit'],
          databaseNameRules: [v => !!v || 'Database Name is required.',
                              v => /^[a-zA-Z0-9-_\. ]+$/.test(v) || 'Database Name must be valid'],
          userNameRules: [v => !!v || 'User Name is required.'],
          passwordRules: [v => !!v || 'Password is required.'],
          schemaNameRules: [v => !!v || 'Schema Name is required.',
                              v => /^[a-zA-Z0-9-_ ]+$/.test(v) || 'Schema Name must be valid'],
        }
      },
      watch:{
        '$store.state.migrationInfo':{
        handler(newValue){
          this.manageStatus(newValue)
          }
        },
      },
      mounted(){
        this.loadDS()
        this.GetMigrationStatus()
        this.GetEnvSettings()
      },
      methods:{
        nextStep(running_steper_index){
          if(running_steper_index+1 > this.total_step)
            this.steper_index = 1
          else
            ++this.steper_index
        },
        performUserOperation(action){
          if(action === 'start'){
              var data = {"client_id":this.$session.get("client_id")}
              var url = config.MIGRATION_SERVICE_URL +'/migrate_user_role_ds_env'
              this.user_progress_value = 0.001
              postToServer(this, url,data).then(response_data => {
               
              }).catch(error_response => {
                this.user_progress_value = 0
            });
          }
          else{
            clearInterval(this.user_process_timer)
            this.user_progress_value = 0
          }
        },
        performRoleOperation(action){
          clearInterval(this.user_process_timer)
          if(action === 'start'){
              var data = {"client_id":this.$session.get("client_id")}
              var url = config.MIGRATION_SERVICE_URL +'/migrate_security_role_master'
              this.role_progress_value = 0.001
              postToServer(this, url,data).then(response_data => {
               // this.performDsOperation('start')
              }).catch(error_response => {
                this.role_progress_value = 0
            });
          }
          else{
            clearInterval(this.role_process_timer)
            this.role_progress_value = 0
          }
        },
        performDsOperation(action){
          if(action === 'start'){
            var data = {"client_id":this.$session.get("client_id")}
              var url = config.MIGRATION_SERVICE_URL +'/migrate_datasource'
              this.ds_progress_value = 0.001
              postToServer(this, url,data).then(response_data => {
               // this.performEnvOperation('start')
              }).catch(error_response => {
                this.ds_progress_value = 0
            });
          }
          else{
            clearInterval(this.ds_process_timer)
            this.ds_progress_value = 0
          }
        },
        performEnvOperation(action){
          if(action === 'start'){
            var data = {"client_id":this.$session.get("client_id")}
            var url = config.MIGRATION_SERVICE_URL +'/migrate_environment'
            this.env_progress_value = 0.001
            postToServer(this, url,data).then(response_data => {
            
            }).catch(error_response => {
              this.env_progress_value = 0
            });
          }
          else{
            clearInterval(this.env_process_timer)
            this.env_progress_value = 0
          }
        },
        performPDOperation(action){
          if(action === 'start'){
            this.pd_progress_value = 0.001
            var data = {"client_id":this.$session.get("client_id")}
            var url = config.MIGRATION_SERVICE_URL +'/migrate_pd_wf_engine'
            postToServer(this, url,data).then(response_data => {
             // this.performWKOperation('start')
            }).catch(error_response => {
              this.env_progress_value = 0
            });
          }
          else{
            clearInterval(this.env_process_timer)
            this.env_progress_value = 0
          }
        },
        performWKOperation(action){
          if(action === 'start'){
            var data = {"client_id":this.$session.get("client_id")}
            var url = config.MIGRATION_SERVICE_URL +'/migrate_workflow_data'
            this.workflow_progress_value = 0.001
            postToServer(this, url,data).then(response_data => {
             // this.performEngineOperation('start')
            }).catch(error_response => {
              this.workflow_progress_value = 0
            });
          }
          else{
            clearInterval(this.env_process_timer)
            this.env_progress_value = 0
          }
        },
        performResumeJob(){
          var data = {"client_id":this.$session.get("client_id")}
            var url = config.MIGRATION_SERVICE_URL +'/resume_job_migration'
            postToServer(this, url,data).then(response_data => {
             // this.performEngineOperation('start')
            }).catch(error_response => {
              // alert('error',error_response)
            });
        },
        submitManuelDetails(){
             var _this = this
           if (this.$refs.form.validate()) {
             var data = {'property_data':{'source_database_details':{'database_type':_this.source_database_type,
          'host_name':_this.source_host_name,
          'database_port':_this.source_database_port,
          'database_name':_this.source_database_name,
          'user_name':_this.source_user_name,
          'password':_this.source_password,
          'schema_name':_this.source_schema,},
          'catalog_database_details':{'database_type':_this.catalog_database_type,
          'host_name':_this.catalog_host_name,
          'database_port':_this.catalog_database_port,
          'database_name':_this.catalog_database_name,
          'user_name':_this.catalog_user_name,
          'password':_this.catalog_password,
          'schema_name':_this.catalog_schema,}}}
           }
          var url = config.MIGRATION_SERVICE_URL +'/save_properties'
          postToServer(this, url,data).then(response_data => {
             _this.message_to_display = 'Data saved successfully. Please click on the below start button to proceed.'
              _this.steper_index = 2
            }).catch(error_response => {
              _this.message_to_display = "Failed to save the data. Please check logs for more details"
            });
        },
        performEngineOperation(action){
          if(action === 'start'){
            this.engine_progress_value = 0.001
            var data = {"client_id":this.$session.get("client_id")}
            var url = config.MIGRATION_SERVICE_URL +'/migrate_engine_data'
            this.env_progress_value = 0.001
            postToServer(this, url,data).then(response_data => {
            }).catch(error_response => {
              this.engine_progress_value = 0
            });
          }
          else{
            clearInterval(this.env_process_timer)
            this.env_progress_value = 0
          }
        },
        loadDS(value){
          this.test_env_list = []
          this.prod_env_list = []
          this.test_env = ''
          this.prod_env = ''
          this.sum_pro_env = ''
          this.sum_test_env = ''
         this.getAllEnvs('test')
         this.getAllEnvs('production')
        },
        getAllEnvs(type){
          var url = config.MIGRATION_SERVICE_URL +'/get_environment_by_type'
          this.test_env_list = []
          this.prod_env_list = []
          this.test_env = ''
          this.prod_env = ''
          this.sum_test_env = ''
          this.sum_pro_env = ''
          var data = {"env_type":type,"db_type":this.databaseType}
          postToServer(this, url,data).then(response_data => {
             if(response_data){
                if(type === 'test'){
                  this.test_env_list = response_data
                }
                else
                  this.prod_env_list = response_data
             }
            }).catch(error_response => {
              
          });
        },
        AddEnvMigrationSettings(){
          var url = config.MIGRATION_SERVICE_URL +'/manage_env_migration_settings'


          // if(!this.test_env || !this.prod_env || !this.sum_test_env || !this.sum_pro_env || this.test_env === this.prod_env || this.test_env === this.sum_test_env || this.test_env === this.sum_pro_env || this.prod_env === this.sum_test_env || this.prod_env === this.sum_pro_env || this.sum_test_env === this.sum_pro_env)
          if (!this.test_env && !this.prod_env){
            alert('Alteast select test or production environment to proceed')
            return
          }
          var data = {
                    "client_id": this.$session.get('client_id'),
                    "db_type": this.databaseType,
                    "test_env": this.test_env,
                    "prod_env": this.prod_env,
                    "summarize_test_env": this.sum_test_env,
                    "summarize_prod_env": this.sum_pro_env
            }
          postToServer(this, url,data).then(response_data => {
             this.steper_index = 4
             this.performPDOperation('start')
            }).catch(error_response => {
              
          });
        },
        GetEnvSettings(){
          var url = config.MIGRATION_SERVICE_URL +'/get_env_migration_settings'
          var data = {
                    "client_id": this.$session.get('client_id')
            }
          postToServer(this, url,data).then(response_data => {
             if(response_data){
                response_data = response_data[0]
                this.databaseType = response_data.db_type
                this.test_env = response_data.test_env
                this.prod_env = response_data.prod_env
                this.sum_test_env = response_data.summarize_test_env
                this.sum_pro_env = response_data.summarize_prod_env
             }
            }).catch(error_response => {
              
          });
        },
        GetMigrationStatus(){
          var url = config.MIGRATION_SERVICE_URL +'/get_migration_status'
          var data = {
                    "client_id": this.$session.get('client_id')
            }
          postToServer(this, url,data).then(response_data => {
             if(response_data){
              this.user_progress_value = 0
              this.role_progress_value = 0
              this.ds_progress_value = 0
              this.env_progress_value = 0
              this.pd_progress_value = 0
              this.workflow_progress_value = 0
              this.engine_progress_value = 0

              this.user_running_count = '0/0'
              this.role_running_count = '0/0'
              this.ds_running_count = '0/0'
              this.engine_running_count = '0/0'
              this.workflow_running_count = '0/0'
              this.engine_running_count = '0/0'


              this.steper_index = 1
                for (var i = 0; i < response_data.length; i++) {
                  var current_row = response_data[i]
                  if(current_row.data_type === 'users'){
                    if(current_row.status === 'completed')
                      this.user_progress_value = 100
                    this.user_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'security_role'){
                    if(current_row.status === 'completed')
                      this.role_progress_value = 100
                    this.role_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'datasource'){
                    if(current_row.status === 'completed')
                      this.ds_progress_value = 100
                    this.ds_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'environments'){
                    if(current_row.status === 'completed')
                      this.env_progress_value = 100
                    this.env_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'process_definition'){
                    if(current_row.status === 'completed')
                      this.pd_progress_value = 100
                    this.pd_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'workflow'){
                    if(current_row.status === 'completed')
                      this.workflow_progress_value = 100
                    this.workflow_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  else if(current_row.data_type === 'engine'){
                    if(current_row.status === 'completed')
                      this.engine_progress_value = 100
                    this.engine_running_count = current_row.current_running_count +"/"+current_row.total_count
                  }
                  if(current_row.status === 'running'){
                    current_row.mg_type = current_row.data_type
                    current_row.running_count = current_row.current_running_count
                    this.manageStatus(current_row)
                  }

                }
             }
            }).catch(error_response => {
              
          });
        },
        ResetAllNonEnvData(type){
          var url = config.MIGRATION_SERVICE_URL +'/reset_migration_data'
          if(type === 'all')
            this.reset_all_loading = true
          var data = {"reset_data":type,"client_id":this.$session.get('client_id')}
          if (type === 'users')
            data = {"reset_data":'all'}  
          else if (type === 'datasource')
            data = {"reset_data":["datasource","environments","process_definition","workflow","engine"]}
          else if (type === 'environments')
            data = {"reset_data":["environments","process_definition","workflow","engine"]}
          else if (type === 'process_definition')
            data = {"reset_data":["process_definition","workflow","engine"]}
          else if (type === 'workflow')
            data = {"reset_data":'workflow'}
          else if (type === 'engine')
            data = {"reset_data":"engine"}
          else if (type === 'env_wise_section'){
            data = {"reset_data":["process_definition","workflow","engine"]}
          }
          postToServer(this, url,data).then(response_data => {
            this.reset_all_loading = false
            this.GetMigrationStatus();
            }).catch(error_response => {
              this.reset_all_loading = false
          });
        },
        manageStatus(data){
          var mg_type = data.mg_type
          var running_count = data.running_count
          var total_count = data.total_count
          
          if(data.message){
            this.message_to_display = data.message
            return
          }

          if(running_count === 0 || !running_count)
            return
          var running_status = running_count +'/'+total_count
          var completed_percentage  = (running_count/total_count) * 100
          if(completed_percentage < 0.001)
            completed_percentage = 0.001
          if (completed_percentage !== 100)
            completed_percentage = completed_percentage.toFixed(3)
          if(mg_type === 'users'){
            this.user_progress_value = completed_percentage
            this.user_running_count = running_status
            if(completed_percentage === 100){
              // this.performRoleOperation('start')
            }
          }
          else if(mg_type === 'security_role'){
            this.role_progress_value = completed_percentage
            this.role_running_count = running_status
            if(completed_percentage === 100){
              // this.performDsOperation('start')
            }
          }
          else if(mg_type === 'datasource'){
            this.ds_progress_value = completed_percentage
            this.ds_running_count = running_status
            if(completed_percentage === 100){
              // this.performEnvOperation('start')
            }
          }
          else if(mg_type === 'environments'){
            this.env_progress_value = completed_percentage
            this.env_running_count = running_status
              if(completed_percentage === 100){
                this.loadDS()
                this.steper_index = 3
             }
          }
          else if(mg_type === 'process_definition'){
            this.pd_progress_value = completed_percentage
            this.pd_running_count = running_status
            if(completed_percentage === 100){
              // this.performWKOperation('start')
            }
          }
          else if(mg_type === 'workflow'){
            this.workflow_progress_value = completed_percentage
            this.workflow_running_count = running_status
            if(completed_percentage === 100){
              // this.performEngineOperation('start')
            }
          }
          else if(mg_type === 'engine'){
            this.engine_progress_value = completed_percentage
            this.engine_running_count = running_status
          }
        },
        uploadXMLFile(){
          this.xml_file = this.$refs.xmlfile.files[0];
        },
        uploadConfigeFile(){
          this.confige_file = this.$refs.file.files[0];
        },
        submitFile(){
          var _this = this
          if(!this.xml_file || !this.confige_file){
            alert('Select both the files')
            return
          }
          let formData = new FormData();
          formData.append('xmlfilename', this.xml_file);
          formData.append('propfilename', this.confige_file);
          formData.append('client_id',  this.$session.get('client_id'));
          formData.append('added_by', this.$session.get('email'));
          fileUpload(this, config.MIGRATION_SERVICE_URL + MANAGEPROPERYFILE, formData).then(response  => {
              _this.message_to_display = 'Files uploded successfully. Please click on the below start button to proceed.'
              _this.steper_index = 2
            }).catch(error_response => {
              _this.message_to_display = "Failed to upload the files. Please check the logs for more details."
            })
        },
        ShowConfirm(){
            let confirmFn = function () {
            this.$parent.FileUploadConfirm()
            }
            let obj = {
              title: 'Confirm',
              message: 'Configuration data get inserted successfully. Do you want to proceed with migration ?',
              type: 'info',
              useConfirmBtn: true,
              onConfirm: confirmFn
            }
            this.$refs.simplert.openSimplert(obj)
          },
        FileUploadConfirm(){

        },
        performSqlMigration(){
          if(!this.jobs_for_sql){
            alert('atleast input one job id')
            return
          }  
          let _this = this
          var data = {"client_id":this.$session.get("client_id"),'jobs':this.jobs_for_sql}
          _this.btn_loading = true
          var url = config.MIGRATION_SERVICE_URL +'/update_sql_logs_by_jobs'
          postToServer(this, url,data).then(response_data => {
           alert('successfully inserted')
           _this.btn_loading = false
          }).catch(error_response => {
            alert('error',error_response)
            _this.btn_loading = false
          });

        }
      }
    }
</script>