
export const SUMMARIZE_F0911_TYPE = "Jde_summ_f0911"
export const F0911_CATALOG_TABLE_NAME = "TBF_F0911"
export const F0911_CATALOG_D_TABLE_NAME = "TDB_F0911"
export const F0911_CATALOG_COLUMN_LIST = ["UNIQUE_ID", "GLKCO", "GLDCT", "GLDOC", "GLDGJ", "GLJELN", "GLLT", "GLEXTL", "GLAA"]
export const F0911_D_CATALOG_COLUMN_LIST = ["UNIQUE_ID", "GLKCO", "GLDCT", "GLDOC", "GLDGJ", "GLJELN", "GLLT", "GLEXTL"]

export const SUMMARIZE_F4111_TYPE = "Jde_summ_f4111"
export const F4111_CATALOG_TABLE_NAME = "TBF_F4111"
export const F4111_CATALOG_D_TABLE_NAME = "TDB_F4111"
export const F4111_KEY_CATALOG_TABLE_NAME = "TKEY_F4111"
export const F4111_CATALOG_COLUMN_LIST = ["UNIQUE_ID", "ILUKID",  "ILKCO", "ILMCU", "ILITM", "ILLITM", "ILAITM", "ILLOCN", "ILLOTN", "ILGLPT", "ILTRUM", "ILIPCD", "ILPAID", "ILTRQT", "ILUNCS"]
export const F4111_D_CATALOG_COLUMN_LIST = ["UNIQUE_ID", "ILUKID"]
