<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/catalogdeletionhistory">Delete Catalog Request List</router-link>
              </li>
              <li>Request Delete</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="EDC-Row">
      <v-col cols="12" class="EDC-Col">
        <edc-data-grid :dataList="tableList"></edc-data-grid>
      </v-col>
    </v-row>
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import config from './../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../../data/macros.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'

export default {
  name: 'DeleterequestDetails',
  components: {
  },
  data: function () {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'success',
      snackbartimeout: 10,
      loader:false,
      tableList: {
        headers: [
        { text: 'Business Object Name', value:'business_object_name',   title:'Business Object Name'},
        { text: 'Related Catalog Name', value:'catalog_table_name', title:'Catalog Table Name'},
        { text: 'Expected Row Count', value:'expected_row',   title:'Expected Row Count'},
        { text: 'Deleted Row Count', value:'process_row', title:'Deleted Row Count'},
        { text: 'Status', value: 'status', title:'Status'},
        { text: 'Business Object Id', value: 'business_object_id', title:'Busienss Object Id'},
        { text: 'Remark', value: 'details', title:'Remark'},
        ],
        actions:[],
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        rows: [],
        selected_rows: [],
        disableDefaltSorting:true,
        hideExport:true,
        hideShowColumns:true,
        itemkey:'id'
      },
    }
  },
  computed:{

  },
  mounted() {
    this.getRequestDetails(this.$route.params.request_id)
  },
  methods: {
    getRequestDetails(request_id){
        var _this = this
      _this.tableList.rows = []
      var client_id = this.$session.get('client_id')
      var data = {
        "request_id":request_id,
        "client_id":client_id
      }
      this.loader =true
      postToServer(this, config.ENGINE_API_URL +'/get_catalog_request_details',data).then(response=>{
        _this.loader = false
        _this.tableList.rows = response
      }).catch(error=>{
        _this.loader = false
      })
    }

    }
}
</script>
<style scoped>
</style>
