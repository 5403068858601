<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li v-if="schedule_id"><router-link to="/scheduledJobList">Schedule Job List</router-link></li>
                            <li v-else><router-link to="" @click.native="CancelSchedule">Create </router-link></li>
                            <li>Schedule {{category}}</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <v-row class="EDC-Row">
            <v-card class="EDC-VCard" elevation-1>
                <v-row class="EDC-Row">
                    <v-col cols="12" class="EDC-Col">
                        <span class="EDC-Heading">Schedule - {{scheduleName}}</span>
                    </v-col>
                </v-row>

                <v-row class="EDC-Row" align="start">
                    <edc-schedule :beginDateLabel="begin_date_label" :edcScheduleObject="jobObject" @saveSchedule="getSaveObject" @cancelSchedule="CancelSchedule" :isOneTimeOnly="isOneTimeOnly"></edc-schedule>
                </v-row>

                <loading-panel :loader="loader"></loading-panel>
                
            </v-card>
        </v-row>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import config from "../../config.json"
import Breadcrumb from "../Breadcrumbs.vue"
import {
    get as getToServer,
    post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../data/client_message.js'
import {
    GET_SCHEDULE,
    SCHEDULE_JOB,
    UPDATE_JOB,
    SCHEDULE_COORDINATION,
    SCHEDULE_JOB_PLAN
} from '../../data/url_constants.js';

import {Snackbar_Default_Timeout} from '@/constants/constants.js'

import edcSchedule from '@/views/edcSchedule/edcSchedule'

export default {
    name: 'Schedule',
    components: {
        'edc-schedule':edcSchedule,
        Breadcrumb
    },
    data() {
        return {
            category:'Job',
            schedule_id:'',
            scheduleName:'',
            disabled: 0,
            snackbar:false,
            snackbartext:'',
            snackbartimeout: Snackbar_Default_Timeout,
            colorValue:'',
            loader:false,
            loading: false,
            schedule_name: "",
            jobObject: {},
            date_label:'Schedule Date',
            begin_date_label:'Beginning Date',
            time_label:'Schedule Time (HH:MM)',
            isOneTimeOnly:false,
            isRequestIsInProgress:false
        }
    },
    mounted() {
        let _this = this;
        if(this.$route.params.schedule_data){
            if(this.$route.params.schedule_data.job_plan_name){
                this.scheduleName = this.$route.params.schedule_data.job_plan_name
            }else{
                this.scheduleName = this.$route.params.schedule_data.process_definition_name
            }
            this.category = this.$route.params.category;
            this.isOneTimeOnly =  this.$route.params.schedule_data.is_one_time_schedule
        }
        

        if (this.$route.params.schedule_id) {
            this.schedule_id = this.$route.params.schedule_id
            this.getSchedule()
        }

        if(this.$route.params.type && this.$route.params.type == 'history' && this.$route.params.job){
            alert('reached here')
          //   this.type = 'history';
          //   this.jobObject = this.$route.params.job;

          //   this.jobObject.schedule_id = this.jobObject.id;
          //   this.jobObject.params.is_recurring = false;
        }
    },
    props: {
        msg: String
    },
    methods: {
        saveData(inputParam) {
            let _this = this;
            _this.loader = true
            if(_this.isRequestIsInProgress)
                return
            
            let url = "";
            if (this.$route.params.schedule_id && this.$route.params.type && this.$route.params.type == 'edit') {
                inputParam.id = this.$route.params.schedule_id;
                url = config.SCHEDULER_URL + UPDATE_JOB;
            } else {
                if(this.category === 'Job')
                url = config.COORDINATOR_API_URL + SCHEDULE_COORDINATION;
                if(this.category === 'Job Plan')
                url = config.JOB_PLAN_URL + SCHEDULE_JOB_PLAN;
            }
            _this.isRequestIsInProgress = true
            postToServer(this, url, inputParam).then(
                    addResponse  => {
                        _this.loader = false
                        this.snackbar = true;
                        if(addResponse.message === 'Successfully call approval process' || addResponse.message ==='Request sent for approval'){
                            this.colorValue = 'info'
                            this.snackbartext = 'Job scheduled request send for approval';
                        }
                        else
                        {
                            this.colorValue = 'success'
                            this.snackbartext = 'Job scheduled successfully'
                        }
                        setTimeout(function () {
                            _this.$router.push('/scheduledJobList');
                        }, 1500);
                    }).catch(error_response => {
                _this.loader=false;
                 _this.isRequestIsInProgress = false
                if(error_response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        getSaveObject(obj) {
            let freshObject = {"schedule_id":this.schedule_id,"params":_.cloneDeep(obj)}
            let _this = this;
            if(this.$route.params.schedule_data){
                freshObject.params = Object.assign(freshObject.params, this.$route.params.schedule_data)
                freshObject.params.service_id = this.$route.params.service_id;
                freshObject.params.end_point = this.$route.params.end_point;
                freshObject.params.category = this.$route.params.category;
            }

            freshObject.params.name = this.scheduleName
            freshObject.params.start_from = freshObject.params.start_date
            freshObject.params.client_id = _this.$session.get('client_id')
            freshObject.params.created_by = _this.$session.get('email')
            
            _this.saveData(freshObject);
        },
        getSchedule() {
            var _this = this
            getToServer(this, config.SCHEDULER_URL + GET_SCHEDULE + this.$route.params.schedule_id).then(response => {
                let inputParam = response.params
                this.scheduleName =  inputParam.name
                this.jobObject = inputParam;
                this.isOneTimeOnly = inputParam.is_one_time_schedule
                
            }).catch(error_response => {

            })
        },
        
        CancelSchedule(){
            // this.$router.push('/process_def_list_for_execution')

            if(this.$route.params.schedule_data && this.$route.params.schedule_data.job_plan_name){
                this.$router.push('/jobplanlist')
            }
            else{

            this.$router.go(-1);
            }
            // this.$emit('closeScheduler')
        },
    },

}
</script>

<style scoped>
 .radioClass >>> label{
    top: 3px !important;
} 
/*.radioClass >>> i{
    font-size: 30px
}*/
.customCheckbox >>> label{
    top: 5px !important;
}
/* .customCheckbox >>> i{
    top: 5px !important;
} */
.pointer{
    cursor: pointer;
}
.pointer >>> span{
    cursor: pointer;
}
.custWidth{
    width: 55px;
}
.customCheckbox >>> label{
    top: 0px !important;
}
.v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
}

.v-divider--vertical {
    min-height:10%;
}
.theme--light.v-divider {
    border-color: rgba(0,0,0,.50);
}

.EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
}

.EDC-VCardTitle {
    background: #494949 !important;
    color: white !important;
    text-align: left !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0px !important;
    height: 30px !important;
}

.EDC-SchRow{
    padding-left:10px !important;
    padding-right:10px !important;
}

.EDC-ColAlignPadding {
    padding-left:32px !important
}

.EDC-ColTimeZone {
    margin-top:4px !important;
}

.EDC-ColRadio {
    padding-top:8px !important;
    margin-left: -3px !important;
}

.EDC-ColRun{
    padding-top:15px !important;
}

.EDC-ColUntil{
    padding-top:10px !important;
}

.EDC-ColMainRadio{
    margin-top:6px !important;
}

.EDC-RadioMargin {
    margin-top: -8px !important;
    margin-left: -3px !important;
}

.EDC-CalenderMargin {
    margin-top: -18px !important;
    padding-left: 10px !important;
}

.EDC-TextfieldMargin {
    padding-left: 10px !important;
}

.EDC-UIPadding {
    padding-right:10px !important;
}

.EDC-ComboboxPadding {
    padding-top: 5px !important;
}

.EDC-Indefinitely {
    margin-top: 12px !important;
}

.EDC-Date {
    margin-top: 18px !important;
}

</style>