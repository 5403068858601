<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid :dataList="tableList" @onDownload="onDownload" :liveStreamRow='newRow' 
                        @onSort="sortBy" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @UpdateFilter="filterData"></edc-data-grid>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
</template>
<style scoped>

</style>
<script>
    import moment from 'moment'
    import {mixinJobManagement} from './../../../mixins/jobmanagementmixin.js'
    import {post as postToServer } from '../../../methods/serverCall.js';
    import config from '../../../config.json'
    import {manageScreenDisplay} from '@/methods/special.js'
    import {GETALLSTEPSDETAIL, EXPORT_STEP_DETAILS} from '../../../data/url_constants.js';

    export default{
        name:"jobLogs",
        mixins:[mixinJobManagement],
        props:{
            jobId:{
                type:Number,
                default:0
            },
            jobMode:{
                type:String,
                default:''
            },
            jobName:{
                type:String,
                default:''
            }
        },
        data(){
            return{
                logsHeader: [
                    { text: 'Log Id', value: 'job_log_id',  options:[],align:"right", title: 'Job Log Id', dataType:'int'},
                    { text: 'Step type', value: 'step_type', options:[], title: 'Job Step Type'},
                    { text: 'Step name', value: 'step_name', options:[], title: 'Job Step Name'},
                    { text: 'Operation', value: 'operation',  title: 'Operation Name'},
                    { text: 'Table', value: 'table_name',  options:[], title: 'Table Name' },
                    { text: 'Count', value: 'log_count', options:[], align:'right', title: 'Step Query Count', validate:'number', dataType:'int'},
                    { text: 'Status' , value: "step_status",  options:[], title: 'Step Status'},
                    {text: 'Time (sec)', value: 'processing_time',  align:'right', title: 'Processing Time In Sec', hideInlineFilter:true},
                    { text: 'Start Date' , value: "start_date",dataType:'date', options:[], title: 'Step Start Date'},
                    { text: 'Start Time' , value: "start_time",dataType:'time', options:[], title: 'Step Start Time', hideInlineFilter:true},
                    { text: 'End Date', value: "end_date",dataType:'date',  options:[], title: 'Step End Date'},
                    {text: 'End Time', value: "end_time",dataType:'time', options:[], title: 'Step End Time', hideInlineFilter:true},
                    { text: 'Log Details', value: "query",  options:[],  title: 'Query',copyToClipboard:true, hideInlineFilter:false}
                ],
                tableList: {
                    headers:[],
                    actions: [],
                    rows:[]
                },
                envIpJson : {
                    "filter": [],
                    "sort": [{"column_name": "job_log_id","type": "asc"}],
                    "page": 1,
                    "page_size": 10
                },
                newRow:{},
            }
        },
        watch:{
            jobId:{
                handler(newValue){
                    this.getJobLogs(this.envIpJson)
                }
            },
            '$store.state.sqllogslivestatus':{
                handler(newValue){
                    this.UpdateSqlLogsDetails(newValue)
                }
            },
        },
        mounted() {
            this.tableList = this.getGridObj(this.logsHeader,'job_log_id',false, {"showSelect":false})
            this.envIpJson.page_size = manageScreenDisplay()
            this.getJobLogs(this.envIpJson)
        },
        methods: {
            getJobLogs(inputJson){
                let _this = this;
                var step_data = {
                    "client_id":this.clientId,
                    "filter": inputJson.filter,
                    "page": inputJson.page,
                    "page_size": inputJson.page_size,
                    "sort": inputJson.sort,
                    "job_id": this.jobId
                }
                _this.loader = true
                postToServer(this, config.ENGINE_API_URL + GETALLSTEPSDETAIL, step_data).then(response => {
                    _this.loader = false
                    if(response){
                        var data = response.result
                        data.map(function(obj){
                            if(obj.step_type)
                                obj.step_type = obj.step_type.charAt(0).toUpperCase() + obj.step_type.slice(1)
                            if(obj.operation)
                                obj.operation = obj.operation.charAt(0).toUpperCase() + obj.operation.slice(1)
                        })
                        _this.tableList.rows = data;
                        _this.tableList.total_count = response.total;   
                    }
                }).catch(JobStepError => {
                    _this.showMessage(JobStepError)
                })
            },
            sortBy: function (sortJson) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.sort = sortJson
                this.getJobLogs(inputJson);
            },
            onPageChange(page, perPage) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getJobLogs(inputJson);
            },
            onPerPageChange(perPage,page) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getJobLogs(inputJson);
            },
            filterData(filterArray,pageno){
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.filter=filterArray;
                inputJson.page=1
                this.getJobLogs(inputJson);
            },
            onDownload(downloadParams, filterArray){
                let _this = this;
                let filter=[];
                filterArray.map((obj, index)=>{
                    obj.stringArray.map((stringObj, stringIndex)=>{
                        let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                    })
                });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "client_id": _this.$session.get('client_id'),
                    "env_id": _this.environmentId,
                    "job_id": _this.jobId,
                    "mode":_this.jobMode,
                    "job_name": _this.jobName,
                    "row_option": downloadParams.recordType,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter
                }
                var url = config.ENGINE_API_URL+EXPORT_STEP_DETAILS;
                _this.loader=true;
                postToServer(_this, url, inputJson).then(Response => {
                    _this.loader=false;
                    var url = config.ENGINE_API_URL + "/static/" + Response;
                    window.open(url, '_blank');
                }).catch(objError => {
                    _this.showMessage(objError)
                });
            },
            UpdateSqlLogsDetails(data){
                if(this.jobId == data.job_id){
                    if(!data.job_log_id)
                        return
                    if(!data['log_count'])
                        data['log_count'] = data['query_result']
                    if(data.step_type)
                        data.step_type = data.step_type.charAt(0).toUpperCase() + data.step_type.slice(1)
                    if(data.operation)
                        data.operation = data.operation.charAt(0).toUpperCase() + data.operation.slice(1)

                    if(data.start_datetime_without_timezone && data.start_datetime_without_timezone!="None"){
                        var date_without_miliseconds = data.start_datetime_without_timezone.split('.')[0]
                        var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")

                        var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
                        data.start_date = timezoned_formated_date
                        var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')

                        data.start_time = timezoned_start_time
                    }

                    if(data.end_datetime_without_timezone && data.end_datetime_without_timezone!="None"){
                        var date_without_miliseconds = data.end_datetime_without_timezone.split('.')[0]
                        var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")

                        var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
                        data.end_date = timezoned_formated_date
                        var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')

                        data.end_time = timezoned_start_time
                    }
                    data.liveDataFor = 'sqlLog'
                    this.newRow = _.cloneDeep(data)
                }
            },
        },
    }
</script>