<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row" v-if="!hideBreadscrumb">
                    <v-col cols="8" class="EDC-Col">
                        <v-row class="EDC-Row breadcrumbRow">
                            <v-col cols="12" class="EDC-Col">
                                <ul class="breadcrumb breadcrumbUL">
                                    <li v-if="showJobPlanbtn">{{execution_env_name}}</li>
                                    <li v-if="showJobPlanbtn"><router-link to="/jobplanlist">Job Plan List</router-link></li>
                                    <li v-if="showJobPlanbtn"><router-link :to="{name:'managejobplan',params:{'job_plan_id':job_plan_id,'execution_environment':Environment.id,'execution_env_name':Environment.name}}">{{job_plan_name}}</router-link> </li>
                                    <li v-if="!showJobPlanbtn">
                                        <select v-model="Environment" v-on:change="getProcessDefList" style="-webkit-appearance: menulist" autofocus ref="envname">
                                            <option :value="{}">Select Environment</option>
                                            <option v-for="(env, index)
                                            in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                        </select> 
                                    </li>
                                    <li>Job List</li>
                                </ul>
                            </v-col>                     
                        </v-row>
                    </v-col>
                    <v-col cols="4" class="EDC-Col">
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <edc-data-grid :dataList="tableList"  @onDownload="onDownload" @onCreate="onExecute" 
                        @onCellEvent="onExecute" @onCreateJobPlan="onCreateJobPlan" @onAddMultiple="onAddMultipleJobs" 
                        @ondblClick="onExecute" @showObjectTable="onShowPD" @ShowObjectGraph="onGraphView"></edc-data-grid>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <v-dialog v-model="viewobj_dialog" width="unset" height='600' eager persistent>
                            <v-card class="rounded-card">
                                <v-toolbar dark dense max-height="40">
                                    <v-col class="EDC-Col text-md-center">Object Viewer
                                        <v-icon class="text-lg-left" @click="viewobj_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
                                    </v-col>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row no-gutters class="EDC-Row">
                                        <edc-pdviewer :objectdetails="objectdetails" key="pdviewer" v-if="viewobj_dialog && showpd"> 
                                        </edc-pdviewer>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <loading-panel :loader="loader"></loading-panel>
                        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import config from '../../config.json'
    import {post as postToServer } from '../../methods/serverCall.js';
    import {ALL_PUBLISHED_PROCESS_DEF_LIST, EXPORT_JOB_LIST} from '../../data/url_constants.js';
    import {getEnvironmentList} from '../../methods/EnvironmentList.js'
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    import objectPDTabularView from '../../views/objectPDTabularView.vue'
    import {getReadonlyRedirectionUrl} from '../../methods/commonFunc.js'
    import {getURLByDomainByProduct} from '@/methods/domain_management.js'

    export default{
        name:"JobList",
        components:{
            'edc-pdviewer':objectPDTabularView,
        },
        props:{
            hideBreadscrumb:{
                type:Boolean,
                default:false
            },
            showAddMultiple:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                userRole:{},
                loader:false,
                showpd:false,
                viewobj_dialog:false,
                EnvironmentList: [],
                Environment: {},
                headers: [
                    { text: 'Name', value: 'object_name', width:'20%', title: 'Job Name'},
                    // { text: 'Type', value: 'object_type', sortable: false },
                    { text: 'Ver', value: 'object_version',  options:[], align:'right', width:'10%', title: 'Job Version', dataType:'int'},
                    { text: 'Published By', value: 'published_by',  options:[], width:'20%', title: 'Job Published By'},
                    { text: 'Published Date', value: 'timezone_aware_published_date',   options:[],width:'20%', title: 'Job Published Date', dataType:'date'},
                    { text: 'Published Time', value: 'timezone_aware_published_time',   options:[],width:'20%',title: 'Job Published Time', dataType:'time',hideInlineFilter: true},
                    { text: 'Object ID', value: 'object_id', width: '200px', title: 'Object Id', sortable: false, align:'right'},
                ],
                tableList: {
                    headers:[],
                    rows:[],
                    actions:[]
                },
                snackbar:false,
                snackbartext:'',
                colorValue:'success',
                snackbartimeout: Snackbar_Default_Timeout, 
                showJobPlanbtn:false,
                job_plan_name:null,
                execution_environment:null,
                execution_env_name:null,
                policy_details:[],
                job_plan_id:this.$store.state.jobPlanDetails.job_plan_id
            }
        },
        watch:{
            'hideBreadscrumb':{
                handler(newValue){
                }
            },
            'showAddMultiple':{
                handler(newValue){
                    
                }
            }
        },
        mounted() {
            this.tableList = this.getGridObj(this.headers,'object_name')
            this.userRole = this.$session.get('user_role_mapping');
            if(this.showAddMultiple){
                this.tableList.hideExport=true
                this.tableList.hideShowColumns=true
                this.showCreateSchedulejob = false
                this.showJobPlanbtn = true
                this.job_plan_name = this.$route.params.job_plan_name
                this.execution_environment = this.$route.params.execution_environment,
                this.execution_env_name = this.$route.params.execution_env_name
                this.tableList.actions=[{'text':'addmultiple','key':"object_name", selectType:"multiple", role:true,index:1, 'displayText':'Add Jobs to Job Plan'}
                ];
            }
            else{
                this.tableList.actions=[{'text':'create','key':"object_name", selectType:"single", role:true,index:1, 'displayText':'Run'},
                    {'text': 'showpd', 'key': 'object_name',  selectType:"single", index:2, role:true}
                ];
            }

            var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
                this.Environment = this.$session.get('selected_env')
                this.getProcessDefList()
            }
            getEnvironmentList(this)
        },
        methods:{
            onDownload(downloadParams, filterArray){
                let _this = this
                _this.loader=true;
                let filter=[];
                filterArray.map((obj, index)=>{
                    obj.stringArray.map((stringObj, stringIndex)=>{
                        let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(_.cloneDeep(temp));
                    })
                });
                let inputJson={
                    "recordType":downloadParams.recordType,
                    "row_option": downloadParams.recordType,
                    "client_id": _this.$session.get('client_id'),
                    "env_id": _this.Environment.id,
                    "file_format": downloadParams.fileType,
                    "min_range": downloadParams.minRange|| null,
                    "max_range": downloadParams.maxRange||null,
                    "filter": filter
                }
                var url = config.PUBLISHER_URL+EXPORT_JOB_LIST;    
                postToServer(_this, url, inputJson).then(Response => {
                    var url = config.PUBLISHER_URL + "/static/" + Response;
                    window.open(url, '_blank');
                    _this.loader=false;
                }).catch(objError => {
                    _this.showMessage(objError)    
                })
            },
            showMessage(message,type="error"){
                this.loader = false
                this.snackbar = true
                this.colorValue = type
                this.snackbartext = message
            },
            getProcessDefList () {
                this.$session.set('selected_env', this.Environment)
                let _this = this;
                var client_id = this.$session.get('client_id')
                var t_data = {'env_id': this.Environment.id, 'client_id': client_id}
                this.tableList.rows = []
                _this.loader = true; 
                postToServer(this, config.PUBLISHER_URL + ALL_PUBLISHED_PROCESS_DEF_LIST, t_data).then(response => {
                    _this.loader = false; 
                    var data = response
                    data.map(function(obj){
                        obj.display_name = obj.object_name.toUpperCase()
                    })
                    data = _.sortBy(data,["display_name"])
                    _this.tableList.rows = data;
                    _this.tableList.total_count =data.length; 
                }).catch(ProcessDocError => {
                    _this.showMessage(ProcessDocError)
                });
            },
            onExecute:function(record){
                if(this.showAddMultiple)
                    return false
                let previousUrl = null;
                let job_plan_name = null
                let execution_env_name= null
                let execution_environment = null
                if(this.$route.params.previousUrl && this.$route.params.previousUrl == 'jobPlan')
                    previousUrl = 'jobPlan';
                job_plan_name = this.$route.params.job_plan_name
                execution_env_name = this.$route.params.execution_env_name
                execution_environment = this.$route.params.execution_environment

                this.$store.state.createJobParams={ 'process_definition_id': record.object_id, 
                'process_doc_name': record.object_name, 'activity_id':null,
                'environment':this.Environment, 'is_restore':false};
                this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.object_id, 
                    'process_doc_name': record.object_name, 'activity_id':null,
                    'environment':this.Environment, 'is_restore':false,
                    previousUrl:previousUrl, job_plan_name:job_plan_name,
                    execution_env_name:execution_env_name,
                    execution_environment:execution_environment, id :this.$route.params.id}
                });
            },
            onAddMultipleJobs(records){
                var _this = this
                var newJobList = []
                for (var i = 0; i < records.length; i++) {
                    var job_data = {
                    'client_id': this.$session.get('client_id'),
                    'process_definition_id': records[i].object_id,
                    'process_definition_name': records[i].object_name,
                    'created_by':this.$session.get('email') ,
                    'mode_of_execution':'final',
                    'execution_environment': this.Environment.id,
                    'execution_env_name': this.Environment.name,
                    'exe_type':'on_demand',
                    'other_job_details_need_to_fetch':true,
                    'limit_details':[],
                    "reports_generation_details":[]
                }
                newJobList.push(_.cloneDeep(job_data))
                }
                this.$emit('addNewJobsInPlan', newJobList)
            },
            onShowPD(record){
                this.showpd = true
                this.objectdetails  = {'env_id':record.env_id,'object_id':record.object_id,'object_name':record.object_name,'isfromjob':true}
                this.viewobj_dialog = true
            },
            onGraphView(record){
                let designerHostDetails =_.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
                let additionalUrl = getReadonlyRedirectionUrl('PD',this.Environment,record)
                let url = getURLByDomainByProduct(this, designerHostDetails,'login',additionalUrl)
                window.open(url, '_blank');  
            }
        }
    }
</script>