<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Job Calendar</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onNew="addCalendar" @onCellEvent="onCellEvent" @onHistory="onHistory" @onDownload="onDownload" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, post as postToServer
} from './../../methods/serverCall.js';
import {
    SERVER_ERROR
} from './../../data/client_message.js'
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from './../../data/macros.js'
import _ from 'lodash'
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    JOB_CALENDAR_SERVICE,
    CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION,
} from './../../data/macros.js'
import getUserRole from './../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    
    data: function () {
        return {
            userRole:{},
            loader: false,
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            // tableList: {
            //     headers: [{
            //             text: 'Name',
            //             value: 'name',
            //             width: "20%",
            //             title: 'Job Calendar Name'
            //         },
            //         {
            //             text: 'Description',
            //             value: 'description',
            //             width: "20%",
            //             title: 'Job Calendar Description'
            //         },
            //         {
            //             text: 'Created By',
            //             value: 'created_by',
            //             width: "20%",
            //             title: 'Job Calendar Created By'
            //         },
            //         {
            //             text: 'Created Date',
            //             value: 'timezone_aware_action_taken_date',
            //             width: "15%",
            //             title: 'Job Calendar Created Date',
            //             dataType: 'date'
            //         },
            //         {
            //             text: 'Created Time',
            //             value: 'timezone_aware_action_taken_time',
            //             width: "15%",
            //             title: 'Job Calendar Created Time',
            //             dataType: 'time',
            //             hideInlineFilter: true
            //         },
            //         {
            //             text: 'Status',
            //             value: 'status',
            //             width: "10%",
            //             title: 'Job Calendar Status'
            //         }
            //         // {
            //         //     text: 'Default',
            //         //     value: 'is_default',
            //         //     width: "25%",
            //         // }
            //     ],
            //     actions:[],
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll:false,
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [{
                        text: 'Name',
                        value: 'name',
                        width: "20%",
                        title: 'Job Calendar Name'
                    },
                    {
                        text: 'Description',
                        value: 'description',
                        width: "20%",
                        title: 'Job Calendar Description'
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: "20%",
                        title: 'Job Calendar Created By'
                    },
                    {
                        text: 'Created Date',
                        value: 'timezone_aware_action_taken_date',
                        width: "15%",
                        title: 'Job Calendar Created Date',
                        dataType: 'date'
                    },
                    {
                        text: 'Created Time',
                        value: 'timezone_aware_action_taken_time',
                        width: "15%",
                        title: 'Job Calendar Created Time',
                        dataType: 'time',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        width: "10%",
                        title: 'Job Calendar Status'
                    }
                    // {
                    //     text: 'Default',
                    //     value: 'is_default',
                    //     width: "25%",
                    // }
                ],
            valid: true,
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
       this.tableList =  this.getGridObj(this.headers, 'name')

        this.tableList.actions = [{
                            'text': 'new',
                            'key': "name",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, CREATE_ACTION),
                            index:1,
                        },{
                            'text': 'edit',
                            'key': "name",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, UPDATE_ACTION),
                            index:2
                        },
                        {
                            'text': 'delete',
                            'key': "name",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_CALENDAR_SERVICE, DELETE_ACTION),
                            index:3
                        }
                ], //if we need conditional action in row then provide key
        this.getCalendarList();
    },
    methods: {
        onDownload(downloadParams, filterArray){
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "client_id": this.$session.get('client_id'),
            "env_id": "",
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.DATA_SOURCE_URL+EXPORT_DATASOURCE;    
        postToServer(this, url, inputJson).then(envirnentResponse => {
            var url = config.DATA_SOURCE_URL + "/static/" + envirnentResponse;
            // window.open(url, '_blank');
            getToServer(this, url).then(Response => {
                let inputurl = window.URL.createObjectURL(new Blob([Response]));
                var link = document.createElement('a');
                link.href = inputurl;
                 link.setAttribute('download', envirnentResponse);
                link.click();
            });
            
        });
    },
        onHistory(record){
            this.$router.push({name:'historyCalendar', params: {record:record}});
        },
        onCellEvent(record){
            this.onEdit(record);
        },
        addCalendar(){
            let _this = this;
            _this.$router.push('/add_calendar');
        
        },
        onDelete:function(record){
            let _this = this;
            _this.deleteCalendar(record.id);
        },
        addBulkUser(){
            let _this = this;
            _this.$router.push('/addbulkuser');
        },
        onEdit:function(record){
            let _this = this;
            this.$store.state.calendarDetails = record;    
            this.$router.push({ name: 'update_calendar', params: { calendarDetails: record, type:'edit' }})
        },
        getCalendarList() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var client_id = this.$session.get('client_id')
            var request_data = {'client_id': client_id}
            postToServer(_this, config.JOB_CALENDAR_URL + '/get_all_calendar', request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.tableList.rows = response;
                    _this.tableList.total_count = response.length;
                } else {
                    _this.tableList.rows = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        deleteCalendar(id){
            let _this = this;
            var Authorization = this.$session.get('access_token')
            var deleteData = {"calendar_id": id}
            postToServer(_this, config.JOB_CALENDAR_URL + '/remove_calendar', deleteData).then(response => {
                _this.getCalendarList()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        }
    }
}
</script>
<style scoped>

</style>