/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Copy': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g opacity=".98"><path pid="0" d="M38.45 4.722v14.49c0 2.716.905 3.621 2.717 3.621m0 0H56.56" _fill="none" _stroke="#000" stroke-width="2" stroke-linejoin="round"/><path pid="1" d="M47.505 19.211l-5.433-5.433v4.347c0 .602.485 1.086 1.087 1.086z"/><path pid="2" d="M22.15 17.216H11.03c-1.91 0-3.448 1.551-3.448 3.478v34.779c0 1.927 1.538 3.478 3.449 3.478h27.592c1.91 0 3.45-1.551 3.45-3.478v-9.095M22.11 17.287V42.94c0 1.926 1.539 3.477 3.45 3.477h16.456m0 0h11.136c1.91 0 3.449-1.55 3.449-3.477V22.072L39.355 4.682H25.56c-1.91 0-3.449 1.552-3.449 3.479v9.126" _fill="none" _stroke="#000" stroke-width="2"/><path pid="3" d="M28.489 30.983h21.733M28.489 37.322h21.733M28.489 24.645h7.244M14 50h21.733M14 42.756h3.622M14 35.511h3.622" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/></g>'
  }
})
