<template>
    <v-btn v-if="!showIcon" :loading="loading" outlined :disabled="disabled" 
                    color="apptext" :class="{'border':true,'apply-margin':addMarginCss}" elevation="1" small :title="itemHelp">{{itemText}}</v-btn>

    <v-btn v-else :loading="loading" outlined :disabled="disabled" color="apptext" class="border" elevation="1" small :title="itemHelp">
        <svgicon  :title="iconTitle || itemHelp" :class="{'svg-icon-grid':true,'svg-fill-grid':true,'svg-icon-blur':disabled}" :name="iconName" :original="true"></svgicon>
        {{itemText}}
    </v-btn>

</template>
<script>
import { BTN_COLOR } from '@/data/macros.js';
export default {
    name:'vcButton',
    props: {
      itemText: String,
      loading: Boolean,
      disabled:Boolean,
      itemHelp:String,
      addMarginCss:{
        type:Boolean,
        default:true
      },
      iconObject:{
        type:Object,
        default:()=>{
            return {}
        }
      },
    },
    data() {
      return {
          buttonColor:BTN_COLOR
      }
    },
    computed:{
        showIcon(){
            return !_.isEmpty(this.iconObject)
        },
        iconName(){
            return this.iconObject.name
        },
        iconTitle(){
            return this.iconObject.title
        },
    },
    watch:{
        'disabled':{
            handler(newValue){
                
            }
        },
        'iconObject':{
            handler(newValue){

            }
        }
    }
}
</script>
<style scoped>
.border{
    border-radius:10px;
}
.apply-margin{
    margin:4px !important;
}
</style>
