<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                                <select v-model="Environment" v-on:change="GetJobRuleList" style="-webkit-appearance: menulist">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select>
                            </li>
                            <li>Job Rule List</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @onEdit="onEdit"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
    </div>
</template>

<script>
import moment from 'moment'
import config from './../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import {SERVER_ERROR} from './../../data/client_message.js'
import { GET_ALL_WORKFLOW_LIST, UPDATE_ENV_RULE,DELETE_JOB_RULE,
    ENVIRONMENT_RULES, ADD_ENV_RULE, MANAGE_JOB_RULE, ALL_JOB_RULE, GET_ALL_JOB_RULES_BY_ENV, GET_ALL_PUBLISHESD_PROCESS_DEF_LIST,ALL_PUBLISHED_BUSINESS_OBJ_LIST} from './../../data/url_constants.js';
    import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js'
    import environmentList from '../../methods/EnvironmentList.js'
    import workflowList from '../../methods/WorkflowList.js'
    import _ from 'lodash'
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {JOB_MANAGEMENT_SERVICE,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,READ_ACTION} from "./../../data/macros.js"
import getUserRole from './../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    data: function () {
        return {
            loader:false, 
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },   
            headers: [
                { text: 'Select Object Type', value: 'object_type', width:"33%",option:[], 
                'item_text':'name', 'item_value':'name', title: 'Select Object Type', useAutoComplete:true,isJobRule:true},
                { text: 'Select Object Name', value: 'object_name', width:"33%",option:[], 
                'item_text':'object_name', 'item_value':'object_name', title: 'Select Object Name', useAutoComplete:true},
                { text: 'Select Workflow', value: 'workflow_name', width:"33%",option:[], 
                'item_text':'workflow_name', 'item_value':'workflow_name', title: 'Select Workflow Name', useAutoComplete:true}],        
            // tableList: {
            //     headers: [
            //     { text: 'Select Object Type', value: 'object_type', width:"33%",option:[], 
            //     'item_text':'name', 'item_value':'name', title: 'Select Object Type', useAutoComplete:true,isJobRule:true},
            //     { text: 'Select Object Name', value: 'object_name', width:"33%",option:[], 
            //     'item_text':'object_name', 'item_value':'object_name', title: 'Select Object Name', useAutoComplete:true},
            //     { text: 'Select Workflow', value: 'workflow_name', width:"33%",option:[], 
            //     'item_text':'workflow_name', 'item_value':'workflow_name', title: 'Select Workflow Name', useAutoComplete:true}],
            //     actions: [
            //         // {'text':'edit','key':"id", selectType:"single", role:true, index:1},
            //         // {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},
            //         // {'text':"save","key":"is_row_editable",selectType:"single", index:3},
            //         // {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
            //         // {'text':'delete','key':"id", selectType:"single", role:true, index:5}
            //     ],//if we need conditional action in row then provide key
            //         sorting_type: CLIENT_SIDE,
            //         filterType: CLIENT_SIDE,
            //         paginationType: CLIENT_SIDE,
            //         total_count: 0,
            //         is_row_edit:true,
            //         rows: [],
            //         selected_rows: [],
            //         syncHeaderScroll:false,
            //         disableDefaltSorting:true,
            //         is_grid_inline_edit:true,
            //         itemkey:'id'
            //     },
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid:true,
            EnvironmentList: [],
            Environment: {},
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            userRole:{},
            businessObjectList:[],
            processDefList:[],
        }
    },
              
    mounted() {
        this.tableList = this.getGridObj(this.headers,'id',true,{is_grid_inline_edit:true})
        this.tableList.headers[0].option = [{"name":"Business Object","value":"BO"},{"name":"Process Definition","value":"PD"}]
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [{'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,UPDATE_ACTION), index:1},
        {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,CREATE_ACTION), index:2},    
        {'text':"save","key":"is_row_editable",selectType:"single", index:3},
        {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
        {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,DELETE_ACTION), index:5}]
        
            this.Environment = this.$session.get('selected_env')
            this.GetJobRuleList()

        this.getEnvironmentList();
        this.getWorkflowList(); 

    },
    
            methods: {
                GetJobRuleList(){
                    var env_value = this.Environment
                    this.$session.set('selected_env', this.Environment)
                    this.getRuleList(env_value.id)
                    this.getProcessDefList(env_value.id)
                    this.GetBusinessObjectList(env_value.id)
                },

                onSave(record){
                    if(!record.object_type || !record.object_name || !record.workflow_name){
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = 'All fields are required.';
                        return false
                    }
                    this.saveRule(record);            
                },
                onCancel(){
                    this.getRuleList();
                },
                getEnvironmentList() {
                    let _this = this;
                    environmentList(this).then(response=>{
                        if(response)
                            this.EnvironmentList = response
                    },response=>{
                        this.ShowErrorMessage=true
                        this.ErrorMessage=response
                    })
                },
                getWorkflowList(){
                    let _this = this;
                    workflowList(this).then(response=>{
                        if(response)
                            this.tableList.headers[2].option = response;
                    },response=>{
                        this.ShowErrorMessage=true
                        this.ErrorMessage=response
                    })
                },
                getRuleList(){
                    let _this = this;
                    _this.loader = true;
                    var client_id = this.$session.get('client_id')
                    var env_id = this.Environment.id
                    var data = {"env_id":env_id,"client_id":client_id}
                    postToServer(this, config.COORDINATOR_API_URL + '/get_all_job_rules_env', data).then(response => {
                        _this.loader = false;
                        if(response){
                            _this.tableList.rows = response;
                            _this.tableList.total_count = response.length            
                        } else {
                            _this.tableList.rows = [];
                            _this.tableList.total_count = 0
                        }
                    },response => {
                        _this.loader = false;
                        _this.tableList.rows = [];
                        _this.tableList.total_count = 0
                        this.ShowErrorMessage=true
                        this.ErrorMessage=response
                    }).catch(EnvironmentError => {
                        _this.loader = false;
                        _this.tableList.rows = [];
                        _this.tableList.total_count = 0
                        if(EnvironmentError){
                            this.loader = null 
                            this.snackbar = true
                            this.colorValue = 'error'
                            this.snackbartext = EnvironmentError;
                        }
                        else {
                            this.snackbar = true
                            this.colorValue = 'error'
                            this.snackbartext = SERVER_ERROR;
                        }
                    })
                },
                GetBusinessObjectList(env_id) {
                    var user_id = this.$session.get('user_id')
                    var client_id = this.$session.get('client_id')
                    var get_bo_list_url = config.PUBLISHER_URL + ALL_PUBLISHED_BUSINESS_OBJ_LIST
                    var data = {"client_id": client_id, "env_id": env_id}
                    var _this = this
                    postToServer(_this, get_bo_list_url, data, true).then(response => {
                        response.unshift({"id": 0, "object_id": "BO0", "object_name": "ANY"});
                        response.map((obj)=>{
                            obj.bo_name = obj.object_name
                            obj.bo_id = obj.object_id
                        })
                        _this.businessObjectList = response;
                    }).catch(error_response => {
                        if(error_response){

                        }
                        else {

                        }
                    });
                },
                getProcessDefList (env_id) {
                    let _this = this;
                    _this.loader = true; 
                    var client_id = this.$session.get('client_id')
                    var data = {"client_id":client_id,"env_id":env_id}
                    postToServer(this, config.PUBLISHER_URL + '/published_process_def_in_env', data).then(response => {
                        _this.loader = false;
                        response.unshift({"id": 0, "object_id": "PD0", "object_name": "ANY"});
                        response.map((obj)=>{
                            obj.pd_name = obj.object_name
                            obj.pd_id = obj.object_id
                        })
                        _this.processDefList = response;
                    }).catch(ProcessDocError => {
                        _this.loader = false; 
                        if(ProcessDocError){
                            this.tableList.headers[1].option = [];
                        }
                        else {
                            this.snackbar = true
                            this.colorValue = 'error'
                            this.snackbartext = SERVER_ERROR;
                        }
                    });
                },
                saveRule(rule){
                    let envObj = this.Environment
                    let workflowObj = _.find(this.tableList.headers[2].option, ['workflow_name', rule.workflow_name]);
                    let processDefObj = {}

                    let objectType = _.find(this.tableList.headers[0].option, ['name', rule.object_type]);

                    if (rule.object_type === 'Process Definition')
                        processDefObj = _.find(this.processDefList, ['object_name', rule.object_name]);
                    else
                        processDefObj = _.find(this.businessObjectList, ['object_name', rule.object_name]);

                    if(envObj && workflowObj && processDefObj && objectType){
                        let inputJson = {'id': rule.id ? rule.id :null ,
                        'env_id':envObj ? envObj.id : null,
                        'env_name': envObj.name || null, 
                        'workflow_id':workflowObj ? workflowObj.object_id : null, 
                        'workflow_name': rule.workflow_name || null,
                        'object_id': processDefObj ? processDefObj.object_id : null, 
                        'object_name':processDefObj ? processDefObj.object_name : null,
                        'object_type':rule.object_type
                    }
                    let url =  config.COORDINATOR_API_URL + MANAGE_JOB_RULE;
                    postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        this.getRuleList();
                    }).catch(DatasourceError => {
                        this.getRuleList();
                        if(DatasourceError){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = DatasourceError;
                    }
                    else {
                        // this.snackbar = true
                        // this.colorValue = 'error'
                        // this.snackbartext = SERVER_ERROR;
                    }
                });  
                }
            },
            onDelete(rule){
                let inputJson = { id:rule.id }
                let url =  config.COORDINATOR_API_URL + DELETE_JOB_RULE;              
                postToServer(this, url, inputJson).then(response  => {
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = response;
                    this.getRuleList();
                }).catch(DatasourceError => {
                    this.getRuleList();
                    if(DatasourceError){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = DatasourceError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });               
            },
            onNew(){
                if(!this.Environment){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'Please select environment';
                return
            }

            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }

            this.tableList.rows.unshift({'id':'',
                'object_type':'',
                'object_name':'',
                'workflow_name':'',
                'is_row_editable' : true,
            });
         },
         onValueUpdate(record){
            this.tableList.headers[1].option = this.businessObjectList
            if(!record ||!record.object_type)
                return
            if(record.object_type === 'Process Definition')
                this.tableList.headers[1].option = this.processDefList
        },
     }
 }
 </script>
 <style>
 /* tr:nth-child(even) {background-color: #E8E8E8;}
 .blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>