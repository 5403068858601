/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'object_notes': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M2.117.221a.265.265 0 00-.265.264V.53h-.264a.799.799 0 00-.795.793v3.705c0 .435.36.793.795.793h3.173a.799.799 0 00.796-.793V1.323A.799.799 0 004.76.53h-.263V.485A.265.265 0 004.232.22a.265.265 0 00-.263.264V.53h-.53V.485A.265.265 0 003.177.22a.265.265 0 00-.266.264V.53h-.53V.485A.265.265 0 002.118.22zm-.529.838h.264v.308a.265.265 0 00.265.264.265.265 0 00.264-.264V1.06h.529v.308a.265.265 0 00.266.264.265.265 0 00.264-.264V1.06h.529v.308a.265.265 0 00.263.264.265.265 0 00.266-.264V1.06h.263a.26.26 0 01.267.264v3.705a.26.26 0 01-.267.264H1.588a.259.259 0 01-.266-.264V1.323c0-.151.115-.264.266-.264zm.264 1.059v.527h2.646v-.527H1.852zm0 1.056v.53h2.646v-.53H1.852zm0 1.059v.529h2.646v-.53H1.852z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
