<template>
  <div id="register">
    <v-container style="padding-top:10px">
      <v-form ref="form" @submit.prevent="validateBeforeLogin" v-model="valid" v-if="!isResetLogin">
        <v-layout id="loginContainer" row wrap v-show="!loader">
          <v-flex xs4 offset-xs4>
            <v-layout row wrap style="background: white;padding: 40px;border-radius: 15px;margin-top:16px !important;">
              <v-flex justify-center>
                <img src="../../assets/images/essentio_logo_normal.png" alt="Essentio logo" style="width:30%;">
              </v-flex>       
              <v-flex xs12>
                <v-text-field label="Username" append-icon="fa-envelope"
                v-model="email"
                required
                :rules="usernameRules" autocomplete="off" validate-on-blur>
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field label="Password"
                v-model="password"
                :append-icon="show1 ? 'visibility_off' : 'visibility'"
                :type="show1 ? 'text' : 'password'"
                class="input-group--focused"
                required
                :rules="passwordRules"
                @click:append="show1 = !show1" autocomplete="off">
                </v-text-field>
              </v-flex>
              <v-flex xs12 >
                <!-- <v-btn :loading="loading" outline :disabled="loading"  @click.native="loader = 'loading'" @click="submit" 
                   type="submit" color="indigo" style="border-radius:10px">Login</v-btn> -->
                   <vc-button type="submit" :loading="loading" :disabled="loading" itemText="Login"></vc-button>
              </v-flex>
              <v-flex xs6 class="text-sm-left" style="margin-top:12px;">
               <!--  <router-link style="color:indigo !important" to="/Register">Create New Account</router-link> -->
              </v-flex>
              <v-flex xs6 class="text-sm-right" style="margin-top:12px;">
                <router-link to="/forgotpassword" style="color:indigo !important">Forgot Password</router-link>
              </v-flex>
              <!-- <v-flex xs5 offset-xs2 class="text-sm-right" style="margin-top:12px;">
                <router-link to="/resendemail" style="color:indigo !important">Re-send email</router-link>
              </v-flex> -->
            </v-layout>
            <v-layout>
              <!-- <v-flex xs12 style="margin-top:3%">
                <span style="color:white"><i class="far fa-copyright"></i> {{getTodaysYear()}} Essentio Software LLC 1.0.2</span>
              </v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <loading-panel :loader="loader"></loading-panel>

      <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { post as postToServer } from './../../methods/serverCall.js';
  import { get as getToServer } from './../../methods/serverCall.js';
  import config from '../../config.json'
  import {REGISTRATION_SUCCESS_MESSAGE, LOGIN_FAILED_MESSAGE, VERIFY_TOKEN_MESSAGE, SESSION_EXPIRED, RESET_PASSWORD_MESSAGE} from '../../data/client_message.js'
  import {AUTHENTICATE_USER, MARKLOGIN, VERIFY_CLIENT,VERIFY_USER} from '../../data/url_constants.js'
  import moment from 'moment'
  import {
  GetUIDateFormat
} from '@/data/manage_account_constants.js'
  import {Snackbar_Login_Timeout} from '@/constants/constants.js'
  import {Archivist_Product,Workflow_Product,Dataviewer_Product} from './../../constants/constants.js'
  import {getUrlForRedirection} from '@/methods/commonFunc.js'

  export default {
    mixins: [validationMixin],
    name: 'Register',
    data() {
      return {
        email: '',
        password: '',
        passwordFieldType: 'password',
        show1: false,
        valid:true,
        snackbar:false,
        snackbartext:'',
        colorValue:'',
        snackbartimeout: Snackbar_Login_Timeout,
        loader: false,
        loading: false,
        usernameRules:[v => !!v || 'User Name is required'], //change made because of LDAP login functionality
        // usernameRules:[v => !!v || 'E-mail is required',
        //               v => /.+@.+/.test(v) || 'E-mail must be valid'],
        passwordRules:[v => !!v || 'Password is required.'],
        reportdownloadkey:'',
        hostName: window.location.hostname
      }
    },
    mounted() {
      if(this.$route.query.downloadkey)
        this.reportdownloadkey = this.$route.query.downloadkey
      if(this.$route.query.product_key){
        this.loginUserByToken(this.$route.query.product_key,this.$route.query.last_env)
        return
      }

      if(this.isResetLogin){
        this.email = this.$route.params.username
        this.password = this.$route.params.password
        this.login()
        return
      }
      if(this.hostName){
          let access_token = this.$cookies.get('access_token',null,this.hostName)
          let last_used_env = this.$cookies.get('last_selected_env_id',null,config.DOMAIN_NAME)
          if(!access_token){
            access_token = this.$cookies.get('access_token')
            last_used_env = this.$cookies.get('last_selected_env_id')
          }
          if(access_token){
            this.loginUserByToken(access_token,last_used_env)
            return
          }
        }
      this.is_registration_success()
      this.is_reset_password_success()
      this.is_session_expired()
      this.verify_token()
    },
    props: {
      msg: String
    },
    computed:{
    isResetLogin(){
      return this.$route.params && this.$route.params.isResetAndLogin
    }
  },
    methods: {
      getTodaysYear(){
        return moment().format('YYYY')
      },
      is_registration_success(){
        if(this.$cookies.isKey('registration_success')){
          this.$cookies.remove('registration_success')
          this.snackbar = true
          this.colorValue = 'info'
          this.snackbartext = REGISTRATION_SUCCESS_MESSAGE;
        }
      },
      is_reset_password_success(){
        if(this.$cookies.isKey('reset_password_success')){
          this.$cookies.remove('reset_password_success')
          this.snackbar = true
          this.colorValue = 'info'
          this.snackbartext = RESET_PASSWORD_MESSAGE;
        }
      },
      is_session_expired(){
        if(this.$cookies.isKey('session_exp_msg')){
          this.$cookies.remove('session_exp_msg')
          this.snackbar = true
          this.colorValue = 'info'
          this.snackbartext = SESSION_EXPIRED;
        }
      },
      verify_token(){
        if(this.$route.query.token){
          var verify_token_end_point = config.ACCOUNT_REGISTRATION_URL +  VERIFY_CLIENT+ this.$route.query.token
          getToServer(this, verify_token_end_point, true).then(accountRegistrationResponse => {
              this.snackbar = true
              this.colorValue = 'info'
              this.snackbartext = VERIFY_TOKEN_MESSAGE;
          }).catch(error_response => {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this.$cookies.set('token_expired', '1')
              //window.location.href = "/register"
          });
        }
        else if(this.$route.query.usertoken){
            var verify_token_end_point = config.USER_PROVISION_URL +  VERIFY_USER+ this.$route.query.usertoken
            getToServer(this, verify_token_end_point, true).then(UserProvisionResponse => {
                this.snackbar = true
                this.colorValue = 'info'
                this.snackbartext = VERIFY_TOKEN_MESSAGE;
            }).catch(error_response => {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                this.$cookies.set('token_expired', '1')
                //window.location.href = "/register"
            });
        }
      },
      marklogin(user_id){
        var user_mark_end_point = config.USER_PROVISION_URL+ MARKLOGIN+user_id
        getToServer(this, user_mark_end_point).then();
      },
      validateBeforeLogin () {
          if (this.$refs.form.validate()) {
          this.login()
          }
      },
      login(){
        let user_input_details ={
          'username': this.email,
          'password': this.password,
          'grant_type': 'password',
          'application_token':config.APPLICATION_TOKEN
        }
        this.loading = true;
        if(this.isResetLogin)
        this.loader = true
        var user_authentication_end_point = config.AUTHENTICATION_URL + AUTHENTICATE_USER
        postToServer(this, user_authentication_end_point, user_input_details, true).then(authenticationResponse  => {
          this.loader = false

          this.loading=false;
              if(authenticationResponse){
                let user_details = authenticationResponse;
                this.manageSession(user_details)
              }
          }).catch(error_response => {
            this.loading=false;
            this.loader = false
            if(error_response === 'Password has expired and needs to be reset.'){
              this.$router.push({name:'ResetAndLogin',params:{"isResetAndLogin":true,"email":this.email,'resetMsg':error_response}})
              return
            }
            if(error_response){
              this.snackbar = true               
              this.colorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.colorValue = 'error'
              this.snackbartext = LOGIN_FAILED_MESSAGE;
            }
           
        });
      },
      manageSession(user_details){
        this.$session.start()
        console.log('user_details.user_role_mapping',user_details.user_role_mapping)
        this.$session.set('access_token', user_details.access_token)
        this.$session.set('client_id', user_details.clientId)
        this.$session.set('user_id', user_details.userId)
        this.$session.set('theme_name', user_details.themeName)
        this.$session.set('last_selected_env_id', user_details.last_selected_env_id)
        this.$session.set('product_list', [])
        if(user_details.product_list)
          this.$session.set('product_list',user_details.product_list)
        this.$session.set('last_used_product', user_details.last_used_product)
        this.$session.set('user_name', user_details.firstName + ' ' + user_details.lastName)
        this.$session.set('first_name', user_details.firstName)
        this.$session.set('last_name', user_details.lastName)
        this.$session.set('phone', user_details.phone)
        this.$session.set('client_name',user_details.clientName)
        this.$session.set('ipaddress',this.ipaddress)
        this.$session.set('email',user_details.username)
        this.$session.set('time_zone_preference',user_details.time_zone_preference)
        this.$session.set('date_format',user_details.date_format)
        var UI_date_format = GetUIDateFormat(user_details.date_format)
        this.$session.set('UI_date_format',UI_date_format)
        this.$session.set('allow_job_to_run',user_details.job_calender_rules_allowed)
        this.$cookies.set('Timeout','timeout', config.SESSION_TIMEOUT + 'min')
        this.$session.set('user_role_mapping', user_details.user_role_mapping)
        this.$session.set('erp_cloud_host',user_details.erp_cloud_host)
        this.$session.set('product',Archivist_Product)
        window.sessionStorage.setItem('product', Archivist_Product);

        if(this.hostName)
            this.$cookies.set('access_token',user_details.access_token,null,null,this.hostName)
          // this.$cookies.set('last_selected_env_id',user_details.last_selected_env_id,null,null,config.DOMAIN_NAME)
        // }
        this.$session.set('protocol',user_details.protocol)
        if(this.reportdownloadkey)
          this.$session.set('reportdownloadkey', this.reportdownloadkey)
        let web_scoket_url = 'http://127.0.0.1:3000'
        if(user_details.protocol && user_details.websocket_host_ip &&
         user_details.websocket_port){
          web_scoket_url = user_details.protocol +'://'+ user_details.websocket_host_ip+":"+user_details.websocket_port
        }
        console.log('web socket url',web_scoket_url)
        this.$session.set('WEB_SOCKET_URL',web_scoket_url)
        this.marklogin(user_details.userId)
        if(this.$route.query && this.$route.query.redirect_to){
          let redirctionUrl = getUrlForRedirection(this.$route.query,true)
          window.location.href = redirctionUrl
        }
        else{
          window.location.href = "/dashboard"
        }
      },
      loginUserByToken(token,last_env){
        let _this = this
        let user_input_details ={
          'access_token': token,
          'product_name':Archivist_Product
        }
        this.loading = true;
        _this.loader = true
        var user_authentication_end_point = config.AUTHENTICATION_URL + '/get_user_details_by_token'
        postToServer(this, user_authentication_end_point, user_input_details, true).then(authenticationResponse  => {
          this.loading=false;
          if(authenticationResponse){
            // authenticationResponse.last_selected_env_id = last_env
            this.manageSession(authenticationResponse)
          }

        }).catch(error_response => {
          _this.loader = false
          console.log(error_response)
              this.loading=false;
              // if(error_response){
              //   this.snackbar = true               
              //   this.colorValue = 'error'
              //   this.snackbartext = error_response;
              // }
              // else {
              //   this.colorValue = 'error'
              //   this.snackbartext = LOGIN_FAILED_MESSAGE;
              //   this.snackbar = true
              // }
             
          });
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
