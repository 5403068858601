<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getCatalogTableList" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Clear Catalog</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row class="EDC-Row">
      <v-col cols="12" class="EDC-Col">
        <edc-data-grid :dataList="tableList"></edc-data-grid>
      </v-col>
    </v-row>
    <v-row class="EDC-Row" v-if="tableList.rows.length">
      <v-col class="EDC-Col" align="right" style="padding-right:16px !important;padding-top:8px !important;">
        <toggle-button v-model="ExecutionMode" :labels="{checked: 'Proof', unchecked: 'Final'}"
        :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28"
        :sync="true" style="margin-top:10px !important; font-size:10px !important" :disabled="disabledButtons"></toggle-button>
        <vc-button itemText="Delete For This Environment" :disabled="disabledButtons" @click.native="ShowConfirmDelete('include')"></vc-button>
        <vc-button itemText="Delete For Other Environments" :disabled="disabledButtons" @click.native="ShowConfirmDelete('exclude')"></vc-button>
      </v-col>
    </v-row>
    <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import Simplert from 'vue2-simplert'
import config from './../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
import {DELETE_CATALOG_DATA} from './../../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../../data/macros.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'

export default {
  name: 'DeleteCatalogData',
  components: {
  'simplert':Simplert
  },
  data: function () {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'success',
      snackbartimeout: 10,
      loader:false,
      Environment:{},
      EnvironmentList:[],
      ExecutionMode: true,
      // tableList: {
      //   headers: [
      //   { text: 'Business Object Name', value:'business_object_name',   title:'Business Object Name'},
      //   { text: 'Related Catalog Name', value:'catalog_table_name', title:'Catalog Table Name'},
      //   { text: 'Business Object Id', value: 'business_object_id', title:'Busienss Object Id'},
      //   ],
      //   actions:[],
      //   sorting_type: CLIENT_SIDE,
      //   filterType: CLIENT_SIDE,
      //   paginationType: CLIENT_SIDE,
      //   total_count: 0,
      //   rows: [],
      //   selected_rows: [],
      //   disableDefaltSorting:true,
      //   hideExport:true,
      //   hideShowColumns:true,
      //   itemkey:'id'
      // },
      tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
      headers: [
        { text: 'Business Object Name', value:'business_object_name',   title:'Business Object Name'},
        { text: 'Related Catalog Name', value:'catalog_table_name', title:'Catalog Table Name'},
        { text: 'Business Object Id', value: 'business_object_id', title:'Busienss Object Id'},
        ],
    }
  },
  computed:{
    disabledButtons(){
      return this.tableList.selectedRows.length === 0
    }
  },
  mounted() {
    this.tableList = this.getGridObj(this.headers,'id',true, {showActions :  false, showExport : false})
    // this.tableList.actions = [
    //   {'text': 'delete', 'key': 'id', selectType:"multiple", index:1,role:true},
    // ]
   var env_value = this.$session.get('selected_env')
   if( env_value && env_value.id != '0' && env_value.id !='all_env')
      {
        this.Environment = this.$session.get('selected_env')
      }
      getEnvironmentList(this);
      this.getCatalogTableList()
    },
  methods: {
    getCatalogTableList(){
      var _this = this
      _this.tableList.rows = []
      if(!this.Environment.id)
        return false
      var client_id = this.$session.get('client_id')
      var data = {
        "env_id":this.Environment.id,
        "client_id":client_id
      }
      _this.$session.set('selected_env', this.Environment)
      this.loader =true
      postToServer(this, config.ENGINE_API_URL +'/get_available_catalog_tables',data).then(response=>{
        _this.loader = false
        _this.tableList.rows = response
      }).catch(error=>{
        _this.loader = false
      })
    },
    onDelete(){

    },
    confirm_clear_catalog_data(action){
     var _this = this
      var client_id = this.$session.get('client_id')
      var data = {
        "env_id":this.Environment.id,
        "client_id":client_id,
        "env_deletion_type":action,
        "mode_of_execution":this.ExecutionMode ? 'proof':'final',
        "business_object_data_list":this.tableList.selected_rows
      }
      _this.loader = true
      postToServer(this, config.ENGINE_API_URL + DELETE_CATALOG_DATA, data).then(response => {
        _this.loader = false
        if (response) {
            _this.snackbar = true
            _this.colorValue = 'info'
            _this.snackbartext = "Successfully received your request";

        }}).catch(ProcessDefError => {
          _this.loader = false
          if(ProcessDefError){
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = ProcessDefError;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'Failed to delete';
          }
        });
    },

  ShowConfirmDelete(action){
    var _this = this
    let option = "for the "
    if(action === 'exclude')
      option = "other than "
    let confirmFn = function () {
    _this.confirm_clear_catalog_data(action)
    }
    var con_message = ""
    if (!_this.ExecutionMode){
      con_message = 'If you proceed to delete catalog data in final mode you would not restore any job for '+this.Environment.name +' environment for selected business object. Are you sure want delete all catalog table data '+option+this.Environment.name +' environment, Proceed?'
    }
    else {
    con_message = 'If you proceed to delete catalog data in proof mode you can see count of catalog data for selected business object in this environment or other than this environment. Are you sure want delete all catalog table data '+option+this.Environment.name +' environment, Proceed?'
    }

    let obj = {
      title: 'Confirm Delete Catalog Data',
      message: con_message,
      type: 'info',
      useConfirmBtn: true,
      onConfirm: confirmFn
    }
    _this.$refs.simplert.openSimplert(obj)
  }
}
}
</script>
<style scoped>
</style>
