import {DATABASE_NUMERIC_DATATYPE} from './../../constants/metaData.js';
import {separate_operator} from './../../methods/common.js'

export function isUDClookup(header){
	return header['frower'] === 'UDC' || header['frerul'] === 'UDC'
}

export function isVALUElookup(header){
	return header['frower'] === 'VALUE' || header['frerul'] === 'VALUE'
}

export function isFILEloopup(header){
	return  header['frerul'] === 'FILE'
}

export  function isSERVERloopup(header) {
	return  header['frerul'] === 'SERVER'
}

export function isSameLookupOpenLastTime(new_header,old_header={}){
	return old_header.value === new_header.value
}

export function formatColumn(colObj,db_type='oracle'){
	if(colObj.column_db_type)
		db_type = colObj.column_db_type
	colObj["align"] = "left"
		if(!colObj['avoidTrim'])
        	colObj['avoidTrim'] = false
        if(!colObj["data_type"])
        	colObj['data_type'] = 'char'
        colObj['datatype'] = colObj["data_type"].toLowerCase()
        if(DATABASE_NUMERIC_DATATYPE.indexOf(colObj["data_type"].toLowerCase()) >=0)
          colObj["align"] = "right"
        colObj['text'] = colObj["column_name"].toUpperCase()
        if(!colObj["description"])
          colObj["description"] =colObj["column_name"].toUpperCase()
        
        colObj['displayHeader'] = colObj["description"].trim().toUpperCase()
        if(!colObj['col_alias']){
        	if(db_type === 'ms_sql')
        		colObj['value'] = colObj["column_name"].toUpperCase()
        	else
        		colObj['value'] = colObj["column_name"].toLowerCase()
        }
        else{
        	if(db_type === 'ms_sql')
        		colObj['value'] = colObj["col_alias"].toUpperCase()
        	else
        		colObj['value'] = colObj["col_alias"].toLowerCase()
        }

        colObj['title'] = colObj["value"].toLowerCase()

        // add padding rule
        colObj = formatPadding(colObj)
    return colObj
}

export function getFILEGridHeaders(headers,db_type){
	let gridHeader = [
		{text:'',value:'radio',useRadio:true,hideSorting:true,hideInlineFilter:true,width:'20px',showDeleteInlineFilterIcon:true},

	]
	for(let i=0;i<headers.length;i++){
		let colObj = headers[i]
		if(i===0)
			colObj['width'] = "80px"
		colObj = formatColumn(colObj,false,db_type)
		gridHeader.push(_.cloneDeep(colObj))
	}
	return gridHeader
}

export function formatPadding(header){
	// frdtas indicate the size of padding. If that value is not there then it means padding 
	// not required for that column
	if(!header.frdtas)
		return header

	let maxsize =header.frdtas
	header['paddingReq'] = false
	if((header.frdrul === '*RAB' || header.frdrul === '*RABN')){
		header['paddingText'] = ' '
		header['paddingReq'] = true
		header['avoidTrim'] = true
		header['padUpto'] = maxsize
		header['padFrom'] = 'left'
	}
	else if(((header.frdrul === '*RAZ') && header.frdtas) || header.frero1 === 'F0010'){
		header['paddingText'] = '0'
		header['paddingReq'] = true
		header['avoidTrim'] = true
		header['padUpto'] = maxsize
		header['padFrom'] = 'left'
	}
	return header
}


export function addPadding(textToPad,paddingText,padUpto,padFrom){
	var operator_sign = separate_operator(textToPad, true)
	var indx_percent = textToPad.indexOf('%')
	var operator_count = (textToPad.split("%").length - 1)
	if(["=","!=","<>"].indexOf(textToPad)>-1)
		return textToPad
	let Length = textToPad.length
	if(operator_sign)
				{
					textToPad = textToPad.replaceAll(operator_sign, '')
				}
	if(padFrom==='left'){
		textToPad = textToPad.padStart(padUpto,paddingText)
		//test
		if(operator_sign)
		{
			if(operator_count> 1){
				textToPad = operator_sign + textToPad + operator_sign
			}
			else if(indx_percent === Length-1){
				textToPad = textToPad + operator_sign
			}
			else
				textToPad = operator_sign + textToPad
		}
		return textToPad
		}
		
	else{
	textToPad = textToPad.padEnd(padUpto,paddingText)
		if(operator_sign)
		{
			if(operator_count> 1){
				textToPad = operator_sign + textToPad + operator_sign
			}
			else if(indx_percent === textToPad.length-1){
				textToPad = textToPad + operator_sign
			}
			else
				textToPad = operator_sign + textToPad
		}
		return textToPad
	}
}


export function isJDEDate(column_type){
	if(['DATEW','DATEG'].indexOf(column_type)>-1)
		return true
	return false
}

export function getJDETime(time){
	if(!time)
		return ''
	time = time.replace(':','').replace(':','')
	time = addPadding(time,'0',6,'left')
	return parseInt(time)
}