<template>
  <div>
    <!-- <v-layout row wrap pl-4 class="breadcrumbBackground" mb-4>
        <v-flex xs8 style="margin:auto">
          <v-layout row wrap>
            <v-flex xs12 pl-3>
              <ul class="breadcrumb">
                <li><a href="/dashboard">Home</a></li>                            
                <li><router-link to="/currentjoblist">Todays Job List</router-link></li>
                <li><span @click="$router.go(-1)">
                  <a>[{{envname}}]</a>
                </span>  </li>
                <li>[{{job_id}}] - [{{job_name}}]</li><li>Transaction Details</li><li>{{status}}</li>
              </ul>
            </v-flex>
            <v-flex >

            </v-flex>
          </v-layout>

        </v-flex>
        <v-flex xs4 text-xs-right pr-4 style="">
        	<span id="radioControls" v-show="status === 'Running'">
                <v-radio-group row v-model="whichgraph" @change="loadAllGraph()" hide-details>
                    <v-radio class="radioClass" :color="checkboxColor" label="Live"  value="live"></v-radio>
                    <v-radio class="radioClass" :color="checkboxColor" label="All"  value="all"></v-radio>
                </v-radio-group>
            </span>
        </v-flex>
      </v-layout> -->
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="lineecharts"
      >
        <IEcharts
          :option="lineoption"
          :loading="lineloading"
          @click="onDataPointClick"
          @ready="onLineGraphReady"
        />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs6
        class="echarts"
        style="height:400px !important;overflow:auto;"
      >
        <IEcharts
          :option="option"
          :loading="loading"
          @click="onClick"
          @ready="onReady"
        />
      </v-flex>
      <v-flex
        xs6
        class="echarts"
        style="height:400px !important;overflow:auto;"
      >
        <IEcharts
          :option="option"
          :loading="loading"
          @click="onAvgClick"
          @ready="onAvgReady"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
	import ECharts from 'vue-echarts'
	import IEcharts from 'vue-echarts-v3/src/full.js';
	import 'echarts/lib/chart/line'
	import 'echarts/lib/component/polar'
	import {CHECKBOX_COLOR} from '../../../data/macros.js'
	import config from './../../../config.json'
	import { get as getToServer, deleteFromServer, post as postToServer } from './../../../methods/serverCall.js';
	import _ from 'lodash'
	const cloneDeep = require('clone-deep');

	export default {
		components:{
			IEcharts
		},
		data:function() {
			return {
				isDrillDownload:false,
				subtextStyle:{
							color:"#000",
							// fontWeight:'bold',
							// fontStyle:'italic'
						},
				drilldownText:'',
				envname:'',
				job_id:'',
				job_name:'',
				status:'',
				Datajsonlist:[],
				deleteDatajsonlist:[],
				analysis_id:6,
				loading:false,
				pieloading:false,
				lineloading:false,
				total_echart_instance:null,
				total_echart_graph:null,
				pie_echart_instance:null,
				pie_echart_graph:null,
				line_echart_instance:null,
				line_echart_graph:null,
				avg_echart_instance:null,
				avg_echart_graph:null,
				noOfLines:1,
				datapoint_size:5,
				datapoint_border_width:2,
				line_width:3,
				backend_data:null,
				wholeGraphData:{},
				running_transaction_data:{"transaction_id":"","data":[]},
				call_send_to_fetch_transdetails:false,
				temp_data_container:{},
				showoptions:true,
				whichgraph:'all',
				checkboxColor:CHECKBOX_COLOR,
				titleFontSize:{
							fontSize:15,
						},
				pieoptions:{

				},
				lineoption:{
					title:{
						text:"Commit Progress test"
					},
					tooltip: {
						show:true,
					},
					legend:{
						data:["commit time","commit size"]
					},
				    xAxis: {
				        type: 'category',
				    },
				    yAxis: [{
				        type: 'value',
				        name:'Time in (Sec)',
				        nameGap:15,
				        nameLocation:'end',

				    },
			    	{    
			    		type: 'value',
			            name: 'Commit Size',
			            axisLine: {
			                lineStyle: {
			                    color: 'blue'
			                }
			            }
				    }]
				},
				option :{
				    xAxis: {
				        type: 'category',
				        name:'Tables',
				        nameGap:30,
				        nameLocation:'center',
				        axisTick:{
				        	alignWithLabel:true,
				        },
				         axisLabel: {
					            interval: 0,
					            rotate: 30
					        },
				    },
				    yAxis: {
				        type: 'value'
				    },
				    series: [{
				        data: [],
				        type: 'bar'
				    }]
				}
			}
		},
		watch:{
	      '$store.state.transctionTableObj':{
		      handler(newValue){
		       this.updateData(newValue)
		      }
	      },
	      '$store.state.liveStatus':{
			    handler(newValue){
			        if(this.job_id === newValue.job_id){
			            // if status other than running then radio buttons will get hide.So we need to set graph as all
			            if(newValue.status.toLowerCase() != "running")
			            	this.whichgraph = 'all'
			            this.status = newValue.status.charAt(0).toUpperCase() +newValue.status.slice(1)
			            // if job get completed then need to fetch last transaction details
			            if(this.status === 'Completed'){
			            	var last_transaction_id = this.running_transaction_data.transaction_id
			            	this.fetchSingleTransDetails(last_transaction_id)
			            }
			        }
			    }
			}
	    },
		mounted(){
			this.job_id = this.$route.params.jobid
			this.job_name = this.$route.params.jobname
			this.env_id = this.$route.params.env_id
			this.envname = this.$route.params.envname
			this.status = this.$route.params.status
			if(this.status === 'Running')
				this.whichgraph = "live"
			this.getJobCompletedTransData()
		},
		methods:{
			onLineGraphReady(instance,echarts){
				this.line_echart_instance = instance
				this.line_echart_graph = echarts
			},
			onPieReady(instance,echarts){
				this.pie_echart_instance = instance
				this.pie_echart_graph = echarts
			},
			onReady(instance,echarts){
				this.total_echart_instance = instance
				this.total_echart_graph = echarts
			},
			onAvgReady(instance,echarts){
				this.avg_echart_instance = instance
				this.avg_echart_graph = echarts
			},
			onClick(event,instance,ECharts){

			},
			onAvgClick(){

			},
			getDataPoints(response){
				
			},
			getDataPoints_inCouple(response){
				
			},
			onDataPointClick(event, instance, ECharts){
				var data = event
				if(event.data.length > 1)
					this.drilldownText = event.seriesName + ' at '+event.data[0] + " is " +event.data[1]
				this.ShowDrillDownGraph(event.dataIndex)
			},
			ShowDrillDownGraph(data_index){
				let _this = this
				_this.isDrillDownload = true
				var data_point_data = cloneDeep(this.wholeGraphData.step_details[0].transaction_details[data_index])

				var table_insert_stats = []
				var table_delete_stats = []
				var table_data = data_point_data.table_details
				for (var j = 0; j < table_data.length; j++) {
					var current_row = table_data[j]
					_this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
				} // j for loop end
				var toolbox = {
						show:true,
						feature:{
							myTool1:{
				    			show:true,
				    			title:'Back',
				    			icon:'M464 0H144c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v320c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-96 464H48V256h320v208zm96-96h-48V144c0-26.5-21.5-48-48-48H144V48h320v320z',
				    			onclick:function(){
				    				var transactions = _this.wholeGraphData.step_details[0].transaction_details
				    				_this.drilldownText = ''
				    				_this.processTransactionData(transactions)
				    			}
				    		},
				    		// saveAsImage:{
				    		// 	title:'Save',
				    		// },
						}
					}
				this.generate_total_bar_graph(table_insert_stats,table_delete_stats,this.total_echart_instance,toolbox)
			},
			getJobCompletedTransData(){
				this.loading = true
				postToServer(this, config.ENGINE_API_URL + '/get_job_statistics', {"job_id":this.job_id}).then(response => {
					this.wholeGraphData = response
					this.processTransactionData(response.step_details[0].transaction_details)
					this.loading = false
				}).catch(JobStepError => {
					this.loading = false
				})
			},
			processTransactionData(transaction_details){
				var transactions = cloneDeep(transaction_details)
				var table_insert_stats = []
				var table_delete_stats = []
				var commit_time = []
				var commit_line_graph_data =[]
				var commit_size = []
				for (var i = 0; i < transactions.length; i++) {
					var current_trans = transactions[i]
					commit_time.push({"value":current_trans.transaction_time,"name":"TransNo "+(i+1)})
					commit_line_graph_data.push([current_trans.transaction_end_time,current_trans.transaction_time])
					commit_size.push([current_trans.transaction_end_time,current_trans.chunk_size])
					if(current_trans.status === 'commit'){
						var table_data = current_trans.table_details
						for (var j = 0; j < table_data.length; j++) {
							var current_row = table_data[j]
							this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
						} // j for loop end
					}
				} // i for loop finish
				this.generate_line_graph(commit_line_graph_data,commit_size)
				this.generate_total_bar_graph(table_insert_stats,table_delete_stats,this.total_echart_instance)
				// this.generate_pi(commit_time)
			},
			ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats){
				var operation = current_row.operation
				var table_name = current_row.table_name
				if(!current_row['avg_size']) // avg_size not come with live publish data
					current_row["avg_size"] = 0
				current_row["avg_size"] = parseInt(current_row["avg_size"])
				current_row["total_time"] = parseInt(current_row["total_time"])
				current_row["total_count"] = parseInt(current_row["total_count"])
				current_row["total_size"] = current_row["total_count"] * (current_row["avg_size"]/(1024))
				if(operation === "insert"){
					var obj = _.find(table_insert_stats,["table_name",table_name])
					if(obj){
						obj.total_time = obj.total_time + current_row.total_time
						obj.total_count = obj.total_count + current_row.total_count
						var index = _.findIndex(table_insert_stats,["table_name",table_name])
						obj.time_graph_key = [obj.total_time,table_name]
						obj.size_graph_key = [obj.total_count,table_name]
						obj.avg_time_key = [this.calculateAvgTime(obj.total_count,obj.total_time),table_name]
						obj.avg_time_size_key = [this.calculateAvgSizeTime(obj.total_size,obj.total_time),table_name]
						this.$set(table_insert_stats,index,obj)
					}
					else{
						current_row.time_graph_key = [current_row.total_time,table_name]
						current_row.size_graph_key = [current_row.total_count,table_name]
						current_row.avg_time_key = [this.calculateAvgTime(current_row.total_count,current_row.total_time),table_name]
						current_row.avg_time_size_key = [this.calculateAvgSizeTime(current_row.total_size,current_row.total_time),table_name]
						table_insert_stats.push(current_row)
					}
				}
				else {
					var obj = _.find(table_delete_stats,["table_name",table_name])
					if(obj){
						obj.total_time = obj.total_time + current_row.total_time
						obj.total_count = obj.total_count + current_row.total_count
						obj.time_graph_key = [-obj.total_time,table_name]
						obj.size_graph_key = [-obj.total_count,table_name]
						obj.avg_time_key = [-this.calculateAvgTime(obj.total_count,obj.total_time),table_name]
						obj.avg_time_size_key = [-this.calculateAvgSizeTime(obj.total_size,obj.total_time),table_name]
						var index = _.findIndex(table_delete_stats,["table_name",table_name])
						this.$set(table_delete_stats,index,obj)
					}
					else{
						current_row.time_graph_key = [-current_row.total_time,table_name]
						current_row.size_graph_key = [-current_row.total_count,table_name]
						current_row.avg_time_key = [-this.calculateAvgTime(current_row.total_count,current_row.total_time),table_name]
						current_row.avg_time_size_key = [-this.calculateAvgSizeTime(current_row.total_size,current_row.total_time),table_name]
						table_delete_stats.push(current_row)
					}
				}
			},
			calculateAvgTime(count ,timeInSec){
				if(timeInSec === 0 || count === 0)
					return 0
				return (count/timeInSec).toFixed(2)
			},
			calculateAvgSizeTime(size,timeInSec){
				if(timeInSec === 0 || size === 0)
					return 0
				return (size/timeInSec).toFixed(2)
			},
			generate_line_graph(commit_line_graph_data,commit_size){
				var line_options =  {
					title:{
						text:"Commit Progress test",
						textStyle:this.titleFontSize
					},
					tooltip: {
						show:true,
					},
					legend:{
						data:["commit duration","commit size"]
					},
					 dataZoom : {
					 	type:'inside',
					 	show:true,
					 	realtime: true,
					 	start:0,
					 	end:100,
					 },
				    xAxis: {
				        type: 'category',
				    },
				    yAxis: [{
				        type: 'value',
				        name:'Time in (Sec)',
				        nameGap:15,
				        nameLocation:'end'

				    },
			    	{    
			    		type: 'value',
			            name: 'Commit Size',
			            axisLine: {
			                lineStyle: {
			                    color: 'blue'
			                }
			            }
				    }],
				    series: [{
				        data: commit_line_graph_data,
				        type: 'line',
				        symbolSize:5,
				        name:"commit duration"
				    },
				    {
				    	data:commit_size,
				    	type: 'line',
				    	color:"blue",
				        symbolSize:5,
				        yAxisIndex: 1,
				        name:"commit size"
				    }]
				};
				this.line_echart_instance.setOption(line_options, true)
			},
			generate_total_bar_graph(insert_data,delete_data,graph_instance,toolbox={}){
				let _this = this
				if(insert_data.length < 1)
					return false
				var tables = _.map(insert_data, "table_name")
				var insert_time_series_data = _.map(insert_data, "time_graph_key")
				var delete_time_series_data = _.map(delete_data, "time_graph_key")
				var insert_size_series_data = _.map(insert_data, "size_graph_key")
				var delete_size_series_data = _.map(delete_data, "size_graph_key")
				var option= {
					title:{
						text:"Total Time & Record Count",
						textStyle:this.titleFontSize,
						subtext:_this.drilldownText,
						subtextStyle :_this.subtextStyle
					},
					tooltip: {
						show:true,
					},
					toolbox: toolbox,
					grid: {
				        left: '3%',
				        right: '4%',
				        bottom: '5%',
				        containLabel: true
				    },
				    legend:{
				    	data:["Ins.Time","Del.Time","Ins.Count","Del.Count"],
				    	right:30,
				    },
				    dataZoom : [{
					 	type:'inside',
					 	show:true,
					 	realtime: true,
					 	start:0,
					 	end:100,
					 	yAxisIndex:0
					 }],
				    xAxis : [
				        {
				            type : 'value',
					        name:'Time in(MSec)',
					        nameGap:25,
					        nameLocation:'center',
					        axisTick:{
					        	alignWithLabel:true,
					        },
					   },
					   {
				            type: 'value',
					        name:'Record Count',
					        nameGap:25,
					        nameLocation:'center',
					        axisTick:{
					        	alignWithLabel:true,
					        },
					   }
				    ],
				     yAxis : [
				        {
				            type : 'category',
				            axisTick : {show: false},
				            data : tables,
				            axisLabel: {
					            interval: 0,
					            rotate: 45
					        },
					         
				        }
				    ],
				    series : [
				    	{
				    		name:"Ins.Time",
				    		type:'bar',
				    		stack:"inlinetime",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside',
				                    // formatter: function(data){
				                    // 	return data[1]
				                    // }
				                }
				            },
				            data:insert_time_series_data
				    	},
				    	{
				    		name:"Del.Time",
				    		type:'bar',
				    		stack:"inlinetime",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            data:delete_time_series_data
				    	},
				    	{
				    		name:"Ins.Count",
				    		type:'bar',
				    		stack:"inlinesize",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            xAxisIndex: 1,
				            data:insert_size_series_data
				    	},
				    	{
				    		name:"Del.Count",
				    		type:'bar',
				    		stack:"inlinesize",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            xAxisIndex: 1,
				            data:delete_size_series_data
				    	},
				    ]
				}
				graph_instance.setOption(option, true)
				_this.generate_avg_bar_graph(insert_data,delete_data,_this.avg_echart_instance,toolbox={})

			},
			generate_avg_bar_graph(insert_data,delete_data,graph_instance,toolbox={}){
				let _this = this
				if(insert_data.length < 1)
					return false
				var tables = _.map(insert_data, "table_name")
				var insert_avg_time_series_data = _.map(insert_data, "avg_time_key")
				var delete_avg_time_series_data = _.map(delete_data, "avg_time_key")
				var insert_avg_size_time_series_data = _.map(insert_data, "avg_time_size_key")
				var delete_avg_size_time_series_data = _.map(delete_data, "avg_time_size_key")
				var option= {
					title:{
						text:"Operation Avg Time & Size",
						textStyle:this.titleFontSize,
						subtext:_this.drilldownText,
						subtextStyle :_this.subtextStyle
					},
					tooltip: {
						show:true,
					},
					toolbox: toolbox,
					grid: {
				        left: '3%',
				        right: '4%',
				        bottom: '5%',
				        containLabel: true
				    },
				    legend:{
				    	data:["Ins.AvgT","Del.AvgT","Ins.AvgS","Del.AvgS"],
				    	right:30,
				    },
				    dataZoom : [{
					 	type:'inside',
					 	show:true,
					 	realtime: true,
					 	start:0,
					 	end:100,
					 	yAxisIndex:0
					 }],
				    xAxis : [
				        {
				            type : 'value',
					        name:'Time in(MSec)',
					        nameGap:25,
					        nameLocation:'center',
					        axisTick:{
					        	alignWithLabel:true,
					        },
					   },
					   {
				            type: 'value',
					        name:'Size in (Mb)',
					        nameGap:25,
					        nameLocation:'center',
					        axisTick:{
					        	alignWithLabel:true,
					        },
					   }
				    ],
				     yAxis : [
				        {
				            type : 'category',
				            axisTick : {show: false},
				            data : tables,
				            axisLabel: {
					            interval: 0,
					            rotate: 45
					        },
					         
				        }
				    ],
				    series : [
				    	{
				    		name:"Ins.AvgT",
				    		type:'bar',
				    		stack:"inlinetime",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside',
				                    // formatter: function(data){
				                    // 	return data[1]
				                    // }
				                }
				            },
				            data:insert_avg_time_series_data
				    	},
				    	{
				    		name:"Del.AvgT",
				    		type:'bar',
				    		stack:"inlinetime",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            data:delete_avg_time_series_data
				    	},
				    	{
				    		name:"Ins.AvgS",
				    		type:'bar',
				    		stack:"inlinesize",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            xAxisIndex: 1,
				            data:insert_avg_size_time_series_data
				    	},
				    	{
				    		name:"Del.AvgS",
				    		type:'bar',
				    		stack:"inlinesize",
				            label: {
				                normal: {
				                    show: true,
				                    position: 'inside'
				                }
				            },
				            xAxisIndex: 1,
				            data:delete_avg_size_time_series_data
				    	},
				    ]
				}
				graph_instance.setOption(option, true)

			},

			updatetimedata(data){
				var table_name = data.table_name
				var obj = _.find(this.Datajsonlist,["table_name",table_name])

				if(data.operation === "insert"){
					data.insert_count  = parseInt(data.total_count)
					data.delete_count = 0
				}
				else{
					data.delete_count = parseInt(data.total_count)
					data.insert_count = 0
				}

				if(obj){
					obj.insert_count = obj.insert_count + data.insert_count
					obj.delete_count = obj.delete_count + data.delete_count
					var index = _.findIndex(this.Datajsonlist,["table_name",table_name])
						this.$set(this.Datajsonlist,index,obj)
				}
				else{
					this.Datajsonlist.push(data)
				}
				var tablelist = _.map(this.Datajsonlist,"table_name")
				var insert_data_list = _.map(this.Datajsonlist,"insert_count")
				var delete_data_list =_.map(this.Datajsonlist,"delete_count")
				var option = {
				    xAxis: {
				        type: 'category',
				        name:'Tables',
				        nameGap:0,
				        data:tablelist,
				        nameLocation:'center',
				        axisTick:{
				        	alignWithLabel:true,
				        },
				         axisLabel: {
					            interval: 0,
					            rotate: 30
					        },
				    },
				    yAxis: {
				        type: 'value'
				    },
				    tooltip : {
				        trigger: 'axis',
				        axisPointer: {
				            type: '',
				            label: {
				                backgroundColor: '#6a7985'
				            }
				        }
				    },
				    series: [{
				    	name:"Insert",
				        data: insert_data_list,
				        type: 'bar'
				    },
				    {
				    	name:"Delete",
				        data: delete_data_list,
				        type: 'bar'
				    }]
				}
				this.total_echart_instance.setOption(option, true)
			},
			updateData(data){
				/*new data received. Now check is the new transaction or old*/
				if(this.job_id != data.job_id)
					return false
				if(this.running_transaction_data && this.running_transaction_data.transaction_id){
					if(data.transaction_id === this.running_transaction_data.transaction_id)
					{
					this.running_transaction_data["data"].push(data)
					return this.CreateLiveGraph()
					}
					else{
						var old_transaction_id = this.running_transaction_data.transaction_id
						this.running_transaction_data ={"transaction_id":"","data":[]}
						this.running_transaction_data.transaction_id = data.transaction_id
						this.running_transaction_data["data"].push(data)
						
						// we need to fetch all details of old_transacion_id to design new graph. 
						this.fetchSingleTransDetails(old_transaction_id)
					}
				}
				else{
					var new_transaction_id = data.transaction_id
					this.running_transaction_data.transaction_id = data.transaction_id
					this.running_transaction_data["data"].push(data)
					// fetch data only once when visited this page
					this.fetchSingleTransDetails(new_transaction_id)
				}
			},
			fetchSingleTransDetails(transaction_id){
				if(!transaction_id)
					return false
				var data = {"transaction_id":transaction_id}
				let _this = this
				postToServer(this, config.ENGINE_API_URL + '/get_transaction_statistics', data).then(response => {
					console.log(response)
					if(response.status === "commit"){
						// add this response into wholeGraphData
						if(!_this.wholeGraphData.step_details || _this.wholeGraphData.step_details.length < 1){
							_this.wholeGraphData = {"step_details":[{"transaction_details":[]}]}
							_this.wholeGraphData.step_details[0].transaction_details.push(response)
						}
						else{
							var obj = _.find(_this.wholeGraphData.step_details[0].transaction_details, ["id",response.id])
							if(!obj)
								_this.wholeGraphData.step_details[0].transaction_details.push(response)
						}

						_this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
					}
					else{
						if(response.id === _this.running_transaction_data.transaction_id){
							_this.arrangeRunningData(response.table_details)
						}
					}
				}).catch(JobStepError => {
					
				})
			},
			arrangeRunningData(response_data){
				let _this = this
				/*
				Now we need to add response table details in running_transaction_data.data if that not not present. Where we add its order is mattered here. Becuase table comes in respose in always get excuted first.So we need to add that table at index level on. So loop of the response table details start from last element
				*/
				for (var i = response_data.length - 1; i >= 0; i--) {
					var table_name = response_data[i].table_name
					var operation = response_data[i].operation
					var data_list = _this.running_transaction_data["data"]
					var is_data_found = false
					for (var j = data_list.length - 1; j >= 0; j--) {
					 	if(table_name === data_list[j]["table_name"] && operation === data_list[j]["operation"]){
					 		is_data_found = true
					 		break;
					 	}
					} 
					if(!is_data_found)
						_this.running_transaction_data["data"].unshift(response_data[i])
				}
				if(_this.whichgraph === 'live' && !_this.isDrillDownload)
					this.CreateLiveGraph()
			},
			CreateLiveGraph(){
				if(this.whichgraph === "all" || this.isDrillDownload)
					return false
				let _this = this
				var insert_data = []
				var delete_data = []
				var data_rows =cloneDeep(_this.running_transaction_data["data"])
				for (var i = 0; i < data_rows.length; i++) {
					var current_row = data_rows[i]
					_this.ProcessOnTableGraph(current_row,insert_data,delete_data)
				}
					_this.generate_total_bar_graph(insert_data,delete_data,_this.total_echart_instance)
			},
			loadAllGraph(){
            let _this = this;
            _this.isDrillDownload = false
            _this.total_echart_instance.setOption(this.option, true)
            if(_this.whichgraph === 'live'){
                _this.CreateLiveGraph()
            }
            else{
				_this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
            }
        }
	}
}
</script>
<style scoped>
 .echarts {
  width: 600px;
  height: 400px;
 }
.lineecharts{
	width: 600px;
  height: 200px !important;
}
.graph_div {
	width:500px !important;
	height:500px !important;
	overflow: auto !important;
}

#radioControls .v-input--selection-controls {
   margin-top: 0px !important; 
   padding-top: 0px !important; 
}

</style>