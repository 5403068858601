<template> 
  <div>
    <v-row class="EDC-Row">
        <v-col cols="8" class="EDC-Col">
            <v-row class="EDC-Row breadcrumbRow">
                <v-col cols="12" class="EDC-Col">
                    <ul class="breadcrumb breadcrumbUL">
                        <li>
                        <select v-model="Environment" @change="GetPolicyDetailsList" style="-webkit-appearance: menulist" autofocus ref="envname">
                            <option :value="{}">Select Environment</option>
                            <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                        </select>
                        </li>
                        <li>Policies</li>
                    </ul>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList"  @onDownload="onDownload" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '../../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../../data/client_message.js'

import {CREATE_POLICY, POLICY_LIST_BY_ENV, ALL_PUBLISHED_BUSINESS_OBJ_LIST, UPDATE_POLICY, DELETE_CURRENT_POLICY,GET_ALL_POLICY_TYPE_LIST,GET_ALL_PUBLISHESD_PROCESS_DEF_LIST, EXPORT_POLICY_DETAILS} from '../../../data/url_constants.js';
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import environmentList from '../../../methods/EnvironmentList.js'
import {POLICY_SERVICE,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,READ_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
import {POLICY_VALUE_TYPE,MONTH_CODE_MAPPING, CODE_TO_MONTH_MAPPING,POLICY_VALUE_TYPE_WITH_RETENTION} from '@/data/metaData.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    
    data: function () {
        return {
            loader:false,
            fiscalYearArray : [{name:'January',fiscal_year:'Jan'},{name:'February',fiscal_year:'Feb'},{name:'March',fiscal_year:'Mar'},{name:'April',fiscal_year:'Apr'},
                                {name:'May',fiscal_year:'May'},{name:'June',fiscal_year:'Jun'},{name:'July',fiscal_year:'Jul'},{name:'August',fiscal_year:'Aug'},
                                {name:'September',fiscal_year:'Sep'},{name:'October',fiscal_year:'Oct'},{name:'November',fiscal_year:'Nov'},{name:'December',fiscal_year:'Dec'}],   
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                {
                    text: 'Id',
                    value: 'id',
                    title: 'Policy Id',
                    disabled:true,
                    align:'right',
                    dataType:'int'
                },
                {
                    text: 'Business Object',
                    value: 'bo_name',
                    width: "20%",
                    option:[],
                    'item_text':'bo_name',
                    'item_value':'bo_name',
                    title: 'Policy Business Object Name',
                    useAutoComplete:true
                },
                {
                    text: 'Business Object ID',
                    value: 'bo_id',
                    title: 'Policy Business Object Id',
                    disabled:true,
                },
                {
                    text: 'Process Definition',
                    value: 'pd_name',
                    width: "20%",
                    option:[],
                    'item_text':'pd_name',
                    'item_value':'pd_name',
                    title: 'Policy Process Definition Name',
                    useAutoComplete:true
                },{
                    text: 'Process Definition ID',
                    value: 'pd_id',
                    title: 'Policy Process Definition Id',
                    disabled:true
                },
                {
                    text: 'Policy',
                    value: 'policy_type',
                    option:[],
                    width: "15%",
                    'item_text':'text',
                    'item_value':'text',
                    title: 'Policy Name',
                    useAutoComplete:true,
                    isPolicy:true,
                },
                {
                    text: 'Value Type',
                    value: 'value_type',
                    option:[],
                    width: "15%",
                    'item_text':'name',
                    'item_value':'name',
                    dataType:'value_type',
                    title: 'Policy Value Type',
                    useAutoComplete:true,
                    isPolicy:true
                },
                {
                    text: 'Value',
                    value: 'policy_value',
                    dataType:'date',
                    isNumber:true,
                    width: "15%",
                    align:'right',
                    title: 'Policy Value',
                    useTextField:true,
                    inputType:'number',
                    isPolicy:true
                },
                {
                    text: 'Fiscal year',
                    value: 'fiscal_year',
                    width: "15%",
                    option:[],
                    'item_text':'name',
                    'item_value':'name',
                    title: 'Policy Fiscal Year',
                    useAutoComplete:true,
                    isPolicy:true
                },
                {
                    text: 'Policy Value',
                    value: 'calculated_date',
                    title: 'Calculated policy date as today (YYYY-MM-DD)',
                    disabled:true
                },
            ], 
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            EnvironmentList: [],
            Environment: 0,
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            userRole:{},
            PolicyTypeList:[],
            BO_list:[],
            PD_list:[],
            Policy_types:[{"id": 1, "text": "Archival", "type": "Archival policy"}, {"id": 2, "text": "Retention", "type": "Retention policy"},{"id": 3, "text": "Summarization", "type": "Summarization policy"}],
            // Policy_types:[{"id": 1, "text": "Archival", "type": "Archival policy"}, {"id": 2, "text": "Retention", "type": "Retention policy"}],
        }
    },//
    mounted() {
        this.tableList = this.getGridObj(this.headers,'id',true,{is_grid_inline_edit:true})
        var env_value = this.$session.get('selected_env')
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [{'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,POLICY_SERVICE,UPDATE_ACTION),index:1},
                    {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getUserRole(this.userRole,POLICY_SERVICE,CREATE_ACTION),index:2},    
                    {'text':"save","key":"is_row_editable",selectType:"single",index:3},
                    {'text':"cancel","key":"is_row_editable",selectType:"single",index:4},
                    {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,POLICY_SERVICE,DELETE_ACTION),index:5},
                    ]
        if( env_value && env_value.id != '0'){
            this.Environment = this.$session.get('selected_env')
            this.GetPolicyListByEnv()
        }else{
            this.tableList.rows = []
        }
        this.tableList.headers[8].option=this.fiscalYearArray;
        // this.tableList.headers[2].option=this.PolicyTypeList;
        this.tableList.headers[6].option=POLICY_VALUE_TYPE,
        getEnvironmentList(this);
        //this.GetAllPolicyTypeList();
        this.focus_env()
        this.tableList.headers[5].option = this.Policy_types
        // this.GetPolicyDetailsList()
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option":downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.POLICY_URL+EXPORT_POLICY_DETAILS;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.POLICY_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
                
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                      _this.loader = null
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = objError;
                    }
                    else {
                      _this.snackbar = true
                      _this.colorValue = 'error'
                      _this.snackbartext = SERVER_ERROR;
                    }
              })
        },
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
        GetAllPolicyTypeList () {
			var get_policy_type_url = config.POLICY_URL + GET_ALL_POLICY_TYPE_LIST
			getToServer(this, get_policy_type_url, true).then(response => {
				this.tableList.headers[5].option = response
                this.Policy_types = response
				}).catch(error_response => {
				if(error_response){
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				}
				else {
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = SERVER_ERROR;
					}
				});
		},
        onNew(record){
            if(!this.Environment){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'Please select environment';
                return;
            }

            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
          this.tableList.rows.unshift({'bo_id':'N/A',
                        'pd_id':'N/A',
                        'policy_type':'',
                        'value_type':'',
                        'policy_value':'',
                        'fiscal_year':'',
                        'calculated_date':'',
                        'is_row_editable' : true
                });              
        },
        onSave(record){
            let _this = this;
            if(!record.bo_name || !record.pd_name || !record.policy_type || !record.value_type  || !record.fiscal_year){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields required';
                this.loader = false
                return false
            }
            var data_to_send = {
                "client_id":this.$session.get('client_id'),
                "env_id":this.Environment.id,
                "bo_name":record.bo_name,
                "bo_id":_.find(this.BO_list,["bo_name",record.bo_name]).bo_id,
                "pd_name":record.pd_name,
                "pd_id":_.find(this.PD_list,["pd_name",record.pd_name]).pd_id,
                "policy_type":_.find(_this.Policy_types,["text",record.policy_type]).type,
                // "value_type":record.value_type === "Actual Date"?"actual_date":record.value_type,
                "value_type":record.value_type,
                "policy_value":record.policy_value,
                "fiscal_year":this.getFiscalYear(record.fiscal_year),
                "id":record.id,
                "env_name": this.Environment.name
                }
            if(data_to_send.value_type != "Permanent" && !data_to_send.policy_value){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields required';
                return false
            }
            if(data_to_send.policy_type === "Retention policy" && data_to_send.value_type === "Permanent"){
                data_to_send.policy_value = ""
            }
            var save_policy_url = config.POLICY_URL; 
            if(record.id){
                save_policy_url = save_policy_url + UPDATE_POLICY;
            }else{
                save_policy_url = save_policy_url+CREATE_POLICY;
            }
            _this.loader = true;
            postToServer(_this, save_policy_url, data_to_send).then(response => {
                _this.snackbar = true
                _this.colorValue = 'success'
                _this.snackbartext = response;
                _this.GetPolicyListByEnv()
                _this.loader = false;
            }).catch(error_response => {
                _this.loader = false;
                _this.GetPolicyListByEnv();
                if(error_response){
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = error_response;
                }
                else {
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        getFiscalYear(month){
            return MONTH_CODE_MAPPING[month];
        },
        onValueUpdate(item,event_value,header,headerindex,itemindex){
            if(!header)
                return
            if(header.value === 'policy_type'){
                if(item['policy_type'] === 'Retention'){
                    this.tableList.headers[headerindex+1].option = POLICY_VALUE_TYPE_WITH_RETENTION
                }
                else{
                    this.tableList.headers[headerindex+1].option = POLICY_VALUE_TYPE
                }
            }
            else if(header.value === 'value_type'){
                this.tableList.rows[itemindex]['policy_value'] = ''
                this.tableList.headers[headerindex+1].disabled = false
                if(event_value === 'Permanent')
                    this.tableList.headers[headerindex+1].disabled = true
            }
            this.calculatePolicy(item,itemindex)
        },
        calculatePolicy(record,index){
            var _this = this
            if(!record.policy_type || !record.value_type  || !record.fiscal_year||!record.policy_value){
                return
            }
            let data_to_send = {
                "policy_type":_.find(_this.Policy_types,["text",record.policy_type]).type,
                "value_type":record.value_type,
                "policy_value":record.policy_value,
                "fiscal_year":this.getFiscalYear(record.fiscal_year)
            }
            postToServer(_this, config.POLICY_URL+'/get_actual_policy_date', data_to_send).then(response => {
                if(response){
                    // alert(response.calculated_date)
                    record.calculated_date = response.calculated_date
                }
            }).catch(error_response => {

            })
        },
        onCancel(){
            this.GetPolicyListByEnv();
        },
        onDelete(record){
            let _this = this
            var policy_name = record.policy_name
            var get_delete_policy_list_url = config.POLICY_URL + DELETE_CURRENT_POLICY;
            var env_id = this.Environment.id
            var var_data = {"client_id":this.$session.get('client_id'), "id": record.id, "env_id": env_id, "env_name": this.Environment.name};
            debugger
            _this.loader = true;
            if(!record.id){
                this.onCancel();
                return;
            }
            postToServer(_this, get_delete_policy_list_url, var_data).then(response => {
                _this.snackbar = true
                _this.loader = false;
                _this.colorValue = 'success'
                _this.snackbartext = response;
                _this.GetPolicyListByEnv()
            }).catch(error_response => {
                _this.loader = false;
                if(error_response){
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = error_response;
                }
                else {
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        GetPolicyDetailsList(){
            this.$session.set('selected_env', this.Environment)
            var env_id = this.Environment

            if(env_id != "0"){
                this.GetPolicyListByEnv()
            }else{
                this.tableList.rows = []
            }
        },

        GetBusinessObjectList(env_id) {
            var _this = this
            var user_id = this.$session.get('user_id')
            var client_id = this.$session.get('client_id')
            var get_bo_list_url = config.PUBLISHER_URL + ALL_PUBLISHED_BUSINESS_OBJ_LIST
            var data = {"client_id": client_id, "env_id": env_id}
            var anyBO = {"id": 0, "object_id": "BO0", "object_name": "ANY","bo_name":"ANY","bo_id":"BO0"}
            _this.tableList.headers[1].option = [anyBO]
            this.BO_list = [anyBO]
            postToServer(_this, get_bo_list_url, data, true).then(response => {
                    response.unshift({"id": 0, "object_id": "BO0", "object_name": "ANY"});
                    response.map((obj)=>{
                    obj.bo_name = obj.object_name
                    obj.bo_id = obj.object_id
                })
                    _this.tableList.headers[1].option = response;
                    this.BO_list = response
                    console.log('bos',response)
                }).catch(error_response => {
                if(error_response){
                    
                }
                else {
                    
                    }
                });
        },
        getProcessDefList (env_id) {
            let _this = this;
            _this.loader = true; 
            var client_id = this.$session.get('client_id')
            var data = {"client_id":client_id,"env_id":env_id}
            let anyPD = {"id": 0, "object_id": "PD0", "object_name": "ANY", "pd_id":"PD0", "pd_name":"ANY"}
            _this.tableList.headers[3].option = [anyPD]
            this.PD_list = [anyPD]
            postToServer(this, config.PUBLISHER_URL + '/published_process_def_in_env', data).then(response => {
                _this.loader = false;
                response.unshift({"id": 0, "object_id": "PD0", "object_name": "ANY"});
                response.map((obj)=>{
                    obj.pd_name = obj.object_name
                    obj.pd_id = obj.object_id
                })
                this.tableList.headers[3].option = response;
                this.PD_list = response
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        GetPolicyListByEnv()
        {
              var env_id = this.Environment.id
            this.GetBusinessObjectList(env_id)
            this.getProcessDefList(env_id)

            let _this = this
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var data = {'client_id': client_id, 'env_id': env_id}
            var get_all_policy_list_url = config.POLICY_URL + POLICY_LIST_BY_ENV
            postToServer(this, get_all_policy_list_url, data).then(response => {
            _this.loader = false;
            response.map(obj=>{
                obj.fiscal_year = CODE_TO_MONTH_MAPPING[obj.fiscal_year];
                // obj.policy_value_type = obj.policy_value_type == 'actual_date' ? 'Actual Date':obj.policy_value_type;
                obj.policy_value_type = obj.policy_value_type
                obj.policy_type = _.find(_this.Policy_types,["type",obj.policy_type]).text
            })
            this.tableList.rows = response;
            this.tableList.total_count = response.length;
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                this.tableList.rows = []
                // this.snackbar = true
                // this.colorValue = 'error'
                // this.snackbartext = error_response;
            }
            else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });
        }
    }
}
</script>
<style>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>