export const titleFontSize = {
	fontSize:15,
}
const fontSize = 8
export function getLineGraph(commit_line_graph_data,commit_size,timeunit='seconds'){
	return {
		tooltip: {
			show:true,
		},
		grid:{
			left:35,
			right:35,
			bottom:25,
			top:30
		},
		legend:{
			data:["commit duration","commit size"],
			selected: {
        // selected'series 1'
        'commit time': true,
        // unselected'series 2'
        'commit size': false
      }
    },
    dataZoom : {
    	type:'inside',
    	show:true,
    	realtime: true,
    	start:0,
    	end:100,
    },
    xAxis: {
    	type: 'category',
    },
    yAxis: [{
    	type: 'value',
    	name:timeunit,
    	nameGap:10,
    	nameLocation:'end',
    	axisLabel: {
			interval: 0,
			rotate: 45,
			fontSize:fontSize,
		},
    },
    {    
    	type: 'value',
    	name: 'Commit Size',
    	axisLine: {
    		lineStyle: {
    			color: 'blue'
    		}
    	},
    	axisLabel: {
			interval: 0,
			rotate: 45,
			fontSize:fontSize,
		},
    }],
    series: [{
    	data: commit_line_graph_data,
    	type: 'line',
    	symbolSize:5,
    	name:"commit duration"
    },
    {
    	data:commit_size,
    	type: 'line',
    	color:"blue",
    	symbolSize:5,
    	yAxisIndex: 1,
    	name:"commit size"
    }]
  }
}



export function getBarGraph(legend,yAxisName,tables,series1,series2,toolbox){
	// in case of copy and purge , only one series have the data.
	let edcseries = []
	if(series1.length === 0 || series2.length ===0){
		let mainseries = series1.length?series1:series2
		edcseries = [{
			name:series1.length?legend[0]:legend[1],
			type:'bar',
			stack:"insertstack",
			label: {
				normal: {
					show: true,
					position: 'inside',        
				}
			},
			data:mainseries
		}]
	}
	else{
		edcseries = [{
			name:legend[0],
			type:'bar',
			stack:"insertstack",
			label: {
				normal: {
					show: true,
					position: 'inside',        
				}
			},
			data:series1
		},{
			name:legend[1],
			type:'bar',
			stack:"deletestack",
			label: {
				normal: {
					show: true,
					position: 'inside'
				}
			},
			data:series2
		}]
	}
	return {
		tooltip: {
			show:true,
		},
		toolbox: toolbox,
		grid: {
			left: '3%',
			right: '4%',
			bottom: '5%',
			containLabel: true,
		},
		legend:{
			data:legend,
			right:30,
		},
		grid:{
			left:45,
			right:35,
			bottom:45,
			top:30
		},
		dataZoom : [{
			type:'inside',
			show:true,
			realtime: true,
			start:0,
			end:100,
			yAxisIndex:0
		}],
		xAxis : [
		{
			type : 'category',
			name:'tables',
			nameGap:25,
			data : tables,
			nameLocation:'center',
			axisTick:{
				alignWithLabel:true,
			},
		},
		],
		yAxis : [
		{
			
			type : 'value',
			axisTick : {show: false},
			
			axisLabel: {
				interval: 0,
				rotate: 45,
				fontSize:fontSize,
			},
			nameTextStyle:{
			},
			name:yAxisName
		}
		],
		series : edcseries
  }
}


export function getBarGraph1(legend,xAxisName,tables,series1,series2,toolbox){
	return {
		tooltip: {
			show:true,
		},
		toolbox: toolbox,
		grid: {
			left: '3%',
			right: '4%',
			bottom: '5%',
			containLabel: true,
		},
		legend:{
			data:legend,
			right:30,
		},
		grid:{
			left:45,
			right:35,
			bottom:45,
			top:30
		},
		dataZoom : [{
			type:'inside',
			show:true,
			realtime: true,
			start:0,
			end:100,
			yAxisIndex:0
		}],
		xAxis : [
		{
			type : 'value',
			name:xAxisName,
			nameGap:25,
			nameLocation:'center',
			axisTick:{
				alignWithLabel:true,
			},
		},
		],
		yAxis : [
		{
			type : 'category',
			axisTick : {show: false},
			data : tables,
			axisLabel: {
				interval: 0,
				rotate: 45,
				fontSize:fontSize,
			},
			nameTextStyle:{
			},
			name:'tables'
		}
		],
		series : [
		{
			name:legend[0],
			type:'bar',
			stack:"insertstack",
			label: {
				normal: {
					show: true,
					position: 'inside',        
				}
			},
			data:series1
		},
		{
			name:legend[1],
			type:'bar',
			stack:"deletestack",
			label: {
				normal: {
					show: true,
					position: 'inside'
				}
			},
			data:series2
		},
    // {
    //   name:"Ins.Count",
    //   type:'bar',
    //   stack:"inlinesize",
    //   label: {
    //     normal: {
    //       show: true,
    //       position: 'inside'
    //     }
    //   },
    //   xAxisIndex: 1,
    //   data:insert_size_series_data
    // },
    // {
    //   name:"Del.Count",
    //   type:'bar',
    //   stack:"inlinesize",
    //   label: {
    //     normal: {
    //       show: true,
    //       position: 'inside'
    //     }
    //   },
    //   xAxisIndex: 1,
    //   data:delete_size_series_data
    // },
    ]
  }
}