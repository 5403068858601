<template>
  <div>
    <span @click="DownloadAgent"><a>Download</a></span>
  </div>
</template>
<script>
import moment from 'moment'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import config from '../../config.json'
export default {
  name: 'ClientJobListNew',
  data() {
		return {

		}
	},
  watch:{},
  mounted() {},
  methods: {
  DownloadAgent(){
    let _this = this
    var data_to_send = {"client_id":this.$session.get("client_id"),
                        "client_name": this.$session.get("email")}
    var url = config.AGENT_MANAGEMENT_URL + "/download_agent"
    var report_download_url = config.AGENT_MANAGEMENT_URL + "/export_agent_in_zip/"
    postToServer(this, url, data_to_send).then(response => {
      _this.loader=true;
      if(response){
        report_download_url = report_download_url + response
        window.open(report_download_url, '_blank');
       _this.loader=false;

      }
    }).catch(error_response => {

      if(error_response){
      }
      else {
        }
      });
  },
}
}
</script>

