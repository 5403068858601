<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row" style="padding-top:10px !important" align="center">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb" style="padding-left: 14px !important">
              <li>
                <router-link to="/dashboard">Home</router-link>
              </li>
              <li>
                <router-link to="/calendar">Job Calendar</router-link>
              </li>
              <li>
                <router-link :to="{'name':'update_calendar',params:{ calendarDetails: $store.state.calendarDetails, type:'edit'}}">{{ $store.state.calendarDetails.name }}</router-link>
              </li>
              <li>Manage Job Calendar Event</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-row class="EDC-Row">
      <v-card class="EDC-VCard" elevation-1>
          <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
              <span class="EDC-Heading">Manage Job Calendar Event</span>
            </v-col>
          </v-row>

          <v-row class="EDC-Row EDC-SchRow EDC-RowsSpacing" align="start" style="padding-bottom:24px !important;">
            <v-col class="EDC-Col EDC-UIPadding" cols="2">
              <v-text-field autocomplete="off" v-model="eventName" label="Event Name" hide-details :rules="nameRules" dense @blur="validateEventName"/>
            </v-col>
            
          </v-row>
          <v-row class="EDC-Row" align="start">
            <edc-schedule :beginDateLabel="begin_date_label" :endDateLabel="end_date_label" :edcScheduleObject="jobObject" :showEndDate="true" :showEndTime="true" @saveSchedule="getSaveObject" @cancelSchedule="CancelJobEvent" :scheduleFor="'job_calendar'"></edc-schedule>
          </v-row>
      </v-card>
    </v-row>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
  </div>
</template>
<script>
import config from "../../config.json"
import {
  get as getToServer,
  post as postToServer
} from './../../methods/serverCall.js';
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  GET_EVENT_JOB,
  ADD_EVENT_JOB,
  UPDATE_EVENT_JOB,
  VALIDATE_EVENT
} from '../../data/url_constants.js';

import filter from 'lodash/filter'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import edcSchedule from '@/views/edcSchedule/edcSchedule'

export default {
  name: 'jobCalenderEvent',
  components: {
    'edc-schedule':edcSchedule
  },
  data() {
    return {
      disabled: 0,
      snackbar:false,
      snackbartext:'',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue:'error',
      loader:false,
      loading: false,
      end_date_label:'Select End Date (Day,MM DD YYYY)',
      begin_date_label:'Select Effective Date (Day,MM DD YYYY)',
      nameRules:[v => !!v || 'Name is required'],
      jobObject:{},
      eventName:'Untitled Event',
      isRequestIsInProgress:false
    }
  },
  watch:{
    'eventName':{
      handler(newvalue,oldvalue){
        
      }
    }
  },
  mounted() {
    let _this = this;
      delete this.jobObject.start_from
      delete this.jobObject.start_time
      delete this.jobObject.end_time
      this.jobObject.exception_start_date = ''
      this.jobObject.exception_end_date = ''
      this.jobObject.endIndays = 0
      if (this.$route.params.eventDetails && this.$route.params.eventDetails.id) {
        this.jobObject.name = this.$route.params.eventDetails.id;
        this.getJobEvent();
      }else if(this.$route.params.eventDetails){
        this.setJobEvent(this.$route.params.eventDetails);
      }
      else{
        this.jobObject = _.cloneDeep(JOB_OBJECT.params);
        this.jobObject.timezone = this.$session.get('time_zone_preference');
      }

    },
    props: {
      msg: String
    },
    methods: {
      validateEventName(){
        if(!this.eventName)
          this.eventName ='Untitled Event'
      },
      setJobEvent(response){
        this.jobObject = response;
        let _this = this;
        // this.jobObject.exception_start_time = response.exception_start_time.HH + ':' + response.exception_start_time.mm;
        // this.jobObject.exception_end_time = response.exception_end_time.HH + ':' + response.exception_end_time.mm;
        this.jobObject.is_recurring = (response.is_recurring =="One Time" ? false:true);
        if(this.jobObject.is_recurring){
          if(response.recurring.recurrence_pattern.recurrence_pattern_type == 'weekly'){
            _this.dayOfWeeks.map(obj=>{
              let tempValue = _.indexOf(response.recurring.recurrence_pattern.week_array,obj.text)
              obj.value =  tempValue !== -1 ? true:false
            });
          }
          if(response.recurring.recurrence_pattern.recurrence_pattern_type == 'monthly'){
            _this.dayOfMonthArray.map(obj=>{
              let tempValue = _.indexOf(response.recurring.recurrence_pattern.month_array,obj.text);
              obj.value =  tempValue !== -1 ? true:false;
            });
          }
        }
      },
      saveData(inputParam) {
        let _this = this;
        if(_this.isRequestIsInProgress)
          return

        var clientId = this.$session.get('client_id')
        var user_id = this.$session.get('email')
        let url = config.JOB_CALENDAR_URL;
        if(this.jobObject.id){
          url+= UPDATE_EVENT_JOB
          inputParam['id'] = this.jobObject.id  
        }else{
          url+= ADD_EVENT_JOB
        }
        _this.isRequestIsInProgress = true
        postToServer(this, url, inputParam).then(
        addResponse  => {
          let record = _this.$store.state.calendarDetails;
          _this.$router.push({ name: 'update_calendar', 
            params: { calendarDetails: record, type:'edit',eventDetails:addResponse,
            manageJobCalendar:true,previousRecord:_this.$route.params.previousRecord}});
        }).catch(error_response => {
          _this.isRequestIsInProgress = false
          if(error_response){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_response;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
        });
      },
      getSaveObject(obj) {
        let freshObject = obj
        let tempObject = _.cloneDeep(obj)
        let _this = this;
        delete freshObject.workflow_details
        delete freshObject.category
        delete freshObject.service_id
        delete freshObject.end_point
        delete freshObject.start_from
        delete freshObject.execution_environment
        delete freshObject.execution_env_name
        freshObject.exception_start_date =freshObject.start_date
        freshObject.exception_end_date = freshObject.end_date
        freshObject.name = this.eventName
        freshObject.client_id = this.$session.get('client_id')
        freshObject.created_by = this.$session.get('email')
        // freshObject.exception_start_job = freshObject.exception_start_job  
        // freshObject.exception_end_job = freshObject.exception_end_job
        freshObject.exception_start_time = freshObject.start_time
        freshObject.exception_end_time = freshObject.end_time
        freshObject.calendar_id = _this.$route.params.calendarDetails.id
        freshObject.is_active = true;
        delete freshObject.start_time
        delete freshObject.end_time
        delete freshObject.start_date
        delete freshObject.end_date
        _this.saveData(freshObject);
      },
      getJobEvent() {
        var data_to_pass = {"id":this.$route.params.eventDetails.id};
        let _this = this;
        postToServer(_this, config.JOB_CALENDAR_URL + GET_EVENT_JOB,data_to_pass).then(response => {
          var start_date = response.params.exception_start_date
          var end_date = response.params.exception_end_date
          response.params["start_time"] = response.params.exception_start_time
          response.params["end_time"] = response.params.exception_end_time
          response.params["start_from"] = start_date
          response.params["end_date"] = end_date
          _this.jobObject = response.params;
          _this.jobObject = response.params;
          // this.jobObject.exception_start_time = response.params.exception_start_time.HH + ':' + response.params.exception_start_time.mm;
          _this.Time24to12(response.params.exception_start_time,'Start')
          // this.jobObject.exception_end_time = response.params.exception_end_time.HH + ':' + response.params.exception_end_time.mm;
          _this.Time24to12(response.params.exception_end_time,'End')
          _this.manageRecurrenceList()

          _this.recurrenceType = response.params.recurrenceType
          let recurrenceDetails  = _.find(_this.recurrenceTypeList,["value",_this.recurrenceType])

          _this.schedule_type = recurrenceDetails.recurrence_pattern_type
          if(_this.schedule_type !='one_time'){
            // manage until
            if(_this.jobObject.recurring.range_of_recurrence.end_on)
              _this.recurrenceEndOn = 'indefinitely'
            else if(_this.jobObject.recurring.range_of_recurrence.until_date)
              _this.recurrenceEndOn = 'until_date'
            else
              _this.recurrenceEndOn = 'afterNinteration'
          }
        }).catch(error_response => {

        })
      },
      CancelJobEvent(){
        this.$router.push("/calendar")
      },
    },
  }
  </script>

  <style scoped>
  .radioClass >>> label{
    top: 3px !important;
  } 
  /*.radioClass >>> i{
    font-size: 30px
  }*/
  .customCheckbox >>> label{
    top: 5px !important;
  }
  /* .customCheckbox >>> i{
    top: 5px !important;
  } */
  .pointer{
    cursor: pointer;
  }
  .pointer >>> span{
    cursor: pointer;
  }
  .custWidth{
    width: 55px;
  }
  .customCheckbox >>> label{
    top: 0px !important;
  }
  .v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
  }

  .v-divider--vertical {
    min-height:10%;
  }
  .theme--light.v-divider {
    border-color: rgba(0,0,0,.50);
  }

  .EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
  }

  .EDC-VCardTitle {
    background: #494949 !important;
    color: white !important;
    text-align: left !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0px !important;
    height: 30px !important;
  }

  .EDC-SchRow{
    padding-left:10px !important;
    padding-right:10px !important;
  }

  .EDC-ColAlignPadding {
    padding-left:32px !important
  }

  .EDC-ColTimeZone {
    margin-top:4px !important;
  }

  .EDC-ColRadio {
    padding-top:8px !important;
    margin-left: -3px !important;
  }

  .EDC-ColRun{
    padding-top:15px !important;
  }

  .EDC-ColUntil{
    padding-top:10px !important;
  }

  .EDC-ColMainRadio{
    margin-top:6px !important;
  }

  .EDC-RadioMargin {
    margin-top: -8px !important;
    margin-left: -3px !important;
  }

  .EDC-CalenderMargin {
    margin-top: -8px !important;
    padding-left: 16px !important;
  }

  .EDC-TextfieldMargin {
    padding-left: 10px !important;
  }

  .EDC-UIPadding {
    padding-right:10px !important;
  }

  .EDC-ComboboxPadding {
    padding-top: 5px !important;
  }

  .EDC-Indefinitely {
    margin-top: 12px !important;
  }

  .EDC-Date {
    margin-top: 18px !important;
  }

  </style>