
<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="11" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL" v-if="!show_summarization">
              <li v-if="from_archive">
                <router-link to="/archives">{{ env_name }}</router-link>
              </li>
              <li v-if="!from_archive">
                <router-link to="/allactivity">{{ env_name }}</router-link>
              </li>
              <li>
                <router-link to="/archives">Data Management</router-link>
              </li>

              <li v-if="from_archive">
                <router-link to="/archives">Manage Archives</router-link>
              </li>
              <li v-if="from_archive">
                <router-link to="/archives">{{ bo_name }}</router-link>
              </li>
              <li v-if="!from_archive">
                <router-link to="/allactivity">Business Object Activity</router-link>
              </li>
              <li v-if="!from_archive">
                <router-link to="/allactivity">{{ bo_name }}</router-link>
              </li>
              <li>Show Activity Data</li>
              <li>{{ activity }}</li>
            </ul>
            <ul class="breadcrumb breadcrumbUL" v-else >
              <li>
                <router-link to="/summarizationactivity">{{ env_name }}</router-link>
              </li>
              <li>
                <router-link to="/summarizationactivity">Summarization Activity</router-link>
              </li>
              <li>
                <router-link to="/summarizationactivity">{{summarizeFor}}</router-link>
              </li>
              <li style="cursor:pointer;" @click="showDrilldownTable=false">
                {{summarizationEntryType}}
              </li>
              <li v-if="showDrilldownTable">{{drilldownEntryType}}</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <!-- onEnquiry and onDetailedHistory events defined for BF and Details ICON CLICK EVENT respectivly -->
    <edc-data-grid :dataList="tableList"  @onDownload="onDownload" @UpdateFilter="filterData"  @onPageChange="onPagination"
     @onPerPageChange="onPerPageChange" @onSort="sortBy"  @onEnquiry="onEnquiry($event,true)"
     @onDetailedHistory="onEnquiry($event,true)" key="grid1" @clearDrillDown="showDrilldownTable=false" @redirectToDataviewer="redirectToDataviewer"></edc-data-grid>

    <edc-data-grid :dataList="drilldownTableList"  @onDownload="onDownloadDrilldown" @UpdateFilter="filterDataDrilldown"
     @onPageChange="onPaginationDrilldown" @onPerPageChange="onPerPageChangeDrilldown" @onSort="sortByDrilldown"
      v-show="showDrilldownTable" key="grid2" @redirectToDataviewer="redirectToDataviewer"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
  import config from '../../../config.json'
  import { post as postToServer } from '../../../methods/serverCall.js';
  import { CATALOGDRVDETAILS, EXPORT_ACTIVITY_TABLE } from '../../../data/url_constants.js';
  import {ACTIVITY_TYPE_SELECTIVE_RESTORE,ACTIVITY_TYPE_SELECTIVE_DISPOSE} from './../../../data/job_management.js'
  import { SERVER_ERROR } from '../../../data/client_message.js';
  import {DATABASE_NUMERIC_DATATYPE} from '../../../data/metaData.js';
  var moment = require("moment-timezone")
  import cloneDeep from 'lodash/cloneDeep'
  import {
    CLIENT_SIDE,
    SERVER_SIDE
  } from '../../../data/macros.js'
  import {mapActions} from 'vuex'
  import {manageScreenDisplay} from '@/methods/special.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'
  import {getURLByDomainByProduct} from '@/methods/domain_management.js'
  import {getPathfinderProduct} from "@/methods/commonFunc.js"
  export default {
    data(){
      return{
        // default_column_names: ["ACTION","ACTION_PERFORMED_BY","ACTION_PERFORMED_DATE","CATALOG_ID","is_archived","ACTIVITY_TYPE","CREATED_DATE"],
        activity_ids:this.$route.params.activity_ids,
        job_id:this.$route.params.job_id,
        table_name:this.$route.params.table_name,
        is_action:this.$route.params.is_action,
        env_name:this.$route.params.env_name,
        bo_name:this.$route.params.bo_name,
        activity:this.$route.params.activity,
        filter_data:this.$route.params.filter_data,
        env_id:this.$route.params.env_id,
        unique_identifiers:this.$route.params.unique_identifiers,
        specific_to:this.$route.params.specific_to,
        from_archive:this.$route.params.from_archive,
        is_column_details_required: true,
        columns_details:[{}],
        ds_info:{},
        header_list:[],
        show_summarization:this.$route.params.show_summarization,
        summarizeFor:this.$route.params.summarizeFor,
        summarizationEntryType:this.$route.params.summarizationEntryType,
        drilldownEntryType:'',
        tableList: {
          headers: [],
          actions: [], //if we need conditional action in row then provide key
          rows: [],
        },
        envIpJson : {
          "filter": [],
          "sort": {
            "column": "",
            "type": ""
          },
          "page": 1,
          "page_size": 10
        },
        drilldownTableList:{
          headers: [],
          actions: [], //if we need conditional action in row then provide key
          rows: [],
        },
        showDrilldownTable:false,
        drillDownEnvInJson:{
          "filter": [],
          "sort": {
            "column": "",
            "type": ""
          },
          "page": 1,
          "page_size": 10
        },
        drilldownUniqueIdentifiers:this.$route.params.drilldownUniqueIdentifiers,
        drilldownTableName:this.$route.params.drilldownTableName,
        isDrilldownInLowerCase:false,
        drilldownForRecords:[],
        snackbar: false,
        snackbartext: '',
        snackbartimeout: Snackbar_Default_Timeout,
        colorValue: 'success',
        valid: true,
        loader:false,
        loadingpanel:false,
        grid_total_count:-1, // this variable used for db2i datalist. db2i not provide count and data in single query.
      }
    },

    mounted() {
      console.log('this.$route.params', JSON.stringify(this.$route.params))
      var isCompressionApplicable = false
      var showSelect = false
      let actions = []
      if(this.show_summarization){
        isCompressionApplicable = true
        if(this.summarizationEntryType === 'BF')
          this.drilldownEntryType = 'Details'
        else {
          this.drilldownEntryType = 'BF'
        }
        // this.tableList.hideSelect = false
        // this.tableList.isCompressionApplicable = true
        // this.drilldownTableList.isCompressionApplicable = true
        // this.tableList.itemkey = "rn"
        // this.drilldownTableList.itemkey = "rn"
        showSelect = true
        if(this.show_summarization){
          if(this.summarizationEntryType === 'Details')
            actions = [
              {'text':'enquiry','key':"rn",selectType:"multiple",index:1,'displayText':'Show Related BF Entries'}
          ]
          else{
            actions =[{'text':'detailedHistory','key':"rn",selectType:"multiple",index:1,
            "displayText":"Show Related Summarized Entries"}]
          }
        }
      }
      this.tableList = this.getGridObj(this.headers,'rn',false,{"isCompressionApplicable":isCompressionApplicable,"showSelect":showSelect})
      this.drilldownTableList = this.getGridObj(this.headers,'rn',false,{"isCompressionApplicable":isCompressionApplicable})
      this.tableList.actions = actions
      let page_size = manageScreenDisplay()
      // if(this.show_summarization)
      //   page_size = page_size - 5
      this.envIpJson.page_size = page_size
      if(this.tableList.paginationType == CLIENT_SIDE)
        this.envIpJson.page_size="*";
      console.log(this.$route.params)
      console.log('mounted')
      this.GetDataFromCatalog(this.envIpJson);
      for (let i = 0; i < this.unique_identifiers.length; i++) {
        this.header_list.push({text:this.unique_identifiers[i],value:this.unique_identifiers[i]})
      }
      if(this.drilldownUniqueIdentifiers && this.drilldownUniqueIdentifiers.length){
        for (let i = 0; i < this.drilldownUniqueIdentifiers.length; i++) {
          this.drilldownTableList.headers.push({text:this.drilldownUniqueIdentifiers[i],value:this.drilldownUniqueIdentifiers[i]})
        }
      }
      if(this.summarizationEntryType === 'Details'){
        this.drilldownTableList.headers.push({text:"Job ID",value:"ACTIVITY_JOB_ID",width:'10%', align:'right', dataType:'int'})
        this.drilldownTableList.headers.push({text:"Activity Type",value:"ACTIVITY_TYPE",width: '20%'})
        this.drilldownTableList.headers.push({text:"Created Date",value:"ACTIVITY_PER_DATE",width: '20%', hideInlineFilter:true})
        this.drilldownTableList.headers.push({text:"Sec Job ID",value:"SEC_ACTIVITY_JOB_ID",width: '10%',align:'right', dataType:'int'})
        this.drilldownTableList.headers.push({text:"Sec Activity",value:"SEC_ACTIVITY_TYPE",width: '20%'})
        this.drilldownTableList.headers.push({text:"Sec Action Performed By",value:"SEC_ACTIVITY_PER_BY",width: '30%'})
        this.drilldownTableList.headers.push({text:"Sec Action Performed Date",value:"SEC_ACTIVITY_PER_DATE",width: '30%', hideInlineFilter:true})
        return
      }
      this.header_list.push({text:"Job ID",value:"ACTIVITY_JOB_ID",width:'10%', align:'right', dataType:'int'})
      this.header_list.push({text:"Activity Type",value:"ACTIVITY_TYPE",width: '20%'})
      // this.header_list.push({text:"CATALOG_ID",value:"CATALOG_ID"})
      this.header_list.push({text:"Created Date",value:"ACTIVITY_PER_DATE",width: '20%', hideInlineFilter:true})
      if(this.activity.toLowerCase()!= 'purge'){
        this.header_list.push({text:"Sec Job ID",value:"SEC_ACTIVITY_JOB_ID",width: '10%',align:'right', dataType:'int'})
        this.header_list.push({text:"Sec Activity",value:"SEC_ACTIVITY_TYPE",width: '20%'})
        this.header_list.push({text:"Sec Action Performed By",value:"SEC_ACTIVITY_PER_BY",width: '30%'})
        this.header_list.push({text:"Sec Action Performed Date",value:"SEC_ACTIVITY_PER_DATE",width: '30%', hideInlineFilter:true})
      }
    },

    methods: {
      onDownload(downloadParams, filterArray){
        let _this = this;
        let inputJson = this.envIpJson
        let download_job_id = ''
        var job_data ={
          "file_format": downloadParams.fileType,
          "activity_ids": this.activity_ids,
          "is_action":this.is_action,
          "table_name":this.table_name,
          "env_id":this.env_id,
          "filter_data":this.filter_data,
          "client_id":this.$session.get('client_id'),
          "filter": inputJson.filter,
          "page": inputJson.page,
          "page_size": inputJson.page_size,
          "unique_identifiers": this.unique_identifiers,
          "sort": inputJson.sort,
          "mapped_agent_id":this.$session.get('selected_env').mapped_agent_id,
          "ds_info":this.ds_info,
          "is_column_details_required":this.is_column_details_required,
          "column_details":this.columns_details,
          "job_name":this.bo_name,
          "job_id":this.job_id,
          "specific_to":this.specific_to
        }
        _this.loader=true;
        var url = config.ENGINE_API_URL+EXPORT_ACTIVITY_TABLE;
        postToServer(this, url, job_data).then(Response => {
          _this.loader=false;
          var url = config.ENGINE_API_URL + "/static/" + Response;
          window.open(url, '_blank')
        }).catch(JobError => {
          if(JobError){
            _this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = JobError;
          }
          else {
            _this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
        });
      },

      hideLoader(){
        let _this = this;
        setTimeout(function(){
          _this.loader = false;
        },500);
      },

      filterData(filterArray){
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.filter=[];
        _.set(inputJson, 'page', 1);
        filterArray.map((obj, index)=>{
          obj['column_name'] = obj['column_name'].toUpperCase()
          obj['column'] = obj['column_name']
          obj['value'] = obj['value1']
        });
        _.set(inputJson,"filter",filterArray)
        console.log('filterData')
        this.GetDataFromCatalog(inputJson);
      },

      onPagination(page, perPage) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.page = page;
        inputJson.page_size = perPage;
        console.log('onPagination')
        this.GetDataFromCatalog(inputJson);
      },

      sortBy: function (key, sortingType) {
        let _this = this;
        if(!key || !sortingType)
          return

        let inputJson = _this.envIpJson;
        inputJson.sort.column = key;
        inputJson.sort.type = sortingType;
        console.log('sortBy')
        this.GetDataFromCatalog(inputJson);
      },

      onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.GetDataFromCatalog(inputJson);
        },

      GetDataFromCatalog(envIpJson){
        let _this = this;
        let inputJson = this.envIpJson
        var job_data ={
          "activity_ids": this.activity_ids,
          "is_action":this.is_action,
          "table_name":this.table_name,
          "env_id":this.$session.get('selected_env').id,
          "filter_data":this.filter_data,
          "client_id":this.$session.get('client_id'),
          "filter": inputJson.filter,
          "page": inputJson.page,
          "page_size": inputJson.page_size,
          "unique_identifiers": this.unique_identifiers,
          "specific_to":this.summarizationEntryType?this.summarizationEntryType.toLowerCase():'',
          "sort": inputJson.sort,
          "mapped_agent_id":this.$session.get('selected_env').mapped_agent_id,
          "ds_info":this.ds_info,
          "is_column_details_required":this.is_column_details_required,
        }
        _this.loader=true;

        postToServer(_this, config.ENGINE_API_URL + CATALOGDRVDETAILS,job_data).then(response => {
          var data = response
          _this.grid_total_count =0
          _this.tableList.total_count = 0
          _this.tableList.rows = []
          if(data && data.data.length > 0){
            data = data.data
            var column_list = []
            if(_this.is_column_details_required && response.columns_details){
              _this.columns_details = response.columns_details
              _this.is_column_details_required = false
            }
          if(response.total_count)
            _this.grid_total_count = response.total_count
            var obj_col_names = Object.keys(data[0]);
            obj_col_names.map(col=>{
              let header={ text: col, value: col};
              column_list.push(cloneDeep(header));
            })
            // in case of oracle sometime column name comes in lower case. So if we try bind value part to table header in UPPERCASE its not get bind. So over come that we first check data columns which we received in lower or upper case. To identify that we check 'action' column case.This columns comes with all data.We cannot compare unique identifier column as we dont know the names of the same.
            var is_lower_case = false
            for (var i = 0; i < obj_col_names.length; i++) {
              if(obj_col_names[i]==='sec_activity_type' || obj_col_names[i]==='unique_id'){
                is_lower_case = true
                _this.isDrilldownInLowerCase = true
                break;
              }
            }
              _this.header_list.map(function(obj){
                if(is_lower_case)
                  obj["value"] = obj["value"].toLowerCase()
                if(!obj['align'])
                    obj["align"] = "left"
              var col_details = _.find(_this.columns_details,["column_name",obj['text']])
                if(col_details)
                {
                  if(DATABASE_NUMERIC_DATATYPE.indexOf(col_details["data_type"].toLowerCase()) >=0)
                    obj["align"] = "right"
                    obj["dataType"] = "int"
                }
              })
            _this.tableList.headers = _this.header_list
            _this.tableList.rows = data;
            if(data[0].total_count)
              _this.grid_total_count = data[0].total_count
            _this.tableList.total_count = _this.grid_total_count;
            _this.ds_info = response.ds_info

          } else {
           this.errorMsg = data.message
         }
         _this.hideLoader();
                // this.GetJobPercentage()
              }).catch(JobStepError => {
                _this.hideLoader();
                if(JobStepError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = 'Error occurred while fetching the details.';
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
                _this.hideLoader();
              })
            },
            redirectToDataviewer(){
              let _this = this
              var redirectionObj  = getPathfinderProduct(_this)
              let data_to_send = {
                "env_id":this.env_id,
                "client_id":this.$session.get('client_id'),
                "archivist_ds_type":["catalog_data"],
                "request_for":"catalog",
                "job_name":this.bo_name,
                "job_id":this.job_id,
                "table_list":[{"output_table_name":this.table_name,"output_table_reference":this.bo_name+ ' Catalog Table'}]
              }
              _this.loader=true
              var cache_url = config.EDC_CACHE_SERVICE_URL + "/add_dataviewer_redirect_key"
              postToServer(_this, cache_url, data_to_send).then(response=>{
                _this.loader=false
                if(response){
                var archivist_redirect_key=response
                // write code here to create key and pass that key to the function as query_string
                let query_string = '&archivist_redirect_key='+archivist_redirect_key// append key here
                let url = getURLByDomainByProduct(_this,redirectionObj,'login',query_string)
                window.open(url, '_blank');
                }
                else{
                  return
                }
              }).catch(error_response=>{
                  _this.loader=false
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
              })
            }, 
            onEnquiry(records){
              let _this = this;
              if(records)
                _this.drilldownForRecords = records
              let specific_to = this.specific_to
              if(this.specific_to)
                specific_to = ''
              else
                specific_to = 'details'
              let inputJson = this.drillDownEnvInJson
              var job_data ={
                "activity_ids": this.activity_ids,
                "is_action":this.is_action,
                "table_name":this.drilldownTableName,
                "env_id":this.$session.get('selected_env').id,
                "filter_data":_this.getFilterList(_this.drilldownForRecords),
                "client_id":this.$session.get('client_id'),
                "filter": inputJson.filter,
                "page": inputJson.page,
                "page_size": inputJson.page_size,
                "unique_identifiers": this.drilldownUniqueIdentifiers,
                "specific_to":specific_to,
                "sort": inputJson.sort,
                "mapped_agent_id":this.$session.get('selected_env').mapped_agent_id,
                "ds_info":this.ds_info,
                "is_column_details_required":true,
              }
              _this.loader=true;
              _this.drilldownTableList.total_count = 0
              _this.drilldownTableList.rows = []
              _this.showDrilldownTable = true
              postToServer(_this, config.ENGINE_API_URL + CATALOGDRVDETAILS,job_data).then(response => {
                _this.loader=false;
                var data = response
                let columns_details = response.columns_details
                if(data && data.data.length > 0){
                  data = data.data
                  let tableHeaders = _.cloneDeep(_this.drilldownTableList.headers)
                  tableHeaders.map(function(obj){
                    if(_this.isDrilldownInLowerCase)
                      obj["value"] = obj["value"].toLowerCase()
                    if(!obj['align'])
                        obj["align"] = "left"
                  var col_details = _.find(columns_details,["column_name",obj['text']])
                    if(col_details)
                    {
                      if(DATABASE_NUMERIC_DATATYPE.indexOf(col_details["data_type"].toLowerCase()) >=0)
                        obj["align"] = "right"
                        obj["dataType"] = "int"
                    }
                  })
                  _this.drilldownTableList.headers = tableHeaders
                  _this.drilldownTableList.rows = data;
                  if(data.length)
                    _this.drilldownTableList.total_count = data[0].total_count;
                }
                else{
                  _this.errorMsg = data.message
                }

              }).catch(JobStepError => {
                _this.loader=false;
                if(JobStepError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = 'Error occurred while fetching the details.';
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
              })
            },
            getFilterList(records){
              let valueList = []
              for(let i=0;i<records.length;i++){
                let key = "UNIQUE_ID"
                if(this.isDrilldownInLowerCase)
                  key= "unique_id"
                valueList.push(records[i][key])
              }
              return [{"s_parentheses":"","column_name":"UNIQUE_ID","operator":"_in_","v_type1":"Value","v_type2":"Value","value1":valueList,"value2":"","e_parentheses":"","is_drv_table":false,"ref_row_id":"","ref_step_id":""}]
            },
            filterDataDrilldown(filterArray){
              let _this = this;
              let inputJson = _this.drillDownEnvInJson;
              inputJson.filter=[];
              _.set(inputJson, 'page', 1);
              filterArray.map((obj, index)=>{
                obj['column_name'] = obj['column_name'].toUpperCase()
                obj['column'] = obj['column_name']
                obj['value'] = obj['value1']
              });
              _.set(inputJson,"filter",filterArray)
              console.log('filterData')
              this.onEnquiry();
            },

            onPaginationDrilldown(page, perPage) {
              let _this = this;
              let inputJson = _this.drillDownEnvInJson;
              inputJson.page = page;
              inputJson.page_size = perPage;
              console.log('onPagination')
              this.onEnquiry();
            },

            sortByDrilldown: function (key, sortingType) {
              let _this = this;
              if(!key || !sortingType)
                return

              let inputJson = _this.drillDownEnvInJson;
              inputJson.sort.column = key;
              inputJson.sort.type = sortingType;
              this.onEnquiry();
            },

            onPerPageChangeDrilldown(perPage,page) {
                  let _this = this;
                  let inputJson = _this.drillDownEnvInJson;
                  inputJson.page = page;
                  inputJson.page_size = perPage;
                  this.onEnquiry();
              },
              onDownloadDrilldown(downloadParams, filterArray){
                let _this = this;
                let inputJson = this.drillDownEnvInJson

                let specific_to = this.specific_to
                if(this.specific_to)
                  specific_to = ''
                else
                  specific_to = 'details'
                let download_job_id = ''
                var job_data ={
                  "file_format": downloadParams.fileType,
                  "activity_ids": this.activity_ids,
                  "is_action":this.is_action,
                  "table_name":this.drilldownTableName,
                  "env_id":this.env_id,
                  "filter_data":this.getFilterList(_this.drilldownForRecords),
                  "client_id":this.$session.get('client_id'),
                  "filter": inputJson.filter,
                  "page": inputJson.page,
                  "page_size": inputJson.page_size,
                  "unique_identifiers": this.drilldownUniqueIdentifiers,
                  "sort": inputJson.sort,
                  "mapped_agent_id":this.$session.get('selected_env').mapped_agent_id,
                  "ds_info":this.ds_info,
                  "is_column_details_required":true,
                  "column_details":this.columns_details,
                  "job_name":this.summarizeFor,
                  "job_id":this.job_id,
                  "specific_to":specific_to
                }
                _this.loader=true;
                var url = config.ENGINE_API_URL+EXPORT_ACTIVITY_TABLE;
                postToServer(this, url, job_data).then(Response => {
                  _this.loader=false;
                  var url = config.ENGINE_API_URL + "/static/" + Response;
                  window.open(url, '_blank')
                }).catch(JobError => {
                  if(JobError){
                    _this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = JobError;
                  }
                  else {
                    _this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                  }
                });
              },
          }
        }
        </script>
        <style scoped>
        .smaller{
          width:20px;
          height:20px;
          font: 10px
        }
        /* tr:nth-child(even) {background-color: #E8E8E8;}
        .blurOpacity{
          opacity: 0;
        }
        .showInput {
          visibility: inherit !important;
          width: 50% !important;
        }

        .srch-ip {
          width: 0px;
          background: white;
          color: black;
          float: right;
          transition: all 1s;
          visibility: hidden;
        }

        table {
          border: 2px solid grey;
          border-radius: 3px;
          background-color: #fff;
          cursor: pointer;
        }

        thead {
          line-height: 30px;
          transition: all 10s;
        }

        th {
          background-color: grey;
          color: rgba(255, 255, 255, 0.66);
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        th.active {
          color: #fff;
        }

        th.active .arrow {
          opacity: 1;
        }

        .arrow {
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          margin-left: 5px;
          opacity: 0.66;
        }

        .arrow.asc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #fff;
        }

        .arrow.dsc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #fff;
        }

        .srch-icon {
          font-size: 16px;
          color: white;
          float: right;
          margin-top: 4%;
        } */
        </style>
