<!--<template>
    <div>
        <v-layout row wrap pl-4 class="breadcrumbBackground">
            <v-flex>
                <v-layout row wrap pl-3>
                    <ul class="breadcrumb">
                        <li>
                            <router-link to="/PackageList">Package List</router-link>
                        </li>
                        <li>Object Details</li>
                        <li>{{ package_name }}</li>
                    </ul>
                </v-layout>                
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex mt-2 ml-2 style="text-align: left">
                <span><b>Package Display Name:</b> {{ package_display_name }}</span><br>
                <span><b>Package Note:</b> {{ package_description }}</span>
            </v-flex>
        </v-layout>
        <v-flex xs12 mt-3>
             <edc-data-grid :dataList="tableList"></edc-data-grid>
        </v-flex>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>-->
<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row breadcrumbRow">
            <v-col class="EDC-Col">
                <ul class="breadcrumb breadcrumbUL">
                    <li>
                        <router-link to="/PackageList">Package List</router-link>
                    </li>
                    <li>
                        <router-link to="/PackageList">Object Details</router-link>
                    </li>
                    <li>
                        <router-link to="/PackageList">{{ package_display_name }}</router-link>
                    </li>
                    <li>
                        <router-link to="/PackageList">{{ package_description }}</router-link>
                    </li>
                </ul>
            </v-col>
        </v-row>
        <edc-data-grid :dataList="tableList"></edc-data-grid>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<script>
import {fileUpload, post as postToServer} from "@/methods/serverCall.js"
import config from '../../config.json'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import LoadPanel from '../../views/loading.vue'
export default {
    data () {
        return {
            loader:false,
            // tableList: {
            //     headers: [
            //         {text: 'Object Type', value: 'object_type_desc', sortable: true,
            //         disabled: true, width:'25%', align:'left', title: 'Version of Object'},
            //         {text: 'Object Name', value: 'object_name', sortable: true, disabled: true,
            //         width: '25%', align:'left', title: 'Name of Object'},
            //         {text: 'Object Id', value: 'object_id', sortable: true,
            //         disabled: true, width:'25%', align:'left', title: 'Version of Object'},
            //         {text: 'Object Version', value: 'object_version', sortable: true,
            //         disabled: true, width:'25%', align:'right', title: 'Version of Object'},
            //     ],               
            //     actions: [],
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     rows: [],
            //     hideSelect:true,
            //     hideActions:true,
            //     hideRowInformation:true,
            //     hideFilter:true,
            //     hideExport:true,
            //     hideShowColumns:true
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    {text: 'Object Type', value: 'object_type_desc', sortable: true,
                    disabled: true, width:'25%', align:'left', title: 'Version of Object'},
                    {text: 'Object Name', value: 'object_name', sortable: true, disabled: true,
                    width: '25%', align:'left', title: 'Name of Object'},
                    {text: 'Object Id', value: 'object_id', sortable: true,
                    disabled: true, width:'25%', align:'left', title: 'Version of Object'},
                    {text: 'Object Version', value: 'object_version', sortable: true,
                    disabled: true, width:'25%', align:'right', title: 'Version of Object'},
                ],   
            package_id:this.$route.params.package_id,
            package_name:this.$route.params.package_name,
            package_display_name: this.$route.params.package_display_name,
            package_description: this.$route.params.package_description
        }
    },
    components: {
        'loading-panel':LoadPanel
    },
    mounted(){
        this.tableList =  this.getGridObj(this.headers, 'Object Id', true, {showSelect :  false})
        this.GetPackageObject()
    },
    methods:{
        GetPackageObject() {
            var get_package_object_url = config.PACKAGE_API_URL + '/get_package_object'
            var _this = this
            var package_id = this.package_id
            var t_data = {'package_id': package_id}
            _this.loader = true
            postToServer(this, get_package_object_url, t_data, true).then(response =>
            {
                _this.loader = false
                var data = response
                if(data){
                    this.tableList.rows = data;
                    this.tableList.total_count =data.length; 
                }
                else
                {
                    data = []
                    this.tableList.rows = data;
                    this.tableList.total_count = data.length; 
                }
            }).catch(ProcessDocError => {
                _this.loader = false; 
                if(ProcessDocError){
                    this.tableList.rows = [];
                }   
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

    }
}
</script>
<style>
</style>